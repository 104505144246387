/* eslint-disable react/jsx-key */
import React, { useMemo, useContext, useState } from 'react';
import { useTable, useExpanded } from 'react-table';
import styled, { ThemeContext } from 'styled-components';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';

import Button from '../../darkblue-ui/Button/Button';

import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import PlayCircleIcon from '@material-ui/icons/PlayCircleFilled';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FileCopy from '@material-ui/icons/FileCopy';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import Publish from '@material-ui/icons/Publish';
import { handleServerStatus, downloadServerLogs } from './utils';
import { plainToFlattenObject } from 'utils/Functions/utils';
import { getDarkPursuitUrl } from '../../routes';

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0px;
  padding: 0;
  margin: 0 auto;
  border-collapse: collapse;

  background-color: ${(props) => props.theme.colors.tableBackground};
  th {
    color: ${(props) => props.theme.colors.tableText};
  }

  td:nth-child(5),
  th:nth-child(5) {
    border-right: 2px solid darkgrey;
  }

  td:nth-child(6),
  th:nth-child(6) {
    padding-left: 16px;
  }
`;

const StyledTableHead = styled.th`
  text-align: left;
`;

const VerticalDivider = styled.div`
  height: 35px;
  border-left: 2px solid darkgrey;
  margin: 0px 2px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  button:nth-child(2) {
    margin-left: 8px;
  }
`;

const SubRowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template: 'button button';
  grid-gap: 12px;
`;

const Grid = styled.div`
  display: grid;
  grid-template: ${(props) => (props.template ? props.template : '"a a a"')};
  grid-gap: ${(props) => (props.gap ? props.gap : '24px')};
`;

const StyledActionButton = styled(Button)`
  background: none;
  color: ${(props) => props.theme.colors.actionButton};

  p {
    color: ${(props) => props.theme.colors.actionButton};
  }

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary};
    background: none;
  }

  svg:hover {
    color: ${(props) => props.theme.colors.altPrimary};
    background: none;
  }
`;

const StyledUnfoldLessIcon = styled(UnfoldLessIcon)`
  color: ${(props) => props.theme.colors.actionButton};

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary};
    background: none;
  }
`;

const StyledUnfoldMoreIcon = styled(UnfoldMoreIcon)`
  color: ${(props) => props.theme.colors.actionButton};

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary};
    background: none;
  }
`;

const ActionButtons = ({
  status,
  server,
  apiKey,
  toggleModal,
  setCurrentServerId,
  getServers,
  ...rest
}) => {
  const [deleteBtnDisabled, setDeleteBtnDisabled] = useState(false);
  const [pauseBtnDisabled, setPauseBtnDisabled] = useState(false);
  const [startBtnDisabled, setStartBtnDisabled] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { serverId, server_info } = server;

  const isPaused = server_info.tags['service-tor'] === 'inactive';
  let buttons = [];

  if (status === 'running' && !isPaused && server_info.tags['service-tor']) {
    buttons = [
      <StyledActionButton
        onClick={async () => {
          try {
            setPauseBtnDisabled(true);
            const pauseResponse = await handleServerStatus(
              'stop',
              serverId,
              apiKey,
            );
            getServers();
            enqueueSnackbar('Server paused', { variant: 'success' });
            setPauseBtnDisabled(false);
          } catch (error) {
            setPauseBtnDisabled(false);
            enqueueSnackbar('Something went wrong', { variant: 'error' });
          }
        }}
        title="Pause tor service"
        disabled={pauseBtnDisabled}
        key={`${serverId}_pause`}
        justIcon
      >
        <PauseCircleFilledIcon />
      </StyledActionButton>,
      <StyledActionButton
        value="delete"
        onClick={() => {
          setCurrentServerId(serverId);
          toggleModal('delete');
        }}
        title="Delete server"
        key={`${serverId}_delete`}
        disabled={deleteBtnDisabled}
        justIcon
      >
        <DeleteForeverIcon />
      </StyledActionButton>,
    ];
  } else if (status === 'running' && isPaused) {
    buttons = [
      <StyledActionButton
        onClick={async () => {
          try {
            setStartBtnDisabled(true);
            const resumeResponse = await handleServerStatus(
              'start',
              serverId,
              apiKey,
            );
            getServers();
            enqueueSnackbar('Server started', { variant: 'success' });
            setStartBtnDisabled(false);
          } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
            setStartBtnDisabled(false);
          }
        }}
        title="Resume tor service"
        key={`${serverId}_play`}
        disabled={startBtnDisabled}
        justIcon
      >
        <PlayCircleIcon />
      </StyledActionButton>,
      <StyledActionButton
        value="delete"
        onClick={() => {
          setCurrentServerId(serverId);
          toggleModal('delete');
        }}
        title="Delete server"
        key={`${serverId}_delete`}
        disabled={deleteBtnDisabled}
        justIcon
      >
        <DeleteForeverIcon />
      </StyledActionButton>,
    ];
  } else if (
    server_info.tags.configuration === 'Failed. Please reach out to admin.'
  ) {
    buttons = [
      <StyledActionButton
        title="Pause server"
        key={`${serverId}_play`}
        disabled
        justIcon
      >
        <PauseCircleFilledIcon />
      </StyledActionButton>,
      <StyledActionButton
        title="Delete server"
        key={`${serverId}_delete`}
        disabled
        justIcon
      >
        <DeleteForeverIcon />
      </StyledActionButton>,
    ];
  }

  return (
    <ActionButtonsContainer>
      {buttons
        .map((comp, idx) =>
          idx < buttons.length - 1
            ? [comp, <VerticalDivider key={`${rest?.serverId}_divider`} />]
            : comp,
        )
        .flat()}
    </ActionButtonsContainer>
  );
};

const UploadButton = (props) => {
  const { toggleModal, server, setCurrentServerId } = props;

  return (
    <StyledActionButton
      style={{ height: '41px', padding: '10px 20px' }}
      title="Upload web site zip file to host server"
      value="upload"
      onClick={() => {
        toggleModal('upload');
        setCurrentServerId(server.serverId);
      }}
    >
      <Publish />
    </StyledActionButton>
  );
};

const ExpandRowToggle = (props) => {
  const { row } = props;
  return (
    <span {...row.getToggleRowExpandedProps()}>
      {row.isExpanded ? <StyledUnfoldLessIcon /> : <StyledUnfoldMoreIcon />}
    </span>
  );
};

const DarkHostTable = (props) => {
  const {
    data,
    toggleModal,
    apiKey,
    setCurrentServerId,
    getServers,
    user,
  } = props;
  const themeContext = useContext(ThemeContext);
  // Cell data styling
  const cellPropHandler = (cellInfo) => {
    const { value, row } = cellInfo;
    const configuration = row.original.server_info.tags.configuration;

    let style = {};
    if (
      value === 'running' &&
      configuration !== 'Failed. Please reach out to admin.'
    ) {
      style = { color: themeContext.colors.success };
    } else if (value === 'paused') {
      style = { color: themeContext.colors.alertIconWarning };
    } else if (value === 'Server initializing' || value === 'pending') {
      style = { color: themeContext.colors.primary };
    } else if (
      value === 'shutting-down' ||
      value === 'terminated' ||
      (configuration === 'Failed. Please reach out to admin.' &&
        value === 'running')
    ) {
      style = { color: themeContext.colors.alertIconDanger };
    }
    return { style };
  };

  const renderRowSubComponent = React.useCallback(
    ({ row, toggleModal, excludeFromExpandedRow }) => {
      const { serverId } = row.original;
      return (
        <SubRowContainer>
          <div
            style={{
              fontSize: '12px',
              minWidth: 'fit-content',
            }}
          >
            {Object.entries(plainToFlattenObject(row.original))
              .filter(([key, value]) => !excludeFromExpandedRow.includes(key))
              .map(([key, value]) => {
                // since key is a field, split at the '.' and get the most nested name.
                const keyArr = key.split('.');
                const displayName = keyArr[keyArr.length - 1];
                return (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'max-content auto auto',
                      columnGap: '15px',
                      marginBottom: '5px',
                    }}
                  >
                    <p style={{ minWidth: 'max-content', margin: '0' }}>
                      {displayName}:
                    </p>
                    <p style={{ minWidth: 'max-content', margin: '0' }}>
                      {value ? value : 'none'}
                    </p>
                  </div>
                );
              })}
          </div>
          <div />
          <Grid
            template={`"A A"
                     "A A"`}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p>Network Logs</p>
              <ButtonGrid>
                <Button
                  onClick={() =>
                    toggleModal('OVERRIDE', { type: 'packet', serverId })
                  }
                >
                  View
                </Button>
                <Button
                  onClick={() =>
                    downloadServerLogs({ type: 'packet', serverId, apiKey })
                  }
                >
                  Download
                </Button>
              </ButtonGrid>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p>Web Server Logs</p>
              <ButtonGrid>
                <Button
                  onClick={() =>
                    toggleModal('OVERRIDE', { type: 'server', serverId })
                  }
                >
                  View
                </Button>
                <Button
                  onClick={() =>
                    downloadServerLogs({ type: 'server', serverId, apiKey })
                  }
                >
                  Download
                </Button>
              </ButtonGrid>
            </div>
          </Grid>
        </SubRowContainer>
      );
    },
    [],
  );

  // Column setup for table
  const columns = useMemo(
    () => [
      {
        Header: 'Server Name',
        accessor: 'server_info.tags.name', // accessor is the "key" in the data
      },
      {
        Header: 'CPU/RAM',
        accessor: 'server_info.size',
        Cell: (props) => (
          <p style={{ textTransform: 'capitalize' }}>{props.value}</p>
        ),
      },
      {
        Header: 'Storage',
        accessor: 'server_info.storage',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row, cell }) => {
          const status = cell.value;
          const serverTags = row.original.server_info.tags;
          if (status === 'running') {
            const torService = serverTags['service-tor'];
            if (torService === 'active') {
              return (
                <p style={{ color: themeContext.colors.success }}>Active</p>
              );
            } else if (torService === 'inactive') {
              return (
                <p style={{ color: themeContext.colors.alertIconWarning }}>
                  Inactive
                </p>
              );
            } else if (serverTags?.configuration) {
              return (
                <p
                  style={{
                    ...cellPropHandler(cell)?.style,
                    textTransform: 'capitalize',
                  }}
                >
                  {serverTags.configuration}
                </p>
              );
            } else {
              return (
                <p
                  style={{
                    ...cellPropHandler(cell)?.style,
                    textTransform: 'capitalize',
                  }}
                >
                  {status}
                </p>
              );
            }
          } else {
            return (
              <p style={cellPropHandler(cell)?.style}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </p>
            );
          }
        },
      },
      {
        Header: 'Actions',
        Cell: (props) => {
          return (
            <ActionButtons
              setCurrentServerId={setCurrentServerId}
              toggleModal={toggleModal}
              apiKey={props.apiKey}
              server={props.row.original}
              getServers={getServers}
              {...props.row.values}
            />
          );
        },
      },
      {
        Header: 'Site Files',
        accessor: 'files',
        Cell: (props) => {
          const serverFile = props.row.original?.server_info?.tags?.file;

          if (serverFile) {
            return <p>{serverFile}</p>;
          }

          return (
            <UploadButton
              setCurrentServerId={setCurrentServerId}
              toggleModal={toggleModal}
              server={props.row.original}
              {...props.row}
            />
          );
        },
      },
      {
        Header: 'Onion Address',
        accessor: 'address',
        Cell: (props) => {
          const { enqueueSnackbar } = useSnackbar();
          const copy = useClipboard().copy;

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p>{props.value}</p>
              <div style={{ display: 'flex' }}>
                <StyledActionButton
                  onClick={() => {
                    try {
                      copy(props.value);
                      enqueueSnackbar('Onion address copied!', {
                        variant: 'success',
                      });
                    } catch (error) {
                      enqueueSnackbar('Something went wrong', {
                        variant: 'error',
                      });
                    }
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifySelf: 'end',
                  }}
                  title="Copy onion address"
                  justIcon
                >
                  <FileCopy />
                </StyledActionButton>
                <StyledActionButton
                  title="Open Onion site in DarkPursuit"
                  onClick={() => {
                    try {
                      getDarkPursuitUrl(user, props.value);
                    } catch (e) {
                      console.log('Unable to open due to', e);
                    }
                  }}
                >
                  <TrackChangesIcon />
                </StyledActionButton>
              </div>
            </div>
          );
        },
      },
      {
        Header: () => null,
        id: 'Expander',
        Cell: (props) => <ExpandRowToggle {...props} />,
      },
    ],
    [],
  );

  const excludeFromExpandedRow = [
    ...columns.filter((col) => col?.accessor).map((col) => col.accessor),
    'server_info.os',
    'server_info.tags.username',
    'server_info.tags.hiddenService',
    'server_info.tags.darkhost',
  ];

  const tableInstance = useTable({ columns, data }, useExpanded);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = tableInstance;

  return (
    <StyledTable>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              // Apply the header cell props
              // eslint-disable-line react/jsx-key
              <StyledTableHead {...column.getHeaderProps()}>
                {// Render the header
                column.render('Header')}
              </StyledTableHead>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {// Loop over the table rows
        rows.map((row, idx) => {
          const rowStyles =
            idx % 2 === 0
              ? {
                  backgroundColor:
                    themeContext.colors.alternativeTableBackground,
                }
              : { backgroundColor: themeContext.colors.tableBackground };
          // Prepare the row for display
          prepareRow(row);
          return (
            <React.Fragment key={row.getRowProps().key}>
              {/* Apply the row props */}
              <tr style={rowStyles}>
                {// Loop over the rows cells
                row.cells.map((cell) => {
                  // Apply the cell props
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: { ...cell.column.style, padding: '0px 10px' },
                        },
                        cellPropHandler(cell),
                      ])}
                    >
                      {// Render the cell contents
                      cell.render('Cell', {
                        serverId: row.original.serverId,
                        apiKey,
                      })}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded ? (
                <tr style={rowStyles}>
                  <td
                    style={{ padding: '0px 10px' }}
                    colSpan={visibleColumns.length}
                  >
                    {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                    {renderRowSubComponent({
                      row,
                      toggleModal,
                      excludeFromExpandedRow,
                    })}
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default DarkHostTable;
