import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

/**
 * @Styled Component
 * component styling Section
 */
const CustomMultiSelect = styled(Select)`
 .select__menu {
   background-color: ${(props) => props.theme.colors.multiSelect};
    color:  ${(props) => props.theme.colors.multiSelectColor};
   } 
 }
 
 .select__control {
     background-color: ${(props) => props.theme.colors.multiSelect};
     border: 1px solid rgba(42, 141, 254, 0.51);
     border-radius: 0px;
   }
 
   .select__multi-value {
     background-color ${(props) => props.theme.colors.multiSelectChip}
   }
   .select__indicator.select__clear-indicator {
     display: none
   }
 `;

const ColumnInputSelect = ({
  tableColumns,
  setLocalHiddenColumns,
  localHiddenColumns,
  inputValue,
  setInputValue,
}) => {
  return (
    <div style={{ width: '100%', marginBottom: '20px' }}>
      <p style={{ fontWeight: 'bold' }}>
        {inputValue.length > 1 ? `Visible Columns` : 'Visible Column'}
      </p>
      <CustomMultiSelect
        isMulti
        name="HideColumns"
        options={localHiddenColumns.map((column) => {
          return {
            id: column,
            value: column,
            label: column,
          };
        })}
        value={inputValue}
        styles={{
          option: (styles, { isFocused }) => ({
            ...styles,
            color: isFocused ? 'black' : null,
            ':hover': {
              cursor: 'pointer',
            },
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            ':hover': {
              backgroundColor: '#025cc2',
              color: 'white',
              cursor: 'pointer',
            },
          }),
          indicatorsContainer: (styles) => ({
            ...styles,
            ':hover': {
              cursor: 'pointer',
            },
          }),
        }}
        classNamePrefix="select"
        onChange={(value, { action, ...rest }) => {
          switch (action) {
            case 'select-option':
              if (rest?.option?.id) {
                setInputValue([...value]);
                setLocalHiddenColumns([
                  ...localHiddenColumns.filter((c) => c !== rest.option.id),
                ]);
              }
              break;
            case 'remove-value':
              if (
                value &&
                value.length > 0 &&
                rest?.removedValue?.id &&
                tableColumns.length >= 1
              ) {
                setInputValue([...value]);
                setLocalHiddenColumns([
                  ...localHiddenColumns,
                  ...tableColumns
                    .filter(
                      ({ Header }) =>
                        !value.find(({ value }) => Header === value),
                    )
                    .filter((col) => {
                      return col.Header === rest.removedValue.id;
                    }).map(c => c.Header),
                ]);
              }
              break;
          }
        }}
      />
    </div>
  );
};

export default ColumnInputSelect;
