import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import _ from 'lodash';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import dayjs from 'dayjs';

// @material-ui/core components
import StorefrontIcon from '@material-ui/icons/Store';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';

// @darkblue-ui/components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import Loader from '../../darkblue-ui/Spinners/Loader.js';
import FilterSidebar from '../../darkblue-ui/FilterSidebar/FilterSidebar';
import FilterModal from '../../darkblue-ui/FilterModal/FilterModal';
import HelpToolTipButton from '../../darkblue-ui/Button/HelpToolTipButton';
import BluestoneLogoComponent from '../../darkblue-ui/BluestoneLogoComponent/BluestoneLogoComponent';
import CustomSelectedFilters from '../../components/CustomSelectedFilters/CustomSelectedFilters';
import CalendarFilter from '../../darkblue-ui/CustomRSComponents/Filters/CalendarFilter/CalendarFilter';
import DropdownSelectFilter from '../../darkblue-ui/CustomRSComponents/Filters/DropdownSelectFilter/DropdownSelectFilter.js';

import Cluster from '../Elastic/Cluster';

import {
  getDefaultFilterState,
  getUrlFilterParams,
  getFilterValues,
  getDefaultIndexFilterState,
  handleFilterClick,
  numberWithCommas,
  formatDateObjectToString,
} from '../../filters/utils';

import {
  UniversalFilterNameList,
  UniversalFieldNameList,
  UniversalSelectorList,
  UniversalFilterComponentList,
  UniversalCategoryList,
  UniversalDefaultFilterState,
} from '../../filters/UniversalFilters';

import {
  uniqueItemsByMarket,
  uniqueItemsByNetByTime,
  uniqueItemsByMarketByTime,
  aiContentByMarket,
  mostReviewedVendor,
  topOpiateVendors,
  topMethVendors,
  vendorRiskScore,
  dashboardDatesQuery,
} from '../../utils/Queries/CustomQueries';

import { downloadAsPDF } from '../../utils/Functions/utils.js';

import {
  VictoryChart,
  VictoryBar,
  VictoryStack,
  VictoryPie,
  VictoryTheme,
  VictoryTooltip,
  VictoryLabel,
  VictoryAxis,
  VictoryContainer,
  VictoryLegend,
  VictoryVoronoiContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
} from 'victory';

import InlineDateFilter from '../../components/CustomFilters/InlineDateFilter';

import GetAppIcon from '@material-ui/icons/GetApp';

import Counter from '../../darkblue-ui/Widgets/Counter/Counter.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';

import SwitchGroup from 'darkblue-ui/Inputs/SwitchGroup';

const Buffer = styled.div`
  min-width: 180px;
`;

const ThemedGetAppIcon = styled(GetAppIcon)`
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary};
    cursor: pointer;
  }
`;

const StyledCard = styled(Card)`
  background-color: ${(props) => props.theme.colors.cardBackground} !important;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img``;

const getChartColors = (item, items, chartColors) => {
  let sortedItems = items.sort();
  return chartColors[sortedItems.indexOf(item)];
};

const riskyColorMap = (score) => {
  switch (score) {
    case 'Very High':
      return '#9C382D';
    case 'High':
      return '#C46B25';
    case 'Medium':
      return '#DAA51B';
  }
};

const MissingData = () => {
  return (
    <h2>No data found. Please adjust your query parameters and try again.</h2>
  );
};

const VendorDashboard = (props) => {
  const { index, logo } = props;
  const filters = UniversalFilterComponentList;
  const filterNames = getFilterValues(index, UniversalFilterNameList);
  const filterCategories = getFilterValues(index, UniversalCategoryList);
  const selectors = getFilterValues(index, UniversalSelectorList);
  const location = useLocation();
  const [toggleOn, setToggleOn] = useState(true);
  const [toggleOn2, setToggleOn2] = useState(true);
  const [filterState, setFilterState] = useState(
    getDefaultFilterState(filters, {
      ...getDefaultIndexFilterState(index, UniversalDefaultFilterState),
      ...getUrlFilterParams(location.search),
      TimeFilter: {
        start: new Date(new Date().setDate(new Date().getDate() - 30)),
        end: new Date(),
      },
    }),
  );
  const [triggerQuery, setTriggerQuery] = useState(null);
  const themeContext = useContext(ThemeContext);
  const containerTooltipRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    if (triggerQuery) {
      triggerQuery();
    }
  }, [filterState, triggerQuery]);
  return (
    <Cluster index={index.join(',')}>
      <GridContainer
        style={{ marginRight: '24px', marginTop: '12px' }}
        justify="flex-end"
        alignItems="center"
      >
        {index.length > 1 && (
          <GridItem>
            <DropdownSelectFilter
              otherField
              filterType="MultiDropdownList"
              filterLabel="Dark Web Marketplaces"
              text="By default, this dashboard will display the top results across all Dark Web Marketplaces. Select specific markets of interest below to generate custom visualizations."
              filterState={filterState}
              URLParams
              setFilterState={setFilterState}
              value={filterState['SiteNameFilter']}
              componentId="SiteNameFilter"
              filterList={filterNames}
              dataField="site.name.keyword"
            />
          </GridItem>
        )}
        <GridItem>
          <CalendarFilter
            filterList={filterNames}
            filterState={filterState}
            setFilterState={setFilterState}
            query={dashboardDatesQuery}
          />
        </GridItem>
        <GridItem>
          <Tooltip title="Download Report">
            <ThemedGetAppIcon
              onClick={() =>
                downloadAsPDF(
                  'dashboard',
                  'DarkBlue Vendor Trends',
                  themeContext.colors.mainBackground,
                )
              }
            />
          </Tooltip>
        </GridItem>
      </GridContainer>
      <div style={{ display: 'flex' }}>
        <div id="dashboard" style={{ width: '100%', paddingLeft: '20px' }}>
          <GridContainer justify="center" alignItems="center">
            <GridItem xs={4}>
              <h1 style={{ marginTop: '0px' }}>Vendor Trends Mission Space</h1>
              <p>
                Dark Web Vendors operate across multiple Dark Web Marketplaces,
                selling a variety of products. Track which vendors have the most
                activity and reviews here.
              </p>
            </GridItem>
            <GridItem xs={2}>
              <Counter
                field="site.name.keyword"
                title="Dark Web Markets"
                reactiveFilters={filterNames}
                color="warning"
                toolTipPreText="During the time period selected, DarkBlue collected data on "
                toolTipPostText=" Dark Web Marketplaces."
                icon={<StorefrontIcon />}
                excludedIndices={['market_stats']}
              />
            </GridItem>
            <GridItem xs={2}>
              <Counter
                field="vendorId.keyword"
                title="Dark Web Vendors"
                color="warning"
                reactiveFilters={filterNames}
                toolTipPreText="During the time period selected, DarkBlue identified "
                toolTipPostText=" vendor profiles on Dark Web Marketplaces."
                icon={<StorefrontIcon />}
                excludedIndices={['market_stats']}
              />
            </GridItem>
            <GridItem xs={2}>
              <Counter
                field="productId.keyword"
                title="Dark Web Products"
                reactiveFilters={filterNames}
                color="warning"
                toolTipPreText="During the time period selected, DarkBlue identified "
                toolTipPostText=" product listings on Dark Web Marketplaces."
                icon={<StorefrontIcon />}
                excludedIndices={['market_stats']}
              />
            </GridItem>
            <GridItem xs={2}>
              <Counter
                field="reviewId.keyword"
                title="Dark Web Reviews"
                reactiveFilters={filterNames}
                color="warning"
                toolTipPreText="During the time period selected, DarkBlue identified "
                toolTipPostText=" vendor and product reviews on Dark Web Marketplaces."
                excludedIndices={['market_stats']}
                icon={<StorefrontIcon />}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ zIndex: '0' }}>
            <GridItem xs={12}>
              <CustomSelectedFilters
                index={index}
                filters={[
                  ...filters,
                  <DropdownSelectFilter
                    otherField
                    filterLabel="Dark Web Marketplaces"
                    filterType="MultiDropdownList"
                    text="Select markets of interest to generate custom visualizations."
                    filterState={filterState}
                    URLParams
                    setFilterState={setFilterState}
                    value={filterState['SiteNameFilter']}
                    componentId="SiteNameFilter"
                    filterList={filterNames}
                    dataField="site.name.keyword"
                  />,
                ]}
                filterState={filterState}
                setFilterState={setFilterState}
                dashboard
              />
            </GridItem>
          </GridContainer>

          <GridContainer justify="center" style={{ zIndex: '0' }}>
            <GridItem style={{ zIndex: '0' }} xs={6} sm={6} md={6} lg={6}>
              {toggleOn ? (
                <ReactiveComponent
                  componentId="mostReviewedVendor"
                  customQuery={mostReviewedVendor}
                  defaultQuery={mostReviewedVendor}
                  key="mostReviewedVendor"
                  react={{
                    and: [...filterNames],
                  }}
                  render={(props) => {
                    const { aggregations, loading } = props;
                    let chartData = [];
                    let markets = [];

                    if (
                      !loading &&
                      aggregations &&
                      aggregations.vendors &&
                      aggregations.vendors.buckets
                    ) {
                      aggregations.vendors.buckets.forEach((vendor) => {
                        chartData = [
                          ...chartData,
                          {
                            x: vendor.key,
                            y: vendor.review_sort.value,
                            market: vendor.site.buckets[0].key,
                          },
                        ];
                        markets = [...markets, vendor.site.buckets[0].key];
                      });

                      chartData.sort((a, b) => a.y - b.y);
                    }
                    return (
                      <GridItem xs={12}>
                        <StyledCard>
                          <CardHeader>
                            <GridContainer>
                              <GridItem xs={6}>
                                <h2>Top Reviewed Dark Web Vendors</h2>
                              </GridItem>
                              <GridItem xs={4}>
                                <GridContainer
                                  direction="row"
                                  justify="center"
                                  spacing={1}
                                >
                                  <h4>Risk Score</h4>
                                  <GridItem>
                                    <SwitchGroup
                                      alignItems="flex-end"
                                      style={{ paddingRight: '12px' }}
                                      value={toggleOn}
                                      onChange={() => setToggleOn(!toggleOn)}
                                    />
                                  </GridItem>
                                  <h4>Reviews</h4>
                                </GridContainer>
                              </GridItem>
                              <GridItem xs={2}>
                                <div>
                                  <HelpToolTipButton
                                    size="medium"
                                    tipPlacement="top"
                                    text={`Product reviews are often required by Dark Web Marketplaces following a sale, 
                                    creating a proxy for sales volumes and popularity of a given vendor. `}
                                  />
                                </div>
                              </GridItem>
                              <GridItem>
                                <p>
                                  Displays most reviewed vendors in the last 30
                                  days across Dark Web Markets{' '}
                                </p>
                              </GridItem>
                            </GridContainer>
                          </CardHeader>
                          <CardBody>
                            {loading ? (
                              <Loader />
                            ) : !(
                                chartData[0] &&
                                Object.entries(chartData[0]).length
                              ) ? (
                              <MissingData />
                            ) : (
                              <VictoryChart
                                theme={VictoryTheme.material}
                                containerComponent={<VictoryContainer />}
                                padding={{
                                  top: 20,
                                  bottom: 120,
                                  left: 110,
                                  right: 40,
                                }}
                              >
                                <VictoryLegend
                                  x={60}
                                  y={300}
                                  orientation="horizontal"
                                  itemsPerRow={3}
                                  gutter={10}
                                  rowGutter={2}
                                  data={[
                                    ...new Set(
                                      chartData.map((data) => {
                                        return data.market;
                                      }),
                                    ),
                                  ].map((data) => {
                                    return {
                                      name: data,
                                      symbol: {
                                        fill: getChartColors(
                                          data,
                                          [
                                            ...new Set(
                                              chartData.map((data) => {
                                                return data.market;
                                              }),
                                            ),
                                          ],
                                          themeContext.chartColors,
                                        ),
                                      },
                                    };
                                  })}
                                  style={{ labels: { fontSize: 8 } }}
                                />
                                <VictoryAxis
                                  theme={VictoryTheme.material}
                                  renderInPortal
                                  label="Vendor Id"
                                  style={{
                                    axisLabel: {
                                      padding: 100,
                                      fontSize: 8,
                                    },
                                    tickLabels: {
                                      paddingTop: 10,
                                      fontSize: 6,
                                    },
                                  }}
                                  tickLabelComponent={
                                    <VictoryLabel
                                      dx={5}
                                      textAnchor="end"
                                    />
                                  }
                                />
                                <VictoryAxis
                                  dependentAxis
                                  label="Number of Reviews"
                                  renderInPortal
                                  tickCount={8}
                                  offsetY={110}
                                  style={{
                                    tickLabels: { fontSize: 6 },
                                    axisLabel: {
                                      fontSize: 8,
                                      padding: 30,
                                    },
                                  }}
                                  tickLabelComponent={
                                    <VictoryLabel textAnchor="middle" />
                                  }
                                />
                                <VictoryBar
                                  events={[
                                    {
                                      target: 'data',
                                      eventHandlers: {
                                        onMouseOver: () => {
                                          return [
                                            {
                                              mutation: () => ({
                                                active: true,
                                              }),
                                              target: 'labels',
                                            },
                                            {
                                              mutation: (props) => {
                                                return {
                                                  style: {
                                                    ...props.style,
                                                    ...{
                                                      cursor: 'pointer',
                                                      opacity: '.9',
                                                    },
                                                  },
                                                };
                                              },
                                            },
                                          ];
                                        },
                                        onMouseOut: () => {
                                          return [
                                            {
                                              mutation: () => ({
                                                active: false,
                                              }),
                                              target: 'labels',
                                            },
                                            {
                                              mutation: (props) => {
                                                return {
                                                  style: {
                                                    ...props.style,
                                                    ...{
                                                      cursor: 'inherit',
                                                      opacity: '1',
                                                    },
                                                  },
                                                };
                                              },
                                            },
                                          ];
                                        },
                                        onClick: () => {
                                          return [
                                            {
                                              target: 'data',
                                              mutation: ({ datum }) => {
                                                const encodedMarket = encodeURI(
                                                  `["${datum.market}"]`,
                                                );
                                                const encodedVendor = encodeURI(
                                                  `"${datum.x}"`,
                                                );
                                                const encodedTime = {
                                                  start: encodeURI(
                                                    `${dayjs(
                                                      filterState.TimeFilter
                                                        .start,
                                                    ).format(
                                                      'YYYY-MM-DD HH:mm:ss',
                                                    )}`,
                                                  ),
                                                  end: encodeURI(
                                                    `${dayjs(
                                                      filterState.TimeFilter
                                                        .end,
                                                    ).format(
                                                      'YYYY-MM-DD HH:mm:ss',
                                                    )}`,
                                                  ),
                                                };

                                                const path = `/search/data?DateTimeFilter=%7B%22value%22%3A%7B%22startValue%22%3A%22${encodedTime.start}%22%2C%22endValue%22%3A%22${encodedTime.end}%22%7D%7D&SiteNameFilter=%7B%22value%22%3A${encodedMarket}%7D&VendorIdFilter=%7B%22value%22%3A${encodedVendor}%7D`;
                                                history.push(path);
                                              },
                                            },
                                          ];
                                        },
                                      },
                                    },
                                  ]}
                                  barRatio={1.1}
                                  // offset={{ x: 10 }}
                                  minDomain={{ x: 0 }}
                                  horizontal
                                  style={{
                                    data: {
                                      fill: (d) =>
                                        getChartColors(
                                          d.datum.market,
                                          [...new Set(markets)],
                                          themeContext.chartColors,
                                        ),
                                    },
                                  }}
                                  data={chartData}
                                  y={(datum) => datum.y}
                                  labels={({ datum }) =>
                                    `${numberWithCommas(datum.y)} reviews`
                                  }
                                  labelComponent={
                                    <VictoryTooltip
                                      constrainToVisableArea
                                      orientation="top"
                                      flyoutStyle={{
                                        fill:
                                          themeContext.colors.cardBackground,
                                      }}
                                      dx={-30}
                                    />
                                  }
                                />
                              </VictoryChart>
                            )}
                          </CardBody>
                        </StyledCard>
                      </GridItem>
                    );
                  }}
                />
              ) : (
                <ReactiveComponent
                  componentId="vendorRiskScore"
                  customQuery={vendorRiskScore}
                  defaultQuery={vendorRiskScore}
                  key="vendorRiskScore"
                  react={{
                    and: [...filterNames],
                  }}
                  render={(props) => {
                    const { aggregations, loading } = props;
                    let chartData = [];
                    if (
                      !loading &&
                      aggregations &&
                      aggregations.sites &&
                      aggregations.sites.buckets &&
                      aggregations.sites.buckets[0] &&
                      aggregations.sites.buckets[0].risk_score &&
                      aggregations.sites.buckets[0].risk_score.buckets &&
                      chartData.length === 0
                    ) {
                      aggregations.sites.buckets.forEach((site) => {
                        const data = site.risk_score.buckets.map((score) => {
                          if (score.vendors.value) {
                            return {
                              siteName: site.key,
                              x: score.key,
                              y: score.vendors.value,
                            };
                          } else {
                            return {
                              siteName: site.key,
                              x: score.key,
                              y: 0,
                            };
                          }
                        });
                        chartData = [...chartData, data];
                      });
                      chartData.sort(
                        (a, b) =>
                          a.reduce((accum, item) => accum + item.y, 0) -
                          b.reduce((accum, item) => accum + item.y, 0),
                      );
                    }
                    return (
                      <GridItem xs={12}>
                        <StyledCard>
                          <CardHeader>
                            <GridContainer>
                              <GridItem xs={6}>
                                <h2>Top Reviewed Dark Web Vendors</h2>
                              </GridItem>
                              <GridItem xs={4}>
                                <GridContainer alignItems="center" spacing={1}>
                                  <h4>Risk Score</h4>
                                  <GridItem>
                                    <SwitchGroup
                                      alignItems="flex-end"
                                      style={{ paddingRight: '12px' }}
                                      value={toggleOn}
                                      onChange={() => setToggleOn(!toggleOn)}
                                    />
                                  </GridItem>
                                  <h4>Reviews</h4>
                                </GridContainer>
                              </GridItem>
                              <GridItem xs={2}>
                                <div>
                                  <HelpToolTipButton
                                    size="medium"
                                    tipPlacement="top"
                                    text={`Identify which Dark Web Marketplaces host high-risk vendors that sell opiates or methamphetamines. 
                                    operate across multiple Dark Web Marketplaces, 
                                    and have AI-identified actionable selectors. 
                                    Vendor risk is assessed using an explainable risk score that focuses on actionability, severity, and recency.`}
                                  />
                                </div>
                              </GridItem>
                              <GridItem>
                                <p>
                                  Displays number of risky vendors identified by
                                  AI for each Dark Web Marketplace{' '}
                                </p>
                              </GridItem>
                            </GridContainer>
                          </CardHeader>
                          <CardBody>
                            {loading ? (
                              <Loader />
                            ) : !(
                                chartData[chartData.length - 1] &&
                                Object.entries(chartData[chartData.length - 1])
                                  .length
                              ) ? (
                              <MissingData />
                            ) : (
                              <VictoryChart
                                theme={VictoryTheme.material}
                                containerComponent={<VictoryContainer />}
                                padding={{
                                  top: 20,
                                  bottom: 100,
                                  left: 100,
                                  right: 40,
                                }}
                              >
                                <VictoryLegend
                                  x={80}
                                  y={300}
                                  orientation="horizontal"
                                  itemsPerRow={3}
                                  gutter={10}
                                  rowGutter={2}
                                  data={[
                                    ...new Set(
                                      chartData[chartData.length - 1].map(
                                        (vendor) => {
                                          return vendor.x;
                                        },
                                      ),
                                    ),
                                  ].map((x) => {
                                    return {
                                      name: x,
                                      symbol: {
                                        fill: riskyColorMap(x),
                                      },
                                    };
                                  })}
                                  style={{ labels: { fontSize: 8 } }}
                                />
                                <VictoryAxis
                                  theme={VictoryTheme.material}
                                  renderInPortal
                                  label="Dark Web Marketplace"
                                  tickCount={10}
                                  style={{
                                    axisLabel: {
                                      padding: 90,
                                      fontSize: 8,
                                    },
                                    tickLabels: {
                                      paddingBottom: 40,
                                      fontSize: 6,
                                    },
                                  }}
                                  tickLabelComponent={
                                    <VictoryLabel
                                      dx={5}
                                      textAnchor="end"
                                      // angle={-45}
                                    />
                                  }
                                />
                                <VictoryAxis
                                  dependentAxis
                                  label="Number of Risky Vendors"
                                  renderInPortal
                                  tickCount={5}
                                  style={{
                                    tickLabels: { fontSize: 6 },
                                    axisLabel: {
                                      fontSize: 8,
                                      padding: 25,
                                    },
                                  }}
                                />
                                <VictoryStack
                                  horizontal
                                  offset={10}
                                  minDomain={{ x: 0 }}
                                >
                                  {chartData.map((site) => {
                                    return (
                                      <VictoryStack key={site}>
                                        {site.map((vendor) => {
                                          return (
                                            <VictoryBar
                                              horizontal
                                              barRatio={2.5}
                                              events={[
                                                {
                                                  target: 'data',
                                                  eventHandlers: {
                                                    onMouseOver: () => {
                                                      return [
                                                        {
                                                          mutation: () => ({
                                                            active: true,
                                                          }),
                                                          target: 'labels',
                                                        },

                                                        {
                                                          mutation: (props) => {
                                                            return {
                                                              style: {
                                                                ...props.style,
                                                                ...{
                                                                  cursor:
                                                                    'pointer',
                                                                  opacity: '.9',
                                                                },
                                                              },
                                                            };
                                                          },
                                                        },
                                                      ];
                                                    },
                                                    onMouseOut: () => {
                                                      return [
                                                        {
                                                          mutation: () => ({
                                                            active: false,
                                                          }),
                                                          target: 'labels',
                                                        },
                                                        {
                                                          mutation: (props) => {
                                                            return {
                                                              style: {
                                                                ...props.style,
                                                                ...{
                                                                  cursor:
                                                                    'inherit',
                                                                  opacity: '1',
                                                                },
                                                              },
                                                            };
                                                          },
                                                        },
                                                      ];
                                                    },

                                                    onClick: () => {
                                                      return [
                                                        {
                                                          target: 'data',
                                                          mutation: ({
                                                            datum,
                                                          }) => {
                                                            const encodedMarket = encodeURI(
                                                              `["${datum.siteName}"]`,
                                                            );
                                                            const encodedRisk = encodeURI(
                                                              `["${datum.x}"]`,
                                                            );
                                                            const encodedTime = {
                                                              start: encodeURI(
                                                                `${dayjs(
                                                                  filterState
                                                                    .TimeFilter
                                                                    .start,
                                                                ).format(
                                                                  'YYYY-MM-DD HH:mm:ss',
                                                                )}`,
                                                              ),
                                                              end: encodeURI(
                                                                `${dayjs(
                                                                  filterState
                                                                    .TimeFilter
                                                                    .end,
                                                                ).format(
                                                                  'YYYY-MM-DD HH:mm:ss',
                                                                )}`,
                                                              ),
                                                            };

                                                            const path = `/search/data?DateTimeFilter=%7B%22value%22%3A%7B%22startValue%22%3A%22${encodedTime.start}%22%2C%22endValue%22%3A%22${encodedTime.end}%22%7D%7D&SiteNameFilter=%7B%22value%22%3A${encodedMarket}%7D&RiskRankFilter=%7B%22value%22%3A${encodedRisk}%7D`;
                                                            history.push(path);
                                                          },
                                                        },
                                                      ];
                                                    },
                                                  },
                                                },
                                              ]}
                                              data={[vendor]}
                                              key={(datum) => datum.x}
                                              y={(datum) => datum.y}
                                              x={(datum) => datum.siteName}
                                              labels={
                                                ({ fontSize: 6 },
                                                ({ datum }) => {
                                                  return `${numberWithCommas(
                                                    datum.y,
                                                  )}  ${datum.x} Risk Vendors`;
                                                })
                                              }
                                              style={{
                                                fontSize: 6,
                                                data: {
                                                  fill: (d) =>
                                                    riskyColorMap(d.datum.x),
                                                },
                                              }}
                                              labelComponent={
                                                <VictoryTooltip
                                                  constrainToVisableArea
                                                  // style={{ padding: 5 }}
                                                  flyoutStyle={{
                                                    fill:
                                                      themeContext.colors
                                                        .cardBackground,
                                                  }}
                                                  orientation="top"
                                                  dx={-5}
                                                />
                                              }
                                            />
                                          );
                                        })}
                                      </VictoryStack>
                                    );
                                  })}
                                </VictoryStack>
                              </VictoryChart>
                            )}
                          </CardBody>
                        </StyledCard>
                      </GridItem>
                    );
                  }}
                />
              )}
            </GridItem>

            <GridItem style={{ zIndex: '0' }} xs={6} sm={6} md={6} lg={6}>
              {toggleOn2 ? (
                <ReactiveComponent
                  componentId="topOpiateVendors"
                  customQuery={topOpiateVendors}
                  defaultQuery={topOpiateVendors}
                  // key="topOpiateVendors"
                  react={{
                    and: [...filterNames, "TimeFilter"],
                  }}
                  render={(props) => {
                    const { aggregations, loading } = props;
                    let chartData = [];
                    let markets = [];
                    if (
                      !loading &&
                      aggregations &&
                      aggregations.vendors &&
                      aggregations.vendors.buckets &&
                      aggregations.vendors.buckets[0] &&
                      aggregations.vendors.buckets[0].sites &&
                      aggregations.vendors.buckets[0].sites.buckets &&
                      chartData.length === 0
                    ) {
                      aggregations.vendors.buckets.forEach((vendor) => {
                        const data = vendor.sites.buckets.map((site) => {
                          if (site[1].value) {
                            return {
                              vendorName: vendor.key,
                              x: site.key,
                              y: site[1].value,
                            };
                          } else {
                            return {
                              vendorName: vendor.key,
                              x: site.key,
                              y: 0,
                            };
                          }
                        });
                        chartData = [...chartData, data];
                        markets = [...markets, vendor.sites.buckets[0].key];
                      });
                      chartData.sort(
                        (a, b) =>
                          a.reduce((accum, item) => accum + item.y, 0) -
                          b.reduce((accum, item) => accum + item.y, 0),
                      );
                    }
                    return (
                      <GridItem xs={12}>
                        <StyledCard>
                          <CardHeader>
                            <GridContainer>
                              <GridItem xs={6}>
                                <h2>Top Dark Web Drug Vendors</h2>
                              </GridItem>
                              <GridItem xs={4}>
                                <GridContainer
                                  direction="row"
                                  justify="center"
                                  spacing={1}
                                >
                                  <h4>Meth</h4>
                                  <GridItem>
                                    <SwitchGroup
                                      alignItems="flex-end"
                                      style={{ paddingRight: '12px' }}
                                      value={toggleOn2}
                                      onChange={() => setToggleOn2(!toggleOn2)}
                                    />
                                  </GridItem>
                                  <h4>Opiates</h4>
                                </GridContainer>
                              </GridItem>
                              <GridItem xs={2}>
                                <div>
                                  <HelpToolTipButton
                                    size="medium"
                                    tipPlacement="top"
                                    text={`Track which vendors are the biggest risk to counter drug missions, based on quantity of opiate products listed.`}
                                  />
                                </div>
                              </GridItem>
                              <GridItem>
                                <p>
                                  Displays vendors selling the most opiates and
                                  which Dark Web Marketplaces they operate on{' '}
                                </p>
                              </GridItem>
                            </GridContainer>
                          </CardHeader>
                          <CardBody>
                            {loading ? (
                              <Loader />
                            ) : !(
                                chartData[0] &&
                                Object.entries(chartData[0]).length
                              ) ? (
                              <MissingData />
                            ) : (
                              <VictoryChart
                                theme={VictoryTheme.material}
                                containerComponent={<VictoryContainer />}
                                padding={{
                                  top: 20,
                                  bottom: 100,
                                  left: 90,
                                  right: 40,
                                }}
                              >
                                <VictoryLegend
                                  x={40}
                                  y={300}
                                  orientation="horizontal"
                                  itemsPerRow={3}
                                  gutter={10}
                                  rowGutter={2}
                                  data={[
                                    ...new Set(
                                      chartData.map((vendor) => {
                                        return vendor[0].x;
                                      }),
                                    ),
                                  ].map((vendor) => {
                                    return {
                                      name: vendor,
                                      symbol: {
                                        fill: getChartColors(
                                          vendor,
                                          [
                                            ...new Set(
                                              chartData.map((vendor) => {
                                                return vendor[0].x;
                                              }),
                                            ),
                                          ],
                                          themeContext.chartColors,
                                        ),
                                      },
                                    };
                                  })}
                                  style={{ labels: { fontSize: 8 } }}
                                />
                                <VictoryAxis
                                  theme={VictoryTheme.material}
                                  renderInPortal
                                  label="Vendor Id"
                                  tickCount={10}
                                  style={{
                                    axisLabel: {
                                      padding: 80,
                                      fontSize: 8,
                                    },
                                    tickLabels: {
                                      paddingTop: 20,
                                      fontSize: 6,
                                    },
                                  }}
                                  tickLabelComponent={
                                    <VictoryLabel
                                      dx={5}
                                      textAnchor="end"
                                      // angle={-45}
                                    />
                                  }
                                />
                                <VictoryAxis
                                  dependentAxis
                                  label="Opiate Products"
                                  renderInPortal
                                  tickCount={5}
                                  style={{
                                    tickLabels: { fontSize: 6 },
                                    axisLabel: {
                                      fontSize: 8,
                                      padding: 25,
                                    },
                                  }}
                                />
                                <VictoryStack
                                  horizontal
                                  offset={10}
                                  minDomain={{ x: 0 }}
                                >
                                  {chartData.map((vendor) => {
                                    return (
                                      <VictoryStack key={vendor}>
                                        {vendor.map((sites) => {
                                          return (
                                            <VictoryBar
                                              horizontal
                                              events={[
                                                {
                                                  target: 'data',
                                                  eventHandlers: {
                                                    onMouseOver: () => {
                                                      return [
                                                        {
                                                          mutation: () => ({
                                                            active: true,
                                                          }),
                                                          target: 'labels',
                                                        },
                                                        {
                                                          mutation: (props) => {
                                                            return {
                                                              style: {
                                                                ...props.style,
                                                                ...{
                                                                  cursor:
                                                                    'pointer',
                                                                  opacity: '.9',
                                                                },
                                                              },
                                                            };
                                                          },
                                                        },
                                                      ];
                                                    },
                                                    onMouseOut: () => {
                                                      return [
                                                        {
                                                          mutation: () => ({
                                                            active: false,
                                                          }),
                                                          target: 'labels',
                                                        },
                                                        {
                                                          mutation: (props) => {
                                                            return {
                                                              style: {
                                                                ...props.style,
                                                                ...{
                                                                  cursor:
                                                                    'inherit',
                                                                  opacity: '1',
                                                                },
                                                              },
                                                            };
                                                          },
                                                        },
                                                      ];
                                                    },

                                                    onClick: () => {
                                                      return [
                                                        {
                                                          target: 'data',
                                                          mutation: ({
                                                            datum,
                                                          }) => {
                                                            const encodedOpiateTag = encodeURI(
                                                              `["opiates"]`,
                                                            );
                                                            const encodedVendor = encodeURI(
                                                              `"${datum.xName}"`,
                                                            );
                                                            const encodedMarket = encodeURI(
                                                              `["${datum.x}"]`,
                                                            );
                                                            const encodedTime = {
                                                              start: encodeURI(
                                                                `${dayjs(
                                                                  filterState
                                                                    .TimeFilter
                                                                    .start,
                                                                ).format(
                                                                  'YYYY-MM-DD HH:mm:ss',
                                                                )}`,
                                                              ),
                                                              end: encodeURI(
                                                                `${dayjs(
                                                                  filterState
                                                                    .TimeFilter
                                                                    .end,
                                                                ).format(
                                                                  'YYYY-MM-DD HH:mm:ss',
                                                                )}`,
                                                              ),
                                                            };

                                                            const path = `/search/data?DateTimeFilter=%7B%22value%22%3A%7B%22startValue%22%3A%22${encodedTime.start}%22%2C%22endValue%22%3A%22${encodedTime.end}%22%7D%7D&SiteNameFilter=%7B%22value%22%3A${encodedMarket}%7D&VendorIdFilter=%7B%22value%22%3A${encodedVendor}%7D&DrugContentTagsFilter=%7B%22value%22%3A${encodedOpiateTag}%7D`;
                                                            history.push(path);
                                                          },
                                                        },
                                                      ];
                                                    },
                                                  },
                                                },
                                              ]}
                                              barRatio={2.5}
                                              data={[sites]}
                                              key={(datum) => datum.x}
                                              y={(datum) => datum.y}
                                              x={(datum) => datum.vendorName}
                                              labels={
                                                ({ fontSize: 8 },
                                                ({ datum }) => {
                                                  return `${numberWithCommas(
                                                    datum.y,
                                                  )} items`;
                                                })
                                              }
                                              style={{
                                                fontSize: 6,
                                                data: {
                                                  fill: (d) =>
                                                    getChartColors(
                                                      d.datum.x,
                                                      [...new Set(markets)],
                                                      themeContext.chartColors,
                                                    ),
                                                },
                                              }}
                                              labelComponent={
                                                <VictoryTooltip
                                                  constrainToVisableArea
                                                  orientation="top"
                                                  flyoutStyle={{
                                                    fill:
                                                      themeContext.colors
                                                        .cardBackground,
                                                  }}
                                                  dx={-30}
                                                  // style={{ padding: 5 }}
                                                />
                                              }
                                            />
                                          );
                                        })}
                                      </VictoryStack>
                                    );
                                  })}
                                </VictoryStack>
                              </VictoryChart>
                            )}
                          </CardBody>
                        </StyledCard>
                      </GridItem>
                    );
                  }}
                />
              ) : (
                <ReactiveComponent
                  componentId="topMethVendors"
                  customQuery={topMethVendors}
                  defaultQuery={topMethVendors}
                  key="topMethVendors"
                  react={{
                    and: [...filterNames],
                  }}
                  render={(props) => {
                    const { aggregations, loading } = props;
                    let chartData = [];
                    let markets = [];
                    if (
                      !loading &&
                      aggregations &&
                      aggregations.vendors &&
                      aggregations.vendors.buckets &&
                      aggregations.vendors.buckets[0] &&
                      aggregations.vendors.buckets[0].sites &&
                      aggregations.vendors.buckets[0].sites.buckets &&
                      chartData.length === 0
                    ) {
                      aggregations.vendors.buckets.forEach((vendor) => {
                        const data = vendor.sites.buckets.map((site) => {
                          if (site[1].value) {
                            return {
                              vendorName: vendor.key,
                              x: site.key,
                              y: site[1].value,
                            };
                          } else {
                            return {
                              vendorName: vendor.key,
                              x: site.key,
                              y: 0,
                            };
                          }
                        });
                        chartData = [...chartData, data];
                        markets = [...markets, vendor.sites.buckets[0].key];
                      });
                      chartData.sort(
                        (a, b) =>
                          a.reduce((accum, item) => accum + item.y, 0) -
                          b.reduce((accum, item) => accum + item.y, 0),
                      );
                    }
                    return (
                      <GridItem xs={12}>
                        <StyledCard>
                          <CardHeader>
                            <GridContainer>
                              <GridItem xs={6}>
                                <h2>Top Dark Web Drug Vendors</h2>
                              </GridItem>
                              <GridItem xs={4}>
                                <GridContainer
                                  direction="row"
                                  justify="center"
                                  spacing={1}
                                >
                                  <h4>Meth</h4>
                                  <GridItem>
                                    <SwitchGroup
                                      alignItems="flex-end"
                                      style={{ paddingRight: '12px' }}
                                      value={toggleOn2}
                                      onChange={() => setToggleOn2(!toggleOn2)}
                                    />
                                  </GridItem>
                                  <h4>Opiates</h4>
                                </GridContainer>
                              </GridItem>
                              <GridItem xs={2}>
                                <div>
                                  <HelpToolTipButton
                                    size="medium"
                                    tipPlacement="top"
                                    text={`Track which vendors are the biggest risk to counter drug missions, based on quantity of methamphetamines products listed.`}
                                  />
                                </div>
                              </GridItem>
                              <GridItem>
                                <p>
                                  Displays vendors selling the most
                                  methamphetamines and which Dark Web
                                  Marketplaces they operate on{' '}
                                </p>
                              </GridItem>
                            </GridContainer>
                          </CardHeader>
                          <CardBody>
                            {loading ? (
                              <Loader />
                            ) : !(
                                chartData[0] &&
                                Object.entries(chartData[0]).length
                              ) ? (
                              <MissingData />
                            ) : (
                              <VictoryChart
                                theme={VictoryTheme.material}
                                containerComponent={<VictoryContainer />}
                                padding={{
                                  top: 20,
                                  bottom: 100,
                                  left: 90,
                                  right: 40,
                                }}
                              >
                                <VictoryLegend
                                  x={40}
                                  y={300}
                                  orientation="horizontal"
                                  itemsPerRow={3}
                                  gutter={10}
                                  rowGutter={2}
                                  data={[
                                    ...new Set(
                                      chartData.map((vendor) => {
                                        return vendor[0].x;
                                      }),
                                    ),
                                  ].map((vendor) => {
                                    return {
                                      name: vendor,
                                      symbol: {
                                        fill: getChartColors(
                                          vendor,
                                          [
                                            ...new Set(
                                              chartData.map((vendor) => {
                                                return vendor[0].x;
                                              }),
                                            ),
                                          ],
                                          themeContext.chartColors,
                                        ),
                                      },
                                    };
                                  })}
                                  style={{ labels: { fontSize: 8 } }}
                                />
                                <VictoryAxis
                                  theme={VictoryTheme.material}
                                  renderInPortal
                                  label="Vendor Id"
                                  tickCount={10}
                                  style={{
                                    axisLabel: {
                                      padding: 80,
                                      fontSize: 8,
                                    },
                                    tickLabels: {
                                      paddingBottom: 20,
                                      fontSize: 6,
                                    },
                                  }}
                                  tickLabelComponent={
                                    <VictoryLabel
                                      dx={5}
                                      textAnchor="end"
                                      // angle={-45}
                                    />
                                  }
                                />
                                <VictoryAxis
                                  dependentAxis
                                  label="Methamphetamine Products"
                                  renderInPortal
                                  tickCount={5}
                                  style={{
                                    tickLabels: { fontSize: 6 },
                                    axisLabel: {
                                      fontSize: 8,
                                      padding: 25,
                                    },
                                  }}
                                />
                                <VictoryStack
                                  horizontal
                                  offset={10}
                                  minDomain={{ x: 0 }}
                                >
                                  {chartData.map((vendor) => {
                                    return (
                                      <VictoryStack key={vendor}>
                                        {vendor.map((sites) => {
                                          return (
                                            <VictoryBar
                                              horizontal
                                              barRatio={2.5}
                                              events={[
                                                {
                                                  target: 'data',
                                                  eventHandlers: {
                                                    onMouseOver: () => {
                                                      return [
                                                        {
                                                          mutation: () => ({
                                                            active: true,
                                                          }),
                                                          target: 'labels',
                                                        },
                                                        {
                                                          mutation: (props) => {
                                                            return {
                                                              style: {
                                                                ...props.style,
                                                                ...{
                                                                  cursor:
                                                                    'pointer',
                                                                  opacity: '.9',
                                                                },
                                                              },
                                                            };
                                                          },
                                                        },
                                                      ];
                                                    },
                                                    onMouseOut: () => {
                                                      return [
                                                        {
                                                          mutation: () => ({
                                                            active: false,
                                                          }),
                                                          target: 'labels',
                                                        },
                                                        {
                                                          mutation: (props) => {
                                                            return {
                                                              style: {
                                                                ...props.style,
                                                                ...{
                                                                  cursor:
                                                                    'inherit',
                                                                  opacity: '1',
                                                                },
                                                              },
                                                            };
                                                          },
                                                        },
                                                      ];
                                                    },

                                                    onClick: () => {
                                                      return [
                                                        {
                                                          target: 'data',
                                                          mutation: ({
                                                            datum,
                                                          }) => {
                                                            const encodedOpiateTag = encodeURI(
                                                              `["methamphetamines"]`,
                                                            );
                                                            const encodedMarket = encodeURI(
                                                              `["${datum.x}"]`,
                                                            );
                                                            const encodedVendor = encodeURI(
                                                              `"${datum.xName}"`,
                                                            );
                                                            const encodedTime = {
                                                              start: encodeURI(
                                                                `${dayjs(
                                                                  filterState
                                                                    .TimeFilter
                                                                    .start,
                                                                ).format(
                                                                  'YYYY-MM-DD HH:mm:ss',
                                                                )}`,
                                                              ),
                                                              end: encodeURI(
                                                                `${dayjs(
                                                                  filterState
                                                                    .TimeFilter
                                                                    .end,
                                                                ).format(
                                                                  'YYYY-MM-DD HH:mm:ss',
                                                                )}`,
                                                              ),
                                                            };

                                                            const path = `/search/data?DateTimeFilter=%7B%22value%22%3A%7B%22startValue%22%3A%22${encodedTime.start}%22%2C%22endValue%22%3A%22${encodedTime.end}%22%7D%7D&SiteNameFilter=%7B%22value%22%3A${encodedMarket}%7D&VendorIdFilter=%7B%22value%22%3A${encodedVendor}%7D&DrugContentTagsFilter=%7B%22value%22%3A${encodedOpiateTag}%7D`;
                                                            history.push(path);
                                                          },
                                                        },
                                                      ];
                                                    },
                                                  },
                                                },
                                              ]}
                                              data={[sites]}
                                              key={(datum) => datum.x}
                                              y={(datum) => datum.y}
                                              x={(datum) => datum.vendorName}
                                              labels={
                                                ({ fontSize: 8 },
                                                ({ datum }) => {
                                                  return `${numberWithCommas(
                                                    datum.y,
                                                  )} items`;
                                                })
                                              }
                                              style={{
                                                fontSize: 6,
                                                data: {
                                                  fill: (d) =>
                                                    getChartColors(
                                                      d.datum.x,
                                                      [...new Set(markets)],
                                                      themeContext.chartColors,
                                                    ),
                                                },
                                              }}
                                              labelComponent={
                                                <VictoryTooltip
                                                  constrainToVisableArea
                                                  orientation="top"
                                                  flyoutStyle={{
                                                    fill:
                                                      themeContext.colors
                                                        .cardBackground,
                                                  }}
                                                  dx={-30}
                                                />
                                              }
                                            />
                                          );
                                        })}
                                      </VictoryStack>
                                    );
                                  })}
                                </VictoryStack>
                              </VictoryChart>
                            )}
                          </CardBody>
                        </StyledCard>
                      </GridItem>
                    );
                  }}
                />
              )}
            </GridItem>
          </GridContainer>
          <BluestoneLogoComponent logo={logo} />
        </div>
      </div>
    </Cluster>
  );
};

export default VendorDashboard;
