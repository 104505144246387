import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import dayjs from 'dayjs';

import Paper from '@material-ui/core/Paper';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import { StateContext } from '../../../Search/StateProviderContext/StateProviderContext.js';
import { lbcThreadQuery } from '../../../../utils/Queries/CustomQueries';
import Modal from '../../../Modals/Modal/Modal.js';
import Loader from '../../../Spinners/Loader';
import { Fragment } from 'react';

const ThreadContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
`;

const StyledSpan = styled.span`
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  line-height: 18px;
  color: ${(props) => props.theme.colors.primaryText};
`;

const TimestampSpan = styled.span`
  float: right;
  margin: 0;
  opacity: 0.95;
  color: ${(props) => props.theme.colors.primaryText};
`;

const ThreadCard = styled(Paper)`
  &&& {
    padding: 16px 12px;
    border-radius: 0px;
    max-width: 100%;
    width: 100%;
    background-color: ${(props) => props.theme.colors.mainBackground};
    margin-bottom: 16px;
  }
`;

const ThreadEntry = ({ entry, setFilterState }) => {
  const {
    timestamp,
    senderUsername,
    recipientUsername,
    message,
    advertisementMessage,
    messageType,
    advertisementId,
    filterState,
  } = entry._source;

  return (
    <ThreadCard>
      <GridContainer alignItems="baseline" justify="space-between">
        <GridItem xs={12}>
          <GridContainer alignItems="center">
            {senderUsername ? (
              <GridItem xs={12}>
                <Title>Sender Username:</Title>{' '}
                <StyledSpan
                  onClick={() => {
                    setFilterState({
                      ...filterState,
                      ...{
                        SenderUsernameFilter: {
                          elasticPath: ['senderUsername.keyword'],
                          value: senderUsername,
                        },
                      },
                      ...{
                        DocFilter: {
                          elasticPath: ['doc.type.keyword'],
                          value: ['message'],
                        },
                      },
                    });
                  }}
                  className="no-margin"
                >
                  {senderUsername}
                </StyledSpan>
                <TimestampSpan>
                  {dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')}
                </TimestampSpan>
              </GridItem>
            ) : null}
            {recipientUsername ? (
              <GridItem xs={10}>
                <Title>Recipient Username:</Title>{' '}
                <StyledSpan
                  onClick={() => {
                    setFilterState({
                      ...filterState,
                      ...{
                        RecipientUsernameFilter: {
                          elasticPath: ['recipientUsername.keyword'],
                          value: recipientUsername,
                        },
                        ...{
                          DocFilter: {
                            elasticPath: ['doc.type.keyword'],
                            value: ['message'],
                          },
                        },
                      },
                    });
                  }}
                  className="no-margin"
                >
                  {recipientUsername}
                </StyledSpan>
              </GridItem>
            ) : null}
            {messageType ? (
              <GridItem xs={10}>
                <Title>Message Type: </Title>{' '}
                <StyledSpan
                  onClick={() => {
                    setFilterState({
                      ...filterState,
                      ...{
                        MessageTypeFilter: {
                          elasticPath: ['messageType.keyword'],
                          value: messageType,
                        },
                        ...{
                          DocFilter: {
                            elasticPath: ['doc.type.keyword'],
                            value: ['message'],
                          },
                        },
                      },
                    });
                  }}
                  className="no-margin"
                >
                  {messageType}
                </StyledSpan>
              </GridItem>
            ) : null}
            {advertisementId ? (
              <GridItem>
                <Title>Advertisement Id</Title>{' '}
                <StyledSpan
                  onClick={() => {
                    setFilterState({
                      ...filterState,
                      ...{
                        AdvertisementIdFilter: {
                          elasticPath: ['advertisementId.keyword'],
                          value: String(advertisementId),
                        },
                      },
                      ...{
                        DocFilter: {
                          elasticPath: ['doc.type.keyword'],
                          value: ['message'],
                        },
                      },
                    });
                  }}
                  className="no-margin"
                >
                  {advertisementId}
                </StyledSpan>{' '}
              </GridItem>
            ) : null}
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {message ? (
          <GridItem>
            <Title>Message</Title>
            <p style={{ margin: '0px' }}>{message}</p>
          </GridItem>
        ) : null}
        {advertisementMessage ? (
          <GridItem>
            <Title>Advertisement Message</Title>
            <p style={{ margin: '0px' }}>{advertisementMessage}</p>
          </GridItem>
        ) : null}
      </GridContainer>
    </ThreadCard>
  );
};

const ThreadModal = ({
  setShowLBCThreadModal,
  handleFilterOn,
  setFilterState,
  threadId,
  filterState,
}) => {
  const { apiUrl, apiKey } = useContext(StateContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchThread = async () => {
      setLoading(true);
      const resp = await fetch(`${apiUrl}/_search`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${apiKey.key}`,
        },
        method: 'POST',
        body: JSON.stringify(lbcThreadQuery(threadId)),
      });

      const respData = await resp.json();

      setData(respData);
      setLoading(false);
    };

    fetchThread();
  }, []);
  return (
    <Modal
      open
      onClickOutside={() => setShowLBCThreadModal(false)}
      style={{
        maxWidth: '50%',
        maxHeight: '80%',
        padding: '0px 100px',
        overflowY: 'auto',
      }}
    >
      {loading === false ? (
        <ThreadContainer>
          <>
            {data.hits &&
              data.hits.hits.length > 0 &&
              data.hits.hits.map((entry) => {
                return (
                  <Fragment key={entry._id}>
                    <ThreadEntry
                      handleFilterOn={handleFilterOn}
                      setFilterState={setFilterState}
                      filterState={filterState}
                      threadId={threadId}
                      filterState={filterState}
                      closeModal={() => setShowLBCThreadModal(false)}
                      entry={entry}
                      key={entry._id}
                    />
                  </Fragment>
                );
              })}
          </>
        </ThreadContainer>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loader />
        </div>
      )}
    </Modal>
  );
};

export default ThreadModal;
