const UniversalFieldNameList = {
  scans: [
    'error',
    'port',
    '_id',
    '_index',
    '_source',
    'score',
    'status',
    '_type',
    'timeout',
    'crypto.ssh',
    'crypto.ssh.key',
    'crypto.ssh.fingerprint',
    'crypto.ssh.type',
    'cyrpto.ssh.key',
    'crypto.tls.extensions.AuthorityKeyIdentifier.key_identifier',
    'crypto.tls.extensions.AuthorityKeyIdentifier.oid',
    'crypto.tls.extensions.BasicConstraints.oid',
    'crypto.tls.extensions.SubjectKeyIdentifier.digest',
    'crypto.tls.extensions.SubjectKeyIdentifier.oid',
    'crypto.tls.fingerprint',
    'crypto.tls.publicKey',
    'crypto.tls.serial',
    'crypto.tls.signature',
    'crypto.tls.version',
    'crypto.tls.subject',
    'cyrpto.ssh.version',
    'cyrpto.tls.publicKey ',
    'cyrpto.tls.serial',
    'protocol',
    'cyrpto.tls.subject',
    'cyrpto.tls.signature',
    'message',
    'cyrpto.tls.version',
    'cyrpto.tls.signatureHashAlgorithm',
    'target',
    'ssh.key',
    'ssh.version',
    'ssh.type',
    'ssh.fingerprint',
    'crypto.tls.issuer',
  ],

  databases: [
    'url',
    '_id',
    '_index',
    '_type',
    'addDeclared',
    'address',
    'adminDuties',
    'age',
    'ansr',
    'answer',
    'athr',
    'back',
    'backone',
    'birthday.$date',
    'birthday',
    'branch_Name',
    'branchid',
    'c_name',
    'c_phone',
    'cate',
    'cell',
    'cellnumber',
    'checker',
    'city',
    'co_id',
    'comment.content.Text',
    'comment.content.employer',
    'comment.content.employercomment.content.id.$oid',
    'comment.content.id.$oid',
    'comment.content.uid',
    'comment.content.user',
    'community',
    'company',
    'contact',
    'course',
    'creator',
    'dId',
    'dates',
    'day',
    'department',
    'district',
    'doc.contentHash',
    'doc.extracted',
    'doc.fileName',
    'doc.fileType',
    'doc.idHash',
    'doc.processedTime',
    'doc.scraped',
    'doc.source',
    'doc.type',
    'domain',
    'domicile',
    'driverslic',
    'eid',
    'email',
    'email2',
    'email3',
    'emailAddresses',
    'employer',
    'event_id',
    'examState',
    'family',
    'family_instructions',
    'fax',
    'firstParty',
    'firstname',
    'fuhao',
    'fwf',
    'groupId',
    'hint',
    'id.$oid',
    'id',
    'id_card',
    'ipAddress',
    'isPartyer',
    'jbf',
    'job',
    'join.id.$oid',
    'join.jnPl',
    'join.jnRn',
    'join.jnYr',
    'last4ssn',
    'lastname',
    'latter',
    'loginName',
    'mailtext',
    'marry_status',
    'mid_init',
    'mingzhu',
    'month',
    'my_evaluate',
    'my_promise',
    'nId',
    'name',
    'name1',
    'name2',
    'name3',
    'newDpt',
    'newsId',
    'ofTown',
    'office',
    'orde',
    'org',
    'page',
    'pageparty',
    'party',
    'party_type',
    'passwd',
    'password',
    'per_id',
    'phone',
    'province',
    'pwd',
    'ques',
    'r_name',
    'r_phone',
    'regid',
    'remark',
    'review',
    'role_num',
    'school',
    'secret',
    'sectF',
    'sects',
    'sex',
    'shenType',
    'snum',
    'source',
    'state',
    'state_lic',
    'state_licstatus',
    'status',
    'street1',
    'street2',
    'streetsCom',
    'studentId',
    'subject',
    'telephone',
    'texesId',
    'title',
    'token',
    'town',
    'txt',
    'type',
    'uid',
    'updater',
    'username',
    'usrName',
    'village',
    'weiQq',
    'work',
    'worker',
    'xueli',
    'year',
    'zhibu',
    'zhuanye',
    'zhuanyezip',
    'zip',
  ],
  get all() {
    return [...new Set([...this.scans, ...this.databases])];
  },
};

export { UniversalFieldNameList };
