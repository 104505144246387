import React from 'react';
import PersonIcon from '@material-ui/icons/Person';

const tags = [
  {
    singular: true,
    indexTag: true,
    path: 'doc.type',
    tagLabel: 'Data Type',
    elasticPath: ['doc.type.keyword'],
    filterName: 'DocFilter',
    icon: <PersonIcon />,
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.net',
    tagLabel: 'Site Net',
    elasticPath: ['site.net.keyword'],
    filterName: 'SiteNetFilter',
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.name',
    toolTipInfo: 'Site Name',
    tagLabel: 'Site Name',
    filterName: 'SiteNameFilter',
    elasticPath: ['site.name.keyword'],
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.type',
    filterName: 'SiteTypeFilter',
    tagLabel: 'Site Type',
    elasticPath: ['site.type.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.tags',
    tagPointer: 'ai.content',
    filterName: 'ContentTagsFilter',
    tagLabel: 'Content Tags',
    elasticPath: ['ai.content.tags.keyword'],
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'role',
    filterName: 'RoleFilter',
    tagLabel: 'Role',
    elasticPath: ['role.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.language',
    elasticPath: ['ai.language.keyword'],
    tagLabel: 'Language',
    filterName: 'LanguageFilter',
    filterValueType: 'array',
  },
  {
    descriptionPath: 'ai.usper.explanation',
    tagText: 'Potential USPER',
    path: 'ai.usper.potential',
    tagLabel: 'US Person',
    filterName: 'UsperFilter',
    elasticPath: ['ai.usper.potential'],
    filterValueType: 'array',
  },
];

const earmarks = [{ path: 'timestamp' }];

const title = {
  title: 'Author',
  path: 'author',
  filterName: 'AuthorFilter',
  elasticPath: ['author.keyword'],
  filterValueType: 'array',
};

const body = [
  {
    title: 'Description',
    path: 'description',
    filterName: 'AuthorDescriptionFilter',
    elasticPath: ['description.keyword'],
    filterValueType: 'array',
  },
  { title: 'Signature', path: 'signature' },
  { title: 'Number of Posts', path: 'numberOfPosts' },
  {
    title: 'PGP Matches',
    path: 'ai.user.matches.selectors.pgp',
  },
];

const footers = [
  { title: 'Joined', path: 'joined' },
  { title: 'Last Visit', path: 'lastVisit' },
  {
    title: 'Last Post',
    path: 'ai.user.activity.lastPost',
  },
  {
    title: 'Last Post Type',
    path: 'ai.user.activity.lastPostType',
  },
  {
    title: 'Posts in the Last 7 Days',
    path: 'ai.user.activity.posts7day',
  },
  {
    title: 'Posts in the Last 30 Days',
    path: 'ai.user.activity.posts30day',
  },
  {
    title: 'Profile Located At',
    path: 'page',
    filterName: 'PageFilter',
    elasticPath: ['page.keyword'],
    filterValueType: 'array',
  },
  { title: 'Minutes Spent Online', path: 'minutesSpentOnline' },
];

const selectors = [
  'analyticsIDs',
  'apacheServices',
  'bitcoinAddresses',
  'bitcoinSignature',
  'emailAddresses',
  'ethereumAddresses',
  'litecoinAddresses',
  'moneroAddresses',
  'pgpKeys',
  'phoneNumbers',
  'ssh',
  'socialMedia',
  'vendorBitcoinAddress',
  'vendorPgp',
  'pgp.fingerprints',
  'pgp.identities',
];

export { tags, earmarks, title, body, footers, selectors };
