import React, { useState, useContext } from 'react';
import _ from 'lodash';
// import { UserConsumer } from '../../../../DBAuthenticator.js';
import styled from 'styled-components';

import HighlightComponent from '../HighlightComponent';
import Button from '../../../Button/Button';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import { HtmlTranslator } from '../Translator';
import TranslateIcon from '@material-ui/icons/Translate';

const SnapshotContainer = styled(GridContainer)`
  &&& {
    background-color: ${(props) => props.theme.colors.cardBackground};
  }
`;

const ThemedTranslationIcon = styled(TranslateIcon)`
color: ${(props) => props.theme.colors.primary} !important;
&:hover {
  color: ${(props) => props.theme.colors.altPrimary} !important;
  cursor: pointer !important;
`;

const SnapshotModal = ({ setShowSnapModal, pageTitle, pageUrl, cleanHtml }) => {
  const [previewHtmlData, setPreviewHtmlData] = useState(cleanHtml);
  const [isTranslated, setIsTranslated] = useState(false);
  return (
    <SnapshotContainer direction="column">
      <GridItem>
        <GridContainer justify="center" alignItems="center">
          <GridItem xs={10} style={{ textAlign: 'center' }}>
            <h1>
              Page Title: <HighlightComponent text={pageTitle} />
            </h1>
            <h3>
              URL: <HighlightComponent text={pageUrl} />
            </h3>
            {isTranslated === true ? (
              <p>
                ***Disclaimer: This record has been machine translated into
                English.***{' '}
              </p>
            ) : null}
          </GridItem>
          <GridItem xs={2}>
            <Button
              style={{ padding: '10px 20px' }}
              color="danger"
              onClick={() => setShowSnapModal(false)}
            >
              Close
            </Button>
          </GridItem>
          <GridItem xs={11} style={{ textAlign: 'right' }}>
            {isTranslated === false ? (
              <ThemedTranslationIcon
                className="fas fa-language fa-2x"
                onClick={async () => {
                  const value = await HtmlTranslator(cleanHtml);
                  setPreviewHtmlData(value);
                  setIsTranslated(true);
                }}
              />
            ) : (
              <ThemedTranslationIcon
                className="fas fa-language fa-2x"
                onClick={() => {
                  setPreviewHtmlData(cleanHtml);
                  setIsTranslated(false);
                }}
              />
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem autoFocus>
        <iframe
          srcDoc={previewHtmlData}
          style={{ minHeight: '600px', minWidth: '100%' }}
        />
      </GridItem>
    </SnapshotContainer>
  );
};

export default SnapshotModal;
