import React, { useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';

import Account from './Account';
import ContentPreferences from './ContentPreferences';
import Notifications from './Notifications';
import Apperance from './Apperance';

const Container = styled.div`
  display: flex;
  height: 260px;
  grid-gap: 20px;
  flex-direction: row;
  padding: 10px 40px 0px;
  align-items: center;

  ul {
    list-style-type: none;
    padding: 0;

    border-radius: 5px !important;
    border-color: #d0d7de;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    
  }

  ul > li {
    font-size: 20px;
    padding: 5px; 0px;
    border-bottom: 1px solid #ddd;
  }

  ul > li.active {
    border-left: solid #2A8DFE;
    background: ${(props) => (props.theme === 'dark' ? '#2a8dfe' : '#f9fafb')};
  }

  ul > li:hover {
    background-color: #6e76811a;
    color: ${(props) => (props.theme === 'dark' ? '#2a8dfe' : 'black')};
    cursor: pointer;
  }

  & > div.side__menu {
    width: 15%;
    height: 114px;
  }

  & > div:nth-child(2) {
    border: none;
    width: 50%;
    height: 80px;
  }

  @media only screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    
    & > div.side__menu {
      width: 100%;
      height: fit-content;
    }
    
  & > div:nth-child(2) {
    border: none;
    width: 100%;
    height: 80px;
  }
  } 
  
`;

const SettingNavMenu = ({ settingList, value, setValue }) => {
  return (
    <ul>
      {settingList.map((list) => {
        return (
          <li
            onClick={() => setValue(list.value)}
            key={list.name}
            className={list.value === value ? 'active' : ''}
          >
            {list.name}
          </li>
        );
      })}
    </ul>
  );
};

const SettingPane = (props) => {
  const { children, value, index } = props;

  return (
    <div style={{ display: value === index ? 'block' : 'none' }}>
      {value === index && children}
    </div>
  );
};

export default function Settings({ expanded, theme, setTheme }) {
  const [value, setValue] = useState(1);
  const { enqueueSnackbar } = useSnackbar();

  const settingList = [
    {
      name: 'Account',
      value: 1,
    },
    {
      name: 'Preferences',
      value: 2,
    },
    {
      name: 'Appearance',
      value: 3,
    },
    {
      name: 'Notifications',
      value: 4,
    },
  ];

  return (
    <>
      <Container expanded={expanded} theme={theme}>
        <div className="side__menu">
          <SettingNavMenu
            settingList={settingList}
            value={value}
            setValue={setValue}
          />
        </div>
        <div>
          <SettingPane value={value} index={1}>
            <Account snackBar={enqueueSnackbar} />
          </SettingPane>
          <SettingPane value={value} index={2}>
            <ContentPreferences snackBar={enqueueSnackbar} />
          </SettingPane>
          <SettingPane value={value} index={3}>
            <Apperance
              theme={theme}
              setTheme={setTheme}
              snackBar={enqueueSnackbar}
            />
          </SettingPane>
          <SettingPane value={value} index={4}>
            <Notifications snackBar={enqueueSnackbar} />
          </SettingPane>
        </div>
      </Container>
    </>
  );
}
