import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SortIcon from '@material-ui/icons/Sort';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { StateContext } from '../../../../Search/StateProviderContext/StateProviderContext';

const Wrapper = styled.div.attrs(() => ({
  id: 'sort-wrapper',
}))`
  z-index: 9;
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  background-color: ${(props) => props.theme.colors.fog};
  color: ${(props) =>
    props.isOpen
      ? props.theme.colors.almostBlack
      : props.theme.colors.altPrimary};
  border-radius: 0px;
  padding: 3px 10px;

  &:hover {
    cursor: ${(props) => (props.isOpen ? 'auto' : 'pointer')};
  }

  * {
    width: 100%;
  }
`;

const StyledSortIcon = styled(SortIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

const InputSelection = styled.div`
  font-size: 14px;

  *:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.altPrimary};
  }
`;

const SelectedSortIndexText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.altPrimary};
`;
const SortHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  &&&:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.altPrimary};
  }
`;

const AbsolutePositioner = styled.div`
  position: absolute;
  bottom: -68px;
  left: 0px;
  border-radius: 0px;
  background-color: ${(props) => props.theme.colors.fog};
  width: max-content;
  padding: 3px 10px;
`;

const SearchSort = () => {
  const [open, setOpen] = useState(false);
  // const [selectedSortIndex, setSelectedSortIndex] = useState(0);
  const [isDefaultSort, setIsDefaultSort] = useState(true);
  const { sort, setSort, sortVals } = useContext(StateContext);

  const handleSortClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsDefaultSort(sort === 0);
  }, []);

  return (
    <>
      {open ? (
        <Wrapper isOpen={open}>
          <SortHeader
            onClick={() => setOpen(false)}
            style={{ display: 'flex' }}
          >
            <StyledSortIcon /> Sort
          </SortHeader>
          <ClickAwayListener onClickAway={handleSortClick}>
            <AbsolutePositioner>
              <form style={{ display: 'flex', flexDirection: 'column' }}>
                {sortVals.map(({ displayName, sortType, direction }, index) => {
                  return (
                    <InputSelection key={displayName + direction + sortType}>
                      <label>
                        <input
                          onChange={() => {
                            setSort(index);
                            setOpen(false);
                            setIsDefaultSort(false);
                          }}
                          checked={index === sort}
                          style={{ width: 'fit-content' }}
                          type="radio"
                          name={displayName}
                        />
                        {displayName}
                      </label>
                    </InputSelection>
                  );
                })}
              </form>
            </AbsolutePositioner>
          </ClickAwayListener>
        </Wrapper>
      ) : (
        <Wrapper isOpen={open} onClick={() => setOpen(true)}>
          <SelectedSortIndexText>
            <StyledSortIcon />{' '}
            {isDefaultSort ? 'Sort' : `Sort: ${sortVals[sort].displayName}`}
          </SelectedSortIndexText>
        </Wrapper>
      )}
    </>
  );
};

export default SearchSort;
