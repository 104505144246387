import React, { useEffect, useContext, useMemo, useState } from 'react';
import bodybuilder from 'bodybuilder';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { extent, max } from 'd3-array';

import { ThemeContext } from 'styled-components';

import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { curveLinear } from '@visx/curve';
import { withParentSize } from '@visx/responsive';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { scaleTime, scaleLinear, scaleOrdinal } from '@visx/scale';

import { fetchData } from '../../../../darkblue-ui/Search/utils';

import { abbreviateNumber } from '../../../../utils/Functions/utils';

import { colorChartItem } from '../../../../darkblue-ui/styles/colors/utils.js';
import Grid from 'darkblue-ui/core/Grid/Grid';

const LegendContainer = styled(Grid)`
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 8px;
  template: 'item item item';
`;

const LineChart = ({
  xAccessor,
  yAccessor,
  data = [],
  width,
  height,
  parentWidth,
  parentHeight,
  events = {},
  currentTheme,
}) => {
  const theme = useContext(ThemeContext);
  const { onClick = () => null, onMouseOut = () => null } = events;

  const margins = { top: 10, bottom: 40, left: 20, right: 15 };
  const legendGlyphSize = 8;

  const allData = data.reduce((rec, d) => rec.concat(d.data), []);

  const ordinalColorScale = scaleOrdinal({
    domain: data.map((d) => d.key),
    range: data.map((d, idx) => colorChartItem(currentTheme, d.key, idx)),
  });

  const xScalar = useMemo(() =>
    scaleTime({
      domain: extent(allData, xAccessor),
      range: [0, parentWidth - margins.left - margins.right],
      nice: true,
    }),
  );
  const yScalar = useMemo(() =>
    scaleLinear({
      domain: [0, max(allData, yAccessor)],
      range: [parentHeight - margins.top - margins.bottom, 0],
    }),
  );

  const formatDate = (date) => {
    return dayjs(date).format('MM-DD-YYYY');
  };

  return (
    <div>
      <svg height={parentHeight} width={parentWidth}>
        <Group left={margins.left}>
          {data.map((gpData, idx) => {
            return (
              <Group key={`lines-${gpData.key}`}>
                <LinePath
                  curve={curveLinear}
                  data={gpData.data}
                  x={(d) => xScalar(xAccessor(d)) ?? 0}
                  y={(d) => yScalar(yAccessor(d)) ?? 100}
                  stroke={ordinalColorScale(gpData.key)}
                  strokeWidth={2}
                  strokeOpacity={1}
                />
              </Group>
            );
          })}
          <AxisLeft
            scale={yScalar}
            // left={margins.left}
            orientation={'left'}
            styles={{ fontSize: '8px' }}
            tickStroke={currentTheme.colors.primaryText}
            stroke={currentTheme.colors.primaryText}
            label="Hits"
            hideTicks={true}
            labelOffset={8}
            tickFormat={(t) => abbreviateNumber(t)}
            tickLabelProps={() => ({
              fill: '#000',
              fontSize: 8,
              textAnchor: 'middle',
            })}
          />
          <AxisBottom
            top={parentHeight - margins.top - margins.bottom}
            scale={xScalar}
            // left={margins.left}
            orientation={'bottom'}
            numTicks={4}
            tickFormat={formatDate}
            stroke={currentTheme.colors.primaryText}
            hideAxisLine={true}
            label="Timestamp"
            tickStroke={currentTheme.colors.primaryText}
            tickLabelProps={() => ({
              fontSize: 8,
              textAnchor: 'middle',
            })}
          />
        </Group>
      </svg>
      <LegendOrdinal
        scale={ordinalColorScale}
        labelFormat={(label) => {
          return `${String(label).toUpperCase()}`;
        }}
      >
        {(labels) => {
          return (
            <LegendContainer repeat={4}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                  <svg
                    style={{ marginRight: '3px' }}
                    width={legendGlyphSize}
                    height={legendGlyphSize}
                  >
                    <rect
                      fill={label.value}
                      width={legendGlyphSize}
                      height={legendGlyphSize}
                    />
                  </svg>
                  <LegendLabel
                    style={{ color: theme.colors.primaryText }}
                    align="left"
                    margin="0 0 0 4px"
                  >
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </LegendContainer>
          );
        }}
      </LegendOrdinal>
    </div>
  );
};

const GeoPolitical = ({ webType, apiKey, dashboardFilters }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentTheme = useContext(ThemeContext);
  const GeoPoliticalChart = withParentSize(LineChart);

  const { additionalFilters = [], filterValue, path } = webType;

  useEffect(() => {
    const fetchEntities = async () => {
      let body = bodybuilder();

      if (additionalFilters.length) {
        additionalFilters.forEach((filter) => {
          filter(body, { value: filterValue, elasticPath: path });
        });
      }

      Object.entries(dashboardFilters).forEach(([name, filter]) => {
        const { query, value, elasticPath } = filter;
        query(body, { value, elasticPath });
      });

      body
        //.andFilter('range', 'timestamp', { gte: 'now-180d', lte: 'now' })
        //.notFilter('match_phrase', 'site.net.keyword', 'Open Web')
        .aggregation(
          'terms',
          'ai.entities.geopolitical.keyword',
          { size: 10, order: { _count: 'desc' } },
          (agg) =>
            agg.aggregation('date_histogram', 'timestamp', {
              fixed_interval: '1d',
              time_zone: 'America/New_York',
            }),
          'entities',
        );
      const resp = await fetchData({
        canceled: false,
        query: body.build(),
        apiKey,
        index: ['communications', 'trends'],
      });

      const chartData = resp.aggregations.entities.buckets.map((b) => ({
        key: b.key,
        data: b.agg_date_histogram_timestamp.buckets.map((bd) => ({
          x: bd.key_as_string,
          y: bd.doc_count,
        })),
      }));

      setLoading(false);
      setData(chartData);
    };

    fetchEntities();
  }, [webType]);

  return (
    <div style={{ height: '200px', position: 'relative' }}>
      {!loading && data ? (
        <GeoPoliticalChart
          currentTheme={currentTheme}
          data={data}
          xAccessor={(d) => dayjs(d.x).toDate()}
          yAccessor={(d) => d.y}
        />
      ) : (
        <h1>Loading </h1>
      )}
    </div>
  );
};

export default GeoPolitical;
