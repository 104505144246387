import React, { useEffect, useState, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import useLocalStorage from 'utils/Hooks/SetLocalStorage';
import { UserConsumer } from './DBAuthenticator.js';

import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect, Link } from 'react-router-dom';

import darkTheme from 'darkblue-ui/styles/colors/darkTheme';
import lightTheme from 'darkblue-ui/styles/colors/lightTheme';
import GlobalThemeProvider from './darkblue-ui/styles/GlobalThemeProvider/GlobalThemeProvider';

import DarkBlue from 'layouts/DarkBlue.js';
import TOS from 'views/Tos/Tos.js';
import Auth from './Auth';

import { SnackbarProvider, useSnackbar } from 'notistack';

const hist = createBrowserHistory();

const themeOptions = {
  dark: darkTheme,
  light: lightTheme,
};

const App = ({ setUser, setAcceptedTOS, user }) => {
  const local = process.env.REACT_APP_DELPHI_ENV === 'local';
  const [theme, setTheme] = useState(
    user.attributes['custom:themePreference'] || 'light',
  );

  const swapTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  return (
    <UserConsumer>
      {({ tos }) => {
        if (!tos) {
          return (
            <ThemeProvider theme={themeOptions[theme]}>
              <GlobalThemeProvider />
              <SnackbarProvider>
                <Router history={hist}>
                  <Switch>
                    <Route
                      path="/"
                      render={() => (
                        <TOS
                          setUser={setUser}
                          setAcceptedTOS={setAcceptedTOS}
                        />
                      )}
                    />
                  </Switch>
                </Router>
              </SnackbarProvider>
            </ThemeProvider>
          );
        } else if (tos) {
          return (
            <ThemeProvider theme={themeOptions[theme]}>
              <GlobalThemeProvider />
              <SnackbarProvider>
                <Router history={hist}>
                  <Switch>
                    <Route
                      path="/"
                      render={() => (
                        <DarkBlue setTheme={swapTheme} theme={theme} />
                      )}
                    />
                  </Switch>
                </Router>
              </SnackbarProvider>
            </ThemeProvider>
          );
        }
      }}
    </UserConsumer>
  );
};

export default App;
