import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import { StateContext } from '../../../Search/StateProviderContext/StateProviderContext.js';

import UserPreviewCard from '../../../CustomRSComponents/UserPreviewCard/UserPreviewCard';
import Modal from '../../../Modals/Modal/Modal.js';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import TranslateIcon from '@material-ui/icons/Translate';

import Button from '../../../Button/Button';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Tooltip from '@material-ui/core/Tooltip';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import { tooltip } from 'assets/jss/material-dashboard-pro-react.js';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Loader from '../../../Spinners/Loader';

import Close from '@material-ui/icons/Close';

import Slide from '@material-ui/core/Slide';

import styles from 'assets/jss/material-dashboard-pro-react/modalStyle.js';

import Tag from '../../../Tag/Tag';

import { threadQuery } from '../../../../utils/Queries/CustomQueries';

import { Translator } from '../Translator';

import { useSnackbar } from 'notistack';

import { ThreadTranslator } from '../Translator';

import {
  VictoryBar,
  VictoryTheme,
  VictoryChart,
  VictoryAxis,
  VictoryContainer,
  VictoryLabel,
} from 'victory';
import { set } from 'js-cookie';

const useStyles = makeStyles({ ...styles, ...tooltip });

const ErrorMessageText = styled.p`
  color: ${(props) => props.theme.colors.danger};
`;

const ThreadVictoryContainer = styled(VictoryContainer)`
  &&& {
    height: inherit !important;
    max-width: 500px !important;
    min-width: 400px !important;
  }
`;

const ThreadCard = styled(Paper)`
  &&& {
    padding: 16px 12px;
    border-radius: 0px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.mainBackground};
    margin-bottom: 16px;
  }
`;

const ThreadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ThreadTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0px 0px 0px;
  max-height: 100px;
`;

const StatsContainer = styled(GridContainer)`
  z-index: 1000;
  position: absolute;
  background-color: ${(props) => props.theme.colors.cardBackground};
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

const ThreadTitle = styled.p`
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  align-self: center;
  color: ${(props) => props.theme.colors.white};
`;

const AuthorTitle = styled.p`
  margin: 0;
  font-weight: 500;
`;

const Timestamp = styled.p`
  margin: 0;
  opacity: 0.95;
`;

const Post = styled.p`
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: 400;

  ${(props) =>
    props.highlighted &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.primary};
    `}
`;

const CloseButton = styled(Button)`
  height: 100%;
  max-height: 41px;
`;

const ButtonGridContainer = styled(GridContainer)`
  &&& {
    position: absolute;
    width: fit-content;
    z-index: 1001;
    right: 10px;
    top: 10px;
  }
`;

const PodiumContainer = styled(Paper)`
  &&& {
    min-width: 400px;
    max-width: 600px;
    padding: 0px 45px;
    margin-bottom: 45px;
  }
`;

const ThreadContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UserDetailParagraph = styled.a`
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
`;

const TranslationButton = styled(Button)`
color: ${(props) => props.theme.colors.primary} !important;
&:hover {
  color: ${(props) => props.theme.colors.altPrimary} !important;
  cursor: pointer !important;
`;

const getTopThreeUsers = (buckets) => {
  return buckets.slice(0, 3).map((user) => {
    return { user: user.key, count: user.doc_count };
  });
};

const parseHistData = (buckets) => {
  return buckets.map((bucket) => {
    return { x: bucket.key_as_string, y: bucket.doc_count };
  });
};

const FilterableTooltip = ({
  filterState,
  filterValue,
  dataField,
  handleFilterOn,
  setFilterState,
  closeModal,
  title,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  if (filterValue) {
    return (
      <React.Fragment>
        <p style={{ textAlign: 'center' }}>{title}</p>
        <Button
          color="success"
          size="sm"
          onClick={
            filterValue
              ? () => {
                  handleFilterOn({
                    filterState: filterState,
                    filterName: filterValue,
                    filterValue: dataField,
                    onClick: setFilterState,
                    enqueueSnackbar: enqueueSnackbar,
                  });
                  closeModal();
                }
              : null
          }
        >
          <ZoomInIcon />
        </Button>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const TopThreePosterPodium = ({ users }) => {
  return (
    <PodiumContainer>
      <h3>Top Users</h3>
      {users.map((user, idx) => {
        return (
          <div key={user.user}>
            <CardBody>
              <CardIcon>
                {idx === 0 ? (
                  <LooksOneIcon fontSize="large" style={{ color: '#06a2b9' }} />
                ) : idx === 1 ? (
                  <LooksTwoIcon fontSize="large" style={{ color: '#06a2b9' }} />
                ) : (
                  <Looks3Icon fontSize="large" style={{ color: '#06a2b9' }} />
                )}
              </CardIcon>
              <GridContainer>
                <GridItem>
                  <h4>{user.user}:</h4>
                </GridItem>
                <GridItem>
                  <h4>
                    {user.count} {user.count > 1 ? ' posts' : ' post'}
                  </h4>
                </GridItem>
              </GridContainer>
            </CardBody>
          </div>
        );
      })}
    </PodiumContainer>
  );
};

const ThreadEntry = ({
  entry,
  handleFilterOn,
  closeModal,
  setFilterState,
  filterState,
  selectedUserId,
  selectedPostContent,
  previewData,
  index,
  resultStats,
}) => {
  const { timestamp, author, userId, post, postId, title, _id } = entry;
  const siteId = entry.site.id;
  const classes = useStyles();
  const [showUser, setShowUser] = useState(false);
  const [isFirstTranslation, setIsFirstTranslation] = useState(true);

  let postsArr = previewData;
  const [allPosts, setAllPosts] = useState(postsArr);
  const [translationErr, setTranslationErr] = useState('');

  return (
    <ThreadCard>
      <GridContainer alignItems="baseline" justify="space-between">
        <GridItem xs={6}>
          <GridContainer justify="flex-start" alignItems="center">
            <GridItem>
              <Tooltip
                id="Tooltip"
                classes={{ tooltip: classes.tooltip }}
                placement="top"
                interactive
                title={
                  <FilterableTooltip
                    filterValue={'AuthorFilter'}
                    filterState={filterState}
                    handleFilterOn={handleFilterOn}
                    dataField={author}
                    closeModal={closeModal}
                    setFilterState={setFilterState}
                    title="Author"
                  />
                }
              >
                <AuthorTitle>{author}</AuthorTitle>
              </Tooltip>
            </GridItem>
            <GridItem>
              <Tooltip
                id="Tooltip"
                classes={{ tooltip: classes.tooltip }}
                placement="top"
                interactive
                title={
                  <FilterableTooltip
                    filterValue={'UserIdFilter'}
                    filterState={filterState}
                    handleFilterOn={handleFilterOn}
                    dataField={userId}
                    closeModal={closeModal}
                    setFilterState={setFilterState}
                    title="User ID"
                  />
                }
              >
                <AuthorTitle>{userId}</AuthorTitle>
              </Tooltip>
            </GridItem>
            <GridItem>
              <Tooltip
                id="tooltip"
                classes={{ tooltip: classes.tooltip }}
                placement="top"
                title="Post ID"
              >
                <p>{postId}</p>
              </Tooltip>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6}>
          <GridContainer alignItems="baseline" justify="flex-end">
            <GridItem>
              <Tooltip
                id="tooltip"
                classes={{ tooltip: classes.tooltip }}
                placement="top"
                title="Timestamp"
              >
                <Timestamp>{timestamp}</Timestamp>
              </Tooltip>
            </GridItem>
            <GridItem>
              <UserDetailParagraph onClick={() => setShowUser(!showUser)}>
                {showUser ? 'Hide User Details' : 'Show User Details'}
              </UserDetailParagraph>
            </GridItem>
            <GridItem>
              {isFirstTranslation ? (
                <Tag
                  icon={<TranslateIcon fontSize="small" />}
                  key="translate"
                  onClick={async (e) => {
                    e.preventDefault();
                    const values = await Translator([
                      {
                        key: 'post',
                        value: _.get(entry, 'post'),
                      },
                    ]);
                    // if nothing changed, indicate that to the user
                    if (entry.post === values.post) {
                      setTranslationErr('Post is already in English');
                    } else {
                      postsArr = [
                        ...postsArr.slice(0, index),
                        { ...postsArr[index], ...{ post: values.post } },
                        ...postsArr.slice(index + 1),
                      ];
                      setTranslationErr('');
                      setAllPosts(postsArr);
                      setIsFirstTranslation(false);
                    }
                  }}
                  action
                >
                  Translate Record
                </Tag>
              ) : (
                <Tag
                  icon={<TranslateIcon fontSize="small" />}
                  key="translate"
                  onClick={() => {
                    postsArr = [
                      ...postsArr.slice(0, index),
                      { ...postsArr[index], ...{ post: entry.post } },
                      ...postsArr.slice(index + 1),
                    ];
                    setAllPosts(postsArr);
                    setIsFirstTranslation(true);
                  }}
                  action
                >
                  Translate Record
                </Tag>
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ display: 'flex', flexDirection: 'column' }}>
        <GridItem>
          {translationErr ? (
            <ErrorMessageText>{translationErr}</ErrorMessageText>
          ) : null}
          <Post
            highlighted={
              allPosts[index].post === selectedPostContent &&
              userId === selectedUserId
            }
          >
            {allPosts[index].post}
          </Post>
        </GridItem>
        <GridItem>
          {!isFirstTranslation ? (
            <p>
              ***Disclaimer: This record has been machine translated into
              English.***{' '}
            </p>
          ) : null}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          {showUser ? (
            <UserPreviewCard
              id={_id}
              author={author}
              userId={userId}
              siteId={siteId}
              resultStats={resultStats}
            />
          ) : null}
        </GridItem>
      </GridContainer>
    </ThreadCard>
  );
};

const ThreadModal = ({
  setShowThread,
  data,
  loading,
  aggregations,
  threadPosts,
  classes,
  handleFilterOn,
  setFilterState,
  selectedPostContent,
  userId,
  filterState,
}) => {
  const [showStats, openStats] = useState(false);
  const [isTranslated, setIsTranslated] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [translatedTitle, setTranslatedTitle] = useState('');
  const [titleTranslated, setTitleTranslated] = useState(false);
  const [titleTranslationErr, setTitleTranslationErr] = useState('');

  useEffect(() => {
    if (previewData.length === 0 && data?.hits?.hits.length > 0) {
      setPreviewData(data.hits.hits.map((d) => d._source));
    }
  }, [data]);

  const resultStats = {
    numberOfResults: data.hits?.hits.length,
    numberOfPages: 12,
  };

  return (
    <Modal
      xs={12}
      open
      onClickOutside={() => setShowThread(false)}
      style={{
        minWidth: '75%',
        maxHeight: '80%',
        padding: '0px 16px',
        overflowY: 'auto',
        justifyContent: 'center',
        backgroundColor: showStats ? 'white' : null,
      }}
    >
      {loading === false && previewData && !showStats ? (
        <ThreadContainer>
          {isTranslated === true ? (
            <p>
              ***Disclaimer: This record has been machine translated into
              English.***{' '}
            </p>
          ) : null}
          <ThreadTitleContainer>
            <ThreadTitle>
              {previewData[0]
                ? titleTranslated
                  ? translatedTitle
                  : previewData[0].title
                : 'Thread View'}
            </ThreadTitle>
            {titleTranslated ? (
              <Tag
                icon={<TranslateIcon fontSize="small" />}
                key="translate"
                onClick={() => setTitleTranslated(false)}
                style={{ marginBlock: '1em' }}
                action
              >
                Translate Title
              </Tag>
            ) : (
              <Tag
                icon={<TranslateIcon fontSize="small" />}
                key="translate"
                onClick={async () => {
                  const values = await Translator([
                    {
                      key: 'title',
                      value: _.get(previewData[0], 'title'),
                    },
                  ]);
                  if (previewData[0].title === values.title) {
                    setTitleTranslationErr('Title is already in English');
                  } else {
                    setTranslatedTitle(values.title);
                    setTitleTranslated(true);
                  }
                }}
                style={{ marginBlock: '1em' }}
                action
              >
                Translate Title
              </Tag>
            )}
            <ButtonGridContainer>
              <GridItem>
                <CloseButton onClick={() => openStats(!showStats)}>
                  {showStats ? 'Hide Stats' : 'Show Stats'}
                </CloseButton>
              </GridItem>
              <GridItem>
                <CloseButton
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => setShowThread(false)}
                >
                  <Close fontSize="large" className={classes.modalClose} />
                </CloseButton>
              </GridItem>
            </ButtonGridContainer>
          </ThreadTitleContainer>
          {titleTranslationErr ? (
            <ErrorMessageText style={{ margin: '0 auto' }}>
              {titleTranslationErr}
            </ErrorMessageText>
          ) : null}
          <ThreadTitleContainer>
            <p>{resultStats.numberOfResults} posts in thread</p>
          </ThreadTitleContainer>
          <ThreadContent>
            {previewData &&
              previewData.map((entry, idx) => {
                return (
                  <ThreadEntry
                    resultStats={resultStats}
                    handleFilterOn={handleFilterOn}
                    setFilterState={setFilterState}
                    selectedPostContent={selectedPostContent}
                    selectedUserId={userId}
                    filterState={filterState}
                    closeModal={() => setShowThread(false)}
                    previewData={previewData}
                    entry={entry}
                    key={entry.doc.idHash}
                    index={idx}
                  />
                );
              })}
          </ThreadContent>
        </ThreadContainer>
      ) : loading === false && previewData && showStats ? (
        <>
          <ButtonGridContainer>
            <GridItem>
              <CloseButton onClick={() => openStats(!showStats)}>
                {showStats ? 'Hide Stats' : 'Show Stats'}
              </CloseButton>
            </GridItem>
            <GridItem>
              <CloseButton
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setShowThread(false)}
              >
                <Close fontSize="large" className={classes.modalClose} />
              </CloseButton>
            </GridItem>
          </ButtonGridContainer>
          <GridContainer justify="center" alignItems="center">
            <GridItem>
              <h3>Thread Insights</h3>
            </GridItem>
            <GridContainer justify="space-evenly">
              {aggregations &&
              aggregations.fieldContent &&
              aggregations.fieldContent.buckets ? (
                <GridItem>
                  <TopThreePosterPodium
                    users={getTopThreeUsers(aggregations.fieldContent.buckets)}
                  />
                </GridItem>
              ) : (
                <p>Unable to determine top users</p>
              )}
              {aggregations &&
              aggregations.postHist &&
              aggregations.postHist.buckets ? (
                <VictoryChart
                  theme={VictoryTheme.material}
                  containerComponent={<ThreadVictoryContainer />}
                  padding={{
                    top: 30,
                    bottom: 120,
                    left: 60,
                    right: 80,
                  }}
                >
                  <VictoryAxis
                    theme={VictoryTheme.material}
                    renderInPortal
                    label="Time Period"
                    tickCount={8}
                    style={{
                      tickLabels: {
                        paddingBottom: 40,
                        angle: 45,
                      },
                    }}
                    padding={{ top: 30 }}
                    tickLabelComponent={
                      <VictoryLabel dy={10} textAnchor="start" />
                    }
                  />
                  <VictoryAxis
                    theme={VictoryTheme.material}
                    dependentAxis
                    label="New Posts"
                    axisLabelComponent={
                      <VictoryLabel text="New Posts" dy={-40} />
                    }
                  />
                  <VictoryBar
                    alignment="middle"
                    data={parseHistData(aggregations.postHist.buckets)}
                    y={(datum) => datum.y}
                  />
                </VictoryChart>
              ) : (
                <p>Unable to display post histograme</p>
              )}
            </GridContainer>
          </GridContainer>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loader />
          <CloseButton
            justIcon
            className={classes.modalCloseButton}
            style={{ position: 'absolute', right: '10px' }}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowThread(false)}
          >
            <Close fontSize="large" className={classes.modalClose} />
          </CloseButton>
        </div>
      )}
    </Modal>
  );
};

const ThreadModalContainer = (props) => {
  const {
    threadId,
    siteId,
    setShowThread,
    board,
    filterState,
    setFilterState,
    handleFilterOn,
    userId,
    siteNet,
    author,
    channelId,
    selectedPostContent,
  } = props;
  const classes = useStyles();
  const globalProps = props;

  const { apiUrl, apiKey } = useContext(StateContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true)
    const fetchThread = async () => {
      const resp = await fetch(`${apiUrl}/_search`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${apiKey.key}`,
        },
        method: 'POST',
        body: JSON.stringify(
          threadQuery(threadId, siteId, board, userId, channelId, siteNet),
        ),
      });

      const respData = await resp.json();
      setData(respData);
      setLoading(false);
    };

    fetchThread();
  }, []);

  const threadPosts = data?.hits?.hits.map((e) => e._source.post);

  return (
    <ThreadModal
      {...globalProps}
      {...props}
      data={data}
      loading={loading}
      aggregations={data.aggregations}
      classes={classes}
      threadPosts={threadPosts}
    />
  );
};

export default ThreadModalContainer;
