import React, { useContext } from 'react';
import styled from 'styled-components';

import Button from '../../darkblue-ui/Button/Button';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import { UserConsumer } from '../../DBAuthenticator.js';

import TosText from './TosText';

const TOSContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.mainBackground};
`;

const Tos = ({ setUser, setAcceptedTOS }) => {
  return (
    <UserConsumer>
      {(userSess) => (
        <TOSContainer>
          <GridContainer justify="center" direction="column" alignItems="center">
            <GridItem
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h1>Terms of Service Agreement</h1>
            </GridItem>
            <GridItem
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              xs={8}
            >
              <TosText />
            </GridItem>
            <GridItem
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button color="info" onClick={async () => {
                const updateSuccess = await userSess.auth.updateUserAttributes(
                  userSess.user,
                  {
                    "custom:acceptedTOS": "true",
                  },
                );
                if (updateSuccess) {
                  const attributes = await userSess.auth.userAttributes(userSess.user);
                  attributes.map((attr) => {
                    userSess.user.attributes[attr.Name] = attr.Value;
                  });
                  setUser(userSess.user);
                  setAcceptedTOS(true);
                }
              }}>
                Accept Terms of Service
              </Button>
            </GridItem>
          </GridContainer>
        </TOSContainer>
      )}
    </UserConsumer>
  );
};

export default Tos;
