const terms = [
  { path: '_id' },
  {
    path: 'doc.type',
    filterName: 'DocFilter',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
    sortKey: 1,
  },
  {
    path: 'doc.idHash',
    filterName: 'DocIdFilter',
    elasticPath: ['doc.idHash.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.pgp.armoredKeys',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.armoredKeys.keyword'],
  },
  {
    path: 'selectors.pgp.created',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
  },
  {
    path: 'selectors.pgp.fingerprints',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.fingerprints.keyword'],
  },
  {
    path: 'selectors.pgp.identities.comments',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.identities.comments.keyword'],
  },
  {
    path: 'selectors.pgp.identities.emails',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.identities.emails.keyword'],
  },
  { path: 'selectors.pgp.identities.is_primary' },
  {
    path: 'selectors.pgp.identities.names',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.identities.names.keyword'],
  },
  {
    path: 'selectors.pgp.identities.signers',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.identities.signers.keyword'],
  },
  {
    path: 'selectors.bitcoinAddresses',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SelectorBitcoinAddressesFilter',
    elasticPath: ['selectors.bitcoinAddresses.keyword'],
    filterValueType: 'array',
    sortKey: 2,
  },
  {
    path: 'selectors.emailAddresses',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SelectorEmailAddressesFilter',
    elasticPath: ['selectors.emailAddresses.keyword'],
    filterValueType: 'array',
    sortKey: 3,
  },
  {
    path: 'selectors.ipAddresses',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'IpAddressFilter',
    elasticPath: ['selectors.ipAddresses.keyword'],
    filterValueType: 'array',
    sortKey: 4,
  },
  {
    path: 'advertisementId',
    filterName: 'AdvertisementIdFilter',
    elasticPath: ['advertisementId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.phoneNumbers',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SelectorPhoneNumberFilter',
    elasticPath: ['selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.postLinks',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
  },
  {
    path: 'selectors.socialMedia',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.socialMedia.keyword'],
  },
  {
    path: 'userIds ',
    filterName: 'UserIdsFilter',
    elasticPath: ['userIds.keyword'],
    sortKey: 7,
    filterValueType: 'array',
  },
  { path: 'senderIsBuyer', elasticPath: ['senderIsBuyer.keyword'], sortKey: 8 },
  { path: 'walletId', elasticPath: ['walletId.keyword'], sortKey: 9 },
  {
    path: 'conversationId',
    filterName: 'ConversationIdFilter',
    elasticPath: ['conversationId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'message',
    elasticPah: ['message.keyword'],
    filterName: 'MessageFilter',
    filterValueType: 'array',
  },
  {
    path: 'messageId',
    filterName: 'MessageIdFilter',
    elasticPath: ['messageId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'messageType',
    filterName: 'MessageTypeFilter',
    elasticPath: ['messageType.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'recipientId',
    filterName: 'RecipientIdFilter',
    elasticPath: ['recipientId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'recipientUsername',
    elasticPath: ['recipientUsername.keyword'],
    filterValueType: 'array',
    filterName: 'RecipientUsernameFilter',
  },
  {
    path: 'senderId',
    filterName: 'SenderIdFilter',
    elasticPath: ['senderId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'senderUsername',
    filterName: 'SenderUsernameFilter',
    elasticPath: ['senderUsername.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'disputeMessage',
    filterName: 'DisputeMessageFilter',
    elasticPath: ['disputeMessage.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'advertisementMessage',
    filterName: 'AdvertisementMessageFilter',
    elasticPath: ['advertisementMessage.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'accountInfo',
    filterName: 'AccountInfoFilter',
    elasticPath: ['accountInfo.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'bankName',
    filterName: 'BankNameFilter',
    elasticPath: ['bankName.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'amount',
  },
  {
    path: 'tags',
    filterName: 'TagsFilter',
    elasticPath: ['tags.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'btcAmount',
  },
  {
    path: 'onlineProvider',
    filterName: 'OnlineProviderFilter',
    elasticPath: ['onlineProvider.keyword'],
    filterValueType: 'array',
    sortKey: 5,
  },
  {
    path: 'countryCode',
    filterName: 'CountryCodeFilter',
    elasticPath: ['countryCode.keyword'],
    filterValueType: 'array',
    sortKey: 6,
  },

  {
    path: 'currentPrice',
  },
  {
    path: 'currency',
    filterName: 'CurrencyFilter',
    elasticPath: ['currency.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'feeAmountBtc',
  },
  {
    path: 'threadId',
    filterName: 'ThreadIdFilter',
    elasticPath: ['threadId.keyword'],
    filterValueType: 'array',
  },
  { path: 'timestamp' },
  { path: 'adminReopenedAt' },
  { path: 'advertiserCancelledAt' },
  { path: 'automaticCanceledAt' },
  { path: 'buyerCanceledAt' },
  { path: 'cachedReceipt', elasticPath: ['cachedReceipt.keyword'] },
  { path: 'closedAt' },
  { path: 'contacterCancelledAt' },
  { path: 'disputeResolvedAt' },
  { path: 'disputeStartedAt' },
  { path: 'disputeStartedBuyerAt' },
  { path: 'escrowClosedAt' },
  { path: 'escrowEnabledAt' },
  { path: 'escrowFundedAt' },
  { path: 'escrowId', elasticPath: ['escrowId.keyword'] },
  { path: 'escrowReleasedAt' },
  { path: 'escrowReturnedAt' },
  { path: 'exchangeEscrowId', elasticPath: ['exchangeEscrowId.keyword'] },
  { path: 'paymentCompletedAt' },
  { path: 'verifyCode', elasticPath: ['verifyCode.keyword'] },
  { path: 'visitedAt' },
];

export { terms };
