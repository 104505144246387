import React, { useEffect } from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { notQuery } from '../../../utils/Queries/CustomQueries';

const CustomComponent = (props) => {
  useEffect(() => {
    props.setQuery({ query: notQuery(props.value), value: props.value });
  }, [props.value]);
  return null;
};

const NotFilter = (props) => {
  return (
    <ReactiveComponent
      {...props}
      URLParams={true}
      customQuery={() => notQuery(props.value)}
      render={({ setQuery }) => (
        <CustomComponent value={props.value} setQuery={setQuery} />
      )}
    />
  );
};

export default NotFilter;
