import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';

const tags = [
  {
    indexTag: true,
    singular: true,
    filterName: 'DocFilter',
    path: 'doc.type',
    tagLabel: 'Data Type',
    elasticPath: ['doc.type.keyword'],
    icon: <DescriptionIcon />,
    filterValueType: 'array',
  },
];

const title = {
  title: 'Title',
  path: 'fileName',
  filterName: 'FileNameFilter',
  elasticPath: ['fileName.keyword'],
  filterValueType: 'array',
};

const earmarks = [{ path: 'doc.processedTime' }];

const body = [
  { title: 'Document Text', path: 'text' },
  { title: 'Segment ID', path: 'doc.segmentId' },
];

const footers = [
  { title: 'File Type', path: 'doc.fileType' },
  { title: 'Source Page', path: 'sourcePage' },
];
const selectors = [
  'bitcoinAddresses',
  'emailAddresses',
  'ethereumAddresses',
  'litecoinAddresses',
  'moneroAddresses',
  'pgpKeys.armoredKeys',
  'pgpKeys.fingerprints',
  'pgpKeys.identities.names',
  'phoneNumbers',
  'socialMedia',
  'creditCards',
  'identity',
];
export { tags, earmarks, title, body, footers, selectors };
