import React from 'react';
import MessageIcon from '@material-ui/icons/Message';

const tags = [
  {
    singular: true,
    indexTag: true,
    path: 'doc.type',
    tagLabel: 'Data Type',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
    filterName: 'DocFilter',
    icon: <MessageIcon style={{ verticalAlign: 'middle' }} />,
  },
  {
    singular: true,
    path: 'messageType',
    tagLabel: 'Message Type',
    filterName: 'MessageTypeFilter',
    filterValueType: 'array',
    elasticPath: ['messageType.keyword'],
  },
  {
    singular: false,
    path: 'tags',
    tagLabel: 'Tags',
    elasticPath: ['tags.keyword'],
    filterValueType: 'array',
    filterName: 'TagsFilter',
  },
];

const earmarks = [
  {
    title: 'Timestamp',
    path: 'timestamp',
  },
];

const title = {
  title: 'Sender Username',
  path: 'senderUsername',
  redirectTo: '/search/data',
  redirectLabel: 'Data',
  filterName: 'SenderUsernameFilter',
  filterValueType: 'array',
  elasticPath: ['senderUsername.keyword'],
};

const body = [
  {
    title: 'Message',
    path: 'message',
  },
  {
    title: 'Advertisement Message',
    path: 'advertisementMessage',
    filterName: 'AdvertisementMessageFilter',
    filterValueType: 'array',
    elasticPath: ['advertisementMessage.keyword'],
  },
  {
    title: 'Recipient Username',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    path: 'recipientUsername',
    filterName: 'RecipientUsernameFilter',
    filterValueType: 'array',
    elasticPath: ['recipientUsername.keyword'],
  },
  {
    title: 'Sender Id',
    path: 'senderId',
    filterName: 'SenderIdFilter',
    filterValueType: 'array',
    elasticPath: ['senderId.keyword'],
  },
  {
    title: 'Recipient Id',
    path: 'recipientId',
    filterName: 'RecipientIdFilter',
    filterValueType: 'array',
    elasticPath: ['recipientId.keyword'],
  },
  {
    title: 'Dispute Message',
    path: 'disputeMessage',
    filterName: 'DisputeMessageFilter',
    filterValueType: 'array',
    elasticPath: ['disputeMessage.keyword'],
  },
  {
    title: 'Bank Name',
    path: 'bankName',
    filterName: 'BankNameFilter',
    filterValueType: 'array',
    elasticPath: ['bankName.keyword'],
  },
  {
    title: 'Account Info ',
    path: 'accountInfo',
    filterName: 'AccountInfoFilter',
    filterValueType: 'array',
    elasticPath: ['accountInfo.keyword'],
  },
];

const selectors = [
  'socialMedia',
  'postLinks',
  'phoneNumbers',
  'ipAddresses',
  'emailAddresses',
  'bitcoinAddresses',
];

const footers = [
  {
    title: 'BTC Amount',
    path: 'btcAmount',
  },
  {
    title: 'Amount',
    path: 'amount',
  },
  {
    title: 'Current Price',
    path: 'currentPrice',
  },
  {
    title: 'Currency',
    filterName: 'CurrencyFilter',
    elasticPath: ['currency.keyword'],
    filterValueType: 'array',
    path: 'currency',
  },
  {
    title: 'Fee Amount BTC',
    path: 'feeAmountBtc',
  },
  {
    title: 'Phone Number',
    filterName: 'SelectorPhoneNumberFilter',
    elasticPath: ['selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    path: 'selectors.phoneNumbers',
  },
  {
    title: 'Email Addresses',
    filterName: 'SelectorEmailAddressesFilter',
    elasticPath: ['selectors.emailAddresses.keyword'],
    filterValueType: 'array',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    path: 'selectors.emailAddresses',
  },
  {
    title: 'Bitcoin Addresses',
    filterName: 'SelectorBitcoinAddressesFilter',
    elasticPath: ['selectors.bitcoinAddresses.keyword'],
    filterValueType: 'array',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    path: 'selectors.bitcoinAddresses',
  },
];

export { tags, title, body, footers, earmarks, selectors };
