import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import Modal from 'darkblue-ui/Modals/Modal/Modal';
import Loader from 'darkblue-ui/Spinners/Loader';
import Button from 'darkblue-ui/Button/Button';
import Input from 'darkblue-ui/Inputs/Input';
import { changePassword, getUserInfo } from './utils';

const ModalBody = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.cardBackground};
  overflow-y: auto;
`;

export const ShowPasswordText = styled.a`
  margin-left: 19px;
  margin-right: 0px;
  margin-top: 0px;
  font-family: 'Red Hat Text', sans-serif;
  font-size: 15px;
  padding-right: 60px !important;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ErrorText = styled.p`
  color: #e63946;
  font-size: 12px;
`;

export default function ChangePasswordModal({ open, setOpen, snackBar }) {
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const formSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .min(16, 'New password is short, 16 characters minimum'),
    confirmPassword: Yup.string()
      .required('Confirm new password')
      .oneOf(
        [Yup.ref('newPassword'), null],
        'Password must match with new password',
      ),
  });

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onClickOutside={() => setOpen(false)}
    >
      <div>
        <ModalBody>
          <h1
            style={{
              fontWeight: '500',
              fontSize: '18px',
              textAlign: 'center',
            }}
          >
            Change Password
          </h1>
          <Formik
            enableReinitialize
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            }}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, validate },
            ) => {
              if (validate) {
                validate(validate);
              } else {
                try {
                  await changePassword(values.oldPassword, values.newPassword);

                  snackBar(`Password has been changed!`, {
                    variant: 'success',
                  });
                  setOpen(false);
                  resetForm();
                  setSubmitting(false);
                } catch (err) {
                  snackBar(`Password was not changed!`, {
                    variant: 'error',
                  });
                  console.log(err);
                }
              }
              // handleChangePassword(values);
            }}
            validationSchema={formSchema}
          >
            {({
              values,
              isSubmitting,
              handleBlur,
              errors,
              touched,
              handleSubmit,
              dirty,
              isValid,
              handleChange,
              resetForm,
            }) => {
              return isSubmitting ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Loader color="#2c6982" />
                </div>
              ) : (
                <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <Input
                        onBlur={handleBlur}
                        label="Old Password"
                        inputProps={{
                          type: showPassword ? 'text' : 'password',
                          onChange: handleChange,
                          value: values.oldPassword,
                          name: 'oldPassword',
                        }}
                      />
                      {dirty && errors.oldPassword && touched.oldPassword && (
                        <ErrorText>{errors.oldPassword}</ErrorText>
                      )}
                    </div>
                    <div>
                      <Input
                        onBlur={handleBlur}
                        label="New Password"
                        inputProps={{
                          type: showPassword ? 'text' : 'password',
                          onChange: handleChange,
                          value: values.newPassword,
                          name: 'newPassword',
                        }}
                      />
                      {dirty && errors.newPassword && touched.newPassword && (
                        <ErrorText>{errors.newPassword}</ErrorText>
                      )}
                    </div>
                    <div>
                      <Input
                        onBlur={handleBlur}
                        label="Confirm Password"
                        inputProps={{
                          type: showPassword ? 'text' : 'password',
                          onChange: handleChange,
                          value: values.confirmPassword,
                          name: 'confirmPassword',
                        }}
                      />
                      {dirty &&
                        errors.confirmPassword &&
                        touched.confirmPassword && (
                          <ErrorText>{errors.confirmPassword}</ErrorText>
                        )}
                    </div>
                  </div>
                  <div>
                    <ShowPasswordText onClick={handleShowPassword}>
                      {showPassword ? 'Hide Password' : 'Show Password'}
                    </ShowPasswordText>
                  </div>

                  <div>
                    <p style={{ fontSize: '14px', textAlign: 'center' }}>
                      Password must be at least 16 alphanumeric characters
                    </p>
                  </div>

                  {errors.password && (
                    <div>
                      <h4
                        style={{
                          color: errors.password ? '#f55a4e' : '#fff',
                          textAlign: 'center',
                        }}
                      >
                        {errors.password}
                      </h4>
                    </div>
                  )}
                  <div
                    style={{
                      margin: '20px 10px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      disabled={!dirty || Object.values(values).includes('')}
                      type="button"
                      style={{ padding: '10px 20px' }}
                      onClick={handleSubmit}
                    >
                      Change Password
                    </Button>
                    <Button
                      style={{
                        marginLeft: '5px',
                        padding: '8px 20px',
                      }}
                      type="reset"
                      onClick={() => {
                        setOpen(false);
                        resetForm();
                      }}
                      secondary
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </div>
    </Modal>
  );
}
