import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { UserContext } from '../../DBAuthenticator.js';

import FilterSidebar from '../../darkblue-ui/FilterSidebar/FilterSidebar';
import FilterSideBarDrawer from '../../darkblue-ui/FilterSidebar/FilterSideBarDrawer';
import FilterModal from '../../darkblue-ui/FilterModal/FilterModal';
import Button from '../../darkblue-ui/Button/Button';
import useLocalStorage from '../../utils/Hooks/SetLocalStorage';
import Loader from '../../darkblue-ui/Spinners/Loader';
import SearchNotifications from './SearchNotifications';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';

import Results from '../../darkblue-ui/Results/Results';

import {
  getFilterValues,
  getValuesForIndex,
  getFilteredNames,
} from '../../filters/utils';

import SearchPrevalence from '../../darkblue-ui/Results/ResultsWidgets/SearchPrevalence';
import StateProvider from '../../darkblue-ui/Search/StateProvider/StateProvider.js';
import Searchbar from '../../darkblue-ui/Search/Searchbar/Searchbar';

import {
  UniversalCategoryList,
  getSearchFields,
} from '../../filters/UniversalFilters';

import SaveModal from '../../darkblue-ui/Search/SaveModal';

const FilterButtonOpener = styled(Button)`
  box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, 0.02),
    0 1.1px 3.7px rgba(0, 0, 0, 0.028), 0 2.1px 6.9px rgba(0, 0, 0, 0.035),
    0 3.8px 12.3px rgba(0, 0, 0, 0.042), 0 7.1px 23px rgba(0, 0, 0, 0.05),
    0 17px 55px rgba(0, 0, 0, 0.07);
  transition: all 0.2s ease-in-out;
  float: left;
  margin-right: 5px;

  &:hover {
    -ms-transform: scale(1.1)
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

const ButtonGridContainer = styled(GridContainer)`
  max-width: fit-content;
`;

const Buffer = styled.div`
  min-width: 192px;
`;

const LogoContainer = styled.div`
  height: calc(100% - 123px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
`;

const Search = (props) => {
  const userSess = useContext(UserContext) || { user: { username: '' } };
  const { index, dataset, layoutLogo } = props;

  const searchFields = getFilterValues(index);
  const safeSearchFields = searchFields || [];
  const [searchSaved, setSearchSaved] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const filterCategories = getValuesForIndex(index, UniversalCategoryList);
  const filterNames = getFilteredNames(index);
  const [showTermsChart, setShowTermsChart] = useState(false);
  return (
    <StateProvider index={index} apiKey={userSess.apiKey}>
      {({ hits, setSort, filterState, setFilterState }) => {
        return (
          <div
            style={{
              ...(props.sideFilter ? { display: 'flex' } : {}),
              ...{ height: '100%' },
            }}
          >
            <FilterModal
              index={index}
              apiKey={userSess.apiKey}
              filterState={filterState}
              filterCategories={filterCategories}
              filterList={filterNames}
              setFilterState={setFilterState}
              filterModalOpen={filterModalOpen}
              setFilterModalOpen={setFilterModalOpen}
            />
            <SaveModal
              setSearchSaved={setSearchSaved}
              setShowSaveModal={setShowSaveModal}
              showSaveModal={showSaveModal}
              filterState={filterState}
              saveType="search"
              index={index}
              dataset={dataset}
            />
            <FilterSideBarDrawer
              expanded={props.expanded}
              open={props.sideFilter}
            >
              <FilterSidebar
                index={index}
                apiKey={userSess.apiKey}
                categories={filterCategories}
                filterList={filterNames}
                setShowFilterModal={setFilterModalOpen}
                handleFilterClose={props.toggleSideFilter}
                expanded={props.expanded}
              />
            </FilterSideBarDrawer>
            {props.sideFilter && <Buffer expanded={props.expanded} />}

            <div
              style={{
                ...(props.sideFilter ? { width: '100%' } : {}),
                paddingTop: '24px',
                height: '100%',
              }}
            >
              <div style={{ width: '100%', display: 'grid' }}>
                <SearchNotifications
                  index={[`.user_${userSess.user.username}`]}
                  apiKey={userSess.apiKey}
                />
              </div>
              <div style={{ height: '100%' }}>
                {hits.length === 0 ? (
                  <GridContainer
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                  >
                    <GridItem>
                      <LogoContainer>
                        <img
                          style={{
                            width: '25%',
                            marginTop: '20px',
                            minWidth: '350px',
                          }}
                          src={layoutLogo}
                        />
                      </LogoContainer>
                    </GridItem>
                    <GridItem style={{ height: '100%' }} xs={12}>
                      <GridContainer alignItems="center" justify="center">
                        <GridItem
                          xs={12}
                          style={{ maxWidth: '60%', minWidth: '550px' }}
                        >
                          <Searchbar
                            showTermsChart={showTermsChart}
                            setShowTermsChart={setShowTermsChart}
                            setSort={setSort}
                            elasticPath={getSearchFields(index)}
                            filterName="Searchbar"
                            sideFilterOpen={props.sideFilter}
                            toggleSideFilter={props.toggleSideFilter}
                            showSaveModal={showSaveModal}
                            setShowSaveModal={setShowSaveModal}
                          />
                          <Results hits={hits} layoutLogo={layoutLogo} />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer style={{ height: 'fit-content' }}>
                    <GridItem style={{ height: '100%' }} xs={12}>
                      <GridContainer alignItems="center" justify="center">
                        <GridItem xs={12}>
                          <Searchbar
                            showTermsChart={showTermsChart}
                            setShowTermsChart={setShowTermsChart}
                            setSort={setSort}
                            elasticPath={getSearchFields(index)}
                            filterName="Searchbar"
                            sideFilterOpen={props.sideFilter}
                            toggleSideFilter={props.toggleSideFilter}
                            showSaveModal={showSaveModal}
                            setShowSaveModal={setShowSaveModal}
                          />
                        </GridItem>
                      </GridContainer>
                      {showTermsChart && hits.length > 0 ? (
                        <>
                          <GridItem
                            xs={3}
                            padding={0}
                            style={{
                              textAlign: 'center',
                              width: '100%',
                              maxWidth: '100%',
                            }}
                          >
                            <p>Results over time</p>
                          </GridItem>
                          <SearchPrevalence
                            searchFields={safeSearchFields}
                            index={index}
                            apiKey={userSess.apiKey}
                          />
                        </>
                      ) : null}
                      <Results hits={hits} layoutLogo={layoutLogo} />
                    </GridItem>
                  </GridContainer>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </StateProvider>
  );
};

export default React.memo(Search);
