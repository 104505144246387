import React from 'react';
import styled from 'styled-components';
import paginate from 'jw-paginate';
import Button from '../Button/Button';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${(props) => props.bottom ? "50px" : "20px"};
  padding-top: ${(props) => props.bottom ? "20px" : "0px"};
`;

const Pagination = ({ page, totalHits, size, setPage, bottom}) => {
  const totalPages = Math.ceil(totalHits / size);
  const pagesArray = [1, 2, 3, 4, 5];
  const { pages, currentPage, startPage, endPage } = paginate(
    totalHits,
    page + 1,
    size,
    5,
  );

  return (
    <PaginationContainer bottom={bottom}>
      {currentPage > 1 ? (
        <Button active={false} pagination onClick={() => setPage(0)}>
          <FirstPageIcon style={{ display: 'flex' }} />
        </Button>
      ) : null}
      {pages.map((p) => {
        return (
          <Button
            active={p === currentPage}
            pagination
            key={p}
            onClick={() => setPage(p - 1)}
          >
            {p}
          </Button>
        );
      })}
      {currentPage < totalPages ? (
        <Button
          active={false}
          pagination
          onClick={() => setPage(totalPages - 1)}
        >
          <LastPageIcon style={{ display: 'flex' }} />
        </Button>
      ) : null}
    </PaginationContainer>
  );
};

export default Pagination;
