import React, { useEffect, useContext, useMemo, useState } from 'react';
import bodybuilder from 'bodybuilder';

import { ThemeContext } from 'styled-components';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { withParentSize } from '@visx/responsive';
import { AxisBottom, AxisLeft } from '@visx/axis';

import { fetchData } from '../../../../darkblue-ui/Search/utils';

import { colorChartItem } from '../../../../darkblue-ui/styles/colors/utils.js';

import { abbreviateNumber } from '../../../../utils/Functions/utils';

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const BarChart = ({
  xAccessor,
  yAccessor,
  data,
  width,
  height,
  parentWidth,
  parentHeight,
  events = {},
  currentTheme,
}) => {
  const { onClick = () => null, onMouseOut = () => null } = events;
  const margins = { top: 10, bottom: 60, left: 30, right: 15 };

  const xMax = parentWidth;
  const yMax = parentHeight;

  const chartMaxX = xMax - margins.left - margins.right;
  const chartMaxY = yMax - margins.top - margins.bottom;

  const xScalar = useMemo(
    () =>
      scaleBand({
        range: [0, chartMaxX],
        domain: data.map(xAccessor),
        padding: 0.4,
      }),
    [xMax],
  );
  const yScalar = useMemo(
    () =>
      scaleLinear({
        range: [chartMaxY, 0],
        round: true,
        domain: [0, Math.max(...data.map(yAccessor))],
      }),
    [yMax],
  );

  return (
    <svg width={xMax} height={yMax}>
      <Group top={margins.top} left={margins.left}>
        <AxisLeft
          scale={yScalar}
          styles={{ fontSize: '8px' }}
          stroke={currentTheme.colors.primaryText}
          labelOffset={10}
          label="Hits"
          rangePadding={0}
          hideTicks={true}
          tickStroke={currentTheme.colors.primaryText}
          tickFormat={(t) => abbreviateNumber(t)}
          tickLabelProps={() => ({
            fill: '#000',
            fontSize: 8,
            textAnchor: 'middle',
          })}
        />
        <AxisBottom
          top={parentHeight - margins.top - margins.bottom}
          scale={xScalar}
          stroke={currentTheme.colors.primaryText}
          hideAxisLine={true}
          label="Content Tags"
          tickFormat={capitalize}
          tickStroke={currentTheme.colors.primaryText}
          tickLabelProps={() => ({
            fontSize: 8,
            textAnchor: 'middle',
          })}
        />
        {data.map((d, idx) => {
          const xValue = xAccessor(d);
          const yValue = yAccessor(d);

          const barWidth = xScalar.bandwidth();
          const barHeight =
            yMax - margins.top - margins.bottom - yScalar(yValue) || 0;

          const fill = colorChartItem(currentTheme, d, idx);

          return (
            <Bar
              key={`bar-${xValue}`}
              x={xScalar(xValue)}
              y={yMax - margins.top - margins.bottom - barHeight}
              width={barWidth}
              height={barHeight}
              fill={fill}
              onClick={(e) => onClick(d, e)}
              onMouseOut={(e) => onMouseOut(d, e)}
            />
          );
        })}
      </Group>
    </svg>
  );
};

const TopContentTags = ({ apiKey, webType, dashboardFilters }) => {
  const [data, setData] = useState([]);
  const currentTheme = useContext(ThemeContext);
  const ContentTagsChart = withParentSize(BarChart);

  useEffect(() => {
    const fetchTags = async () => {
      let body = bodybuilder();
      body
        .andFilter('range', 'timestamp', { gte: 'now-90d', lte: 'now' })
        .aggregation(
          'terms',
          'ai.content.tags.keyword',
          { size: 10 },
          'content_tags',
        );
      const resp = await fetchData({
        canceled: false,
        query: body.build(),
        apiKey,
        index: ['sites'],
      });

      const chartData = resp.aggregations.content_tags.buckets.map((b) => ({
        x: b.key,
        y: b['doc_count'],
      }));

      setData(chartData);
    };

    fetchTags();
  }, [dashboardFilters]);

  return (
    <div style={{ height: '200px' }}>
      <ContentTagsChart
        currentTheme={currentTheme}
        data={data}
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
      />
    </div>
  );
};

export default TopContentTags;
