const terms = [
  { path: '_id' },
  {
    path: 'doc.type',
    filterName: 'DocFilter',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
    sortKey: 1,
  },
  {
    path: 'doc.idHash',
    filterName: 'DocIdFilter',
    elasticPath: ['doc.idHash.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'accountInfo',
    filterName: 'AccountInfoFilter',
    elasticPath: ['accountInfo.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'advertisementId',
    filterName: 'AdvertisementIdFilter',
    elasticPath: ['advertisementId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'advertismentType',
    filterName: 'AdvertismentTypeFilter',
    elasticPath: ['advertismentType.keyword'],
    filterValueType: 'array',
    sortKey: 2,
  },
  {
    path: 'bankName',
    filterName: 'BankNameFilter',
    elasticPath: ['bankName.keyword'],
    filterValueType: 'array',
    sortKey: 3,
  },
  {
    path: 'city',
    filterName: 'CityFilter',
    elasticPath: ['city.keyword'],
    filterValueType: 'array',
  },
  { path: 'createdAt' },
  { path: 'closedAt' },
  {
    path: 'country',
    filterName: 'CountryFilter',
    elasticPath: ['country.keyword'],
    filterValueType: 'array',
    sortKey: 4,
  },
  {
    path: 'currency',
    filterName: 'CurrencyFilter',
    elasticPath: ['currency.keyword'],
    filterValueType: 'array',
    sortKey: 5,
  },
  {
    path: 'exchangeCity',
    sortKey: 6,
    filterName: 'ExchangeCityFilter',
    elasticPath: ['exchangeCity.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'exchangeCountry',
    sortKey: 7,
    filterName: 'ExchangeCountryFilter',
    elasticPath: ['exchangeCountry.keyword'],
  },
  { path: 'escrowMessages', sortKey: 8 },
  { path: 'exchangeContactMessages', sortKey: 9 },
  { path: 'exchangeMessages', sortKey: 10 },
  { path: 'messages', sortKey: 11 },
  {
    path: 'selectors.locations',
    elasticPath: ['selectors.locations.keyword'],
    sortKey: 12,
  },
  {
    path: 'tempPrice',
  },
  {
    path: 'tweeted',
  },
  {
    path: 'tempPriceUsd',
  },
  {
    path: 'updatedAt',
  },
  {
    path: 'maxAmount',
  },
  { path: 'timestamp' },
  {
    path: 'requireFeedbackScore',
  },
  {
    path: 'locationId',
    filterName: 'LocationIdFilter',
    elasticPath: ['locationId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'locationText',
    sortKey: 13,
    filterName: 'LocationTextFilter',
    elasticPath: ['locationText.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'message',
    filterName: 'MessageFilter',
    elasticPath: ['message.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'onlineProvider',
    filterName: 'OnlineProviderFilter',
    elasticPath: ['onlineProvider.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'requirements',
    filterName: 'RequirementsFilter',
    elasticPath: ['requirements.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.emailAddresses',
    filterName: 'SelectorEmailAddressesFilter',
    elasticPath: ['selectors.emailAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.phoneNumbers',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SelectorPhoneNumberFilter',
    elasticPath: ['selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.postLinks',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'PostLinksFilter',
    elasticPath: ['selectors.postLinks.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.socialMedia',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SocialMediaFilter',
    elasticPath: ['selectors.socialMedia.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'tradeType',
    filterName: 'TradeTypeFilter',
    elasticPath: ['tradeType.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.pgp.armoredKeys',
    elasticPath: ['selectors.pgp.armoredKeys.keyword'],
  },
  {
    path: 'selectors.pgp.created',
  },
  {
    path: 'selectors.pgp.fingerprints',
    elasticPath: ['selectors.pgp.fingerprints.keyword'],
  },
  {
    path: 'selectors.pgp.identities.comments',
    elasticPath: ['selectors.pgp.identities.comments.keyword'],
  },
  {
    path: 'selectors.pgp.identities.emails',
    elasticPath: ['selectors.pgp.identities.emails.keyword'],
  },
  {
    path: 'selectors.pgp.emails',
    elasticPath: ['selectors.pgp.emails.keyword'],
  },
  {
    path: 'selectors.identities.names',
    elasticPath: ['selectors.pgp.identities.names.keyword'],
  },
  {
    path: 'selectors.identities.signers',
    elasticPath: ['selectors.pgp.identities.signers.keyword'],
  },
  {
    path: 'type',
    filterName: 'TypeFilter',
    elasticPath: ['type.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'userId',
    filterName: 'UserIdFilter',
    elasticPath: ['userId.keyword'],
    filterName: 'UserIdFilter',
    filterValueType: 'array',
  },
  {
    path: 'username',
    filterName: 'UserNameFilter',
    elasticPath: ['username.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'zipcode',
    filterName: 'ZipCodeFilter',
    elasticPath: ['zipcode.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'text_content.en',
    elasticPath: ['text_content.en.keyword'],
  },
  {
    path: 'text_content.es',
    elasticPath: ['text_content.es.keyword'],
  },
  {
    path: 'text_content.pt',
    elasticPath: ['text_content.pt.keyword'],
  },
  {
    path: 'text_content.ar',
    elasticPath: ['text_content.ar.keyword'],
  },
  {
    path: 'text_content.fa',
    elasticPath: ['text_content.fa.keyword'],
  },
  {
    path: 'text_content.ko',
    elasticPath: ['text_content.ko.keyword'],
  },
  {
    path: 'text_content.ru',
    elasticPath: ['text_content.ru.keyword'],
  },
  {
    path: 'text_content.tr',
    elasticPath: ['text_content.tr.keyword'],
  },
  {
    path: 'text_content.zh',
    elasticPath: ['text_content.zh.keyword'],
  },
  { path: 'exchangeLocation.lat' },
  { path: 'exchangeLocation.lon' },
  { path: 'location.lat' },
  { path: 'location.lon' },
  { path: 'riskLevel' },
];

export { terms };
