import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { UserContext } from '../../DBAuthenticator.js';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import StateProvider from '../../darkblue-ui/Search/StateProvider/StateProvider.js';
import Searchbar from '../../darkblue-ui/Search/Searchbar/Searchbar';
import TableResults from '../../darkblue-ui/Results/TableResults.js';
import FilterSidebar from '../../darkblue-ui/FilterSidebar/FilterSidebar';
import FilterSideBarDrawer from '../../darkblue-ui/FilterSidebar/FilterSideBarDrawer';
import { getValuesForIndex, getFilteredNames } from '../../filters/utils';

import { UniversalCategoryList } from '../../filters/UniversalFilters';

const LogoContainer = styled.div`
  height: calc(100% - 123px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
`;

const Buffer = styled.div`
  min-width: 192px;
`;

const Datasets = (props) => {
  const {
    index,
    layoutLogo,
    sideFilter,
    expanded,
    disableSort = false,
    disableGraph = false,
  } = props;
  const [rowArr, setRowArr] = useState([]);
  const filterNames = getFilteredNames(index);
  const userSess = useContext(UserContext) || { user: { username: '' } };
  const categories = getValuesForIndex(index, UniversalCategoryList);
  const searchFields = ['*'];
  return (
    <StateProvider index={index} apiKey={userSess.apiKey}>
      {({
        hits,
        filterState,
        setFilterState,
        loading,
        page,
        setPage,
        size,
        totalHits,
      }) => {
        return (
          <div
            style={{
              ...(props.sideFilter ? { width: '100%' } : {}),
              height: 'fit-content',
              ...(Object.values(filterState).length > 0
                ? { overflowX: 'hidden' }
                : {}),
              display: 'flex',
            }}
          >
            <FilterSideBarDrawer expanded={expanded} open={sideFilter}>
              <FilterSidebar
                index={index}
                dateTimePickerPath="doc.extracted"
                apiKey={userSess.apiKey}
                categories={categories}
                filterList={filterNames}
                handleFilterClose={props.toggleSideFilter}
                expanded={props.expanded}
                hideExpanded={true}
              />
            </FilterSideBarDrawer>
            {props.sideFilter && <Buffer expanded={props.expanded} />}

            <div
              style={{
                height: '100%',
                width: '100%',
                padding: '1rem',
                paddingTop: '40px',
              }}
            >
              {hits.length === 0 && Object.entries(filterState).length === 0 ? (
                <GridContainer
                  alignItems="center"
                  justify="center"
                  style={{ height: '100%' }}
                >
                  <GridItem>
                    <LogoContainer>
                      <img
                        style={{
                          width: '25%',
                          marginTop: '20px',
                          minWidth: '350px',
                        }}
                        src={layoutLogo}
                      />
                    </LogoContainer>
                  </GridItem>
                  <GridItem style={{ height: '100%' }} xs={12}>
                    <GridContainer alignItems="center" justify="center">
                      <GridItem
                        xs={12}
                        style={{ maxWidth: '60%', minWidth: '550px' }}
                      >
                        <Searchbar
                          showTermsChart={false}
                          elasticPath={searchFields}
                          filterName="Searchbar"
                          sideFilterOpen={props.sideFilter}
                          toggleSideFilter={props.toggleSideFilter}
                          showAdvFeatures={false}
                          disableSort={disableSort}
                          disableGraph={disableGraph}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              ) : props.sideFilter ? (
                <>
                  <GridContainer
                    style={{
                      height: 'fit-content',
                      width: 'calc(100% - 192px - 15px)',
                      maxWidth: 'calc(100% - 192px - 15px)',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <Searchbar
                        showTermsChart={false}
                        elasticPath={searchFields}
                        filterName="Searchbar"
                        showAdvFeatures={false}
                        sideFilterOpen={props.sideFilter}
                        toggleSideFilter={props.toggleSideFilter}
                        disableSort={disableSort}
                        disableGraph={disableGraph}
                      />
                    </div>
                    <div style={{ overflow: 'hidden', width: '100%' }}>
                      <TableResults
                        filterState={filterState}
                        setFilterState={setFilterState}
                        hits={hits}
                        layoutLogo={layoutLogo}
                        rowArr={rowArr}
                        setRowArr={setRowArr}
                        loading={loading}
                        page={page}
                        setPage={setPage}
                        size={size}
                        totalHits={totalHits}
                      />
                    </div>
                  </GridContainer>
                </>
              ) : (
                <>
                  <GridContainer
                    style={{
                      height: 'fit-content',
                      width: 'calc(100% - 15px)',
                      maxWidth: '100%',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <Searchbar
                        showTermsChart={false}
                        elasticPath={searchFields}
                        filterName="Searchbar"
                        showAdvFeatures={false}
                        sideFilterOpen={props.sideFilter}
                        toggleSideFilter={props.toggleSideFilter}
                        disableSort={disableSort}
                        disableGraph={disableGraph}
                      />
                    </div>
                    <div style={{ overflow: 'hidden', width: '100%' }}>
                      <TableResults
                        filterState={filterState}
                        setFilterState={setFilterState}
                        hits={hits}
                        layoutLogo={layoutLogo}
                        rowArr={rowArr}
                        setRowArr={setRowArr}
                        loading={loading}
                        page={page}
                        setPage={setPage}
                        size={size}
                        totalHits={totalHits}
                      />
                    </div>
                  </GridContainer>
                </>
              )}
            </div>
          </div>
        );
      }}
    </StateProvider>
  );
};

export default Datasets;
