import React from 'react';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import { searchbarSearchQuery } from '../../darkblue-ui/Search/utils.js';

const tags = [
  {
    indexTag: true,
    singular: true,
    filterName: 'DocFilter',
    path: 'doc.type',
    tagLabel: 'Data Type',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
    icon: <StoreMallDirectoryIcon />,
  },
  {
    singular: true,
    path: 'site.net',
    tagLabel: 'Site Net',
    elasticPath: ['site.net.keyword'],
    filterName: 'SiteNetFilter',
    filterValueType: 'array',
    id: 'site-net-id',
  },
  {
    singular: true,
    path: 'site.type',
    filterName: 'SiteTypeFilter',
    tagLabel: 'Site Type',
    elasticPath: ['site.type.keyword'],
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.name',
    toolTipInfo: 'Site Name',
    filterName: 'SiteNameFilter',
    tagLabel: 'Site Name',
    elasticPath: ['site.name.keyword'],
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'board',
    toolTipInfo: 'Board',
    filterName: 'BoardFilter',
    tagLabel: 'Board',
    elasticPath: ['board.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.tags',
    tagPointer: 'ai.content',
    tagLabel: 'Content Tags',
    elasticPath: ['ai.content.tags.keyword'],
    filterName: 'ContentTagsFilter',
    filterValueType: 'array',
  },
  {
    path: 'ai.language',
    elasticPath: ['ai.language.keyword'],
    tagLabel: 'Language',
    filterName: 'LanguageFilter',
    filterValueType: 'array',
  },
  {
    descriptionPath: 'ai.usper.explanation',
    tagText: 'Potential USPER',
    path: 'ai.usper.potential',
    tagLabel: 'US Person',
    filterName: 'UsperFilter',
    elasticPath: ['ai.usper.potential'],
    filterValueType: 'array',
  },
];

const earmarks = [{ path: 'timestamp' }];

const title = {
  title: 'Post Title',
  path: 'title',
  filterName: 'TitleFilter',
  elasticPath: ['title.keyword'],
  filterValueType: 'array',
};

const body = [
  { title: 'Post Contents', path: 'post' },
  {
    title: 'Author',
    path: 'author',
    elasticPath: ['author.keyword'],
    filterName: 'AuthorFilter',
    filterValueType: 'array',
  },
];

const footers = [
  {
    title: 'Hidden Service/ Page URL',
    path: 'page',
    elasticPath: ['page.keyword'],
    filterName: 'PageFilter',
    tooltipValueType: 'string',
    filterValueType: 'array',
  },
  {
    title: 'Thread ID',
    path: 'threadId',
    filterName: 'ThreadIDFilter',
    elasticPath: ['threadId.keyword'],
    filterValueType: 'array',
  },
  {
    title: 'Post ID',
    path: 'postId',
    filterName: 'PostIDFilter',
    elasticPath: ['postId.keyword'],
    filterValueType: 'array',
  },
];

const selectors = [
  'analyticsIDs',
  'apacheServices',
  'bitcoinAddresses',
  'bitcoinSignature',
  'emailAddresses',
  'hashtags',
  'images',
  'ipAddresses',
  'likes',
  'pgp.fingerprints',
  'pgp.identities.emails',
  'pgp.identities.is_primary',
  'pgp.identities.names',
  'phoneNumbers',
  'ethereumAddresses',
  'litecoinAddresses',
  'moneroAddresses',
  'pgpKeys',
  'phone',
  'phoneNumbers',
  'ssh',
  'socialMedia',
  'vendorBitcoinAddress',
  'vendorPgp',
  'creditCards',
  'identity',
];

export { tags, earmarks, title, body, footers, selectors };
