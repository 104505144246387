import React, { useState } from 'react';
import {
  ReactiveList,
  DataSearch,
  MultiDropdownList,
} from '@appbaseio/reactivesearch';
import _ from 'lodash';

import styled from 'styled-components';
import Cluster from '../Elastic/Cluster';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { getScansTableData } from '../../utils/Functions/utils';
import ReactTable from '../../darkblue-ui/Table/ReactTable';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Pagination from '../../components/Pagination/Pagination';
import SearchBar from '../../darkblue-ui/CustomRSComponents/SearchBar';
import CalendarFilter from '../../darkblue-ui/CustomRSComponents/Filters/CalendarFilter/CalendarFilter';
import {
  databaseQuery,
  scansQuery,
  intFieldQuery,
} from '../../utils/Queries/CustomQueries';
import Loader from '../../darkblue-ui/Spinners/Loader';

import { UniversalFieldNameList } from '../../filters/UniversalTableFilters';
import * as ScanTerms from '../../darkblue-consts/DataDictionary/Scans.js';
import { getFilterValues } from '../../filters/utils';

const getColumnNames = (idx) => {
  switch (idx) {
    case 'scans':
      return ScanTerms.terms;
    case 'db_*':
      return [];
  }
};

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const removeExtraFields = (data) => {
  return data.map(
    ({
      highlight,
      _index,
      _type,
      _id,
      _score,
      _click_id,
      sort,
      proxy,
      ...rest
    }) => rest,
  );
};

const IndexHeader = styled.h2`
  color: ${(props) => props.theme.colors.cardTitle};
`;

const ResultsText = styled.p`
  color: ${(props) => props.theme.colors.cardTitle};
`;

const ThemedDropdown = styled(MultiDropdownList)`
  border: 1px solid ${(props) => props.theme.colors.altPrimaryOpacity};
  background-color: #deecfa;
  button {
    min-height: 34px;
    border: none;
  }

  * {
    color: ${(props) => props.theme.colors.almostBlack};
  }
`;

const StyledGridItem = styled(GridItem)`
  display: flex;
  align-items: center;

  div {
    width: 100%;
    div {
      border: none !important;
    }
  }
`;

const TableSearch = (props) => {
  const { index, showCalendar, defaultVisibleColumns } = props;
  const today = new Date();
  const p7 = new Date();
  p7.setDate(today.getDate() - 180);
  const [currentPage, setPage] = useState(0);
  const [rowArr, setRowArr] = useState([]);

  const [filterState, setFilterState] = useState({
    SearchboxComponent: '',
    TimeFilter: { start: p7, end: today },
    columnFilterState: {},
  });

  const [defaultColumnsList, setDefaultColumnsList] = useState(
    getColumnNames(index),
  );

  const searchFields = getFilterValues(
    [props.indexName.toLocaleLowerCase()],
    UniversalFieldNameList,
  );

  return (
    <>
      <Cluster index={index}>
        <GridContainer
          style={{ padding: '48px 15px 0px 15px' }}
          direction="column"
        >
          <GridContainer justify="space-between" style={{ flexWrap: 'nowrap' }}>
            <div style={{ padding: '0px 15px', width: '100%' }}>
              <SearchBar
                filterState={filterState}
                setFilterState={setFilterState}
                showAdvFeatures={false}
                safeSearchFields={['*']}
              />
            </div>
            {showCalendar ? (
              <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <CalendarFilter
                  filterState={filterState}
                  setFilterState={setFilterState}
                />
              </GridItem>
            ) : null}
            {index === 'db_*' ? (
              <StyledGridItem xs={4}>
                <ThemedDropdown
                  componentId="Database Filter"
                  placeholder="Select a Database"
                  dataField="doc.dataset.keyword"
                  showFilter={false}
                  size={500}
                />
              </StyledGridItem>
            ) : null}
          </GridContainer>
          {Object.entries(filterState.columnFilterState).map(
            ([filterName, filterValue]) => {
              const checkOnlyNumbers = new RegExp('^[0-9]*$');
              if (
                typeof parseInt(filterValue) === 'number' &&
                checkOnlyNumbers.test(filterValue)
              ) {
                return (
                  <DataSearch
                    key={filterName}
                    componentId={filterName}
                    dataField={filterName}
                    customQuery={() => intFieldQuery(filterName, filterValue)}
                    style={{ maxHeight: '0px', visibility: 'hidden' }}
                    filterLabel={filterName}
                    autosuggest={false}
                    URLParams={true}
                    queryFormat={'and'}
                    value={
                      !_.isEqual(filterValue, {}) && filterValue
                        ? filterValue
                        : ''
                    }
                  />
                );
              } else {
                return (
                  <DataSearch
                    key={filterName}
                    componentId={filterName}
                    dataField={filterName}
                    style={{ maxHeight: '0px', visibility: 'hidden' }}
                    filterLabel={filterName}
                    autosuggest={false}
                    URLParams={true}
                    queryFormat={'and'}
                    value={
                      !_.isEqual(filterValue, {}) && filterValue
                        ? filterValue
                        : ''
                    }
                  />
                );
              }
            },
          )}
          <GridItem
            style={{
              overflowX: 'hidden',
            }}
            xs={12}
            overrideClasses
          >
            <ReactiveList
              componentId="TableComponent"
              dataField="doc.idHash"
              react={{
                and: [
                  ...[
                    'ColumnFilter',
                    'SearchboxComponent',
                    'TimeFilter',
                    'Database Filter',
                  ],
                  ...Object.keys(filterState.columnFilterState),
                ],
              }}
              defaultQuery={() =>
                index === 'scans'
                  ? scansQuery(currentPage)
                  : databaseQuery(currentPage)
              }
              paginationAt="bottom"
              pagination={true}
              renderNoResults={function() {
                return <ResultsText>No results found</ResultsText>;
              }}
              renderResultStats={function(stats) {
                return (
                  <ResultsText>
                    {`Showing ${numberWithCommas(
                      stats.displayedResults,
                    )} of total ${numberWithCommas(stats.numberOfResults)}${
                      stats.numberOfResults >= 10000 ? '+' : ''
                    }`}
                  </ResultsText>
                );
              }}
              renderPagination={({
                pages,
                totalPages,
                currentPage,
                setPage,
              }) => {
                if (Number.isFinite(totalPages)) {
                  let paginationOptions = [];

                  if (currentPage !== 0) {
                    paginationOptions.push({
                      text: <p>{'<<'}</p>,
                      onClick: () => {
                        setPage(0);
                        if (rowArr && rowArr.length) {
                          setRowArr([]);
                        }
                      },
                    });
                  }

                  if (currentPage >= pages) {
                    paginationOptions.push({
                      text: (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '8px',
                          }}
                        >
                          <ChevronLeftIcon />
                          <p className="s no-margin">Prev</p>
                        </div>
                      ),
                      onClick: () => setPage(currentPage - 1),
                    });
                  }
                  for (
                    let i = currentPage - 4;
                    i < currentPage + pages && i < totalPages;
                    i++
                  ) {
                    if (i >= 0) {
                      paginationOptions.push({
                        text: <p>{i + 1}</p>,
                        onClick: () => setPage(i),
                        active: i === currentPage,
                      });
                    }
                  }
                  if (currentPage + pages <= totalPages) {
                    paginationOptions.push({
                      text: (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '8px',
                          }}
                        >
                          <p className="s no-margin">NEXT</p>
                          <ChevronRightIcon />
                        </div>
                      ),
                      onClick: () => setPage(currentPage + 1),
                    });
                  }

                  if (currentPage !== totalPages - 1 && totalPages > 0) {
                    paginationOptions.push({
                      text: <p>{'>>'}</p>,
                      onClick: () => {
                        setPage(totalPages - 1);
                        if (rowArr && rowArr.length) {
                          setRowArr([]);
                        }
                      },
                    });
                  }
                  return (
                    <GridContainer justify="center" alignItems="center">
                      <GridItem>
                        {paginationOptions[0] ? (
                          <Pagination
                            rowArr={rowArr}
                            setRowArr={setRowArr}
                            pages={paginationOptions}
                          />
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  );
                }
              }}
            >
              {(props) => {
                const { data, loading, resultStats } = props;
                const { numberOfPages, numberOfResults, time } = resultStats;
                const cleanData = removeExtraFields(data);
                const tableData = getScansTableData(cleanData);
                return (
                  <div>
                    {!loading && Number.isFinite(numberOfPages) ? (
                      <>
                        <ReactTable
                          defaultVisibleColumns={defaultVisibleColumns}
                          filterState={filterState}
                          setFilterState={setFilterState}
                          data={tableData}
                          columns={searchFields}
                          index={index}
                          defaultColumnsList={defaultColumnsList}
                          setDefaultColumnsList={setDefaultColumnsList}
                          rowArr={rowArr}
                          setRowArr={setRowArr}
                        />
                      </>
                    ) : (
                      <GridContainer justify="center" alignItems="center">
                        <GridItem>
                          <Loader />
                        </GridItem>
                      </GridContainer>
                    )}
                  </div>
                );
              }}
            </ReactiveList>
          </GridItem>
        </GridContainer>
      </Cluster>
    </>
  );
};

TableSearch.defaultProps = {
  showDropDownFilter: true,
};

export default TableSearch;
