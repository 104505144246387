import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Storage } from '../../Auth.js';

const StyledDocument = styled(Document)`
  max-width: 95%;
`;

const StyledPage = styled(Page)`
  overflow-x: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.h3`
  max-width: 70%;
  text-align: center;
`;

const MarkdownContainer = styled.div`
  padding: 24px;
  border-radius: 0px;
  box-shadow: 0 8px 16px 0 rgba(7, 29, 54, 0.15);

  & h1 {
    margin: 0px;
  }
`;

const DocumentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 95%;
`;

const PrevPage = styled(KeyboardArrowLeftIcon)`
  visibility: ${(props) => (props.hide ? 'hidden' : 'inherit')};
  color: ${(props) => props.theme.colors.primary};
  &&& {
    height: 100%;
    background-color: ${(props) => props.theme.colors.fog};

    &:hover {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

const NextPage = styled(KeyboardArrowRightIcon)`
  visibility: ${(props) => (props.hide ? 'hidden' : 'inherit')};
  color: ${(props) => props.theme.colors.primary};
  &&& {
    height: 100%;
    background-color: ${(props) => props.theme.colors.fog};

    &:hover {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.white};
  }
`;
const Back = styled.div`
  color: ${(props) => props.theme.colors.primary};
  min-width: fit-content;
  display: flex;
  height: fit-content;
  margin-right: -24px;
  & h3 {
    margin: 0px;
    color: ${(props) => props.theme.colors.primary};
  }
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const ThemedGetAppIcon = styled(GetAppIcon)`
  color: ${(props) => props.theme.colors.primary} !important;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const DownloadLink = styled.a`
  visibility: ${(props) => (props.hide ? 'hidden' : 'inherit')};
`;

const FileViewer = ({ doc, setDoc }) => {
  const { location, title, doc_type } = doc;
  const [content, setContent] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  useEffect(() => {
    const loadFile = async () => {
      if (doc_type === 'markdown') {
        const signedURL = await Storage.get(location);
        const response = await fetch(signedURL);
        const docContent = await response.text();
        setContent(docContent);
      } else {
        const signedURL = await Storage.get(location);
        setContent(signedURL);
      }
    };

    loadFile();
  }, [title]);

  return (
    <Container>
      <TitleContainer>
        <Back onClick={() => setDoc()}>
          <KeyboardArrowLeftIcon /> <h3>Back</h3>
        </Back>
        <TitleText>{title}</TitleText>
        <DownloadLink hide={doc_type === 'markdown'} href={content} download>
          <ThemedGetAppIcon />
        </DownloadLink>
      </TitleContainer>

      {doc_type === 'markdown' ? (
        <MarkdownContainer>
          <ReactMarkdown escapeHtml={false} source={content} />
        </MarkdownContainer>
      ) : (
        <DocumentContainer>
          {numPages ? (
            <PrevPage hide={pageNumber === 1} onClick={previousPage} />
          ) : null}
          <StyledDocument onLoadSuccess={onDocumentLoadSuccess} file={content}>
            <StyledPage pageNumber={pageNumber} />
          </StyledDocument>

          {numPages ? (
            <NextPage hide={pageNumber + 1 > numPages} onClick={nextPage} />
          ) : null}
        </DocumentContainer>
      )}
    </Container>
  );
};

export default FileViewer;
