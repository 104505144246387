import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  ReactiveComponent,
  MultiDropdownList,
} from '@appbaseio/reactivesearch';
import Cluster from '../Elastic/Cluster.js';

import { UserConsumer } from '../../DBAuthenticator.js';
import ResourcesSidebar from './Sidebar.js';
import ResourceCard from './ResourceCard.js';
import CustomSelectedFilters from '../../components/CustomSelectedFilters/CustomSelectedFilters.js';
import FileViewer from './FileViewer.js';
import SearchBar from '../../darkblue-ui/CustomRSComponents/SearchBar.js';
import Auth, { Storage } from '../../Auth.js';
import { resourcesQuery } from '../../utils/Queries/CustomQueries.js';

const Container = styled.div`
  display: flex;
  transform: translateX(-50px);
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  min-width: 70%;
  padding-left: 18px;
  margin: 0 auto;
  padding-top: 70px;
  overflow-x: hidden;
`;

const StyledMarkdown = styled.div`
  & > h1 {
    color: red;
  }

  & > p {
    font-size: 12px;
  }
`;

const HiddenDiv = styled.div`
  visibility: hidden;
  max-height: 0px;
  max-width: 0px;
`;

const Resources = (props) => {
  const { expanded } = props;
  const [doc, setDoc] = useState();
  const [filterState, setFilterState] = useState({
    SearchboxComponent: '',
    Tags: [],
  });

  const filters = [
    <MultiDropdownList
      componentId="Tags"
      size={10000}
      key="Tags"
      dataField="tags.keyword"
      label="Tags"
      queryFormat="and"
      value={filterState.Tags}
    />,
  ];

  return (
    <UserConsumer>
      {({ storage }) => {
        return (
          <Cluster index="resources">
            <HiddenDiv>{filters.map((f) => f)}</HiddenDiv>
            <Container>
              <ResourcesSidebar
                expanded={expanded}
                filterState={filterState}
                setFilterState={setFilterState}
                setDoc={setDoc}
              />

              <SearchContainer>
                {doc ? (
                  <FileViewer doc={doc} setDoc={setDoc} />
                ) : (
                  <>
                    <SearchBar
                      filterState={filterState}
                      setFilterState={setFilterState}
                      componentId="SearchboxComponent"
                      showAdvFeatures={false}
                      queryTypeAnd={false}
                      safeSearchFields={[
                        'category',
                        'short_description',
                        'title',
                      ]}
                    />
                    <CustomSelectedFilters
                      filterState={filterState}
                      setFilterState={setFilterState}
                      index="resources"
                      filters={filters}
                      showClearFilters={false}
                    />
                    <ReactiveComponent
                      react={{ and: ['SearchboxComponent', 'Tags'] }}
                      size={50}
                      defaultQuery={resourcesQuery}
                      customQuery={resourcesQuery}
                      componentId="SearchResults"
                      dataField="sortOrder"
                      sortBy="desc"
                      render={({ loading, data }) => {
                        data.sort((a, b) => {
                          if (a.title < b.title) {
                            return -1;
                          }
                          if (a.title > b.title) {
                            return 1;
                          }
                          return 0;
                        });
                        return data.map((d) => {
                          return (
                            <ResourceCard
                              filterState={filterState}
                              setFilterState={setFilterState}
                              setDoc={setDoc}
                              key={d._id}
                              data={d}
                            />
                          );
                        });
                      }}
                    />
                  </>
                )}
              </SearchContainer>
            </Container>
          </Cluster>
        );
      }}
    </UserConsumer>
  );
};

export default Resources;
