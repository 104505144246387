import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { ipColumns, adIdColumns, datasetColumns } from './PopupColumns';
import _ from 'lodash';
import styled from 'styled-components';
import { getRandomHexColor } from 'darkblue-ui/styles/colors/utils';

const StylesTable = styled.table`
  border-collapse: collapse;
  padding: 10px;
  tbody tr:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.charcoal};

    td {
      color: ${(props) =>
        props.theme.mode === 'dark'
          ? props.theme.colors.primaryText
          : props.theme.colors.white};
    }
  }

  th {
    text-align: left;
  }
  td {
    min-width: 100px;
  }
`;

const StyledRow = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;

const Table = (props) => {
  const {
    tableData,
    columns,
    setSliderOpen,
    sliderContent,
    setSliderContent,
    selectedFeature,
    map,
  } = props;
  const [highlightColor, setHighlightColor] = useState('');
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData,
  });

  useEffect(() => {
    setHighlightColor(getRandomHexColor());
  }, []);

  return (
    <StylesTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          // eslint-disable-next-line react/jsx-key
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              // eslint-disable-next-line react/jsx-key
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            // eslint-disable-next-line react/jsx-key
            <StyledRow
              title="Click row to view more"
              onClick={() => {
                const featureState = map.current.getFeatureState(
                  selectedFeature,
                );
                const usableColor =
                  featureState?.strokeColor !== 'white'
                    ? featureState?.strokeColor
                    : highlightColor;
                const { source, id } = selectedFeature;

                if (featureState?.strokeColor === 'white') {
                  map.current.setFeatureState(
                    {
                      source,
                      id,
                    },
                    {
                      active: true,
                      strokeColor: highlightColor,
                    },
                  );
                }

                const content = [
                  {
                    ...row.original,
                    highlight: true,
                    highlightColor: usableColor,
                    selectedFeature,
                  },
                  ...sliderContent,
                ];
                const uniqueContent = _.uniqWith(
                  content,
                  (a, b) => _.get(a, 'id') === _.get(b, 'id'),
                );
                setSliderContent(uniqueContent);
                setSliderOpen(true);
              }}
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => {
                // eslint-disable-next-line react/jsx-key
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: '150px',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </StyledRow>
          );
        })}
      </tbody>
    </StylesTable>
  );
};

const PopupTable = (props) => {
  const { type = 'ip', data } = props;

  const memoIpColumns = useMemo(() => ipColumns, []);

  const memoAdIdColumns = useMemo(() => adIdColumns, []);

  const memoDatasetColumns = useMemo(() => datasetColumns, []);

  let columns;

  switch (type) {
    case 'ip':
      columns = memoIpColumns;
      break;
    case 'adId':
      columns = memoAdIdColumns;
      break;
    case 'dataset':
      columns = memoDatasetColumns;
      break;
  }

  return data && data.length > 0 ? (
    <Table columns={columns} tableData={data} {...props} />
  ) : (
    <p>Zoom in to see records</p>
  );
};

export default PopupTable;
