import React from 'react';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';

const StyledDrawer = styled(Drawer)`
  & > div {
    position: relative;
    z-index: -5;
    border-right: none;
  }
`;
const FilterSideBarDrawer = ({ open, children }) => {
  return (
    <>
      <StyledDrawer
        style={{
          ...(open
            ? { position: 'fixed' }
            : { position: 'relative', zIndex: -5 }),
        }}
        variant="persistent"
        open={open}
      >
        {children}
      </StyledDrawer>
    </>
  );
};

export default FilterSideBarDrawer;
