import React from 'react';
import styled, { css } from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import Modal from '../Modals/Modal/Modal.js';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

import Button from '../Button/Button';
import Loader from '../Spinners/Loader';

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RefreshIcon from '@material-ui/icons/Refresh';

import SelectFilter from '../Search/SelectFilter/SelectFilter';
import TextFilter from 'darkblue-ui/Search/TextFilter/TextFilter';
import GeoPointFilter from 'darkblue-ui/Search/TextFilter/GeoPointFilter';

import {
  getDefaultFilterState,
  getDefaultIndexFilterState,
} from '../../filters/utils';

import {
  UniversalDefaultFilterState,
  AdvancedFilters,
} from '../../filters/UniversalFilters.js';

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const FiltersContainer = styled(GridContainer)`
  position: relative;
  &&& {
    max-height: 650px;
    width: 98%;
    margin: auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 90px;
  }
`;

const FilterModalContainer = styled(GridContainer)`
  background-color: ${(props) => props.theme.colors.cardBackground};
  border-radius: 0px;
  position: relative;
`;

const ClickGridItem = styled(GridItem)`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const ClearFiltersIcon = styled(RemoveCircleOutlineIcon)`
  color: ${(props) => props.theme.colors.charcoal};
  margin-left: 5px;
`;

const CloseModalIcon = styled(HighlightOffIcon)`
  color: ${(props) => props.theme.colors.charcoal};
  margin-left: 5px;
`;

const FilterModal = ({
  filterList,
  filterState,
  setFilterState,
  setFilterModalOpen,
  filterModalOpen,
  filterCategories,
  index,
  apiKey,
}) => {
  //Populate the advance filter modal based by the indices
  const advancedFiltersByIndex = AdvancedFilters.filter((f) =>
    filterList.includes(f.filterName),
  );

  /**
   * Resets filter state based on default state.
   * @param  {boolean} isClearFilter - A boolean to indicate which default state to set to.
   */
  const resetFilterState = (isClearFilter) => {
    if (isClearFilter) {
      setFilterState({});
    } else {
      const { Searchbar = {}, ...rest } = filterState;
      setFilterState(
        Object.entries(Searchbar).length > 0 ? { Searchbar: Searchbar } : {},
      );
    }
  };

  return (
    <Modal
      open={filterModalOpen}
      onClose={() => setFilterModalOpen(false)}
      onClickOutside={() => setFilterModalOpen(false)}
    >
      <FilterModalContainer direction="column">
        <GridContainer style={{ justifyContent: 'space-between' }}>
          <GridItem style={{ marginLeft: '22px' }} xs={6}>
            <h1 style={{ fontWeight: 'normal' }}>Advanced Filters</h1>
          </GridItem>
          <GridItem style={{ display: 'flex' }}>
            <ClickGridItem
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '-15px',
              }}
              onClick={() => resetFilterState(false)}
            >
              <p className="no-margin">Clear Filters </p>
              <ClearFiltersIcon
                className="filter-header-icons"
                fontSize="small"
              />
            </ClickGridItem>
            <ClickGridItem
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => setFilterModalOpen(false)}
            >
              <p className="no-margin">Close </p>
              <CloseModalIcon
                className="filter-header-icons"
                fontSize="small"
              />
            </ClickGridItem>
          </GridItem>
        </GridContainer>
        <FiltersContainer>
          {filterCategories
            ? filterCategories
                .filter((category) => {
                  if (
                    advancedFiltersByIndex.filter((f) => {
                      return f.filterCategory === category;
                    }).length > 0
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((category) => {
                  return (
                    <div key={category} style={{ width: '100%' }}>
                      <GridItem style={{ position: 'relative' }}>
                        <h1 className="lighter">{category}</h1>
                        {advancedFiltersByIndex
                          .filter((f) => {
                            return f.filterCategory === category;
                          })
                          .map((filter) => {
                            switch (filter.filterType) {
                              case 'SelectFilter':
                                return (
                                  <SelectFilter
                                    key={filter.filterName}
                                    filterState={filterState}
                                    index={index}
                                    apiKey={apiKey}
                                    {...filter}
                                  />
                                );
                              case 'TextFilter':
                                return (
                                  <TextFilter
                                    key={filter.filterName}
                                    filterState={filterState}
                                    setFilterState={setFilterState}
                                    index={index}
                                    apiKey={apiKey}
                                    filterModalOpen={filterModalOpen}
                                    {...filter}
                                  />
                                );
                              case 'GeoPointFilter':
                                return (
                                  <GeoPointFilter
                                    key={filter.filterName}
                                    filterState={filterState}
                                    setFilterState={setFilterState}
                                    index={index}
                                    apiKey={apiKey}
                                    filterModalOpen={filterModalOpen}
                                    {...filter}
                                  />
                                );
                            }
                          })}
                      </GridItem>
                      <hr />
                    </div>
                  );
                })
            : null}
        </FiltersContainer>
      </FilterModalContainer>
    </Modal>
  );
};

export default FilterModal;
