import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

//@custom utils
import {
  getDefaultFilterState,
  getUrlFilterParams,
  formatDateObjectToString,
} from '../../filters/utils.js';
import {
  UniversalFilterComponentList,
  UniversalDefaultFilterState,
} from '../../filters/UniversalFilters';

//Default parameters initializers.
const today = new Date();
const p7 = new Date();
p7.setDate(today.getDate() - 7);

/**
 * @function utils
 * A function that returns a filtered encoded time uri.
 * @param  {Array<String>} index - An array of routes indcies.
 * Reference route.js ex.index={['communications', 'profiles', 'markets']}
 */
function useEnCodeTimeUri(start = p7, end = today, index) {
  const location = useLocation();

  const filterState = getDefaultFilterState(UniversalFilterComponentList, {
    ...getUrlFilterParams(location.search),
    TimeFilter: {
      value: [start, end],
    },
  });

  return encodeURI(
    `["${formatDateObjectToString(
      filterState.TimeFilter.start,
    )}","${formatDateObjectToString(filterState.TimeFilter.end)}"]`,
  );
}

export default useEnCodeTimeUri;
