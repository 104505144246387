import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import _ from 'lodash';
import geohash from 'ngeohash';
import mapboxgl from '!mapbox-gl';
import { useSnackbar } from 'notistack';
/* eslint import/no-webpack-loader-syntax: off */
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  handleDraw,
  handleDelete,
  handleBounds,
  handleMarkerState,
  zoomLevelToPrecision,
  addLayerToMap,
  removeLayerFromMap,
} from './mapUtils.js';
import { useQuery } from 'urql';

import { getSearchFields } from 'filters/UniversalFilters.js';

const MapControls = ({
  filterState,
  setFilterState,
  hits,
  searchError,
  mapParentRef,
  zoom,
  setZoom,
  centerCoords,
  setCenterCoords,
  layers,
  setMapIsLoading,
  setLatLong,
  setPopupContent,
  map,
  setDataCategories,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [layerPaths, setLayerPaths] = useState([]);
  const [queryVariables, setQueryVariables] = useState(null);
  const [pauseQuery, setPauseQuery] = useState(true);
  const [currentField, setCurrentField] = useState('');
  const [selectedFeature, setClickedFeature] = useState(null);
  const PointQuery = `
    query ($index: [String]!, $fields: [String], $term: String, $radius: Float, $location: InputMapPoint, $startTime: String, $endTime: String) {
      point (index: $index, fields: $fields, term: $term, radius: $radius, location: $location, startTime: $startTime, endTime: $endTime){
        id
        lat
		    lon
		    type
		    index
		    data {
          ... on Darkflow {
            server {
              ip {
                ip
              }
              asn {
                organization_name
              }
              packets
              bytes
              geo {
                location {
                  lat
                  lon
                }
              }
            }
          }
          ... on Communications {
            post
            site {
              name
            }
            title
            threadId
            postId
            post
          }
        }
      }
    }
  `;

  const [result, runQuery] = useQuery({
    query: PointQuery,
    variables: queryVariables,
    pause: pauseQuery,
  });
  const { data, fetching, error } = result;

  const [mapLoaded, setMapLoaded] = useState(false);
  const Draw = useRef(null);
  const theme = useContext(ThemeContext);
  const mapSetup = useRef({
    zoom,
    setZoom,
    centerCoords,
    setCenterCoords,
  });
  mapSetup.current = {
    zoom,
    setZoom,
    centerCoords,
    setCenterCoords,
  };
  const handleDrawRef = useRef(() =>
    handleDraw(map.current, Draw.current, filterState, setFilterState),
  );
  handleDrawRef.current = () =>
    handleDraw(map.current, Draw.current, filterState, setFilterState);
  const handleDeleteRef = useRef((deletedFeatureIds) => {
    handleDelete({
      filterState,
      setFilterState,
      deletedFeatureIds,
    });
  });
  handleDeleteRef.current = (deletedFeatureIds) => {
    return handleDelete({
      filterState,
      setFilterState,
      deletedFeatureIds,
    });
  };
  const layerPathsRef = useRef(() => layerPaths);
  layerPathsRef.current = () => layerPaths;
  const filterStateRef = useRef(() => filterState);
  filterStateRef.current = () => filterState;

  const handleMarkerClick = async (
    e,
    currentLayerPaths,
    currentFilterState,
  ) => {
    const feature =
      map.current.queryRenderedFeatures(e.point, {
        layers: currentLayerPaths,
      })?.[0] || {};
    setClickedFeature(feature);
    const featureIndex = feature?.state?.index;
    const coordinates = feature?.geometry?.coordinates.slice();
    const zoomLevel = Math.ceil(map.current.getZoom());
    const field = feature?.layer?.id;
    const searchFields = getSearchFields(featureIndex);
    const searchTerm = currentFilterState?.MapSearchbar?.value;
    const timeRange = currentFilterState?.DateTimeFilter?.value;

    const cleanQueryVariables = Object.fromEntries(
      Object.entries({
        location: { lat: coordinates[1], lon: coordinates[0] },
        index: featureIndex,
        fields: searchFields,
        term: searchTerm,
        radius: zoomLevelToPrecision(zoomLevel),
        startTime: timeRange?.startValue,
        endTime: timeRange?.endValue,
      }).filter(([key, value]) => value),
    );

    setCurrentField(field);
    setQueryVariables(cleanQueryVariables);
    setLatLong(coordinates);

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }
  };

  // handle errors for both popup requests and main geo search requests
  useEffect(() => {
    if (error || searchError) {
      enqueueSnackbar(`An error occurred. Please contact support.`, {
        variant: 'error',
      });
    }
  }, [error, searchError]);

  // handle popup state
  useEffect(() => {
    if (!fetching && !error && currentField && data?.point?.length > 0) {
      const cleanData = data.point
        .filter((p) => p.type === currentField)
        .map((p) => ({ id: p.id, ...p }));
      const uniqueContent = _.uniqWith(
        cleanData,
        (a, b) => _.get(a, 'id') === _.get(b, 'id'),
      );
      setPopupContent({
        data: uniqueContent,
        field: currentField,
        error: null,
        loading: fetching,
        selectedFeature,
      });
      setPauseQuery(true);
    } else {
      setPopupContent({
        data: data?.point,
        error,
        field: currentField,
        loading: fetching,
        selectedFeature,
      });
    }
  }, [data, selectedFeature, fetching, error]);

  useEffect(() => {
    if (queryVariables) {
      setPauseQuery(false);
    }
  }, [queryVariables]);

  // handle map creation
  useEffect(() => {
    if (map.current) {
      return;
    } else {
      const mapTheme = theme.mode || 'dark';

      Draw.current = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
          polygon: true,
          trash: true,
        },
      });

      map.current = new mapboxgl.Map({
        minZoom: 1,
        container: mapParentRef.current,
        style: `mapbox://styles/mapbox/${mapTheme}-v10`,
        center: [
          mapSetup.current.centerCoords.lat,
          mapSetup.current.centerCoords.lng,
        ],
        zoom: mapSetup.current.zoom,
      });

      map.current.addControl(
        new mapboxgl.NavigationControl({ showCompass: false }),
      );

      map.current.addControl(Draw.current, 'top-right');
      map.current.on('load', (loadEvent) => {
        map.current.flyTo({
          center: mapSetup.current.centerCoords,
          zoom: mapSetup.current.zoom,
        });

        map.current.on('moveend', () => {
          mapSetup.current.setCenterCoords({
            lat: map.current.getCenter().lat,
            lng: map.current.getCenter().lng,
          });
          mapSetup.current.setZoom(map.current.getZoom());
        });

        setMapLoaded(true);
      });

      map.current.on('draw.create', (e) => {
        setMapIsLoading(true);
        handleDrawRef.current();
      });

      map.current.on('draw.update', (e) => {
        setMapIsLoading(true);
        handleDrawRef.current();
      });

      map.current.on('draw.delete', (e) => {
        setMapIsLoading(true);
        const deletedFeatureIds = e.features.map((f) => f.id);
        handleDeleteRef.current(deletedFeatureIds);
      });
    }

    return () => map.current.remove();
  }, []);

  // handle map data
  useEffect(() => {
    const activePaths = hits.map((h) => h.path);
    const inactiveLayers = layers.filter(
      ({ path }) => !activePaths.includes(path),
    );

    if (Array.isArray(hits) && hits.length > 0) {
      let categories = [];
      setLayerPaths(activePaths);

      hits.forEach(({ data, path, index }, i) => {
        const { displayText = '' } = layers?.find(
          (layer) => layer.path === path,
        );
        categories.push({ displayText, color: theme.chartColors[i], path });

        if (map.current && map.current.getSource(path)) {
          const geojson = {
            type: 'FeatureCollection',
            features: data.map(({ lat, lon }) => {
              return {
                type: 'Feature',
                geometry: { type: 'Point', coordinates: [lon, lat] },
              };
            }),
          };

          map.current.getSource(path).setData(geojson);
        } else if (!map.current.getSource(path)) {
          addLayerToMap({
            map: map.current,
            properties: {},
            id: path,
            color: theme.chartColors[i],
          });

          handleMarkerState({
            map: map.current,
            sourceId: path,
            staticProperties: { index: Array.isArray(index) ? index : [index] },
          });

          const geojson = {
            type: 'FeatureCollection',
            features: data.map(({ lat, lon }) => {
              return {
                type: 'Feature',
                geometry: { type: 'Point', coordinates: [lon, lat] },
              };
            }),
          };
          map.current.on(
            'click',
            path,
            async (e) =>
              await handleMarkerClick(
                e,
                layerPathsRef.current(),
                filterStateRef.current(),
              ),
          );

          map.current.getSource(path).setData(geojson);
        }
      });

      setDataCategories(categories);
    }

    inactiveLayers.forEach(({ path }) => {
      if (map.current.getSource(path)) {
        removeLayerFromMap(map.current, path);
      }
    });

    map.current.on('idle', () => {
      setMapIsLoading(false);
    });
  }, [hits, mapLoaded, layers.length]);

  // handle shape drawing
  // boundsQuery: any query performed automatically when the map is moved
  useEffect(() => {
    // handle adding shapes from filterState
    // as well as removing shapes no longer present in filterState
    if (Draw.current && filterState.GeoFilter?.value) {
      const filterValues = filterState.GeoFilter?.value;
      const filterIds = filterState.GeoFilter?.value.map((val) => val.id);
      const shapesToRemove = Draw.current
        .getAll()
        ?.features?.filter((shape) => {
          return !filterIds.includes(shape.id);
        });

      filterValues
        .filter((val) => !val.boundsQuery)
        .forEach(({ id, geoJSON, type }) => {
          const feature = {
            id,
            type: 'Feature',
            properties: {},
            geometry: { type, coordinates: geoJSON },
          };
          Draw.current.add(feature);
        });

      shapesToRemove.forEach((shape) => {
        Draw.current.delete(shape.id);
      });
    }

    // handle deleting shapes when no filterState is present
    if (Draw.current && !filterState.GeoFilter?.value) {
      Draw.current.deleteAll();
      handleBounds({
        map: map.current,
        filterState,
        setFilterState,
        setMapIsLoading,
        filterName: 'GeoFilter',
      });
    }
  }, [filterState, Draw.current]);

  return null;
};

export default MapControls;
