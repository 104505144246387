import React, { useMemo, useState, useEffect } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';

import MUITooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import HighlightComponent from '../../composite/DarkBlueCard/HighlightComponent';
const Styles = styled.div`
  overflow-x: scroll;
  overflow-y: auto;
  height: 50vh;

  table {
    margin: 25px auto;
    padding: 1rem;
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    table-layout: ${(props) =>
      props.index === 'scans' ? 'fixed !important;' : null};

    thead {
      position: sticky;
      top: 0px;
      box-shadow: 0px -1px #eee;
    }

    th,
    td {
      border: 1px solid #eee;
      padding: 8px;
      border-collapse: collapse;
      text-align: center;
      word-wrap: break-word;
      text-align: left;
    }

    th {
      background: #2a8dfe;
      color: #fff;
      text-transform: capitalize;
      font-size: 12px;
      
      &.last {
        border-right: none;
      }

      span[role='columnheader'] {
        margin-left: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }
    .pagination {
      padding: 0.5rem;
    }
  }
`;

const TableDataExpandDiv = styled.div`
  width: fit-content;
  height: fit-content;
  a:hover {
    cursor: pointer;
  }
  align-self: flex-end;
`;

const TooltipForm = styled.form`
  display: flex;
  align-items: center;
  height: 25px;
  border: 1px solid ${(props) => props.theme.colors.primaryOpacity};
`;

const TooltipHeader = styled.p`
  color: ${(props) => props.theme.colors.almostBlack};
  text-align: center;
  margin: 0;
`;

const TooltipInput = styled.input`
  height: 100%;
  padding: 0;
  border: 0;

  &:focus {
    outline: none;
  }
`;

const TooltipButton = styled.button`
  height: 100%;
  border: none;
`;

const TableTooltipFilter = ({ elasticPath, setFilterState, filterState }) => {
  return (
    <Formik
      initialValues={{ searchValue: '' }}
      onSubmit={(values) => {
        const currentTableFilterState = filterState?.DatasetFilter?.value || {};
        const newDatasetFilterValue = filterState?.DatasetFilter?.value[
          elasticPath
        ]
          ? {
              [elasticPath]: [
                values.searchValue,
                ...filterState?.DatasetFilter?.value[elasticPath],
              ],
            }
          : { [elasticPath]: [values.searchValue] };
        const newTableFilterState = {
          ...currentTableFilterState,
          ...newDatasetFilterValue,
        };
        setFilterState({
          ...filterState,
          ...{ DatasetFilter: { value: newTableFilterState } },
        });
      }}
    >
      {({ values, handleChange, handleSubmit }) => {
        return (
          <TooltipForm onSubmit={handleSubmit}>
            <TooltipInput
              type="text"
              name="searchValue"
              onChange={handleChange}
              value={values.searchValue}
            />
            <TooltipButton type="submit">Add</TooltipButton>
          </TooltipForm>
        );
      }}
    </Formik>
  );
};

const DisplayTable = ({
  filterState,
  setFilterState,
  rowArr = [],
  setRowArr,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
}) => {
  const [activeTooltip, setActiveTooltip] = useState('');

  const handleTooltipClose = () => {
    setActiveTooltip('');
  };

  const handleTooltipOpen = (fieldName) => {
    setActiveTooltip(fieldName);
  };

  return (
    <>
      <Styles>
        <table
          style={{ margin: '0 auto', minWidth: '100%' }}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr
                key={`headerGroup_${i}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) =>
                  column.Header !== 'timestamp' ? (
                    <MUITooltip
                      key={column.Header}
                      placement="top"
                      open={activeTooltip === column.Header}
                      title={
                        <ClickAwayListener
                          onClickAway={() => {
                            if (column.Header === activeTooltip) {
                              handleTooltipClose();
                            }
                          }}
                        >
                          <div style={{ pointerEvents: 'all' }}>
                            <TooltipHeader>{column.Header}</TooltipHeader>
                            <TableTooltipFilter
                              elasticPath={column.Header}
                              filterState={filterState}
                              setFilterState={setFilterState}
                            />
                          </div>
                        </ClickAwayListener>
                      }
                      disableFocusListener
                      disableTouchListener
                      disableHoverListener
                    >
                      <th onClick={() => handleTooltipOpen(column.Header)}>
                        <span>{column.Header}</span>
                      </th>
                    </MUITooltip>
                  ) : (
                    <th key={column.Header}>
                      <span>{column.Header}</span>
                    </th>
                  ),
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row);

                return (
                  <tr key={`row_${i}`} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      let tempArray = rowArr;
                      const header = cell.column.Header;
                      const cellText = `${cell.row.original.message}`;
                      if (header === 'message' && rowArr.length < 10) {
                        tempArray.push({
                          cell: cell,
                          expanded: false,
                        });
                        setRowArr((arr) => [
                          ...arr,
                          { cell: cell, expanded: false },
                        ]);
                      }

                      return cellText.length > 200 && header === 'message' ? (
                        rowArr[i]?.expanded ? (
                          <td key={cell} {...cell.getCellProps()}>
                            <div style={{ display: 'inline-block' }}>
                              <p>
                                <HighlightComponent text={cell.value} />
                              </p>
                            </div>
                            <div
                              style={{ display: 'flex', justifyContent: 'end' }}
                            >
                              <TableDataExpandDiv
                                onClick={() => {
                                  tempArray = [
                                    ...rowArr.slice(0, i),
                                    {
                                      ...rowArr[i],
                                      ...{
                                        expanded: false,
                                      },
                                    },
                                    ...rowArr.slice(i + 1),
                                  ];
                                  setRowArr(tempArray);
                                }}
                              >
                                <a>Minimize</a>
                              </TableDataExpandDiv>
                            </div>
                          </td>
                        ) : (
                          <td
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                            key={cell}
                            {...cell.getCellProps()}
                          >
                            <p>
                              <HighlightComponent
                                text={cellText.slice(0, 197) + '...'}
                              />
                            </p>
                            <TableDataExpandDiv
                              onClick={() => {
                                tempArray = [
                                  ...rowArr.slice(0, i),
                                  {
                                    ...rowArr[i],
                                    ...{
                                      expanded: true,
                                    },
                                  },
                                  ...rowArr.slice(i + 1),
                                ];
                                setRowArr(tempArray);
                              }}
                            >
                              <a>Expand</a>
                            </TableDataExpandDiv>
                          </td>
                        )
                      ) : (
                        <td key={cell} {...cell.getCellProps()}>
                          <p>
                            <HighlightComponent text={cell.value} />
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td style={{ fontSize: '15px' }} colSpan="10">
                  No Records Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Styles>
    </>
  );
};

DisplayTable.defaultProps = {
  showColumnDisplayControl: false,
};

export default DisplayTable;
