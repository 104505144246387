import React from 'react';

const TosText = () => {
  return (
    <>
      <h2 style={{ textAlign: 'center' }}>
        Warning: DarkBlue contains language and topics some users may find
        disturbing or offensive.
      </h2>
      <p style={{ textAlign: 'center' }}>
        While using DarkBlue, or any data derived from this technology, users
        are required to follow all local, state, and federal laws as well as the
        policies and procedures of their organization. Your organization may
        decide to audit your use of this system at any time.
      </p>
    </>
  );
};

export default TosText;
