import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';

// @material-ui/core components
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import StorefrontIcon from '@material-ui/icons/Store';
import { DateRange } from '@appbaseio/reactivesearch';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  NetworkCell,
} from '@material-ui/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';

// @darkblue-ui/components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Button from '../../darkblue-ui/Button/Button';

import BluestoneLogoComponent from '../../darkblue-ui/BluestoneLogoComponent/BluestoneLogoComponent';
import FilterDrawer from '../../components/FilterDrawer/FilterDrawer';
import FilterSidebar from '../../darkblue-ui/FilterSidebar/FilterSidebar';
import FilterModal from '../../darkblue-ui/FilterModal/FilterModal';
import HelpToolTipButton from '../../darkblue-ui/Button/HelpToolTipButton';

import Loader from '../../darkblue-ui/Spinners/Loader';
import FilterCategory from '../../components/FilterCategory/FilterCategory';
import Results from '../../darkblue-ui/Results/Results';
import CustomSelectedFilters from '../../components/CustomSelectedFilters/CustomSelectedFilters';
import CalendarFilter from '../../darkblue-ui/CustomRSComponents/Filters/CalendarFilter/CalendarFilter';

//@Third party libraries
import Cluster from '../Elastic/Cluster';

import {
  numberWithCommas,
  getDefaultFilterState,
  getUrlFilterParams,
  getFilterValues,
  getFilteredNames,
  getDefaultIndexFilterState,
  formatDateObjectToString,
} from '../../filters/utils.js';

import {
  handleChartFilterOn,
  downloadAsPDF,
} from '../../utils/Functions/utils';

import { startSearchDate } from '../../consts/MagicValues';

import MarketFilter from '../../darkblue-ui/CustomRSComponents/Filters/MarketFilter/MarketFilter';

import SwitchGroup from 'darkblue-ui/Inputs/SwitchGroup';

import {
  UniversalFilterNameList,
  UniversalFieldNameList,
  UniversalSelectorList,
  UniversalFilterComponentList,
  UniversalCategoryList,
  UniversalDefaultFilterState,
} from '../../filters/UniversalFilters';

import {
  uniqueItemsByMarket,
  uniqueItemsByNetByTime,
  uniqueItemsByMarketByTime,
  aiContentByMarket,
  productCount30DayTrend,
  uniqueReviewsByMarket,
  dashboardDatesQuery,
} from '../../utils/Queries/CustomQueries';

import {
  VictoryChart,
  VictoryBar,
  VictoryStack,
  VictoryPie,
  VictoryTheme,
  VictoryTooltip,
  VictoryLabel,
  VictoryAxis,
  VictoryContainer,
  VictoryLegend,
  VictoryVoronoiContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryClipContainer,
  Flyout,
} from 'victory';

import SelectFilter from '../../darkblue-ui/Search/SelectFilter/SelectFilter.js';

import DropdownSelectFilter from '../../darkblue-ui/CustomRSComponents/Filters/DropdownSelectFilter/DropdownSelectFilter.js';

import Counter from '../../darkblue-ui/Widgets/Counter/Counter';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';

const DashboardCounter = styled.h1`
  fontsize: 32;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.cardTitle};
`;

const Buffer = styled.div`
  min-width: 180px;
`;

const DashboardGridContainer = styled(GridContainer)`
  box-shadow: unset !important;

  &&& > * {
    box-shadow: unset !important;
  }
`;

const ThemedGetAppIcon = styled(GetAppIcon)`
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary};
    cursor: pointer;
  }
`;

const StyledCard = styled(Card)`
  background-color: ${(props) => props.theme.colors.cardBackground} !important;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const getChartColors = (item, items, chartColors) => {
  let sortedItems = [...items];
  sortedItems.sort();
  return chartColors[sortedItems.indexOf(item)];
};

// const pieDataColorMap = (data) => {
//   switch (data) {
//     case 'opiates':
//       return '#FFF59D';
//     case 'leaks':
//       return '#F4511E';
//     case 'weapons':
//       return '#DCE775';
//     case 'drugs':
//       return '#8BC34A';
//     case 'technology':
//       return '#00796B';
//     case 'cp':
//       return '#006064';
//     case 'fraud':
//       return '#455A64';
//     default:
//       return '#F00';
//   }
// };

// const tagDataColorMap = (tags) => {
//   switch (tags) {
//     case 'fraud':
//       return '#FCC044';
//     case 'technology':
//       return '#F4511E';
//     case 'leaks':
//       return '#DCE775';
//     case 'violence':
//       return '#8BC34A';
//     case 'weapons':
//       return '#61e0fa';
//     case 'cp':
//       return '#1711d1';
//     case '_missing_':
//       return '#455A64';
//     case 'drugs':
//       return '#5c2c6e';
//   }
// };

class CustomLabel extends React.Component {
  render() {
    return (
      <g>
        <VictoryTooltip
          {...this.props}
          x={this.props.width / 2}
          y={(this.props.height + this.props.style.padding * 4) / 2}
          orientation="top"
          pointerLength={0}
          cornerRadius={50}
          flyoutWidth={100}
          flyoutHeight={100}
          flyoutStyle={{ fill: 'white', stroke: 'white' }}
        />
      </g>
    );
  }
}

const MissingData = () => {
  return (
    <h2>No data found. Please adjust your query parameters and try again.</h2>
  );
};

CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;

const MarketsDashboard = (props) => {
  const { index, logo } = props;
  const filters = UniversalFilterComponentList;
  const filterNames = getFilteredNames(index, UniversalFilterNameList);
  const filterCategories = getFilterValues(index, UniversalCategoryList);
  const selectors = getFilterValues(index, UniversalSelectorList);
  const location = useLocation();
  const [toggleOn, setToggleOn] = useState(true);
  const [filterState, setFilterState] = useState(
    getDefaultFilterState(filters, {
      ...getDefaultIndexFilterState(index, UniversalDefaultFilterState),
      ...getUrlFilterParams(location.search),
      TimeFilter: {
        start: new Date(new Date().setDate(new Date().getDate() - 30)),
        end: new Date(),
      },
    }),
  );
  const [triggerQuery, setTriggerQuery] = useState(null);
  // const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const containerTooltipRef = useRef(null);

  useEffect(() => {
    if (triggerQuery) {
      triggerQuery();
    }
  }, [filterState, triggerQuery]);
  return (
    <Cluster index={index.join(',')}>
      <GridContainer
        style={{ marginRight: '24px', marginTop: '12px' }}
        justify="flex-end"
        alignItems="center"
      >
        {index.length > 1 && (
          <GridItem>
            <DropdownSelectFilter
              otherField
              label="Dark Web Marketplaces"
              filterLabel="Dark Web Marketplaces"
              filterType="MultiDropdownList"
              text="By default, this dashboard will display the top results across all Dark Web Marketplaces. Select specific markets of interest below to generate custom visualizations."
              filterState={filterState}
              setFilterState={setFilterState}
              componentId="SiteNameFilter"
              URLParams
              value={filterState['SiteNameFilter']}
              filterList={filterNames}
              dataField="site.name.keyword"
            />
          </GridItem>
        )}
        <GridItem>
          <CalendarFilter
            filterList={filterNames}
            filterState={filterState}
            setFilterState={setFilterState}
            query={dashboardDatesQuery}
          />
        </GridItem>
        <GridItem>
          <Tooltip title="Download Report">
            <ThemedGetAppIcon
              onClick={() =>
                downloadAsPDF(
                  'dashboard',
                  'DarkBlue Market Trends',
                  themeContext.colors.mainBackground,
                )
              }
            />
          </Tooltip>
        </GridItem>
      </GridContainer>

      <div style={{ display: 'flex' }}>
        <div id="dashboard" style={{ width: '100%', paddingLeft: '20px' }}>
          <GridContainer justify="center" alignItems="center">
            <GridItem xs={4}>
              <h1 style={{ marginTop: '0px' }}>
                Dark Web Markets Mission Space
              </h1>
              <p>
                Dark Web Marketplaces are a dynamic environment, with markets
                opening and closing with high frequency. Use this dashboard to
                stay on top of the latest trends and threats for market data in
                DarkBlue.
              </p>
            </GridItem>
            <GridItem xs={2}>
              <Counter
                field="site.name.keyword"
                title="Dark Web Markets"
                color="warning"
                reactiveFilters={filterNames}
                toolTipPreText="During the time period selected, DarkBlue collected data on "
                toolTipPostText=" Dark Web Marketplaces."
                icon={<StorefrontIcon />}
                excludedIndices={['market_stats']}
              />
            </GridItem>
            <GridItem xs={2}>
              <Counter
                field="vendorId.keyword"
                title="Dark Web Vendors"
                reactiveFilters={filterNames}
                color="warning"
                toolTipPreText="During the time period selected, DarkBlue identified "
                toolTipPostText=" vendor profiles on Dark Web Marketplaces."
                icon={<StorefrontIcon />}
                excludedIndices={['market_stats']}
              />
            </GridItem>
            <GridItem xs={2}>
              <Counter
                field="productId.keyword"
                title="Dark Web Products"
                reactiveFilters={filterNames}
                color="warning"
                toolTipPreText="During the time period selected, DarkBlue identified "
                toolTipPostText=" product listings on Dark Web Marketplaces."
                icon={<StorefrontIcon />}
              />
            </GridItem>
            <GridItem xs={2}>
              <Counter
                field="reviewId.keyword"
                title="Dark Web Reviews"
                reactiveFilters={filterNames}
                color="warning"
                toolTipPreText="During the time period selected, DarkBlue identified "
                toolTipPostText=" vendor and product reviews on Dark Web Marketplaces."
                icon={<StorefrontIcon />}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridContainer>
              <GridItem xs={11}>
                <CustomSelectedFilters
                  index={index}
                  filters={[
                    ...filters,
                    <DropdownSelectFilter
                      otherField
                      URLParams={true}
                      key="MarketFilter"
                      filterType="MultiDropdownList"
                      filterLabel="Market Name"
                      text="Select markets of interest to generate custom visualizations."
                      filterState={filterState}
                      setFilterState={setFilterState}
                      componentId="SiteNameFilter"
                      value={filterState['SiteNameFilter']}
                      filterList={filterNames}
                      dataField="site.name.keyword"
                    />,
                  ]}
                  filterState={filterState}
                  setFilterState={setFilterState}
                  dashboard
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
          <GridContainer
            style={{ position: 'relative', zIndex: '0' }}
            alignItems="center"
          >
            {/* </GridContainer> */}

            <GridItem style={{ zIndex: '0' }} xs={6} sm={6} md={6} lg={6}>
              <ReactiveComponent
                componentId="uniqueItemsByMarketByTime"
                customQuery={uniqueItemsByMarketByTime}
                defaultQuery={uniqueItemsByMarketByTime}
                react={{
                  and: [...filterNames, "TimeFilter"],
                }}
                render={(props) => {
                  const { aggregations, loading } = props;
                  let chartData = [];
                  let chartTags = [];
                  let tagListClean = [];
                  let tagListSorted = [];
                  if (
                    !loading &&
                    aggregations &&
                    aggregations.markets &&
                    aggregations.markets.buckets &&
                    aggregations.markets.buckets[0] &&
                    aggregations.markets.buckets[0].prod_type &&
                    aggregations.markets.buckets[0].prod_type.buckets
                  ) {
                    aggregations.markets.buckets.forEach((market) => {
                      const data = market.prod_type.buckets.map(
                        (prodTags, idx) => {
                          chartTags = [...chartTags, prodTags.key];
                          return {
                            siteName: market.key,
                            x: prodTags.key,
                            y: prodTags[1].value,
                          };
                        },
                      );
                      chartData = [...chartData, data];
                    });
                    chartData.sort(
                      (a, b) =>
                        a.reduce((accum, item) => accum + item.y, 0) -
                        b.reduce((accum, item) => accum + item.y, 0),
                    );
                    tagListClean = [...new Set(chartTags)];
                    tagListSorted = tagListClean.sort();
                  }
                  return (
                    <GridItem xs={12}>
                      <StyledCard>
                        <CardHeader padding={{ bottom: 0 }}>
                          <GridContainer alignItems="flex-start">
                            <GridItem xs={10}>
                              <h2>Dark Web Market Products by Category</h2>
                            </GridItem>
                            <GridItem xs={2}>
                              <div>
                                <HelpToolTipButton
                                  size="medium"
                                  tipPlacement="top"
                                  text={`Darknet Markets will differ in the products and goods offered on their site, with different marketplaces catering to different users.${' '}
                                   Use the graph below to determine which marketplaces sell which kinds of products.`}
                                />
                              </div>
                            </GridItem>
                            <GridItem>
                              <p>
                                The count of product listings by category for
                                top Dark Web Marketplaces
                              </p>
                            </GridItem>
                          </GridContainer>
                        </CardHeader>
                        <CardBody>
                          {loading ? (
                            <Loader />
                          ) : !(chartData[0] && chartData[0].length) ? (
                            <MissingData />
                          ) : (
                            <VictoryChart
                              constrainToVisableArea
                              theme={VictoryTheme.material}
                              containerComponent={<VictoryContainer />}
                              padding={{
                                top: 20,
                                bottom: 100,
                                left: 100,
                                right: 40,
                              }}
                            >
                              <VictoryLegend
                                x={80}
                                y={300}
                                orientation="horizontal"
                                itemsPerRow={3}
                                gutter={20}
                                rowGutter={2}
                                data={tagListClean.map((tag) => {
                                  if (tag === '__missing__') {
                                    return {
                                      name: 'unlabeled',
                                      symbol: {
                                        fill: getChartColors(
                                          tag,
                                          tagListSorted,
                                          themeContext.chartColors,
                                        ),
                                      },
                                    };
                                  } else {
                                    return {
                                      name: tag,
                                      symbol: {
                                        fill: getChartColors(
                                          tag,
                                          tagListSorted,
                                          themeContext.chartColors,
                                        ),
                                      },
                                    };
                                  }
                                })}
                                style={{ labels: { fontSize: 8 } }}
                              />
                              <VictoryAxis
                                theme={VictoryTheme.material}
                                renderInPortal
                                label="Dark Web Marketplaces"
                                tickCount={5}
                                style={{
                                  axisLabel: {
                                    padding: 90,
                                    fontSize: 8,
                                  },
                                  tickLabels: {
                                    fontSize: 6,
                                  },
                                }}
                                tickLabelComponent={
                                  <VictoryLabel
                                    dx={5}
                                    textAnchor="end"
                                    // angle={-30}
                                  />
                                }
                              />
                              <VictoryAxis
                                dependentAxis
                                label="Product Count"
                                renderInPortal
                                tickCount={8}
                                style={{
                                  tickLabels: { fontSize: 6 },
                                  axisLabel: {
                                    fontSize: 8,
                                    padding: 25,
                                  },
                                }}
                              />
                              <VictoryStack
                                horizontal
                                offset={10}
                                minDomain={{ x: 0 }}
                              >
                                {chartData.map((net) => {
                                  return (
                                    <VictoryStack key={net}>
                                      {net.map((tags) => {
                                        return (
                                          <VictoryBar
                                            horizontal
                                            barRatio={2.5}
                                            data={[tags]}
                                            catagories={(datum) =>
                                              datum.siteName
                                            }
                                            key={(datum) => datum.x}
                                            y={(datum) => datum.y}
                                            x={(datum) => datum.siteName}
                                            labels={({ datum }) => {
                                              if (datum.x === '__missing__') {
                                                return `${numberWithCommas(
                                                  datum.y,
                                                )} unlabeled items`;
                                              } else {
                                                return `${numberWithCommas(
                                                  datum.y,
                                                )} ${datum.x} items`;
                                              }
                                            }}
                                            style={{
                                              fontSize: 6,
                                              // labels: { fill: 'red' },
                                              data: {
                                                fill: getChartColors(
                                                  tags.x,
                                                  tagListClean,
                                                  themeContext.chartColors,
                                                ),
                                              },
                                            }}
                                            labelComponent={
                                              <VictoryTooltip
                                                constrainToVisableArea
                                                flyoutStyle={{
                                                  fill:
                                                    themeContext.colors
                                                      .cardBackground,
                                                }}
                                                style={{ fill: 'black' }}
                                                orientation="top"
                                                dx={-2}
                                              />
                                            }
                                            events={[
                                              {
                                                target: 'data',
                                                eventHandlers: {
                                                  onMouseOver: () => {
                                                    return [
                                                      {
                                                        mutation: () => ({
                                                          active: true,
                                                        }),
                                                        target: 'labels',
                                                      },
                                                      {
                                                        mutation: (props) => {
                                                          return {
                                                            style: {
                                                              ...props.style,
                                                              ...{
                                                                cursor:
                                                                  'pointer',
                                                                opacity: '.9',
                                                              },
                                                            },
                                                          };
                                                        },
                                                      },
                                                    ];
                                                  },
                                                  onMouseOut: () => {
                                                    return [
                                                      {
                                                        mutation: () => ({
                                                          active: false,
                                                        }),
                                                        target: 'labels',
                                                      },
                                                      {
                                                        mutation: (props) => {
                                                          return {
                                                            style: {
                                                              ...props.style,
                                                              ...{
                                                                cursor:
                                                                  'inherit',
                                                                opacity: '1',
                                                              },
                                                            },
                                                          };
                                                        },
                                                      },
                                                    ];
                                                  },

                                                  onClick: () => {
                                                    return [
                                                      {
                                                        target: 'data',
                                                        mutation: ({
                                                          datum,
                                                        }) => {
                                                          const encodedMarket = encodeURI(
                                                            `["${datum.siteName}"]`,
                                                          );
                                                          const encodedTag = encodeURI(
                                                            `["${datum.x}"]`,
                                                          );
                                                          const notFilterTags = tagListClean
                                                            .filter(
                                                              (t) =>
                                                                t !==
                                                                '__missing__',
                                                            )
                                                            .map((t) => {
                                                              return {
                                                                elasticPath: [
                                                                  'ai.content.tags.keyword',
                                                                ],
                                                                value: `${t}`,
                                                              };
                                                            });
                                                          const encodedTime = {
                                                            start: encodeURI(
                                                              `${dayjs(
                                                                filterState
                                                                  .TimeFilter
                                                                  .start,
                                                              ).format(
                                                                'YYYY-MM-DD HH:mm:ss',
                                                              )}`,
                                                            ),
                                                            end: encodeURI(
                                                              `${dayjs(
                                                                filterState
                                                                  .TimeFilter
                                                                  .end,
                                                              ).format(
                                                                'YYYY-MM-DD HH:mm:ss',
                                                              )}`,
                                                            ),
                                                          };
                                                          
                                                          const missingPath = `NotFilter=%7B%22value%22%3A${encodeURI(
                                                            JSON.stringify(
                                                              notFilterTags,
                                                            ),
                                                          )}%7D`;

                                                          const tagPath = `ContentTagsFilter=%7B%22value%22%3A${encodedTag}%7D`;
                                                          const path = `/search/data?DateTimeFilter=%7B%22value%22%3A%7B%22startValue%22%3A%22${
                                                            encodedTime.start
                                                          }%22%2C%22endValue%22%3A%22${
                                                            encodedTime.end
                                                          }%22%7D%7D&SiteNameFilter=%7B%22value%22%3A${encodedMarket}%7D&${
                                                            datum.x ===
                                                            '__missing__'
                                                              ? missingPath
                                                              : tagPath
                                                          }`;
                                                          history.push(path);
                                                        },
                                                      },
                                                    ];
                                                  },
                                                },
                                              },
                                            ]}
                                          />
                                        );
                                      })}
                                    </VictoryStack>
                                  );
                                })}
                              </VictoryStack>
                            </VictoryChart>
                          )}
                        </CardBody>
                      </StyledCard>
                    </GridItem>
                  );
                }}
              ></ReactiveComponent>
            </GridItem>
            <GridItem style={{ zIndex: '0' }} xs={6} sm={6} md={6} lg={6}>
              {toggleOn ? (
                <ReactiveComponent
                  componentId="productCount30DayTrend"
                  customQuery={productCount30DayTrend}
                  defaultQuery={productCount30DayTrend}
                  key="productCount30DayTrend"
                  react={{
                    and: [...filterNames],
                  }}
                  render={(props) => {
                    const { aggregations, loading } = props;
                    let chartData = [];
                    let markets = [];
                    if (
                      !loading &&
                      aggregations &&
                      aggregations.markets &&
                      aggregations.markets.buckets &&
                      aggregations.markets.buckets[0] &&
                      chartData.length === 0
                    ) {
                      aggregations.markets.buckets.forEach((market) => {
                        const data = market.date_agg.buckets.map((day) => {
                          if (day.max_product_count.value) {
                            return {
                              netName: market.key,
                              x: day.key_as_string.split(' ', 1)[0],
                              y: day.max_product_count.value,
                            };
                          } else {
                            return {
                              netName: market.key,
                              x: day.key_as_string.split(' ', 1)[0],
                              y: null,
                            };
                          }
                        });
                        chartData = [...chartData, data];
                        markets = [...markets, market.key];
                      });
                      chartData.sort((a, b) => b.length - a.length);
                    }
                    return (
                      <GridItem xs={12}>
                        <StyledCard>
                          <CardHeader>
                            <GridContainer>
                              <GridItem xs={6}>
                                <h2>Dark Web Market Trends</h2>
                              </GridItem>
                              <GridItem xs={4}>
                                <GridContainer alignItems="center" spacing={1}>
                                  <h4>Reviews</h4>
                                  <GridItem>
                                    <SwitchGroup
                                      alignItems="flex-end"
                                      style={{ paddingRight: '12px' }}
                                      value={toggleOn}
                                      onChange={() => setToggleOn(!toggleOn)}
                                    />
                                  </GridItem>
                                  <h4>Products</h4>
                                </GridContainer>
                              </GridItem>
                              <GridItem xs={2}>
                                <HelpToolTipButton
                                  size="medium"
                                  tipPlacement="top"
                                  text={`Discover Dark Web Marketplace trends by tracking how products are trending over time. Understand which marketplaces are adding or updating product listings`}
                                />
                              </GridItem>
                              <GridItem>
                                <p>
                                  A 30-day rolling count of the number of
                                  products for top Dark Web Marketplaces over
                                  time{' '}
                                </p>
                              </GridItem>
                            </GridContainer>
                          </CardHeader>
                          <CardBody>
                            {loading ? (
                              <Loader />
                            ) : !(chartData[0] && chartData[0].length > 1) ? (
                              <MissingData />
                            ) : (
                              <VictoryChart
                                theme={VictoryTheme.material}
                                containerComponent={
                                  <VictoryVoronoiContainer
                                    labels={(d) => d.y}
                                  />
                                }
                                padding={{
                                  top: 10,
                                  bottom: 130,
                                  left: 60,
                                  right: 60,
                                }}
                              >
                                <VictoryAxis
                                  theme={VictoryTheme.material}
                                  renderInPortal
                                  tickCount={8}
                                  style={{
                                    tickLabels: {
                                      paddingTop: 20,
                                      angle: 45,
                                      fontSize: 6,
                                    },
                                  }}
                                  tickLabelComponent={
                                    <VictoryLabel dx={5} textAnchor="start" />
                                  }
                                />
                                <VictoryAxis
                                  dependentAxis
                                  label="Product Count"
                                  style={{
                                    axisLabel: {
                                      padding: 50,
                                      fontSize: 8,
                                    },
                                    tickLabels: {
                                      fontSize: 6,
                                    },
                                  }}
                                />
                                <VictoryLegend
                                  x={25}
                                  y={300}
                                  orientation="horizontal"
                                  itemsPerRow={3}
                                  gutter={10}
                                  rowGutter={2}
                                  data={chartData.map((net) => {
                                    return {
                                      name: net[0].netName,
                                      symbol: {
                                        fill: getChartColors(
                                          net[0].netName,
                                          markets,
                                          themeContext.chartColors,
                                        ),
                                      },
                                    };
                                  })}
                                  style={{ labels: { fontSize: 8 } }}
                                />

                                {chartData.map((net) => {
                                  return (
                                    <VictoryLine
                                      data={net}
                                      key={net[0].netName}
                                      labels={({ datum }) => {
                                        if (datum.y) {
                                          return `${
                                            datum.netName
                                          } ${numberWithCommas(
                                            datum.y,
                                          )} products`;
                                        } else {
                                          return null;
                                        }
                                      }}
                                      labelComponent={
                                        <VictoryTooltip
                                          constrainToVisableArea
                                          flyoutStyle={{
                                            fill:
                                              themeContext.colors
                                                .cardBackground,
                                          }}
                                          style={{
                                            labels: { fontSize: 8 },
                                          }}
                                        />
                                      }
                                      style={{
                                        data: {
                                          stroke: getChartColors(
                                            net[0].netName,
                                            markets,
                                            themeContext.chartColors,
                                          ),
                                          strokeWidth: 2,
                                        },
                                      }}
                                    />
                                  );
                                })}
                              </VictoryChart>
                            )}
                          </CardBody>
                        </StyledCard>
                      </GridItem>
                    );
                  }}
                />
              ) : (
                <ReactiveComponent
                  componentId="uniqueReviewsByMarket"
                  customQuery={uniqueReviewsByMarket}
                  defaultQuery={uniqueReviewsByMarket}
                  key="uniqueReviewsByMarket"
                  react={{
                    and: [...filterNames],
                  }}
                  render={(props) => {
                    const { aggregations, loading } = props;
                    let chartData = [];
                    let markets = [];
                    if (
                      !loading &&
                      aggregations &&
                      aggregations.markets &&
                      aggregations.markets.buckets &&
                      aggregations.markets.buckets[0] &&
                      aggregations.markets.buckets[0].date_agg &&
                      aggregations.markets.buckets[0].date_agg.buckets &&
                      chartData.length === 0
                    ) {
                      aggregations.markets.buckets.forEach((market) => {
                        const data = market.date_agg.buckets.map((day) => {
                          if (day.max_reviews.value) {
                            return {
                              netName: market.key,
                              x: day.key_as_string.split(' ', 1)[0],
                              y: day.max_reviews.value,
                            };
                          } else {
                            return {
                              netName: market.key,
                              x: day.key_as_string.split(' ', 1)[0],
                              y: null,
                            };
                          }
                        });

                        chartData = [...chartData, data];
                        markets = [...markets, market.key];
                      });
                      chartData.sort((a, b) => b.length - a.length);
                    }
                    return (
                      <GridItem xs={12}>
                        {' '}
                        <StyledCard>
                          <CardHeader>
                            <GridContainer>
                              <GridItem xs={6}>
                                <h2>Dark Web Market Trends</h2>
                              </GridItem>
                              <GridItem xs={4}>
                                <GridContainer alignItems="center">
                                  <h4>Reviews</h4>
                                  <GridItem>
                                    <SwitchGroup
                                      alignItems="flex-end"
                                      style={{ paddingRight: '12px' }}
                                      value={toggleOn}
                                      onChange={() => setToggleOn(!toggleOn)}
                                    />
                                  </GridItem>
                                  <h4>Products</h4>
                                </GridContainer>{' '}
                              </GridItem>
                              <GridItem xs={2}>
                                <HelpToolTipButton
                                  size="medium"
                                  tipPlacement="top"
                                  text={`Discover Dark Web Marketplace trends by tracking how reviews are trending over time. Reviews ${' '}
                                  are often mandatory after a sale, creating a proxy for volume of sales and popularity for a given marketplace.`}
                                />
                              </GridItem>
                              <GridItem>
                                <p>
                                  A 30-day rolling count of the number of
                                  reviews for top Dark Web Marketplaces over
                                  time{' '}
                                </p>
                              </GridItem>
                            </GridContainer>
                          </CardHeader>
                          <CardBody>
                            {loading ? (
                              <Loader />
                            ) : !(
                                chartData[0] &&
                                Object.entries(chartData[0]).length > 1
                              ) ? (
                              <MissingData />
                            ) : (
                              <VictoryChart
                                theme={VictoryTheme.material}
                                containerComponent={
                                  <VictoryVoronoiContainer
                                    labels={(d) => d.y}
                                  />
                                }
                                padding={{
                                  top: 10,
                                  bottom: 130,
                                  left: 60,
                                  right: 60,
                                }}
                              >
                                <VictoryAxis
                                  theme={VictoryTheme.material}
                                  renderInPortal
                                  tickCount={8}
                                  style={{
                                    tickLabels: {
                                      paddingBottom: 40,
                                      angle: 45,
                                      fontSize: 6,
                                    },
                                  }}
                                  tickLabelComponent={
                                    <VictoryLabel dx={10} textAnchor="start" />
                                  }
                                />
                                <VictoryAxis
                                  dependentAxis
                                  label="Review Count"
                                  style={{
                                    axisLabel: {
                                      padding: 50,
                                      fontSize: 8,
                                    },
                                    tickLabels: {
                                      fontSize: 6,
                                    },
                                  }}
                                />
                                <VictoryLegend
                                  x={20}
                                  y={300}
                                  orientation="horizontal"
                                  itemsPerRow={3}
                                  gutter={10}
                                  rowGutter={2}
                                  data={chartData.map((net) => {
                                    return {
                                      name: net[0].netName,
                                      symbol: {
                                        fill: getChartColors(
                                          net[0].netName,
                                          markets,
                                          themeContext.chartColors,
                                        ),
                                      },
                                    };
                                  })}
                                  style={{ labels: { fontSize: 8 } }}
                                />

                                {chartData.map((net) => {
                                  return (
                                    <VictoryLine
                                      data={net}
                                      key={net[0].netName}
                                      y={(datum) => datum.y}
                                      labels={({ datum }) => {
                                        if (datum.y) {
                                          return `${
                                            datum.netName
                                          } ${numberWithCommas(
                                            datum.y,
                                          )} Reviews`;
                                        } else {
                                          return null;
                                        }
                                      }}
                                      labelComponent={
                                        <VictoryTooltip
                                          flyoutStyle={{
                                            fill:
                                              themeContext.colors
                                                .cardBackground,
                                          }}
                                          constrainToVisableArea
                                        />
                                      }
                                      // events={[ */}
                                      //   {
                                      //     target: 'data',
                                      //     eventHandlers: {
                                      //       onClick: () => {
                                      //         return [
                                      //           {
                                      //             target: 'labels',
                                      //             mutation: (props) => {
                                      //               handleFilterClick(
                                      //                 // {
                                      //                 //   SiteNameFilter: props.datum.xName,
                                      //                 // },
                                      //                 filterState,
                                      //                 setFilterState,
                                      //               );
                                      //             },
                                      //           },
                                      //         ];
                                      //       },
                                      //     },
                                      //   },
                                      // ]}
                                      style={{
                                        data: {
                                          stroke: getChartColors(
                                            net[0].netName,
                                            markets,
                                            themeContext.chartColors,
                                          ),
                                          strokeWidth: 2,
                                        },
                                      }}
                                    />
                                  );
                                })}
                              </VictoryChart>
                            )}
                          </CardBody>
                        </StyledCard>
                      </GridItem>
                    );
                  }}
                />
              )}
            </GridItem>
          </GridContainer>

          {/* <GridContainer style={{ position: 'relative', zIndex: '0' }}>
          <ReactiveComponent
            componentId="aiContentByMarket"
            customQuery={aiContentByMarket}
            react={{
              and: [...filterNames],
            }}
            render={(props) => {
              const { aggregations, loading } = props;
              let PieCharts = [];
              let chartData = [];
              if (
                !loading &&
                aggregations &&
                aggregations.markets &&
                aggregations.markets.buckets
              ) {
                aggregations.markets.buckets
                  .filter((bucket) => {
                    return bucket.content.buckets.length !== 0;
                  })
                  .forEach((bucket) => {
                    const title = bucket.key;
                    chartData = bucket.content.buckets.map((content) => {
                      return { x: content.key, y: content.doc_count };
                    });
                    PieCharts = [
                      ...PieCharts,
                      <GridItem xs={4} key={title}>
                        <Card>
                          <CardHeader>
                            <h4>{title}</h4>
                          </CardHeader>
                          <CardBody>
                            {loading ||
                            !(
                              chartData[0] &&
                              Object.entries(chartData[0]).length
                            ) ? (
                              <Loader />
                            ) : (
                              <VictoryPie
                                events={[
                                  {
                                    target: 'data',
                                    eventHandlers: {
                                      onClick: () => {
                                        return [
                                          {
                                            target: 'labels',
                                            mutation: (props) => {
                                              handleFilterOn(
                                                {
                                                  SiteNameFilter: title,
                                                  ContentTagsFilter:
                                                    props.datum.xName,
                                                },
                                                filterState,
                                                setFilterState,
                                              );
                                            },
                                          },
                                        ];
                                      },
                                    },
                                  },
                                ]}
                                theme={VictoryTheme.material}
                                data={chartData}
                                labels={({ datum }) => `${datum.x}: ${datum.y}`}
                                labelComponent={<CustomLabel />}
                                style={{
                                  data: {
                                    fill: (d) => pieDataColorMap(d.datum.x),
                                  },
                                }}
                              />
                            )}
                            <VictoryLegend
                              x={5}
                              y={0}
                              orientation="horizontal"
                              itemsPerRow={3}
                              gutter={5}
                              rowGutter={5}
                              style={{ labels: { fontSize: 25 } }}
                              data={chartData.map((item) => {
                                return {
                                  name: item.x,
                                  symbol: { fill: pieDataColorMap(item.x) },
                                };
                              })}
                            />
                          </CardBody>
                        </Card>
                      </GridItem>,
                    ];
                  });
              }

              return PieCharts;
            }}
          ></ReactiveComponent>
        </GridContainer> */}
          <BluestoneLogoComponent logo={logo} />
        </div>
      </div>
      {/* </div> */}
    </Cluster>
  );
};

export default MarketsDashboard;
