import React from 'react';
import styled from 'styled-components';

import Button from '../../Button/Button';
import Grid from '../../core/Grid/Grid';

const SwitchGroupContainer = styled(Grid)`
  height: 2em;
  grid-template-columns: repeat(
    ${(props) => props.children.length || 1},
    minmax(0, 1fr)
  );
`;

const SwitchItemContainer = styled(Grid)``;

const SwitchItem = styled(Button)``;

const SwitchSelect = ({ onClick, options, value, accessor = 'value' }) => {
  return (
    <SwitchGroupContainer template="option">
      {options.map((o) => {
        const active = value === o[accessor];
        return (
          <SwitchItem
            key={accessor + o.label}
            active={active}
            secondary={!active}
            onClick={() => onClick(o[accessor])}
          >
            {o.label}
          </SwitchItem>
        );
      })}
    </SwitchGroupContainer>
  );
};

export default SwitchSelect;
