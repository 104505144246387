import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';

import { StateContext } from '../Search/StateProviderContext/StateProviderContext.js';

import CheckboxFilter from '../Search/CheckboxFilter/CheckboxFilter';
import ToggleFilter from '../Search/ToggleFilter/ToggleFilter';
import Link from '@material-ui/core/Link';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import ActiveFilters from '../Search/ActiveFilters/ActiveFilters';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateTimePicker from '../Search/DateTimePicker';
import { SidebarFilters } from '../../filters/UniversalFilters.js';
import DataFilter from 'darkblue-ui/Search/DataFilter/DataFilter.js';

const FilterHeader = styled.div`
  height: 18px;
  width: 222px;
  color: #082441;
  font-family: 'Red Hat Text';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 18px;
  padding-right: 15px;
  padding-bottom: 100px;
  .icons-header-container {
    &:hover {
      cursor: pointer;
    }
  }
  p {
    color: ${(props) => props.theme.colors.primaryText} !important;
    font-family: 'Red Hat Text';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;
  }
`;

const StyledFilterSidebar = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['expanded'].includes(prop) && defaultValidatorFn(prop),
})`
  box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, 0.02),
    0 1.1px 3.7px rgba(0, 0, 0, 0.028), 0 2.1px 6.9px rgba(0, 0, 0, 0.035),
    0 3.8px 12.3px rgba(0, 0, 0, 0.042), 0 7.1px 23px rgba(0, 0, 0, 0.05),
    0 17px 55px rgba(0, 0, 0, 0.07);
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 250px;
  transform: translateX(-30%);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: left;
  position: fixed;
  transition-property: top bottom width;
  transition-duration: 0.2s, 0.2s, 0.35s;
  transition-timing-function: linear, linear, ease;
  color: inherit;
  padding: 0px 5px;
  padding-bottom: 30px;
  background-color: ${(props) => props.theme.colors.filterSidebar};
  svg.filter-header-icons {
    vertical-align: middle;
    margin-right: 10px;
    font-size: 16px;
    color: #67737e;
    letter-spacing: 0;
    text-align: center;
  }

  ${(props) =>
    props.expanded &&
    css`
      transform: translateX(-35%);
      min-width: 200px;
      margin-left: 10px;
    `}
  ${(props) =>
    props.hide &&
    css`
      transform: translateX(-1000%);
    `}
  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }
`;
const LinkParagraph = styled(Link)`
  display: inline;
  font-family: 'Red Hat Text', regular;
  font-size: 12px;
  letter-spacing: 0;
  color: ${(props) => props.theme.colors.primaryText} !important;
  line-height: 15px;
`;
const AdvancedFiltersToggle = styled.a`
  text-align: center;
  padding-top: 12px;
  &:hover {
    cursor: pointer;
  }
`;

const ThemedExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${(props) => props.theme.colors.primary} !important;
  padding: 0px !important;
  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const StyledAccordion = styled(Accordion)`
  margin: 10px 0px;
  position: unset !important;
  background-color: ${(props) => props.theme.colors.filterSidebar} !important;
  color: ${(props) => props.theme.colors.filterSidebarText} !important;
  &&& p {
    color: ${(props) => props.theme.colors.filterSidebarText} !important;
  }
  &&& .MuiInput-underline:after .MuiInput-underline:after {
    color: ${(props) => props.theme.colors.filterSidebarText};
  }

  &&& .MuiAccordionSummary-root {
    padding: 0px 16px !important;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  margin: -20px 0px !important;
  display: inline-flex !important;

  .MuiButtonBase-root {
    padding: 0px !important;
  }
`;
const StyledDetails = styled(AccordionDetails)`
  root: {
    background-color: ${(props) => props.theme.colors.filterSidebar};
    color: ${(props) => props.theme.colors.filterSidebarText};
  }
`;

const FilterSidebar = ({
  categories,
  setShowFilterModal = () => null,
  expanded,
  hideExpanded,
  handleFilterClose,
  hide,
  apiKey,
  index,
  currentIndex,
  setCurrentIndex,
  layers,
}) => {
  const { filterState, setFilterState } = useContext(StateContext);
  const toggleShowModal = (modalType) => {
    switch (modalType) {
      case 'filterModal':
        setShowFilterModal(true);
        break;
    }
  };

  /**
   * Resets filter state based on default state.
   * @param  {boolean} isClearFilter - A boolean to indicate which default state to set to.
   */
  const resetFilterState = (isClearFilter) => {
    if (isClearFilter) {
      setFilterState({});
    } else {
      const { Searchbar = {}, ...rest } = filterState;
      setFilterState(
        Object.entries(Searchbar).length > 0 ? { Searchbar: Searchbar } : {},
      );
    }
  };
  return (
    <StyledFilterSidebar hide={hide} expanded={expanded}>
      <FilterHeader>
        <div style={{ display: 'inline-block' }}>
          <p style={{ padding: '0px 16px' }}>
            Filters{' '}
            {/* <span style={{ fontSize: '15px' }}> ({appliedFiltersCount})</span> */}
          </p>
        </div>
        <div
          className="icons-header-container"
          style={{
            display: 'inline-block',
            float: 'right',
            marginTop: '18px',
          }}
        >
          <div onClick={handleFilterClose} style={{ cursor: 'pointer' }}>
            <VisibilityOffIcon
              className="filter-header-icons"
              fontSize="small"
            />
            <LinkParagraph>Hide Filters</LinkParagraph>
          </div>
          <div
            onClick={() => {
              resetFilterState(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            <HighlightOffIcon
              className="filter-header-icons"
              fontSize="small"
            />
            <LinkParagraph>Reset</LinkParagraph>
          </div>
          <div
            onClick={() => {
              resetFilterState(false);
            }}
            style={{ cursor: 'pointer' }}
          >
            <RemoveCircleOutlineIcon
              className="filter-header-icons"
              fontSize="small"
            />
            <LinkParagraph>Clear Filters</LinkParagraph>
          </div>
        </div>
      </FilterHeader>
      <ActiveFilters />
      {categories.map((category) => {
        if (category === 'Toggle Filters') {
          return (
            <StyledAccordion key={category} elevation={0}>
              <StyledAccordionSummary
                expandIcon={<ThemedExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {category}
              </StyledAccordionSummary>
              <AccordionDetails
                style={{
                  width: '100%',
                  marginTop: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {SidebarFilters.filter((f) => {
                  return f.filterCategory === category;
                }).map((filter) => {
                  return (
                    <ToggleFilter
                      key={filter.filterName}
                      filterState={filterState}
                      setFilterState={setFilterState}
                      index={index}
                      apiKey={apiKey}
                      {...filter}
                    />
                  );
                })}
              </AccordionDetails>
            </StyledAccordion>
          );
        } else {
          return SidebarFilters.filter((f) => {
            return f.filterCategory === category;
          }).map((filter) => {
            switch (filter.filterType) {
              case 'SelectFilter':
                return (
                  <StyledAccordion key={filter.filterName} elevation={0}>
                    <StyledAccordionSummary
                      expandIcon={<ThemedExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {filter.filterLabel}
                    </StyledAccordionSummary>
                    <AccordionDetails
                      style={{ width: '100%', marginTop: '10px' }}
                    >
                      <CheckboxFilter
                        key={filter.filterCategory}
                        filterState={filterState}
                        index={index}
                        apiKey={apiKey}
                        {...filter}
                      />
                    </AccordionDetails>
                  </StyledAccordion>
                );
              case 'DateTimeFilter': {
                return (
                  <StyledAccordion
                    key={filter.filterName}
                    style={{ width: '100%' }}
                    elevation={0}
                  >
                    <StyledAccordionSummary
                      expandIcon={<ThemedExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {filter.filterLabel}
                    </StyledAccordionSummary>
                    <AccordionDetails
                      style={{ width: '100%', marginTop: '10px' }}
                    >
                      <DateTimePicker
                        elasticPath={filter.elasticPath}
                        filterName={filter.filterName}
                        index={index}
                      />
                    </AccordionDetails>
                  </StyledAccordion>
                );
              }
              case 'DataFilter': {
                const specialFilter = index.includes('darkflow')
                  ? filter
                  : { elasticPath: 'timestamp', filterName: 'DateTimeFilter' };
                return (
                  <StyledAccordion
                    key={filter.filterName}
                    style={{ width: '100%' }}
                    elevation={0}
                  >
                    <StyledAccordionSummary
                      expandIcon={<ThemedExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {filter.filterLabel}
                    </StyledAccordionSummary>
                    <AccordionDetails
                      style={{ width: '100%', marginTop: '10px' }}
                    >
                      <DataFilter
                        currentIndex={currentIndex}
                        filterState={filterState}
                        setCurrentIndex={setCurrentIndex}
                        setFilterState={setFilterState}
                        filterName={filter.filterName}
                        layers={layers}
                      />
                    </AccordionDetails>
                  </StyledAccordion>
                );
              }
            }
          });
        }
      })}
      {hideExpanded ? null : (
        <>
          <AdvancedFiltersToggle
            onClick={() => {
              toggleShowModal('filterModal');
            }}
          >
            Advanced Filters
          </AdvancedFiltersToggle>
        </>
      )}
    </StyledFilterSidebar>
  );
};

export default FilterSidebar;
