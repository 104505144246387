import { useRef } from 'react';
import bodybuilder from 'bodybuilder';
import mapboxgl from '!mapbox-gl';
/* eslint import/no-webpack-loader-syntax: off */
import _ from 'lodash';
import dayjs from 'dayjs';

// internal imports
import { fetchData } from 'darkblue-ui/Search/utils';
import { getSearchFields } from 'filters/UniversalFilters';

// controls events that indicate a hover state on clusters
const handleClusterHover = ({ map, layerId }) => {
  const sourceId = map.getLayer(layerId).source;
  let hoverId = null;

  map.on('mouseenter', layerId, (e) => {
    map.getCanvas().style.cursor = 'pointer';
    hoverId = e.features[0].id;

    if (hoverId) {
      map.setFeatureState(
        {
          source: sourceId,
          id: hoverId,
        },
        {
          active: true,
          strokeColor: 'white',
        },
      );
    }
  });

  map.on('mouseleave', layerId, (e) => {
    const features = map.queryRenderedFeatures(e.point) || [];
    const additionalMarkers = features.filter(
      (feature) => feature.layer.type === 'circle',
    );

    // if there are no other markers, revert cursor to default
    if (additionalMarkers.length === 0) {
      map.getCanvas().style.cursor = '';
    }

    if (hoverId) {
      map.setFeatureState(
        {
          source: sourceId,
          id: hoverId,
        },
        {
          active: false,
          strokeColor: 'white',
        },
      );
    }
  });

  map.on('click', layerId, (e) => {
    const cluster = map.queryRenderedFeatures(e.point, { layers: [layerId] });
    const clusterId = cluster[0]?.properties?.cluster_id;
    const clusterSource = map.getSource(cluster[0].source);

    // get children of cluster and fit map bounds to them
    clusterSource.getClusterChildren(clusterId, (error, features) => {
      if (!error) {
        const coordinates = features.map(
          (feature) => feature.geometry.coordinates,
        );
        const latLng = new mapboxgl.LngLatBounds(
          coordinates[0],
          coordinates[0],
        );
        const bounds = coordinates.reduce((bounds, coord) => {
          return bounds.extend(coord);
        }, latLng);

        map.fitBounds(bounds, {
          padding: 200,
        });
      }
    });
  });
};

// handles active state for individual markers
const handleMarkerState = ({
  map,
  sourceId,
  layerId,
  staticProperties = {},
}) => {
  let hoverId = null;
  let clickEventId = null;

  map.on('mouseenter', layerId || sourceId, (event) => {
    map.getCanvas().style.cursor = 'pointer';

    if (event.features.length === 0) return;

    // When the mouse moves over the layer, update the
    // feature state for the feature under the mouse
    if (sourceId) {
      map.removeFeatureState({
        source: sourceId,
        id: hoverId,
      });
    }

    hoverId = event.features[0].id;

    const existingColor = map.getFeatureState({ source: sourceId, id: hoverId })
      ?.strokeColor;
    const strokeColor =
      existingColor && existingColor !== 'white' ? existingColor : 'white';

    map.setFeatureState(
      {
        source: sourceId,
        id: hoverId,
      },
      {
        active: true,
        strokeColor,
        ...staticProperties,
      },
    );
  });

  map.on('click', layerId || sourceId, () => {
    if (hoverId) {
      const existingColor = map.getFeatureState({
        source: sourceId,
        id: hoverId,
      })?.strokeColor;
      const strokeColor =
        existingColor && existingColor !== 'white' ? existingColor : 'white';
      // set clicked feature to active
      map.setFeatureState(
        {
          source: sourceId,
          id: hoverId,
        },
        {
          active: true,
          strokeColor,
          ...staticProperties,
        },
      );
      clickEventId = hoverId;
    }
  });

  // When the mouse leaves the layer, update the
  // feature state of the previously hovered feature
  map.on('mouseleave', layerId || sourceId, (e) => {
    hoverId = null;

    // Reset the cursor style
    map.getCanvas().style.cursor = '';
  });

  hoverId = null;
  clickEventId = null;
};

/**
 * Adds a custom layer to the map and returns the map itself.
 * @param  {Object} map the instance of the map to add layers to.
 * @param  {Object} properties custom properties passed into the mapbox addSource method.
 * @param  {String} id the id of the layer that will be added.
 * @param  {String} color the color of markers that will be added.
 * @param  {String} highlightColor the outline color of markers that will appear on hover or click.
 * @param  {Object} geojson a list of features to be added to the map in GeoJSON format.
 * @returns Map instance passed in after new layers are added
 */
const addLayerToMap = ({ map, properties = {}, id, color, geojson }) => {
  const clusterId = `${id}-cluster`;
  const defaultProperties = {
    cluster: true,
    clusterMaxZoom: 14, // Max zoom to cluster points on
    clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    generateId: true,
  };

  map.addSource(id, {
    type: 'geojson',
    data: geojson || {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: { type: 'Point', coordinates: [] },
        },
      ],
    },
    ...defaultProperties,
    ...properties,
  });

  // clustered
  map.addLayer({
    id: clusterId,
    type: 'circle',
    source: id,
    filter: ['has', 'point_count'],
    paint: {
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      'circle-stroke-width': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        4, // when the features hover state === true
        2,
      ],
      'circle-color': color,
      'circle-stroke-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        ['feature-state', 'strokeColor'], // when the features hover state === true
        'white', // when it is false
      ],
    },
  });

  // unclustered, single points
  map.addLayer({
    id,
    type: 'circle',
    source: id,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-radius': {
        base: 1.4,
        stops: [[1, 5], [22, 180]],
      },
      'circle-stroke-width': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        4, // when the features hover state === true
        2,
      ],
      'circle-color': color,
      'circle-stroke-color': [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        ['feature-state', 'strokeColor'], // when the features hover state === true
        'white', // when it is false
      ],
    },
  });

  // adds point count for clusters
  map.addLayer({
    id: `${id}-cluster-count`,
    type: 'symbol',
    source: id, // source for point count
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
  });

  handleClusterHover({ map, layerId: clusterId });
  map.moveLayer(`${id}-cluster-count`); // ensure cluster count remains on top of cluster itself
};

const removeLayerFromMap = (map, id) => {
  if (map.getLayer(`${id}-cluster`) && map.getLayer(`${id}-cluster-count`)) {
    map.removeLayer(id);
    map.removeLayer(`${id}-cluster`);
    map.removeLayer(`${id}-cluster-count`);
    map.removeSource(id);
  } else {
    map.removeLayer(id);
    map.removeSource(id);
  }
};

const handleDraw = (map, drawInstance, filterState, setFilterState) => {
  const allShapes = drawInstance
    .getAll()
    .features.map(({ geometry, id }) => {
      const latLong = geometry.coordinates.map((shape) => {
        return shape.map((coord) => mapboxgl.LngLat.convert(coord));
      });
      return {
        id,
        type: geometry.type,
        geoJSON: geometry.coordinates,
        latLong,
        boundsQuery: false,
        precision: 8, //Math.min(Math.max(map.getZoom(), 3), 8),
      };
    })
    .flat();

  const newFilterState = {
    ...filterState,
    ...{
      ['GeoFilter']: {
        elasticPath: 'geo_shape',
        value: allShapes,
      },
    },
  };

  setFilterState(newFilterState);
};

const handleDelete = ({
  filterState,
  setFilterState,
  deletedFeatureIds = [],
}) => {
  const cleanFilterState = {
    ...filterState,
    ...{
      ['GeoFilter']: {
        elasticPath: 'geo_shape',
        value: [
          ...filterState.GeoFilter?.value.filter(
            (val) => !deletedFeatureIds.includes(val.id),
          ),
        ],
      },
    },
  };
  setFilterState(cleanFilterState);
};

const handleBounds = ({
  map,
  filterState,
  setFilterState,
  setMapIsLoading,
  filterName,
}) => {
  const bounds = map.getBounds();
  const northWest = bounds.getNorthWest();
  const northEast = bounds.getNorthEast();
  const southEast = bounds.getSouthEast();
  const southWest = bounds.getSouthWest();
  const latLong = [southWest, southEast, northEast, northWest];
  const onlyShapeValues =
    filterState.GeoFilter?.value &&
    filterState.GeoFilter.value.filter((val) => val.boundsQuery !== true);

  const globalBounds = [
    [-180, -90],
    [180, -90],
    [180, 90],
    [-180, 90],
    [-180, -90],
  ];

  const timeState =
    filterName === 'MapsDateTimeFilter' && !filterState[filterName]
      ? {
          [filterName]: {
            value: {
              startValue: dayjs()
                .subtract(1, 'hour')
                .format('YYYY-MM-DD HH:mm:ss'),
              endValue: dayjs().format('YYYY-MM-DD HH:mm:ss'),
              valueDescriptor: '1-hour',
            },
            elasticPath: ['@timestamp'],
          },
        }
      : {};
  const newFilterState =
    filterState.GeoFilter && Object.entries(filterState.GeoFilter).length > 0
      ? {
          ...filterState,
          ...timeState,
          ...{
            ['GeoFilter']: {
              ...filterState.GeoFilter,
              ...{
                value: [
                  ...filterState.GeoFilter.value.filter(
                    (val) => val.boundsQuery === false,
                  ),
                  {
                    id: _.uniqueId(),
                    type: 'Polygon',
                    geoJSON: [globalBounds],
                    latLong,
                    boundsQuery: true,
                    precision: 8, //Math.min(Math.max(map.getZoom(), 3), 8),
                  },
                ],
              },
            },
          },
        }
      : {
          ...filterState,
          ...timeState,
          ...{
            ['GeoFilter']: {
              elasticPath: 'geo_shape',
              value: [
                {
                  id: _.uniqueId(),
                  type: 'Polygon',
                  geoJSON: [globalBounds],
                  latLong,
                  boundsQuery: true,
                  precision: 8, //Math.min(Math.max(map.getZoom(), 3), 8),
                },
              ],
            },
          },
        };

  if (
    !onlyShapeValues ||
    (onlyShapeValues.length === 0 && !filterState.GeoFilter?.cancelQuery)
  ) {
    setMapIsLoading(true);
    setFilterState(newFilterState);
  }
};

const handleAdTechState = ({
  map,
  field,
  adTechData = [],
  point,
  filterState,
  setFilterState,
}) => {
  if (map && map.getSource(field)) {
    const cleanFilterState = {
      ...filterState,
      ...{
        ['GeoFilter']: {
          cancelQueries: true,
          elasticPath: '',
          value: [
            {
              adTech: false,
              cancelQueries: true,
              id: _.uniqueId(),
              type: 'Point',
              coordinates: point,
              latLong: point,
              field,
            },
            ...adTechData.map(({ latitude, longitude, ...rest }) => {
              return {
                adTech: true,
                coordinates: [longitude, latitude],
                latLong: point,
                ...rest,
              };
            }),
          ],
        },
      },
    };

    setFilterState(cleanFilterState);
  }
};

const getGeoRecords = async ({
  filterState,
  point,
  field,
  distance,
  index,
  apiKey,
}) => {
  let geoQuery = bodybuilder();
  if (Array.isArray(field)) {
    field.forEach((f) => {
      geoQuery.size(10).orFilter('geo_distance', {
        // similar to google query on geo_distance
        distance,
        [f]: {
          ...point,
        },
      });
    });
  } else {
    geoQuery.size(10).orFilter('geo_distance', {
      distance,
      [field]: {
        ...point,
      },
    });
  }

  if (index.includes('darkflow') && filterState?.MapsDateTimeFilter?.value) {
    const { startValue, endValue } = filterState?.MapsDateTimeFilter?.value;
    geoQuery.query('range', '@timestamp', {
      gte: startValue,
      lte: endValue,
      format: 'yyyy-MM-dd HH:mm:ss',
    });
  } else if (filterState?.MapSearchbar) {
    geoQuery.query('simple_query_string', {
      query: filterState?.MapSearchbar?.value,
      fields: getSearchFields([
        'communications',
        'sites',
        'profiles',
        'markets',
        'docs',
      ]),
      default_operator: 'and',
    });
  }

  const data = await fetchData({
    canceled: false,
    query: geoQuery.build(),
    apiKey,
    index,
  });

  return data?.hits?.hits.map((r) => r._source);
};

const useMapRef = (data) => {
  const dataRef = useRef(data);
  dataRef.current = () => data;

  return dataRef.current();
};

const zoomLevelToPrecision = (zoomLevel) => {
  const lookup = [
    { width: 5000, height: 5000 },
    { width: 1250, height: 625 },
    { width: 156, height: 156 },
    { width: 39.1, height: 19.5 },
    { width: 4.89, height: 4.89 },
    { width: 1.22, height: 0.61 },
    { width: 0.153, height: 0.153 },
    { width: 0.0382, height: 0.019 },
  ];
  const precisionIndex = Math.min(Math.max(zoomLevel, 0), lookup.length - 1);

  // Mapbox's precision level is offset one from the standard
  return lookup[precisionIndex].width;
};

const resetMarker = ({ map, selectedFeature }) => {
  const { source, id } = selectedFeature;

  map.setFeatureState(
    {
      source,
      id,
    },
    {
      active: false,
      strokeColor: 'white',
    },
  );
};

export {
  addLayerToMap,
  removeLayerFromMap,
  handleDelete,
  handleBounds,
  handleDraw,
  getGeoRecords,
  useMapRef,
  zoomLevelToPrecision,
  handleAdTechState,
  handleMarkerState,
  resetMarker,
};
