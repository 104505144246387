import React from 'react';
import styled, { css } from 'styled-components';

import IconWrapper from '../Icons/IconWrapper';

const StyledButton = styled.button`
  p{color: ${(props) => props.theme.colors.plume};}
  ${(props) =>
    props.color &&
    css`
      background-color: ${(props) => props.theme.colors[props.color]};
    `}

  &:hover{
    background-color: ${(props) => props.theme.colors.altPrimary}
  }
  border:none;
  ${(props) =>
    props.primary &&
    css`
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.plume};
      &:hover {
        background-color: ${(props) => props.theme.colors.plume};
        color: ${(props) => props.theme.colors.primary};
      }
    `}

  ${(props) =>
    props.primary &&
    props.color === 'warning' &&
    css`
      background-color: ${(props) => props.theme.colors.warning};
      &:hover {
        background-color: ${(props) => props.theme.colors.plume};
        color: ${(props) => props.theme.colors.warning};
        border: 2px solid ${(props) => props.theme.colors.warning};
        & > * {
          color: ${(props) => props.theme.colors.warning};
        }
      }
    `}

  ${(props) =>
    props.primary &&
    props.color === 'danger' &&
    css`
      background-color: ${(props) => props.theme.colors.danger};
      &:hover {
        background-color: ${(props) => props.theme.colors.plume};
        color: ${(props) => props.theme.colors.danger};
        border: 2px solid ${(props) => props.theme.colors.danger};
        & > * {
          color: ${(props) => props.theme.colors.danger};
        }
      }
    `}
    ${(props) =>
      props.secondary &&
      css`
        background-color: ${(props) => props.theme.colors.plume};
        border: 2px solid ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.primary} !important;
        &:hover {
          background-color: ${(props) => props.theme.colors.plume};
          border: 2px solid ${(props) => props.theme.colors.altPrimary};
          color: ${(props) => props.theme.colors.altPrimary} !important;
        }

        & > p {
          color: ${(props) => props.theme.colors.primary} !important;
        }
      `}

  ${(props) =>
    props.secondary &&
    props.color === 'warning' &&
    css`
      background-color: ${(props) => props.theme.colors.plume};
      border: 2px solid ${(props) => props.theme.colors.warning};
      color: ${(props) => props.theme.colors.warning};
      &:hover {
        background-color: ${(props) => props.theme.colors.warning};
        border: 2px solid ${(props) => props.theme.colors.warning};
        color: ${(props) => props.theme.colors.plume};
      }
    `}
  
  ${(props) =>
    props.secondary &&
    props.color === 'danger' &&
    css`
      background-color: ${(props) => props.theme.colors.plume};
      border: 2px solid ${(props) => props.theme.colors.danger};
      color: ${(props) => props.theme.colors.danger};
      &:hover {
        background-color: ${(props) => props.theme.colors.danger};
        border: 2px solid ${(props) => props.theme.colors.danger};
        color: ${(props) => props.theme.colors.plume};
      }
    `}


  ${(props) =>
    props.tertiary &&
    css`
      background-color: transparent;
      border: none;
      color: ${(props) => props.theme.colors.primary};
      &:hover {
        background-color: transparent;
        border: transparent;
        color: ${(props) => props.theme.colors.altPrimary};
      }
    `}
  
  ${(props) =>
    props.tertiary &&
    props.color === 'warning' &&
    css`
      background-color: transparent;
      border: none;
      color: ${(props) => props.theme.colors.warning};
      &:hover {
        background-color: transparent;
        border: transparent;
        color: ${(props) => props.theme.colors.warning};
        opacity: 0.5;
      }
    `}
  ${(props) =>
    props.tertiary &&
    props.color === 'danger' &&
    css`
      background-color: transparent;
      border: none;
      color: ${(props) => props.theme.colors.danger};
      &:hover {
        background-color: transparent;
        border: transparent;
        color: ${(props) => props.theme.colors.danger};
        opacity: 0.5;
      }
    `}

  ${(props) => props.size === 'sm' && css``}
  ${(props) => props.size === 'lg' && css``}
  ${(props) =>
    props.rounded &&
    css`
      border: none;
      padding: 15px 15px;
    `}
  ${(props) =>
    props.pagination &&
    css`
      min-width: fit-content;
      width: 30px;
      height: 30px;
      text-transform: uppercase;
      background-color: ${(props) => props.theme.colors.altPrimary} !important;
      ${(props) =>
        !props.active &&
        css`
          background-color: transparent !important;
          font-weight: 400;
          border: none;
          & p {
            color: ${(props) => props.theme.colors.primary};
          }
          &:hover {
            background: ${(props) =>
              props.theme.colors.primaryOpacity} !important;
            & > * {
              color: ${(props) => props.theme.colors.primary};
            }
          }
          & > * {
            color: ${(props) => props.theme.colors.primary};
          }
        `};
    `}

  ${(props) =>
    props.datePicker &&
    css`
      height: 36px;
      width: 42px;
      border: 1px solid ${(props) => props.theme.colors.charcoalOpacity};
      border-radius: 0px;
      background-color: #e6ecf2;
      margin-right: 4px;

      ${(props) =>
        props.active &&
        css`
          &:hover {
            background-color: ${(props) => props.theme.colors.primary};
            & > * {
              color: ${(props) => props.theme.colors.plume};
            }
          }
        `}

      ${(props) =>
        props.off &&
        css`
          background-color: ${(props) => props.theme.colors.fog};
          border: 1px solid rgba(103, 115, 126, 0.38);
          & > * {
            color: ${(props) => props.theme.colors.charcoal};
          }
          &:hover {
            & > * {
              color: ${(props) => props.theme.colors.plume};
            }
          }
        `}
    `}

  ${(props) =>
    props.selector &&
    css`
      width: 66px;
      height: 36px
      border-radius:0px;
      border: 2px solid transparent;
      
     ${props.color === 'danger' &&
       css`
         background-color: ${(props) => props.theme.colors.danger};
         &:hover {
           background-color: ${(props) => props.theme.colors.plume};
           color: ${(props) => props.theme.colors.danger};
           border: 2px solid ${(props) => props.theme.colors.danger};
           & > * {
             color: ${(props) => props.theme.colors.danger};
           }
         }
       `}

      ${props.color === 'warning' &&
        css`
          background-color: ${(props) => props.theme.colors.warning};
          border: none;
          color: ${(props) => props.theme.colors.plume};
          &:hover {
            background-color: ${(props) => props.theme.colors.plume};
            border: transparent;
            color: ${(props) => props.theme.colors.warning};
            opacity: 0.5;
          }
        `}

      ${(props) =>
        props.off &&
        css`
          background-color: ${(props) => props.theme.colors.fog};
          border: 1px solid rgba(103, 115, 126, 0.38);
          & > * {
            color: ${(props) => props.theme.colors.charcoal};
          }
          &:hover {
            & > * {
              color: ${(props) => props.theme.colors.plume};
            }
          }
        `}

      ${(props) =>
        props.active &&
        css`
          &:hover {
            background-color: ${(props) => props.theme.colors.primary};
            & > * {
              color: ${(props) => props.theme.colors.plume};
            }
          }
        `}
      
      ${(props) =>
        props.disabled &&
        css`
          border: 1px solid rgba(103, 115, 126, 0.38);
          opacity: 0.3;
          border-radius: 0px;
          background-color: ${(props) => props.theme.colors.fog};

          &:hover {
            cursor: default;
            border: 1px solid rgba(103, 115, 126, 0.38);
          }
        `}
    `}
  
  ${(props) =>
    props.search &&
    css`
      max-height: 100%;
      padding-left: 24px;
      padding-right: 24px;
      max-width: fit-content;
    `}
        
  ${(props) =>
    props.justIcon &&
    css`
      width: 41px;
      height: 41px;
      font-size: 20px;
      min-width: 41px;
      padding-left: 12px;
      padding-right: 12px;

      ${(props) =>
        props.hideBackground &&
        css`
        background-color: transparent;
        border: none;
        height: fit-content;
        & > * {
          color: ${(props) => props.theme.colors.primary};
        }
        &:hover{
          background-color: transparent;
          border; none;
          & > * {
          color: ${(props) => props.theme.colors.altPrimary};
        }
        }
      `}
    `}

    ${(props) =>
      props.active &&
      css`
        background-color: ${(props) => props.theme.colors.primary};
      `}

    ${(props) =>
      props.disabled &&
      css`
        opacity: 0.3;
        &:hover {
          background-color: ${(props) => props.theme.colors.primary};
          cursor: default;
        }
      `}
`;

const getColorPair = (color, theme) => {
  switch (color) {
    case 'danger':
      return { primary: 'red' };
  }
};

const Button = (props) => {
  if (props.justIcon) {
    return (
      <StyledButton {...props}>
        <IconWrapper {...props}>{props.children}</IconWrapper>
      </StyledButton>
    );
  } else if (props.customChild) {
    return <StyledButton {...props}>{props.children}</StyledButton>;
  } else {
    return (
      <StyledButton {...props}>
        <p className="s">{props.children}</p>
      </StyledButton>
    );
  }
};

export default Button;
