import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

// @material-ui/core components

import Sidebar from '../darkblue-ui/Sidebar/Sidebar';
// import { filterRoutes } from 'routes.js';
import { UserConsumer } from 'DBAuthenticator.js';
import Button from '../darkblue-ui/Button/Button';
// import routes from 'routes.js';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import companyLogoDarkMode from 'assets/img/BluestoneLogo.png';
import companyLogoLightMode from 'assets/img/bluestone-logo-light-bg.png';

const lightLogo = require('assets/img/Light.svg');
const lightMiniLogo = require('assets/img/Light.svg');

const darkLogo = require('assets/img/Dark.svg');
const darkMiniLogo = require('assets/img/Dark.svg');

const ViewWrapper = styled.div`
  height: 100px;
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  background-color: ${(props) => props.theme.colors.mainBackground};

  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }
`;

const MainPanel = styled.div`
  position: relative;
  float: right;
  width: ${(props) => {
    if (!props.sideFilter) {
      return props.expanded ? 'calc(100% - 250px)' : 'calc(100% - 124px)';
    } else {
      return props.expanded ? 'calc(100% - 250px)' : 'calc(100% - 100px)';
    }
  }};
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  max-height: 100%;
  height: 100%;
`;

const Content = styled.div`
  padding: 0px 15px;
  min-height: calc(100% - 123px);
  height: calc(100% - 123px);
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
`;

const SidebarContainer = styled.div`
  position: fixed;
`;

const CollapseButton = styled(Button)`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.filterSidebar};
  color: ${(props) => props.theme.colors.filterSidebarText};
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  bottom: 40px;
  border-radius: 0px;

  &:focus {
    outline: none;
  }
`;

const DarkBlue = (props) => {
  const { theme, ...rest } = props;
  const logo = theme === 'light' ? companyLogoLightMode : companyLogoDarkMode;
  const sidebarLogo = theme === 'light' ? lightLogo : darkLogo;
  const layoutLogo = theme === 'light' ? darkLogo : lightLogo;
  const [expanded, setExpanded] = useState(true);
  const [sideFilter, setSideFilter] = useState(false);

  const handleDrawerToggle = () => {
    setExpanded(!expanded);
  };

  const toggleSideFilter = () => {
    setSideFilter(!sideFilter);
  };

  const getRoutes = (
    routes,
    expanded,
    sideFilter,
    toggleSideFilter,
    logo,
    user,
    layoutLogo,
  ) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(
          prop.views,
          expanded,
          sideFilter,
          toggleSideFilter,
          logo,
          user,
          layoutLogo,
        );
      }
      if (
        [
          '',
          '/search',
          '/datasets',
          '/visualizations',
          '/user',
          '/MissionSpace',
          '/Modules',
          'link',
        ].includes(prop.layout)
      ) {
        return (
          <Route path={prop.layout + prop.path} key={key}>
            {React.cloneElement(<prop.component />, {
              setTheme: props.setTheme,
              theme: props.theme,
              expanded: expanded,
              sideFilter: sideFilter,
              toggleSideFilter: toggleSideFilter,
              logo: logo,
              user,
              layoutLogo,
            })}
          </Route>
        );
      } else {
        return <h1 style={{ color: 'white' }}>Page Not Found</h1>;
      }
    });
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setExpanded(false);
    }
  };

  return (
    <UserConsumer>
      {(userSess) => (
        <ViewWrapper>
          <SidebarContainer>
            <Sidebar
              routes={userSess.routes}
              handleDrawerToggle={handleDrawerToggle}
              logo={sidebarLogo}
              expanded={expanded}
              user={userSess}
              setExpanded={setExpanded}
              miniLogo={theme === 'light' ? darkMiniLogo : lightMiniLogo}
              {...rest}
            />
            <CollapseButton justIcon onClick={() => setExpanded(!expanded)}>
              {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </CollapseButton>
          </SidebarContainer>

          <MainPanel sideFilter={sideFilter} expanded={expanded}>
            {
              <Content>
                <Container>
                  <Route exact path="/">
                    <Redirect to="/search/data" />
                  </Route>
                  <Switch>
                    {getRoutes(
                      userSess.routes,
                      expanded,
                      sideFilter,
                      toggleSideFilter,
                      logo,
                      userSess,
                      layoutLogo,
                    )}
                  </Switch>
                </Container>
              </Content>
            }
          </MainPanel>
        </ViewWrapper>
      )}
    </UserConsumer>
  );
};

export default DarkBlue;
