import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { capitalizeFirstLetter } from '../../../../utils/Functions/utils';
import Button from '../../../Button/Button';
import LanguageIcon from '@material-ui/icons/Language';

import { MultiDropdownList } from '@appbaseio/reactivesearch';

const StyledIndexFilter = styled(MultiDropdownList)`
  width: 200px !important;
  &&& button {
    display: none;

    &.show {
      display: inherit;
    }
  }
  &&& > h2 {
    display: none;
  }
`;

const DatasetButton = styled(Button)`
  box-sizing: border-box;
  height: 30px;
  width: ${(props) =>
    props.indexFilterLength === 2
      ? '200px'
      : props.indexFilterLength === 1
      ? '155px'
      : '223px'};
  border: 1px solid #2a8dfe;
  border-radius: 0px;
  background-color: #deecfa;

  p {
    color: #2a8dfe;
    font-family: 'Red Hat regular';
    font-size: 12px;
    letter-spacing: 0;
    text-align: center;
    line-height: 16px;
  }

  svg {
    vertical-align: middle;
    margin-left: 8px;
    text-align: center;
  }
  &:hover {
    p {
      color: #deecfa;
    }
  }
`;

const FilterContainer = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.cardBackground};
  padding: 12px 20px;
  left: 0;
  right: 0;
  margin-top: 12px;
  box-shadow: ${(props) => props.theme.shadow.medHeavy};
  z-index: 15;
`;

const Item = styled.div`
  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.primary
      : props.theme.colors.primaryText};
  & > span {
    &:hover {
      color: ${(props) =>
        props.isSelected ? props.theme.danger : props.theme.colors.primary};
    }
  }
  &:hover {
    cursor: pointer;
  }
`;

const isSelected = (filterState, data) => {
  return filterState.IndexFilter.indexOf(data) > -1;
};

const IndexFilter = ({
  filterState,
  setFilterState,
  filterList = [],
  ...rest
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [FilterDisplayText, setFilterDisplayText] = useState(
    'Showing Profiles, Markets',
  );

  useEffect(() => {
    if (
      filterState.IndexFilter.length < 3 &&
      filterState.IndexFilter.length !== 0
    ) {
      setFilterDisplayText(
        filterState.IndexFilter.map((s) =>
          capitalizeFirstLetter(s.split('_')[0]),
        ).toString(),
      );
    } else {
      setFilterDisplayText('Showing Profiles,Markets, + 1');
    }
  }, [filterState.IndexFilter]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <StyledIndexFilter
      componentId="IndexFilter"
      filterType="SelectFilter"
      dataField="_index"
      showFilter={true}
      URLParams={true}
      value={filterState.IndexFilter}
      onChange={(value, triggerQuery) => {
        const newFilterState = {
          ...filterState,
          ...{ IndexFilter: value },
        };
        setFilterState(newFilterState);
        if (triggerQuery) triggerQuery();
      }}
      render={({ loading, error, data, handleChange }) => {
        if (loading || data.length === 0) {
          return <div>Fetching Results.</div>;
        }
        if (error) {
          return (
            <div>
              Something went wrong! Error details {JSON.stringify(error)}
            </div>
          );
        }
        return (
          <div style={rest.style}>
            <DatasetButton
              indexFilterLength={filterState.IndexFilter.length}
              className="show"
              onClick={toggleDropdown}
            >
              <LanguageIcon />
              {FilterDisplayText}
            </DatasetButton>
            {showDropdown ? (
              <FilterContainer>
                {data.map((item) => {
                  let displayKey = capitalizeFirstLetter(
                    item.key.split('_')[0],
                  );

                  return (
                    <Item
                      isSelected={isSelected(filterState, item.key)}
                      onClick={() => {
                        handleChange(item.key);
                      }}
                      key={item.key}
                    >
                      <span>{displayKey}</span>
                    </Item>
                  );
                })}
              </FilterContainer>
            ) : null}
          </div>
        );
      }}
    />
  );
};

export default IndexFilter;
