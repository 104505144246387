import React, { useState } from 'react';
import Input from '../../Inputs/Input';

const GeoPointFilter = (props) => {
  const {
    filterState,
    setFilterState,
    filterName,
    filterLabel,
    filterModalOpen,
    query,
    elasticPath,
  } = props;
  const [inputValue, setInputValue] = useState({ lat: '', lon: '' });

  const handleSubmit = () => {
    if (inputValue.lat && inputValue.lon)
      setFilterState({
        ...filterState,
        ...{
          [filterName]: {
            value: [inputValue],
            query,
            elasticPath,
          },
        },
      });
  };

  return (
    <>
      <div style={{ display: 'inline-flex' }}>
        <Input
          style={{ width: '150px', margin: '4px' }}
          name="latitudeValue"
          label={'Latitude'}
          filterModalOpen={filterModalOpen}
          inputProps={{
            value: inputValue.lat,
            onChange: (e) => {
              setInputValue({ ...inputValue, lat: e.target.value });
            },
          }}
        />
        <Input
          style={{ width: '150px', margin: '4px' }}
          name="longitudeValue"
          label={'Longitude'}
          filterModalOpen={filterModalOpen}
          inputProps={{
            value: inputValue.lon,
            onChange: (e) => {
              setInputValue({ ...inputValue, lon: e.target.value });
            },
            onSubmit: handleSubmit,
          }}
          button
        />
      </div>
    </>
  );
};

export default GeoPointFilter;
