import React, { useState } from 'react';
import styled from 'styled-components';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

const StyledSelect = styled(Select)`
  && {
    color: ${(props) => props.theme.colors.almostBlack};
    margin-left: 5px;
    width: auto;
    font-size: ${props=> props.theme.fontSizes[3]}
  }

  &&::before {
      border-bottom: none;
    // border-bottom: 2px solid ${(props) => props.theme.colors.almostBlack};
  }

  &&::after {
      border-bottom: none;
    // border-bottom: 2px solid ${(props) => props.theme.colors.primary};
  }

  & .MuiSelect-icon {
    color: ${(props) => props.theme.colors.almostBlack};
  }

  & .MuiSelect-selectMenu {
    margin-top: 2px;
    background-color: ${(props) => props.theme.colors.fog}
  }
`;

const StyledInputLabel = styled.p`
  && {
    color: ${(props) => props.theme.colors.almostBlack};
    font-size: ${props=> props.theme.fontSizes[3]};
    margin: 0px;
    margin-left: 5px;
  }
`;

function CustomSelect(props) {

  return (
    <>
      <StyledSelect
        value={props.defaultValue}
        onChange={(e) => props.onChange(e.target.value)}
        labelId={props.label}
      >
        {props.items.map((item) => (
          <MenuItem key={item.displayText} value={item.value}>
            {item.displayText}
          </MenuItem>
        ))}
      </StyledSelect>
      <StyledInputLabel id={props.label}>Results per page</StyledInputLabel>
    </>
  );
}

export default CustomSelect;
