import React from 'react';
import { createGlobalStyle } from 'styled-components';
const GlobalThemeProvider = createGlobalStyle`
  html{
    font-family: 'Red Hat Text', sans-serif !important;
  }

  .root{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }

  .lighter{
    font-weight: lighter;
  }

  .italic{
    font-style: italic;
  }

  h1{
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.primaryText};
  }

  h2{
    font-size: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.primaryText};
  }

  h3{
    font-size: 18px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.primaryText};
  }

  h4{
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primaryText};
  }

  tr{

  }

  text{
    &:not(.label){
    tspan{
        fill: ${(props) => props.theme.colors.primaryText} !important;
    }
    }
  }

  td{
    color: ${(props) => props.theme.colors.primaryText};
    font-size: 12px;
    line-height: 15px;
  }
  span.card{
    color: ${(props) => props.theme.colors.primaryText};
  }

  li{
    color: ${(props) => props.theme.colors.primaryText};
  }

  code{
    color: ${(props) => props.theme.colors.primaryText};
  }
  
  li{
    font-size: 14px;
  }

  p{
    font-size:14px;
    line-height: 18px;
    color: ${(props) => props.theme.colors.primaryText};
  }
  p.bold{
    font-weight: bold;
  }
  p.xl{
    font-size: 20px;
     line-height: 25px;
  }
  p.l{
    font-size: 16px;
     line-height: 20px;
  }
  p.l.bold{
    font-size: 16px;
    font-weight: bold;
     line-height: 20px;
  }

  p.s{
    font-size: 12px;
    line-height: 15px;
  }

  p.xs{
    font-size: 10px;
    line-height: 12px;
  }

  p.no-margin{
    margin: 0px;
  }

  p.secondary{
    color: ${(props) => props.theme.colors.sidebarText};
  }

  p.tertiary {
    color: ${(props) => props.theme.colors.primaryText};
  }

  em{
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    color: ${(props) => props.theme.colors.primaryText};
  }

  em.meta{
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: normal;
  }

  mark{
    background-color: ${(props) => props.theme.colors.highlight};
  }

  .primary{
    color: ${(props) => props.theme.colors.primary}
  }

  .underline-on-hover{
    &:hover{
      text-decoration: underline;
    }
  }

  .fit-content{
    max-width: fit-content !important;
    max-height: fit-content !important;
    padding: 2px 8px;
  }

  
  .MuiMenu-paper{
    background-color: ${(props) => props.theme.colors.filterSidebar} !important;
    border-radius: 0px !important;
    box-shadow: none !important;
  }

  .MuiList-root{
    font-family: 'Red Hat Text', sans-serif !important; 
    font-size: ${(props) => props.theme.fontSizes[3]} !important;
    color: ${(props) => props.theme.colors.filterSidebarText} !important;
  }

  .MuiTooltip-tooltip{
    font-family: 'Red Hat Text', sans-serif !important;
    border-radius: 0px !important;
    border: 1px solid ${(props) =>
      props.theme.colors.altPrimaryOpacity} !important;
    border-radius: 0px !important;
    background-color: ${(props) => props.theme.colors.fog} !important;
    color: ${(props) => props.theme.colors.almostBlack} !important;
    // &&& div, p {
    //   color: ${(props) => props.theme.colors.almostBlack} !important;
    // }
  }

  .MuiTooltip-arrow {
    color: ${(props) => props.theme.colors.altPrimaryOpacity} !important;
  }

  button{
    color: ${(props) => props.theme.colors.plume};
    font-family: 'Red Hat Text', sans-serif !important;
    font-weight: bold;
    // height: 40px;
    // width: 158px;
    min-width: fit-content;
    border-radius:0px;
    outline: none;
    background-color: ${(props) => props.theme.colors.primary};
    transition: all 0.3s;
    cursor: pointer;

    & > :not(div){
      font-family: 'Red Hat Text', sans-serif !important;
      font-weight: bold;
      margin: 0px !important;
    }
  
  button.fit-content{
    max-width: fit-content !important;
    max-height: fit-content !important;
  }
  }

  button.base{
    padding: 10px 20px;
  }

  input { 
    &&& .MuiInputBase-input{
      color: ${(props) => props.theme.colors.primaryText};
    }
  }

  .MuiTable-root{
    background-color: ${(props) => props.theme.colors.cardBackground};
    .MuiTableCell-root{
      color: ${(props) => props.theme.colors.cardTitle}
    }
  }

  a{
    color: ${(props) => props.theme.colors.linkText};
  }

  hr{
    border-top: 3px solid ${(props) => props.theme.colors.cloud};
  }

::-webkit-scrollbar
{
	width: 5px;
}

::-webkit-scrollbar-thumb
{
	background-color:  #c9c6c6;
}
  tspan{
    color: ${(props) => props.theme.colors.linkText} !important;
  }

  .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${(
      props,
    ) =>
      String(props.theme.colors.almostBlack).replace(
        '#',
        '%23',
      )}' width='100' height='110' viewBox='0 0 100 100'%3E%3Cpolygon points='71.65063509461098,62.5 50,75 28.349364905389038,62.50000000000001 28.34936490538903,37.50000000000001 49.99999999999999,25 71.65063509461098,37.50000000000001' /%3E%3C/svg%3E") !important;
    background-color: white;
    background-position: center !important;
  }

  .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${(
      props,
    ) =>
      String(props.theme.colors.almostBlack).replace(
        '#',
        '%23',
      )}' width='100' height='100' viewBox='0 0 100 100'%3E%3Cpolygon points='71.21320343559643,71.21320343559643 28.786796564403577,71.21320343559643 28.78679656440357,28.786796564403577 71.21320343559643,28.78679656440357' /%3E%3C/svg%3E") !important;
    background-color: white;
    background-position: center !important;
  }

  .leaflet-draw-toolbar .leaflet-draw-edit-remove {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${(
      props,
    ) =>
      String(props.theme.colors.almostBlack).replace(
        '#',
        '%23',
      )}' class='MuiSvgIcon-root MuiSvgIcon-fontSizeSmall' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'%3E%3C/path%3E%3Cpath d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z'%3E%3C/path%3E%3C/svg%3E") !important;
    background-color: white;
    background-size: 25px !important;
    background-position: center !important;
  }

  .leaflet-retina .leaflet-control-layers-toggle {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${(
      props,
    ) =>
      String(props.theme.colors.almostBlack).replace(
        '#',
        '%23',
      )}' class='MuiSvgIcon-root MuiSvgIcon-fontSizeSmall' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath d='M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z'%3E%3C/path%3E%3C/svg%3E") !important;
    background-color: white;
    background-size: 35px !important;
    background-position: center !important;
  }

  .leaflet-bar a {
    color: ${(props) => props.theme.colors.almostBlack} !important;
  }
`;

export default GlobalThemeProvider;
