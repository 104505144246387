import React from 'react';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const tags = [
  {
    singular: true,
    indexTag: true,
    path: 'doc.type',
    tagLabel: 'Data Type',
    elasticPath: ['doc.type.keyword'],
    filterName: 'DocFilter',
    icon: <AttachMoneyIcon />,
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.net',
    tagLabel: 'Site Net',
    elasticPath: ['site.net.keyword'],
    filterName: 'SiteNetFilter',
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.name',
    tagLabel: 'Site Name',
    filterName: 'SiteNameFilter',
    elasticPath: ['site.name.keyword'],
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'role',
    tagLabel: 'Role',
    filterName: 'RoleFilter',
    elasticPath: ['role.keyword'],
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'userType',
    tagLabel: 'User Type',
    filterName: 'UserTypeFilter',
    elasticPath: ['userType.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.tags',
    tagLabel: 'Content Tags',
    tagPointer: 'ai.content',
    elasticPath: ['ai.content.tags.keyword'],
    filterName: 'ContentTagsFilter',
    filterValueType: 'array',
  },
  {
    path: 'ai.language',
    tagLabel: 'Language',
    elasticPath: ['ai.language.keyword'],
    filterName: 'LanguageFilter',
    filterValueType: 'array',
  },
  {
    descriptionPath: 'ai.usper.explanation',
    tagText: 'Potential USPER',
    path: 'ai.usper.potential',
    tagLabel: 'US Person',
    filterName: 'UsperFilter',
    elasticPath: ['ai.usper.potential'],
    filterValueType: 'array',
  },
];

const earmarks = [{ path: 'timestamp' }];

const title = {
  title: 'Vendor Name',
  path: 'vendorName',
  filterName: 'VendorNameFilter',
  elasticPath: ['vendorName.keyword'],
  filterValueType: 'array',
};

const body = [
  { title: 'Review', path: 'review' },
  { title: 'Rating', path: 'rating' },
  {
    title: 'Reviewer',
    path: 'user',
    filterName: 'UserFilter',
    elasticPath: ['user.keyword'],
    filterValueType: 'array',
  },
  { title: 'Number of Posts', path: 'numberOfPosts' },
];

const footers = [
  { title: 'Page', path: 'page' },
  {
    title: 'VendorID',
    filterName: 'VendorIdFilter',
    elasticPath: ['vendorId.keyword'],
    filterValueType: 'array',
    path: 'vendorId',
  },
];

const selectors = [
  'analyticsIDs',
  'apacheServices',
  'bitcoinAddresses',
  'bitcoinSignature',
  'emailAddresses',
  'ethereumAddresses',
  'litecoinAddresses',
  'moneroAddresses',
  'pgp.fingerprints',
  'pgp.identities.emails',
  'pgp.identities.names',
  'phone',
  'phoneNumbers',
  'ssh',
  'socialMedia',
  'vendorBitcoinAddress',
  'vendorPgp',
  'identity',
];

export { tags, earmarks, title, body, footers, selectors };
