import React from 'react';
import styled, { css } from 'styled-components';
import useOnclickOutside from 'react-cool-onclickoutside';

const DialogContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 100%;
  z-index: -100;
  background-color: rgba(0, 0, 0, 0.5);

  ${(props) =>
    props.open &&
    css`
      right: 0px;
      left: 0px;
      top: 0px;
      bottom: 0px;
      z-index: 1300;
    `}
`;

const DialogContent = styled.div`
  max-width: ${(props) => (props.widthoverride === 'true' ? '80vw' : '600px')};
  display: flex;
  z-index: 1301;
`;

const Modal = ({ open, onOpen, onClose, style, onClickOutside, widthoverride, ...rest }) => {
  const ref = useOnclickOutside(() => {
    if (open) {
      onClickOutside();
    }
  });

  return (
    <DialogContainer open={open}>
      <DialogContent widthoverride={widthoverride} style={style} ref={ref}>
        {rest.children}
      </DialogContent>
    </DialogContainer>
  );
};

export default Modal;
