import React from 'react';
import styled, { css } from 'styled-components';

import HighlightComponent from '../../darkblue-ui/composite/DarkBlueCard/HighlightComponent.js';
import Tag from '../../darkblue-ui/Tag/Tag.js';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const ResourceTag = styled(Tag)`
  min-width: 160px;
`;

const CardContainer = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackground};
  border-radius: 0px;
  box-shadow: 0 2px 4px 0 rgba(7, 29, 54, 0.15);
  padding: 24px 24px 26px 24px;
  margin-bottom: 16px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
`;

const TagsContainer = styled.div`
  display: flex;
`;

const ReadMore = styled.p`
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

const ArrowRight = styled(ArrowRightAltIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

const ResourceCard = ({ data, setDoc, filterState, setFilterState }) => {
  const addTagToFilter = (tag) => {
    setFilterState({
      ...filterState,
      ...{ Tags: [...filterState.Tags, tag] },
    });
  };

  return (
    <CardContainer>
      <HeaderContainer>
        <h1>
          <HighlightComponent text={data.title} />
        </h1>
        <TagsContainer>
          {Array.isArray(data.tags) &&
            data.tags.map((tag) => (
              <ResourceTag key={tag} onClick={() => addTagToFilter(tag)}>
                {tag}
              </ResourceTag>
            ))}
        </TagsContainer>
      </HeaderContainer>
      <p>
        <HighlightComponent text={data.short_description} />
      </p>
      <ReadMore onClick={() => setDoc(data)} className="bold">
        Read More <ArrowRight />
      </ReadMore>
    </CardContainer>
  );
};

export default ResourceCard;
