import React from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { Link } from 'react-router-dom';
import { newSearchesQuery } from '../../utils/Queries/CustomQueries.js';
import styled, { keyframes } from 'styled-components';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { fetchData } from 'darkblue-ui/Search/utils.js';
import { useEffect } from 'react';
import { useState } from 'react';
import GridItem from 'components/Grid/GridItem.js';

const NotificationCircle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
  top: -10px;
  right: -20px;
  border-radius: 0px;
  background-color: red;

  & p {
    margin: 0px !important;
    color: ${(props) => props.theme.colors.white};
  }
`;

const pulse = (props) => keyframes`
  0% {
    box-shadow: ${props.theme.shadow.notification};
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
`;

const StyledNotificationIcon = styled(NotificationsNoneIcon).attrs(() => ({
  id: 'notification-icon',
}))``;

const StyledNotificationActiveIcon = styled(NotificationsActiveIcon).attrs(
  () => ({
    id: 'notification-icon',
  }),
)`
  animation: ${(props) => pulse(props)} 2s infinite;
  color: ${(props) => props.theme.colors.primary};
  border-radius: 0px;
`;

const SearchNotifications = (props) => {
  const { apiKey, index } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    let canceled = false;

    const fetchStuff = async () => {
      const response = await fetchData({
        canceled: canceled,
        query: newSearchesQuery(),
        apiKey: apiKey,
        index: index,
      });
      setData(response?.hits?.hits);
    };

    fetchStuff();
    return () => (canceled = true);
  }, []);
  const newNotifications = data.length;

  return newNotifications ? (
    <Link
      style={{ width: 'fit-content', marginLeft: 'auto' }}
      to="../user/queries"
    >
      <div style={{ position: 'relative' }}>
        <StyledNotificationActiveIcon
          style={{ width: '1.3em', height: '1.3em' }}
        />
        <NotificationCircle>
          <p>{newNotifications}</p>
        </NotificationCircle>
      </div>
    </Link>
  ) : (
    <Link
      style={{ width: 'fit-content', marginLeft: 'auto' }}
      to="../user/queries"
    >
      <StyledNotificationIcon style={{ width: '1.3em', height: '1.3em' }} />
    </Link>
  );
};

export default SearchNotifications;
