import React, { useRef } from 'react';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Loader from '../../Spinners/Loader';
import Tooltip from '@material-ui/core/Tooltip';

import { numberWithCommas } from '../../../filters/utils';
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

const ToolTipParagraph = styled.p`
  font-size: 11px;
  color: #fff;
`;

const CounterCard = styled(Card)`
  max-width: 100%;
  max-height: auto;
  background-color: ${(props) => props.theme.colors.cardBackground} !important;
`;

const CounterCardBody = styled(CardBody)`
  text-align: center;
`;

const Counter = ({
  field,
  title,
  color,
  icon,
  toolTipPreText,
  toolTipPostText,
  reactiveFilters,
  excludedIndices = [],
}) => {
  const cId = 'counter' + field;
  const containerTooltipRef = useRef(null);
  return (
    <ReactiveComponent
      componentId={cId}
      react={{
        and: [
          ...['TimeFilter', 'LocationFilter', 'LanguageFilter'],
          ...reactiveFilters,
        ],
      }}
      customQuery={(props) => {
        const excludedIndicesMap = excludedIndices.map((i) => {
          return {
            term: {
              _index: i,
            },
          };
        });

        const indicesQuery = {
          query: {
            bool: {
              must_not: excludedIndicesMap,
            },
          },
        };
        const query = {
          size: 0,
          aggs: {
            count: {
              cardinality: {
                field: field,
              },
            },
          },
        };

        if (excludedIndices.length) {
          return { ...query, ...indicesQuery };
        } else {
          return query;
        }
      }}
      defaultQuery={(props) => {
        const excludedIndicesMap = excludedIndices.map((i) => {
          return {
            term: {
              _index: i,
            },
          };
        });

        const indicesQuery = {
          query: {
            bool: {
              must_not: excludedIndicesMap,
            },
          },
        };
        const query = {
          size: 0,
          aggs: {
            count: {
              cardinality: {
                field: field,
              },
            },
          },
        };

        if (excludedIndices.length) {
          return { ...query, ...indicesQuery };
        } else {
          return query;
        }
      }}
      render={(props) => {
        const { aggregations, resultStats, loading } = props;
        let aggData = 0;
        if (aggregations && Number.isInteger(aggregations.count.value)) {
          aggData = aggregations.count.value;
        }

        const aggDataNumber = Number.isInteger(aggData)
          ? numberWithCommas(aggData)
          : null;
        return (
          <CounterCard>
            {loading ? (
              <Loader />
            ) : (
              <CounterCardBody>
                <Tooltip
                  title={
                    <ToolTipParagraph>
                      {toolTipPreText + aggDataNumber + toolTipPostText}
                    </ToolTipParagraph>
                  }
                  placement={
                    title.includes('Products') || title.includes('Reviews')
                      ? 'bottom'
                      : 'top'
                  }
                  arrow
                >
                  <h1 ref={containerTooltipRef}>{aggDataNumber}</h1>
                </Tooltip>
                <h2>{title}</h2>
                {aggData === 0 ? (
                  <em>
                    No data found. Please adjust your query parameters and try
                    again.
                  </em>
                ) : null}
              </CounterCardBody>
            )}
          </CounterCard>
        );
      }}
    />
  );
};

export default Counter;
