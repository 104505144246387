import React, { useEffect } from 'react';
import _ from 'lodash';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { useFormik } from 'formik';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import '../MapboxStyle.css';
import Input from 'darkblue-ui/Inputs/Input.js';
import SearchIcon from '@material-ui/icons/Search';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapSearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: black;
  background: none;
  border: none;
  padding: 0;
`;

const CustomTooltip = styled(ReactTooltip)`
  font-size: 14px !important;
  pointer-events: auto !important;

  &:hover {
    font-size: 14px !important;
    pointer-events: auto !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
`;

const MapSearchbar = ({
  index,
  filterState,
  setFilterState,
  filterName,
}) => {
  const { values, handleSubmit, handleChange, resetForm } = useFormik({
    initialValues: {
      MapSearchbar: '',
    },
    onSubmit: (values) =>
      onSubmit(
        filterState,
        setFilterState,
        'MapSearchbar',
        values.MapSearchbar,
      ),
  });

  // handles Searchbar for Map
  const onSubmit = (filterState, setFilterState, filterName, value) => {
    if (value) {
      setFilterState({
        ...filterState,
        ...{ [filterName]: { value } },
      });
    } else {
      const { [filterName]: _dropped, ...cleanState } = filterState;
      setFilterState(cleanState);
      resetForm();
    }
  };

  useEffect(() => {
    if (!filterState.MapSearchbar?.value) {
      resetForm();
    }
  }, [filterState.MapSearchbar?.value]);

  return (
    <>
      <MapSearchButton
        data-tip="hover with tooltip for search"
        data-for="map-search-tooltip"
      >
        <SearchIcon />
      </MapSearchButton>
      <CustomTooltip
        id="map-search-tooltip"
        place="right"
        type="light"
        effect="solid"
        delayHide={1000}
        arrowColor="transparent"
      >
        <form onSubmit={handleSubmit}>
          <Input
            id="MapSearchbar"
            name="MapSearchbar"
            label="Search by Terms"
            inputProps={{
              value: values.MapSearchbar || '',
              onSubmit: handleSubmit,
              onChange: handleChange,
            }}
            type="text"
            fullWidth={true}
          />
        </form>
      </CustomTooltip>
    </>
  );
};

export default MapSearchbar;
