import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import emojiStrip from 'emoji-strip';

const splitTranslate = (value) => {
  const isValueArr = Array.isArray(value);
  const size = !isValueArr
    ? Math.ceil(value.length / 9000)
    : Math.ceil(value.join().length / 9000);
  let chunks = [...Array(size)];

  if (!isValueArr) {
    const groupedChunk = chunks.map((x, idx) => {
      return value.substring(idx * 9000, (idx + 1) * 9000);
    });
    return groupedChunk;
  } else {
    const groupedChunk = chunks.map((x, idx) => {
      return value.join(' ').substring(idx * 9000, (idx + 1) * 9000);
    });
    return groupedChunk;
  }
};

const stripEmoticons = (text) => {
  return text ? emojiStrip(text) : text;
};

const Translator = async (values) => {
  const cleanValues = values.filter((cv) => cv && cv.value);
  let subscriptionKey = process.env.REACT_APP_TRANSLATOR_SECRET;
  let endpoint = process.env.REACT_APP_TRANSLATOR_ENDPOINT;
  let apivURL = '?api-version=3.0&to=en&textType=html';

  const response = await Promise.all(
    cleanValues.map((value) => {
      return Promise.all(
        splitTranslate(value.value).map(async (chunk) => {
          const responseChunks = fetch(endpoint + '/translate' + apivURL, {
            method: 'POST',
            headers: {
              'Ocp-Apim-Subscription-Key': subscriptionKey,
              'Ocp-Apim-Subscription-Region': 'eastus',
              'Content-type': 'application/json',
              'X-ClientTraceId': uuidv4().toString(),
            },

            body: JSON.stringify([{ text: chunk }]),
          });
          return responseChunks;
        }),
      );
    }),
  );

  const combinedResponses = await Promise.all(
    response.map(async (v) => {
      return await Promise.all(
        v.map(async (entry) => {
          const resp = await entry.json();
          return resp;
        }),
      );
    }),
  );

  const combinedText = combinedResponses.map((outer) => {
    return outer
      .map((inner) => {
        return Array.isArray(inner)
          ? inner.map((resp) => resp.translations[0].text)
          : 'null';
      })
      .join('');
  });

  let respObj = {};
  values.forEach((v, i) => {
    respObj[v.key] = combinedText[i];
  });
  return respObj;
};

const HtmlTranslator = async (value, splitValue) => {
  let subscriptionKey = process.env.REACT_APP_TRANSLATOR_SECRET;
  let endpoint = process.env.REACT_APP_TRANSLATOR_ENDPOINT;
  let htmlURL = '?api-version=3.0&to=en&textType=html';
  if (value.length < 10000) {
    try {
      const response = await fetch(
        endpoint + '/translate' + htmlURL,

        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': subscriptionKey,
            'Ocp-Apim-Subscription-Region': 'eastus',
            'Content-type': 'application/json; charset=UTF-8',
            'X-ClientTraceId': uuidv4().toString(),
          },

          body: JSON.stringify([{ Text: stripEmoticons(value) }]),
        },
      );
      const translatedHtml = await response.json();
      let returnHtml = translatedHtml[0].translations[0].text;

      return returnHtml;
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      const response = await Promise.all(
        splitTranslate(value).map((chunk) => {
          return fetch(
            endpoint + '/translate' + htmlURL,

            {
              method: 'POST',
              headers: {
                'Ocp-Apim-Subscription-Key': subscriptionKey,
                'Ocp-Apim-Subscription-Region': 'eastus',
                'Content-type': 'application/json; charset=UTF-8',
                'X-ClientTraceId': uuidv4().toString(),
              },

              body: JSON.stringify([{ Text: stripEmoticons(chunk) }]),
            },
          );
        }),
      );
      const combinedResponses = await Promise.all(
        response.map(async (r) => {
          return await r.json();
        }),
      );
      const combinedText = combinedResponses
        .map((r) => {
          return r[0].translations[0].text;
        })
        .join(' ');

      return combinedText;
    } catch (e) {
      console.log(e);
    }
  }
};

const SearchTranslator = async (values, selectedLang, fromLang) => {
  let subscriptionKey = process.env.REACT_APP_TRANSLATOR_SECRET;
  let endpoint = process.env.REACT_APP_TRANSLATOR_ENDPOINT;
  let apivURL = `?api-version=3.0&from=${fromLang ? fromLang : 'en'}&to=`;
  let languageCode = selectedLang;
  if (selectedLang) {
    try {
      const response = await fetch(
        endpoint + '/translate' + apivURL + languageCode,

        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': subscriptionKey,
            'Ocp-Apim-Subscription-Region': 'eastus',
            'Content-type': 'application/json',
            'X-ClientTraceId': uuidv4().toString(),
          },

          body: JSON.stringify([{ Text: stripEmoticons(values) }]),
        },
      );
      const translatedData = await response.json();
      let returnData = translatedData[0].translations[0].text;

      return returnData;
    } catch (e) {
      console.log(e);
    }
  } else {
    return values;
  }
};

const ThreadTranslator = async ({ title, posts }) => {
  let subscriptionKey = process.env.REACT_APP_TRANSLATOR_SECRET;
  let endpoint = process.env.REACT_APP_TRANSLATOR_ENDPOINT;
  let apivURL = '?api-version=3.0&to=en';
  const stringBody = JSON.stringify(
    posts.map((v) => {
      return { text: stripEmoticons(v) };
    }),
  );

  if (stringBody.length < 10000) {
    try {
      const postResponse = await fetch(
        endpoint + '/translate' + apivURL,

        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': subscriptionKey,
            'Ocp-Apim-Subscription-Region': 'eastus',
            'Content-type': 'application/json',
            'X-ClientTraceId': uuidv4().toString(),
          },

          body: stringBody,
        },
      );
      const titleResponse = await fetch(
        endpoint + '/translate' + apivURL,

        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': subscriptionKey,
            'Ocp-Apim-Subscription-Region': 'eastus',
            'Content-type': 'application/json',
            'X-ClientTraceId': uuidv4().toString(),
          },

          body: JSON.stringify([{ text: title }]),
        },
      );

      const translatedData = await postResponse.json();
      const translatedTitle = await titleResponse.json();
      const returnPosts = translatedData.map(
        (post) => post.translations[0].text,
      );
      const returnData = { title: translatedTitle, posts: returnPosts };

      return returnData;
    } catch (e) {
      console.log(e);
    }
  } else {
    const postResponse = await Promise.all(
      posts.map(async (post) => {
        const postChunks = await Promise.all(
          splitTranslate(post).map(async (chunk) => {
            const responseChunks = await fetch(
              endpoint + '/translate' + apivURL,
              {
                method: 'POST',
                headers: {
                  'Ocp-Apim-Subscription-Key': subscriptionKey,
                  'Ocp-Apim-Subscription-Region': 'eastus',
                  'Content-type': 'application/json',
                  'X-ClientTraceId': uuidv4().toString(),
                },
                body: JSON.stringify([{ text: stripEmoticons(chunk) }]),
              },
            );

            const resp = await responseChunks.json();
            return resp;
          }),
        );
        return postChunks.map((t) => t[0].translations[0].text).join('');
      }),
    );

    const titleResponse = await fetch(
      endpoint + '/translate' + apivURL,

      {
        method: 'POST',
        headers: {
          'Ocp-Apim-Subscription-Key': subscriptionKey,
          'Ocp-Apim-Subscription-Region': 'eastus',
          'Content-type': 'application/json',
          'X-ClientTraceId': uuidv4().toString(),
        },

        body: JSON.stringify([{ text: title }]),
      },
    );

    const translatedTitle = await titleResponse.json();
    const returnData = { title: translatedTitle, posts: postResponse };
    return returnData;
  }
};

export { Translator, HtmlTranslator, SearchTranslator, ThreadTranslator };
