import React from 'react';
import styled, { css } from 'styled-components';

import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ActionsContainer from '../../darkblue-ui/composite/DarkBlueCard/TooltipActions/ActionsContainer';

const StyledTag = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['action'].includes(prop) &&
    !['noAction'].includes(prop) &&
    defaultValidatorFn(prop),
})`
  padding-top: 4px;
  padding-right: -8px;
  padding-bottom: 4px;
  padding-left: 8px;
  width: fit-content;
  border-radius:0px;
  padding: 0px 6px;
  height: 28px;
  margin: 3px 6px;
  display: flex;
  border: 1px solid transparent;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;

  color: ${(props) => props.theme.colors.altPrimary};
  background-color: ${(props) =>
    props.usper ? props.theme.colors.usperTag : props.theme.colors.contentTag};

  & > * {
    color: ${(props) => props.theme.colors.altPrimary};
  }

  svg {
    display: flex;
    align-items: center;
  }

  &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.fog};
        color: ${(props) => props.theme.colors.primary};
        border: 1px solid ${(props) => props.theme.colors.primary};
      }
  

  ${(props) =>
    props.action &&
    css`
      background-color: ${(props) => props.theme.colors.success};
      color: ${(props) => props.theme.colors.white};

      & > * {
        color: ${(props) => props.theme.colors.white};
      }

      &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.fog};
        color: ${(props) => props.theme.colors.primary};
        border: 1px solid ${(props) => props.theme.colors.primary};
      }
    `};



  ${(props) =>
    props.indexTag &&
    css`
      color: ${(props) => props.theme.colors.altPrimary} !important;
      background-color: ${(props) => props.theme.colors.contentTag};

      & > * {
        color: ${(props) => props.theme.colors.altPrimary} !important;
      }
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${(props) => props.theme.colors[props.color]};
    `}
    
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${(props) => props.theme.colors[props.backgroundColor]};
    `}

    ${(props) =>
      props.noAction &&
      css`
        pointer-events: none;

        & > * {
          color: ${(props) => props.theme.colors.white};
        }

        &:hover {
          cursor: default;
          background-color: inherit;
          color: inherit;
          border: inherit;
        }
      `}
`;

const StyledTooltip = styled(Tooltip)`
  @global {
    &&.MuiTooltip-tooltip div {
      background-color: ${(props) => props.theme.colors.mainBackground};
    }

    &.MuiTooltip-tooltip.bg-override {
      background-color: ${(props) => props.theme.colors.mainBackground};
      border-radius: 0px;
    }

    &.MuiTooltip-tooltip.bg-override div {
      display: flex;
      width: fit-content;
    }
  }

  p {
    width: fit-content;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: 'column';
  justify-content: center;
  color: ${(props) => props.theme.colors.primaryText};
`;

const UsperText = styled.p`
  color: ${(props) => props.theme.colors.altPrimary};
`;

const TagTooltip = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipState = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <StyledTooltip
        classes={{
          tooltip: 'bg-override',
        }}
        interactive
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <StyledWrapper>
            <ActionsContainer
              {...props}
              style={{ justifyContent: 'space-evenly' }}
            />
          </StyledWrapper>
        }
      >
        {React.cloneElement(props.children, {
          onClick: props.children.onClick
            ? props.children.onClick
            : handleTooltipState,
        })}
      </StyledTooltip>
    </ClickAwayListener>
  );
};

const Tag = ({ action, icon, ...rest }) => {
  return (
    <>
      {rest.onClick ? (
        <StyledTag action={action} id={`tag-${rest.children}`} {...rest}>
          {icon ? <span style={{ marginRight: '4px' }}>{icon}</span> : null}
          <p onClick={rest.onClick} className="s bold no-margin">
            {rest.children === 'csam' ? 'CSAM' : rest.children}
          </p>
        </StyledTag>
      ) : rest.noAction ? (
        <StyledTag path={rest.path} id={`tag-${rest.children}`} {...rest}>
          {icon ? <span style={{ marginRight: '4px' }}>{icon}</span> : null}
          <p className="s bold no-margin">
            {rest.children === 'csam' ? 'CSAM' : rest.children}
          </p>
        </StyledTag>
      ) : action ? (
        <TagTooltip id={`tag-tooltip-${rest.children}`} {...rest}>
          <StyledTag action={action} id={`tag-${rest.children}`} {...rest}>
            {icon ? <span style={{ marginRight: '4px' }}>{icon}</span> : null}
            <p className="s bold no-margin">
              {rest.children === 'csam' ? 'CSAM' : rest.children}
            </p>
          </StyledTag>
        </TagTooltip>
      ) : rest?.descriptionPath === 'ai.usper.explanation' ? (
        <TagTooltip id="tag-tooltip-potential-usper" {...rest}>
          <StyledTag id="tag-potential-usper" usper={true} {...rest}>
            {icon ? <span style={{ marginRight: '4px' }}>{icon}</span> : null}
            <UsperText className="s bold no-margin">
              {rest.children === 'csam' ? 'CSAM' : rest.children}
            </UsperText>
          </StyledTag>
        </TagTooltip>
      ) : (
        <TagTooltip id={`tag-tooltip-${rest.children}`} {...rest}>
          <StyledTag id={`tag-${rest.children}`} {...rest}>
            {icon ? <span style={{ marginRight: '4px' }}>{icon}</span> : null}
            <p className="s bold no-margin">
              {rest.children === 'csam' ? 'CSAM' : rest.children}
            </p>
          </StyledTag>
        </TagTooltip>
      )}
    </>
  );
};

export default Tag;
