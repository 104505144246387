import React, { useState, useContext, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import _ from 'lodash';
import Button from '../../darkblue-ui/Button/Button.js';
import { UserConsumer } from '../../DBAuthenticator.js';
import Cluster from '../Elastic/Cluster';
import { useTable } from 'react-table';
import SavedItemTable from '../../darkblue-ui/Table/SavedItemTable.js';
import { FileCopy } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useClipboard } from 'use-clipboard-copy';
import { useSnackbar } from 'notistack';
import ShareIcon from '@material-ui/icons/Share';

import {
  DateRange,
  ReactiveList,
  MultiDropdownList,
  ReactiveComponent,
} from '@appbaseio/reactivesearch';
import {
  generalDatesQuery,
  savedSearches,
} from '../../utils/Queries/CustomQueries';
import Loader from '../../darkblue-ui/Spinners/Loader';
import { cleanData } from '../../utils/Functions/utils';
import { startSearchDate } from 'consts/MagicValues';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
`;

const NewIndicatorWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
`;

const NewIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 0px;
  background-color: ${(props) => props.theme.colors.primary};
  animation: ${pulse} 2s infinite;
`;

const CopiedIndicator = styled.div`
  color: ${(props) => props.theme.colors.alertIconSuccess};
  text-align: center;
`;

const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;

  & > * {
    color: ${(props) => props.theme.colors.almostBlack};
  }

  &:hover {
    & > * {
      color: ${(props) => props.theme.colors.altPrimary};
      cursor: pointer;
    }
  }
`;

const StyledP = styled.p`
  font-size: ${(props) => props.theme.fontSizes[2]};
`;

const ShareSearch = ({ url }) => {
  const { enqueueSnackbar } = useSnackbar();
  const copy = useClipboard().copy;

  const btnStyle = {
    alignSelf: 'center',
    width: '41px',
    height: '41px',
    minWidth: '41px',
    marginLeft: '15px',
  };

  return (
    <div>
      <p
        style={{
          marginTop: '0',
          marginBottom: '3px',
          textDecoration: 'underline',
        }}
      >
        Search URL
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <p style={{ margin: '0', wordBreak: 'break-all' }}>{url}</p>

        <Button
          style={btnStyle}
          onClick={() => {
            const copiedValue = url;
            copy(copiedValue);
            enqueueSnackbar('Successfully Copied!', {
              variant: 'success',
            });
          }}
        >
          <FileCopy fontSize="small" style={{ verticalAlign: 'middle' }} />
        </Button>
      </div>
    </div>
  );
};

const Searches = () => {
  const [fire, setFire] = useState(0);
  const [shareArr, setShareArr] = useState([]);
  const [open, setOpen] = useState(false);

  const handleTooltipState = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  let tempArr = [];

  const columns = useMemo(() => [
    {
      Header: 'Alerting Enabled',
      id: 'alerting_enabled_checkbox',
      Cell: (props) => {
        return (
          <input
            type="checkbox"
            checked={
              props.row.original.alert && props.row.original.alert.enabled
            }
            onChange={props.handleToggleNotifications}
            disabled={
              !(
                props.row.original.alert &&
                Number.isInteger(props.row.original.alert.hits)
              )
            }
          />
        );
      },
    },
    {
      Header: 'New Hits',
      id: 'new_record_circle',
      Cell: (props) => {
        return props.row.original.alert && props.row.original.alert.hits > 0 ? (
          <NewIndicatorWrapper>
            <NewIndicator />
          </NewIndicatorWrapper>
        ) : null;
      },
    },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Created At', accessor: 'timestamp' },
    {
      id: 'Go To!',
      Cell: (props) => (
        <Button
          style={{ width: '140px', height: '26px' }}
          onClick={props.handleRedirect}
        >
          Run Search
        </Button>
      ),
    },
    {
      id: 'Share Search',
      Cell: (props, displayName = 'test') => {
        if (tempArr.length < props.rows.length) {
          tempArr.push(false);
        }

        let row = props.row;
        let decodedQuery = atob(row.original['query']);
        const basePath = window.location.protocol + '//' + window.location.host;
        const endpoint = row.original['endpoint'];

        if (decodedQuery.indexOf('TimeFilter') === -1) {
          //if there is no time filter, then we create one.
          //this is a hack to prevent the default time filter of one week
          // prior to today being created.
          let startDateStr = '';
          if (
            'alert' in row.original &&
            row.original['alert']['enabled'] === true
          ) {
            startDateStr = row.original['timestamp'].slice(0, 10);
          } else {
            startDateStr = startSearchDate.toISOString().slice(0, 10);
          }

          const currentDateStr = new Date().toISOString().slice(0, 10);
          //this specific line is a hack to encode the URL parameter string manually.
          decodedQuery += `&TimeFilter=%5B%22${startDateStr}%22%2C%22${currentDateStr}%22%5D`;
        }
        const fullURL = basePath + endpoint + decodedQuery;
        return (
          <>
            <ClickAwayListener
              onClickAway={() => {
                tempArr[props.row.id] = false;
                setShareArr(tempArr);
              }}
            >
              <Tooltip
                arrow
                classes={{
                  tooltip: 'bg-override',
                }}
                interactive
                placement="right"
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => {
                  tempArr[props.row.id] = false;
                  setShareArr(tempArr);
                }}
                open={shareArr[props.row.id]}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<ShareSearch url={fullURL} />}
              >
                <button
                  style={{ width: '140px', border: 'none', height: '26px' }}
                  onClick={() => {
                    tempArr[props.row.id] = true;
                    setShareArr(tempArr);
                  }}
                >
                  Share Search <FileCopy style={{ verticalAlign: 'middle' }} />
                </button>
              </Tooltip>
            </ClickAwayListener>
            {/* {shareArr[shareArr.indexOf(props.row.id)] ? (
              <CopiedIndicator>Copied search URL!</CopiedIndicator>
            ) : null} */}
          </>
        );
      },
    },
    {
      id: 'Delete',
      Cell: (props, displayName = 'test') => (
        <Button
          style={{ width: '140px', height: '26px' }}
          color="danger"
          onClick={props.handleDelete}
        >
          Delete Search
        </Button>
      ),
    },
  ]);
  return (
    <UserConsumer>
      {(userSess) => {
        return (
          <Cluster key={fire} index={'.user_' + userSess.user.username}>
            <ReactiveComponent
              componentId="TableWrapper"
              key={fire}
              react={{
                and: ['DatasetFilter', 'TimeFilter'],
              }}
              defaultQuery={savedSearches}
              customQuery={savedSearches}
            />
            <ReactiveList
              react={{
                and: ['TableWrapper'],
              }}
              loader={<Loader />}
              renderResultStats={function(stats) {
                let resultCount = stats.numberOfResults;
                if (resultCount === 10000) {
                  resultCount = '10,000+';
                }
                return (
                  <p className="s secondary">
                    Showing {stats.displayedResults} of total {resultCount} in{' '}
                    {stats.time} ms
                  </p>
                );
              }}
              componentId="SearchResults"
              dataField="_id"
              size={100}
              pagination={true}
              paginationAt="both"
              load={true}
              render={(props) => {
                const { error, data, loading } = props;
                if (error) {
                  return <h4>No saved searches yet.</h4>;
                }
                if (data && !loading) {
                  return (
                    <SavedItemTable
                      data={data}
                      showNotes={true}
                      userSess={userSess}
                      fire={fire}
                      setFire={setFire}
                      columns={columns}
                    />
                  );
                }
                return <div />;
              }}
            />
          </Cluster>
        );
      }}
    </UserConsumer>
  );
};

export default Searches;
