import React, { useState } from 'react';
import styled from 'styled-components';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const Wrapper = styled.div.attrs(() => ({
  id: 'sort-wrapper',
}))`
  z-index: 9;
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) =>
    props.isOpen
      ? props.theme.colors.almostBlack
      : props.theme.colors.altPrimary};
  border-radius: 0px;
  padding: 3px 10px;

  &:hover {
    cursor: ${(props) => (props.isOpen ? 'auto' : 'pointer')};
    background-color: ${(props) => props.theme.colors.altPrimary};
  }
`;

const StyledIcon = styled.div`
  color: ${(props) => props.theme.colors.plume};
`;

const InputSelection = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.plume};
  *:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.plume};
  }
`;

const SelectedSortIndexText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.altPrimary};
`;

const SortHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.plume};

  &&&:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.altPrimary};
  }
`;

const LabelText = styled.p`
  width: fit-content;
  word-break: none;
  color: ${(props) => props.theme.colors.plume};
  font-weight: bold;
`;

const AbsolutePositioner = styled.div`
  position: absolute;
  bottom: -80px;
  color: ${(props) => props.theme.colors.primaryText};
  background-color: ${(props) => props.theme.colors.primary};
  left: 0px;
  border-radius: 0px;
  width: max-content;
  padding: 3px 10px;
`;

const Dropdown = ({ options, value, onChange, icon, dropdownLabel }) => {
  const [open, setOpen] = useState(false);

  const { dropdownValue } = value;

  const { value: currentOptionValue } = dropdownValue;

  const toggleDropdown = () => {
    setOpen(!open);
  };
  const handleDropdownClickOff = () => {
    setOpen(false);
  };

  return (
    <>
      <Wrapper isOpen={open}>
        <SortHeader onClick={toggleDropdown} style={{ display: 'flex' }}>
          <StyledIcon>{icon}</StyledIcon>
          <LabelText>{dropdownLabel}</LabelText>
        </SortHeader>
        {open ? (
          <ClickAwayListener onClickAway={handleDropdownClickOff}>
            <AbsolutePositioner>
              <form style={{ display: 'flex', flexDirection: 'column' }}>
                {options.map(({ label, value }) => {
                  return (
                    <InputSelection key={label}>
                      <label>
                        <input
                          onChange={() => {
                            onChange(value);
                            setOpen(false);
                          }}
                          checked={value === currentOptionValue}
                          style={{ width: 'fit-content' }}
                          type="radio"
                          name={label}
                        />
                        {label}
                      </label>
                    </InputSelection>
                  );
                })}
              </form>
            </AbsolutePositioner>
          </ClickAwayListener>
        ) : null}
      </Wrapper>
    </>
  );
};

export default Dropdown;
