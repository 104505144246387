import React, { useState, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import Card from '../Card/Card';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Button from '../Button/Button';
import Loader from '../Spinners/Loader';

import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import ChainalysisLogo from '../../assets/img/chainalysis_logo.png';
import DarkDarkblueLogo from '../../assets/img/darkblue_logo_dark.png';
import LightDarkblueLogo from '../../assets/img/darkblue_logo_light.png';
import CoinbaseLogo from '../../assets/img/coinbase_logo.svg';

const ThemedFilterAddIconIcon = styled(AddIcon)`
  vertical-align: middle;
  color: ${(props) => props.theme.colors.primary} !important;
  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const CardBodyContainer = styled(GridContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BitcoinContainer = styled(GridContainer)`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.mainBackground};
  margin: 10px;
`;

const TableContainer = styled(GridItem)`
  table {
    border-collapse: collapse;
    table-layout: fixed !important;
    width: 100%;
    color: ${(props) => props.theme.colors.cardTitle};
  }
  td,
  th {
    font-size: 15px;
    text-align: left;
    padding: 8px;
    word-wrap: break-word;
  }

  table tr th:nth-child(1) {
    width: 33%;
  }

  @media (min-width: 768px) and (max-width: 1050px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      font-weight: bold;
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }
  }
`;

const PartnerContainer = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
`;

const IntegrationsContainer = styled.div`
  display: flex;
`;

const PartnerButton = styled.div`
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
`;

const SizedPartnerImage = styled.img`
  max-width: 120px;
  width: 120px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const SvgPartnerImage = styled.img`
  height: 28px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const PartnerDemoLink = styled.a`
  font-size: 11px;
  color: ${(props) => props.theme.colors.primaryText};
  text-decoration: underline;
  position: absolute;
  bottom: 1px;
  left: 25%;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const PartnerContentsContainer = styled.div`
  position: relative;
`;

const ShowMore = styled.p`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

const ExpandContainer = styled.div`
  display: flex;
  padding-right: 36px;
`;

const TopCard = ({
  address,
  debits,
  credits,
  balance,
  txs30Days = 0,
  setFilterState,
  filterState,
  expanded,
  setExpanded,
  mode,
}) => {
  const history = useHistory();
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Bitcoin Address</th>
            <th>Total Debits (Inputs)</th>
            <th>Total Credits (Outputs)</th>
            <th>Current Balance</th>
            <th>Txs over 30 days</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  fontWeight: '500',
                }}
              >
                {address ? address : 'Invalid BTC Address'}
              </span>
              {address ? (
                <span style={{ paddingRight: '3px' }}>
                  <ThemedFilterAddIconIcon
                    onClick={() => {
                      setFilterState({
                        ...filterState,
                        ...{
                          BitcoinAddressesFilter: {
                            value: [address],
                          },
                        },
                      });
                    }}
                  />
                </span>
              ) : null}
            </td>
            <td>{credits ? `${credits} BTC` : 0}</td>
            <td>{debits ? `${debits} BTC` : 0}</td>
            <td>{balance ? `${balance} BTC` : 0}</td>
            <td>{txs30Days ? `${txs30Days}` : 0}</td>
          </tr>
        </tbody>
      </table>
      <PartnerContainer>
        {address ? <p>Explore More With</p> : null}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <IntegrationsContainer>
            {address ? (
              <PartnerContentsContainer>
                <PartnerButton
                  onClick={() => {
                    const path = `/Modules/bitcoin?bitcoinaddress=%5B\"${address}\"%5D`;
                    if (address) {
                      history.push(path);
                    }
                  }}
                >
                  <SizedPartnerImage
                    className="sized"
                    src={mode === 'dark' ? LightDarkblueLogo : DarkDarkblueLogo}
                  />
                </PartnerButton>
                <PartnerDemoLink />
              </PartnerContentsContainer>
            ) : null}
            {address ? (
              <PartnerContentsContainer>
                <PartnerButton
                  onClick={() => {
                    const url = `https://reactor.chainalysis.com/graphs/list/cluster/btc/${address}`;
                    window.open(url, '_blank');
                  }}
                >
                  <SizedPartnerImage src={ChainalysisLogo} className="sized" />
                </PartnerButton>
                <PartnerDemoLink
                  href="https://go.chainalysis.com/partner-reactor-access-request.html?utm_source=bluestone"
                  target="_blank"
                >
                  Request a Demo
                </PartnerDemoLink>
              </PartnerContentsContainer>
            ) : null}
            {address ? (
              <PartnerContentsContainer>
                <PartnerButton
                  onClick={() => {
                    const url = `http://app.analytics.coinbase.com/btc/addresses/${address}`;
                    window.open(url, '_blank');
                  }}
                >
                  <SvgPartnerImage src={CoinbaseLogo} />
                </PartnerButton>
              </PartnerContentsContainer>
            ) : null}
          </IntegrationsContainer>
          <ExpandContainer>
            {address ? (
              <ShowMore onClick={() => setExpanded(!expanded)}>
                {expanded
                  ? 'Hide More Transactional Data'
                  : 'More Transactional Data'}
              </ShowMore>
            ) : null}
          </ExpandContainer>
        </div>
      </PartnerContainer>
    </div>
  );
};

const BottomCard = ({ address, firstTx, recentTx, totalTx }) => {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>First Tx </th>
            <th>Most Recent Tx </th>
            <th>Total Tx Count </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>{firstTx} </td>
            <td>{recentTx}</td>
            <td>{totalTx}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const BtcCard = (props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <TopCard {...props} expanded={expanded} setExpanded={setExpanded} />
      {expanded ? <BottomCard {...props} /> : null}
    </div>
  );
};

const BitcoinModalView = ({
  bitcoinInfo,
  bitcoinAddress,
  last30Days,
  isLoading,
  setLoading,
  fetchData,
  setShowBtcInformation,
  filterState,
  setFilterState,
  bitcoinAddressChunks,
  setBitcoinAddressChunks,
}) => {
  const btcDivisor = 100000000;

  const themeContext = useContext(ThemeContext);

  return (
    <BitcoinContainer justify="center">
      <GridContainer direction="row">
        {bitcoinAddress.length >= 11 && (
          <GridItem xs={6}>
            <p style={{ margin: '8px 71px' }}>
              {`Showing ${bitcoinInfo.length} of ${bitcoinAddress.length} bitcoin addresses`}
            </p>
          </GridItem>
        )}
        <GridItem xs={bitcoinInfo.length >= 11 ? 6 : 12}>
          <Button
            color="danger"
            onClick={() => {
              setShowBtcInformation(false);
            }}
            style={{ margin: '8px 71px', float: 'right', padding: '10px 20px' }}
          >
            Close
          </Button>
        </GridItem>
      </GridContainer>
      <GridItem xs={12}>
        {bitcoinInfo
          .sort((a, b) => b.balance - a.balance)
          .map((item, index) => {
            const { txrefs = [] } = item;
            const hasTxs = txrefs.length > 0;
            const sortedTxs = hasTxs
              ? txrefs.sort(
                  (a, b) => {
                    return new Date(a.confirmed) - new Date(b.confirmed);
                  }
                )
              : false;
            const recentTx = hasTxs
              ? dayjs(sortedTxs[sortedTxs.length - 1].confirmed).format(
                  'YYYY-MM-DD',
                )
              : 'None';

            const firstTx = hasTxs
              ? dayjs(sortedTxs[0].confirmed).format('YYYY-MM-DD')
              : 'None';

            const totalTx = hasTxs ? item.n_tx : 'None';
            
            return (
              <Card key={index} style={{ margin: '8px 50px' }}>
                <GridContainer direction="column" justify="space-between">
                  <CardBodyContainer justify="center">
                    <TableContainer>
                      <BtcCard
                        mode={themeContext.mode}
                        filterState={filterState}
                        setFilterState={setFilterState}
                        address={item.address}
                        debits={item.total_sent / btcDivisor}
                        credits={item.total_received / btcDivisor}
                        balance={item.balance / btcDivisor}
                        txs30Days={
                          hasTxs &&
                          txrefs.filter(({ confirmed }) => {
                            const date = dayjs(confirmed).isAfter(last30Days);
                            return date;
                          }).length
                        }
                        firstTx={firstTx}
                        recentTx={recentTx}
                        totalTx={totalTx}
                      />
                    </TableContainer>
                  </CardBodyContainer>
                </GridContainer>
              </Card>
            );
          })}
      </GridItem>
      <div style={{ display: 'flex', justify: 'center' }}>
        {isLoading && <Loader />}
      </div>
      {bitcoinAddressChunks.length > 0 && (
        <GridItem>
          <Link
            onClick={() => {
              const [btcToFetch, ...newChunks] = bitcoinAddressChunks;
              setLoading(true);
              fetchData(btcToFetch);
              setBitcoinAddressChunks(newChunks);
            }}
            style={{
              cursor: 'pointer',
              textAlign: 'center',
              display: 'block',
              color: themeContext.colors.altPrimary,
              margin: '15px',
              fontSize: '20px',
            }}
          >
            View More
          </Link>
        </GridItem>
      )}
    </BitcoinContainer>
  );
};

export default BitcoinModalView;
