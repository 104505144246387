import dayjs from 'dayjs';

const mockRunningServers = async (apiKey) => {
  const data = [
    {
      server_type: 'darkhost',
      server_info: {
        size: 'small',
        os: 'ubuntu-21.04',
        storage: 10,
        tags: {
          name: 'darkserver4',
        },
      },
      services: ['nginx', 'tor', 'auditbeat'],
    },
    {
      server_type: 'darkhost',
      server_info: {
        size: 'medium',
        os: 'ubuntu-21.04',
        storage: 10,
        tags: {
          name: 'EvilSite96',
        },
      },
      services: ['nginx', 'tor', 'auditbeat'],
    },
    {
      server_type: 'darkhost',
      server_info: {
        size: 'medium',
        os: 'ubuntu-21.04',
        storage: 25,
        tags: {
          name: 'SadServer',
        },
      },
      services: ['nginx', 'tor', 'auditbeat'],
    },
    {
      server_type: 'darkhost',
      server_info: {
        size: 'XL',
        os: 'Debian-21.04',
        storage: 5000,
        tags: {
          name: 'ChonkyServer',
        },
      },
      services: ['nginx', 'tor', 'auditbeat'],
      files: 'BitcoinExchangeForBaddies.zip',
      address: '1800BitcoinTumblr.onion',
    },
  ];

  return Promise.resolve(data);
};

const createServer = async (values, apiKey) => {
  const wbUrl = 'https://' + process.env.REACT_APP_API_HOST;

  const createResponse = await fetch(`${wbUrl}/darkhost/build`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `ApiKey ${apiKey.key}`,
    },
    body: JSON.stringify(values),
  }).then((resp) => resp.json());
  return createResponse;
};

const getRunningServers = async (apiKey) => {
  const servers = await fetch(
    `https://${process.env.REACT_APP_API_HOST}/darkhost/list`,
    {
      method: 'POST',
      body: JSON.stringify({ status: 'running' }),
      headers: { Authorization: `ApiKey ${apiKey.key}` },
    },
  ).then((resp) => resp.json());

  return servers;
};

const handleServerStatus = async (value = '', id, apiKey) => {
  const pauseResponse = await fetch(
    `https://${process.env.REACT_APP_API_HOST}/darkhost/service/${id}/tor`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `ApiKey ${apiKey.key}`,
      },
      body: JSON.stringify({ status: value }),
    },
  ).then((resp) => resp.json());

  return pauseResponse;
};

const deleteServer = async (id, apiKey) => {
  const deleteResponse = await fetch(
    `https://${process.env.REACT_APP_API_HOST}/darkhost/server/${id}`,
    {
      method: 'DELETE',
      headers: { Authorization: `ApiKey ${apiKey.key}` },
    },
  ).then((resp) => resp.json());

  return deleteResponse;
};

const uploadFile = async (formData, serverId, apiKey) => {
  const uploadResponse = await fetch(
    `https://${process.env.REACT_APP_API_HOST}/darkhost/upload/${serverId}`,
    {
      method: 'POST',
      headers: {
        Authorization: `ApiKey ${apiKey.key}`,
      },
      body: formData,
    },
  ).then((resp) => resp.json());

  return uploadResponse;
};

const getServerLogs = async ({ type, serverId, apiKey }) => {
  if (type === 'packet') {
    const auditbeatResp = await fetch(
      `https://${process.env.REACT_APP_API_HOST}/darkhost/logs/${serverId}/auditbeat`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${apiKey.key}`,
        },
      },
    ).then((resp) => resp.json());
    const packetbeatResp = await fetch(
      `https://${process.env.REACT_APP_API_HOST}/darkhost/logs/${serverId}/packetbeat`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${apiKey.key}`,
        },
      },
    ).then((resp) => resp.json());

    return {
      ...auditbeatResp,
      ...packetbeatResp,
      hits: { hits: [...packetbeatResp.hits.hits, ...auditbeatResp.hits.hits] },
    };
  } else {
    const logResponse = await fetch(
      `https://${process.env.REACT_APP_API_HOST}/darkhost/logs/${serverId}/filebeat`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${apiKey.key}`,
        },
      },
    ).then((resp) => resp.json());

    return logResponse;
  }
};

const downloadServerLogs = async (props) => {
  const { type, serverId } = props;
  const logResponse = await getServerLogs(props);

  const fileName = `${serverId}-${type}-${dayjs().format(
    'YYYY-MM-DD hh mm ss',
  )}`;

  downloadTextAsPlainText({ fileName, ...{ text: logResponse } });
};

const mockServerStatus = async (servers) => {
  const data = servers.map((s, idx) => {
    if (idx % 3 === 0) return { status: 'Paused' };
    else if (idx % 2 === 0) {
      return { status: 'Server Initializing' };
    }
    return { status: 'Online' };
  });

  return Promise.resolve(data);
};

const downloadTextAsPlainText = ({ fileName = 'logs.txt', text }) => {
  const element = document.createElement('a');
  const file = new Blob([text], {
    type: 'text/plain;charset=utf-8',
  });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
};

export {
  mockRunningServers,
  getRunningServers,
  mockServerStatus,
  createServer,
  handleServerStatus,
  deleteServer,
  uploadFile,
  getServerLogs,
  downloadTextAsPlainText,
  downloadServerLogs,
};
