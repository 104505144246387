import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import styled, { ThemeContext } from 'styled-components';
import { useQuery } from 'urql';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import { addLayerToMap, handleAdTechState, resetMarker } from '../mapUtils';
import Loader from '../../../../darkblue-ui/Spinners/Loader';
import Button from 'darkblue-ui/Button/Button';
import '../MapboxStyle.css';
import { Close, ExitToApp } from '@material-ui/icons';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 10px 0px;
`;

const ItemName = styled.strong`
  color: ${(props) => props.theme.colors.primaryText};
  font-size: 14px;
`;

const ItemValue = styled.p`
  margin: 0 0 0 10px;
  flex: 1 1 0;
  min-width: 0;
`;

const AdTechContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0px;
  padding: 0px 15px;
  background-color: ${(props) => props.theme.colors.cardBackground};
`;

const AdTechItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.cardBackground};
  border-bottom: 1px solid ${(props) => props.theme.colors.primaryText};
`;

const CloseIcon = styled(Close)`
  position: absolute;
  top: 8px;
  right: 10px;
  color: ${(props) => props.theme.colors.primaryText};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.altPrimary};
  }
`;

const HighlightContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const HighlightedRecord = styled.div`
  position: absolute;
  width: 5px;
  min-width: 5px;
  height: 100%;
  background-color: ${(props) => props.highlightColor};
  ${(props) => !props.highlight && 'display: none'}
`;

const CloseButton = ({ setSliderContent, index, map, selectedFeature }) => {
  const { id, source } = selectedFeature;

  return (
    <CloseIcon
      onClick={() => {
        setSliderContent((prevState) => {
          const cleanState = prevState.filter((item, i) => {
            return i !== index;
          });
          const featureIds = cleanState
            .map((c) => c.selectedFeature.id)
            .filter((featureId) => featureId === id);

          if (featureIds.length === 0) {
            resetMarker({ map: map.current, selectedFeature });
          }

          return cleanState;
        });
      }}
    />
  );
};

const DarkflowContent = ({
  content,
  map,
  field,
  filterState,
  setFilterState,
  index,
  setSliderContent,
  type,
}) => {
  const { data, selectedFeature } = content;
  const theme = useContext(ThemeContext);
  const [hasSearched, setHasSearched] = useState(false);
  const fieldPrefix = 'server';
  const ipAddress = _.get(data, `${fieldPrefix}.ip.ip`);
  const latLong = _.get(data, type);
  const AdTechQuery = `
  query ($ipAddresses: [String]) {
    adTech(ipAddresses: $ipAddresses) {
  		ip_address
      advertising_id
  		advertising_id_type
  		latitude
  		longitude
  		user_agent
  		timestamp
  		country
    }
  }
  `;

  const fields = [
    { displayName: 'Latitude', accessor: `${fieldPrefix}.geo.location.lat` },
    { displayName: 'Longitude', accessor: `${fieldPrefix}.geo.location.lon` },
    { displayName: 'IP', accessor: `${fieldPrefix}.ip.ip` },
    { displayName: 'ASN', accessor: `${fieldPrefix}.asn.organization_name` },
    { displayName: 'Packets', accessor: `${fieldPrefix}.packets` },
    { displayName: 'Bytes', accessor: `${fieldPrefix}.bytes` },
  ];

  // const [result, runQuery] = useQuery({
  //   query: AdTechQuery,
  //   pause: true,
  //   variables: {
  //     ipAddresses: [ipAddress],
  //   },
  // });

  // const { data: adTechData, fetching, error } = result;

  // useEffect(() => {
  //   if (fetching) {
  //     setHasSearched(true);
  //   } else if (!fetching && adTechData?.adTech.length === 0) {
  //     setHasSearched(true);
  //   } else {
  //     setHasSearched(false);
  //   }
  // }, [fetching, adTechData]);

  // useEffect(() => {
  //   if (
  //     Array.isArray(adTechData?.adTech) &&
  //     adTechData?.adTech.length > 0 &&
  //     map.current &&
  //     !map.current.getSource('ad-tech')
  //   ) {
  //     handleAdTechState({
  //       map: map.current,
  //       field,
  //       adTechData: adTechData.adTech,
  //       point: latLong,
  //       filterState,
  //       setFilterState,
  //     });
  //     const coordinates = adTechData.adTech.map(({ latitude, longitude }) => [
  //       longitude,
  //       latitude,
  //     ]);

  //     if (!map.current.getSource('ad-tech')) {
  //       const geojson = {
  //         type: 'FeatureCollection',
  //         features: coordinates.map((coord) => ({
  //           type: 'Feature',
  //           geometry: { type: 'Point', coordinates: coord },
  //         })),
  //       };

  //       addLayerToMap({
  //         map: map.current,
  //         properties: {},
  //         id: 'ad-tech',
  //         color: 'rgb(168, 50, 166)',
  //         highlightColor: theme.colors.fuschia,
  //         geojson,
  //       });

  //       const bounds = new mapboxgl.LngLatBounds(
  //         coordinates[0],
  //         coordinates[0],
  //       );

  //       //Extend the 'LngLatBounds' to include every coordinate in the bounds result.
  //       const newBounds = [...coordinates, [latLong.lon, latLong.lat]];
  //       newBounds.forEach((coord) => {
  //         bounds.extend(coord);
  //       });

  //       map.current.fitBounds(bounds, {
  //         padding: 100,
  //       });
  //     }
  //   }
  // }, [adTechData?.adTech]);

  return (
    <ContentContainer>
      <CloseButton
        map={map}
        index={index}
        setSliderContent={setSliderContent}
        selectedFeature={selectedFeature}
      />
      {fields.map(({ displayName, accessor }) => {
        const value = _.get(data, accessor);
        return (
          <ItemContainer key={displayName}>
            <ItemName>{displayName}:</ItemName>
            <ItemValue>{value}</ItemValue>
          </ItemContainer>
        );
      })}
      {/* <ItemName>AdTech Data</ItemName> */}
      {/* {!fetching && !adTechData?.adTech ? (
        <Button
          style={{
            margin: '10px 0',
            height: '30px',
            width: '60%',
            alignSelf: 'center',
          }}
          onClick={runQuery}
        >
          Get AdTech Data
        </Button>
      ) : null} */}
      {/* {fetching && <Loader />} */}
      {/* {adTechData?.adTech && adTechData?.adTech.length > 0 ? (
        <AdTechContainer>
          {adTechData.adTech.map((ad) => {
            return (
              <AdTechItemContainer key={ad.advertising_id}>
                <ItemContainer style={{ width: '100%' }}>
                  <ItemName>IP:</ItemName>
                  <ItemValue>{ad.ip_address}</ItemValue>
                </ItemContainer>
                <ItemContainer style={{ width: '100%' }}>
                  <ItemName>Ad ID:</ItemName>
                  <ItemValue>{ad.advertising_id}</ItemValue>
                </ItemContainer>
                <ItemContainer style={{ width: '100%' }}>
                  <ItemName>Timestamp:</ItemName>
                  <ItemValue>{ad.timestamp}</ItemValue>
                </ItemContainer>
              </AdTechItemContainer>
            );
          })}
        </AdTechContainer>
      ) : !fetching && hasSearched ? (
        <ItemContainer>
          <ItemValue>No AdTech data found.</ItemValue>
        </ItemContainer>
      ) : null} */}
    </ContentContainer>
  );
};

const DatasetContent = ({ content, index, setSliderContent, map }) => {
  const { id, data, selectedFeature } = content;
  const url = `/search/data?DocIdFilter=${encodeURIComponent(
    JSON.stringify({
      value: [id],
    }),
  )}`;

  const fields = [
    { displayName: 'Site', accessor: 'site.name' },
    { displayName: 'Title', accessor: 'title' },
    { displayName: 'Thread ID', accessor: 'threadId' },
    { displayName: 'Post ID', accessor: 'postId' },
    { displayName: 'Post', accessor: 'post' },
  ];

  return (
    <ContentContainer>
      <CloseButton
        map={map}
        index={index}
        setSliderContent={setSliderContent}
        selectedFeature={selectedFeature}
      />
      {fields.map(({ displayName, accessor }) => {
        const value = _.get(data, accessor);
        return (
          <ItemContainer key={displayName}>
            <ItemName>{displayName}:</ItemName>
            <ItemValue>{value}</ItemValue>
          </ItemContainer>
        );
      })}
      <a
        href={url}
        target="_blank"
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0',
          alignSelf: 'center',
          textDecoration: 'none',
          fontSize: '14px',
        }}
      >
        View details in DarkBlue <ExitToApp />
      </a>
    </ContentContainer>
  );
};

const SliderContent = (props) => {
  const { type } = props;

  switch (type) {
    case 'darkflow.server.geo.location':
      return (
        <HighlightContainer className="highlight">
          <HighlightedRecord
            highlightColor={props?.content?.highlightColor}
            highlight={props?.content?.highlight}
          />
          <DarkflowContent {...props} />
        </HighlightContainer>
      );
    case 'ai.geo.coordinates':
      return (
        <HighlightContainer className="highlight">
          <HighlightedRecord
            highlightColor={props?.content?.highlightColor}
            highlight={props?.content?.highlight}
          />
          <DatasetContent {...props} />
        </HighlightContainer>
      );
    default:
      return (
        <HighlightContainer className="highlight">
          <HighlightedRecord
            highlightColor={props?.content?.highlightColor}
            highlight={props?.content?.highlight}
          />
          <DatasetContent {...props} />
        </HighlightContainer>
      );
  }
};

export default SliderContent;
