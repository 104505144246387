import React from 'react';
import styled, { css } from 'styled-components';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.active ? props.theme.colors.primary : props.theme.colors.cloud};
  border-radius: 0px;
  transition: all 100ms;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.cloud};

  ${CheckboxContainer}:hover {
    border: 1px solid ${(props) => props.theme.colors.altPrimary};
  }

  ${(props) =>
    props.sidebar &&
    css`
      width: 26px;
      height: 26px;
    `}
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-right: 4px;
`;

const Icon = styled.svg`
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const Checkbox = ({ active, className, ...rest }) => {
  return (
    <CheckboxContainer {...rest} className={className}>
      <HiddenCheckbox active={active} {...rest} />
      <StyledCheckbox active={active}>
        <Icon active={active} viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
