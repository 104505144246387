import React, { useEffect } from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { requiredFieldsQuery } from '../../../utils/Queries/CustomQueries';

const CustomComponent = (props) => {
  useEffect(() => {
    props.setQuery({
      query: requiredFieldsQuery(props.value),
      value: props.value,
    });
  }, [props.value]);

  useEffect(() => {
    setTimeout(() => {
      props.setQuery({
        query: requiredFieldsQuery(props.value),
        value: props.value,
      });
    }, 750);
  }, []);

  return null;
};

const RequiredFieldFilter = (props) => {
  return (
    <ReactiveComponent
      {...props}
      URLParams={true}
      componentId="RequiredFieldFilter"
      customQuery={() => requiredFieldsQuery(props.value)}
      defaultQuery={() => requiredFieldsQuery(props.value)}
      render={({ setQuery }) => (
        <CustomComponent value={props.value} setQuery={setQuery} />
      )}
    />
  );
};

export default RequiredFieldFilter;
