import React, { useMemo, useEffect, useState, useContext } from 'react';
import DisplayTable from './ResultsWidgets/DisplayTable';
import Loader from 'darkblue-ui/Spinners/Loader';
import Pagination from 'darkblue-ui/Pagination/Pagination';
import _ from 'lodash';
import { useTable, useSortBy } from 'react-table';
import HighlightComponent from 'darkblue-ui/composite/DarkBlueCard/HighlightComponent';
import ColumnInputSelect from 'darkblue-ui/Inputs/ColumnInputSelect';
import { StateContext } from 'darkblue-ui/Search/StateProviderContext/StateProviderContext';

const plainToFlattenObject = (object) => {
  const result = {};

  const flatten = (obj, prefix = '') => {
    _.forEach(obj, (value, key) => {
      if (_.isObject(value) && !Array.isArray(value)) {
        flatten(value, `${prefix}${key}.`);
      } else if (key !== 'snapshot') {
        result[`${prefix}${key}`] = <div>{String(value || 'Missing')}</div>;
      } else {
        result[`${prefix}${key}`] = String(value);
      }
    });
  };

  flatten(object);

  return result;
};

const TableResults = ({
  loading,
  page,
  setPage,
  size,
  totalHits,
  hits,
  filterState,
  ...rest
}) => {
  const [localHiddenColumns, setLocalHiddenColumns] = useState([]);
  const [inputValue, setInputValue] = useState([]);

  const allColumnNames = useMemo(
    () =>
      hits.length > 0
        ? [
            ...new Set(
              hits
                .map((record) => {
                  return Object.entries(
                    plainToFlattenObject(record._source),
                  ).map(([key]) => {
                    return key;
                  });
                })
                .flat(1)
                .sort(),
            ),
          ]
        : hits,
    [hits],
  );

  const tableColumns = useMemo(
    () =>
      allColumnNames
        .filter((v) => localHiddenColumns.indexOf(v) === -1)
        .map((record) => {
          return {
            Header: record,
            Cell: ({ cell: { value } }) => (
              <HighlightComponent text={String(value)} />
            ),
            accessor: record,
          };
        }),
    [allColumnNames],
  );

  const tableData = useMemo(() => hits.map((r) => r._source), [
    filterState,
    hits,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
  } = useTable({
    columns: tableColumns,
    data: tableData,
  });

  useEffect(() => {
    setInputValue(
      tableColumns.map(({ Header }) => {
        return {
          id: Header,
          value: Header,
          label: Header,
        };
      }),
    );
    setHiddenColumns([...localHiddenColumns]);
  }, [hits]);

  useEffect(() => {
    setHiddenColumns([...localHiddenColumns]);
  }, [localHiddenColumns, page]);

  if (loading) {
    return (
      <div style={{ width: 'fit-content', margin: '0 auto' }}>
        <Loader />
      </div>
    );
  } else if (hits.length === 0) {
    return <h1>No results found </h1>;
  } else {
    return (
      <>
        <ColumnInputSelect
          inputValue={inputValue}
          setInputValue={setInputValue}
          tableColumns={tableColumns}
          localHiddenColumns={localHiddenColumns}
          setLocalHiddenColumns={setLocalHiddenColumns}
          setHiddenColumns={setHiddenColumns}
        />
        <Pagination
          page={page}
          setPage={setPage}
          size={size}
          totalHits={totalHits}
        />
        <DisplayTable
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          {...rest}
        />
        <Pagination
          page={page}
          setPage={setPage}
          size={size}
          totalHits={totalHits}
          bottom
        />
      </>
    );
  }
};

export default TableResults;
