import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { useDropzone } from 'react-dropzone';
import dayjs from 'dayjs';
import {
  createServer,
  deleteServer,
  uploadFile,
  getServerLogs,
  downloadTextAsPlainText,
} from './utils';
import { useSnackbar } from 'notistack';
import Close from '@material-ui/icons/Close';

import HighlightComponent from '../../darkblue-ui/composite/DarkBlueCard/HighlightComponent';
import Loader from '../../darkblue-ui/Spinners/Loader';
import * as yup from 'yup';
import Button from '../../darkblue-ui/Button/Button';

import Modal from '../../darkblue-ui/Modals/Modal/Modal';
import Input from 'darkblue-ui/Inputs/Input';

const StyledModal = styled(Modal)`
  max-width: 80vw !important;
`;

const ModalContentWrapper = styled.div`
  padding: 25px;
  background-color: ${(props) => props.theme.colors.mainBackground};
  display: flex;
  flex-direction: column;
`;

const CreationTag = styled.h4`
  margin: 10px 0;
  font-size: 14px;
`;

const TagDescription = styled.p`
  margin: 0px 0px 10px 0px;
  font-style: italic;
`;

const CreationFormGrid = styled.div`
  display: grid;
  min-width: 30vw;
  row-gap: 10px;
`;

const StyledDropZone = styled.div`
  border: 2px ${(props) => props.theme.colors.primaryText} dashed;
  padding: 5px;
  text-align: center;
  width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSelect = styled(Field)`
  outline: none;
  background: ${(props) => props.theme.colors.fog};
  border: none;
  color: ${(props) => props.theme.colors.navy};
  height: 48px;
  border: 1px solid ${(props) => props.theme.colors.cloud};
`;

const StyledCloseIcon = styled(Close)`
  color: ${(props) => props.theme.colors.primaryText};
  align-self: flex-end;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary};
    cursor: pointer;
  }
`;

const schema = yup.object().shape({
  server_info: yup.object().shape({
    size: yup.string().required(),
    os: yup.string().required(),
    storage: 0,
    tags: yup.object().shape({
      //username: yup.string().required(),
      //group: yup.string().required(),
      name: yup.string().required(),
    }),
  }),
  // playbooks: yup
  //   .array()
  //   .of(yup.string().required())
  //   .required(),
});

const NewServerForm = ({ toggleModal, getServers, apiKey }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    server_type: 'darkhost',
    server_info: {
      size: 'small',
      os: 'ubuntu-21.04',
      storage: 8,
      tags: { username: '', group: '', name: '' },
    },
    services: ['tor', 'nginx', 'filebeat', 'packetbeat'],
  };

  return loading ? (
    <Loader />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        const cleanedValues = {
          ...values,
          server_info: {
            ...values.server_info,
            storage: parseInt(values.server_info.storage),
          },
        };
        try {
          setLoading(true);
          const response = await createServer(cleanedValues, apiKey);
          enqueueSnackbar('Server starting!', { variant: 'success' });
          getServers();
          toggleModal();
          setLoading(false);
        } catch (error) {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
      }}
    >
      {({ values, errors, handleChange }) => {
        return (
          <Form>
            <h2 style={{ marginTop: '0', marginBottom: '15px' }}>
              Create a DarkHost server
            </h2>
            <CreationFormGrid>
              <div>
                <CreationTag>Server Name</CreationTag>
                <TagDescription>The display name of the server</TagDescription>
                <Field name="server_info.tags.name">
                  {({ field, form, meta }) => {
                    return (
                      <Input
                        name="server_info.tags.name"
                        inputProps={{
                          onChange: (e) => form.handleChange(e),
                          value: form.values[field.name],
                        }}
                        style={{ width: '100%' }}
                        placeholder="Type server name"
                      />
                    );
                  }}
                </Field>
              </div>
              <div>
                <CreationTag>CPU / RAM</CreationTag>
                <TagDescription>
                  A combination of CPU and RAM (larger is faster)
                </TagDescription>
                <StyledSelect
                  style={{ width: '100%' }}
                  onChange={handleChange}
                  value={values.server_info.size}
                  as="select"
                  placeholder="Server Size"
                  name="server_info.size"
                >
                  <option value="small">Small</option>
                  <option value="medium">Medium</option>
                  <option value="large">Large</option>
                </StyledSelect>
              </div>
              <div>
                <CreationTag>Operating System</CreationTag>
                <TagDescription>
                  The Operating System the server will use
                </TagDescription>
                <StyledSelect
                  style={{ width: '100%' }}
                  onChange={handleChange}
                  value={values.server_info.os}
                  as="select"
                  placeholder="Server OS"
                  name="server_info.os"
                >
                  <option value="ubuntu-21.04">ubuntu-21.04</option>
                  <option value="ubuntu-20.04">ubuntu-20.04</option>
                  <option value="ubuntu-18.04">ubuntu-18.04</option>
                  <option value="centos-7">centos-7</option>
                </StyledSelect>
              </div>
              <div>
                <CreationTag>Storage</CreationTag>
                <TagDescription>
                  The amount of storage the server will have
                </TagDescription>
                <StyledSelect
                  style={{ width: '100%' }}
                  onChange={handleChange}
                  value={values.server_info.storage}
                  as="select"
                  placeholder="Choose storage size"
                  name="server_info.storage"
                >
                  <option value={8}>8 (Gb)</option>
                  <option value={16}>16 (Gb)</option>
                  <option value={32}>32 (Gb)</option>
                  <option value={64}>64 (Gb)</option>
                </StyledSelect>
              </div>
            </CreationFormGrid>
            <Button
              style={{ marginTop: '40px', padding: '10px 20px' }}
              type="submit"
            >
              Create
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

const UploadModalForm = ({
  currentServerId,
  getServers,
  toggleModal,
  apiKey,
}) => {
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    accept: '.zip',
    multiple: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [btnDisabled, setBtnDisabled] = useState(false);

  const fileInfo = acceptedFiles.map((file) => {
    const { size, path } = file;

    return (
      <li style={{ listStyleType: 'none' }} key={path}>
        {path} - {size} bytes
      </li>
    );
  });

  return (
    <div>
      <h2>Upload DarkHost site files</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '10px 0px',
        }}
      >
        <section style={{ width: '100%' }} className="container">
          <StyledDropZone {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag and drop a zip file</p>
          </StyledDropZone>
          {fileInfo.length > 0 && (
            <aside>
              <h4 style={{ marginBottom: 0 }}>File</h4>
              <ul>{fileInfo}</ul>
            </aside>
          )}
        </section>
        <p>or</p>
        <Button style={{ padding: '10px 20px' }} onClick={open}>
          Choose file to upload
        </Button>
      </div>
      <Button
        style={{ padding: '10px 20px' }}
        disabled={btnDisabled}
        onClick={() => {
          setBtnDisabled(true);
          acceptedFiles.map(async (file) => {
            try {
              const { path, name } = file;
              const formData = new FormData();
              const arrayBuffer = await file.arrayBuffer();
              const blob = new Blob([new Uint8Array(arrayBuffer)]);
              formData.append('file', blob, name);

              const uploadResponse = await uploadFile(
                formData,
                currentServerId,
                apiKey,
              );

              if (uploadResponse?.message === 'success') {
                enqueueSnackbar('File uploaded successfully!', {
                  variant: 'success',
                });
                getServers();
                toggleModal();
              } else {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
              }
              setBtnDisabled(false);
            } catch (error) {
              enqueueSnackbar('Something went wrong', { variant: 'error' });
              setBtnDisabled(true);
            }
          });
        }}
      >
        Upload to server
      </Button>
    </div>
  );
};

const LogModal = (props) => {
  const { type, currentServer } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [originalText, setOriginalText] = useState(``);
  const [loading, setLoading] = useState(false);
  const [logText, setLogText] = useState(``);
  const [errorFetching, setErrorFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (searchTerm.length === 0 && originalText && originalText.length > 0) {
      setLogText(originalText);
    } else if (searchTerm.length > 0 && originalText.length > 0) {
      let findTermExp = new RegExp(`(${searchTerm})`, 'gi');
      const highlightedText = originalText.replace(
        findTermExp,
        '<mark>$1</mark>',
      );
      setLogText(highlightedText);
    }
  }, [searchTerm]);

  useEffect(() => {
    const getLogInfo = async () => {
      try {
        setLoading(true);
        const logTextResponse = await getServerLogs({
          ...props,
          serverId: currentServer.serverId,
        });
        const logs = JSON.stringify(
          logTextResponse.hits.hits.map((h) => h._source),
        );
        setLogText(logs);
        setOriginalText(logs);
        setLoading(false);
        setErrorFetching(false);
      } catch (error) {
        setErrorFetching(true);
      }
    };

    getLogInfo();
  }, [currentServer.serverId, type]);

  const fileName = `${currentServer.serverId}-${type}-${dayjs().format(
    'YYYY-MM-DD hh mm ss',
  )}`;

  if (!loading && logText.length > 0 && !errorFetching) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <div
            style={{
              padding: '0 10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h2>
              {currentServer.server_info.tags.name}{' '}
              {type === 'server' ? 'Server' : 'Network'} Logs
            </h2>
            <h3>{currentServer.serverId}</h3>
          </div>

          <Button
            onClick={() => downloadTextAsPlainText({ text: logText, fileName })}
            style={{ marginLeft: '15px', padding: '10px 20px' }}
          >
            Download
          </Button>
        </div>
        <div style={{ padding: '10px' }}>
          <h3 style={{ marginBottom: '5px' }}>Search Logs</h3>
          <Input
            style={{
              width: '100%',
              boxSizing: 'border-box',
              marginBottom: '20px',
            }}
            type="text"
            inputProps={{
              value: searchTerm,
              onChange: (e) => setSearchTerm(e.target.value),
            }}
            placeholder="Type search keyword(s)"
          />
          <div
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              width: '100%',
              height: '100%',
              minWidth: '800px',
              minHeight: '400px',
              maxHeight: '25vh',
              backgroundColor: 'black',
              color: 'limegreen',
              padding: '10px',
              boxSizing: 'border-box',
            }}
          >
            <HighlightComponent text={logText} />
          </div>
        </div>
      </div>
    );
  } else if (loading) {
    return <Loader />;
  } else if (errorFetching) {
    enqueueSnackbar('Something went wrong', { variant: 'error' });
  }
  return logText.length === 0 && <h1>No Logs Found</h1>;
};

const DeleteModal = ({ toggleModal, currentServerId, getServers, apiKey }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = async () => {
    try {
      const deleteResponse = await deleteServer(currentServerId, apiKey);

      getServers();
      enqueueSnackbar('Successfully deleted the server', {
        variant: 'success',
      });
      toggleModal('');
    } catch (error) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    }
  };

  return (
    <div>
      <h2>Are you sure you want to delete this server?</h2>
      <div style={{marginTop: '5vh'}}>
      <Button style={{ padding: '10px 20px' }} onClick={handleDelete}>
        Yes
      </Button>
      <Button
        style={{ padding: '10px 20px', marginLeft: '8px' }}
        value="delete"
        onClick={toggleModal}
      >
        No
      </Button>
      </div>
    </div>
  );
};

const ServerModalForm = ({
  type,
  open,
  onClickOutside,
  toggleModal,
  currentServerId,
  apiKey,
  getServers,
  currentServer,
}) => {
  if (type === 'create') {
    return (
      <StyledModal
        widthoverride="true"
        open={open}
        onClickOutside={onClickOutside}
      >
        <ModalContentWrapper>
          <StyledCloseIcon onClick={onClickOutside} />
          <NewServerForm
            getServers={getServers}
            toggleModal={toggleModal}
            apiKey={apiKey}
          />
        </ModalContentWrapper>
      </StyledModal>
    );
  } else if (type === 'upload') {
    return (
      <StyledModal
        widthoverride="true"
        open={open}
        onClickOutside={onClickOutside}
      >
        <ModalContentWrapper>
          <StyledCloseIcon onClick={onClickOutside} />
          <UploadModalForm
            currentServerId={currentServerId}
            toggleModal={toggleModal}
            apiKey={apiKey}
            getServers={getServers}
          />
        </ModalContentWrapper>
      </StyledModal>
    );
  } else if (type === 'delete') {
    return (
      <StyledModal
        widthoverride="true"
        open={open}
        onClickOutside={onClickOutside}
      >
        <ModalContentWrapper>
          <StyledCloseIcon onClick={onClickOutside} />
          <DeleteModal
            currentServerId={currentServerId}
            toggleModal={toggleModal}
            apiKey={apiKey}
            getServers={getServers}
          />
        </ModalContentWrapper>
      </StyledModal>
    );
  } else if (type === 'packet' || type === 'server') {
    return (
      <StyledModal
        widthoverride="true"
        open={open}
        onClickOutside={onClickOutside}
      >
        <ModalContentWrapper>
          <StyledCloseIcon onClick={onClickOutside} />
          <LogModal currentServer={currentServer} apiKey={apiKey} type={type} />
        </ModalContentWrapper>
      </StyledModal>
    );
  } else {
    return null;
  }
};

export default ServerModalForm;
