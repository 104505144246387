import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import Button from '../../darkblue-ui/Button/Button';

import { UniversalDefaultFilterState } from '../../filters/UniversalFilters';
import GridContainer from '../../components/Grid/GridContainer';
import {
  getDefaultFilterState,
  getDefaultIndexFilterState,
} from '../../filters/utils';

import { capitalizeFirstLetter } from '../../utils/Functions/utils';

import { SelectedFilters } from '@appbaseio/reactivesearch';
import Divider from '@material-ui/core/Divider';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { startSearchDate } from '../../consts/MagicValues';

const FilterButton = styled(Button)`
  max-height: fit-content;
  min-height: fit-content;
  height: fit-content;
  padding: 4px 8px;
  margin: 1.5px;
  word-break: break-word;
  background-color: ${(props) => props.color || props.theme.colors.primary};
  border-radius: 0px;
  &:hover {
    background-color: ${(props) =>
      props.hoverColor || props.theme.colors.danger};
    text-decoration: line-through;
  }
`;

const SelectedFilterParagraph = styled.div`
  color: #2a8dfe;
  display: flex;
  align-items: center;
  font-family: 'Red Hat Text', bold !important;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const NotFilterButton = styled(FilterButton)`
  height: fit-content;
  &:hover {
    background-color: ${(props) => props.theme.colors.danger};
    text-decoration: line-through;
  }
`;

const CustomStyledSelectedFilters = styled(SelectedFilters)`
  a {
    height: fit-content;
    background-color: #777;
    color: white;
  }

  svg.filter-header-icons {
    vertical-align: middle;
    margin-left: 8px;
    font-size: 16px;
    color: #67737e;
    letter-spacing: 0;
    text-align: center;
  }
`;

const ClearFilterButton = styled(Button)`
  border: 2px solid transparent;
  &:hover {
    background-color: ${(props) => props.theme.colors.plume};
    color: ${(props) => props.theme.colors.warning};
    border: 2px solid ${(props) => props.theme.colors.warning};
    & > * {
      color: ${(props) => props.theme.colors.warning};
    }
  }
`;

const parseNotFilterValue = (name) => {
  return name
    .split('.')
    .filter((x) => x !== 'keyword')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const CustomSelectedFilters = (props) => {
  const {
    filterState,
    setFilterState,
    dashboard,
    filters,
    index,
    showClearFilters = true,
  } = props;
  const parseObject = (obj) => {
    if (Array.isArray(obj) && typeof obj[0] === 'object') {
      return obj
        .filter((item) => item !== undefined && item !== null)
        .map((item) => {
          const foo = Object.entries(item);
          return foo.map((entry) => {
            return entry[0] + ' : ' + entry[1] + '<br />';
          });
        })
        .join(', ');
    } else if (Array.isArray(obj)) {
      return obj.join(', ');
    } else {
      return obj;
    }
  };

  return (
    <GridContainer
      direction="column"
      style={{
        padding: '0 15px 10px',
        width: '125%',
      }}
    >
      <CustomStyledSelectedFilters
        showClearAll={false}
        render={(props) => {
          const {
            selectedValues: { TimeFilter, ...selectedValues },
            setValue,
          } = props;

          const clearFilter = (component) => {
            setValue(component, null);
          };

          const filters = Object.keys(selectedValues)
            .filter((comp) => {
              return comp !== 'TimeFilter' && comp !== 'RestrictedFieldsFilter';
            })
            .map((component) => {
              const label = selectedValues[component].label + ': ';
              const parsedObject =
                component === 'IndexFilter' && selectedValues[component].value
                  ? parseObject(
                      selectedValues[component].value.map((s) =>
                        capitalizeFirstLetter(s.split('_')[0]),
                      ),
                    )
                  : component === 'LBCIndexFilter' &&
                    selectedValues[component].value
                  ? parseObject(
                      selectedValues[component].value.map((s) =>
                        s === 'lbc_bulletin'
                          ? capitalizeFirstLetter(
                              'lbc_advertisement'.split('_')[1],
                            )
                          : capitalizeFirstLetter(s.split('_')[1]),
                      ),
                    )
                  : parseObject(selectedValues[component].value);
              if (
                !selectedValues[component].value ||
                selectedValues[component].value.length === 0 ||
                label === 'undefined: '
              ) {
                return null;
              } else if (
                component === 'NotFilter' &&
                selectedValues[component].value.length
              ) {
                return selectedValues[component].value.map((value, index) => {
                  if (value && index % 2 === 0) {
                    const notFilterKey = selectedValues[component].value[index];
                    const notFilterValue =
                      selectedValues[component].value[index + 1];

                    return (
                      <span
                        key={
                          component +
                          notFilterValue +
                          String(filterState[component]) +
                          notFilterKey
                        }
                        style={{ display: 'inline-grid' }}
                      >
                        <SelectedFilterParagraph
                          selector
                          customChild
                          className="fit-content"
                          onClick={() => {
                            var newArr = [];
                            filterState.NotFilter.forEach((val, idx) => {
                              if (idx % 2 === 0) {
                                newArr.push([
                                  filterState.NotFilter[idx],
                                  filterState.NotFilter[idx + 1],
                                ]);
                              }
                            });

                            const notFilterArray = newArr
                              .filter((arr) => {
                                return arr.some(
                                  (item) =>
                                    item.indexOf(notFilterValue) === -1 &&
                                    item.indexOf(notFilterKey) === -1,
                                );
                              })
                              .flat();

                            clearFilter(component);
                            setFilterState({
                              ...filterState,
                              ...{
                                NotFilter: notFilterArray,
                              },
                            });
                          }}
                          key={notFilterKey + notFilterValue}
                        >
                          Excluded {parseNotFilterValue(notFilterKey)}:{' '}
                          {notFilterValue}
                          <HighlightOffIcon
                            className="filter-header-icons"
                            fontSize="small"
                          />
                        </SelectedFilterParagraph>
                      </span>
                    );
                  } else {
                    return null;
                  }
                });
              } else if (
                component === 'BulkSearchFilter' &&
                selectedValues[component].value.length
              ) {
                return (
                  <span
                    key={component + String(filterState[component])}
                    style={{ display: 'inline-grid' }}
                  >
                    <SelectedFilterParagraph
                      selector
                      customChild
                      onClick={() => {
                        clearFilter(component);
                        setFilterState({
                          ...filterState,
                          ...{
                            [component]: '',
                          },
                        });
                      }}
                      className="fit-content"
                    >
                      Bulk Search Active
                      <HighlightOffIcon
                        className="filter-header-icons"
                        fontSize="small"
                      />
                    </SelectedFilterParagraph>
                  </span>
                );
              } else {
                return (
                  <span
                    key={component + String(filterState[component])}
                    style={{ display: 'inline-grid' }}
                  >
                    <SelectedFilterParagraph
                      selector
                      customChild
                      className="fit-content"
                      onClick={() => {
                        clearFilter(component);
                        if (
                          component === 'TimeFilter' &&
                          filterState.TimeFilter.start.getTime() !==
                            startSearchDate.getTime() &&
                          !Boolean(dashboard)
                        ) {
                          setFilterState({
                            ...filterState,
                            ...{
                              TimeFilter: {
                                start: startSearchDate,
                                end: new Date(),
                              },
                            },
                          });
                        } else if (component === 'TimeFilter') {
                          const p7 = new Date();
                          p7.setDate(p7.getDate() - 7);
                          setFilterState({
                            ...filterState,
                            ...{
                              TimeFilter: {
                                start: p7,
                                end: new Date(),
                              },
                            },
                          });
                        } else if (
                          typeof filterState[component] === 'object' &&
                          Array.isArray(filterState[component])
                        ) {
                          setFilterState({
                            ...filterState,
                            ...{ [component]: [] },
                          });
                        } else if (typeof filterState[component] === 'string') {
                          setFilterState({
                            ...filterState,
                            ...{ [component]: '' },
                          });
                        } else if (
                          filterState.hasOwnProperty('columnFilterState') &&
                          filterState.columnFilterState.hasOwnProperty(
                            component,
                          )
                        ) {
                          setFilterState({
                            ...filterState,
                            columnFilterState: {
                              ...filterState.columnFilterState,
                              [component]: '',
                            },
                          });
                        } else {
                          setFilterState({
                            ...filterState,
                            ...{ [component]: {} },
                          });
                        }
                      }}
                    >
                      {label}{' '}
                      {Array.isArray(selectedValues[component].value)
                        ? parsedObject
                        : selectedValues[component].value}
                      <HighlightOffIcon
                        className="filter-header-icons"
                        fontSize="small"
                      />
                    </SelectedFilterParagraph>
                  </span>
                );
              }
            });

          return [...filters];
        }}
        // Use for Saved Searches onChange={(selectedValue) => console.log(selectedValue)}
        onClear={(component, value) => {
          if (
            component === 'TimeFilter' &&
            filterState.TimeFilter.start.getTime() !=
              startSearchDate.getTime() &&
            !Boolean(dashboard)
          ) {
            setFilterState({
              ...filterState,
              ...{
                TimeFilter: {
                  start: startSearchDate,
                  end: new Date(),
                },
              },
            });
          } else if (component === 'TimeFilter') {
            const p7 = new Date();
            p7.setDate(p7.getDate() - 7);
            setFilterState({
              ...filterState,
              ...{
                TimeFilter: {
                  start: p7,
                  end: new Date(),
                },
              },
            });
          } else if (
            typeof filterState[component] === 'object' &&
            Array.isArray(filterState[component])
          ) {
            setFilterState({
              ...filterState,
              ...{ [component]: [] },
            });
          } else if (typeof filterState[component] === 'string') {
            setFilterState({
              ...filterState,
              ...{ [component]: '' },
            });
          } else {
            setFilterState({
              ...filterState,
              ...{ [component]: {} },
            });
          }
        }}
      />
    </GridContainer>
  );
};

export default CustomSelectedFilters;
