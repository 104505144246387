const terms = [
  { path: 'ai.corporateRisk' },
  {
    path: 'ai.language',
    sortKey: 11,
    filterName: 'LanguageFilter',
    elasticPath: ['ai.language.keyword'],
    filterValueType: 'array',
  },
  { path: 'ai.location', sortKey: 10 },
  {
    path: 'ai.content.csam',
    elasticPath: ['ai.content.csam.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.drugs',
    filterName: 'ContentDrugsFilter',
    elasticPath: ['ai.content.drugs.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.fraud',
    elasticPath: ['ai.content.fraud.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.leaks',
    elasticPath: ['ai.content.leaks.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.violence',
    elasticPath: ['ai.content.violence.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.tags',
    sortKey: 4,
    filterName: 'ContentTagsFilter',
    elasticPath: ['ai.content.tags.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.technology',
    elasticPath: ['ai.content.technology.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.stats.matches.selectors.phoneNumbers',
  },
  {
    path: 'ai.stats.matches.selectors.pgp.identities.emails',
  },
  {
    path: 'ai.stats.matches.selectors.pgp.fingerprints',
  },
  {
    path: 'ai.stats.matches.selectors.emailAddresses',
  },
  {
    path: 'ai.stats.matches.selectors.bitcoinAddresses',
  },
  {
    path: 'ai.stats.activity.daysActive',
  },
  {
    path: 'ai.stats.activity.earliestActivity',
  },
  {
    path: 'ai.stats.activity.earliestProduct',
  },
  {
    path: 'ai.stats.activity.earliestReview',
  },
  {
    path: 'ai.stats.activity.earliestVendorProfile',
  },
  {
    path: 'ai.stats.selectors.email',
  },
  {
    path: 'ai.stats.selectors.emailAddresses',
  },
  {
    path: 'ai.stats.matches.names.exact',
  },
  {
    path: 'ai.stats.severity.fentanylMentions',
  },
  {
    path: 'ai.stats.selectors.languages',
  },
  {
    path: 'ai.stats.activity.lastActivity',
  },
  {
    path: 'ai.stats.activity.lastProduct',
  },
  {
    path: 'ai.stats.activity.lastReview',
  },
  {
    path: 'ai.stats.activity.products30day',
  },
  {
    path: 'ai.stats.activity.products7day',
  },
  {
    path: 'ai.stats.activity.productsTotal',
  },
  {
    path: 'ai.stats.activity.reviewsTotal',
  },
  {
    path: 'ai.stats.matches.names.fuzzy',
  },
  {
    path: 'ai.stats.matches.selectors.pgp',
  },
  {
    path: 'ai.stats.risk.explained',
  },
  {
    path: 'ai.stats.risk.rank',
    filterName: 'RiskRankFilter',
    elasticPath: ['ai.stats.risk.rank.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.stats.selectors.pgp.fingerprints',
  },
  {
    path: 'ai.stats.selectors.pgp.identities.emails',
  },
  {
    path: 'ai.stats.selectors.pgp.names',
  },
  {
    path: 'ai.stats.selectors.phone',
  },
  {
    path: 'ai.stats.selectors.phoneNumbers',
  },
  {
    path: 'ai.stats.selectors.postLinks',
  },
  {
    path: 'ai.stats.selectors.socialMedia',
  },
  {
    path: 'ai.stats.severity.productPercentage.opiates',
  },
  {
    path: 'ai.stats.severity.productPercentage.syntheticBenzodiazepines',
  },
  {
    path: 'Product Percentage: Synthetic Stimulants',
  },
  {
    path: 'ai.stats.severity.productPercentage.methamphetamines',
  },
  {
    path: 'ai.stats.severity.totalOpiates',
  },
  {
    path: 'ai.stats.severity.totalMethamphetamines',
  },
  {
    path: 'ai.stats.timestamp',
  },
  {
    path: 'ai.content.weapons',
    elasticPath: ['ai.content.weapons.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.opiates',
    elasticPath: ['ai.drugs.opiates.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticBenzodiazepines',
    elasticPath: ['ai.drugs.syntheticBenzodiazepines.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticCannabinoids',
    elasticPath: ['ai.drugs.syntheticCannabinoids.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticDissociatives',
    elasticPath: ['ai.drugs.syntheticDissociatives.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticOpiates',
    elasticPath: ['ai.drugs.syntheticOpiates.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticPsychedelics',
    elasticPath: ['ai.drugs.syntheticPsychedelics.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticStimulants',
    elasticPath: ['ai.drugs.syntheticStimulants.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.methamphetamines',
    elasticPath: ['ai.drugs.methamphetamines.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.tags',
    filterName: 'DrugContentTagsFilter',
    elasticPath: ['ai.drugs.tags.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.usper.potential',
    elasticPath: ['ai.usper.potential'],
  },
  {
    path: 'ai.usper.explanation',
    elasticPath: ['ai.usper.explanation.keyword',]
  },
  { path: 'averageRating' },
  { path: 'amountOnEscrow' },
  {
    path: 'banned ',
  },
  {
    path: 'bitcoinAddresses',
    filterName: 'BitcoinAddressesFilter',
    elasticPath: ['bitcoinAddresses.keyword'],
    filterValueType: 'array',
  },
  { path: 'bitcoinPubkey' },
  { path: 'bitcoinSignature' },
  { path: 'comment' },
  { path: 'contact' },
  { path: 'condition' },
  { path: 'coupons' },
  { path: 'currencies' },
  { path: 'customerService' },
  { path: 'deliverySpeed' },
  { path: 'description' },
  { path: 'doc.contentHash' },
  {
    path: 'doc.idHash',
    filterName: 'DocIdFilter',
    elasticPath: ['doc.idHash.keyword'],
    filterValueType: 'array',
  },
  { path: 'doc.priorVersions' },
  { path: 'doc.processedTime' },
  { path: 'doc.scraped' },
  {
    path: 'doc.type',
    sortKey: 5,
    filterName: 'DocFilter',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'emailAddresses',
    filterName: 'EmailAddressesFilter',
    elasticPath: ['emailAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ethereumAddress',
    elasticPath: ['ethereumAddress.keyword'],
    filterValueType: 'array',
  },
  { path: 'followerCount' },
  { path: 'followingCount' },
  { path: 'grams' },
  { path: 'handle' },
  { path: 'imgAvatarHash' },
  { path: 'imgHeaderHash' },
  { path: 'imgsIPFSHash' },
  { path: 'karmaPoints' },
  { path: 'lastVisit' },
  { path: 'ipAddresses' },
  { path: 'ipfsHash' },
  { path: 'itemsSold' },
  { path: 'joined' },
  { path: 'itemPopularity' },
  { path: 'lastModified' },
  { path: 'listingCount' },
  {
    path: 'litecoinAddress',
    elasticPath: ['litecoinAddress.keyword'],
    filterValueType: 'array',
  },
  { path: 'moderator' },
  { path: 'moderators' },
  {
    path: 'moneroAddresses',
    filterName: 'MoneroAddressesFilter',
    elasticPath: ['moneroAddresses.keyword'],
    filterValueType: 'array',
  },
  { path: 'negativeReviews' },
  { path: 'neutralReviews' },
  { path: 'nsfw' },
  { path: 'ogWeight' },
  { path: 'originalCurrency' },
  { path: 'originalWeight' },
  { path: 'otherMarkets.items_sold' },
  { path: 'otherMarkets.market_name' },
  { path: 'otherMarkets.neg_points' },
  { path: 'otherMarkets.pos_points' },
  { path: 'otherMarkets.score_percent' },
  { path: 'itemsSold' },
  { path: 'originalPrice' },
  { path: 'overallRating' },
  {
    path: 'page',
    filterName: 'PageFilter',
    elasticPath: ['page.keyword'],
    filterValueType: 'array',
  },
  { path: 'paymentMethods' },
  { path: 'pgpKeys' },
  { path: 'pgp.armoredKey' },
  { path: 'pgp.created' },
  {
    path: 'pgp.fingerprints',
    filterName: 'PgpFingerPrintsFilter',
    elasticPath: ['pgp.fingerprints.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.identities.comments',
    elasticPath: ['pgp.identities.comments.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.identities.emails',
    elasticPath: ['pgp.identities.emails.keyword'],
    filterValueType: 'array',
  },
  { path: 'pgp.identities.is_primary' },
  {
    path: 'pgp.identities.names',
    elasticPath: ['pgp.identities.names.keyword'],
    filterValueType: 'array',
  },
  { path: 'pgp.identities.signers' },
  {
    path: 'phoneNumbers',
    elasticPath: ['phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'postal.uspsExpress',
    elasticPath: ['postal.uspsExpress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'postal.uspsPriority',
    elasticPath: ['postal.uspsPriority.keyword'],
    filterValueType: 'array',
  },
  { path: 'positiveRating' },
  { path: 'positiveReviews' },
  {
    path: 'purchaseAmount',
  },
  { path: 'phone', filterName: 'PhoneFilter' },
  { path: 'postCount' },
  { path: 'postLinks' },
  {
    path: 'productDescription',
    sortKey: 3,
    elasticPath: ['productDescription.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'productId',
    filterName: 'ProductIDFilter',
    elasticPath: ['productId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'productName',
    sortKey: 2,
    filterName: 'ProductNameFilter',
    elasticPath: ['productName.keyword'],
    filterValueType: 'array',
  },
  { path: 'products' },
  { path: 'productSignature' },
  { path: 'productSlug' },
  { path: 'productTags' },
  {
    path: 'productType',
    filterName: 'ProductTypeFilter',
    elasticPath: ['productType.keyword'],
    filterValueType: 'array',
  },
  { path: 'productVariant' },
  { path: 'quality' },
  { path: 'quantity' },
  { path: 'quantityRemaining' },
  {
    path: 'rating',
    filterName: 'RatingFilter',
    elasticPath: ['rating.keyword'],
    filterValueType: 'array',
  },
  { path: 'ratingCount' },
  { path: 'ratingSignature' },
  { path: 'refundPolicy' },
  { path: 'replyTo' },
  {
    path: 'review',
    filterName: 'ReviewFilter',
    elasticPath: ['review.keyword'],
    filterValueType: 'array',
  },
  { path: 'reviewId' },
  {
    path: 'selectors.creditCards',
    elasticPath: ['selectors.creditCards.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.identity',
    elasticPath: ['selectors.identity.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.phoneNumbers',
    elasticPath: ['selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'shipFrom',
    filterName: 'ShipFromFilter',
    elasticPath: ['shipFrom.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'shipTo',
    filterName: 'ShipToFilter',
    elasticPath: ['shipTo.keyword'],
    filterValueType: 'array',
  },
  { path: '_id' },
  {
    path: 'shipping',
    elasticPath: ['shipping.keyword'],
    filterValueType: 'array',
  },

  { path: 'shippingMethods' },
  { path: 'shippingOptions' },
  { path: 'shippingRegions' },
  { path: 'shipToshortDescription' },
  { path: 'site.id' },
  {
    path: 'site.name',
    sortKey: 1,
    filterName: 'SiteNameFilter',
    elasticPath: ['site.name.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'site.net',
    filterName: 'SiteNetFilter',
    elasticPath: ['site.net.keyword'],
    filterValueType: 'array',
  },
  { path: 'site.type' },
  { path: 'socialMedia', sortKey: 12 },
  { path: 'termsAndConditions' },
  {
    path: 'transactionType',
    elasticPath: ['transactionType.keyword'],
  },
  { path: 'timestamp' },
  { path: 'transactionAmount' },
  { path: 'transportAddresses' },
  { path: 'trustedVendor' },
  { path: 'usdPrice' },
  {
    path: 'user',
    filterName: 'UserFilter',
    elasticPath: ['user.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'userType',
    filterName: 'UserTypeFilter',
    elasticPath: ['userType.keyword'],
    filterValueType: 'array',
  },
  { path: 'vendor' },
  { path: 'vendorBitcoinAddress' },
  { path: 'vendorCountry' },
  {
    path: 'vendorId',
    sortKey: 9,
    filterName: 'VendorIdFilter',
    elasticPath: ['vendorId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'vendorInfo',
    sortKey: 7,
    filterName: 'VendorInfoFilter',
    elasticPath: ['vendorInfo.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'vendorLevel',
    elasticPath: ['vendorLevel.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'vendorLocation',
    elasticPath: ['vendorLocation.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'vendorTags',
    elasticPath: ['vendorTags.keyword'],
    filterValueType: 'array',
  },
  { path: 'vendorXp' },
  {
    path: 'vendorName',
    sortKey: 6,
    filterName: 'VendorNameFilter',
    elasticPath: ['vendorName.keyword'],
    filterValueType: 'array',
  },
  { path: 'vendorPgp' },
  { path: 'vendorRating', sortKey: 8 },
  { path: 'vendorSales' },
  { path: 'vendorSignature' },
  { path: 'verifiedVendor' },
  { path: 'website', sortKey: 13 },
];

export { terms };
