import React, { useContext } from 'react';
import styled from 'styled-components';
import { StateContext } from '../StateProviderContext/StateProviderContext.js';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from '../../Button/Button.js';

const FilterButton = styled(Button)`
  max-height: fit-content;
  min-height: fit-content;
  height: fit-content;
  padding: 4px 8px;
  margin: 1.5px;
  word-break: break-word;
  background-color: ${(props) => props.color || props.theme.colors.primary};
  border-radius: 0px;
  &:hover {
    background-color: ${(props) =>
      props.hoverColor || props.theme.colors.danger};
    text-decoration: line-through;
  }
`;

const SelectedFilterParagraph = styled.div`
  color: #2a8dfe;
  display: flex;
  align-items: center;
  font-family: 'Red Hat Text', bold !important;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  word-break: break-all;
  padding: 2px 16px !important;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const NotFilterButton = styled(FilterButton)`
  height: fit-content;
  &:hover {
    background-color: ${(props) => props.theme.colors.danger};
    text-decoration: line-through;
  }
`;

const dropFilterValue = ({ filterName, filterState, setFilterState }) => {
  const { [filterName]: s, ...newFilterState } = filterState;
  setFilterState(newFilterState);
};

const dropArrayFilterValue = ({
  filterName,
  filterState,
  setFilterState,
  value,
  selectedValue,
}) => {
  const newValue = value.filter((v) => v !== selectedValue);
  if (newValue.length > 0) {
    setFilterState({
      ...filterState,
      ...{
        [filterName]: { ...filterState[filterName], ...{ value: newValue } },
      },
    });
  } else {
    const { [filterName]: s, ...droppedFilterState } = filterState;
    setFilterState(droppedFilterState);
  }
};

const dropGeoIdValue = ({
  filterName,
  filterState,
  setFilterState,
  value,
  selectedValue,
}) => {
  const newValue = value.filter((val) => val.id !== selectedValue);
  if (newValue.length > 0) {
    setFilterState({
      ...filterState,
      ...{
        [filterName]: { ...filterState[filterName], ...{ value: newValue } },
      },
    });
  } else {
    const { [filterName]: s, ...droppedFilterState } = filterState;
    setFilterState(droppedFilterState);
  }
};

const dropGeoPointFilterValue = ({
  filterName,
  filterState,
  setFilterState,
  value,
  selectedValue,
}) => {
  const newValue = [
    Object.values(value).map(({ lat, lon }) => [lat, lon]),
  ].filter((v) => {
    const valString = Object.values(v).join(', ');
    return valString !== selectedValue;
  });
  if (newValue.length > 0) {
    setFilterState({
      ...filterState,
      ...{
        [filterName]: { ...filterState[filterName], ...{ value: newValue } },
      },
    });
  } else {
    const { [filterName]: s, ...droppedFilterState } = filterState;
    setFilterState(droppedFilterState);
  }
};

const dropNotFilterValue = ({
  filterName,
  filterState,
  setFilterState,
  value,
  selectedValue,
}) => {
  const newValue = value.filter((v) => v.value !== selectedValue.value);
  if (newValue.length > 0) {
    setFilterState({
      ...filterState,
      ...{
        [filterName]: { ...filterState[filterName], ...{ value: newValue } },
      },
    });
  } else {
    const { [filterName]: s, ...droppedFilterState } = filterState;
    setFilterState(droppedFilterState);
  }
};

const dropDatasetFilterValue = ({
  elasticPath,
  value,
  filterState,
  setFilterState,
  filterName,
}) => {
  const cleanFilterArray =
    filterState[filterName]?.value?.filter((val) => val !== value) || [];

  if (cleanFilterArray.length > 0) {
    setFilterState({
      ...filterState,
      ...{
        DatasetFilter: {
          ...filterState.DatasetFilter,
          value: cleanFilterArray,
        },
      },
    });
  } else {
    const { ['DatasetFilter']: s, ...droppedFilterState } = filterState;
    setFilterState(droppedFilterState);
  }
};

const NotValue = ({ filterName, value = [], filterState, setFilterState }) => {
  return (
    <>
      {value.map((v) => {
        return (
          <SelectedFilterParagraph
            key={v.elasticPath[0] - v.value}
            onClick={() =>
              dropNotFilterValue({
                filterName,
                filterState,
                setFilterState,
                value,
                selectedValue: v,
              })
            }
            selector
            customChild
            className="fit-content"
            key={filterName + v}
          >
            {filterName}:{v.elasticPath[0]}-{v.value}
            <HighlightOffIcon
              className="filter-header-icons"
              fontSize="small"
            />
          </SelectedFilterParagraph>
        );
      })}
    </>
  );
};

const DatasetValue = (props) => {
  const { filterState, setFilterState, filterName } = props;

  return (
    <>
      {Object.entries(filterState.DatasetFilter.value).map(
        ([elasticPath, value]) => {
          return Array.isArray(value) ? (
            value.map((v) => {
              return (
                <SelectedFilterParagraph
                  key={elasticPath + v}
                  onClick={() =>
                    dropDatasetFilterValue({
                      elasticPath,
                      value: v,
                      filterState,
                      setFilterState,
                      filterName,
                    })
                  }
                  selector
                  customChild
                  className="fit-content"
                >
                  {filterName}: {v}
                  <HighlightOffIcon
                    className="filter-header-icons"
                    fontSize="small"
                  />
                </SelectedFilterParagraph>
              );
            })
          ) : (
            <SelectedFilterParagraph
              key={elasticPath + value}
              onClick={() =>
                dropDatasetFilterValue({
                  elasticPath,
                  value,
                  filterState,
                  setFilterState,
                  filterName,
                })
              }
              selector
              customChild
              className="fit-content"
            >
              {filterName}: {value}
              <HighlightOffIcon
                className="filter-header-icons"
                fontSize="small"
              />
            </SelectedFilterParagraph>
          );
        },
      )}
    </>
  );
};

const StringValue = ({ filterName, value, filterState, setFilterState }) => {
  return (
    <SelectedFilterParagraph
      selector
      customChild
      className="fit-content"
      onClick={() =>
        dropFilterValue({ filterName, setFilterState, filterState })
      }
    >
      {filterName}: {value}
      <HighlightOffIcon className="filter-header-icons" fontSize="small" />
    </SelectedFilterParagraph>
  );
};

const ArrayValue = ({
  filterName,
  value,
  filter,
  filterState,
  setFilterState,
}) => {
  return (
    <>
      {value.map((f) => {
        return (
          <SelectedFilterParagraph
            key={f}
            selector
            customChild
            className="fit-content"
            onClick={() =>
              dropArrayFilterValue({
                filterName,
                value,
                filterState,
                setFilterState,
                selectedValue: f,
              })
            }
          >
            {filterName}: {f}
            <HighlightOffIcon
              className="filter-header-icons"
              fontSize="small"
            />
          </SelectedFilterParagraph>
        );
      })}
    </>
  );
};

const GeoValue = ({
  filterName,
  filterState,
  setFilterState,
  value,
  coordinates,
  dropFilterFunc,
  ...rest
}) => {
  const cleanValues = value.filter((val) => !val.boundsQuery);

  return cleanValues.map(({ geoJSON, id }) => {
    const coordString = JSON.stringify(geoJSON);
    return (
      <SelectedFilterParagraph
        key={id}
        selector
        customChild
        className="fit-content"
        onClick={() =>
          dropFilterFunc({
            filterName,
            filterState,
            setFilterState,
            value,
            selectedValue: id,
          })
        }
      >
        {filterName}: {coordString}
        <HighlightOffIcon className="filter-header-icons" fontSize="small" />
      </SelectedFilterParagraph>
    );
  });
};

const TimeValue = ({
  filterName,
  value,
  filter,
  filterState,
  setFilterState,
}) => {
  const { startValue, endValue } = value;
  return (
    <SelectedFilterParagraph
      selector
      customChild
      className="fit-content"
      onClick={() =>
        dropFilterValue({
          filterName,
          value,
          filterState,
          setFilterState,
        })
      }
    >
      {filterName}: {startValue} - {endValue}
      <HighlightOffIcon className="filter-header-icons" fontSize="small" />
    </SelectedFilterParagraph>
  );
};

const FilterComponent = (props) => {
  const { filterName, value, ...rest } = props;
  if (filterName === 'NotFilter') {
    return <NotValue {...props} />;
  } else if (
    ['DateTimeFilter', 'MapsDateTimeFilter', 'DocExtractedFilter'].includes(
      filterName,
    )
  ) {
    return <TimeValue {...props} />;
  } else if (typeof value === 'string') {
    return <StringValue {...props} />;
  } else if (filterName === 'DatasetFilter') {
    return <DatasetValue {...props} />;
  } else if (filterName === 'GeoFilter') {
    const adTechPresent =
      value.filter((val) => {
        return Object.keys(val).indexOf('adTech') > -1;
      }).length > 0;
    if (Array.isArray(value) && !adTechPresent) {
      const coordinates = value.map((val) => {
        const { latLong, ...droppedContent } = val;
        const coordArr = latLong
          .flat()
          .map((coordObj) => Object.values(coordObj));
        return coordArr;
      });
      return (
        <GeoValue
          filterName={filterName}
          coordinates={coordinates}
          value={value}
          dropFilterFunc={dropGeoIdValue}
          {...rest}
        />
      );
    } else {
      return null;
    }
  } else if (filterName === 'GeoCoordinatesFilter') {
    const { value, ...restProps } = props;
    const coordinates = [
      Object.values(value).map(({ lat, lon }) => [lat, lon]),
    ];
    return (
      <GeoValue
        coordinates={coordinates}
        dropFilterFunc={dropGeoPointFilterValue}
        value={value}
        {...restProps}
      />
    );
  } else {
    return <ArrayValue {...props} />;
  }
};

const ActiveFilters = () => {
  const { filterState, setFilterState } = useContext(StateContext);
  return (
    <div>
      {Object.entries(filterState).map(([filterName, props]) => {
        return (
          <FilterComponent
            key={filterName}
            filterState={filterState}
            setFilterState={setFilterState}
            filterName={filterName}
            {...props}
          />
        );
      })}
    </div>
  );
};

export default ActiveFilters;
