import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { filterToQueryMapping } from '../utils';

import { StateContext } from '../StateProviderContext/StateProviderContext';
import { useQuery } from 'urql';
import { getSearchFields } from 'filters/UniversalFilters';

const specialFilters = [
  'utm_content',
  'utm_medium',
  'utm_campaign',
  'utm_source',
];

const handleURLParams = (
  filterState,
  history,
  currentIndex,
  zoom,
  centerCoords,
  tileIndex,
) => {
  const regularFilterParams = Object.entries(filterState)
    .filter(([filterName]) => !specialFilters.includes(filterName))
    .map(([filterName, { value }]) => {
      return `${filterName}=${encodeURIComponent(
        JSON.stringify({
          value,
        }),
      )}`;
    });
  const searchOptions = [
    `dataset=${encodeURIComponent(currentIndex)}`,
    `zoom=${encodeURIComponent(zoom)}`,
    `center=${encodeURIComponent(JSON.stringify(centerCoords))}`,
    `tiles=${encodeURIComponent(tileIndex)}`,
  ];
  const currentFilterParams = [...regularFilterParams, ...searchOptions].join(
    '&',
  );
  history.push('?' + currentFilterParams);
};

const VisualizationStateProvider = ({
  index = [],
  overrideURL,
  apiKey,
  path,
  query,
  currentIndex,
  setCurrentIndex,
  mapOptions,
  tileLayers,
  dataLayers,
  ...rest
}) => {
  const [filterState, setFilterState] = useState({});
  const [hits, setHits] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(0);
  const [sort, setSort] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [timeTaken, setTimeTaken] = useState(0);
  const [relation, setRelation] = useState('');
  const [secondarySorts, setSecondarySorts] = useState(true);
  const [pauseQuery, setPauseQuery] = useState(false);
  const [centerCoords, setCenterCoords] = useState({ lat: 15, lng: 12 });
  const [currentTileName, setCurrentTileName] = useState(tileLayers[0].name);
  const [bounds, setBounds] = useState([]);
  const [timeRange, setTimeRange] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [indices, setIndices] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const globalBounds = [
    [[-180, -90], [180, -90], [180, 90], [-180, 90], [-180, -90]],
  ];

  const BoundsQuery = `
    query ($bounds: [[[Float]]], $index: [String]!, $precision: Int, $startTime: String, $endTime: String, $fields: [String], $term: String) {
      mapping (bounds: $bounds, index: $index, precision: $precision, startTime: $startTime, endTime: $endTime, fields: $fields, term: $term) {
        path
        index
		    data {
			    lat
			    lon
		    }
      }
    }
  `;

  const [result, runQuery] = useQuery({
    query: BoundsQuery,
    variables: {
      bounds,
      index: indices,
      precision: 8,
      startTime: timeRange.startValue,
      endTime: timeRange.endValue,
      fields: getSearchFields(index),
      term: searchTerm || '',
    },
    pause: pauseQuery,
  });

  const { error, fetching, data } = result;

  // read filterState and re-run the query
  useEffect(() => {
    if (filterState.GeoFilter?.value) {
      const shapes = filterState.GeoFilter?.value
        .filter((v) => v.boundsQuery === false)
        .map((shape) => shape.geoJSON)
        .flat();
      if (shapes.length > 0) {
        setBounds(shapes);
      } else {
        setBounds(globalBounds);
      }
      setPauseQuery(false);
    } else {
      setBounds(globalBounds);
      setPauseQuery(false);
    }

    if (filterState.DateTimeFilter) {
      setTimeRange(filterState.DateTimeFilter.value);
    }

    if (filterState.MapSearchbar?.value) {
      setSearchTerm(filterState.MapSearchbar?.value);
    } else {
      setSearchTerm('');
    }
  }, [filterState]);

  useEffect(() => {
    if (filterState.DatasetFilter?.value) {
      const filterIndices = dataLayers
        .filter((layer) => {
          return filterState.DatasetFilter?.value.find((el) => {
            return el === layer.displayText;
          });
        })
        .map(({ index }) => index)
        .flat();
      setIndices(filterIndices);
    } else {
      setIndices([]);
      setHits([]);
    }
  }, [index, filterState]);

  useEffect(() => {
    if (!indices.length > 0) {
      setPauseQuery(true);
    } else {
      setPauseQuery(false);
    }
  }, [indices.length]);

  useEffect(() => {
    if (fetching) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (error) {
      setSearchError(error);
    }
  }, [error, fetching]);

  useEffect(() => {
    if (data?.mapping?.length > 0) {
      setHits(data.mapping);
      setPauseQuery(true);
    }
  }, [data?.mapping]);

  const providerValue = React.useMemo(
    () => ({
      filterState,
      setFilterState,
      hits,
      loading,
      page,
      setPage,
      totalHits,
      size,
      setSize,
      sort,
      setSort,
      apiKey,
      searchError,
      timeTaken,
      relation,
      secondarySorts,
      setSecondarySorts,
      zoom,
      setZoom,
      centerCoords,
      setCenterCoords,
      currentTileName,
      setCurrentTileName,
      tileLayers,
    }),
    [
      filterState,
      hits,
      page,
      totalHits,
      size,
      sort,
      loading,
      apiKey,
      searchError,
      timeTaken,
      relation,
      secondarySorts,
      zoom,
      centerCoords,
      currentTileName,
      tileLayers,
    ],
  );

  useEffect(() => {
    const tileIndex = tileLayers
      .map((layer) => layer.name)
      .indexOf(currentTileName);
    handleURLParams(
      filterState,
      history,
      currentIndex,
      zoom,
      centerCoords,
      tileIndex,
    );
  }, [
    filterState,
    currentIndex,
    zoom,
    centerCoords.lat,
    centerCoords.lng,
    currentTileName,
  ]);

  useEffect(() => {
    const paramString = decodeURIComponent(location.search).replace(
      /\+/g,
      '%2B',
    );
    const searchParams = new URLSearchParams(paramString);

    if (
      Array.from(searchParams).length &&
      Array.from(searchParams).length > 3
    ) {
      setLoading(true);
    }

    let defaultState = {};

    for (const [key, value] of searchParams) {
      const parsedValue = JSON.parse(value);
      if (key === 'dataset') {
        setCurrentIndex(Number(value));
      } else if (key === 'zoom') {
        setZoom(Number(value));
      } else if (key === 'center') {
        setCenterCoords(parsedValue);
      } else if (key === 'tiles') {
        setCurrentTileName(tileLayers[Number(value)].name);
      } else if (filterToQueryMapping[key]) {
        const { query } = filterToQueryMapping[key];
        const elasticPath = filterToQueryMapping[key].elasticPath;

        const mappedParsedValue = {
          ...parsedValue,
          ...{
            query,
            elasticPath,
          },
        };
        defaultState = { ...defaultState, ...{ [key]: mappedParsedValue } };
      }
    }

    defaultState = {
      ...defaultState,
      DatasetFilter: {
        value: dataLayers.map((layer) => layer.displayText) || [],
        query: () => null,
        elasticPath: [],
      },
    };

    setFilterState(defaultState);
  }, []);

  return (
    <StateContext.Provider value={providerValue}>
      {rest.children({
        filterState,
        hits,
        searchError,
        setFilterState,
        setSort,
        loading,
        page,
        setPage,
        size,
        totalHits,
        zoom,
        setZoom,
        centerCoords,
        setCenterCoords,
        currentTileName,
        setCurrentTileName,
        tileLayers,
      })}
    </StateContext.Provider>
  );
};
export default VisualizationStateProvider;
