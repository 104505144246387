import { getSearchFields } from '../../filters/UniversalFilters';

const simpleQueryStringQuery = (body, params) => {
  const { value, path, defaultOperator = 'and' } = params;
  const searchParams = {
    query: value,
    default_operator: defaultOperator,
    fields: path,
  };
  if (params.value) {
    body.query('simple_query_string', searchParams);
  }
};

const searchbarSearchQuery = (
  body,
  { value: query, default_operator = 'and', elasticPath },
) => {
  const searchParams = {
    query,
    default_operator,
    fields: elasticPath,
  };
  if (query) {
    body.query('simple_query_string', searchParams); // body is an object of bodybuilder
  }
};

const termsQuery = (body, { elasticPath, value }) => {
  if (Array.isArray(value)) {
    body.query('terms', elasticPath, value);
  } else {
    body.query('term', elasticPath, value);
  }
};

const manyToManyTermsQuery = (body, { elasticPath, value }) => {
  value.forEach((v) => {
    elasticPath.forEach((ep) => {
      body.orFilter('term', ep, v);
    });
  });

  body.filterMinimumShouldMatch(1);
};

const geoPointQuery = (body, { elasticPath, value }) => {
  const buildGeoPointQuery = (body, path, value) => {
    body.orQuery('exists', path).filter('geo_distance', {
      distance: '5km',
      [path]: {
        ...value,
      },
    });
  };

  if (Array.isArray(value) && value.length > 0) {
    value.forEach((v) => {
      if (Array.isArray(elasticPath) && elasticPath.length > 0) {
        elasticPath.forEach((path) => {
          buildGeoPointQuery(body, path, v);
        });
      } else {
        buildGeoPointQuery(body, elasticPath, v);
      }
    });
  } else {
    if (Array.isArray(elasticPath) && elasticPath.length > 0) {
      elasticPath.forEach((path) => {
        buildGeoPointQuery(body, path, value);
      });
    } else {
      buildGeoPointQuery(body, elasticPath, value);
    }
  }
};

const geoShapeCoordinates = (body, { elasticPath, value }) => {
  if (Array.isArray(value) && value.length > 1) {
    const mapCorners = value.map(({ lat, lon }) => [lon, lat]);
    const geoJSON = [[...mapCorners, mapCorners[0]]];

    body.orFilter('geo_shape', {
      [elasticPath]: {
        shape: {
          type: 'Polygon',
          coordinates: geoJSON,
        },
        relation: 'within',
      },
    });
  } else {
    value.forEach((v) => {
      const { lat, lon } = v;
      body.filter('geo_distance', 'distance', '50m', {
        [elasticPath]: {
          lat,
          lon,
        },
      });
    });
  }
};

const profileExcludeQuery = (body, { elasticPath, value }) => {
  body.notFilter('term', elasticPath, value);
};

/**
 * @component executes a time based query for datetime picker
 * @param  {obj} body Query body
 * @param  {obj} params Query parameter
 */
const timeQuery = (body, params) => {
  const { elasticPath, value } = params;
  const { startValue, endValue } = value;

  body.query('range', elasticPath, {
    gte: startValue,
    lte: endValue,
    format: 'yyyy-MM-dd HH:mm:ss',
  });
};

const geoQuery = (body, { elasticPath, filterPath, value }) => {
  const size = 10000;
  // build queries that need two aggregations
  // one aggregation filters FOR field, the other filters them out
  const buildDualQueries = (
    bodyBuilder,
    path = { filterPath: '', geoPath: '', action: '' },
    value = {},
    aggIndex = 0,
  ) => {
    const { action, filterPath, geoPath } = path;

    let newQuery = bodyBuilder;

    if (action === 'include') {
      newQuery.aggregation(
        'filter',
        {
          bool: {
            filter: [
              {
                geo_shape: {
                  [geoPath]: {
                    shape: {
                      type: 'Polygon',
                      coordinates: value.geoJSON,
                    },
                    relation: 'within',
                  },
                },
              },
              {
                should: [
                  ...path.filterPath.map((p) => ({
                    exists: {
                      field: p,
                    },
                  })),
                ],
              },
            ],
          },
        },
        (a) => {
          return a.aggregation(
            'geohash_grid',
            { field: geoPath, precision: value.precision, size },
            'sub_query',
          );
        },
        `${geoPath}_agg_${aggIndex}`,
      );
    } else if (action === 'exclude') {
      newQuery.aggregation(
        'filter',
        {
          bool: {
            filter: [
              {
                bool: {
                  should: [
                    ...filterPath.map((p) => ({
                      bool: {
                        must_not: {
                          exists: {
                            field: p,
                          },
                        },
                      },
                    })),
                  ],
                  minimum_should_match: 1,
                },
              },
              {
                geo_shape: {
                  [geoPath]: {
                    shape: {
                      type: 'Polygon',
                      coordinates: value.geoJSON,
                    },
                    relation: 'within',
                  },
                },
              },
            ],
          },
        },
        (a) => {
          return a.aggregation(
            'geohash_grid',
            { field: geoPath, precision: value.precision, size },
            'sub_query',
          );
        },
        `${geoPath}_agg_${aggIndex}`,
      );
    }

    return newQuery;
  };
  const buildAggQueries = (bodyBuilder, path, value, aggIndex = 0) => {
    return bodyBuilder
      .aggregation(
        'filter',
        {
          bool: {
            filter: [
              {
                geo_shape: {
                  [path]: {
                    shape: {
                      type: 'Polygon',
                      coordinates: value.geoJSON,
                    },
                    relation: 'within',
                  },
                },
              },
              {
                exists: {
                  field: path,
                },
              },
            ],
          },
        },
        (a) => {
          return a.aggregation(
            'geohash_grid',
            { field: path, precision: value.precision, size },
            'sub_query',
          );
        },
        `${path}_agg_${aggIndex}`,
      )
      .size(0)
      .build();
  };

  if (Array.isArray(value)) {
    value.forEach((v, idx) => {
      if (Array.isArray(filterPath)) {
        filterPath.forEach((filter) => {
          if (typeof filter === 'object' && !Array.isArray(filter)) {
            return buildDualQueries(body, filter, v, idx);
          }
          return buildAggQueries(body, filter, v, idx);
        });
      } else if (typeof filterPath === 'object' && !Array.isArray(filterPath)) {
        return buildDualQueries(body, filterPath, v, idx);
      } else {
        return buildAggQueries(body, filterPath, v, idx);
      }
    });
  } else {
    if (Array.isArray(filterPath)) {
      filterPath.forEach((filter) => {
        if (typeof filter === 'object' && !Array.isArray(filter)) {
          return buildDualQueries(body, filter, value);
        }
        return buildAggQueries(body, filter, value);
      });
    } else if (typeof filterPath === 'object' && !Array.isArray(filterPath)) {
      return buildDualQueries(body, filterPath, value);
    } else {
      return buildAggQueries(body, filterPath, value);
    }
  }
};

const requiredFieldsQuery = (body, { value }) => {
  if (Array.isArray(value)) {
    value.forEach((field) => {
      body.filter('exists', 'field', field);
    });
  } else {
    body.filter('exists', 'field', value);
  }
};

const orRequiredFieldsQuery = (body, { value }) => {
  if (Array.isArray(value)) {
    value.forEach((field) => {
      body.orFilter('exists', 'field', field);
    });
  } else {
    body.filter('exists', 'field', value);
  }
};

const multiDropdownAgg = (body, field) => {
  body.aggregation('terms', field);
};

const searchbarQuery = (body, params) => {
  const { value, elasticPath = [], defaultOperator = 'and' } = params;
  const searchParams = {
    query: value,
    default_operator: defaultOperator,
    fields: elasticPath,
  };

  if (params.value) {
    body.query('simple_query_string', searchParams);
  }
};

const notFilterQuery = (body, params) => {
  const { value } = params;
  value.forEach(({ value, elasticPath }) => {
    body.notFilter('term', elasticPath, value);
  });
};

const excludesFilter = (body, params) => {
  const { value, elasticPath } = params;

  body.notFilter('term', elasticPath, value);
};

const matchTrueQuery = (body, { value, elasticPath }) => {
  if (Array.isArray(value)) {
    value.forEach((v) => {
      body.andFilter('match', elasticPath, true);
    });
  } else {
    body.andFilter('match', elasticPath, true);
  }
};

const datasetTableQuery = (body, { value }) => {
  Object.entries(value).forEach(([elasticPath, v]) => {
    body.query('terms', [elasticPath], v);
  });
};

const shouldExistQuery = (body, fields) => {
  if (Array.isArray(fields)) {
    fields.forEach((field) => {
      body.andFilter('exists', 'field', field);
    });
  } else {
    body.andFilter('exists', 'field', fields);
  }
};

const bucketIncludesQuery = (body, { value, elasticPath }) => {
  elasticPath.forEach((path) => {
    body.orFilter('term', path, value);
  });
};

const bucketExcludesQuery = (body, { value, elasticPath }) => {
  elasticPath.forEach((path) => {
    body.notFilter('term', path, value);
  });
};

const filterToQueryMapping = {
  Searchbar: { query: searchbarQuery },
  IpSearchbar: {
    query: manyToManyTermsQuery,
    elasticPath: ['destination.ip', 'source.ip'],
  },
  RequiredFieldFilter: { query: requiredFieldsQuery },
  DatasetFilter: { elasticPath: ['*'], query: datasetTableQuery },
  NotFilter: { query: notFilterQuery },
  DateTimeFilter: { elasticPath: ['timestamp'], query: timeQuery },
  MapsDateTimeFilter: { elasticPath: ['@timestamp'], query: timeQuery },
  DocExtractedFilter: { elasticPath: ['doc.extracted'], query: timeQuery },
  IndexFilter: { elasticPath: ['doc.index'], query: termsQuery },
  DatabaseIndexFilter: {
    elasticPath: ['doc.dataset.keyword'],
    query: termsQuery,
  },
  DocFilter: { elasticPath: ['doc.type.keyword'], query: termsQuery }, // most have Path and Query
  DocIdFilter: { elasticPath: ['doc.idHash.keyword'], query: termsQuery },
  SiteNetFilter: { elasticPath: ['site.net.keyword'], query: termsQuery },
  SiteTypeFilter: {
    elasticPath: ['site.type.keyword'],
    query: termsQuery,
  },
  SiteNameFilter: {
    elasticPath: ['site.name.keyword'],
    query: termsQuery,
  },
  BoardFilter: { elasticPath: ['board.keyword'], query: termsQuery },
  ContentTagsFilter: {
    elasticPath: ['ai.content.tags.keyword'],
    query: termsQuery,
  },
  DrugContentTagsFilter: {
    elasticPath: ['ai.drugs.tags.keyword'],
    query: termsQuery,
  },
  TagsFilter: {
    elasticPath: ['tags.keyword'],
    query: termsQuery,
  },
  PostOriginFilter: {
    elasticPath: ['countryName.keyword'],
    query: termsQuery,
    highlight: true,
  },
  LanguageFilter: {
    elasticPath: ['ai.language.keyword'],
    query: termsQuery,
  },
  RoleFilter: { elasticPath: ['role.keyword'], query: termsQuery },
  LocationFilter: {
    elasticPath: ['ai.location.keyword'],
    query: termsQuery,
    highlight: true,
  },
  GeoCoordinatesFilter: {
    elasticPath: ['ai.geo.coordinates'],
    query: geoShapeCoordinates,
    highlight: true,
  },
  TitleFilter: {
    elasticPath: ['title.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ProductNameFilter: {
    elasticPath: ['productName.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ProductIDFilter: {
    elasticPath: ['productId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  VendorNameFilter: {
    elasticPath: ['vendorName.keyword'],
    query: termsQuery,
    highlight: true,
  },
  VendorIdFilter: {
    elasticPath: ['vendorId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  AuthorFilter: {
    elasticPath: ['author.keyword'],
    query: termsQuery,
    highlight: true,
  },
  PageFilter: {
    elasticPath: ['page.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ThreadIDFilter: {
    elasticPath: ['threadId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  PostIDFilter: {
    elasticPath: ['postId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  FileNameFilter: {
    elasticPath: ['fileName.keyword'],
    query: termsQuery,
    highlight: true,
  },
  FileTypeFilter: {
    elasticPath: ['fileType.keyword'],
    query: termsQuery,
    highlight: true,
  },
  DocSourcePageFilter: {
    elasticPath: ['sourcePage.keyword'],
    query: termsQuery,
    highlight: true,
  },
  LinksFilter: {
    elasticPath: ['links.keyword'],
    query: termsQuery,
    highlight: true,
  },
  IpAddressesFilter: {
    elasticPath: ['ipAddresses.keyword'],
    query: termsQuery,
    highlight: true,
  },
  PhoneNumbersFilter: {
    elasticPath: ['phoneNumbers.keyword'],
    query: termsQuery,
    highlight: true,
  },
  HiddenServiceFilter: {
    elasticPath: ['hiddenService.keyword'],
    query: termsQuery,
    highlight: true,
  },
  UserIdFilter: { elasticPath: ['userId.keyword'], query: termsQuery },
  UsperFilter: { elasticPath: ['ai.usper.potential'], query: matchTrueQuery },
  EthereumAddressesFilter: {
    elasticPath: ['ethereumAddress.keyword'],
    query: termsQuery,
    highlight: true,
  },
  MoneroAddressesFilter: {
    elasticPath: ['moneroAddress.keyword'],
    query: termsQuery,
    highlight: true,
  },
  LitecoinAddressesFilter: {
    elasticPath: ['litecoinAddress.keyword'],
    query: termsQuery,
    highlight: true,
  },
  UserFilter: {
    elasticPath: ['user.keyword'],
    query: termsQuery,
    highlight: true,
  },
  UserTypeFilter: { elasticPath: ['userType.keyword'], query: termsQuery },
  ProfileDrugContentFilter: {
    elasticPath: ['ai.content.drugs.keyword'],
    query: termsQuery,
  },
  PgpFingerPrintsFilter: {
    elasticPath: ['pgp.fingerprints.keyword'],
    query: termsQuery,
    highlight: true,
  },
  PgpEmailsFilter: {
    elasticPath: ['pgp.identities.emails.keyword'],
    query: termsQuery,
    highlight: true,
  },
  BitcoinAddressesFilter: {
    elasticPath: ['bitcoinAddresses.keyword'],
    query: termsQuery,
    highlight: true,
  },
  EmailAddressesFilter: {
    elasticPath: ['emailAddresses.keyword'],
    query: termsQuery,
    highlight: true,
  },
  AnalyticsIdFilter: {
    elasticPath: ['analyticsIDs.keyword'],
    query: termsQuery,
    highlight: true,
  },
  NameFilter: {
    elasticPath: ['name.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SelectorNameFilter: {
    elasticPath: ['selectors.names.keyword'],
    query: termsQuery,
    highlight: true,
  },
  AuthorDescriptionFilter: {
    elasticPath: ['description.keyword'],
    query: termsQuery,
    highlight: true,
  },
  RiskRankFilter: {
    elasticPath: ['ai.stats.risk.rank.keyword'],
    query: termsQuery,
  },
  ShipToFilter: {
    elasticPath: ['shipTo.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ShipFromFilter: {
    elasticPath: ['shipFrom.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ReviewFilter: {
    elasticPath: ['review.keyword'],
    query: termsQuery,
    highlight: true,
  },
  RatingFilter: {
    elasticPath: ['rating.keyword'],
    query: termsQuery,
    highlight: true,
  },
  VendorInfoFilter: {
    elasticPath: ['vendorInfo.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ProductTypeFilter: {
    elasticPath: ['productType.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SiteChangedFilter: {
    elasticPath: ['ai.site.changed.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ResponseStatusFilter: {
    elasticPath: ['responseStatus'],
    query: termsQuery,
  },
  PageTitleFilter: {
    elasticPath: ['pageTitle.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ServerFilter: {
    elasticPath: ['serverVersion.keyword'],
    query: termsQuery,
    highlight: true,
  },
  PortsFilter: {
    elasticPath: ['openPorts.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ActiveServicesFilter: {
    elasticPath: ['activeServices.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SSHFingerprintFilter: {
    elasticPath: ['crypto.ssh.fingerprint.keyword', 'ssh.fingerprint.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SSHTypeFilter: {
    elasticPath: ['crypto.ssh.type.keyword', 'ssh.type.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SSHVersionFilter: {
    elasticPath: ['crypto.ssh.version.keyword', 'ssh.version.keyword'],
    query: termsQuery,
    highlight: true,
  },
  TLSSubjectIssuerFilter: {
    elasticPath: ['crypto.tls.issuer.keyword', 'crypto.tls.subject.keyword'],
    query: termsQuery,
    highlight: true,
  },
  TLSVersionFilter: {
    elasticPath: ['crypto.tls.version.keyword'],
    query: termsQuery,
    highlight: true,
  },
  TLSSignatureFilter: {
    elasticPath: ['crypto.tls.signature.keyword'],
    query: termsQuery,
    highlight: true,
  },
  TLSFingerprintFilter: {
    elasticPath: ['crypto.tls.fingerprint.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ApacheServerhttpdConfFilter: {
    elasticPath: ['apacheServerInfo.httpdConf.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ApacheServerhttpdInfoConfFilter: {
    elasticPath: ['apacheServerInfo.httpdInfoConf.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ApacheServerVHostsConfFilter: {
    elasticPath: ['apacheServerInfo.httpdVHostsConf.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ApacheServerInfoFilter: {
    elasticPath: ['apacheServerInfo.server.keyword'],
    query: termsQuery,
    highlight: true,
  },
  JSDetectedFilter: {
    elasticPath: ['jsDetected'],
    query: matchTrueQuery,
  },
  BitcoinToggleFilter: {
    elasticPath: ['bitcoinAddresses.keyword'],
    query: requiredFieldsQuery,
  },
  CryptoToggleFilter: {
    elasticPath: [
      'bitcoinAddresses.keyword',
      'moneroAddresses.keyword',
      'litecoinAddresses.keyword',
      'ethereumAddresses.keyword',
    ],
    query: orRequiredFieldsQuery,
  },
  SelectorsToggleFilter: {
    elasticPath: [
      'selectors',
      'bitcoinAddresses.keyword',
      'moneroAddresses.keyword',
      'litecoinAddresses.keyword',
      'ethereumAddresses.keyword',
    ],
    query: orRequiredFieldsQuery,
  },
  FirstSnapshotFilter: {
    elasticPath: ['ai.site.firstSnapshot'],
    query: matchTrueQuery,
  },
  PrivateKeyFilter: {
    elasticPath: ['privateKeyDetected'],
    query: matchTrueQuery,
  },
  ApacheStatusFilter: {
    elasticPath: ['foundApacheModStatus'],
    query: matchTrueQuery,
  },
  ClassifiedFilter: {
    elasticPath: ['classified'],
    query: matchTrueQuery,
  },
  FirstScanFilter: {
    elasticPath: ['site.net.keyword'],
    query: termsQuery,
  },
  CountryFilter: {
    elasticPath: ['country.keyword'],
    query: termsQuery,
    highlight: true,
  },
  CurrencyFilter: {
    elasticPath: ['currency.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ExchangeCityFilter: {
    elasticPath: ['exchangeCity.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ExchangeCountryFilter: {
    elasticPath: ['exchangeCountry.keyword'],
    query: termsQuery,
    highlight: true,
  },
  LocationIdFilter: {
    elasticPath: ['locationId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  LocationTextFilter: {
    elasticPath: ['locationText.keyword'],
    query: termsQuery,
    highlight: true,
  },
  MessageFilter: {
    elasticPath: ['message.keyword'],
    query: termsQuery,
    highlight: true,
  },
  CityFilter: {
    elasticPath: ['city.keyword'],
    query: termsQuery,
    highlight: true,
  },
  TradeTypeFilter: {
    elasticPath: ['tradeType.keyword'],
    query: termsQuery,
    highlight: true,
  },
  RequirementsFilter: {
    elasticPath: ['requirements.keyword'],
    query: termsQuery,
    highlight: true,
  },
  UserNameFilter: {
    elasticPath: ['username.keyword'],
    query: termsQuery,
    highlight: true,
  },
  CurrencyFilter: {
    elasticPath: ['currency.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SelectorEmailAddressesFilter: {
    elasticPath: ['selectors.emailAddresses.keyword'],
    query: termsQuery,
    highlight: true,
  },
  AccountInfoFilter: {
    elasticPath: ['accountInfo.keyword'],
    query: termsQuery,
    highlight: true,
  },
  OnlineProviderFilter: {
    elasticPath: ['onlineProvider.keyword'],
    query: termsQuery,
    highlight: true,
  },
  CountryCodeFilter: {
    elasticPath: ['countryCode.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ExchangeMessagesFilter: {
    elasticPath: ['exchangeMessages.keyword'],
    query: termsQuery,
    highlight: true,
  },
  FacebookDataRawFilter: {
    elasticPath: ['facebookDataRaw.keyword'],
    query: termsQuery,
    highlight: true,
  },
  IntroductionFilter: {
    elasticPath: ['introduction.keyword'],
    query: termsQuery,
    highlight: true,
  },
  DobFilterName: {
    elasticPath: ['selectors.dob.keyword'],
    query: termsQuery,
    highlight: true,
  },
  IpAddressFilter: {
    elasticPath: ['selectors.ipAddresses.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SelectorsLanguageFilter: {
    elasticPath: ['selectors.languages.keyword'],
    query: termsQuery,
    highlight: true,
  },
  LocationsFilter: {
    elasticPath: ['selectors.locations.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SelectorsNamesFilter: {
    elasticPath: ['selectors.names.keyword'],
    query: termsQuery,
    highlight: true,
  },
  PageLinkFilter: {
    elasticPath: ['selectors.pageLinks.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SelectorPhoneNumberFilter: {
    elasticPath: ['selectors.phoneNumbers.keyword'],
    query: termsQuery,
    highlight: true,
  },
  PostLinksFilter: {
    elasticPath: ['selectors.postLinks.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SocialMediaFilter: {
    elasticPath: ['selectors.socialMedia.keyword'],
    query: termsQuery,
    highlight: true,
  },
  TypeFilter: {
    elasticPath: ['type.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SSNFilter: {
    elasticPath: ['selectors.ssn.keyword'],
    query: termsQuery,
    highlight: true,
  },
  TimezoneFilter: {
    elasticPath: ['selectors.timezones.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SelectorBitcoinAddressesFilter: {
    elasticPath: ['selectors.bitcoinAddresses.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SelectorUserNameFilter: {
    elasticPath: ['selectors.usernames.keyword'],
    query: termsQuery,
    highlight: true,
  },
  AdvertismentTypeFilter: {
    elasticPath: ['advertismentType.keyword'],
    query: termsQuery,
    highlight: true,
  },
  LBCLanguageFilter: {
    elasticPath: ['selectors.languages.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ZipCodeFilter: {
    elasticPath: ['zipcode.keyword'],
    query: termsQuery,
    highlight: true,
  },
  WalletIdFilter: {
    elasticPath: ['walletId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ToWalletLabelFilter: {
    elasticPath: ['toWalletLabel.keyword'],
    query: termsQuery,
    highlight: true,
  },
  FromWalletIdFilter: {
    elasticPath: ['fromWalletId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ToBitcoinAddressFilter: {
    elasticPath: ['toBitcoinAddress.keyword'],
    query: termsQuery,
    highlight: true,
  },
  FromWalletLabelFilter: {
    elasticPath: ['fromWalletLabel.keyword'],
    query: termsQuery,
    highlight: true,
  },
  sellerUsernameFilter: {
    elasticPath: ['sellerUsername.keyword'],
    query: termsQuery,
    highlight: true,
  },
  DescriptionFilter: {
    elasticPath: ['description.keyword'],
    query: termsQuery,
    highlight: true,
  },
  BuyerUsernameFilter: {
    elasticPath: ['buyerUsername.keyword'],
    query: termsQuery,
    highlight: true,
  },
  BuyerIdFilter: {
    elasticPath: ['buyerId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SellerIdFilter: {
    elasticPath: ['sellerId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ThreadIdFilter: {
    elasticPath: ['threadId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  TransactionCategoryFilter: {
    elasticPath: ['transactionCategory.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ToWalletIdFilter: {
    elasticPath: ['toWalletId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  CurrencyFilter: {
    elasticPath: ['currency.keyword'],
    query: termsQuery,
    highlight: true,
  },
  BankNameFilter: {
    elasticPath: ['bankName.keyword'],
    query: termsQuery,
    highlight: true,
  },
  TransactionIdFilter: {
    elasticPath: ['transactionId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  DisputeMessageFilter: {
    elasticPath: ['disputeMessage.keyword'],
    query: termsQuery,
    highlight: true,
  },
  RecipientIdFilter: {
    elasticPath: ['recipientId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SenderIdFilter: {
    elasticPath: ['senderId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  AdvertisementMessageFilter: {
    elasticPath: ['advertisementMessage.keyword'],
    query: termsQuery,
    highlight: true,
  },
  SenderUsernameFilter: {
    elasticPath: ['senderUsername.keyword'],
    query: termsQuery,
    highlight: true,
  },
  RecipientUsernameFilter: {
    elasticPath: ['recipientUsername.keyword'],
    query: termsQuery,
    highlight: true,
  },
  ConversationIdFilter: {
    elasticPath: ['conversationId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  MessageIdFilter: {
    elasticPath: ['message.keyword'],
    query: termsQuery,
    highlight: true,
  },
  MessageTypeFilter: {
    elasticPath: ['messageType.keyword'],
    query: termsQuery,
  },
  AdvertisementIdFilter: {
    elasticPath: ['advertisementId.keyword'],
    query: termsQuery,
    highlight: true,
  },
  UserIdsFilter: {
    elasticPath: ['userIds.keyword'],
    query: termsQuery,
  },
  GeoFilter: {
    elasticPath: ['geo_shape'],
    query: geoQuery,
  },
  SentimentValueFilter: {
    elasticPath: ['ai.sentiment.value.keyword'],
    query: termsQuery,
  },
};

const fetchData = ({ query, apiKey, index }) => {
  const wbUrl = 'https://' + process.env.REACT_APP_API_HOST;
  const controller = new AbortController();
  const signal = controller.signal;

  if (apiKey?.key && index.length > 0) {
    const promise = new Promise(async (resolve) => {
      try {
        const response = await fetch(`${wbUrl}/${index.join(',')}/_search`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `ApiKey ${apiKey.key}`,
          },
          method: 'POST',
          signal,
          body: JSON.stringify(query),
        });

        const respData = await response.json();

        resolve(respData);
      } catch (err) {
        console.log(err);
      }
    });
    promise.cancel = () => controller.abort();
    return promise;
  } else {
    return false;
  }
};

export {
  simpleQueryStringQuery,
  searchbarSearchQuery,
  termsQuery,
  profileExcludeQuery,
  requiredFieldsQuery,
  multiDropdownAgg,
  searchbarQuery,
  manyToManyTermsQuery,
  notFilterQuery,
  shouldExistQuery,
  filterToQueryMapping,
  timeQuery,
  fetchData,
  excludesFilter,
  bucketIncludesQuery,
  bucketExcludesQuery,
};
