import React, { useEffect, useRef, Children } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.cardBackground};
  box-sizing: border-box;
  overflow: hidden auto;

  .highlight {
    border-top: 1px solid ${(props) => props.theme.colors.charcoal};
    border-bottom: 1px solid ${(props) => props.theme.colors.charcoal};
  }

  .highlight + .highlight {
    border-top: none;
  }
`;

const Slider = ({ children }) => {
  const containerRef = useRef();
  const numChildren = Children.count(children);
  useEffect(() => {
    if (containerRef.current) {
      // Scroll to the top of the slider when new content is added
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [numChildren]);

  return <Container ref={containerRef}>{children}</Container>;
};

export default Slider;
