import React from 'react';
import dayjs from 'dayjs';
import Button from '../../darkblue-ui/Button/Button';
import styled from 'styled-components';

const CalendarButton = styled(Button)`
  color: ${(props) => props.theme.colors.charcoal};
`;

const InlineDateFilter = (props) => {
  const { setFilterState, filterState, setShowCalendar } = props;

  const isTimePeriod = ({ start, end }) => {
    const filterTimeState = filterState.TimeFilter;
    const filterStartTime = dayjs(filterTimeState.start);
    const filterEndTime = dayjs(filterTimeState.end);
    const buttonStartTime = dayjs(start);
    const buttonEndTime = dayjs(end);

    if (
      filterStartTime.isSame(buttonStartTime, 'day') &&
      filterEndTime.isSame(buttonEndTime, 'day')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getPeriods = (period) => {
    const today = new Date(
      dayjs()
        .hour(23)
        .minute(59),
    );
    let from = new Date(
      dayjs()
        .hour(0)
        .minute(0),
    );

    switch (period) {
      case '1D':
        break;
      case '1W':
        from.setDate(today.getDate() - 7);
        break;
      case '2W':
        from.setDate(today.getDate() - 14);
        break;
      case '1M':
        from.setDate(today.getDate() - 30);
        break;
      case '3M':
        from.setDate(today.getDate() - 90);
        break;
      case '6M':
        from.setDate(today.getDate() - 180);
        break;
      case '1Y':
        from.setDate(today.getDate() - 365);
        break;
      case 'ALL':
        from = new Date(1970, 0, 1);
        break;
      default:
        from.setDate(today.getDate() - 7);
        break;
    }
    return {
      start: dayjs(from)
        .hour(0)
        .minute(0)
        .minute(0)
        .second(0)
        .toDate(),
      end: dayjs(today)
        .hour(0)
        .minute(0)
        .minute(0)
        .second(0)
        .toDate(),
    };
  };

  const handleClick = (period) => {
    setFilterState({ ...filterState, ...{ TimeFilter: getPeriods(period) } });
  };

  const timePeriods = ['1D', '1W', '2W', '1M', '3M', '6M', '1Y', 'ALL'];
  return (
    <div>
      {timePeriods.map((period) => {
        if (isTimePeriod(getPeriods(period))) {
          return (
            <CalendarButton
              style={{ color: '#F6FBFF' }}
              primary
              datePicker
              active
              customChild
              key={period}
              onClick={() => handleClick(period)}
            >
              {period}
            </CalendarButton>
          );
        } else {
          return (
            <CalendarButton
              key={period}
              datePicker
              off
              customChild
              onClick={() => {
                setShowCalendar(false);
                handleClick(period);
              }}
            >
              {period}
            </CalendarButton>
          );
        }
      })}
    </div>
  );
};

export default InlineDateFilter;
