import React, { useContext } from 'react';
import styled from 'styled-components';
import FilterListIcon from '@material-ui/icons/FilterList';

import { StateContext } from '../../../StateProviderContext/StateProviderContext.js';

const Wrapper = styled.div.attrs(() => ({
  id: 'filter-toggle-wrapper',
}))`
  &:hover {
    cursor: ${(props) => (props.isOpen ? 'auto' : 'pointer')};
  }

  * {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  margin: 0px 10px;
  background-color: ${(props) => props.theme.colors.fog};
  color: ${(props) => props.theme.colors.almostBlack};
  border-radius: 0px;
  padding: 3px 10px;
`;

const StyledFilterListIcon = styled(FilterListIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

const SelectedSortIndexText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.altPrimary};
`;

const FilterToggle = ({ toggleSideFilter, disableSort }) => {
  const { filterState } = useContext(StateContext);

  const currentAppliedFilters = Object.entries(filterState).filter(
    ([filterName]) => filterName !== 'Searchbar',
  ).length;

  const filterString = `${
    currentAppliedFilters > 0
      ? `Filters: ${currentAppliedFilters} Active`
      : 'Filters'
  }`;

  return (
    <>
      <Wrapper
        style={disableSort ? { marginLeft: '0' } : {}}
        onClick={toggleSideFilter}
      >
        <SelectedSortIndexText>
          <StyledFilterListIcon /> {filterString}
        </SelectedSortIndexText>
      </Wrapper>
    </>
  );
};

export default FilterToggle;
