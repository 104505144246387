import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import styled, { css, ThemeContext } from 'styled-components';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useFormik } from 'formik';

import Button from '../../darkblue-ui/Button/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import BitcoinChart from '../../darkblue-ui/BitcoinModule/BitcoinChart';
import Loader from '../../darkblue-ui/Spinners/Loader';
import Input from '../../darkblue-ui/Inputs/Input';
import BitcoinModule from '../../darkblue-ui/BitcoinModule/BitcoinModule';
import BluestoneLogoComponent from '../../darkblue-ui/BluestoneLogoComponent/BluestoneLogoComponent';
import useEnCodeTimeUri from '../../utils/Hooks/UseEnCodeTimeUri';
import { downloadAsPDF } from '../../utils/Functions/utils';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import MUITooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';

const BitcoinText = styled.p`
  font-size: 23px;
  font-weight: bold;
  padding: 10px 0px;

  span.btc__value {
    font-size: 30px;
    font-weight: 400;
  }

  span.btc__text {
    font-weight: normal;
    font-size: 25px;
    color: ${(props) => props.theme.colors.cloud};
  }

  span.price__date {
    font-size: 10px;
  }
`;

const ButtonGroupContainer = styled.div`
  float: right;
  margin-top: 36px;
  flex-direction: column;
  display: flex;

  button {
    &:hover {
      background-color: #025cc2;

      span {
        color: white;
      }
    }

    &.active {
      background-color: #025cc2;

      span {
        color: white;
      }
    }
  }
`;

const ThemedGetAppIcon = styled(GetAppIcon)`
  color: ${(props) => props.theme.colors.primary} !important;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const BitcoinCard = styled(Card)`
  background-color: ${(props) => props.theme.colors.cardBackground} !important;
`;

const SubmitButton = styled(Button)`
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
  margin-top: 15px;
  max-width: 150px;
  width: 100%;
`;

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 90% auto;
  align-items: center;
  justify-content: center;
  padding: 15px;

  div {
    width: 100%;
  }
`;

const DateRangeButton = styled(Button)`
  width: 50px;
  height: 25px;
`;

const FilterButton = styled(Button)`
  width: 20%;
  height: 25px;
`;

const validateBitCoinAddress = (address) => {
  let errorMessage = '';
  if (
    /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/g.test(address) ||
    /^bc1[a-zA-HJ-NP-Z0-9]{6,87}$/g.test(address)
  ) {
    errorMessage = '';
  } else if (!address) {
    errorMessage = 'Please enter bitcoin address.';
  } else {
    errorMessage = 'A valid bitcoin address must be entered.';
  }

  return errorMessage;
};

const DateRangeButtonGroup = ({
  buttonSelection,
  setDateRange,
  setButtonSelection,
}) => {
  return (
    <>
      <ButtonGroupContainer>
        <ButtonGroup size="small">
          <DateRangeButton
            className={buttonSelection === '7D' ? 'active' : ''}
            secondary
            type="button"
            onClick={() => {
              setDateRange({
                start: dayjs(
                  new Date(new Date().setDate(new Date().getDate() - 7)),
                ).format('YYYY-MM-DD'),
                end: dayjs().format('YYYY-MM-DD'),
              });
              setButtonSelection('7D');
            }}
          >
            <span>7D</span>
          </DateRangeButton>
          <DateRangeButton
            className={buttonSelection === '1M' ? 'active' : ''}
            secondary
            type="button"
            onClick={() => {
              setDateRange({
                start: dayjs(
                  new Date(new Date().setDate(new Date().getDate() - 30)),
                ).format('YYYY-MM-DD'),
                end: dayjs().format('YYYY-MM-DD'),
              });
              setButtonSelection('1M');
            }}
          >
            <span>1M</span>
          </DateRangeButton>
          <DateRangeButton
            className={buttonSelection === 'ALL' ? 'active' : ''}
            secondary
            type="button"
            onClick={() => {
              setDateRange({
                start: dayjs(
                  new Date(new Date().setDate(new Date().getDate() - 1825)),
                ).format('YYYY-MM-DD'),
                end: dayjs().format('YYYY-MM-DD'),
              });
              setButtonSelection('ALL');
            }}
          >
            <span>ALL</span>
          </DateRangeButton>
        </ButtonGroup>
      </ButtonGroupContainer>
    </>
  );
};

/**
 * @Component TabPanel
 * Renders: Renders the PGP entry form & result list.
 * @param  {obj} props
 */
const BitcoinPageModule = (props) => {
  const { index, logo } = props;
  const history = useHistory();

  const [dateRange, setDateRange] = useState({
    start: dayjs(
      new Date(new Date().setDate(new Date().getDate() - 30)),
    ).format('YYYY-MM-DD'),
    end: dayjs().format('YYYY-MM-DD'),
  });

  const [buttonSelection, setButtonSelection] = useState('1M');
  const [bitcoinAddress, setBitcoinAddress] = useState([]);
  const [errors, setErrors] = useState({});
  const themeContext = useContext(ThemeContext);
  const [currencyPrices, setCurrencyPrices] = useState({});

  const formik = useFormik({
    initialValues: {
      bitcoinAddress: '',
    },
    onSubmit: (values, { resetForm }) => {
      const error = validateBitCoinAddress(values.bitcoinAddress);
      if (!error) {
        setBitcoinAddress([values.bitcoinAddress]);
        resetForm();
        setErrors({ errorMessage: '' });
      } else {
        setErrors({
          errorMessage: error,
        });
        return;
      }
    },
  });

  useEffect(() => {
    const fetchCurrentPrice = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_COINDESK_URL}currentprice.json`,
      );
      const data = await response.json();
      setCurrencyPrices({
        USD: { ...data },
      });
    };
    fetchCurrentPrice();
  }, []);

  useEffect(() => {
    const loadBitcoinAddress = () => {
      setBitcoinAddress([decodeURI(window.location.search).split('"')[1]]);
    };

    if (window.location.search) {
      loadBitcoinAddress();
    }
  }, []);

  const isThereBitcoinData = bitcoinAddress.length > 0;
  return (
    <>
      <GridContainer direction="column" alignItems="center">
        <GridItem xs={12}>
          <GridContainer>
            <GridItem style={{ width: '100%' }}>
              <BitcoinCard>
                <FormContainer onSubmit={formik.handleSubmit}>
                  <Input
                    style={{ width: '100%' }}
                    type="text"
                    name="bitcoinAddress"
                    label="Enter Bitcoin Address"
                    inputProps={{
                      onChange: formik.handleChange,
                      value: formik.values.bitcoinAddress,
                      name: 'bitcoinAddress',
                    }}
                  />
                  <div style={{ width: '100%' }}>
                    <SubmitButton type="submit">Search</SubmitButton>
                  </div>
                </FormContainer>
                {errors.hasOwnProperty('errorMessage') && (
                  <h4
                    style={{
                      color: errors.errorMessage ? '#f55a4e' : '#fff',
                      textAlign: 'center',
                      float: 'left',
                    }}
                  >
                    {errors.errorMessage}
                  </h4>
                )}
              </BitcoinCard>
            </GridItem>
          </GridContainer>
          <div id="BitcoinModule">
            <GridContainer direction="row">
              {isThereBitcoinData ? (
                <GridItem style={{ width: '100%' }}>
                  <BitcoinCard>
                    <GridContainer direction="row">
                      <GridItem xs={8}>
                        <div style={{ display: 'inline-flex' }}>
                          <BitcoinText
                            style={{ padding: '7px', fontSize: '21px' }}
                          >
                            Address:
                          </BitcoinText>

                          <MUITooltip
                            interactive
                            placement="right"
                            title={
                              <div style={{ display: 'inline-block' }}>
                                <FilterButton
                                  secondary
                                  onClick={() => {
                                    const encodedTag = encodeURI(
                                      `"${bitcoinAddress}"`,
                                    );
                                    const path = `/search/data?BitcoinAddressesFilter=%7B%22value%22%3A${encodedTag}%7D`;
                                    history.push(path);
                                  }}
                                >
                                  Search In DarkBlue
                                </FilterButton>
                              </div>
                            }
                          >
                            <p
                              style={{
                                paddingTop: '16px',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontWeight: '400',
                              }}
                            >
                              {bitcoinAddress}
                            </p>
                          </MUITooltip>
                        </div>
                      </GridItem>
                      <GridItem
                        xs={3}
                        style={{ marginTop: '29px', marginRight: '27px' }}
                      >
                        <ThemedGetAppIcon
                          style={{ float: 'right' }}
                          onClick={() =>
                            downloadAsPDF(
                              'BitcoinModule',
                              'DarkBlue Bitcoin Module',
                              themeContext.colors.mainBackground,
                            )
                          }
                        />
                      </GridItem>
                    </GridContainer>

                    <BitcoinModule
                      pageView={true}
                      setErrors={setErrors}
                      filterState={{}}
                      setFilterState={() => {
                      }}
                      bitcoinAddress={bitcoinAddress}
                    />
                  </BitcoinCard>
                </GridItem>
              ) : null}
              <GridItem style={{width: '100%'}}>
                <BitcoinCard key={index}>
                  {_.isEqual(currencyPrices, {}) ? (
                    <Loader />
                  ) : (
                    <>
                      <GridContainer>
                        <GridItem>
                          <GridContainer
                            direction="column"
                            style={{ marginLeft: '10px' }}
                          >
                            <GridItem style={{ height: '35px' }}>
                              <BitcoinText>
                                Bitcoin <span className="btc__text">BTC</span>
                              </BitcoinText>
                            </GridItem>
                            <GridItem style={{ float: 'left' }}>
                              <BitcoinText>
                                <span className="btc__value">
                                  {currencyPrices['USD'].bpi[
                                    'USD'
                                  ].rate_float.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                  })}
                                </span>
                                <span className="price__date">
                                  {dayjs(
                                    currencyPrices['USD'].time.updated,
                                  ).format('h:mm a')}
                                </span>
                              </BitcoinText>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={5}>
                          <DateRangeButtonGroup
                            buttonSelection={buttonSelection}
                            setDateRange={setDateRange}
                            setButtonSelection={setButtonSelection}
                          />
                        </GridItem>
                      </GridContainer>
                    </>
                  )}
                  <Divider />
                  <p
                    style={{
                      margin: '13px 21px 0px',
                      fontSize: '15px',
                      fontWeight: '600',
                      textAlign: 'center'
                    }}
                  >
                    {dayjs(dateRange.start).format('MM/DD/YYYY')}{' '}
                    <span
                      style={{
                        fontSize: 'initial',
                        fontWeight: '300',
                        color: 'grey',
                      }}
                    >
                      to{' '}
                    </span>
                    {dayjs(dateRange.end).format('MM/DD/YYYY')}
                  </p>
                  <BitcoinChart
                    isThereBitcoinData={isThereBitcoinData}
                    chartKey={
                      isThereBitcoinData ? bitcoinAddress[0] : 'default'
                    }
                    range={dateRange}
                  />
                </BitcoinCard>
              </GridItem>
            </GridContainer>
            <div>
              <BluestoneLogoComponent logo={logo} />
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default BitcoinPageModule;
