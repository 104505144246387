const terms = [
  { path: '_id' },
  { path: 'activity.activatedAt.values', sortKey: 12 },
  { path: 'activity.advertisements', sortKey: 19 },
  { path: 'activity.dateJoined.values' },
  { path: 'activity.isActive.values' },
  { path: 'activity.lastLogin.values', sortKey: 8 },
  { path: 'activity.messages' },
  { path: 'activity.escrowMessages', sortKey: 16 },
  { path: 'activity.exchangeContactMessages', sortKey: 18 },
  { path: 'activity.exchangeEscrowMessages', sortKey: 9 },
  { path: 'activity.exchangeMessages', sortKey: 7 },
  { path: 'activity.tradeVolumeDenorm.values' },
  { path: 'activity.transactions' },
  {
    path: 'doc.idHash',
    sortKey: 13,
    filterName: 'DocIdFilter',
    elasticPath: ['doc.idHash.keyword'],
    filterValueType: 'array',
  },
  { path: 'day' },
  { path: 'time' },
  {
    path: 'userId',
    sortKey: 11,
    elasticPath: ['userId.keyword'],
    filterName: 'UserIdFilter',
    filterValueType: 'array',
  },
  {
    path: 'doc.type',
    filterName: 'DocFilter',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'exchangeMessages',
    elasticPath: ['exchangeMessages.keyword'],
  },
  {
    path: 'facebookDataRaw',
    sortKey: 1,
    elasticPath: ['facebookDataRaw.keyword'],
    filterValueType: 'array',
    filterName: 'FacebookDataRawFilter',
  },
  {
    path: 'introduction',
    filterName: 'IntroductionFilter',
    elasticPath: ['introduction.keyword'],
    filterValueType: 'array',
    sortKey: 6,
  },
  {
    path: 'selectors.dob',
    sortKey: 5,
    filterName: 'DobFilterName',
    elasticPath: ['selectors.dob.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.emailAddresses',
    sortKey: 17,
    filterName: 'SelectorEmailAddressesFilter',
    elasticPath: ['selectors.emailAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.gender',
    sortKey: 15,
    elasticPath: ['selectors.gender.keyword'],
  },
  {
    path: 'selectors.ipAddresses',
    sortKey: 14,
    filterName: 'IpAddressFilter',
    elasticPath: ['selectors.ipAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.languages',
    filterValueType: 'array',
    filterName: 'LBCLanguageFilter',
    elasticPath: ['selectors.languages.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.locations',
    filterName: 'LocationsFilter',
    elasticPath: ['selectors.locations.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.names',
    filterName: 'SelectorNameFilter',
    elasticPath: ['selectors.names.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.address',
    elasticPath: ['selectors.address.keyword'],
    sortKey: 4,
  },
  {
    path: 'selectors.info',
    elasticPath: ['selectors.info.keyword'],
    sortKey: 10,
  },
  {
    path: 'selectors.pageLinks',
    filterName: 'PageLinkFilter',
    elasticPath: ['selectors.pageLinks.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.phoneNumbers',
    filterName: 'PhoneNumberFilter',
    elasticPath: ['selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.postLinks',
    elasticPath: ['selectors.postLinks.keyword'],
    filterValueType: 'array',
    filterName: 'PostLinksFilter',
  },

  {
    path: 'selectors.ssn',
    sortKey: 3,
    elasticPath: ['selectors.ssn.keyword'],
    filterValueType: 'array',
    filterName: 'SSNFilter',
  },

  {
    path: 'selectors.timezones',
    elasticPath: ['selectors.timezones.keyword'],
    filterValueType: 'array',
    filterName: 'TimezoneFilter',
  },
  {
    path: 'selectors.bitcoinAddresses',
    elasticPath: ['selectors.bitcoinAddresses.keyword'],
    filterName: 'SelectorBitcoinAddressesFilter',
    filterValueType: 'array',
  },
  {
    path: 'selectors.usernames',
    elasticPath: ['selectors.usernames.keyword'],
    filterValueType: 'array',
    filterName: 'SelectorUserNameFilter',
  },
  { path: 'status.bannedLocal.values' },
  { path: 'status.communitySuperhero.values' },
  { path: 'status.confirmedScammerAt.values' },
  { path: 'status.distrusted.values' },
  { path: 'status.falsePositiveScammerAt.values' },
  { path: 'status.feedbackScore.values' },
  { path: 'status.forcedHoliday.values' },
  { path: 'status.isStaff.values' },
  { path: 'status.isSuperuser.values' },
  { path: 'status.proTrader.values' },
  { path: 'status.socialEngineering.values' },
  { path: 'status.stolenAccount.values' },
  { path: 'status.suspectedScammer.values' },
  { path: 'status.walletFrozen.values' },
  {
    path: 'tags',
    elasticPath: ['tags.keyword'],
    filterValueType: 'array',
    filterName: 'TagsFilter',
  },
  { path: 'timestamp' },
  {
    path: 'walletId',
    sortKey: 2,
    filterName: 'WalletIdFilter',
    elasticPath: ['walletId.keyword'],
    filterValueType: 'array',
  },
];

export { terms };
