import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';

import { updateAttribute, getSettings } from './utils';

import Input from '../../../darkblue-ui/Inputs/Input';
import Button from '../../../darkblue-ui/Button/Button';
import Loader from '../../../darkblue-ui/Spinners/Loader';
import ConfrimEmailPhone from './ConfirmEmailPhone';
import ChangePasswordForm from './ChangePasswordForm';
import WarningModal from './WarningModal';

const SettingsCard = styled.div`
  border-radius: 5px !important;
  padding: 10px;
  border-color: #d0d7de;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  width: 100%;

  @media only screen and (max-width: 1050px) {
    border-style: none;
  }
`;
const ErrorText = styled.p`
  color: #e63946;
  font-size: 1rem;

  & > em {
    color: inherit;
    font-size: inherit;
  }
`;

const UsernameText = styled.p`
  font-size: 15px;
  margin: 0px;
`;

const ResendCode = styled.a`
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  margin-left: 5px;
  font-size: 12px;
`;

const CancelButton = styled(Button)`
  padding: 10px; 20px;
`;

const formSchema = Yup.object().shape({
  email: Yup.string().matches(
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    'Invalid email address',
  ),
  phone_number: Yup.string().matches(/^\+1[0-9]{10}$/i, 'Invalid phone number'),
});

const handleContUpdate = (handleSubmit, setIsOpen) => {
  if (handleSubmit) {
    handleSubmit();
    setIsOpen(false);
  }
};

export default function Account({ snackBar }) {
  const [attributes, setAttributes] = useState({
    loading: true,
  });
  const [verifyEmailPhone, setVerifyEmailPhone] = useState({});
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const {
        email,
        phone_number,
        phoneVerified,
        emailVerified,
        username,
        ...rest
      } = await getSettings('accounts');
      setAttributes({
        username,
        phone_number,
        email,
        phoneVerified,
        emailVerified,
      });
    };
    getUser();
  }, [attributes.phone_number, attributes.email, verifyEmailPhone.open]);

  return (
    <>
      <ConfrimEmailPhone
        snackBar={snackBar}
        verifyEmailPhone={verifyEmailPhone}
        setVerifyEmailPhone={setVerifyEmailPhone}
      />
      <ChangePasswordForm snackBar={snackBar} open={open} setOpen={setOpen} />
      {attributes.loading ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Loader color="#2c6982" />
        </div>
      ) : (
        <SettingsCard>
          <h2>Update Account </h2>
          <div style={{ paddingBottom: '10px' }}>
            <Divider
              style={{ backgroundColor: '#d0d7de' }}
              variant="fullWidth"
            />
          </div>

          <p style={{ fontWeight: 500, display: 'inline', fontSize: '18px' }}>
            Username
          </p>
          <UsernameText>{attributes.username}</UsernameText>
          <Formik
            validateOnBlur
            enableReinitialize
            initialValues={attributes}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, validate },
            ) => {
              if (validate) {
                validate(values);
              } else {
                try {
                  const { email, phone_number } = values;
                  const response = await updateAttribute({
                    email: email || '',
                    phone_number: phone_number || '',
                  });
                  setAttributes(values);
                  resetForm({ values });
                  snackBar(`User Profile Saved!`, {
                    variant: 'success',
                  });
                  setSubmitting(false);
                } catch (err) {
                  console.log(err);
                }
              }
            }}
            validationSchema={formSchema}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              touched,
              resetForm,
              dirty,
              isValid,
            }) => {
              return isSubmitting ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Loader color="#2c6982" />
                </div>
              ) : (
                <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <WarningModal
                    isOpen={isOpen}
                    title={'Remove Email Contact?'}
                    setIsOpen={setIsOpen}
                    resetForm={resetForm}
                    handleContinue={() => {
                      handleContUpdate(handleSubmit, setIsOpen);
                    }}
                  >
                    If you remove your email, you will lose the ability to reset
                    your password. Are you sure you want to remove email
                    contact?
                  </WarningModal>

                  <Input
                    type="text"
                    name="email"
                    label="Email"
                    onBlur={handleBlur}
                    inputProps={{
                      onChange: handleChange,
                      value: values.email || '',
                      name: 'email',
                    }}
                  />

                  {!attributes.emailVerified && attributes.email && (
                    <ResendCode
                      onClick={() =>
                        setVerifyEmailPhone({
                          open: true,
                          confirmType: 'email',
                          email: values.email,
                          phone_number: values.phone_number,
                          attributeName: 'email',
                          title: 'Email',
                        })
                      }
                    >
                      Verify Code
                    </ResendCode>
                  )}
                  {dirty && errors.email && touched.email && (
                    <ErrorText>{errors.email}</ErrorText>
                  )}
                  <Input
                    type="text"
                    label="Phone"
                    name="phone_number"
                    onBlur={handleBlur}
                    inputProps={{
                      onChange: handleChange,
                      value: values.phone_number || '',
                      name: 'phone_number',
                    }}
                  />
                  {!attributes.phoneVerified && attributes.phone_number && (
                    <ResendCode
                      onClick={() =>
                        setVerifyEmailPhone({
                          open: true,
                          confirmType: 'phone',
                          email: values.email,
                          phone_number: values.phone_number,
                          attributeName: 'phone_number',
                          title: 'Phone Number',
                        })
                      }
                    >
                      Verify Code
                    </ResendCode>
                  )}

                  {dirty && errors.phone_number && touched.phone_number ? (
                    <>
                      <ErrorText>{`${errors.phone_number}`}</ErrorText>
                      <ErrorText>
                        Please follow the following format <em>+1xxxxxxxxxx</em>
                      </ErrorText>
                    </>
                  ) : !values.phone_number ? (
                    <span style={{ color: '#585858', fontSize: '15px' }}>
                      {' '}
                      +1xxxxxxxxxx
                    </span>
                  ) : null}
                  <div style={{ margin: '20px 10px' }}>
                    <Button
                      disabled={!(dirty && isValid)}
                      type="button"
                      style={{ padding: '10px 20px' }}
                      onClick={(e) => {
                        e.preventDefault();

                        if (attributes.email && !values.email) {
                          setIsOpen(true);
                        } else {
                          handleSubmit();
                        }
                      }}
                    >
                      Update Profile
                    </Button>
                    <Button
                      style={{
                        marginLeft: '5px',
                        padding: '8px 20px',
                      }}
                      type="reset"
                      secondary
                      onClick={setOpen}
                    >
                      Change Password
                    </Button>
                    {dirty ? (
                      <span style={{ marginLeft: '5px' }}>
                        <Button
                          type="reset"
                          style={{ padding: '8px 22px' }}
                          secondary
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm({ values: attributes });
                          }}
                        >
                          Cancel
                        </Button>
                      </span>
                    ) : null}
                  </div>
                </form>
              );
            }}
          </Formik>
        </SettingsCard>
      )}
    </>
  );
}
