import React from 'react';
import styled from 'styled-components';

const StyledGrid = styled.div`
  display: grid;
  gap: ${(props) => (props.gap ? props.gap : null)};
  grid-template: ${(props) => (props.template ? `"${props.template}"` : null)};
  grid-template-columns: ${(props) =>
    props.repeat ? `repeat(${props.repeat}, minmax(0, 1fr))` : 'item item'};
`;

const Grid = ({ children, ...rest }) => {
  return <StyledGrid {...rest}>{children}</StyledGrid>;
};

export default Grid;
