import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import { Formik } from 'formik';

import SwitchGroup from '../../../darkblue-ui/Inputs/SwitchGroup';
import Button from '../../../darkblue-ui/Button/Button';
import Loader from 'darkblue-ui/Spinners/Loader';
import { updateCustomAttribute, getSettings } from './utils';

const SettingsCard = styled.div`
  border-radius: 5px !important;
  padding: 20px;
  border-color: #d0d7de;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;

  @media only screen and (max-width: 1050px) {
    border-style: none;
  }
}
`;

export default function Apperance({ theme, setTheme, snackBar }) {
  const [attribute, setAttribute] = useState({ loading: true });
  useEffect(() => {
    const getUser = async () => {
      const { savedTheme } = await getSettings('apperance');
      setAttribute({ savedTheme });
    };
    getUser();
  }, []);

  return (
    <>
      {attribute.loading ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Loader color="#2c6982" />
        </div>
      ) : (
        <SettingsCard>
          <h2>Theme preferences</h2>
          <div style={{ paddingBottom: '10px' }}>
            <Divider
              style={{ backgroundColor: '#d0d7de' }}
              variant="fullWidth"
            />
          </div>
          <Formik
            enableReinitialize
            initialValues={{ customTheme: theme }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              await updateCustomAttribute(
                values.customTheme,
                'custom:themePreference',
              );
              setAttribute({ savedTheme: values.customTheme });
              resetForm({ values });
              snackBar(`Theme Saved!`, {
                variant: 'success',
              });
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => {
              return (
                <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <div>
                    <SwitchGroup
                      name="Theme"
                      id="Theme"
                      value={values.customTheme === 'dark'}
                      onChange={() => {
                        setTheme();
                        setFieldValue(
                          'customTheme',
                          values.customTheme === 'dark' ? 'light' : 'dark',
                        );
                      }}
                      label={theme}
                    />
                    <p>Click Save to save your preference.</p>
                  </div>
                  <div style={{ margin: '55px 10px 0px' }}>
                    <Button
                      disabled={attribute.savedTheme === values.customTheme}
                      type="button"
                      style={{ padding: '10px 20px' }}
                      onClick={(e) => {
                        handleSubmit();
                      }}
                    >
                      Save
                    </Button>
                    {attribute.savedTheme !== theme ? (
                      <span style={{ marginLeft: '5px' }}>
                        <Button
                          type="reset"
                          style={{ padding: '8px 22px' }}
                          secondary
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm();
                            setTheme();
                          }}
                        >
                          Cancel
                        </Button>
                      </span>
                    ) : null}
                  </div>
                </form>
              );
            }}
          </Formik>
        </SettingsCard>
      )}
    </>
  );
}
