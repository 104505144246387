import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SwitchGroup from '../../Inputs/SwitchGroup';
import GridContainer from 'components/Grid/GridContainer';

const ToggleContainer = styled.div`
  vertical-align: middle;
  padding: 0px 15px !important;
`;

const CustomComponent = ({
  filterName,
  filterState,
  setFilterState,
  query,
  elasticPath,
  toggleValue,
  setToggleValue,
}) => {
  useEffect(() => {
    if (toggleValue) {
      setFilterState({
        ...filterState,
        ...{
          [filterName]: {
            value: elasticPath,
            query,
            elasticPath,
          },
        },
      });
    } else {
      const { [filterName]: temp, ...newFilterState } = filterState;
      setFilterState(newFilterState);
    }
  }, [toggleValue]);

  useEffect(() => {
    if (!filterState[filterName]) setToggleValue(false);
    else setToggleValue(true);
  }, [filterState[filterName]]);

  return (
    <SwitchGroup
      style={{ padding: '0px' }}
      value={toggleValue}
      onChange={(val) => {
        setToggleValue(val);
      }}
    />
  );
};

const ToggleFilter = (props) => {
  const {
    filterState,
    setFilterState,
    filterName,
    filterLabel,
    query,
    elasticPath,
  } = props;
  const [toggleValue, setToggleValue] = useState(false);

  return (
    <GridContainer style={{ alignItems: 'center', padding: '0 13px' }}>
      <ToggleContainer>
        <CustomComponent
          filterName={filterName}
          filterState={filterState}
          setFilterState={setFilterState}
          query={query}
          elasticPath={elasticPath}
          setToggleValue={setToggleValue}
          toggleValue={toggleValue}
        />
      </ToggleContainer>
      <div>
        <p className={'s'}>{filterLabel}</p>
      </div>
    </GridContainer>
  );
};

export default ToggleFilter;
