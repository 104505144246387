import React from 'react';
import StoreIcon from '@material-ui/icons/Store';
const tags = [
  {
    singular: true,
    indexTag: true,
    path: 'doc.type',
    elasticPath: ['doc.type.keyword'],
    tagLabel: 'Data Type',
    filterName: 'DocFilter',
    icon: <StoreIcon />,
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.net',
    tagLabel: 'Site Net',
    elasticPath: ['site.net.keyword'],
    filterName: 'SiteNetFilter',
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.name',
    tagLabel: 'Site Name',
    filterName: 'SiteNameFilter',
    elasticPath: ['site.name.keyword'],
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'role',
    tagLabel: 'Role',
    filterName: 'RoleFilter',
    elasticPath: ['role.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.tags',
    tagPointer: 'ai.content',
    tagLabel: 'Content Tags',
    elasticPath: ['ai.content.tags.keyword'],
    filterName: 'ContentTagsFilter',
    filterValueType: 'array',
  },
  {
    path: 'ai.language',
    tagLabel: 'Language',
    elasticPath: ['ai.language.keyword'],
    filterName: 'LanguageFilter',
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'ai.stats.risk.rank',
    tagPointer: 'ai.stats.risk.rank',
    tagToolTipDescription: 'ai.stats.risk.explained',
    tagLabel: 'Risk Rank',
    elasticPath: ['ai.stats.risk.rank.keyword'],
    filterName: 'RiskRankFilter',
    filterValueType: 'array',
  },
  {
    descriptionPath: 'ai.usper.explanation',
    tagText: 'Potential USPER',
    path: 'ai.usper.potential',
    tagLabel: 'US Person',
    filterName: 'UsperFilter',
    elasticPath: ['ai.usper.potential'],
    filterValueType: 'array',
  },
];

const earmarks = [{ path: 'timestamp' }];

const title = {
  title: 'Vendor Name',
  path: 'vendorName',
  filterName: 'VendorNameFilter',
  elasticPath: ['vendorName.keyword'],
  filterValueType: 'array',
};

const body = [
  { title: 'Vendor Information', path: 'vendorInfo' },
  { title: 'Signature', path: 'signature' },
  { title: 'Number of Posts', path: 'numberOfPosts' },
];

const footers = [
  {
    title: 'Vendor ID',
    path: 'vendorId',
    filterName: 'VendorIdFilter',
    elasticPath: ['vendorId.keyword'],
    filterValueType: 'array',
  },
  { title: 'Minutes Spent Online', path: 'minutesSpentOnline' },
  { title: 'Days Active', path: 'ai.stats.activity.daysActive' },
  { title: 'Products', path: 'ai.stats.activity.productsTotal' },
  { title: 'Reviews', path: 'ai.stats.activity.reviewsTotal' },
];

const selectors = [
  'analyticsIDs',
  'apacheServices',
  'bitcoinAddresses',
  'bitcoinSignature',
  'emailAddresses',
  'ethereumAddresses',
  'litecoinAddresses',
  'moneroAddresses',
  'pgp.fingerprints',
  'pgp.identities.emails',
  'pgp.identities.names',
  'phone',
  'phoneNumbers',
  'socialMedia',
  'vendorBitcoinAddress',
  'vendorPgp',
  'identity',
];

export { tags, earmarks, title, body, footers, selectors };
