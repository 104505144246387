import React, { useState } from 'react';
import Input from '../../Inputs/Input';

const TextFilter = (props) => {
  const { filterState, setFilterState, filterName, filterLabel, filterModalOpen, query, elasticPath } = props;
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = () => {
    setFilterState({
      ...filterState,
      ...{
        [filterName]: {
          value: inputValue,
          query,
          elasticPath,
        },
      },
    });
  }

  return (
      <Input
        name="textValue"
        label={filterLabel}
        filterModalOpen={filterModalOpen}
        inputProps={{
          value: inputValue,
          onChange: handleChange,
          onSubmit: handleSubmit,
        }}
        button
      />
  );
};

export default TextFilter;
