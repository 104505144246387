import React from 'react';
import styled from 'styled-components';

import Modal from '../../../darkblue-ui/Modals/Modal/Modal';
import Button from './../../../darkblue-ui/Button/Button';

const ModalButton = styled(Button)`
  padding: 10px 20px;
  box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, 0.02),
    0 1.1px 3.7px rgba(0, 0, 0, 0.028), 0 2.1px 6.9px rgba(0, 0, 0, 0.035),
    0 3.8px 12.3px rgba(0, 0, 0, 0.042), 0 7.1px 23px rgba(0, 0, 0, 0.05),
    0 17px 55px rgba(0, 0, 0, 0.07);
`;

const ModalBody = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.cardBackground};
  overflow-y: auto;
`;

export default function WarningModal({
  isOpen,
  resetForm,
  title,
  setIsOpen,
  handleContinue,
  children,
}) {
  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onClickOutside={() => {
        resetForm();
        setIsOpen(false);
      }}
    >
      <ModalBody>
        <div>
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <h1
              style={{
                fontWeight: '500',
                fontSize: '14px',
                textAlign: 'center',
              }}
            >
              {title}
            </h1>
          </span>
        </div>
        <p id="alert-dialog-description">{children}</p>
        <div
          style={{
            margin: '20px 10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            style={{ padding: '10px 20px' }}
            onClick={handleContinue}
            color="warning"
          >
            Continue
          </Button>
          <span style={{ marginLeft: '5px' }}>
            <Button
              style={{ padding: '8px 22px' }}
              type="reset"
              secondary
              onClick={() => {
                resetForm();
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
}
