const terms = [
  { path: 'ai.corporateRisk' },
  { path: 'ai.content.csam', elasticPath: ['ai.content.csam.keyword'] },
  {
    path: 'ai.language',
    sortKey: 6,
    filterName: 'LanguageFilter',
    elasticPath: ['ai.language.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.location',
    filterName: 'LocationFilter',
    elasticPath: ['ai.location.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.geo.coordinates',
    elasticPath: ['ai.geo.coordinates'],
    filterValueType: 'array',
  },
  {
    path: 'ai.sentiment.score'
  },
  {
    path: 'ai.sentiment.value',
    elasticPath: ['ai.sentiment.value.keyword'],
    filterName: 'SentimentValueFilter',
    filterValueType: 'array',
  },
  {
    path: 'ai.spam.hits',
  },
  {
    path: 'ai.spam.topHits',
  },
  {
    path: 'ai.usper.potential',
    elasticPath: ['ai.usper.potential'],
  },
  {
    path: 'ai.usper.explanation',
    elasticPath: ['ai.usper.explanation.keyword'],
  },
  { path: 'answerId' },
  {
    path: 'author',
    sortKey: 5,
    filterName: 'AuthorFilter',
    elasticPath: ['author.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'bitcoinAddresses',
    filterName: 'BitcoinAddressesFilter',
    elasticPath: ['bitcoinAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'board',
    sortKey: 3,
    filterName: 'BoardFilter',
    elasticPath: ['board.keyword'],
    filterValueType: 'array',
  },
  { path: 'capCode' },
  { path: 'country' },
  {
    path: 'countryName',
    filterName: 'PostOriginFilter',
    elasticPath: ['countryName.keyword'],
    filterValueType: 'array',
  },
  { path: 'doc.contentHash' },
  {
    path: 'doc.idHash',
    filterName: 'DocIdFilter',
    elasticPath: ['doc.idHash.keyword'],
    filterValueType: 'array',
  },
  { path: 'doc.priorVersions' },
  { path: 'doc.processedTime' },
  { path: 'doc.scraped' },
  {
    path: 'doc.type',
    filterName: 'DocFilter',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'emailAddresses',
    filterName: 'EmailAddressesFilter',
    elasticPath: ['emailAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'hashtags',
    elasticPath: ['hashtags.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'imageUrls',
    elasticPath: ['imageUrls.keyword'],
    filterValueType: 'array',
  },
  { path: 'images', elasticPath: ['images.keyword'] },
  {
    path: 'ipAddresses',
    elasticPath: ['ipAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.armoredKeys',
    elasticPath: ['pgp.armoredKeys.keyword'],
    filterValueType: 'array',
  },
  { path: 'likes', elasticPath: ['likes.keyword'], filterValueType: 'array' },
  {
    path: 'pgp.created',
    elasticPath: ['pgp.created.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.fingerprints',
    elasticPath: ['pgp.fingerprints.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.identities.comments',
    elasticPath: ['pgp.identities.comments.keyword'],
    filterValueType: 'array',
  },
  { path: 'pgp.identities.is_primary' },
  {
    path: 'pgp.identities.names',
    elasticPath: ['pgp.identities.names.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.identities.emails',
    elasticPath: ['pgp.identities.emails.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.identities.signers',
    elasticPath: ['pgp.identities.signers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'phoneNumbers',
    elasticPath: ['phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'postal.uspsExpress',
    elasticPath: ['postal.uspsExpress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'postal.uspsPriority',
    elasticPath: ['postal.uspsPriority.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ethereumAddress',
    elasticPath: ['ethereumAddress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'litecoinAddress',
    elasticPath: ['litecoinAddress.keyword'],
    filterValueType: 'array',
  },
  { path: '_id' },
  {
    path: 'moneroAddresses',
    filterName: 'MoneroAddressesFilter',
    elasticPath: ['moneroAddresses.keyword'],
    filterValueType: 'array',
  },
  { path: 'numAnswers' },
  {
    path: 'page',
    filterName: 'PageFilter',
    elasticPath: ['page.keyword'],
    filterValueType: 'array',
  },
  { path: 'pgpKeys' },
  {
    path: 'phone',
    filterName: 'PhoneFilter',
    elasticPath: ['phone.keyword'],
    filterValueType: 'array',
  },
  { path: 'points', elasticPath: ['points.keyword'], filterValueType: 'array' },
  {
    path: 'repliesCount',
    elasticPath: ['repliesCount.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'retweets',
    elasticPath: ['retweets.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.creditCards',
    elasticPath: ['selectors.creditCards.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.identity',
    elasticPath: ['selectors.identity.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.phoneNumbers',
    elasticPath: ['selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'tripCode',
    filterName: 'TripCodeFilter',
    elasticPath: ['tripCode.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'vote.down',
    elasticPath: ['vote.down.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'vote.total',
    elasticPath: ['vote.total.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'vote.up',
    elasticPath: ['vote.up.keyword'],
    filterValueType: 'array',
  },
  { path: 'post', sortKey: 1 },
  { path: 'postId', elasticPath: ['postId.keyword'] },
  { path: 'postLinks' },
  { path: 'replyTo' },
  { path: 'site.id' },
  {
    path: 'site.name',
    sortKey: 4,
    filterName: 'SiteNameFilter',
    elasticPath: ['site.name.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'site.net',
    filterName: 'SiteNetFilter',
    elasticPath: ['site.net.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'site.type',
    filterName: 'SiteTypeFilter',
    elasticPath: ['site.type.keyword'],
    filterValueType: 'array',
  },
  { path: 'socialMedia', sortKey: 9 },
  {
    path: 'threadId',
    sortKey: 8,
    filterName: 'ThreadIDFilter',
    elasticPath: ['threadId.keyword'],
    filterValueType: 'array',
  },
  { path: 'timestamp' },
  {
    path: 'title',
    sortKey: 2,
    filterName: 'TitleFilter',
    elasticPath: ['title.keyword'],
    filterValueType: 'array',
  },
  { path: 'type', sortKey: 7 },
  {
    path: 'userId',
    filterName: 'UserIdFilter',
    elasticPath: ['userId.keyword'],
    filterValueType: 'array',
  },
];

export { terms };
