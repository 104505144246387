import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import _ from 'lodash';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

import { FilterList } from '@material-ui/icons';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import './MapboxStyle.css';

import VisualizationStateProvider from 'darkblue-ui/Search/VisualizationStateProvider/VisualizationStateProvider';
import { UserContext } from '../../../DBAuthenticator.js';
import { UniversalCategoryList } from 'filters/UniversalFilters.js';
import FilterSideBarDrawer from 'darkblue-ui/FilterSidebar/FilterSideBarDrawer.js';
import FilterSidebar from 'darkblue-ui/FilterSidebar/FilterSidebar.js';
import { getValuesForIndex, getFilteredNames } from 'filters/utils.js';
import Legend from './MapControl/Legend';
import MapControls from './MapControls';
import MapFeaturePositioner from './MapFeaturePositioner/MapFeaturePositioner';
import Popup from './Popup/Popup';
import Slider from './Slider/Slider.js';
import SliderContent from './Slider/SliderContent.js';
import Button from 'darkblue-ui/Button/Button';
import MapSearchbar from './MapSearchbar/MapSearchbar.js';
import MapStyleSelector from './MapStyleSelector/MapStyleSelector';
import { resetMarker } from './mapUtils';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Buffer = styled.div`
  min-width: 192px;
`;

const StyledMapContainer = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  position: relative;

  .mapboxgl-canvas {
    position: relative;
    left: 0;
    top: 0;
  }

  .mapboxgl-popup {
    max-width: fit-content !important;
  }

  .mapboxgl-popup-content {
    width: fit-content;
    background-color: ${(props) => props.theme.colors.cardBackground};
    color: ${(props) => props.theme.colors.primaryText};
    padding: 2px;
  }

  .mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: ${(props) => props.theme.colors.cardBackground};
  }
  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: ${(props) => props.theme.colors.cardBackground};
  }
  .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: ${(props) => props.theme.colors.cardBackground};
  }
  .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: ${(props) => props.theme.colors.cardBackground};
  }

  .mapboxgl-ctrl-top-right {
    right: ${(props) => (props.slideropen ? props.slideroffset : 0)};
  }

  .mapboxgl-ctrl-bottom-right {
    right: ${(props) => (props.slideropen ? props.slideroffset : 0)};
  }
`;

const fadeAnimation = keyframes`
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0.7;
  }
`;

const MapLoader = styled.div`
  height: 100vh;
  width: 100%;
  margin-top: 0;
  background: ${(props) => props.theme.colors.charcoalOpacity};
  z-index: 999;
  position: absolute;
  animation: ${fadeAnimation} 3s linear infinite;
`;

const FilterIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

const SliderClose = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.cardBackground};
  color: ${(props) => props.theme.colors.primaryText};
  padding: 10px 8px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.altPrimary};
  }
`;

const FilterIcon = styled(FilterList)``;

const Mapbox = ({ layers, overrideURL, ...rest }) => {
  const userSess = useContext(UserContext);
  const cleanLayers = layers.filter(
    ({ id, restrictedLayer }) =>
      userSess.featurePerms.includes(id) || !restrictedLayer,
  );
  const safeIndices = cleanLayers.map((layer) => layer.index).flat();
  const [mapIsLoading, setMapIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const filterCategories = getValuesForIndex(
    [cleanLayers[currentIndex].layer],
    UniversalCategoryList,
  );
  const [dataCategories, setDataCategories] = useState([]);
  const filterNames = getFilteredNames(safeIndices);
  const [popupContent, setPopupContent] = useState(null);
  const [latLong, setLatLong] = useState(null);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [sliderContent, setSliderContent] = useState([]);
  const mapParentRef = useRef();
  const map = useRef(null);
  const sliderOffset = '22%';
  const mapOptions = ['dataset', 'zoom', 'center', 'tiles'];
  const tileLayers = [
    {
      name: 'OpenStreetMap.Mapnik',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    },
  ];

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [mapParentRef?.current?.clientHeight, mapParentRef?.current?.clientWidth]);

  useEffect(() => {
    if (sliderContent.length === 0) {
      setSliderOpen(false);
    }
  }, [sliderContent.length]);

  const apiKey = userSess.apiKey;

  return (
    <VisualizationStateProvider
      overrideURL={overrideURL}
      index={safeIndices}
      showDefault={true}
      apiKey={apiKey}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      mapOptions={mapOptions}
      tileLayers={tileLayers}
      dataLayers={cleanLayers}
    >
      {({
        hits,
        searchError,
        filterState,
        setFilterState,
        loading,
        zoom,
        setZoom,
        centerCoords,
        setCenterCoords,
      }) => {
        return (
          <div
            style={{
              display: 'flex',
              height: 'fit-content',
              maxHeight: '100vh',
              position: 'relative',
            }}
          >
            <FilterSideBarDrawer
              expanded={rest.expanded}
              open={rest.sideFilter}
            >
              <FilterSidebar
                index={safeIndices}
                apiKey={userSess.apiKey}
                categories={filterCategories}
                filterList={filterNames}
                handleFilterClose={rest.toggleSideFilter}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                layers={cleanLayers}
                expanded={rest.expanded}
                hideExpanded
              />
            </FilterSideBarDrawer>
            {rest.sideFilter && <Buffer expanded={rest.expanded} />}
            <MapControls
              mapParentRef={mapParentRef}
              filterState={filterState}
              setFilterState={setFilterState}
              sideFilter={rest.sideFilter}
              toggleSideFilter={rest.toggleSideFilter}
              hits={hits}
              searchError={searchError}
              zoom={zoom}
              setZoom={setZoom}
              centerCoords={centerCoords}
              setCenterCoords={setCenterCoords}
              layers={cleanLayers}
              setMapIsLoading={setMapIsLoading}
              apiKey={apiKey}
              setLatLong={setLatLong}
              setPopupContent={setPopupContent}
              map={map}
              index={safeIndices}
              setDataCategories={setDataCategories}
            />
            <StyledMapContainer
              slideroffset={sliderOffset}
              slideropen={sliderOpen}
              className="map-container"
              ref={mapParentRef}
            >
              <MapFeaturePositioner
                title="Open filter sidebar"
                position="top-left"
                styles={{
                  width: '29px',
                  height: '29px',
                }}
              >
                <FilterIconContainer>
                  <FilterIcon onClick={rest.toggleSideFilter} />
                </FilterIconContainer>
              </MapFeaturePositioner>

              <MapFeaturePositioner
                title="Open Searchbar"
                position="top-left"
                styles={{
                  width: '29px',
                  height: '29px',
                  marginTop: '45px',
                  marginLeft: '10px',
                }}
              >
                <MapSearchbar
                  index={safeIndices}
                  mapParentRef={mapParentRef}
                  filterState={filterState}
                  setFilterState={setFilterState}
                  sideFilter={rest.sideFilter}
                  toggleSideFilter={rest.toggleSideFilter}
                  hits={hits}
                  zoom={zoom}
                  setZoom={setZoom}
                  centerCoords={centerCoords}
                  setCenterCoords={setCenterCoords}
                  layers={cleanLayers}
                  setMapIsLoading={setMapIsLoading}
                  apiKey={apiKey}
                  setLatLong={setLatLong}
                  setPopupContent={setPopupContent}
                  map={map}
                />
              </MapFeaturePositioner>
              {sliderContent.length > 0 && (
                <MapFeaturePositioner
                  styles={{
                    marginRight: '0px',
                    ...{
                      right: sliderOpen ? sliderOffset : 0,
                    },
                  }}
                  position="middle-right"
                >
                  <SliderClose onClick={() => setSliderOpen(!sliderOpen)}>
                    {sliderOpen ? <ChevronRight /> : <ChevronLeft />}
                  </SliderClose>
                </MapFeaturePositioner>
              )}
              {sliderOpen && sliderContent.length > 0 && popupContent.field ? (
                <>
                  <MapFeaturePositioner
                    position="top-right"
                    styles={{
                      width: '22%',
                      height: '100%',
                      margin: 0,
                      background: 'none',
                      overflow: 'hidden',
                    }}
                  >
                    <Slider sliderOpen={sliderOpen}>
                      <Button
                        disabled={sliderContent.length === 1}
                        style={{
                          margin: '20px 0',
                          height: '30px',
                          minHeight: '30px',
                          width: '80%',
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          sliderContent.forEach(({ selectedFeature }) => {
                            resetMarker({ map: map.current, selectedFeature });
                          });
                          setSliderContent([]);
                        }}
                      >
                        Remove All
                      </Button>
                      {sliderContent.map((content, index) => {
                        return (
                          <SliderContent
                            key={content.id}
                            map={map}
                            filterState={filterState}
                            setFilterState={setFilterState}
                            content={content}
                            type={content.type}
                            setSliderContent={setSliderContent}
                            index={index}
                          />
                        );
                      })}
                    </Slider>
                  </MapFeaturePositioner>
                </>
              ) : null}

              <MapFeaturePositioner
                title="Open Basemap Tile"
                position="top-right"
                align="right"
                styles={{
                  width: '29px',
                  height: '29px',
                  marginTop: '145px',
                  marginLeft: '10px',
                  ...{
                    right: sliderOpen ? sliderOffset : 0,
                  },
                }}
              >
                <MapStyleSelector
                  mapParentRef={mapParentRef}
                  filterState={filterState}
                  setFilterState={setFilterState}
                  sideFilter={rest.sideFilter}
                  toggleSideFilter={rest.toggleSideFilter}
                  hits={hits}
                  zoom={zoom}
                  setZoom={setZoom}
                  centerCoords={centerCoords}
                  setCenterCoords={setCenterCoords}
                  layers={cleanLayers}
                  setMapIsLoading={setMapIsLoading}
                  apiKey={apiKey}
                  setLatLong={setLatLong}
                  setPopupContent={setPopupContent}
                  map={map}
                />
              </MapFeaturePositioner>

              <MapFeaturePositioner
                position="bottom-right"
                styles={{
                  ...{
                    right: sliderOpen ? sliderOffset : 0,
                  },
                }}
              >
                <Legend dataCategories={dataCategories} />
              </MapFeaturePositioner>
              {mapIsLoading || loading ? <MapLoader /> : null}
            </StyledMapContainer>
            {popupContent ? (
              <Popup
                filterState={filterState}
                setFilterState={setFilterState}
                map={map}
                latLong={latLong}
                setLatLong={setLatLong}
                type={popupContent?.field}
                popupContent={popupContent}
                setSliderOpen={setSliderOpen}
                setSliderContent={setSliderContent}
                sliderContent={sliderContent}
              />
            ) : null}
          </div>
        );
      }}
    </VisualizationStateProvider>
  );
};

export default Mapbox;
