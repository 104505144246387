import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

const SwitchGroupContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.plume};
`;

const SwitchContainer = styled.div`
  position: relative;
  max-width: 21px;
  width: 21px;
  min-width: 21px;
  height: 10px;
  margin: 12px 8px;
  background-color: ${(props) => props.theme.colors.cloud};
  border-radius: 0px;
  transition: all 300ms linear;

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.colors.primary};
    `}
`;

const LabelText = styled.p`
  color: ${(props) => props.theme.colors.cardBodyTitle};
  text-transform: capitalize;
`;

const HiddenSwitch = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Switch = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 0px;
  border: 1px solid ${(props) => props.theme.colors.cloud};
  left: -12px;
  top: -50%;
  background-color: ${(props) => props.theme.colors.primary};
  transition: all 300ms linear;
  &:hover {
    cursor: pointer;
  }
  ${(props) =>
    props.active &&
    css`
      transform: translate(16px, 0px);
      left: auto;
    `};
`;

const SwitchGroup = (props) => {
  const [active, setActive] = useState(props.value || false);
  const handleSwitchClick = (checked) => {
    if (props.onChange) {
      props.onChange(checked);
    } else {
      setActive(!active);
    }
  };

  useEffect(() => {
    setActive(props.value);
  }, [props.value]);

  return (
    <SwitchGroupContainer style={props.style}>
      <SwitchContainer
        onClick={(e) => handleSwitchClick(!active)}
        active={active}
      >
        <HiddenSwitch />
        <Switch active={active} />
      </SwitchContainer>
      {props.label ? (
        <LabelText
          style={active ? { marginLeft: '15px' } : { marginLeft: '0px' }}
          className="no-margin"
        >
          {props.label}
        </LabelText>
      ) : null}
    </SwitchGroupContainer>
  );
};

export default SwitchGroup;
