import React, { useState, useEffect, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { ResizeObserver } from '@juggle/resize-observer';

import useLocalStorage from '../../utils/Hooks/SetLocalStorage';

import { AxisLeft, AxisBottom } from '@visx/axis';
import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { scaleTime, scaleLinear } from '@visx/scale';

import { AreaClosed, Line, Bar, LinePath } from '@visx/shape';
import { localPoint } from '@visx/event';
import {
  useTooltip,
  useTooltipInPortal,
  defaultStyles,
  Tooltip,
} from '@visx/tooltip';

import { extent, bisector, max } from 'd3-array';

import _ from 'lodash';
import dayjs from 'dayjs';

const ToolTipCard = styled(Tooltip)`
  background-color: ${(props) => props.theme.colors.cardBackground} !important;
`;

/**
 * @Component TabPanel
 * Renders: Renders the PGP entry form & result list.
 * @param  {obj} props
 */
const BitcoinChart = (props) => {
  const { range, chartKey, isThereBitcoinData } = props;

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const themeContext = useContext(ThemeContext);
  const [bitcoinChartValues, setBitcoinChartValues] = useState([]);
  const [cachedBitocoin, setCacheBitcoinData] = useLocalStorage(
    'cacheBitcoinData',
    {},
  );

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
    polyfill: ResizeObserver,
  });

  const getCachedBitcoinData = () => {
    if (!_.isEqual(cachedBitocoin, {})) {
      return cachedBitocoin;
    } else {
      return {};
    }
  };

  const buildChartData = (data) => {
    const xAxisValues = data.hasOwnProperty('bpi') && Object.keys(data.bpi);
    const yAxisValues = data.hasOwnProperty('bpi') && Object.values(data.bpi);
    setBitcoinChartValues(
      xAxisValues.map((date, index) => ({
        date: new Date(dayjs(date)),
        close: yAxisValues[index],
      })),
    );
  };
  const [bitcoinData, setBitcoinData] = useState(getCachedBitcoinData);

  useEffect(() => {
    const fetchData = async () => {
      const partialURL = `start=${range.start}&end=${range.end}`;

      if (bitcoinData[partialURL]) {
        const data = bitcoinData[partialURL];
        buildChartData(data);
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_COINDESK_URL}historical/close.json?start=${range.start}&end=${range.end}`,
        );
        const data = await response.json();
        buildChartData(data);

        setBitcoinData({
          ...bitcoinData,
          ...{ [partialURL]: data },
        });
        setCacheBitcoinData({
          ...bitcoinData,
          ...{ [partialURL]: data },
        });
      }
    };

    fetchData();
  }, [range]);

  const width = 600;
  const height = 500;
  const margin = {
    top: 20,
    bottom: 40,
    left: 60,
    right: 20,
  };

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const getDate = (d) => d.date;
  const getBitcoinValue = (d) => d.close;
  const bisectDate = bisector(getDate).left;

  const xScale = scaleTime({
    range: [0, xMax],
    domain: extent(bitcoinChartValues, getDate),
  });

  const yScale = scaleLinear({
    range: [yMax, 0],
    domain: [0, max(bitcoinChartValues, getBitcoinValue)],
  });

  const handleMouseOver = (event) => {
    const { x } = localPoint(event);
    const normalX = x - margin.left;
    const x0 = xScale.invert(normalX);
    const index = bisectDate(bitcoinChartValues, x0, 0);

    const d = bitcoinChartValues[index];
    if (!d) return;
    showTooltip({
      tooltipData: d,
      tooltipLeft: xScale(getDate(d)),
      tooltipTop: yScale(getBitcoinValue(d)),
    });
  };

  const mouseCaptureArea = (
    <Bar
      width={xMax}
      height={yMax}
      fill="transparent"
      onMouseMove={handleMouseOver}
      onMouseLeave={hideTooltip}
    />
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly' }} ref={containerRef}>
      <svg
        width={width}
        height={height}
        onMouseOver={handleMouseOver}
        onMouseOut={hideTooltip}
      >
        <Group top={margin.top} left={margin.left}>
          <LinearGradient
            id="area-gradient"
            from={themeContext.bitcoinChartColors[0].linearGradient.from}
            to={themeContext.bitcoinChartColors[0].linearGradient.to}
            toOpacity={0.07}
          />

          <AxisLeft
            hideTicks
            hideAxisLine
            scale={yScale}
            top={0}
            numTicks={6}
            label={'Close Price ($)'}
          />

          <AxisBottom
            hideAxisLine={true}
            hideTicks={true}
            numTicks={5}
            scale={xScale}
            top={yMax}
            tickStroke={
              themeContext.bitcoinChartColors[0].axisBottom.tickStroke
            }
          />
          {mouseCaptureArea}

          <LinePath
            data={bitcoinChartValues}
            x={(d) => xScale(getDate(d))}
            y={(d) => yScale(getBitcoinValue(d))}
            stroke={themeContext.bitcoinChartColors[0].linePath.stroke}
            strokeWidth={2}
          />
          {tooltipData && !isThereBitcoinData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: 0 }}
                to={{ x: tooltipLeft, y: yMax }}
                stroke={themeContext.bitcoinChartColors[0].line.stroke}
                strokeWidth={1}
                strokeOpacity={1.6}
                style={{ pointerEvents: 'none' }}
                strokeDasharray="2,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill="transparent"
                stroke={themeContext.bitcoinChartColors[0].circle.stroke}
                strokeWidth={2}
                strokeOpacity={0.8}
                style={{ pointerEvents: 'none' }}
              />
            </g>
          )}
          <AreaClosed
            style={{ marginLeft: '40px' }}
            data={bitcoinChartValues}
            x={(d) => xScale(getDate(d))}
            y={(d) => yScale(getBitcoinValue(d))}
            yScale={yScale}
            fill={'url(#area-gradient)'}
          />
        </Group>
      </svg>
      {tooltipOpen && !isThereBitcoinData && (
        <TooltipInPortal
          key={chartKey}
          className="tool-tip"
          top={30}
          left={tooltipLeft}
          style={{
            position: 'absolute',
            borderRadius: '0px',
          }}
        >
          <ToolTipCard
            style={{
              ...defaultStyles,
              minWidth: 72,
              textAlign: 'center',
              transform: 'translateX(-50%)',
            }}
          >
            <p>
              {dayjs(tooltipData.date).format('MM/DD/YYYY')}{' '}
              <strong>
                {tooltipData.close.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </strong>
            </p>
          </ToolTipCard>
        </TooltipInPortal>
      )}
    </div>
  );
};

export default BitcoinChart;
