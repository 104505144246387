import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DomPurify from 'dompurify';

import SearchIcon from '@material-ui/icons/Search';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TranslateIcon from '@material-ui/icons/Translate';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import MUITooltip from '@material-ui/core/Tooltip';

import Button from '../Button/Button';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import { DataSearch } from '@appbaseio/reactivesearch';
import SwitchGroup from 'darkblue-ui/Inputs/SwitchGroup';

import { SearchTranslator } from '../../darkblue-ui/composite/DarkBlueCard/Translator';
import { FormControl, ListItemIcon } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import FileCopy from '@material-ui/icons/FileCopy';

import { useClipboard } from 'use-clipboard-copy';
import { useSnackbar } from 'notistack';

const StyledSearchIcon = styled(SearchIcon)`
  color: ${(props) => props.theme.colors.primary};
  margin-left: 12px;
`;

const StyledSaveIcon = styled(BookmarkBorderIcon)``;

const StyledSavedIcon = styled(BookmarkIcon)`
  color: ${(props) => props.theme.colors.primary} !important;
`;

const SearchButton = styled(Button)`
  & p {
    color: ${(props) => props.theme.colors.plume};
  }
`;

const SearchBarContainer = styled.div`
  height: 38px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.altPrimaryOpacity};
  border-radius: 0px;
  background-color: ${(props) => props.theme.colors.fog};
  transition: border 100ms linear;
  width: 100%;

  &:focus-within {
    border: 3px solid ${(props) => props.theme.colors.primary};
  }

  &:focus-within ${StyledSearchIcon} {
    color: ${(props) => props.theme.colors.altPrimary};
  }

  &focus ${CustomSearchBar} {
    border: 1px solid ${(props) => props.theme.colors.primary};
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const CustomSearchBar = styled.input.attrs({
  type: 'text',
})`
  ::placeholder {
    color: ${(props) => props.theme.colors.almostBlack};
    opacity: 0.8;
  }
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  padding-left: 12px;
  font-size: 16px;
  line-height: ${(props) => props.theme.type.p.m.lineHeight};

  /* height: 36px;
  width: 696px;
  border: 1px solid rgba(42, 141, 254, 0.51);
  border-radius:0px;
  background-color: #e6ecf2; */
`;


const SaveSearchButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;

  & > * {
    color: ${(props) => props.theme.colors.almostBlack};
  }

  &:hover {
    & > * {
      color: ${(props) => props.theme.colors.altPrimary};
    }
  }
`;

const ThemedTranslateIcon = styled(TranslateIcon)`
color: ${(props) => props.theme.colors.primary} !important;
&:hover {
  color: ${(props) => props.theme.colors.altPrimary} !important;
  cursor: pointer !important;
`;

const TranslateDefineBoxContainer = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackground};
  margin: 4px 8px;
  padding: 8px 8px;
`;

const ThemedMenuItem = styled(MenuItem)`
  font-family: 'Red Hat regular';
  font-size: 12px;
  letter-spacing: 0;
  text-align: center;
  line-height: 16px;
`;

const StyledP = styled.p`
  font-size: ${(props) => props.theme.fontSizes[2]};
`;

const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;

  & > * {
    color: ${(props) => props.theme.colors.almostBlack};
  }

  &:hover {
    & > * {
      color: ${(props) => props.theme.colors.altPrimary};
      cursor: pointer;
    }
  }
`;

const TranslateDefineBox = () => {
  return (
    <TranslateDefineBoxContainer>
      <p>
        Type a term into the search bar in English, then select foreign language
        here to translate English term into selected foreign language before
        executing search.
      </p>
    </TranslateDefineBoxContainer>
  );
};

// const TranslateIcon = () => {
//   return (
//     <Tooltip
//       // disableTouchListener={true}
//       placement="left-end"
//       size="medium"
//       title={<TranslateDefineBox />}
//       // open={showTranslateDefine}
//       // onOpen={() => setShowTranslateDefine(true)}
//       // onClose={() => setShowTranslateDefine(false)}
//     >
//       <ThemedTranslateIcon />
//     </Tooltip>
//   );
// };
//

const languageList = [
  { displayName: 'Arabic', languageCode: 'ar' },
  { displayName: 'Bangla', languageCode: 'bn' },
  { displayName: 'Chinese-simplified', languageCode: 'zh-hans' },
  { displayName: 'Chinese-traditional', languageCode: 'zh-hant' },
  { displayName: 'English', languageCode: 'en' },
  { displayName: 'German', languageCode: 'de' },
  { displayName: 'Korean', languageCode: 'ko' },
  { displayName: 'Persian Farsi', languageCode: 'fa' },
  { displayName: 'Portuguese-Brazil', languageCode: 'pt' },
  { displayName: 'Portuguese-Portugal', languageCode: 'pt-pt' },
  { displayName: 'Russian', languageCode: 'ru' },
  { displayName: 'Spanish', languageCode: 'es' },
  { displayName: 'Turkish', languageCode: 'tr' },
];

const ShareSearch = () => {
  const { enqueueSnackbar } = useSnackbar();
  const copy = useClipboard().copy;
  const url = window.location.href;
  const btnStyle = {
    alignSelf: 'center',
    width: '41px',
    height: '41px',
    minWidth: '41px',
    marginLeft: '15px',
  };
  return (
    <div>
      <p
        style={{
          marginTop: '0',
          marginBottom: '3px',
          textDecoration: 'underline',
        }}
      >
        Search URL
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <p style={{ margin: '0', wordBreak: 'break-all' }}>{url}</p>

        <Button
          style={btnStyle}
          onClick={() => {
            const copiedValue = url;
            copy(copiedValue);
            enqueueSnackbar('Successfully Copied!', {
              variant: 'success',
            });
          }}
        >
          <FileCopy fontSize="small" style={{ verticalAlign: 'middle' }} />
        </Button>
      </div>
    </div>
  );
};

const SearchBar = ({
  filterNames,
  filterState,
  setFilterState,
  showAdvFeatures = true,
  safeSearchFields,
  operatorSyntax,
  setOperatorSyntax,
  queryTypeAnd,
  setQueryTypeAnd,
  setShowSaveModal,
  searchSaved,
  setSearchSaved = () => null,
  setShowSearchPrev = () => null,
}) => {
  const defaultQueryString = filterState.SearchboxComponent;
  const [queryString, setQueryString] = useState(defaultQueryString);
  const [showTranslateDefine, setShowTranslateDefine] = useState(false);
  const [selectedLang, setSelectedLang] = useState('');
  const [open, setOpen] = useState(false);
  const [usedLangs, setUsedLangs] = useState([]);

  const handleLangChange = async (e) => {
    setUsedLangs((arr) => [...arr, e.target.value]);
    const qs = await SearchTranslator(
      queryString,
      e.target.value,
      usedLangs[usedLangs.length - 1],
    );
    setSelectedLang(e.target.value);
    setQueryString(qs);
  };

  const handleClick = async () => {
    setFilterState({
      ...filterState,
      ...{ SearchboxComponent: queryString },
    });
    setShowSearchPrev(true);
  };

  const handleTooltipState = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setSearchSaved(false);
    if (queryString !== filterState.SearchboxComponent) {
      setQueryString(filterState.SearchboxComponent);
    }
  }, [filterState]);

  return (
    <>
      <DataSearch
        componentId="SearchboxComponent"
        dataField={safeSearchFields}
        filterLabel="Query Terms"
        autosuggest={false}
        URLParams={true}
        placeholder={
          operatorSyntax
            ? 'Use query syntax (+, |, -, " ") to query the Dark Web'
            : 'Explore the Dark Web'
        }
        queryFormat={queryTypeAnd ? 'and' : 'or'}
        //highlight={true}
        value={filterState.SearchboxComponent}
        onChange={(value, triggerQuery) => {
          const newFilterState = {
            ...filterState,
            ...{
              SearchboxComponent: DomPurify.sanitize(value),
            },
          };
          setFilterState(newFilterState);
          if (triggerQuery) triggerQuery();
        }}
        searchOperators={operatorSyntax}
        highlight={true}
        customHighlight={(props) => {
          const highlightFields = Object.fromEntries(
            safeSearchFields.map((field) => [field, {}]),
          );
          return {
            highlight: {
              pre_tags: ['<mark>'],
              post_tags: ['</mark>'],
              fields: highlightFields,
              number_of_fragments: 0,
            },
          };
        }}
        style={{ display: 'none' }}
      />
      <SearchBarContainer>
        <SearchInputContainer>
          <StyledSearchIcon />
          <CustomSearchBar
            placeholder={
              operatorSyntax
                ? 'Construct boolean queries with and (+), or (|), and not (-) flags'
                : 'Search for keywords, categories, or sources'
            }
            onChange={(e) => setQueryString(e.target.value)}
            value={queryString}
            onKeyPress={(e) => (e.key === 'Enter' ? handleClick() : null)}
          />
        </SearchInputContainer>
        {showAdvFeatures ? (
          <>
            {' '}
            <SwitchGroup
              style={{ paddingRight: '12px' }}
              value={operatorSyntax}
              label="Advanced Query"
              onChange={setOperatorSyntax}
            />
            <SwitchGroup
              style={{ paddingRight: '12px' }}
              value={queryTypeAnd}
              label={queryTypeAnd ? 'AND' : 'OR'}
              onChange={setQueryTypeAnd}
            />
            <Select
              value={selectedLang}
              // onOpen={() => setShowTranslateDefine(false)}
              onChange={handleLangChange}
              IconComponent={ThemedTranslateIcon}
            >
              {languageList.map((lang) => {
                return (
                  <ThemedMenuItem
                    key={lang.languageCode}
                    value={lang.languageCode}
                  >
                    {lang.displayName}
                  </ThemedMenuItem>
                );
              })}
            </Select>
            <SaveSearchButton onClick={() => setShowSaveModal(true)}>
              {searchSaved ? <StyledSavedIcon /> : <StyledSaveIcon />}
              <p style={{ whiteSpace: 'nowrap' }} className="s no-margin">
                {searchSaved ? 'Saved!' : 'Save'}
              </p>
            </SaveSearchButton>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                classes={{
                  tooltip: 'bg-override',
                }}
                interactive
                placement="bottom"
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<ShareSearch />}
              >
                <ShareContainer onClick={() => handleTooltipState()}>
                  <ShareIcon />
                  <StyledP>Share</StyledP>
                </ShareContainer>
              </Tooltip>
            </ClickAwayListener>
          </>
        ) : null}
        <SearchButton search={true} onClick={() => handleClick()}>
          Search
        </SearchButton>
      </SearchBarContainer>
    </>
  );
};

export default SearchBar;
