import React, { useEffect, useState, useContext, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { useQuery } from 'urql';

import Loader from 'darkblue-ui/Spinners/Loader';
import Grid from 'darkblue-ui/core/Grid/Grid';
import Card from 'darkblue-ui/Card/Card';
import Button from 'darkblue-ui/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import ChainalysisLogo from '../../../assets/img/chainalysis_logo.png';
import DarkDarkblueLogo from '../../../assets/img/darkblue_logo_dark.png';
import LightDarkblueLogo from '../../../assets/img/darkblue_logo_light.png';
import CoinbaseLogo from '../../../assets/img/coinbase_logo.svg';

const ThemedFilterAddIconIcon = styled(AddIcon)`
  vertical-align: middle;
  color: ${(props) => props.theme.colors.primary} !important;
  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const TableContainer = styled.div`
  table {
    border-collapse: collapse;
    table-layout: fixed !important;
    width: 100%;
    color: ${(props) => props.theme.colors.cardTitle};
  }
  td,
  th {
    font-size: 15px;
    text-align: left;
    padding: 8px;
    word-wrap: break-word;
    word-break: break-all;
  }

  table tr th:nth-child(1) {
    width: 33%;
  }
`;

const PartnerContainer = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
`;

const IntegrationsContainer = styled.div`
  display: flex;
`;

const PartnerButton = styled.div`
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
`;

const SizedPartnerImage = styled.img`
  max-width: 120px;
  width: 120px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const SvgPartnerImage = styled.img`
  height: 28px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const PartnerDemoLink = styled.a`
  font-size: 11px;
  color: ${(props) => props.theme.colors.primaryText};
  text-decoration: underline;
  position: absolute;
  bottom: 1px;
  left: 25%;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const PartnerContentsContainer = styled.div`
  position: relative;
`;

const ShowMore = styled.p`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

const ExpandContainer = styled.div`
  display: flex;
  padding-right: 36px;
`;
const Flex = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  flex-direction: ${(props) => props.direction || 'row'};
`;

const FlexShadow = styled(Flex)`
  box-shadow: ${(props) => props.theme.colors.primaryText} 0px -1px 5px 0px;
  margin-top: 16px;
`;

const CryptoContainer = styled(Grid)``;

const CryptoQuery = `
  query($addresses: [String]!){
	  crypto(addresses:$addresses){
	  	name
		  type
      address
      conversionFactor
		  statistics{
        balance
        totalSent
        totalReceived
        txs30Days
        firstTx
        recentTx
        totalTxs
		  }
	}
}
`;

const CryptoCard = ({ address: addr, filterState, setFilterState, theme }) => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);

  const { address, name, type } = addr;
  const { statistics = {} } = addr;

  const {
    totalSent,
    totalReceived,
    balance,
    txs30Days,
    firstTx,
    totalTxs,
    recentTx,
  } = statistics;

  return (
    <Card>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>{name} Address</th>
              <th style={{ textAlign: 'left' }}>Total Debits (Inputs)</th>
              <th style={{ textAlign: 'left' }}>Total Credits (Outputs)</th>
              <th style={{ textAlign: 'left' }}>Current Balance</th>
              <th style={{ textAlign: 'left' }}>Txs over 30 days</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{
                    fontWeight: '500',
                  }}
                >
                  {address ? address : 'Invalid Crypto Address'}
                </span>
                {address ? (
                  <span style={{ paddingRight: '3px' }}>
                    <ThemedFilterAddIconIcon
                      onClick={() => {
                        setFilterState({
                          ...filterState,
                          ...{
                            BitcoinAddressesFilter: {
                              value: [address],
                            },
                          },
                        });
                      }}
                    />
                  </span>
                ) : null}
              </td>
              <td>
                {totalReceived !== null
                  ? `${totalReceived} ${type}`
                  : 'Not Available'}
              </td>
              <td>
                {totalSent !== null ? `${totalSent} ${type}` : 'Not Available'}
              </td>
              <td>
                {balance !== null ? `${balance} ${type}` : 'Not Available'}
              </td>
              <td>{txs30Days !== null ? `${txs30Days}` : 'Not Available'}</td>
            </tr>
          </tbody>
        </table>
        <PartnerContainer>
          {address ? <p>Explore More With</p> : null}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IntegrationsContainer>
              {address ? (
                <PartnerContentsContainer>
                  <PartnerButton
                    onClick={() => {
                      const path = `/Modules/bitcoin?bitcoinaddress=%5B\"${address}\"%5D`;
                      if (address) {
                        history.push(path);
                      }
                    }}
                  >
                    <SizedPartnerImage
                      className="sized"
                      src={
                        theme.mode === 'dark'
                          ? LightDarkblueLogo
                          : DarkDarkblueLogo
                      }
                    />
                  </PartnerButton>
                  <PartnerDemoLink />
                </PartnerContentsContainer>
              ) : null}
              {address ? (
                <PartnerContentsContainer>
                  <PartnerButton
                    onClick={() => {
                      const url = `https://reactor.chainalysis.com/graphs/list/cluster/btc/${address}`;
                      window.open(url, '_blank');
                    }}
                  >
                    <SizedPartnerImage
                      src={ChainalysisLogo}
                      className="sized"
                    />
                  </PartnerButton>
                  <PartnerDemoLink
                    href="https://go.chainalysis.com/partner-reactor-access-request.html?utm_source=bluestone"
                    target="_blank"
                  >
                    Request a Demo
                  </PartnerDemoLink>
                </PartnerContentsContainer>
              ) : null}
              {address ? (
                <PartnerContentsContainer>
                  <PartnerButton
                    onClick={() => {
                      const url = `http://app.analytics.coinbase.com/btc/addresses/${address}`;
                      window.open(url, '_blank');
                    }}
                  >
                    <SvgPartnerImage src={CoinbaseLogo} />
                  </PartnerButton>
                </PartnerContentsContainer>
              ) : null}
            </IntegrationsContainer>
            <ExpandContainer>
              {address && type !== 'INVALID' ? (
                <ShowMore onClick={() => setExpanded(!expanded)}>
                  {expanded
                    ? 'Hide More Transactional Data'
                    : 'More Transactional Data'}
                </ShowMore>
              ) : null}
            </ExpandContainer>
          </div>
        </PartnerContainer>
      </TableContainer>
      {expanded ? (
        <BottomCard
          address={address}
          firstTx={firstTx}
          recentTx={recentTx}
          totalTxs={totalTxs}
        />
      ) : null}
    </Card>
  );
};

const BottomCard = ({ address, firstTx, recentTx, totalTxs }) => {
  return (
    <FlexShadow justifyContent={'flex-end'}>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>First Tx </th>
              <th style={{ textAlign: 'left' }}>Most Recent Tx </th>
              <th style={{ textAlign: 'left' }}>Total Tx Count </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: 'left' }}>{firstTx} </td>
              <td style={{ textAlign: 'left' }}>{recentTx}</td>
              <td style={{ textAlign: 'left' }}>{totalTxs}</td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
    </FlexShadow>
  );
};

const CryptoModal = ({
  filterState,
  pageView,
  setFilterState,
  setShowBtcInformation,
  cryptoAddresses,
}) => {
  const themeContext = useContext(ThemeContext);
  const [result, runQuery] = useQuery({
    query: CryptoQuery,
    variables: {
      addresses: cryptoAddresses,
    },
  });

  //Set shown addresses to the first 10
  const { data, fetching, error } = result;

  if (fetching) return <Loader />;

  if (error) {
    setShowBtcInformation(false);
  }

  if (data) {
    return (
      <CryptoContainer>
        <Flex justifyContent={'space-between'}>
          <p style={{ paddingLeft: '24px' }}>
            Showing {data?.crypto?.length || 0} addresses
          </p>
          <Button
            color="danger"
            onClick={() => {
              setShowBtcInformation(false);
            }}
            style={{ margin: '8px 71px', float: 'right', padding: '10px 20px' }}
          >
            Close
          </Button>
        </Flex>
        {data.crypto.map((addr) => (
          <CryptoCard
            key={addr}
            address={addr}
            theme={themeContext}
            filterState={filterState}
            setFilterState={setFilterState}
          />
        ))}
      </CryptoContainer>
    );
  }

  setShowBtcInformation(false);
  return <></>;
};

export default CryptoModal;
