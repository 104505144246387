import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import DateRangeIcon from '@material-ui/icons/DateRange';

import Button from '../../../Button/Button';
import InlineDateFilter from '../../../../components/CustomFilters/InlineDateFilter';
import Input from '../../../Inputs/Input.js';
import { dashboardDatesQuery } from '../../../../utils/Queries/CustomQueries';

import { DateRange } from '@appbaseio/reactivesearch';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

const DateButton = styled(Button)`
  height: 36px;
  width: ${(props) => (props.isSameYear ? '164px' : '170px')};
  border-radius: 0px;

  p {
    color: #f6fbff;
    font-family: 'Red Hat Text';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DateFilterContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50px;
  right: 200px;
  width: 400px;
  z-index: ${(props) => (props.showCalendar ? 100 : -1)};
  height: fit-content;
  background-color: ${(props) => props.theme.colors.cardBackground};
  padding: 12px;
  box-shadow: ${(props) => props.theme.shadow.medHeavy};
  visibility: ${(props) => (props.showCalendar ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.showCalendar ? null : '0px')};

  & > div {
    padding-bottom: 12px;
  }
`;

const StyledDateRange = styled(DateRange)`
  &&& > h2 {
    display: none;
  }
`;

const CustomCalendar = styled(DateRangePicker)`
  .react-daterange-picker__wrapper {
    visibility: hidden;
    max-height: 0px;
  }

  abbr {
    font-weight: lighter;
  }

  .react-calendar__navigation {
    .react-calendar__navigation__arrow {
      display: none;
    }
  }

  .react-calendar__tile--active {
    background-color: ${(props) => props.theme.colors.primaryOpacity};
  }

  .react-calendar__tile--rangeStart {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 0px;
    max-width: 36px !important;

    abbr {
      font-weight: bold;
      color: ${(props) => props.theme.colors.white};
    }
  }

  .react-daterange-picker__calendar .react-calendar {
    background-color: ${(props) => props.theme.colors.cardBackground};
    color: ${(props) => props.theme.colors.white};
  }
  .react-calendar__tile--rangeEnd {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 0px;
    max-width: 36px !important;

    abbr {
      font-weight: bold;
      color: ${(props) => props.theme.colors.white};
    }
  }

  .react-calendar__navigation button[disabled] {
    display: none;
  }

  .react-calendar__tile:disabled {
    background-color: ${(props) => props.theme.colors.cardBackground};
    color: ${(props) => props.theme.colors.charcoal} !important;
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: ${(props) => props.theme.colors.primary} !important;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: ${(props) => props.theme.colors.primary} !important;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: ${(props) => props.theme.colors.primary} !important;
  }

  .react-calendar__navigation button,
  .react-calendar__navigation button {
    width: fit-content;
    height: fit-content;
    margin: 4px;
  }

  &&& .react-calendar__year-view__months__month {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
  }

  & button {
    max-width: fit-content;
    background-color: ${(props) => props.theme.colors.cardBackground};
    color: ${(props) => props.theme.colors.primaryText} !important;
    border-radius: 0px;
    border: none;
    height: 36px;
    margin: 4px 0px;
  }
`;

const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavBarButtonsContainer = styled.div`
  display: flex;
`;

const HiddenIcon = styled.div`
  display: none;
`;

const CustomDateRangeIcon = styled(DateRangeIcon)`
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const CalendarFilter = ({
  filterState,
  setFilterState,
  filterList = [],
  hide,
  query,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [dirty, setIsDirty] = useState(false);
  const [tempCalendarValue, setTempCalendarValue] = useState(
    filterState.TimeFilter,
  );

  const safeFilterList = filterList.filter((x) => x !== 'TimeFilter');
  const startDate = filterState.TimeFilter.start;
  const endDate = filterState.TimeFilter.end;
  const isSameYear = dayjs(startDate).year() === dayjs(endDate).year();
  const displayDate = isSameYear
    ? `${dayjs(filterState.TimeFilter && filterState.TimeFilter.start).format(
        'MM/DD',
      )}-${dayjs(filterState.TimeFilter && filterState.TimeFilter.end).format(
        'MM/DD YYYY',
      )}`
    : `${dayjs(filterState.TimeFilter && filterState.TimeFilter.start).format(
        'MM/DD YYYY',
      )}-${dayjs(filterState.TimeFilter && filterState.TimeFilter.end).format(
        'MM/DD YYYY',
      )}`;

  const setCalendarState = (dates) => {
    const newDates = { start: dates[0], end: dates[1] };
    setTempCalendarValue(newDates);
    setIsDirty(false);
    setFilterState({
      ...filterState,
      TimeFilter: newDates,
    });
  };

  const setCalendarStateFromInput = () => {
    const newDates = {
      start: new Date(dayjs(tempCalendarValue.start, 'YYYY-MM-DD')),
      end: new Date(dayjs(tempCalendarValue.end, 'YYYY-MM-DD')),
    };
    if (dayjs(newDates.start).isValid() && dayjs(newDates.end).isValid()) {
      setIsDirty(false);
      setFilterState({
        ...filterState,
        TimeFilter: newDates,
      });
    }
  };

  const handleTempDateChange = (value, key) => {
    setIsDirty(true);
    setTempCalendarValue({ ...tempCalendarValue, ...{ [key]: value } });
  };

  useEffect(() => {
    if (tempCalendarValue) {
      setTempCalendarValue(filterState.TimeFilter);
      setIsDirty(false);
    }
  }, [filterState.TimeFilter]);

  return (
    <DateContainer
      style={hide ? { maxHeight: '0px', visibility: 'hidden' } : null}
    >
      <DateButton
        className="DateButton"
        isSameYear={isSameYear}
        customChild
        onClick={() => setShowCalendar(!showCalendar)}
      >
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DateRangeIcon />
          <p style={{ color: 'inherit' }} className="no-margin">
            {displayDate}
          </p>
        </span>
      </DateButton>
      <DateFilterContainer showCalendar={showCalendar}>
        <InlineDateFilter
          setFilterState={setFilterState}
          filterState={filterState}
          setShowCalendar={setShowCalendar}
        />
        <CustomCalendar
          clearIcon={null}
          required
          locale="en"
          isOpen={showPicker}
          onCalendarClose={() => setShowPicker(false)}
          maxDate={new Date()}
          value={[filterState.TimeFilter.start, filterState.TimeFilter.end]}
          onChange={setCalendarState}
          navigationLabel={({ date, label, locale, view }) => {
            return (
              <div>
                <h3>{dayjs(date).format('MMMM YYYY')}</h3>
              </div>
            );
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <Input
              label="Start Date"
              filterModalOpen={showCalendar}
              inputProps={{
                onSubmit: setCalendarStateFromInput,
                value: dirty
                  ? tempCalendarValue.start
                  : dayjs(tempCalendarValue.start).format('YYYY-MM-DD'),
                onChange: (e) => handleTempDateChange(e.target.value, 'start'),
              }}
            />
            <Input
              label="End Date"
              filterModalOpen={showCalendar}
              inputProps={{
                onSubmit: setCalendarStateFromInput,
                value: dirty
                  ? tempCalendarValue.end
                  : dayjs(tempCalendarValue.end).format('YYYY-MM-DD'),
                onChange: (e) => handleTempDateChange(e.target.value, 'end'),
              }}
            />
          </div>
          <CustomDateRangeIcon onClick={() => setShowPicker(true)} />
        </div>
        <StyledDateRange
          style={{ maxHeight: '0px', visibility: 'hidden' }}
          componentId="TimeFilter"
          filterType="DateRange"
          react={{ and: safeFilterList }}
          value={filterState.TimeFilter}
          onChange={(value, triggerQuery) => {
            const newFilterState = {
              ...filterState,
              ...{ TimeFilter: value },
            };
            setFilterState(newFilterState);
          }}
          dataField="timestamp"
          title="Date Range"
          focused={false}
          showSearch={true}
          URLParams={true}
          showOnDashboard={true}
          placeholder={{
            start: 'Start Date',
            end: 'End Date',
          }}
          customQuery={query || dashboardDatesQuery}
          defaultQuery={query || dashboardDatesQuery}
          showClear={false}
          showFilter={true}
          filterLabel="Date"
        />
      </DateFilterContainer>
    </DateContainer>
  );
};

export default CalendarFilter;
