import React, { useContext, useState, useEffect } from 'react';
import { docsQuery } from '../../../../utils/Queries/CustomQueries';
import Modal from '../../../Modals/Modal/Modal.js';
import Loader from '../../../Spinners/Loader';
import styled from 'styled-components';
import HighlightComponent from '../HighlightComponent';
import { StateContext } from '../../../Search/StateProviderContext/StateProviderContext'

const ModalBody = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.cardBackground};
  overflow-y: auto;
`;

const DocsModal = (props) => {
  const { sourceHash, setShowDocsModal, title } = props;
  const { apiUrl, apiKey } = useContext(StateContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchDoc = async () => {
      const resp = await fetch(`${apiUrl}/_search`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${apiKey.key}`,
        },
        method: 'POST',
        body: JSON.stringify(docsQuery(sourceHash)),
      });

      const respData = await resp.json();
      setData(respData.hits.hits);
    };

    fetchDoc();
    setLoading(false);
  }, []);

  return (
    <Modal
      open
      onClickOutside={() => setShowDocsModal(false)}
      style={{
        maxWidth: '80%',
        maxHeight: '80%',
        padding: '0px 16px',
      }}
    >
      <ModalBody>
        {loading === false && data.length > 0 ? (
          <>
            <h1>
              <HighlightComponent text={title} />
            </h1>
            <p>
              <HighlightComponent
                text={data
                  .sort((a, b) => {
                    return a._source.doc.segmentId - b._source.doc.segmentId;
                  })
                  .map((item) => {
                    return item._source.text;
                  })
                  .join('')}
              />
            </p>
          </>
        ) : (
          <Loader />
        )}
      </ModalBody>
    </Modal>
  );
};
export default DocsModal;
