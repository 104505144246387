import React from 'react';

const CustomFeaturePositioner = ({
  position = 'top-left',
  styles = {},
  children,
  ...rest
}) => {
  const backgroundStyles = {
    boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
  };
  let positionStyles = {};

  switch (position) {
    case 'top-left': {
      positionStyles = {
        position: 'absolute',
        top: 0,
        left: 0,
        margin: '10px 0 0 10px',
      };
      return (
        <div
          {...rest}
          style={{ ...backgroundStyles, ...positionStyles, ...styles }}
        >
          {children}
        </div>
      );
    }
    case 'top-middle': {
      positionStyles = {
        position: 'absolute',
        top: 0,
        margin: '10px auto 0 auto',
      };
      return (
        <div
          {...rest}
          style={{ ...backgroundStyles, ...positionStyles, ...styles }}
        >
          {children}
        </div>
      );
    }
    case 'top-right': {
      positionStyles = {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: '10px 10px 0 0',
      };
      return (
        <div
          {...rest}
          style={{ ...backgroundStyles, ...positionStyles, ...styles }}
        >
          {children}
        </div>
      );
    }
    case 'middle-right': {
      positionStyles = {
        position: 'absolute',
        top: '50%',
        bottom: '50%',
        right: 0,
        margin: '0 10px 0 0',
      };
      return (
        <div
          {...rest}
          style={{ ...backgroundStyles, ...positionStyles, ...styles }}
        >
          {children}
        </div>
      );
    }
    case 'bottom-right': {
      positionStyles = {
        position: 'absolute',
        bottom: 0,
        right: 0,
        margin: '0 10px 35px 0',
      };
      return (
        <div
          {...rest}
          style={{ ...backgroundStyles, ...positionStyles, ...styles }}
        >
          {children}
        </div>
      );
    }
    case 'bottom-middle': {
      positionStyles = {
        position: 'absolute',
        bottom: 0,
        margin: '0 auto 35px auto',
      };
      return (
        <div style={{ ...backgroundStyles, ...positionStyles, ...styles }}>
          {children}
        </div>
      );
    }
    case 'bottom-left': {
      positionStyles = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        margin: '0 0 35px 10px',
      };
      return (
        <div style={{ ...backgroundStyles, ...positionStyles, ...styles }}>
          {children}
        </div>
      );
    }
    case 'middle-left': {
      positionStyles = {
        position: 'absolute',
        top: '50%',
        bottom: '50%',
        left: 0,
        margin: '0 0 0 10px',
      };
      return (
        <div style={{ ...backgroundStyles, ...positionStyles, ...styles }}>
          {children}
        </div>
      );
    }
    default: {
      positionStyles = {
        position: 'absolute',
        top: 0,
        left: 0,
        margin: '10px 0 0 10px',
      };
      return (
        <div style={{ ...backgroundStyles, ...positionStyles, ...styles }}>
          {children}
        </div>
      );
    }
  }
};

export default CustomFeaturePositioner;
