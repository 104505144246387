import React, { Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const Container = styled.div`
  padding: 6px 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  /* & > span {
    padding-bottom: 12px;
  };

  & > span: last-child {
    padding-bottom: 0px;
  }; */
`;

const Symbol = styled.i`
  width: 15px;
  height: 15px;
  float: left;
  margin-right: 8px;
  margin-top: 3px;
  border-radius: 10px;
  background-color: ${(props) => props.bgColor};
`;

const Legend = ({ dataCategories }) => {
  return dataCategories.length > 0 ? (
    <Container>
      {dataCategories.map(({ path, displayText, color }) => {
        return (
          <Fragment key={path}>
            <span>
              <Symbol bgColor={color} />
              {displayText}
            </span>
          </Fragment>
        );
      })}
    </Container>
  ) : null;
};

export default Legend;
