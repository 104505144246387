import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Loader from '../Spinners/Loader';
import DarkBlueCard from '../../darkblue-ui/composite/DarkBlueCard/DarkBlueCard';
import { StateContext } from '../Search/StateProviderContext/StateProviderContext.js';
import Pagination from '../Pagination/Pagination.js';

const NoResultsText = styled.h2`
  color: ${(props) => props.theme.colors.cardTitle};
  text-align: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Results = ({
  filterNames,
  dataset,
  selectors,
  showPadding,
  listSize = 25,
  setResultStats,
  setResultCount,
  hits = [],
  layoutLogo,
}) => {
  const {
    setFilterState,
    filterState,
    page,
    setPage,
    totalHits,
    size,
    loading,
    errorFetching,
    timeTaken,
    relation,
  } = useContext(StateContext);

  const filterStateIsEmpty = () => {
    return Object.keys(filterState).length === 0;
  };

  const data = hits.map((h) => h._source);

  const renderErrorStates = () => {
    if (errorFetching && !filterStateIsEmpty()) {
      return (
        <h1>
          Trouble retrieving data. Try refreshing, and if the problem persists
          contact support at{' '}
          <a href="mailto:support@bluestoneanalytics.caci.com">
            support@bluestoneanalytics.caci.com
          </a>
        </h1>
      );
    } else if (data.length === 0 && !filterStateIsEmpty()) {
      return (
        <NoResultsText>
          No results found! Try expanding your search parameters and search
          again.
        </NoResultsText>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      style={
        showPadding
          ? { paddingBottom: '24px' }
          : { paddingLeft: '0px', height: '100%', paddingBottom: '24px' }
      }
    >
      {loading ? (
        <LogoContainer>
          <Loader style={{ margin: '0 auto' }} />
        </LogoContainer>
      ) : (
        <div style={{ height: '100%' }}>
          {renderErrorStates()}
          {data.length ? (
            <Pagination
              page={page}
              setPage={setPage}
              size={size}
              totalHits={totalHits}
            />
          ) : null}
          {data.map((item) => {
            return item.doc ? (
              <DarkBlueCard
                setFilterState={setFilterState}
                loading={item.loading}
                filterState={filterState}
                key={item.doc.idHash}
                data={item}
                dataset={dataset}
                index={item.doc.type}
              />
            ) : null;
          })}
          {data.length ? (
            <Pagination
              page={page}
              setPage={setPage}
              size={size}
              totalHits={totalHits}
              bottom
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Results;
