import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Formik, ErrorMessage } from 'formik';
import { UserConsumer } from '../../DBAuthenticator.js';
import { useSnackbar } from 'notistack';

import Button from '../Button/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loader from '../Spinners/Loader';
import Input from '../Inputs/Input';
import { tooltip } from 'assets/jss/material-dashboard-pro-react.js';
import Checkbox from '../Inputs/Checkbox.js';

// Material UI components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Backdrop from '@material-ui/core/Backdrop';
import * as Yup from 'yup';

import dayjs from 'dayjs';
const apiHost = 'https://' + process.env.REACT_APP_API_HOST;

const TooltipText = styled.h4`
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
`;

const ModalContainer = styled(GridContainer)`
  background-color: ${(props) => props.theme.colors.cardBackground};
  padding: 12px 24px;
  border-radius: 0px;
  z-index: 999;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &&& {
    color: ${(props) => props.theme.colors.primaryText};
    & input {
      color: ${(props) => props.theme.colors.primaryText};
    }
  }
`;

const CenteredWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
  z-index: 999;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
`;

const styles = { ...tooltip };
const useStyles = makeStyles(styles);

const SaveModal = ({
  setShowSaveModal,
  showSaveModal,
  setSearchSaved,
  docId,
  saveType,
  searchQueryString,
  dataset,
  filterState,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant) => {
    enqueueSnackbar(`${saveType} saved!`, { variant });
  };

  const handleModalClose = () => {
    setShowSaveModal(false);
  };

  const modalMapping = {
    record: {
      label: 'Save Record',
      showNotes: true,
      showTime: false,
    },
    search: {
      label: 'Save Search',
      showNotes: true,
      showTime: true,
    },
  };

  const modalValues = modalMapping[saveType];
  return (
    <UserConsumer>
      {(userSess) => {
        return showSaveModal ? (
          <Formik
            initialValues={{
              searchName: '',
              timeCheck: false,
              notificationCheck: false,
              note: '',
              docId: docId ? docId : '',
            }}
            validationSchema={Yup.object().shape({
              searchName: Yup.string().required('Name is required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const saveItem = async () => {
                const qs =
                  saveType === 'record'
                    ? `DocIdFilter=%7B"value"%3A"${values.docId}"%7D`
                    : encodeURI(window.location.search);
                const path = window.location.pathname;
                var searchParams = new URLSearchParams(qs);

                if (!values.timeCheck || values.notificationCheck) {
                  searchParams.delete('DateTimeFilter');
                }

                // URLSearchParams encodes spaces into +'s, so replace with the encoded space character
                let storeQs =
                  '?' + searchParams.toString().replace(/\+/g, '%20');
                var dateStr = dayjs().format('YYYY-MM-DD HH:mm:ss');

                const savedSearch = {
                  doc: {
                    created: dateStr,
                    type: saveType,
                  },
                  timestamp: dateStr,
                  dataset: dataset,
                  endpoint: path,
                  alert: {
                    query: searchQueryString,
                    enabled: values.notificationCheck,
                    hits: 0,
                  },
                  name: values.searchName,
                  note: values.note,
                  query: btoa(storeQs),
                };
                const userIndex = '.user_' + userSess.user.username;
                const userApi = 'ApiKey ' + userSess.apiKey.key;
                try {
                  let response = await fetch(`${apiHost}/${userIndex}/_doc/`, {
                    method: 'POST',
                    headers: {
                      Authorization: userApi,
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(savedSearch),
                  });
                  let data = await response.json();

                  if (data?.error) {
                    enqueueSnackbar('Something went wrong', {
                      variant: 'error',
                    });
                  }
                  handleClickVariant('success');
                } catch (err) {
                  enqueueSnackbar('Something went wrong', { variant: 'error' });
                }
              };
              saveItem();
              setShowSaveModal(false);
              setSearchSaved(true);
              setSubmitting(true);
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) =>
              isSubmitting ? (
                <Backdrop
                  style={{ zIndex: '998' }}
                  open={showSaveModal}
                  onClick={handleModalClose}
                >
                  <CenteredWrapper>
                    <Loader color="#2c6982" />
                  </CenteredWrapper>
                </Backdrop>
              ) : (
                <>
                  <Backdrop
                    style={{ zIndex: 10 }}
                    open={showSaveModal}
                    onClick={handleModalClose}
                  />
                  <CenteredWrapper>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                      <ModalContainer direction="column">
                        <GridItem>
                          <h3>{modalValues.label}</h3>
                        </GridItem>
                        <GridItem style={{ marginBottom: '10px' }}>
                          <Input
                            id="searchName"
                            name="searchName"
                            inputProps={{
                              value: values.searchName,
                              onChange: handleChange,
                            }}
                            label="Name"
                            type="text"
                            fullWidth={true}
                          />
                          <ErrorMessage
                            component="div"
                            style={{ color: 'red' }}
                            name="searchName"
                            className="invalid-feedback"
                          />
                        </GridItem>
                        {modalValues.showNotes && (
                          <GridItem style={{ marginBottom: '10px' }}>
                            <Input
                              id="notesName"
                              name="note"
                              label="Description (Optional)"
                              inputProps={{
                                onChange: handleChange,
                                value: values.notesValue,
                              }}
                            />
                          </GridItem>
                        )}
                        {modalValues.showTime &&
                          filterState['DateTimeFilter'] &&
                          !values.notificationCheck && (
                            <>
                              <GridItem>
                                <Tooltip
                                  id="time-tooltip"
                                  title={
                                    <TooltipText>
                                      Save current time range as part of search.
                                    </TooltipText>
                                  }
                                  placement="left"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <DialogContent>
                                    <StyledFormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={handleChange}
                                          value={values.timeCheck}
                                          name="timeCheck"
                                          active={values.timeCheck}
                                        />
                                      }
                                      label="Save time range"
                                    />
                                  </DialogContent>
                                </Tooltip>
                              </GridItem>
                            </>
                          )}
                        {saveType === 'search' ? (
                          <GridItem>
                            <DialogContent>
                              <StyledFormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleChange}
                                    value={values.notificationCheck}
                                    name="notificationCheck"
                                    active={values.notificationCheck}
                                  />
                                }
                                label="Show me alerts when new records are added"
                              />
                            </DialogContent>
                          </GridItem>
                        ) : null}
                        <GridItem>
                          <DialogActions>
                            <Button
                              style={{ padding: '10px 20px' }}
                              color="success"
                              type="submit"
                            >
                              {modalValues.label}
                            </Button>
                            <Button
                              style={{ padding: '10px 20px' }}
                              color="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowSaveModal(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </DialogActions>
                        </GridItem>
                      </ModalContainer>
                    </form>
                  </CenteredWrapper>
                </>
              )
            }
          </Formik>
        ) : null;
      }}
    </UserConsumer>
  );
};

export default SaveModal;
