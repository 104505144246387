import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import Refresh from '@material-ui/icons/Refresh';

import DarkHostTable from './DarkHostTable';
import ServerModalForm from './ServerModalForm';

import Button from '../../darkblue-ui/Button/Button';
import { UserContext } from '../../DBAuthenticator';
import {
  getRunningServers,
  mockRunningServers,
  mockServerStatus,
} from './utils';

const TitleContainer = styled.div`
  display: flex;
`;

const DarkHostViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  
  button.new-server {
    margin-bottom: 24px;
  }
`;

const DarkHost = (props) => {
  const [data, setData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [modalType, setModalType] = useState('');
  const user = useContext(UserContext);
  const { apiKey } = user;
  const { enqueueSnackbar } = useSnackbar();
  const [currentServerId, setCurrentServerId] = useState('');
  const [refreshBtnDisabled, setRefreshBtnDisabled] = useState(false);

  // Fetch DarkHost Data
  const getServers = async () => {
    setRefreshBtnDisabled(true);
    try {
      const runningServers = await getRunningServers(apiKey);
      const formattedRunningServers = runningServers.map((server) => {
        return {
          serverId: server.id,
          address: server.tags.hiddenService,
          files: '',
          server_info: {
            os: server.tags.os,
            size: server.tags.size,
            storage: server.tags.storage,
            tags: {
              ...server.tags,
            },
          },
          status: server.status,
        };
      });

      setData(formattedRunningServers);
      setRefreshBtnDisabled(false);
    } catch (error) {
      setRefreshBtnDisabled(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    // Get server data on first render
    getServers();
  }, []);

  const toggleModal = (event, overrideOptions = {}) => {
    if (event === 'OVERRIDE') {
      setModalType(overrideOptions.type);
      setCurrentServerId(overrideOptions.serverId);
      setShowForm(!showForm);
    } else if (typeof event === 'string') {
      setModalType(event);
      setShowForm(!showForm);
    } else if (event?.target.value) {
      setModalType(event?.target.value);
      setShowForm(!showForm);
    } else if (event?.currentTarget.value) {
      setModalType(event?.currentTarget.value);
      setShowForm(!showForm);
    } else {
      setShowForm(!showForm);
    }
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <TitleContainer>
        <h1>DarkHost</h1>
        <h1>&nbsp;</h1>
        <h1 className="lighter italic">Dashboard</h1>
      </TitleContainer>

      <DarkHostViewContainer>
        <Button
          style={{
            padding: '15px 35px',
            margin: data.length > 0 ? '70px 0 100px 0' : '40vh 0 100px 0',
            width: 'fit-content',
            height: 'fit-content',
          }}
          value="create"
          className="base new-server"
          onClick={toggleModal}
          justIcon
        >
          <p>Create a DarkHost Server</p>
        </Button>
        {data.length > 0 && (
          <>
            <DarkHostTable
              toggleModal={toggleModal}
              data={data}
              apiKey={apiKey}
              setCurrentServerId={setCurrentServerId}
              getServers={getServers}
              user={user}
            />
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                height: 'fit-content',
                margin: '50px 0px',
              }}
              onClick={async () => {
                try {
                  await getServers();
                  enqueueSnackbar('Servers refreshed', {
                    variant: 'success',
                    autoHideDuration: 2000,
                    transitionDuration: { enter: 300, exit: 1000 },
                  });
                } catch (error) {
                  enqueueSnackbar('Something went wrong', { variant: 'error' });
                }
              }}
              justIcon
              disabled={refreshBtnDisabled}
            >
              <p>Refresh server list</p>
              <Refresh style={{ marginLeft: '5px' }} />
            </Button>
          </>
        )}
      </DarkHostViewContainer>
      <ServerModalForm
        apiKey={apiKey}
        type={modalType}
        open={showForm}
        openModal={toggleModal}
        onClickOutside={toggleModal}
        onClose={toggleModal}
        toggleModal={toggleModal}
        currentServerId={currentServerId}
        getServers={getServers}
        currentServer={
          data.filter(({ serverId }) => serverId === currentServerId)[0]
        }
      />
    </div>
  );
};

export default DarkHost;
