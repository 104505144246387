import React, { useState, useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import bodybuilder from 'bodybuilder';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import Pie from '@visx/shape/lib/shapes/Pie';
import { Group } from '@visx/group';
import { withParentSize } from '@visx/responsive';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { fetchData } from '../../../../darkblue-ui/Search/utils';
import { colorChartItem } from 'darkblue-ui/styles/colors/utils';
import Loader from 'darkblue-ui/Spinners/Loader';

const MouseOverPath = styled.path`
  &:hover {
    cursor: pointer;
  }
`;

const PieChart = ({
  yAccessor,
  data = [],
  parentWidth,
  parentHeight,
  currentTheme,
  dashboardFilters,
}) => {
  const history = useHistory();
  const margin = { top: 20, right: 20, bottom: 20, left: 20 };
  const innerWidth = parentWidth - margin.left - margin.right;
  const innerHeight = parentHeight - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight / 2);
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const handleMouseOver = (event, datum) => {
    const label = event.target.dataset.label;
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: label,
    });
  };

  const handleTimeFilter = () => {
    const { startValue, endValue } = dashboardFilters?.DateTimeFilter?.value;
    const start = startValue ? dayjs(startValue) : dayjs().subtract(7, 'day');
    const end = endValue ? dayjs(endValue) : dayjs();

    const DateTimeFilterValue = {
      value: {
        startValue: start.format('YYYY-MM-DD HH:mm:ss'),
        endValue: end.format('YYYY-MM-DD HH:mm:ss'),
      },
      elasticPath: ['timestamp'],
    };

    return encodeURIComponent(JSON.stringify(DateTimeFilterValue));
  };

  return (
    <svg ref={containerRef} width={parentWidth} height={parentHeight}>
      <rect
        rx={14}
        width={parentWidth}
        height={parentWidth}
        fill="url('#visx-pie-gradient')"
      />
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={data}
          pieValue={yAccessor}
          outerRadius={radius}
          cornerRadius={3}
          padAngle={0.005}
        >
          {(pie) => {
            return pie.arcs.map((arc, i) => {
              const arcPath = pie.path(arc);
              const arcFill = colorChartItem(currentTheme, arc.data.x, i);
              const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
              const [centroidX, centroidY] = pie.path.centroid(arc);

              return (
                <g key={`arc-${arc.data.x}-${arc.data.y}`}>
                  <MouseOverPath
                    onClick={(e) => {
                      const value = e.target.dataset.label;
                      const path = `/search/data?SiteNetFilter=${encodeURIComponent(
                        JSON.stringify({ value: [value] }),
                      )}&DateTimeFilter=${handleTimeFilter()}`;
                      history.push(path);
                    }}
                    data-label={arc.data.x}
                    onMouseOver={handleMouseOver}
                    onMouseOut={hideTooltip}
                    d={arcPath}
                    fill={arcFill}
                  />
                  {tooltipOpen && (
                    <TooltipInPortal
                      // set this to random so it correctly updates with parent bounds
                      key={Math.random()}
                      top={tooltipTop}
                      left={tooltipLeft}
                    >
                      Site Name: <strong>{tooltipData}</strong>
                    </TooltipInPortal>
                  )}
                </g>
              );
            });
          }}
        </Pie>
      </Group>
    </svg>
  );
};

const SitesDistro = ({ apiKey, additionalFilters, dashboardFilters }) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const SiteDistroChart = withParentSize(PieChart);
  const currentTheme = useContext(ThemeContext);
  useEffect(() => {
    const fetchTerms = async () => {
      let body = bodybuilder();

      body
        .size(0)
        .query('range', 'timestamp', { gte: 'now-60d', lte: 'now' })
        .agg('terms', 'site.net.keyword', 'SitesDistro');

      setLoading(true);

      const resp = await fetchData({
        query: body.build(),
        apiKey,
        index: ['sites'],
      });

      const respData = await Promise.all(resp.aggregations.SitesDistro.buckets.map((bucket) => {
          return { x: bucket.key, y: bucket['doc_count'] };
        }))

      const result = respData.reduce((recordCount, record) => recordCount = recordCount + record.y, 0)
      
      respData.forEach((record) => {
        record.x = `${record.x} (${Math.round((record.y / result) * 100, 0)}%)`;
      })

      setTotal(result);
      setData(respData);
      
      setLoading(false);

    };

    fetchTerms();

  }, [additionalFilters]);

  // useEffect(() => {
    
  // }, [data])
  
  
  return (
    <div style={{ height: '200px' }}>
      {!loading && data ? (
        <SiteDistroChart
          currentTheme={currentTheme}
          data={data}
          xAccessor={(d) => d.x}
          yAccessor={(d) => d.y}
          dashboardFilters={dashboardFilters}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SitesDistro;
