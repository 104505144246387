import React from 'react';
import _ from 'lodash';
import DOMPurify from 'dompurify';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import * as ProfileCard from '../../darkblue-consts/CardMappings/Profiles';
import * as SiteCard from '../../darkblue-consts/CardMappings/Sites';
import * as CommCard from '../../darkblue-consts/CardMappings/Comms';
import * as ProductCard from '../../darkblue-consts/CardMappings/MarketProducts';
import * as VendorCard from '../../darkblue-consts/CardMappings/MarketVendors';
import * as ReviewCard from '../../darkblue-consts/CardMappings/MarketReviews';
import * as DocsCard from '../../darkblue-consts/CardMappings/Docs';
import * as LocalBitcoinCard from '../../darkblue-consts/CardMappings/LocalBitcoin';
import * as LocalBitcoinMessageCard from '../../darkblue-consts/CardMappings/LocalBitcoinMessages';
import * as LocalBitcoinTransactionCard from '../../darkblue-consts/CardMappings/LocalBitcoinTransaction';
import * as LocalBitcoinAdvertisementCard from '../../darkblue-consts/CardMappings/LocalBitcoinAdvertisement';
import * as CommsDict from 'darkblue-consts/DataDictionary/Comms';
import * as ProfilesDict from 'darkblue-consts/DataDictionary/Profiles';
import * as MarketsDict from 'darkblue-consts/DataDictionary/Markets';
import * as SitesDict from 'darkblue-consts/DataDictionary/Sites';
import * as DocDict from 'darkblue-consts/DataDictionary/Docs';
import * as LBCDict from 'darkblue-consts/DataDictionary/LBC';
import * as LBCMessageDict from 'darkblue-consts/DataDictionary/LBCMessages';
import * as LBCTransactionDict from 'darkblue-consts/DataDictionary/LBCTransactions';
import * as LBCAdvertisementDict from 'darkblue-consts/DataDictionary/LBCAdvertisement';

DOMPurify.addHook('uponSanitizeElement', function(node, data) {
  if (data.tagName === 'input' || data.tagName === 'button') {
    var attrs = node.attributes;
    if (attrs && 'type' in attrs && attrs.type.nodeValue === 'submit') {
      node.attributes.type.nodeValue = 'button';
    }
  }
  return node;
});

const purifySnapshot = (snapshot) => {
  const clean = DOMPurify.sanitize(snapshot, {
    ADD_TAGS: ['meta', 'head', 'link'],
    ADD_ATTR: ['content'],
    FORBID_TAGS: ['video', 'script', 'a'],
    FORBID_ATTR: ['href', 'src', 'srcset', 'longdesc', 'ping'],
    USE_PROFILES: { html: true },
    SANITIZE_DOM: false,
    FORCE_BODY: true,
    KEEP_CONTENT: true,
    WHOLE_DOCUMENT: true,
    ALLOW_DATA_ATTR: false,
    // RETURN_DOM_FRAGMENT: true
  });

  return clean;
};

const flattenDataForCSV = (object) => {
  const result = {};
  const removeMark = new RegExp('(<mark>|</mark>)', 'g');

  const flatten = (obj, prefix = '') => {
    _.forEach(obj, (value, key) => {
      if (_.isObject(value)) {
        flatten(value, `${prefix}${key}.`);
      } else if (key !== 'snapshot' && value) {
        result[`${prefix}${key}`] = String(value);
      } else if (value) {
        result[`${prefix}${key}`] = String(value).replace(removeMark, '');
      }
    });
  };

  flatten(object);

  return result;
};

const plainToFlattenWithComponents = (object) => {
  const result = {};
  const removeMark = new RegExp('(<mark>|</mark>)', 'g');

  const flatten = (obj, prefix = '') => {
    _.forEach(obj, (value, key) => {
      if (_.isObject(value)) {
        flatten(value, `${prefix}${key}.`);
      } else if (key !== 'snapshot' && value) {
        result[`${prefix}${key}`] = <p>{String(value)}</p>;
      } else if (value) {
        result[`${prefix}${key}`] = String(value).replace(removeMark, '');
      }
    });
  };

  flatten(object);

  return result;
};

/**
 * @function utils
 * A function that renders a UI snackbar on the screen.
 * @param  {string} item - The snack bar item presentation.
 * @param  {string} variant - Snackbar variant type value.
 */
const triggerSnackbarVariant = (item, variant, enqueueSnackbar) => {
  enqueueSnackbar(item + ' already exists in filter', { variant });
};

const removeMarks = (value) => {
  if (Array.isArray(value)) {
    const cleanValues = value.map((item) => removeMarks(item));
    return cleanValues;
  } else if (typeof value === 'string') {
    const cleanValue = value
      .replace(/\<mark\>/g, '')
      .replace(/\<\/mark\>/g, '');
    return cleanValue;
  } else {
    return value;
  }
};

const handleChartFilterOn = ({ filterState, setFilterState, newValues }) => {
  const newFilterState = { ...filterState, ...newValues };
  setFilterState(newFilterState);
};

const handleFilterOn = ({
  filterState,
  filterName,
  filterValueType,
  filterValue,
  onClick,
  enqueueSnackbar,
  query,
  elasticPath,
}) => {
  const cleanFilterValue = removeMarks(filterValue);
  let newState = {};
  if (filterValueType === 'array') {
    if (
      filterState[filterName] &&
      filterState[filterName].value.indexOf(cleanFilterValue) >= 0
    ) {
      triggerSnackbarVariant(cleanFilterValue, 'warning', enqueueSnackbar);
    } else {
      const safeFilterValue = filterState[filterName] || [];
      newState = {
        [filterName]: {
          value: [
            ...safeFilterValue,
            ...(Array.isArray(cleanFilterValue)
              ? cleanFilterValue
              : [cleanFilterValue]),
          ],
          query,
          elasticPath,
        },
      };
    }
  } else if (filterName && filterState && filterValueType === 'string') {
    let newString =
      (filterState[filterName] && filterState[filterName].value) || '';
    if (newString.indexOf(cleanFilterValue) >= 0) {
      triggerSnackbarVariant(cleanFilterValue, 'warning', enqueueSnackbar);
    } else {
      newString = newString + ' ' + cleanFilterValue;
      newState = {
        [filterName]: { value: newString, query, path: elasticPath },
      };
    }
  }
  onClick({ ...filterState, ...newState });
};

const handleFilterOut = ({
  filterState,
  filterValue,
  onClick,
  elasticPath,
}) => {
  const cleanFilterValue = removeMarks(filterValue);
  let newFilterState = {};
  const currentFilterState = Array.isArray(filterState?.NotFilter?.value)
    ? filterState.NotFilter.value
    : [];
  newFilterState = {
    NotFilter: {
      value: [
        ...currentFilterState,
        {
          value: cleanFilterValue,
          elasticPath,
        },
      ],
    },
  };

  if (onClick) {
    onClick({ ...filterState, ...newFilterState });
  } else {
    console.log(filterValue);
  }
};

/**
 * @function utils
 * A function that sets the requred filter state.
 * @param  {obj} filterState The filter state object.
 * @param  {func} onClick a click event listener callback function.
 * @param  {string} elasticPath The required field string.
 */
const handleRequiredFilter = ({
  filterState,
  onClick,
  elasticPath,
  enqueueSnackbar,
}) => {
  let newFilterState = {};
  const requiredFilter = Array.isArray(filterState?.RequiredFieldFilter?.value)
    ? filterState.RequiredFieldFilter.value
    : [];

  if (filterState?.RequiredFieldFilter?.value.includes(elasticPath)) {
    triggerSnackbarVariant(elasticPath, 'warning', enqueueSnackbar);
  } else {
    newFilterState = {
      RequiredFieldFilter: {
        value: [...requiredFilter, elasticPath],
      },
    };
  }

  if (onClick) {
    onClick({ ...filterState, ...newFilterState });
  }
};

const findSelectors = (selectors, data) => {
  return Object.entries(data).filter(([key, value]) => {
    return selectors.find((el) => el === key) && value;
  });
};

const indexToData = (index) => {
  switch (index) {
    case 'profile':
      return ProfileCard;
    case 'site':
    case 'sites':
      return SiteCard;
    case 'chat_post':
    case 'forum_post':
    case 'vichan_post':
    case 'paste_post':
    case 'chan':
    case 'paste':
    case 'forum':
    case 'chat':
      return CommCard;
    case 'product':
      return ProductCard;
    case 'vendor':
      return VendorCard;
    case 'review':
      return ReviewCard;
    case 'document':
      return DocsCard;
    case 'user':
      return LocalBitcoinCard;
    case 'message':
      return LocalBitcoinMessageCard;
    case 'transaction':
      return LocalBitcoinTransactionCard;
    case 'advertisement':
      return LocalBitcoinAdvertisementCard;
    default:
      return ProfileCard;
  }
};

/**
 * @function utils
 * Orders array of objects by displayName and path alphabeticaly.
 * @param  {obj} objA - A obj to be compared by objB
 * @param  {obj} objB - a obj to compare to objA
 * @returns {int}
 */
const compareAlphabeticaly = (objA, objB) => {
  const rowA = objA;
  const rowB = objB;

  let comparison = 0;
  if (rowA.displayName && rowB.displayName) {
    if (rowA.displayName > rowB.displayName) {
      comparison = 1;
    } else if (rowA.displayName < rowB.displayName) {
      comparison = -1;
    }
  } else if (rowA.displayName && !rowB.displayName) {
    if (rowA.displayName > rowB.path) {
      comparison = 1;
    } else if (rowA.displayName < rowB.path) {
      comparison = -1;
    }
  } else if (rowB.displayName && !rowA.displayName) {
    if (rowB.displayName > rowA.path) {
      comparison = 1;
    } else if (rowB.displayName < rowA.path) {
      comparison = -1;
    }
  } else {
    if (rowA.path > rowB.path) {
      comparison = 1;
    } else if (rowA.path < rowB.path) {
      comparison = -1;
    }
  }
  return comparison;
};

/**
 * @function utils
 * Orders array of objects by sortKey property.
 * @param  {obj} a - A obj to be compared by objB
 * @param  {obj} b - a obj to compare to objA
 * @returns {int}
 */
const compare = (a, b) => {
  const rowA = a.sortKey;
  const rowB = b.sortKey;

  let comparison = 0;
  if (rowA && rowB) {
    if (rowA > rowB) {
      comparison = 1;
    } else if (rowA < rowB) {
      comparison = -1;
    }
  } else if (rowA && !rowB) {
    comparison = -1;
  } else if (rowB && !rowA) {
    comparison = 1;
  } else if (!rowA && !rowB) {
    comparison = 1;
  }
  return comparison;
};

/**
 * @function utils
 * Sorts a data dictionary of objects numerically and alphabeticaly.
 * @param  {Array<obj>} objA - An array of data objects
 * @returns {Array}
 */
const sortCardFields = (dict = []) => {
  let definedSortedField = dict
    .filter((obj) => obj.hasOwnProperty('sortKey'))
    .sort(compare);
  let alphabeticalSortedField = dict
    .filter((obj) => !obj.hasOwnProperty('sortKey') && obj.path !== '_id')
    .sort(compareAlphabeticaly);

  //Id starts with _ and needs to be pushed as the last item on
  //alphabeticalSortedField sorted list.
  const cardIdField = dict.filter((item) => {
    return item.path === '_id';
  });

  return [...definedSortedField, ...alphabeticalSortedField, ...cardIdField];
};

const indexToDict = (index) => {
  switch (index) {
    case 'profile':
    case 'profiles':
      return ProfilesDict.terms;
    case 'site':
    case 'sites':
      return SitesDict.terms;
    case 'document':
    case 'docs':
      return DocDict.terms;
    case 'chat_post':
    case 'forum_post':
    case 'vichan_post':
    case 'paste_post':
    case 'communications':
    case 'chan':
    case 'paste':
    case 'forum':
    case 'chat':
      return CommsDict.terms;
    case 'product':
    case 'vendor':
    case 'review':
    case 'markets':
      return MarketsDict.terms;
    case 'message':
      return LBCMessageDict.terms;
    case 'transaction':
      return LBCTransactionDict.terms;
    case 'user':
      return LBCDict.terms;
    case 'advertisement':
      return LBCAdvertisementDict.terms;
    default:
      return [];
  }
};

/**
 * This function returns a list of filter names based by the specified index.
 * @param  {string } index index name to filter terms
 * @returns a list of filtered names.
 */
const indexFilterNames = (index) => {
  switch (index) {
    case 'profiles':
      return ProfilesDict.terms;
    case 'sites':
      return SitesDict.terms;
    case 'docs':
      return DocDict.terms;
    case 'communications':
      return CommsDict.terms;
    case 'markets':
      return MarketsDict.terms;
    case 'lbc_messages':
      return LBCMessageDict.terms;
    case 'lbc_transactions':
      return LBCTransactionDict.terms;
    case 'lbc_users':
      return LBCDict.terms;
    case 'lbc_bulletin':
      return LBCAdvertisementDict.terms;
    default:
      return [];
  }
};

const getTableHeaders = (data) => {
  return [];
};

const flattenKeys = (obj, path = []) =>
  !_.isObject(obj)
    ? { [path.join('.')]: obj }
    : _.reduce(
        obj,
        (cum, next, key) => _.merge(cum, flattenKeys(next, [...path, key])),
        {},
      );

const getScansTableData = (dataArray) => {
  return dataArray.map((obj) => flattenKeys(obj));
};

/**
 * @function utils
 * Parse data provied into table data format.
 * @param  {Array<obj>} object -The Array of objects to be parsed into table data.
 */
const getTableData = (data) => {
  const safeData = data || [];
  const getValue = (value) => {
    if ((value && typeof value != 'object') || typeof value === 'boolean') {
      return String(value);
    } else if (value && typeof Object.values(value)[0] != 'object') {
      return Object.values(value)[0];
    } else if (value && Object.values(value)[0] === 'object') {
      return getValue(Object.values(value[1]));
    } else {
      return null;
    }
  };

  const tableData = safeData.map((record) => {
    const recordKvpArray = Object.entries(record);
    return recordKvpArray.map(([key, value]) => {
      if (value && typeof value === 'object') {
        let objKey = Object.keys({ ...value })[0];
        let objValue = Object.values({ ...value })[0];
        return { [`${key}.${objKey}`]: getValue(objValue) };
      } else {
        return { [key]: getValue(value) };
      }
    });
  });

  return tableData.map((row) => row.reduce((r, c) => Object.assign(r, c), {}));
};

/**
 * @function utils
 * Flatten to simple object plain.
 * @param  {obj} object -The object to be flattened.
 */
const plainToFlattenObject = (object) => {
  const result = {};

  const flatten = (obj, prefix = '') => {
    _.forEach(obj, (value, key) => {
      if (_.isObject(value)) {
        flatten(value, `${prefix}${key}.`);
      } else {
        result[`${prefix}${key}`] = String(value);
      }
    });
  };

  flatten(object);

  return result;
};

/**
 * @function utils
 * Delete object properties.
 * @param  {Array<obj>} data -Array of objects.
 */
const cleanData = (data) => {
  return data.map((item) => {
    let tempItem = item;
    delete tempItem.highlight;
    delete tempItem._index;
    delete tempItem._type;
    delete tempItem._id;
    delete tempItem._score;
    delete tempItem._click_id;
    delete tempItem.sort;

    return plainToFlattenObject(tempItem);
  });
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const cleanDocTypeLabel = (label) => {
  const excludedValues = ['db', 'vi', 'post'];
  const displayNameArr = label
    .split('_')
    .filter((v) => excludedValues.indexOf(v) === -1);
  const displayName = displayNameArr
    .map((n) => capitalizeFirstLetter(n))
    .join(' ')
    .replace('Vichan', 'Chan');
  return displayName;
};

const downloadAsPDF = (id, name = 'DarkBlue Report', backgroundColor) => {
  const doc = window.document.getElementById(id);
  var HTML_Width = doc.clientWidth;
  var HTML_Height = doc.clientHeight;
  var top_left_margin = 15;
  var PDF_Width = HTML_Width + top_left_margin * 2;
  var PDF_Height = PDF_Width + top_left_margin * 2;
  var canvas_image_width = HTML_Width;
  var canvas_image_height = HTML_Height;

  var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

  html2canvas(document.querySelector('#' + id), {
    allowTaint: true,
    useCORS: true,
    orientation: 'p',
    scale: 1.2,
    backgroundColor: backgroundColor,
  }).then(function(canvas) {
    let ctx = canvas.getContext('2d');
    var imgData = canvas.toDataURL('image/jpeg', 1.0);
    var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);

    for (var i = 0; i <= totalPDFPages; i++) {
      if (i > 0) pdf.addPage(PDF_Width, PDF_Height);
      pdf.addImage(
        imgData,
        'JPG',
        top_left_margin,
        -(PDF_Height * i) + top_left_margin * 4,
        canvas_image_width,
        canvas_image_height,
      );
    }

    pdf.save(`${name}.pdf`);
  });
};

const getNumberOfFiltersApplied = (filterState) => {
  const filtersApplied = Object.entries(filterState).filter(([key, value]) => {
    if (typeof value === 'object' && Array.isArray(value)) {
      return value.length;
    } else if (typeof value === 'object') {
      return Object.entries(value)[1];
    } else {
      return Boolean(value);
    }
  });

  return filtersApplied.length;
};


/**
 * @function isEmptyValue
 * Determines if value is empty.
 * @param  {int | obj | string} value - A value to check whether it is empty.
 */
const isEmptyValue = (value) => {
  return (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (Array.isArray(value) &&
      value.every(function(v) {
        return v === null;
      }))
  );
};

const createObjFromPath = (obj, path, value = null) => {
  path = typeof path === 'string' ? path.split('.') : path;
  let current = obj;
  while (path.length > 1) {
    const [head, ...tail] = path;
    path = tail;
    if (current[head] === undefined) {
      current[head] = {};
    }
    current = current[head];
  }
  current[path[0]] = value;
  return obj;
};

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

const abbreviateNumber = (number) => {
  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
};

export {
  purifySnapshot,
  plainToFlattenObject,
  capitalizeFirstLetter,
  cleanData,
  flattenDataForCSV,
  plainToFlattenWithComponents,
  getNumberOfFiltersApplied,
  handleFilterOn,
  isEmptyValue,
  handleFilterOut,
  handleChartFilterOn,
  findSelectors,
  indexToData,
  sortCardFields,
  handleRequiredFilter,
  indexToDict,
  getTableHeaders,
  getTableData,
  removeMarks,
  downloadAsPDF,
  getScansTableData,
  indexFilterNames,
  cleanDocTypeLabel,
  createObjFromPath,
  abbreviateNumber,
};
