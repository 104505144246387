import React from 'react';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
const tags = [
  {
    singular: true,
    indexTag: true,
    path: 'doc.type',
    elasticPath: ['doc.type.keyword'],
    tagLabel: 'Data Type',
    filterName: 'DocFilter',
    icon: <DesktopWindowsIcon />,
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.net',
    tagLabel: 'Site Net',
    elasticPath: ['site.net.keyword'],
    filterName: 'SiteNetFilter',
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'ai.site.type',
    tagLabel: 'Site Type',
    filterName: 'SiteTypeFilter',
    elasticPath: ['ai.site.type.keyword'],
    filterValueType: 'array',
  },
  {
    descriptionPath: 'ai.usper.explanation',
    tagText: 'Potential USPER',
    path: 'ai.usper.potential',
    tagLabel: 'US Person',
    filterName: 'UsperFilter',
    elasticPath: ['ai.usper.potential'],
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'ai.site.firstSnapshot',
    filterName: 'FirstSnapshotFilter',
    tagLabel: 'First Snapshot',
    elasticPath: ['ai.site.firstSnapshot'],
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.name',
    toolTipInfo: 'Site Name',
    tagLabel: 'Site Name',
    filterName: 'SiteNameFilter',
    elasticPath: ['site.name.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.tags',
    tagPointer: 'ai.content',
    filterName: 'ContentTagsFilter',
    tagLabel: 'Content Tags',
    elasticPath: ['ai.content.tags.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.language',
    elasticPath: ['ai.language.keyword'],
    tagLabel: 'Language',
    filterName: 'LanguageFilter',
    filterValueType: 'array',
  },
];

const earmarks = [{ path: 'timestamp' }];

const title = {
  title: 'Page Title',
  path: 'pageTitle',
  filterName: 'PageTitleFilter',
  elasticPath: ['pageTitle.keyword'],
  filterValueType: 'array',
};

const body = [
  {
    title: 'Content Preview',
    path: 'snapshotSnippet',
  },
];

const footers = [
  {
    title: 'Hidden Service/ Page URL',
    path: 'hiddenService',
    elasticPath: ['hiddenService.keyword'],
    filterName: 'HiddenServiceFilter',
    filterValueType: 'array',
  },
];

const selectors = [
  'analyticsIDs',
  'apacheServices',
  'bitcoinAddresses',
  'bitcoinSignature',
  'email',
  'emailAddresses',
  'ethereumAddresses',
  'litecoinAddresses',
  'moneroAddresses',
  'pgp.created',
  'phone',
  'phoneNumbers',
  'crypto.ssh.key',
  'socialMedia',
  'vendorBitcoinAddress',
  'vendorPgp',
  'creditCards',
  'identity',
];

export { tags, earmarks, title, body, footers, selectors };
