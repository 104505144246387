import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import DateRangeIcon from '@material-ui/icons/DateRange';

import Grid from '../../darkblue-ui/core/Grid/Grid';
import Card from '../../darkblue-ui/Card/Card';
import SwitchSelect from '../../darkblue-ui/Inputs/SwitchSelect/SwitchSelect';

import { UserContext } from '../../DBAuthenticator';
import {
  excludesFilter,
  bucketIncludesQuery,
  bucketExcludesQuery,
  termsQuery,
  timeQuery,
} from '../../darkblue-ui/Search/utils';

import TrendingTerms from './OverviewWidgets/prometheus/TrendingTerms';
import TrendingSites from './OverviewWidgets/prometheus/TrendingSites';
import TopContentTags from './OverviewWidgets/prometheus/TopContentTags';
import GeoPolitical from './OverviewWidgets/prometheus/GeoPolitical';
import Counter from './OverviewWidgets/prometheus/Counter';
import SitesDistro from './OverviewWidgets/prometheus/SitesDistro';
import MapMentions from './OverviewWidgets/prometheus/MapMentions';
import TopLocations from './OverviewWidgets/prometheus/TopLocations';
import Dropdown from '../../darkblue-ui/Inputs/Dropdown/Dropdown';

const WidgetCard = styled(Card)`
  height: fit-content;
`;

const HeaderContainer = styled(Grid)`
  max-width: fit-content;
  grid-column-gap: 2em;
  align-items: center;
`;

const DashboardGrid = styled(Grid)`
  display: grid;
  column-gap: 2em;
`;

const TrendingGrid = styled(Grid)`
  height: fit-content;
  align-items: center;
`;

const timeFormat = 'YYYY-MM-DD HH:mm:ss';

const defaultDashboardFilterState = {
  DateTimeFilter: {
    query: timeQuery,
    dropdownValue: { label: '30 Days', value: 30 },
    value: {
      startValue: dayjs()
        .subtract(30, 'days')
        .format(timeFormat),
      endValue: dayjs().format(timeFormat),
    },
    elasticPath: ['timestamp'],
  },
};

const dateRangeOptions = [
  { label: '7 Days', value: 7 },
  { label: '30 Days', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 },
];

const Overview = () => {
  const [dashboardFilters, setDashboardFilters] = useState(
    defaultDashboardFilterState,
  );

  const options = [
    { label: 'All' },
    {
      path: ['net.keyword', 'site.net.keyword'],
      filterValue: 'Open Web',
      label: 'Dark Web',
      additionalFilters: [bucketExcludesQuery],
      urlModifier: `NotFilter=%7B"value"%3A%5B%7B"value"%3A"Open%20Web"%2C"elasticPath"%3A%5B"site.net.keyword"%5D%7D%5D%7D`,
    },
    {
      path: ['net.keyword', 'site.net.keyword'],
      filterValue: 'Open Web',
      label: 'Open Web',
      additionalFilters: [bucketIncludesQuery],
      urlModifier: `SiteNetFilter=%7B"value"%3A%5B"Open%20Web"%5D%7D`,
    },
  ];

  const [webType, setWebType] = useState({
    value: 'All',
    label: 'All',
    urlModifier: '',
  });

  const { apiKey } = useContext(UserContext);

  const handleDateChange = (newValue) => {
    setDashboardFilters({
      ...dashboardFilters,
      DateTimeFilter: {
        ...dashboardFilters.DateTimeFilter,
        ...{
          value: {
            startValue: dayjs()
              .subtract(newValue, 'days')
              .format(timeFormat),
            endValue: dayjs().format(timeFormat),
          },
          dropdownValue: dateRangeOptions.filter(
            ({ value }) => newValue === value,
          )[0],
        },
      },
    });
  };

  return (
    <DashboardGrid>
      <HeaderContainer template="a a">
        <h1>Analytics Overview</h1>
        <Dropdown
          onChange={handleDateChange}
          options={dateRangeOptions}
          value={dashboardFilters['DateTimeFilter']}
          icon={<DateRangeIcon />}
          dropdownLabel={`Date Range: ${dashboardFilters['DateTimeFilter'].dropdownValue.label}`}
        />
      </HeaderContainer>
      <Grid repeat={2} gap={'1em'}>
        <WidgetCard>
          <Grid repeat={3} gap="1em">
            <Counter
              apiKey={apiKey}
              aggField="hiddenService.keyword"
              label="Sites"
              dashboardFilters={dashboardFilters}
            />
            <Counter
              apiKey={apiKey}
              aggField="board.keyword"
              label="Forums"
              dashboardFilters={dashboardFilters}
            />
            <Counter
              apiKey={apiKey}
              aggField="postId.keyword"
              label="Posts"
              dashboardFilters={dashboardFilters}
            />
          </Grid>

          <Grid repeat={2}>
            <div>
              <h3>Top AI Content Tags</h3>
              <TopContentTags
                apiKey={apiKey}
                webType={webType}
                dashboardFilters={dashboardFilters}
              />
            </div>
            <div>
              <h3>Dark Site Sources</h3>
              <SitesDistro
                apiKey={apiKey}
                dashboardFilters={dashboardFilters}
              />
            </div>
          </Grid>
          <Grid repeat={1} gap="1em">
            <Grid>
              <h1 style={{ margin: '16px 0px' }}>Top Mentioned Locations</h1>
            </Grid>
          </Grid>
          <TopLocations apiKey={apiKey} dashboardFilters={dashboardFilters} />
        </WidgetCard>
        <WidgetCard>
          <TrendingGrid template={`item item`}>
            <h1 style={{ fontSize: '2.5em' }}>Trending</h1>
            <SwitchSelect
              value={webType.label}
              accessor="label"
              options={options}
              onClick={(name) =>
                setWebType(options.filter((o) => o.label === name)[0])
              }
            />
            <TrendingTerms
              dashboardFilters={dashboardFilters}
              apiKey={apiKey}
              webType={webType}
            />
            <TrendingSites
              dashboardFilters={dashboardFilters}
              apiKey={apiKey}
              webType={webType}
            />
          </TrendingGrid>
          <Grid>
            <GeoPolitical
              apiKey={apiKey}
              webType={webType}
              dashboardFilters={dashboardFilters}
            />
          </Grid>
        </WidgetCard>
      </Grid>
    </DashboardGrid>
  );
};

export default Overview;
