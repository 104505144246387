import React from 'react';
import styled from 'styled-components';

import Button from '../../darkblue-ui/Button/Button';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';

const BackgroundCover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1030;
  overflow: auto;
  transition: left 200ms;
  left: ${(props) => (props.open ? '20%' : '200%')};
`;

const DrawerComponent = styled(Paper)`
  min-height: 100%;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;

  &&& {
    background-color: ${(props) => props.theme.colors.cardBackground};
  }

  & * > {
    color: ${(props) => props.theme.colors.cardBodyTitle};
  }
`;

const ButtonDiv = styled.div`
  position: absolute;
  z-index: 1031;
  right: 20px;
  top: 20px;
`;

const Title = styled.h1`
  text-align: center;
  color: ${(props) => props.theme.colors.cardBodyTitle};
`;

const Subtitle = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colors.cardBodyTitle};
`;

const FilterContainers = styled(GridContainer)`
  &&& {
    & > * {
      h2 {
        color: ${(props) => props.theme.colors.cardBodyTitle} !important;
      }
      p {
        color: ${(props) => props.theme.colors.cardBodyTitle} !important;
      }

      button {
        p {
          color: inherit !important;
        }
      }
    }
  }
`;

const CustomDrawer = (props) => {
  return <DrawerComponent elevation={3}>{props.children}</DrawerComponent>;
};

const FilterDrawer = (props) => {
  const { open, setOpen } = props;

  return (
    <BackgroundCover open={open}>
      <CustomDrawer>
        <ButtonDiv>
          <Button onClick={() => setOpen(false)} justIcon>
            <CloseIcon />
          </Button>
        </ButtonDiv>
        <GridContainer justify="center" alignItems="center">
          <GridItem xs={12}>
            <Title>Filters</Title>
            <Subtitle>Filters search for exact matches to values</Subtitle>
          </GridItem>
        </GridContainer>
        <FilterContainers>{props.children}</FilterContainers>
      </CustomDrawer>
    </BackgroundCover>
  );
};

export default React.memo(FilterDrawer);
