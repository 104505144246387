import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../DBAuthenticator';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import _ from 'lodash';
import { Storage } from '../../Auth.js';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { useSnackbar } from 'notistack';

import Input from '../../darkblue-ui/Inputs/Input';

import Button from '../../darkblue-ui/Button/Button';

const Bold = styled.span`
  font-weight: 800;
`;

const ThemedCard = styled(Card)`
  background-color: ${(props) => props.theme.colors.cardBackground} !important;
`;

const ErrorMessageText = styled.p`
  border: 3px solid ${(props) => props.theme.colors.danger};
  padding: 8px;
  border-radius: 0px;
`;

const NewSourceSubmission = () => {
  const userSess = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const validateSourceSubmission = (values) => {
    let error = {};
    if (!values.sourceUrl) {
      error.sourceUrl = 'Source URL is a required field';
    }
    if (!values.description) {
      error.description = 'Description is a required field';
    }

    return error;
  };

  const submitNewSource = async (email, sourceUrl, description, resetForm) => {
    let body = {
      fields: {
        project: {
          key: 'BSASUPPORT',
        },
        summary: `${sourceUrl}`,
        description: `${description + ' ' + (email.length > 0 ? email : '')}`,
        issuetype: {
          id: '10009',
        },
      },
    };

    const session = await userSess.auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    const init = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: idJwtToken,
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(
      process.env.REACT_APP_JIRA_SUPPORT_ENDPOINT,
      init,
    );

    try {
      const data = await response.json();
      if (!_.isEqual(data, {})) {
        enqueueSnackbar(`Thank you for your suggestion!`, {
          variant: 'success',
        });

        resetForm({});
      } else if (_.isEqual(data, {})) {
        setErrorMessage(
          'Something went wrong! Please try to submit again, or visit our support portal',
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Formik
      initialValues={{ email: '', sourceUrl: '', description: '' }}
      validate={validateSourceSubmission}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        submitNewSource(
          values.email,
          values.sourceUrl,
          values.description,
          resetForm,
        );
        setSubmitting(false);
      }}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <>
          <form id="support-form" autoComplete="off" onSubmit={handleSubmit}>
            <GridContainer alignItems="center">
              <GridItem xs={3}>
                <Field name="email">
                  {({ field }) => (
                    <Input
                      style={{ width: '100%' }}
                      label="Email"
                      inputProps={field}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={3}>
                <Field name="sourceUrl">
                  {({ field }) => (
                    <Input
                      style={{ width: '100%' }}
                      helperText={errors && errors.sourceUrl}
                      label="Source URL"
                      inputProps={field}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={4}>
                <Field name="description">
                  {({ field }) => (
                    <Input
                      style={{ width: '100%' }}
                      helperText={errors && errors.description}
                      label="Description"
                      inputProps={field}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={2}>
                <Button
                  color="primary"
                  type="submit"
                  style={{ marginTop: '15px', width: '100%', height: '48px' }}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </form>
          <GridContainer>
            {errorMessage ? (
              <GridItem xs={12}>
                <ErrorMessageText>{errorMessage}</ErrorMessageText>
              </GridItem>
            ) : null}
          </GridContainer>
        </>
      )}
    </Formik>
  );
};

const SupportPage = (props) => {
  const [quickstartURL, setQuickstartURL] = useState('');

  useEffect(() => {
    const setQuickStart = async () => {
      const signedURL = await Storage.get('DarkBlue Quickstart Guide.pdf');
      setQuickstartURL(signedURL);
    };
    setQuickStart();
  });
  return (
    <div>
      <h3>Support</h3>
      <GridContainer>
        <GridItem xs={12}>
          <ThemedCard>
            <CardHeader>
              <h3>We are here to support your mission</h3>
            </CardHeader>
            <CardBody>
              <p>
                <Bold>Quick Start Guide -</Bold>
                <a
                  href={quickstartURL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Click here
                </a>{' '}
                to discover DarkBlue’s datasets and get guidance on conducting
                searches, filtering, and saving or exporting your results.
              </p>
              <p>
                <Bold>Phone Support -</Bold>{' '}
                <a
                  href="tel:18005035921"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  1-800-503-5921
                </a>{' '}
                Phone support is available from 8 AM – 5 PM EST Monday through
                Friday excluding government holidays.
              </p>
              <p>
                <Bold>Email Support -</Bold>
                <a
                  href="mailto:support@bluestoneanalytics.caci.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  support@bluestoneanalytics.caci.com
                </a>{' '}
                We will respond to all email requests within 24 hours
                Monday-Friday and prior to 5PM EST on the next business day for
                support emails received over the weekend or on holidays.
              </p>
              <p>
                <Bold>Support Portal -</Bold>
                <a
                  href="https://bluestoneanalytics.atlassian.net/servicedesk/customer/portal/1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://bluestoneanalytics.atlassian.net/servicedesk/customer/portal/1
                </a>{' '}
                The support portal offers centralized support for password
                resets, bug reporting, analytical support, and product feedback.
              </p>
              <p>
                <Bold>Emergency After-Hours Support - </Bold> If you are
                experiencing difficulties for a time-sensitive project outside
                of normal business hours, please call our primary phone support
                number, 1-800-503-5921. We will respond as soon as possible to
                all voicemails.
              </p>
            </CardBody>
          </ThemedCard>
        </GridItem>
        <GridItem xs={12}>
          <ThemedCard>
            <CardHeader>
              <h3>Can we enhance your mission capabilities?</h3>
            </CardHeader>
            <CardBody>
              <h3>
                Submit a new source and we will investigate adding it to our
                platform
              </h3>
              <NewSourceSubmission />
            </CardBody>
          </ThemedCard>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default SupportPage;
