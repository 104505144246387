import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Tooltip from '@material-ui/core/Tooltip';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useClipboard } from 'use-clipboard-copy';

import ActionsContainer from '../TooltipActions/ActionsContainer';

const StyledTooltip = styled(Tooltip)`
  &.MuiTooltip-tooltip {
    border-radius: 0px;
    color: ${(props) => props.theme.colors.navy} !important;
  }
`;

const HighlightWrapper = styled.div`
  max-width: fit-content;
  &:hover {
    cursor: pointer;
  }
  p {
    &:first-child {
      text-decoration: underline;
    }
  }
`;
const HoverWrapper = React.forwardRef((props, ref) => (
  <HighlightWrapper ref={ref}>{props.children}</HighlightWrapper>
));

const CustomTooltip = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const clipboard = useClipboard();

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const toggleTooltipState = () => {
    setShowTooltip(!showTooltip);
  };

  const ChildComp = React.cloneElement(<span>{props.children}</span>, {
    onClick: props.children.onClick
      ? props.children.onClick
      : toggleTooltipState,
  });

  return !props.isearmark ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <StyledTooltip
        classes={{
          tooltip: 'bg-override',
        }}
        interactive
        placement={props.tooltipLocation || 'top'}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={showTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<ActionsContainer {...props} copy={clipboard.copy} />}
      >
        <HoverWrapper {...props}>{ChildComp}</HoverWrapper>
      </StyledTooltip>
    </ClickAwayListener>
  ) : (
    props.children
  );
};

CustomTooltip.defaultProps = {
  isearmark: false,
};

export default React.memo(CustomTooltip);
