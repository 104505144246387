import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { StateContext } from '../../../../Search/StateProviderContext/StateProviderContext';
import { CSVLink } from 'react-csv';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import GetAppIcon from '@material-ui/icons/GetApp';
import CustomSelect from 'darkblue-ui/Select/Select.js';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const Wrapper = styled.div.attrs(() => ({
  id: 'other-wrapper',
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  background-color: ${(props) => props.theme.colors.fog};
  color: ${(props) =>
    props.isOpen
      ? props.theme.colors.almostBlack
      : props.theme.colors.altPrimary};
  border-radius: 0px;
  padding: 3px 10px 3px 3px;

  &:hover {
    cursor: ${(props) => (props.isOpen ? 'auto' : 'pointer')};
  }

  * {
    width: 100%;
  }
`;

const StyledMoreVertIcon = styled(MoreVertIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

const SelectedText = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.altPrimary};
`;

const HeaderText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  &&&:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.altPrimary};
  }
`;

const StyledCSVLink = styled(CSVLink)`
  color: ${(props) => props.theme.colors.almostBlack};
  text-decoration: none;
  margin-left: 3px;
`;

const MoreOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  &:hover {
    * {
      color: ${(props) => props.theme.colors.altPrimary};
      cursor: pointer;
    }
  }
`;

const MoreOptionsText = styled.div`
  margin-left: 3px;
`;

const AbsolutePositioner = styled.div`
  position: absolute;
  bottom: ${(props) =>
    props.showAll ? (props.disableGraph ? '-63px' : '-89px') : '-41px'};
  left: 0px;
  border-radius: 0px;
  background-color: ${(props) => props.theme.colors.fog};
  width: max-content;
  padding: 3px 10px;
  z-index: 999;
`;

const OtherToggle = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const { hits, size, setSize, setPage } = useContext(StateContext);
  const { showTermsChart, setShowTermsChart, disableGraph = false } = props;

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const toggleShowTerms = () => {
    setShowTermsChart(!showTermsChart);
  };

  const resetPage = (val) => {
    setSize(val);
    setPage(0);
  };

  const handleMoreClick = (e) => {
    setShowOptions(false);
  };

  const perPageOptions = [
    {
      displayText: '25',
      value: 25,
    },
    {
      displayText: '50',
      value: 50,
    },
    {
      displayText: '100',
      value: 100,
    },
  ];

  const data = hits.map((h) => h._source);

  const listOfHeaders = data.map((record) => {
    return Object.entries(record).map(([key, value]) => {
      return key;
    });
  });

  let csvBody = [];
  let keyAccumulator = [];

  listOfHeaders.forEach((record) => {
    keyAccumulator = [...keyAccumulator, ...record];
  });

  let uniqueKeys = Array.from(new Set(keyAccumulator));
  uniqueKeys = uniqueKeys.filter((header) => {
    return header !== 'highlight';
  });

  data.forEach((record) => {
    let csvItem = uniqueKeys.map((key) => {
      if (key === 'doc') {
        return 'Data available upon request';
      } else if (record[key]) {
        return JSON.stringify(record[key])
          .replace(/(\r\n|\n|\r)/gm, ' ')
          .replace(/,/g, ' ');
      } else {
        return ' ';
      }
    });
    csvBody = [...csvBody, csvItem];
  });
  return (
    <Wrapper resultsExist={hits.length > 0} isOpen={showOptions}>
      <HeaderText onClick={toggleShowOptions}>
        <StyledMoreVertIcon /> More
      </HeaderText>
      {showOptions ? (
        <ClickAwayListener onClickAway={handleMoreClick} mouseEvent="onMouseUp">
          <AbsolutePositioner showAll={data.length} disableGraph={disableGraph}>
            <MoreOptionsWrapper>
              <CustomSelect
                onChange={resetPage}
                defaultValue={size}
                items={perPageOptions}
                label="selectLabel"
              />
            </MoreOptionsWrapper>
            {data.length ? (
              <>
                <MoreOptionsWrapper>
                  <GetAppIcon />
                  <StyledCSVLink
                    data={[uniqueKeys, ...csvBody]}
                    filename={'multiRecord.csv'}
                  >
                    Download {data.length} records as CSV
                  </StyledCSVLink>
                </MoreOptionsWrapper>
                {!disableGraph ? (
                  showTermsChart ? (
                    <MoreOptionsWrapper onClick={toggleShowTerms}>
                      <VisibilityOffIcon />
                      <MoreOptionsText>Hide results over time</MoreOptionsText>
                    </MoreOptionsWrapper>
                  ) : (
                    <MoreOptionsWrapper onClick={toggleShowTerms}>
                      <VisibilityIcon />
                      <MoreOptionsText>Show results over time</MoreOptionsText>
                    </MoreOptionsWrapper>
                  )
                ) : null}
              </>
            ) : null}
          </AbsolutePositioner>
        </ClickAwayListener>
      ) : null}
    </Wrapper>
  );
};

export default OtherToggle;
