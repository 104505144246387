import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';

const PageTableContainer = styled(GridContainer)`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    height: 400px;
    width: 100%;
  }

  td.cell__title,
  th {
    font-weight: bold;
    text-align: left;
    padding: 8px;
    font-size: 16px;
  }

  td.cell__value {
    font-size: 16px;
  }

  tr:nth-child(even) {
    background-color: ${(props) => props.theme.colors.alternativeTableBackground};
  }

  tbody > tr:nth-child(even) > td {
    color: ${(props) => props.theme.colors.tdTextColorOdd};
  }

  tbody > tr:nth-child(odd) > td {
    color: ${(props) => props.theme.colors.tdTextColorOdd};
  }
`;

const BitcoinFullView = ({ bitcoinInfo, last30Days }) => {
  const item = { ...bitcoinInfo[0] };
  const btcDivisor = 100000000;
  let istxsContainedInAddress =
    item.hasOwnProperty('txs') && bitcoinInfo[0].txs.length > 0;
  let isTransactionOver30Days =
    istxsContainedInAddress &&
    item.txs.filter((t) => {
      const date = dayjs(t.received).isAfter(last30Days);
      return date;
    }).length > 0;
  return (
    <PageTableContainer>
      <GridItem xs={12}>
        <table>
          <tbody>
            <tr>
              <td className="cell__title">Total Debits (Inputs)</td>
              <td className="cell__value">
                {item.total_received ? (
                  <>{item.total_received / btcDivisor} BTC </>
                ) : (
                  0
                )}
              </td>
            </tr>
            <tr>
              <td className="cell__title">Total Credits (Outputs)</td>
              <td className="cell__value">
                {item.total_sent ? (
                  <>{bitcoinInfo[0].total_sent / btcDivisor} BTC </>
                ) : (
                  0
                )}
              </td>
            </tr>
            <tr>
              <td className="cell__title">Current Balance</td>
              <td className="cell__value">
                {item.balance ? <>{item.balance / btcDivisor} BTC</> : 0}
              </td>
            </tr>
            <tr>
              <td className="cell__title">First Transaction</td>
              <td className="cell__value">
                {istxsContainedInAddress
                  ? dayjs(
                      item.txs.sort(
                        (a, b) => new Date(a.received) - new Date(b.received),
                      )[0].received,
                    ).format('YYYY-MM-DD')
                  : 'None'}
              </td>
            </tr>
            <tr>
              <td className="cell__title">Recent Transaction</td>
              <td className="cell__value">
                {istxsContainedInAddress
                  ? dayjs(
                      new Date(
                        Math.max(
                          ...item.txs.map((item) => new Date(item.received)),
                        ),
                      ),
                    ).format('YYYY-MM-DD')
                  : 'None'}
              </td>
            </tr>
            <tr>
              <td className="cell__title">Transactions over 30 days</td>
              <td className="cell__value">
                {isTransactionOver30Days
                  ? item.txs.filter((t) => {
                      const date = dayjs(t.received).isAfter(last30Days);
                      return date;
                    }).length
                  : 0}
              </td>
            </tr>
            <tr>
              <td className="cell__title">Total Transactions Count</td>
              <td className="cell__value">
                {istxsContainedInAddress ? item.txs.length : 0}
              </td>
            </tr>
          </tbody>
        </table>
      </GridItem>
    </PageTableContainer>
  );
};
export default BitcoinFullView;
