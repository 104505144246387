import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

import styled, { ThemeContext } from 'styled-components';

import { Radio } from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';
import '../MapboxStyle.css';
import ReactTooltip from 'react-tooltip';

import { addLayerToMap, handleMarkerState } from '../mapUtils';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapStyleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: black;
  background: none;
  border: none;
  padding: 0;
`;

const StyledTooltip = styled(ReactTooltip)`
  font-size: 14px !important;
  pointer-events: auto !important;

  & {
    background-color: ${(props) =>
      props.theme.colors.cardBackground} !important;
  }

  .MuiIconButton-label {
    color: ${(props) => props.theme.colors.primaryText};
  }

  &:hover {
    font-size: 14px !important;
    pointer-events: auto !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
`;

const RadioWrapper = styled.div`
  color: ${(props) => props.theme.colors.primaryText};
`;

const StyledRadio = styled(Radio)`
  .MuiIconButton-label {
    color: ${(props) =>
      props.checked
        ? props.theme.colors.altPrimary
        : props.theme.colors.primaryText};
  }
`;

const MapStyleSelector = ({
  layers,
  overrideURL,
  map,
  hits,
  currentLayer,
  index,
  filterState,
  setFilterState,
  filterName,
  ...rest
}) => {
  // Change Basemap Tiles using handleBasemap
  const theme = useContext(ThemeContext);
  const mapTheme = theme.mode || 'dark';
  const [basemap, setBasemap] = useState('dark');
  const handleBasemap = (e) => {
    const id = e.target.id;
    map.current.setStyle('mapbox://styles/mapbox/' + id);
    setBasemap(e.target.value);

    map.current.on('style.load', () => {
      hits.forEach(({ path, data, index }, i) => {
        const geojson = {
          type: 'FeatureCollection',
          features: data.map(({ lat, lon }) => {
            return {
              type: 'Feature',
              geometry: { type: 'Point', coordinates: [lon, lat] },
            };
          }),
        };

        if (!map.current.getSource(path) && !map.current.getLayer(path)) {
          addLayerToMap({
            map: map.current,
            properties: {},
            id: path,
            color: theme.chartColors[i],
            highlightColor: theme.colors.fuschia,
            geojson,
          });
        }
        handleMarkerState({
          map: map.current,
          sourceId: path,
          staticProperties: { index: Array.isArray(index) ? index : [index] },
        });
        map.current.moveLayer(path);
      });
    });
  };

  useEffect(() => {
    setBasemap(mapTheme);
  }, [mapTheme]);

  return (
    <>
      <MapStyleButton data-tip="hover with tooltip" data-for="registerTip">
        <LayersIcon />
      </MapStyleButton>
      <StyledTooltip
        id="registerTip"
        place="top"
        effect="solid"
        delayHide={1000}
      >
        <RadioWrapper>
          <span>Dark</span>
          <StyledRadio
            value="dark"
            checked={basemap === 'dark'}
            color="primary"
            onChange={(e) => handleBasemap(e, hits)}
            id="dark-v10"
          />
        </RadioWrapper>
        <RadioWrapper>
          <span>Light</span>
          <StyledRadio
            value="light"
            checked={basemap === 'light'}
            color="primary"
            onChange={(e) => handleBasemap(e, hits)}
            id="light-v10"
          />
        </RadioWrapper>
        <RadioWrapper>
          <span>Satellite</span>
          <StyledRadio
            value="satellite"
            checked={basemap === 'satellite'}
            color="primary"
            onChange={(e) => handleBasemap(e, hits)}
            id="satellite-v9"
          />
        </RadioWrapper>
        <RadioWrapper>
          <span>Streets</span>
          <StyledRadio
            value="streets"
            checked={basemap === 'streets'}
            color="primary"
            onChange={(e) => handleBasemap(e, hits)}
            id="streets-v11"
          />
        </RadioWrapper>
      </StyledTooltip>
    </>
  );
};

export default MapStyleSelector;
