import React from 'react';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
const tags = [
  {
    singular: true,
    indexTag: true,
    path: 'doc.type',
    tagLabel: 'Data Type',
    elasticPath: ['doc.type.keyword'],
    filterName: 'DocFilter',
    icon: <LocalOfferIcon />,
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.net',
    tagLabel: 'Site Net',
    elasticPath: ['site.net.keyword'],
    filterName: 'SiteNetFilter',
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'site.name',
    filterName: 'SiteNameFilter',
    tagLabel: 'Site Name',
    elasticPath: ['site.name.keyword'],
    filterValueType: 'array',
  },
  {
    singular: true,
    path: 'countryName',
    filterName: 'PostOriginFilter',
    tagLabel: 'Country Name',
    elasticPath: ['countryName.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.tags',
    tagPointer: 'ai.content',
    tagLabel: 'Content Tags',
    elasticPath: ['ai.content.tags.keyword'],
    filterName: 'ContentTagsFilter',
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.tags',
    tagPointer: 'ai.drugs',
    tagLabel: 'Drugs',
    filterName: 'DrugContentTagsFilter',
    elasticPath: ['ai.drugs.tags.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.language',
    tagLabel: 'Languages',
    elasticPath: ['ai.language.keyword'],
    filterName: 'LanguageFilter',
    filterValueType: 'array',
  },
  {
    descriptionPath: 'ai.usper.explanation',
    tagText: 'Potential USPER',
    path: 'ai.usper.potential',
    tagLabel: 'US Person',
    filterName: 'UsperFilter',
    elasticPath: ['ai.usper.potential'],
    filterValueType: 'array',
  },
];

const earmarks = [{ path: 'timestamp' }];

const title = {
  title: 'Product Name',
  path: 'productName',
  filterName: 'ProductNameFilter',
  elasticPath: ['productName.keyword'],
  filterValueType: 'array',
};

const body = [
  {
    title: 'Description',
    path: 'productDescription',
    elasticPath: ['productDescription.keyword'],
  },
  {
    title: 'Vendor Name',
    path: 'vendorName',
    filterName: 'VendorNameFilter',
    elasticPath: ['vendorName.keyword'],
    filterValueType: 'array',
  },
  { title: 'Signature', path: 'signature' },
  { title: 'Number of Posts', path: 'numberOfPosts' },
];

const footers = [
  {
    title: 'Product ID',
    path: 'productId',
    filterName: 'ProductIdFilter',
    elasticPath: ['productId.keyword'],
    filterValueType: 'array',
  },
  { title: 'Price in USD', path: 'usdPrice' },
  { title: 'Original Price', path: 'originalPrice' },
  {
    title: 'Vendor ID',
    path: 'vendorId',
    filterName: 'VendorIdFilter',
    elasticPath: ['vendorId.keyword'],
    filterValueType: 'array',
  },
  {
    title: 'Page',
    path: 'page',
    filterName: 'PageFilter',
    elasticPath: ['page.keyword'],
    filterValueType: 'array',
  },
];

const selectors = [
  'analyticsIDs',
  'apacheServices',
  'bitcoinAddresses',
  'bitcoinSignature',
  'emailAddresses',
  'ethereumAddresses',
  'litecoinAddresses',
  'moneroAddresses',
  'pgp.fingerprints',
  'pgp.identities.emails',
  'pgp.identities.names',
  'phone',
  'phoneNumbers',
  'ssh',
  'socialMedia',
  'vendorBitcoinAddress',
  'vendorPgp',
  'identity',
];

export { tags, earmarks, title, body, footers, selectors };
