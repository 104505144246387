import React, { useState, useMemo, Fragment, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import dayjs from 'dayjs';

import Divider from '@material-ui/core/Divider';
import { useSnackbar } from 'notistack';

import CryptoModal from '../../composite/CryptoModal/CryptoModal';

import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import ListIcon from '@material-ui/icons/List';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import SaveIcon from '@material-ui/icons/Save';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import WarningIcon from '@material-ui/icons/Warning';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import DescriptionIcon from '@material-ui/icons/Description';
import ShareIcon from '@material-ui/icons/Share';
import SnapshotModal from './SnapshotModal/SnapshotModal';
import ThreadModal from './ThreadModal/ThreadModal';
import DocsModal from './DocsModal/DocsModal';
import HighlightComponent from './HighlightComponent.js';
import SaveModal from '../../Search/SaveModal';
import Dialog from '@material-ui/core/Dialog';
import Card from '../../Card/Card';
import ShowMore from '../../../darkblue-ui/Button/ShowMoreButton';
import Tag from '../../Tag/Tag';
import Tooltip from './TooltipActions/TooltipActions';
import MUITooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useClipboard } from 'use-clipboard-copy';
import { startSearchDate } from '../../../consts/MagicValues';
import LBCThreadModal from '../../../darkblue-ui/composite/DarkBlueCard/ThreadModal/LBCThreadModal';
import TranslateIcon from '@material-ui/icons/Translate';

import {
  purifySnapshot,
  flattenDataForCSV,
  plainToFlattenWithComponents,
  handleFilterOn,
  handleFilterOut,
  findSelectors,
  indexToData,
  sortCardFields,
  indexToDict,
  isEmptyValue,
  downloadAsPDF,
  plainToFlattenObject,
  removeMarks,
  cleanDocTypeLabel,
} from '../../../utils/Functions/utils';
import { Translator } from '../DarkBlueCard/Translator.js';
import { FileCopy } from '@material-ui/icons';
import MessageIcon from '@material-ui/icons/Message';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StoreIcon from '@material-ui/icons/Store';
import LBCUserTable from './LBCUserTable';
import { useContext } from 'react';
import { UserContext } from 'DBAuthenticator';

const CardTitle = styled.p`
  font-size: 18px !important;
  word-break: break-all;
`;

const SpanCardTitle = styled.span`
  font-size: 18px !important;
  word-break: break-all;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primaryText};
`;

const CardBody = styled.p`
  word-break: break-all;
`;

const CardBodySpan = styled.span`
  word-break: break-all;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.primaryText};
`;

const ExpandedViewDivider = styled(Divider)`
  background-color: ${(props) => props.theme.colors.charcoal} !important;
`;
const SpanCardBody = styled.span`
  font-size: 14px;
  word-break: break-all;
`;

const CardBodyTitle = styled.p`
  padding-bottom: 4px;
  font-size: 18px !important;
`;

const CardBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 8px 0px;
`;

const CardBodyGridItem = styled(GridItem)`
  width: 100%;
`;

const Earmark = styled.p`
  padding-right: 4px;
  margin: 5px;
  color: ${(props) => props.theme.colors.cardBody};
`;

const FooterContainer = styled(GridContainer)`
  padding-top: 4px;
`;

const FooterTitle = styled.p`
  word-break: break-word;
`;

const DividerTitleParagraph = styled.p`
  font-weight: bold;
`;

const FooterBody = styled.p`
  color: ${(props) => props.theme.colors.footerBody};
  word-break: break-all;

  text-decoration: none !important;
`;

const ExpandedCardRow = styled(GridContainer)`
  width: 100% !important;
  margin: 4px 8px !important;
`;

const ExpandedCardContainer = styled(GridContainer)`
  &&& {
    width: 100% !important;
  }

  & ${ExpandedCardRow}:nth-child(even) {
    background-color: ${(props) =>
      props.theme.colors.alternativeTableBackground};
  }
`;

const ThemedGetAppIcon = styled(GetAppIcon)`
  color: ${(props) => props.theme.colors.primary} !important;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const StyledSaveIcon = styled(SaveIcon)`
  color: ${(props) => props.theme.colors.primary} !important;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const ThemedAddLocationIcon = styled(AddLocationIcon)`
  color: ${(props) => props.theme.colors.primary} !important;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const ThemedShareIcon = styled(ShareIcon)`
  color: ${(props) => props.theme.colors.primary} !important;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const ShareRecordContainer = styled.div`
  margin: 4px 8px;
  padding: 8px 8px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  padding: 0px 2px 12px 2px;

  & svg {
    height: 30px;
  }
`;

const TooltipText = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${(props) => props.theme.colors.almostBlack};
`;

const ShareRecord = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const copy = useClipboard().copy;

  const btnStyle = {
    width: '41px',
    height: '41px',
    minWidth: '41px',
    marginLeft: '15px',
    border: 'none',
  };

  const containerStyle = {
    display: 'flex',
    margin: '0',
    flexDirection: 'row',
    wordBreak: 'break-all',
    alignItems: 'center',
  };

  return (
    <ShareRecordContainer>
      {data.map(({ title, value }) => {
        return (
          <div style={{ marginBottom: '6px' }} key={title + value}>
            <TooltipText
              style={{
                marginTop: '0',
                marginBottom: '6px',
                fontWeight: 'bold',
              }}
              key={title + value}
            >
              {title}
            </TooltipText>
            <div style={containerStyle}>
              <TooltipText style={{ margin: '0' }} key={title + value}>
                {value}
              </TooltipText>
              <button
                style={btnStyle}
                onClick={() => {
                  const copiedValue =
                    typeof value === 'string' ? value : String(value);
                  const cleanValue = removeMarks(copiedValue);
                  copy(cleanValue);
                  enqueueSnackbar('Successfully Copied!', {
                    variant: 'success',
                  });
                }}
              >
                <FileCopy
                  fontSize="small"
                  style={{ verticalAlign: 'middle' }}
                />
              </button>
            </div>
          </div>
        );
      })}
    </ShareRecordContainer>
  );
};

/**
 * @Component ExpandedViewContent
 * Renders: Expanded view card content.
 * @param  {obj} props
 *  data - data obj retrieved from cleanData.
 *  filterState - The filter state obj.
 *  setFilterState - The set filter state hook.
 *  item - Item object contains path and sortkey.
 *  value - Value to be rendered on the card.
 */
const ExpandedViewContent = (props) => {
  const {
    data,
    filterState,
    setFilterState,
    filterValueType,
    enqueueSnackbar,
    item,
    value,
  } = props;
  const { icon, title, path, ...rest } = item;
  if (value || typeof value === 'boolean') {
    const isObject = typeof value == 'object' && !Array.isArray(value);
    const isSnapshot = path === 'snapshot';
    let copiedValue = value;

    if (
      Array.isArray(copiedValue) &&
      copiedValue.filter((val) => typeof val === 'object').length > 0
    ) {
      const stringifiedValue = copiedValue
        .filter((x) => x !== null && x)
        .map((val) => {
          return JSON.stringify(val);
        });
      copiedValue = stringifiedValue;
    }

    const ExpandedViewValueString =
      String(copiedValue).length > 3000 && !Array.isArray(copiedValue) ? (
        <ShowMore>{String(copiedValue)}</ShowMore>
      ) : (
        <HighlightComponent text={String(copiedValue)} />
      );
    //HandleFilterOn function requires the value to be of type string when removing mark tags.
    //Initialize filterValue with the data value and type check to ensure it is a type string
    const filterValue = _.get(data, item.path);

    return (
      <ExpandedCardRow alignItems="center" key={path}>
        <GridItem xs={2}>
          <Tooltip
            filterState={filterState}
            setFilterState={setFilterState}
            filterValue={
              typeof filterValue === 'string'
                ? filterValue
                : String(filterValue)
            }
            elasticPath={item.elasticPath}
            filterName={item.filterName}
            filterValueType={filterValueType}
            path={item.path}
            enqueueSnackbar={enqueueSnackbar}
            {...item}
          >
            <CardBody
              className="no-margin card"
              style={{ fontWeight: 'lighter' }}
              // dangerouslySetInnerHTML={{
              //   __html: String(item.path),
              // }}
            >
              <HighlightComponent
                text={String(rest.displayName ? rest.displayName : path)}
              />
            </CardBody>
          </Tooltip>
        </GridItem>
        <GridItem xs={10}>
          {isObject ? (
            Object.entries(plainToFlattenObject(value)).map((entry, index) => {
              return (
                <SpanCardBody
                  component={'span'}
                  key={index}
                  className="no-margin card"
                >
                  <span style={{ fontWeight: 'lighter' }}>
                    {entry[0] + ' '}
                  </span>
                  <HighlightComponent text={entry[1] + ' '} />
                </SpanCardBody>
              );
            })
          ) : isSnapshot ? (
            <SpanCardBody className="no-margin card">
              {ExpandedViewValueString}
            </SpanCardBody>
          ) : (
            <SpanCardBody
              className="no-margin card"
              //dangerouslySetInnerHTML={{ __html: String(value) }}
            >
              {ExpandedViewValueString}
            </SpanCardBody>
          )}
        </GridItem>
      </ExpandedCardRow>
    );
  } else {
    return null;
  }
};

/**
 * @Component ExpandedView
 * Renders: Expanded view card.
 * @param  {obj} props
 *  data - data obj retrieved from cleanData.
 *  dataDict - Array of data objects.
 */
const ExpandedView = (props) => {
  const { data, dataDict } = props;
  const columns = useMemo(() => [
    {
      Header: 'Key',
      accessor: 'key',
    },
    { Header: 'Value', accessor: 'value' },
    { Header: 'RecordId', accessor: 'recordId' },
    { Header: 'Table', accessor: 'table' },
    { Header: 'Field', accessor: 'field' },
  ]);
  return (
    <>
      <DividerTitleParagraph>Featured Fields</DividerTitleParagraph>

      <ExpandedCardContainer direction="column">
        {dataDict
          .filter((item) => item.hasOwnProperty('sortKey'))
          .map((item) => {
            let value = _.get(data, item.path);
            return !isEmptyValue(value) ? (
              <ExpandedViewContent
                key={item.path}
                item={item}
                value={value}
                filterValueType={item.filterValueType}
                {...props}
              />
            ) : null;
          })}
      </ExpandedCardContainer>

      <DividerTitleParagraph style={{ marginTop: '30px' }}>
        Additional Fields
      </DividerTitleParagraph>

      <ExpandedCardContainer direction="column">
        {dataDict
          .filter((item) => !item.hasOwnProperty('sortKey'))
          .map((item) => {
            let value = _.get(data, item.path);

            return !isEmptyValue(value) ? (
              <ExpandedViewContent
                key={item.path}
                item={item}
                value={value}
                filterValueType={item.filterValueType}
                {...props}
              />
            ) : null;
          })}

        {props.index === 'user' && (
          <>
            <DividerTitleParagraph style={{ marginTop: '30px' }}>
              Table
            </DividerTitleParagraph>

            <ExpandedCardContainer direction="column">
              <LBCUserTable columns={columns} data={data} index={props.index} />
            </ExpandedCardContainer>
          </>
        )}
      </ExpandedCardContainer>
    </>
  );
};

const explainAi = (tag = '', pointer) => {
  let explanations = tag
    .split(',')
    .map((term) => term.split(':'))
    .map(([term, instances]) => {
      return `${instances} ${
        instances > 1 ? 'instances' : 'instance'
      } of "${term}"`;
    });
  return `This record has been categorized as ${
    pointer === 'csam' ? 'CSAM' : pointer
  } related, because of ${explanations.join(', ')}`;
};

const TagComponent = ({ data, tagContent, ...rest }) => {
  let staticClassName = '';
  switch (rest.path) {
    case 'ai.language':
      staticClassName = 'language-tag';
      break;
    case 'ai.content.tags':
      staticClassName = 'content-tag';
      break;
    default:
      break;
  }
  if (tagContent) {
    if (rest.tagPointer && Array.isArray(tagContent)) {
      return tagContent.map((pointer) => {
        const hoverData = _.get(data, rest.tagPointer + '.' + pointer);
        const aiExplanation = explainAi(hoverData, pointer);
        return (
          <Tag
            key={pointer}
            id={pointer}
            className={staticClassName}
            tagData={pointer[0]}
            {...rest}
            hoverData={aiExplanation}
            filterValue={pointer}
          >
            {pointer === 'csam' ? 'CSAM' : pointer}
          </Tag>
        );
      });
    } else if (rest.filterName === 'DocFilter') {
      return (
        <Tag
          {...rest}
          key={tagContent}
          id={staticClassName}
          tagData={tagContent}
          className={staticClassName}
          filterValue={tagContent}
        >
          {cleanDocTypeLabel(tagContent)}
        </Tag>
      );
    } else if (rest.singular) {
      let aiExplanation;
      if (rest.tagPointer && rest.tagToolTipDescription) {
        aiExplanation = _.get(data, rest.tagToolTipDescription);
      }
      return (
        <>
          <Tag
            {...rest}
            className={staticClassName}
            filterValue={tagContent}
            tagData={tagContent}
            id={rest.tagPointer}
            {...(rest.tagPointer ? { hoverData: aiExplanation } : null)}
          >
            <HighlightComponent text={tagContent} />
          </Tag>
        </>
      );
    } else if (Array.isArray(tagContent)) {
      return (
        <div style={{ display: 'flex' }}>
          {tagContent.map((content) => {
            return (
              <Tag
                {...rest}
                className={staticClassName}
                key={tagContent}
                filterValue={content}
                tagData={content}
                id={staticClassName}
              >
                <HighlightComponent text={content} />
              </Tag>
            );
          })}
        </div>
      );
    } else if (rest.descriptionPath) {
      let aiExplanation = _.get(data, rest.descriptionPath);
      let textContent;
      if (rest.tagText) {
        textContent = rest.tagText;
      }
      return (
        <Tag
          {...rest}
          className={staticClassName}
          filterValue={tagContent}
          tagData={tagContent}
          {...(rest.descriptionPath ? { hoverData: aiExplanation } : null)}
        >
          <HighlightComponent text={textContent ? textContent : tagContent} />
        </Tag>
      );
    } else {
      return (
        <Tag
          {...rest}
          key={tagContent}
          tagData={tagContent}
          className={staticClassName}
          filterValue={tagContent}
          id={staticClassName}
        >
          <HighlightComponent text={tagContent} />
        </Tag>
      );
    }
  } else {
    return null;
  }
};

const getActionTags = (
  index,
  dataset,
  expanded,
  setExpanded,
  showSaveModal,
  setShowSaveModal,
  showThreadModal,
  setShowThreadModal,
  showSnapModal,
  setShowSnapModal,
  showDocsModal,
  setShowDocsModal,
  threadId,
  csamPresent,
  selectorsPresent,
  isDocSourceHashExist,
  showBtcInformation,
  setShowBtcInformation,
  isCryptoExists,
  previewData,
  setPreviewData,
  isTranslated,
  setIsTranslated,
  cleanData,
  body,
  data,
  title,
  showLBCThreadModal,
  setShowLBCThreadModal,
  userId,
  advertisementId,
  setFilterState,
  filterState,
) => {
  let actionTags = [];
  if (index === 'site') {
    actionTags = [
      ...actionTags,
      <Tag
        key="snapshot"
        action
        id="show-snapshot-tag"
        onClick={() => setShowSnapModal(!showSnapModal)}
      >
        Show Snapshot
      </Tag>,
    ];
  } else if (
    [
      'chat_post',
      'forum_post',
      'vichan_post',
      'paste_post',
      'chan',
      'paste',
      'forum',
      'chat',
    ].includes(index) &&
    threadId
  ) {
    actionTags = [
      ...actionTags,
      <Tag
        key="thread"
        id="show-thread-tag"
        action
        icon={<ListIcon />}
        onClick={() => setShowThreadModal(!showThreadModal)}
      >
        Show Thread
      </Tag>,
    ];
  } else if (index === 'document' && isDocSourceHashExist) {
    actionTags = [
      ...actionTags,
      <Tag
        key="document"
        action
        id="show-document-tag"
        icon={<DescriptionIcon />}
        onClick={() => setShowDocsModal(!showDocsModal)}
      >
        Show Document
      </Tag>,
    ];
  }

  actionTags = [
    ...actionTags,
    <Tag
      icon={<OpenWithIcon />}
      key="expand"
      action
      id="toggle-view-more-tag"
      onClick={() => setExpanded(!expanded)}
    >
      {expanded ? 'Collapse Data' : 'Expand Data'}
    </Tag>,
  ];

  actionTags = [
    ...actionTags,
    isTranslated === false ? (
      <Tag
        icon={<TranslateIcon fontSize="small" />}
        key="translate"
        id="toggle-translate-tag"
        onClick={async ({}) => {
          let getValue = (path) => {
            return _.get(cleanData, path);
          };

          let translateValues = body
            .filter(function(item) {
              const value = getValue(item.path);
              return typeof value === 'string' && value;
            })
            .map((item) => {
              const value = getValue(item.path);
              return { key: item.path, value: value };
            });

          const values = await Translator([
            { key: title.path, value: _.get(cleanData, title.path) },
            ...translateValues,
          ]);
          setPreviewData({ ...previewData, ...values });
          setIsTranslated(true);
        }}
        action
      >
        Translate Record
      </Tag>
    ) : (
      <Tag
        icon={<TranslateIcon fontSize="small" />}
        key="translate"
        id="toggle-translate-tag"
        onClick={() => {
          setPreviewData(data);
          setIsTranslated(false);
        }}
        action
      >
        Translate Record
      </Tag>
    ),
  ];
  if (isCryptoExists) {
    actionTags = [
      ...actionTags,
      <Tag
        icon={<i className="fab fa-btc fa-lg"></i>}
        key="bitcoinAddress"
        action
        id="toggle-bitcoin-tag"
        onClick={() => setShowBtcInformation(!showBtcInformation)}
      >
        Show Crypto
      </Tag>,
    ];
  }

  if (
    index === 'message' &&
    data.threadId &&
    data.threadId.length > 0 &&
    data.messageType !== 'Exchange Escrow'
  ) {
    actionTags = [
      ...actionTags,
      <Tag
        key="thread"
        action
        id="toggle-thread-tag"
        icon={<ListIcon />}
        onClick={() => setShowLBCThreadModal(!showLBCThreadModal)}
      >
        Show Thread
      </Tag>,
    ];
  }

  if (index === 'user') {
    actionTags = [
      ...actionTags,
      ...(data && data.activity.messages > 0
        ? [
            <Tag
              key="message"
              id="toggle-user-tag"
              icon={
                <MessageIcon
                  fontSize="small"
                  style={{ verticalAlign: 'middle' }}
                />
              }
              action
              /**onClick functions for action tags are placeholders until data in available in ES */
              onClick={() => {
                setFilterState({
                  ...{
                    UserIdsFilter: {
                      elasticPath: ['userIds.keyword'],
                      value: String(userId),
                    },
                  },
                  ...{
                    DocFilter: {
                      elasticPath: ['doc.type.keyword'],
                      value: ['message'],
                    },
                  },
                });
              }}
            >
              Show Message
            </Tag>,
          ]
        : []),
      ...(data && data.activity.advertisements > 0
        ? [
            <Tag
              key="advertisement"
              icon={
                <StoreIcon
                  fontSize="small"
                  style={{ verticalAlign: 'middle' }}
                />
              }
              id="toggle-ad-id-tag"
              action
              /**onClick functions for action tags are placeholders until data in available in ES */
              onClick={() => {
                setFilterState({
                  ...{
                    UserIdFilter: {
                      elasticPath: ['userId.keyword'],
                      value: String(userId),
                    },
                  },
                  ...{
                    DocFilter: {
                      elasticPath: ['doc.type.keyword'],
                      value: ['advertisement'],
                    },
                  },
                });
              }}
            >
              Show Advertisements
            </Tag>,
          ]
        : []),
      ...(data && data.activity.transactions > 0
        ? [
            <Tag
              key="transaction"
              id="toggle-transaction-tag"
              icon={
                <AttachMoneyIcon
                  fontSize="small"
                  style={{ verticalAlign: 'middle' }}
                />
              }
              action
              /**onClick functions for action tags are placeholders until data in available in ES */
              onClick={() => {
                setFilterState({
                  ...{
                    UserIdsFilter: {
                      elasticPath: ['userIds.keyword'],
                      value: String(userId),
                    },
                  },
                  ...{
                    DocFilter: {
                      elasticPath: ['doc.type.keyword'],
                      value: ['transaction'],
                    },
                  },
                });
              }}
            >
              Show Transactions
            </Tag>,
          ]
        : []),
    ];
  }

  if (
    index === 'advertisement' &&
    data.messages.filter((x) => x).length > 0 &&
    data.advertisementId
  ) {
    actionTags = [
      ...actionTags,
      <Tag
        key={data.advertisementId}
        icon={
          <MessageIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
        }
        action
        id="toggle-ad-id-messages-tag"
        onClick={() => {
          let advertisementIdValue = removeMarks(String(advertisementId));

          setFilterState({
            ...{
              AdvertisementIdFilter: {
                elasticPath: ['advertisementId.keyword'],
                value: advertisementIdValue,
              },
            },
            ...{
              DocFilter: {
                elasticPath: ['doc.type.keyword'],
                value: ['message'],
              },
            },
          });
        }}
      >
        Show Messages{' '}
      </Tag>,
    ];
  }

  if (selectorsPresent.length > 0) {
    const selectorsFields = selectorsPresent.map((selector) => selector[0]);
    const selectorsHover = `Selectors Found: ${selectorsFields.join('\n')}`;
    actionTags = [
      ...actionTags,
      <Tag
        key="selector"
        action
        id="selector-present-tag"
        isSelector
        hoverData={selectorsHover}
        icon={<FingerprintIcon />}
      >
        Show Selectors
      </Tag>,
    ];
  }
  if (csamPresent) {
    actionTags = [
      ...actionTags,
      <Tag
        key="hazard"
        backgroundColor="danger"
        color="white"
        noAction
        id="csam-present-tag"
        icon={<WarningIcon />}
      >
        Child Sexually Abusive Material Detected
      </Tag>,
    ];
  }

  return actionTags;
};

const DarkBlueCard = ({
  loading,
  filterState,
  setFilterState,
  data,
  index,
  dataset,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [showSnapModal, setShowSnapModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showThreadModal, setShowThreadModal] = useState(false);
  const [showLBCThreadModal, setShowLBCThreadModal] = useState(false);
  const [showSnapshot, setShowSnapshot] = useState(false);
  const [searchSaved, setSearchSaved] = useState(false);
  const [showCitation, setShowCitation] = useState(false);
  const [previewData, setPreviewData] = useState(data);
  const [showTranslation, setShowTranslation] = useState(false);
  const [showBtcInformation, setShowBtcInformation] = useState(false);
  const [isTranslated, setIsTranslated] = useState(false);
  const [showDocsModal, setShowDocsModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleTooltipState = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const bgCleaner = /background=\'[^']*\'|background=\"[^\"]*\"/;

  const cleanHtml = data.snapshotPresent
    ? purifySnapshot(data.snapshot)
        .replace(/(?:\(['"]?)(.*?)(?:['"]?\))/g, '')
        .replace(bgCleaner, '')
    : null;
  const { tags, earmarks, title, body, footers, selectors } = indexToData(
    index,
  );

  const stripMark = (dirtyData = '') => {
    if (dirtyData && dirtyData !== null) {
      const cleanEntry = dirtyData.replace(/<mark>|<\/mark>/g, '');
      return cleanEntry;
    } else {
      return dirtyData;
    }
  };

  let cleanData = previewData;

  const dataDict = sortCardFields(indexToDict(index));
  const { ['selectors']: dataSelectors, ...rest } = cleanData;

  const selectorsPresent =
    selectors && dataSelectors
      ? findSelectors(selectors, {
          ...rest,
          ...Object.keys(dataSelectors).reduce((object, key) => {
            if (dataSelectors[key].length > 0) {
              return { [key]: dataSelectors[key] };
            }
            return object;
          }, {}),
        })
      : [];

  const isCryptoExists =
    cleanData.hasOwnProperty('bitcoinAddresses') ||
    cleanData.hasOwnProperty('litecoinAddresses') ||
    cleanData.hasOwnProperty('moneroAddresses') ||
    cleanData.hasOwnProperty('ethereumAddresses');

  const cleanBitcoinAddress = Array.isArray(cleanData.bitcoinAddresses)
    ? cleanData.bitcoinAddresses.map((btc) => stripMark(btc))
    : [stripMark(cleanData.bitcoinAddresses)];

  const cleanLitecoinAddress = Array.isArray(cleanData.litecoinAddress)
    ? cleanData.litecoinAddress.map((ltc) => stripMark(ltc))
    : [stripMark(cleanData.litecoinAddress)];

  const cleanMoneroAddresses = Array.isArray(cleanData.moneroAddresses)
    ? cleanData.moneroAddresses.map((mon) => stripMark(mon))
    : [stripMark(cleanData.moneroAddresses)];

  const cleanEthAddresses = Array.isArray(cleanData.ethereumAddresses)
    ? cleanData.ethereumAddresses.map((mon) => stripMark(mon))
    : [stripMark(cleanData.ethereumAddresses)];

  const bitcoinAddresses = [...new Set(cleanBitcoinAddress)];

  const litecoinAddresses = [...new Set(cleanLitecoinAddress)];

  const moneroAddresses = [...new Set(cleanMoneroAddresses)];

  const ethAddresses = [...new Set(cleanEthAddresses)];

  const cryptoAddresses = [
    ...bitcoinAddresses,
    ...litecoinAddresses,
    ...moneroAddresses,
    ...ethAddresses,
  ];

  let dataTags = [];
  if (
    cleanData &&
    cleanData.ai &&
    cleanData.ai.content &&
    cleanData.ai.content.tags
  ) {
    dataTags = cleanData.ai.content.tags;
  }

  const findCSAM = () => {
    if (dataTags.find((dataTag) => dataTag === 'csam')) {
      return true;
    } else {
      return false;
    }
  };

  const csamPresent = findCSAM();
  const isDocSourceHashExist = data.doc.sourceHash !== undefined;
  let shareInfo = [];
  if (cleanData && cleanData.doc) {
    //let decodedQuery = atob(row.original['query']);
    const basePath = window.location.protocol + '//' + window.location.host;
    const shareLink = `${basePath +
      window.location.pathname}?DocIdFilter=%7B"value"%3A"${
      cleanData.doc.idHash
    }"%7D`;

    shareInfo = [
      ...shareInfo,
      { title: 'ID hash ', value: cleanData.doc.idHash },
      { title: 'Content hash ', value: cleanData.doc.contentHash },
      { title: 'Shareable URL ', value: shareLink },
    ];
  }

  const actionTags = getActionTags(
    index,
    dataset,
    expanded,
    setExpanded,
    showSaveModal,
    setShowSaveModal,
    showThreadModal,
    setShowThreadModal,
    showSnapModal,
    setShowSnapModal,
    showDocsModal,
    setShowDocsModal,
    data.threadId,
    csamPresent,
    selectorsPresent,
    isDocSourceHashExist,
    showBtcInformation,
    setShowBtcInformation,
    isCryptoExists,
    previewData,
    setPreviewData,
    isTranslated,
    setIsTranslated,
    cleanData,
    body,
    data,
    title,
    showLBCThreadModal,
    setShowLBCThreadModal,
    data.userId,
    data.advertisementId,
    setFilterState,
    filterState,
  );

  const titles = ['Post Contents', 'Description', 'Content Preview'];
  const titleToTranslate = cleanData.pageTitle;
  const contentToTranslate = cleanData.snapshotSnippet;
  const userSess = useContext(UserContext);
  const idHash = removeMarks(data.doc.idHash);

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      setShowThreadModal(false);
      setShowSnapModal(false);
      setShowBtcInformation(false);
      setShowDocsModal(false);
      setShowSaveModal(false);
      setShowLBCThreadModal(false);
    }
  };

  return (
    <>
      {showThreadModal ||
      showSnapModal ||
      showSaveModal ||
      showDocsModal ||
      showBtcInformation ||
      showLBCThreadModal ? (
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={
            showThreadModal ||
            showSnapModal ||
            showSaveModal ||
            showDocsModal ||
            showBtcInformation ||
            showLBCThreadModal
          }
          onClose={handleClose}
        >
          {showSnapModal ? (
            <SnapshotModal
              setShowSnapModal={setShowSnapModal}
              pageTitle={data.pageTitle}
              pageUrl={data.hiddenService}
              cleanHtml={cleanHtml}
            />
          ) : showSaveModal ? (
            <SaveModal
              docId={cleanData.doc.idHash}
              setSearchSaved={setSearchSaved}
              setShowSaveModal={setShowSaveModal}
              showSaveModal={showSaveModal}
              saveType="record"
              dataset={dataset}
            />
          ) : showDocsModal ? (
            <DocsModal
              setShowDocsModal={setShowDocsModal}
              sourceHash={cleanData.doc.sourceHash}
              title={cleanData.fileName}
            />
          ) : showBtcInformation ? (
            <CryptoModal
              filterState={filterState}
              pageView={false}
              setFilterState={setFilterState}
              setShowBtcInformation={setShowBtcInformation}
              cryptoAddresses={cryptoAddresses.filter((addr) => addr)}
            />
          ) : showLBCThreadModal ? (
            <LBCThreadModal
              threadId={
                /<[^>]*>?/gm.test(data.threadId) //Returned results from adv filter or search highlights the value and adds <mark> html tags.
                  ? data.threadId.replace(/<[^>]*>?/gm, '') //In order to pass the value correctly, the tags must be striped using regex
                  : data.threadId
              }
              filterState={filterState}
              setFilterState={setFilterState}
              handleFilterOn={handleFilterOn}
              setShowLBCThreadModal={setShowLBCThreadModal}
              data={cleanData}
            />
          ) : (
            <>
              <ThreadModal
                siteId={cleanData.site.id}
                selectedPostContent={cleanData.post}
                siteNet={cleanData.site.net}
                board={cleanData.board}
                handleFilterOn={handleFilterOn}
                setFilterState={setFilterState}
                filterState={filterState}
                setShowThread={setShowThreadModal}
                threadId={cleanData.threadId}
                page={cleanData.page}
                author={cleanData.author}
                userId={cleanData.userId}
                channelId={cleanData.channelId}
              />
            </>
          )}
        </Dialog>
      ) : null}
      <Card
        id={`darkblue-card-${idHash}`}
        key={idHash}
        className="darkblue-card-static-class"
      >
        <GridContainer justify="space-between">
          <GridItem>
            <GridContainer>
              {tags.map((tag, index) => {
                const tagData = _.get(cleanData, tag.path);
                if (Array.isArray(tagData) && tagData?.length > 0) {
                  return tagData.map((content, index) => {
                    if (index === 0) {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                          id={tag.id || tag.path}
                          key={`${tag.path}: ${idHash}: ${tag.filterName}: ${content}`}
                        >
                          <p
                            className="no-margin s bold"
                            style={{ fontSize: '9px', marginLeft: '5px' }}
                          >
                            {tag.tagLabel}
                          </p>
                          <TagComponent
                            enqueueSnackbar={enqueueSnackbar}
                            setFilterState={setFilterState}
                            filterState={filterState}
                            filterValue={tag}
                            {...tag}
                            data={cleanData}
                            tagContent={content.split()}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                          id={tag.id || tag.path}
                          key={`${tag.path}: ${data.doc.contentHash}: ${idHash}: ${content}`}
                        >
                          <p
                            className="no-margin s bold"
                            style={{
                              fontSize: '9px',
                              visibility: 'hidden',
                              marginLeft: '5px',
                            }}
                          >
                            {'&&'}
                          </p>
                          <TagComponent
                            enqueueSnackbar={enqueueSnackbar}
                            setFilterState={setFilterState}
                            filterState={filterState}
                            filterValue={tag}
                            id={tag.id || tag.path}
                            {...tag}
                            data={cleanData}
                            tagContent={content.split()}
                          />
                        </div>
                      );
                    }
                  });
                } else if (
                  typeof tagData !== 'undefined' &&
                  !Array.isArray(tagData)
                ) {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      id={tag.id || tag.path}
                      key={tag.path}
                    >
                      <p
                        className="no-margin s bold"
                        style={{ fontSize: '9px', marginLeft: '5px' }}
                      >
                        {tag.tagLabel}
                      </p>
                      <TagComponent
                        enqueueSnackbar={enqueueSnackbar}
                        setFilterState={setFilterState}
                        filterState={filterState}
                        filterValue={tag}
                        {...tag}
                        data={cleanData}
                        tagContent={
                          typeof tagData === 'boolean'
                            ? String(tagData)
                            : tagData
                        }
                      />
                    </div>
                  );
                }
              })}
            </GridContainer>
          </GridItem>
          <GridItem>
            <GridContainer alignItems="center">
              <IconContainer>
                {dataset === 'LBC'
                  ? earmarks.map((earmark) => {
                      const { icon, title, ...rest } = earmark;

                      const value = _.get(data, rest.path);
                      return value && index === 'user' ? (
                        <Fragment key={rest.path}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                            key={rest.path}
                          >
                            <em
                              className="no-margin s bold"
                              style={{ fontSize: '11px' }}
                            >
                              {title}{' '}
                            </em>
                            <Tooltip
                              isearmark={true}
                              filterState={filterState}
                              setFilterState={setFilterState}
                              filterValue={value}
                              enqueueSnackbar={enqueueSnackbar}
                              {...rest}
                            >
                              <Earmark>
                                {dayjs(value).format('YYYY-MM-DD HH:mm:ss')}
                              </Earmark>
                            </Tooltip>
                          </div>
                        </Fragment>
                      ) : value && index === 'message' ? (
                        <Fragment key={rest.path}>
                          <Tooltip
                            key={rest.path}
                            isearmark={true}
                            filterState={filterState}
                            setFilterState={setFilterState}
                            filterValue={value}
                            enqueueSnackbar={enqueueSnackbar}
                            {...rest}
                          >
                            <p>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</p>
                          </Tooltip>
                        </Fragment>
                      ) : value && index === 'transaction' ? (
                        <Fragment key={rest.path}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <em
                              className="no-margin s bold"
                              style={{ fontSize: '11px' }}
                            >
                              {title}{' '}
                            </em>
                            <Tooltip
                              key={rest.path}
                              isearmark={true}
                              filterState={filterState}
                              setFilterState={setFilterState}
                              filterValue={value}
                              enqueueSnackbar={enqueueSnackbar}
                              {...rest}
                            >
                              <Earmark>
                                {dayjs(value).format('YYYY-MM-DD HH:mm:ss')}
                              </Earmark>
                            </Tooltip>
                          </div>
                        </Fragment>
                      ) : value && index === 'advertisement' ? (
                        <Fragment key={rest.path}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                            key={rest.path}
                          >
                            <em
                              className="no-margin s bold"
                              style={{ fontSize: '11px' }}
                            >
                              {title}{' '}
                            </em>
                            <Tooltip
                              isearmark={true}
                              filterState={filterState}
                              setFilterState={setFilterState}
                              filterValue={value}
                              enqueueSnackbar={enqueueSnackbar}
                              {...rest}
                            >
                              <Earmark>
                                {dayjs(value).format('YYYY-MM-DD HH:mm:ss')}
                              </Earmark>
                            </Tooltip>
                          </div>
                        </Fragment>
                      ) : null;
                    })
                  : earmarks.map((earmark) => {
                      const { icon, title, ...rest } = earmark;
                      const value = _.get(cleanData, rest.path);
                      if (value) {
                        return (
                          <Tooltip
                            key={rest.path}
                            isearmark={true}
                            filterState={filterState}
                            setFilterState={setFilterState}
                            filterValue={value}
                            enqueueSnackbar={enqueueSnackbar}
                            {...rest}
                          >
                            <Earmark>{value}</Earmark>
                          </Tooltip>
                        );
                      } else {
                        return null;
                      }
                    })}
              </IconContainer>

              <IconContainer id="card-download-icon">
                <em>Download </em>
                <ThemedGetAppIcon
                  onClick={() =>
                    downloadAsPDF(
                      `darkblue-card-${idHash}`,
                      `DarkBlue Record ${idHash}`,
                    )
                  }
                />
              </IconContainer>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <MUITooltip
                  interactive
                  placement="top"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={<ShareRecord data={shareInfo} />}
                >
                  <IconContainer id="card-share-icon">
                    <em>Share</em>
                    <ThemedShareIcon
                      fontSize="small"
                      onClick={() => handleTooltipState()}
                    />
                  </IconContainer>
                </MUITooltip>
              </ClickAwayListener>
              <IconContainer>
                <em>Save</em>
                <StyledSaveIcon
                  id="card-save-icon"
                  onClick={() => {
                    setSearchSaved(!searchSaved);
                    setShowSaveModal(!showSaveModal);
                  }}
                />
              </IconContainer>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {actionTags.map((tag) => {
            return tag;
          })}
        </GridContainer>
        <GridContainer>
          <GridItem
            xs={12}
            style={{
              maxWidth: '100%',
              margin: '12px 0px',
              marginLeft: '-10px',
            }}
          >
            {_.get(cleanData, title.path) ? (
              <>
                <Tooltip
                  filterState={filterState}
                  setFilterState={setFilterState}
                  elasticPath={title.elasticPath}
                  query={title.query}
                  path={title.path}
                  filterValueType={title.filterValueType}
                  filterName={title.filterName}
                  filterValue={_.get(cleanData, title.path)}
                  enqueueSnackbar={enqueueSnackbar}
                  redirectTo={title.redirectTo}
                  redirectLabel={title.redirectLabel}
                >
                  <p className="no-margin bold"> {title.title}</p>
                </Tooltip>
                <SpanCardTitle className="xl bold no-margin">
                  {_.get(cleanData, title.path).length > 3000 ? (
                    <ShowMore>{_.get(cleanData, title.path)}</ShowMore>
                  ) : (
                    <HighlightComponent text={_.get(cleanData, title.path)} />
                  )}
                </SpanCardTitle>
                {title.icon &&
                  React.cloneElement(title.icon, {
                    style: title.icon.props.style,
                    onClick: () => {
                      const value = _.get(cleanData, title.path);
                      const copiedValue =
                        typeof value === 'string' ? value : String(value);

                      const encodedTagUser = encodeURI(`["user"]`);
                      const encodedTagValue = encodeURI(
                        `"${removeMarks(copiedValue)}"`,
                      );
                      window.open(
                        `/datasets/lbc?DocFilter=%7B%22value%22%3A${encodedTagUser}%7D&UserIdFilter=%7B%22value%22%3A${encodedTagValue}%7D`,
                        '_blank',
                      );
                    },
                  })}
              </>
            ) : null}
          </GridItem>
        </GridContainer>
        {expanded ? (
          <ExpandedView
            filterState={filterState}
            setFilterState={setFilterState}
            enqueueSnackbar={enqueueSnackbar}
            data={cleanData}
            dataDict={dataDict}
            index={index}
          />
        ) : (
          body.map((item) => {
            const { icon, title, ...rest } = item;
            const value = _.get(cleanData, rest.path);
            if (!value) {
              return null;
            } else if (csamPresent && titles.includes(title)) {
              return (
                <GridContainer key={item.title}>
                  <CardBodyGridItem style={{ marginLeft: '-11px' }}>
                    <CardBodyContainer>
                      <CardBodyTitle
                        className="s no-margin bold"
                        //dangerouslySetInnerHTML={{ __html: title }}
                      >
                        {title}
                      </CardBodyTitle>
                      <CardBody className="bold no-margin">
                        This record has been flagged by AI as potentially
                        containing Child Sexually Abusive Material (CSAM).
                        Expand this card to view content.
                      </CardBody>
                    </CardBodyContainer>
                  </CardBodyGridItem>
                </GridContainer>
              );
            } else {
              return (
                <GridContainer key={item.path}>
                  <CardBodyGridItem style={{ marginLeft: '-11px' }}>
                    <CardBodyContainer>
                      <Tooltip
                        filterState={filterState}
                        setFilterState={setFilterState}
                        elasticPath={item.elasticPath}
                        path={item.path}
                        query={item.query}
                        filterValueType={item.filterValueType}
                        filterName={item.filterName}
                        filterValue={
                          typeof value === 'string' ? value : String(value)
                        }
                        enqueueSnackbar={enqueueSnackbar}
                        {...rest}
                      >
                        <CardBodyTitle
                          className="s no-margin bold"
                          //dangerouslySetInnerHTML={{ __html: title }}
                        >
                          {title}
                        </CardBodyTitle>
                      </Tooltip>
                      <CardBodySpan
                        className="no-margin"
                        //dangerouslySetInnerHTML={{ __html: value }}
                      >
                        {String(value).length > 3000 ? (
                          <ShowMore>{String(value)}</ShowMore>
                        ) : (
                          <HighlightComponent text={String(value)} />
                        )}
                        {item.icon &&
                          React.cloneElement(item.icon, {
                            style: item.icon.props.style,
                            onClick: () => {
                              const copiedValue =
                                typeof value === 'string'
                                  ? value
                                  : String(value);

                              const encodedTagUser = encodeURI(`["user"]`);
                              const encodedTagValue = encodeURI(
                                `"${removeMarks(copiedValue)}"`,
                              );
                              window.open(
                                `/datasets/lbc?DocFilter=%7B%22value%22%3A${encodedTagUser}%7D&UserIdFilter=%7B%22value%22%3A${encodedTagValue}%7D`,
                                '_blank',
                              );
                            },
                          })}
                      </CardBodySpan>
                    </CardBodyContainer>
                  </CardBodyGridItem>
                </GridContainer>
              );
            }
          })
        )}

        <FooterContainer>
          {footers.map((footer) => {
            const { icon, title, ...rest } = footer;
            const value = _.get(cleanData, rest.path);

            if (!isEmptyValue(value)) {
              return (
                <GridItem
                  style={{ marginLeft: '-11px' }}
                  xs={2}
                  key={footer.title}
                >
                  <GridContainer
                    key={`${footer.title}_${index}`}
                    direction="column"
                  >
                    <Tooltip
                      filterState={filterState}
                      setFilterState={setFilterState}
                      elasticPath={footer.elasticPath}
                      path={footer.path}
                      query={footer.query}
                      filterValueType={footer.filterValueType}
                      filterName={footer.filterName}
                      filterValue={
                        typeof value === 'string' ? value : String(value)
                      }
                      enqueueSnackbar={enqueueSnackbar}
                      {...rest}
                    >
                      <GridItem>
                        <FooterTitle
                          className="no-margin s bold"
                          //dangerouslySetInnerHTML={{ __html: footer.title }}
                        >
                          {title}
                        </FooterTitle>
                      </GridItem>
                    </Tooltip>
                    <GridItem>
                      <FooterBody
                        className="no-margin"
                        // dangerouslySetInnerHTML={{
                        //   __html: value,
                        // }}
                      >
                        <HighlightComponent text={value} />
                      </FooterBody>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              );
            } else {
              return null;
            }
          })}
          <GridItem xs={12} style={{ alignItems: 'center' }}>
            {isTranslated === true ? (
              <p>
                ***Disclaimer: This record has been machine translated into
                English.***{' '}
              </p>
            ) : null}
          </GridItem>
        </FooterContainer>
      </Card>
    </>
  );
};

export default DarkBlueCard;
