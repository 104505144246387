import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import HighlightComponent from '../../darkblue-ui/composite/DarkBlueCard/HighlightComponent.js';
import { Collapse, Link } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';

import styled from 'styled-components';

const ShowMoreContainer = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  }
`;

const ShowMoreText = styled.p`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: 'underline';
`;

const scrollToRef = (ref) =>
  ref.current.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });

export default function ShowMore(props) {
  const { height, more, less, expandDurationEnter, expandDurationExit } = props;

  const collapseRef = useRef(null);
  const [expanded, setExpanded] = useState(false);

  const executeScroll = () => scrollToRef(collapseRef);

  return (
    <>
      <Collapse
        ref={collapseRef}
        in={expanded}
        timeout={{ enter: expandDurationEnter, exit: expandDurationExit }}
        collapsedHeight={height}
      >
        <HighlightComponent text={props.children} />
      </Collapse>
      <span>
        {!expanded ? '....' : null}
        <ShowMoreContainer
          onClick={() => {
            setExpanded(!expanded);
            if (expanded) executeScroll();
          }}
        >
          <ShowMoreText className="s no-margin">
            {!expanded ? more : less}
          </ShowMoreText>
          <ExpandLess
            name="caret"
            style={{
              transform: `rotate( ${!expanded ? '180deg' : '0'})`,
              transition: 'transform .40s',
            }}
          />
        </ShowMoreContainer>
      </span>
    </>
  );
}

ShowMore.defaultProps = {
  height: 60,
  expandDurationEnter: 2000,
  expandDurationExit: 1500,
  more: 'Show more',
  less: 'Show less',
};

ShowMore.propTypes = {
  children: PropTypes.node,
  height: PropTypes.number,
  more: PropTypes.node,
  expandDurationEnter: PropTypes.number,
  expandDurationExit: PropTypes.number,
  less: PropTypes.node,
};
