import dayjs from 'dayjs';

const ipColumns = [
  { Header: 'IP', accessor: 'data.server.ip.ip' },
  { Header: 'ASN', accessor: 'data.server.asn.organization_name' },
  { Header: 'Packets', accessor: 'data.server.packets' },
  { Header: 'Bytes', accessor: 'data.server.bytes' },
  {
    Header: 'Timestamp',
    accessor: '@timestamp',
    Cell: ({ value }) => {
      return dayjs(value).format('YYYY-MM-DD HH:mm');
    },
  },
];

const adIdColumns = [
  {
    Header: 'IP Address',
    accessor: 'ip_address',
  },
  {
    Header: 'Advertising ID',
    accessor: 'advertising_id',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'User Agent',
    accessor: 'user_agent',
  },
];

const datasetColumns = [
  { Header: 'Site', accessor: 'data.site.name' },
  { Header: 'Title', accessor: 'data.title' },
  { Header: 'Post', accessor: 'data.post' },
];

export { ipColumns, adIdColumns, datasetColumns };
