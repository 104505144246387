const terms = [
  { path: '_id' },
  { path: '_score' },
  {
    path: 'bitcoinAddresses',
    filterName: 'BitcoinAddressesFilter',
    elasticPath: ['bitcoinAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'classified',
    sortKey: 3,
    filterName: 'ClassifiedFilter',
    elasticPath: ['classified'],
    filterValueType: 'array',
  },
  {
    path: 'text',
    sortKey: 1,
  },
  {
    path: 'metadata.Title',
  },
  {
    path: 'metadata.Author',
  },
  {
    path: 'moneroAddress',
    filterName: 'MoneroAddressesFilter',
    elasticPath: ['moneroAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'created',
  },
  {
    path: 'metadata.Subject',
  },
  {
    path: 'litecoinAddress',
    elasticPath: ['litecoinAddress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'metadata.Creator',
  },
  {
    path: 'doc.type',
    sortKey: 2,
    filterName: 'DocFilter',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'metadata.Producer',
  },
  {
    path: 'metadata.CreationDate',
  },
  {
    path: 'fileType',
    filterName: 'FileTypeFilter',
    elasticPath: ['fileType.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'fileName',
    filterName: 'FileNameFilter',
    elasticPath: ['fileName.keyword'],
    filterValueType: 'array',
    sortKey: 4,
  },
  {
    path: 'doc.sourcePath',
    filterName: 'DocSourcePathFilter',
    elasticPath: ['doc.sourcePath.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'doc.idHash',
    elasticPath: ['doc.idHash.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'doc.contentHash',
    elasticPath: ['doc.contentHash.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'doc.scraped',
  },
  {
    path: 'doc.processedTime',
  },
  {
    path: 'sourcePage',
    sortKey: 5,
    filterName: 'DocSourcePageFilter',
    elasticPath: ['sourcePage.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'doc.sourceHash',
  },
  {
    path: 'is_primary',
  },
  {
    path: 'fingerprints',
  },
  {
    path: 'doc.segmentId',
  },
  {
    path: 'phoneNumbers',
    filterName: 'PhoneNumbersFilter',
    elasticPath: ['phoneNumbers'],
    filterValueType: 'array',
  },
  { path: 'emails' },
  {
    path: 'ipAddresses',
    filterName: 'IpAddressesFilter',
    elasticPath: ['ipAddresses'],
    filterValueType: 'array',
  },
  {
    path: 'ethereumAddress',
    elasticPath: ['ethereumAddress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'emailAddresses',
    filterName: 'EmailAddressesFilter',
    elasticPath: ['emailAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'links',
    filterName: 'LinksFilter',
    elasticPath: ['links.keyword'],
    filterValueType: 'array',
  },
];

export { terms };
