import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { StateContext } from '../../Search/StateProviderContext/StateProviderContext.js';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { userPreviewQuery } from '../../../utils/Queries/CustomQueries';

import Loader from '../../Spinners/Loader';

import Tooltip from '@material-ui/core/Tooltip';

const UserDetailsContainer = styled.div`
  &&& {
    color: ${(props) => props.theme.colors.primaryText};
    font-weight: 400;
    padding: 12px;
    display: flex;
    justify-content: center;
    border-top: 2px solid ${(props) => props.theme.colors.primaryText};
  }
`;

const UserPreviewCard = (props) => {
  const { userId, author, siteId, id } = props;

  const { apiUrl, apiKey } = useContext(StateContext);
  const [data, setData] = useState({ hits: { hits: [] } });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      const response = await fetch(`${apiUrl}/_search`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${apiKey.key}`,
        },
        method: 'POST',
        body: JSON.stringify(userPreviewQuery(author, userId, siteId)),
      });

      const respData = await response.json();
      setData(respData);
      setLoading(false);
    };

    fetchUser();
  }, []);
  const postCount = data.hits.hits.length;
  const threadPages = props.resultStats.numberOfPages;

  return (
    <UserDetailsContainer>
      {loading ? (
        <p>Loading</p>
      ) : (
        <p>
          {author} has made {postCount} {postCount > 1 ? 'posts' : 'post'} to{' '}
          {siteId} on {threadPages} {threadPages > 1 ? 'threads' : 'thread'}.{' '}
        </p>
      )}
    </UserDetailsContainer>
  );
};

export default UserPreviewCard;
