import React, { useState, useMemo, Fragment } from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';

const Styles = styled.div`
  padding: 1rem;
  table {
    table-layout: fixed;
    border-collapse: collapse;
    border: 1px solid #eee;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: 100%;
    table-layout: ${(props) =>
      props.index === 'scans' ? 'fixed !important;' : null}

    tr {
      &:hover {
        background: #deecfa;

        td {
          color: black;
        }
      }
    }

    th,
    td {
      border: 1px solid #eee;
      padding: 8px;
      border-collapse: collapse;
      text-align: center;
      word-wrap: break-word;
      text-align: left;
    }

    th {
      background: #2a8dfe;
      color: #fff;
      text-transform: capitalize;
      font-size: 12px;
      &.last {
        border-right: none;
      }

      span[role=columnheader] {
        margin-left: 10px
      }


      &:hover {
        cursor: pointer;
      }
    }
  .pagination {
    padding: 0.5rem;
  }
`;

/**
 * @Component LBCUserTable
 * Renders: LBC user table view.
 * @param data Populates the table component
 * @param column A list of table column names
 * @param index The doc type index name
 */
const LBCUserTable = ({ columns, data, index }) => {
  let records = [];
  if (data.selectors)
    Object.keys(data.records).forEach((key) => {
      if (data.records[key]) {
        const newRecords = Object.entries(data.records[key])
          .map(([nestedKey, nestedValue]) => {
            return nestedValue.flat().map((rec) => {
              return {
                ...rec,
                key: `${key}.${nestedKey}`,
                value:
                  typeof rec.value !== 'boolean'
                    ? rec.value
                    : new String(rec.value),
              };
            });
          })
          .flat();
        records = records.concat(newRecords);
      }
    });

  const table = useTable({
    columns,
    ...{
      data: [...new Map(records.map((item) => [item.value, item])).values()],
    },
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = table;

  return (
    <Styles index={index}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((hg) => {
            return (
              <tr key={hg.id} {...hg.getHeaderGroupProps()}>
                {hg.headers.map((col) => {
                  return (
                    <th key={col.id} {...col.getHeaderProps()}>
                      {col.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={`row_${i}`} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td key={cell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td style={{ fontSize: '15px' }} colSpan="10">
                No Records Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Styles>
  );
};

export default LBCUserTable;
