import React, { useMemo, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import { Formik, Field, Form } from 'formik';

import SwitchGroup from '../../../darkblue-ui/Inputs/SwitchGroup';
import HelpToolTipButton from '../../../darkblue-ui/Button/HelpToolTipButton';
import Loader from '../../../darkblue-ui/Spinners/Loader';
import { UserContext } from '../../../DBAuthenticator';

import { getRestrictedFieldState, postRestrictedFields } from './utils';
import Button from '../../../darkblue-ui/Button/Button';

const SettingsCard = styled.div`
  border-radius: 5px !important;
  padding: 10px;
  border-color: #d0d7de;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  width: 100%;

  @media only screen and (max-width: 1050px) {
    border-style: none;
  }
`;

export default function ContentPreferences({ snackBar }) {
  const userSess = useContext(UserContext);
  const {
    userDoc: { _id },
    apiKey,
    user: { username },
    restrictedFields,
    setRestrictedFields,
  } = userSess;

  return (
    <>
      <SettingsCard>
        <h2>Content Preferences</h2>
        <div style={{ paddingBottom: '10px' }}>
          <Divider style={{ backgroundColor: '#d0d7de' }} variant="fullWidth" />
        </div>
        <div>
          <Formik
            enableReinitialize
            initialValues={{ restrictedFields }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const response = await postRestrictedFields(
                values.restrictedFields,
                username,
                apiKey.key,
                _id,
              );
              setRestrictedFields([...values.restrictedFields]);
              resetForm({ values });
              snackBar(`Preferences Saved!`, {
                variant: 'success',
              });
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm, dirty }) => {
              return (
                <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <SwitchGroup
                        style={{ paddingLeft: '14px' }}
                        name="CP/CSAM"
                        id="CP/CSAM"
                        value={(() => {
                          const { isPresent } = getRestrictedFieldState(
                            values.restrictedFields,
                            {
                              elasticPath: 'ai.content.tags',
                              value: 'csam',
                            },
                          );
                          return isPresent;
                        })()}
                        onChange={() => {
                          const { newState } = getRestrictedFieldState(
                            values.restrictedFields,
                            {
                              elasticPath: 'ai.content.tags',
                              value: 'csam',
                            },
                          );
                          setFieldValue('restrictedFields', newState);
                        }}
                        label={'CP/CSAM'}
                      />
                      <HelpToolTipButton
                        style={{ paddingBottom: '40px' }}
                        size="medium"
                        tipPlacement="top"
                        text={`Applies filter to exclude text-based Child Pornography/Child Sexually Abusive Material (CSAM) results from searches when identified by AI. 
                        This is in addition to the site-wide filtering already conducted to exclude images.`}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <p
                        style={{
                          width: '70%',
                          margin: '0px 10px',
                          display: 'inline-block',
                        }}
                      >
                        Enabling this toggle will block any search results
                        tagged by AI as potentially containing CP/CSAM. False
                        positive tagging may restrict results matching searches.
                        NOTE: May take up to 30 seconds to apply.
                      </p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <SwitchGroup
                        style={{ paddingLeft: '14px' }}
                        name="Classified"
                        id="Classified"
                        value={(() => {
                          const { isPresent } = getRestrictedFieldState(
                            values.restrictedFields,
                            {
                              elasticPath: 'classified',
                              value: true,
                            },
                          );
                          return isPresent;
                        })()}
                        onChange={() => {
                          const { newState } = getRestrictedFieldState(
                            values.restrictedFields,
                            {
                              elasticPath: 'classified',
                              value: true,
                            },
                          );
                          setFieldValue('restrictedFields', newState);
                        }}
                        label={'Classified'}
                      />
                      <HelpToolTipButton
                        style={{ paddingBottom: '40px' }}
                        size="medium"
                        tipPlacement="top"
                        text={`Applies filter to block any search results tagged by AI as potentially containing classified material.`}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p
                      style={{
                        width: '70%',
                        margin: '0px 10px',
                        display: 'inline-block',
                      }}
                    >
                      Enabling this toggle will block any search results tagged
                      by AI as potentially containing classified material. False
                      positive tagging may restrict results matching searches.
                      NOTE: May take up to 30 seconds to apply.
                    </p>
                  </div>
                  <div style={{ margin: '55px 10px 0px' }}>
                    <Button
                      disabled={!dirty}
                      type="button"
                      style={{ padding: '10px 20px' }}
                      onClick={(e) => {
                        handleSubmit();
                      }}
                    >
                      Set Preferences
                    </Button>
                    {dirty ? (
                      <span style={{ marginLeft: '5px' }}>
                        <Button
                          type="reset"
                          style={{ padding: '8px 22px' }}
                          secondary
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm();
                          }}
                        >
                          Cancel
                        </Button>
                      </span>
                    ) : null}
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </SettingsCard>
    </>
  );
}
