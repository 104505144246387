import React, { useContext, useEffect, useState } from 'react';
import ReactMapboxGl, { Layer, Feature, Source } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import styled, { ThemeContext } from 'styled-components';
import bodybuilder from 'bodybuilder';
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it
import { fetchData } from 'darkblue-ui/Search/utils';
import Grid from 'darkblue-ui/core/Grid/Grid';
import Loader from 'darkblue-ui/Spinners/Loader';
import { useSnackbar } from 'notistack';
import lookup from 'country-code-lookup';

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
mapboxgl.workerClass = MapboxWorker;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const TopLocations = ({ apiKey, dashboardFilters }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const theme = useContext(ThemeContext);
  const mapTheme = theme.mode || 'dark';
  const aggName = 'countryName_agg';
  const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
    renderWorldCopies: false,
    minZoom: 0,
  });

  const highlightColor = theme.colors.primary;

  useEffect(() => {
    const getTopLocations = async () => {
      try {
        const colorMapping = [
          '#6d027d',
          '#e0000b',
          '#026ccf',
          '#cf8002',
          '#009c08',
        ];
        let locationQuery = bodybuilder();

        Object.entries(dashboardFilters).forEach(([name, filter]) => {
          const { query, value, elasticPath } = filter;
          query(locationQuery, { value, elasticPath });
        });

        locationQuery
          .aggregation(
            'terms',
            { field: 'ai.geo.countries.keyword', size: 15 },
            aggName,
          )
          .size(0)
          .build();
        setLoading(true);
        setError(false);

        const resp = await fetchData({
          canceled: false,
          query: locationQuery.build(),
          apiKey,
          index: ['communications', 'markets', 'sites', 'profiles', 'docs'],
        });

        setData(
          resp.aggregations[aggName].buckets
            .map((b, index) => ({
              name: b.key,
              isoCode: lookup.byCountry(b.key)
                ? lookup.byCountry(b.key).iso3
                : null,
              color: colorMapping[index],
            }))
            .filter((b) => b.isoCode !== null),
        );
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
        setError(true);
        //enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    };

    getTopLocations();
  }, [dashboardFilters]);

  if (loading) return <Loader />;

  if (error) return <h3> An error has occured. Refresh and try again</h3>;

  return (
    data.length > 0 && (
      <Grid>
        <MapContainer>
          <Map
            onStyleLoad={(map) => {
              map
                .addLayer(
                  {
                    id: `heatmap`,
                    source: {
                      type: 'vector',
                      url: 'mapbox://mapbox.country-boundaries-v1',
                    },
                    'source-layer': 'country_boundaries',
                    type: 'fill',
                    paint: {
                      'fill-color': highlightColor,
                      'fill-opacity': 0.4,
                    },
                  },
                  'country-label',
                )
                .setFilter('heatmap', [
                  'all',
                  ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                  [
                    'match',
                    ['get', 'iso_3166_1_alpha_3'],
                    data.map((d) => d.isoCode),
                    true,
                    false,
                  ],
                ]);
            }}
            zoom={[0]}
            style={`mapbox://styles/mapbox/${mapTheme}-v10`}
            containerStyle={{
              height: '100%',
              width: '100%',
              minHeight: '400px',
              minWidth: '400px',
            }}
          ></Map>
        </MapContainer>
      </Grid>
    )
  );
};

export default TopLocations;
