import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Formik, ErrorMessage } from 'formik';
import { UserConsumer } from '../../DBAuthenticator.js';

import Button from '../../darkblue-ui/Button/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Loader from '../../darkblue-ui/Spinners/Loader';
import { tooltip } from 'assets/jss/material-dashboard-pro-react.js';

// Material UI components
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const ModalContainer = styled(GridContainer)`
  background-color: ${(props) => props.theme.colors.cardBackground};
`;

const styles = { ...tooltip };
const useStyles = makeStyles(styles);

const DeleteModal = ({ setShowModal, handleDeleteSubmit, fire, setFire }) => {
  return (
    <UserConsumer>
      {(userSess) => (
        <Formik
          initialValues={{}}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const result = await handleDeleteSubmit(userSess);
            window.setTimeout(() => {
              setFire(fire + 1);
              setShowModal(false);
              setSubmitting(false);
            }, 1500);

            // this is a hacky way to refresh the content of the saved searches,
            // but given time constraints, this is what I am going with
            // window.location.reload(false);
          }}
        >
          {({ handleSubmit, isSubmitting }) =>
            isSubmitting ? (
              <GridContainer justify="center" alignItems="center">
                <Loader color="#2c6982" />
              </GridContainer>
            ) : (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <ModalContainer direction="column">
                  <GridItem>
                    <h3 style={{ paddingLeft: '24px' }}>Delete Saved Search</h3>
                  </GridItem>
                  <GridItem>
                    <DialogActions>
                      <Button color="danger" type="submit">
                        Confirm Deletion
                      </Button>
                      <Button
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </GridItem>
                </ModalContainer>
              </form>
            )
          }
        </Formik>
      )}
    </UserConsumer>
  );
};

export default DeleteModal;
