import Auth from '../../../Auth';

const updateAttribute = async (newUserAttrs) => {
  const user = await getUserInfo();

  try {
    return await Auth.updateUserAttributes(user, { ...newUserAttrs });
  } catch (err) {
    console.log(err);
  }
};

const updateCustomAttribute = async (val, attribute) => {
  const user = await getUserInfo();

  try {
    const result = await Auth.updateUserAttributes(user, {
      [attribute]: val,
    });
    return result;
  } catch (err) {
    console.log(err);
  }
};

const verifyCurrentUserAttribute = async (attribute, code) => {
  try {
    const response = await Auth.verifyCurrentUserAttributeSubmit(
      attribute,
      code,
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

const checkContact = async (user, contact) => {
  try {
    const resp = await Auth.verifiedContact(user);
    const values = await resp.json();
    return values;
  } catch (err) {
    console.log(err);
  }
};

const changePassword = async (oldPassword, newPassword) => {
  try {
    const user = await getUserInfo();

    return await Auth.changePassword(user, oldPassword, newPassword);
  } catch (err) {
    console.log(err);
  }
};
const sendUserVerificationCode = async (attribute) => {
  const user = await getUserInfo();

  try {
    return await Auth.verifyUserAttribute(user, attribute);
  } catch (err) {
    console.log(err);
  }
};

const getUserInfo = async () => {
  const resp = await Auth.currentAuthenticatedUser({ bypassCache: true });
  return resp;
};

const getSettings = async (sectionSettings, userSess = {}) => {
  const user = await getUserInfo();
  const settings = {
    accounts: (({
      username,
      attributes: {
        email,
        phone_number,
        phone_number_verified: phoneVerified,
        email_verified: emailVerified,
        ...rest
      },
    }) => ({
      email,
      username,
      phone_number,
      phoneVerified,
      emailVerified,
      ...rest,
    }))(user),
    preferences: (({
      setRestrictedFields,
      restrictedFields = [],
      apiKey,
      userDoc,
      ...rest
    }) => ({
      setRestrictedFields,
      restrictedFields,
      apiKey,
      userDoc,
      ...rest,
    }))(userSess),
    apperance: (({
      attributes: { ['custom:themePreference']: savedTheme, ...rest },
    }) => ({
      savedTheme,
      ...rest,
    }))(user),
    notifications: (({
      attributes: {
        ['custom:enableEmailAlerts']: enableEmailAlerts,
        email_verified: emailVerified,
        ...rest
      },
    }) => ({
      enableEmailAlerts,
      emailVerified,
      ...rest,
    }))(user),
  };
  return settings[sectionSettings];
};

const getRestrictedFieldState = (currentState, fieldObj) => {
  const { elasticPath, value } = fieldObj;

  const filteredState = currentState.filter(
    (val) => !(val.elasticPath === elasticPath && val.value === value),
  );
  const presentState = currentState.filter(
    (val) => val.elasticPath === elasticPath && val.value === value,
  );
  const isPresent = presentState.length === 1;
  const newState = isPresent
    ? filteredState
    : [...currentState, { value, elasticPath }];
  return { newState, isPresent };
};

const postRestrictedFields = async (restrictedFields, username, key, id) => {
  const userIndex = `.user_${username}`;
  const userApi = 'ApiKey ' + key;
  const postPromise = await fetch(
    `https://${process.env.REACT_APP_API_HOST}/${userIndex}/_update/` + id,
    {
      method: 'POST',
      headers: {
        Authorization: userApi,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        doc: {
          cognito: {
            restrictedFields_v2: restrictedFields,
          },
          doc: {
            type: 'cognito',
          },
        },
      }),
    },
  );
  return true;
};

const setEmailNotifications = async (value) => {
  const user = await getUserInfo();
  const newValue = value === '1' ? true : false;
  try {
    const response = await fetch(
      process.env.REACT_APP_TOGGLE_NOTIFICATIONS_ENDPOINT,
      {
        method: 'POST',
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: user.username,
          enableEmail: newValue,
        }),
      },
    );
    await updateCustomAttribute(value, 'custom:enableEmailAlerts');
  } catch (err) {
    console.log(err);
  }
};

export {
  setEmailNotifications,
  verifyCurrentUserAttribute,
  sendUserVerificationCode,
  getSettings,
  checkContact,
  getUserInfo,
  changePassword,
  updateAttribute,
  getRestrictedFieldState,
  postRestrictedFields,
  updateCustomAttribute,
};
