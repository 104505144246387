import React from 'react';
import styled, { css } from 'styled-components';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const ThemedShareIcon = styled(OpenInNewIcon)`
  color: ${(props) => props.theme.colors.primary} !important;
  vertical-align: middle;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const tags = [
  {
    singular: true,
    indexTag: true,
    path: 'doc.type',
    tagLabel: 'Data Type',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
    filterName: 'DocFilter',
    icon: <AccountBalanceIcon style={{ verticalAlign: 'middle' }} />,
  },
  {
    singular: true,
    path: 'transactionCategory',
    tagLabel: 'Transaction Category',
    filterName: 'TransactionCategoryFilter',
    filterValueType: 'array',
    elasticPath: ['transactionCategory.keyword'],
  },
];

const earmarks = [
  {
    title: 'Transaction Time',
    path: 'timestamp',
  },
];

const title = {
  title: 'Buyer Username',
  path: 'buyerUsername',
  redirectTo: '/search/data',
  redirectLabel: 'Data',
  filterName: 'BuyerUsernameFilter',
  filterValueType: 'array',
  elasticPath: ['buyerUsername.keyword'],
};

const body = [
  {
    title: 'Description',
    path: 'description',
    filterValueType: 'array',
    filterName: 'DescriptionFilter',
    elasticPath: ['description.keyword'],
  },
  {
    title: 'Buyer Id',
    path: 'buyerId',
    filterName: 'BuyerIdFilter',
    filterValueType: 'array',
    elasticPath: ['buyerId.keyword'],
    icon: <ThemedShareIcon />,
  },
  {
    title: 'Seller Username',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    path: 'sellerUsername',
    filterName: 'sellerUsernameFilter',
    filterValueType: 'array',
    elasticPath: ['sellerUsername.keyword'],
  },
  {
    title: 'Seller Id',
    path: 'sellerId',
    filterName: 'SellerIdFilter',
    filterValueType: 'array',
    elasticPath: ['sellerId.keyword'],
    icon: <ThemedShareIcon />,
  },
  {
    title: 'To Wallet Label',
    path: 'toWalletLabel',
    filterName: 'ToWalletLabelFilter',
    filterValueType: 'array',
    elasticPath: ['toWalletLabel.keyword'],
  },
  {
    title: 'From Wallet Label',
    path: 'fromWalletLabel',
    filterName: 'FromWalletLabelFilter',
    filterValueType: 'array',
    elasticPath: ['fromWalletLabel.keyword'],
  },
];

const selectors = [
  'socialMedia',
  'postLinks',
  'phoneNumbers',
  'ipAddresses',
  'emailAddresses',
  'bitcoinAddresses',
];

const footers = [
  {
    title: 'Bitcoin Amount',
    path: 'btcAmount',
  },
  {
    title: 'Bitcoin Address',
    path: 'selectors.bitcoinAddresses',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SelectorBitcoinAddressesFilter',
    filterValueType: 'array',
    elasticPath: ['selectors.bitcoinAddresses.keyword'],
  },
  {
    title: 'Phone Number',
    path: 'selectors.phoneNumbers',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SelectorPhoneNumberFilter',
    filterValueType: 'array',
    elasticPath: ['selectors.phoneNumbers.keyword'],
  },
  {
    title: 'Email Addresses',
    path: 'selectors.emailAddresses',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SelectorEmailAddressesFilter',
    filterValueType: 'array',
    elasticPath: ['selectors.emailAddresses.keyword'],
  },
];

export { tags, title, body, footers, earmarks, selectors };
