import React, { useState, useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { StateContext } from './StateProviderContext/StateProviderContext';
import dayjs from 'dayjs';

//Third Party Libaries
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@material-ui/icons/CheckBoxSharp';

dayjs.locale('en');

//@styled components
const InlineLabel = styled.p`
  display: inline;
  color: ${(props) => props.theme.colors.filterSidebarText};
`;

const StyledRadio = styled(Radio)`
  color: ${(props) => props.theme.colors.filterSidebarText} !important;
  div svg {
    fill: ${(props) =>
      props.checked
        ? props.theme.colors.primary
        : props.theme.colors.multiSelectColor};
  }
  &&&:hover {
    background-color: initial;
    div svg {
      fill: ${(props) => props.theme.colors.altPrimary};
    }
  }
`;

const StyledAccordion = styled.div`
  padding: 0px 44px 0px 15px;
  background-color: ${(props) => props.theme.colors.filterSidebar};
  color: ${(props) => props.theme.colors.filterSidebarText};
  padding-top: 10px;
  margin-left: 10px;
`;

const StyledDateTimePicker = styled(KeyboardDatePicker)`
  .MuiDialogContent-root {
    background-color: orange !important;
    color: blue;
  }

  &&& {
    background-color: ${(props) => props.theme.colors.filterSidebar};
    color: ${(props) => props.theme.colors.filterSidebarText};
  }

  &&& p {
    color: ${(props) => props.theme.colors.filterSidebarText};
  }

  &&& input,
  label,
  button {
    background-color: ${(props) => props.theme.colors.filterSidebar};
    color: ${(props) => props.theme.colors.filterSidebarText};
  }
`;

const StyledToolbar = styled(GridContainer)`
  background-color: ${(props) => props.theme.colors.filterSidebar};
  color: ${(props) => props.theme.colors.filterSidebarText};
  padding: 15px;
  &&& div p {
    color: ${(props) => props.theme.colors.filterSidebarText};
    font-size: 18px;
    font-family: 'Red Hat Text', sans-serif !important;
  }

  &&& {
    font-family: 'Red Hat Text', sans-serif !important;
  }
`;

const ToolbarTypography = styled.div`
  color: ${(props) => props.theme.colors.filterSidebarText};
  &&& {
    padding: 0px;
  }
  &&& p select input div {
    font-family: 'Red Hat Text', sans-serif !important;
    color: ${(props) => props.theme.colors.filterSidebarText};
    font-size: 18px;
    padding: 0px;
  }
`;

const StyledSelect = styled(Select)`
  display: inline;
  padding: 0px;
  &&& .MuiSelect-select.MuiSelect-select {
    padding: 5px;
    color: ${(props) => props.theme.colors.filterSidebarText};
    font-family: 'Red Hat Text', sans-serif !important;
  }

  &&& .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.theme.colors.filterSidebarText};
  }
`;

const CustomSpan = styled.span`
  margin-top: 20px;
  color: red;
  font-size: 13px;
`;

/**
 * @component
 * Time Select Input
 * @param  {string} timeUnit Specified time unit
 * @param  {obj} props component properties
 */
const TimeSelect = ({ timeUnit, props }) => {
  const { date, onChange } = props;
  const [value, setValue] = useState(0);
  const [digits, setDigits] = useState(2);
  const [timeRange, setTimeRange] = useState([]);

  const range = (start, end) => {
    if (start === end) return [start];
    return [start, ...range(start + 1, end)];
  };

  useEffect(() => {
    const startYear = dayjs().year() - 40;
    switch (timeUnit) {
      case 'seconds':
        setTimeRange([...range(0, 60)]);
        setValue(dayjs(date).second());
        return;
      case 'minutes':
        setTimeRange([...range(0, 60)]);
        setValue(dayjs(date).minute());
        return;
      case 'hours':
        setTimeRange([...range(0, 60)]);
        setValue(dayjs(date).hour());
        return;
      case 'years':
        setTimeRange([...range(startYear, dayjs().year())]);
        setDigits(4);
        setValue(dayjs(date).year());
        return;
    }
  }, []);

  const handleChange = (event) => {
    const tmpValue = event.target.value;
    setValue(tmpValue);
    switch (timeUnit) {
      case 'seconds':
        onChange(new Date(dayjs(date).second(tmpValue)));
        return;
      case 'minutes':
        onChange(new Date(dayjs(date).minute(tmpValue)));
        return;
      case 'hours':
        onChange(new Date(dayjs(date).hour(tmpValue)));
        return;
      case 'years':
        onChange(new Date(dayjs(date).year(tmpValue)));
        return;
    }
  };

  return (
    <StyledSelect
      value={value}
      onChange={handleChange}
      IconComponent={() => null}
    >
      {timeRange.map((timeVal) => {
        const fmtTimeVal = ('0' + timeVal).slice(-digits);
        return (
          <MenuItem key={fmtTimeVal} value={timeVal}>
            {fmtTimeVal}
          </MenuItem>
        );
      })}
    </StyledSelect>
  );
};

//Datetime Picker Input Select options
const HourSelect = (props) => {
  return <TimeSelect props={props} timeUnit="hours" />;
};

const MinuteSelect = (props) => {
  return <TimeSelect props={props} timeUnit="minutes" />;
};

const YearSelect = (props) => {
  return <TimeSelect props={props} timeUnit="years" />;
};

/**
 * A span element to show user error message.
 */
const CustomErrorMessage = ({ text }) => {
  return <CustomSpan>{text}</CustomSpan>;
};

/**
 * @component
 * Datetime picker component header
 * @param  {obj} props component properties
 */
const CustomToolbar = (props) => {
  const fmtDate = dayjs(props.date).format('MMM DD');
  return (
    <StyledToolbar direction="row" justifyContent="space-between">
      <GridItem xs={7}>
        <ToolbarTypography>
          {fmtDate}, <YearSelect date={props.date} onChange={props.onChange} />
        </ToolbarTypography>
      </GridItem>
      <GridItem xs={5}>
        <ToolbarTypography style={{ float: 'right' }}>
          <HourSelect date={props.date} onChange={props.onChange} />:
          <MinuteSelect date={props.date} onChange={props.onChange} />
        </ToolbarTypography>
      </GridItem>
    </StyledToolbar>
  );
};

/**
 * Material Ui theming style function.
 * @param  {obj} themeContext - Theming context
 */
const materialTheme = (themeContext) => {
  return createTheme({
    overrides: {
      MuiInputBase: {
        root: {
          '& .MuiButtonBase-root ': {
            backgroundColor: 'unset',
          },
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: themeContext.dateTimePickerColors.muiToolbar,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: themeContext.dateTimePickerColors.muiHeader,
        },
        iconButton: {
          backgroundColor: 'unset',
        },
        transitionContainer: {
          '& p': {
            color: themeContext.dateTimePickerColors.muiText,
          },
        },
      },
      MuiPickersCalendar: {
        transitionContainer: {
          '& .MuiPickersDay-daySelected': {
            backgroundColor: themeContext.dateTimePickerColors.buttonColor,
          },
        },
      },
      MuiPaper: {
        root: {
          backgroundColor:
            themeContext.dateTimePickerColors.muiPaperBackgroundColor,
        },
      },
      MuiDialogContent: {
        root: {
          overflowX: 'hidden',
        },
      },
      MuiDialogActions: {
        spacing: {
          '& .MuiButton-textPrimary': {
            color: themeContext.dateTimePickerColors.muiText,
          },
          '& .MuiButton-textPrimary > span': {
            fontWeight: 500,
          },
        },
      },
    },
  });
};

/**
 * @Component
 * Datetime Picker component
 * @param  {string} elasticPath The elastic  timestamp path name
 * @param  {string} filterName The date time filter name
 */
const DateTimeFilter = ({ elasticPath, filterName, index }) => {
  const [value, setValue] = useState('');
  const { filterState, setFilterState } = useContext(StateContext);
  const [expanded, setExpanded] = useState(false);
  const themeContext = useContext(ThemeContext);

  let now = dayjs();
  let today = now
    .clone()
    .hour(0)
    .minute(0)
    .second(0);

  const eodToday = dayjs()
    .hour(23)
    .minute(59)
    .second(59);

  const [start, setStart] = useState();
  const [end, setEnd] = useState(
    now
      .clone()
      .hour(23)
      .minute(59)
      .second(59),
  );
  const [customDate, setCustomDate] = useState({ start: null, end: null });
  const [errorText, setErrorText] = useState('');

  const handleChange = (event) => {
    const tmpValue = event.target.value;
    setValue(tmpValue);
    switch (tmpValue) {
      case 'week':
        setStart(today.subtract(7, 'day'));
        setEnd(eodToday);
        setExpanded(false);
        return;
      case 'month':
        setStart(today.subtract(1, 'month'));
        setEnd(eodToday);
        setExpanded(false);
        return;
      case 'year':
        setStart(today.subtract(1, 'year'));
        setEnd(eodToday);
        setExpanded(false);
        return;
      case 'all':
        setStart(today.subtract(30, 'year'));
        setEnd(eodToday);
        setExpanded(false);
        return;
      case 'custom':
        setExpanded(true);
        return;
    }
  };

  /**
   * @function
   *  check URL for DateTimeFilter start and end values
   */
  const readDatesFromURL = () => {
    if (
      filterState[filterName] &&
      filterState[filterName].value &&
      filterState[filterName].value.endValue &&
      filterState[filterName].value.startValue
    ) {
      switch (filterState[filterName].value.startValue) {
        case today.subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss'):
          setValue('week');
          return;
        case today.subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'):
          setValue('month');
          return;
        case today.subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss'):
          setValue('year');
          return;
        case today.subtract(30, 'year').format('YYYY-MM-DD HH:mm:ss'):
          setValue('all');
          return;
        default:
          setValue('custom');
          setExpanded(true);
          setCustomDate({
            start: filterState[filterName].value.startValue,
            end: filterState[filterName].value.endValue,
          });
          return;
      }
    } else {
      return false;
    }
  };

  /**
   * @function
   * Start and end date time state
   * @param  {obj<dayjs>} start start date
   * @param  {obj<dayjs>} end end date
   */
  const updateStartEndState = (start, end) => {
    setFilterState({
      ...filterState,
      ...{
        [filterName]: {
          elasticPath: elasticPath,
          value: {
            startValue: start.format('YYYY-MM-DD HH:mm:ss'),
            endValue: end.format('YYYY-MM-DD HH:mm:ss'),
          },
        },
      },
    });
  };

  /**
   * @function
   *  validates start and end dates
   * @param  {} startDate The specified start date
   * @param  {} endDate The specified end date
   */
  const checkDateValidation = (startDate, endDate) => {
    if (
      (startDate > endDate || endDate < startDate) &&
      startDate &&
      endDate &&
      startDate.toDateString() !== endDate.toDateString()
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (expanded && customDate.start && customDate.end && !errorText) {
      updateStartEndState(dayjs(customDate.start), dayjs(customDate.end));
    } else if (!expanded && value.length) {
      updateStartEndState(start, end);
    }
  }, [start, end, customDate]);

  useEffect(() => {
    if (!filterState[filterName]) {
      setValue('');
      setCustomDate({ start: null, end: null });
    } else if (
      customDate.start !== filterState[filterName].value.startValue &&
      customDate.end !== filterState[filterName].value.endValue
    ) {
      readDatesFromURL();
    }
  }, [filterState[filterName]]);

  const standardOptions = [
    { formVal: 'week', name: 'timerange-week', displayName: 'Past Week' },
    { formVal: 'month', name: 'timerange-month', displayName: 'Past Month' },
    { formVal: 'year', name: 'timerange-year', displayName: 'Past Year' },
    { formVal: 'all', name: 'timerange-all', displayName: 'All Time' },
  ];

  return (
    <GridContainer>
      <>
        {standardOptions.map(({ formVal, displayName, name }) => {
          return (
            <GridItem key={`${displayName}_value${name}`} xs={12}>
              <StyledRadio
                checked={value === formVal}
                onClick={handleChange}
                value={formVal}
                name={name}
                key={`${displayName}_value${name}`}
              />
              <InlineLabel>{displayName}</InlineLabel>
            </GridItem>
          );
        })}
      </>
      <GridItem>
        <StyledRadio
          checked={value === 'custom'}
          onClick={handleChange}
          value="custom"
          name="timerange-custom"
          key="custom-radio"
        />
        <InlineLabel>Custom</InlineLabel>
        {expanded ? (
          <StyledAccordion>
            <GridContainer>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={materialTheme(themeContext)}>
                  <StyledDateTimePicker
                    emptyLabel="Start Date"
                    ampm={false}
                    cancelLabel={'Cancel'}
                    okLabel={'Apply'}
                    value={customDate.start}
                    onChange={(value) => {
                      setCustomDate({
                        ...customDate,
                        start: value,
                      });
                      if (value && checkDateValidation(value, customDate.end)) {
                        setErrorText('Start date must be before end date. ');
                        return;
                      } else {
                        setErrorText('');
                      }
                    }}
                    error={errorText ? true : false}
                    format="MM/dd/yyyy HH:mm"
                    maxDate={now.subtract(1, 'hour')}
                    ToolbarComponent={CustomToolbar}
                    style={{ paddingBottom: '15px' }}
                  />
                  <StyledDateTimePicker
                    ampm={false}
                    emptyLabel="End Date"
                    cancelLabel={'Cancel'}
                    okLabel={'Apply'}
                    value={customDate.end}
                    onChange={(value) => {
                      setCustomDate({
                        ...customDate,
                        end: value,
                      });

                      if (
                        value &&
                        checkDateValidation(customDate.start, value)
                      ) {
                        setErrorText('End date must be after start date.');
                        return;
                      } else {
                        setErrorText('');
                      }
                    }}
                    error={errorText ? true : false}
                    format="MM/dd/yyyy HH:mm"
                    maxDate={today}
                    ToolbarComponent={CustomToolbar}
                    todayLabel={'today'}
                  />

                  {errorText && <CustomErrorMessage text={errorText} />}
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </GridContainer>
          </StyledAccordion>
        ) : null}
      </GridItem>
    </GridContainer>
  );
};

export default DateTimeFilter;
