import dayjs from 'dayjs';
import { AllFilterNames, UniversalFieldNameList } from './UniversalFilters';

import { indexFilterNames } from '../utils/Functions/utils';
const dateTimeFormatter = (date, end) => {
  return date + (end ? ' 23:59:59' : ' 00:00:00');
};

const formatDateObject = (date) => {
  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  const dateString = year + '-' + month + '-' + day;
  return new Date(dateString);
};

const formatDateObjectToString = (date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const dateString = year + '-' + month + '-' + day;
  return dateString;
};
const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const cleanFilterField = (field) => {
  const removeMark = new RegExp('(<mark>|</mark>)', 'g');
  return String(field).replace(removeMark, '');
};

const getDefaultFilterState = (filters, indexState = {}) => {
  let defaultFilterState = {};
  const today = new Date();

  const p7 = new Date();
  p7.setDate(today.getDate() - 7);

  filters.forEach((filter) => {
    switch (filter.props.filterType) {
      case 'NotFilter':
        defaultFilterState[filter.props.componentId] = [];
        return;
      case 'RequiredFieldFilter':
        defaultFilterState[filter.props.componentId] = [];
        return;
      case 'DateRange':
        defaultFilterState[filter.props.componentId] = {
          start: p7,
          end: today,
        };
        return;
      case 'CalendarFilter':
        defaultFilterState[filter.props.componentId] = {
          start: p7,
          end: today,
        };
        return;
      case 'MultiDropdownList':
        defaultFilterState[filter.props.componentId] = [];
        return;
      case 'SelectFilter':
        defaultFilterState[filter.props.componentId] = [];
        return;
      case 'DataSearch':
        defaultFilterState[filter.props.componentId] = '';
        return;
      case 'TextFilter':
        defaultFilterState[filter.props.componentId] = '';
        return;
      case 'GeoPointFilter':
        defaultFilterState[filter.props.componentId] = '';
        return;
      case 'RadioFilter':
        defaultFilterState[filter.props.componentId] = '';
        return;
      default:
        return;
    }
  });

  return { ...defaultFilterState, ...indexState };
};

const removeInvalidArrayElements = (val) => {
  if (Array.isArray(val)) {
    return val.filter((v) => v);
  } else {
    return val;
  }
};

const getUrlFilterParams = (location) => {
  const params = new URLSearchParams(location);
  let urlState = [];
  const filterNames = AllFilterNames();
  params.forEach((value, key) => {
    if (filterNames.includes(key)) {
      const defaultValue = removeInvalidArrayElements(JSON.parse(value));
      if (key !== 'TimeFilter' && defaultValue) {
        urlState.push([key, defaultValue]);
      } else {
        urlState.push([
          key,
          {
            start: dayjs(JSON.parse(value)[0])
              .hour(0)
              .minute(0)
              .locale('en-us')
              .toDate(),
            end: dayjs(JSON.parse(value)[1])
              .locale('en-us')
              .toDate(),
          },
        ]);
      }
    }
  });

  return Object.fromEntries(urlState);
};

const getFilterValues = (indices = []) => {
  const filterArrays = indices
    .map((index) => {
      return UniversalFieldNameList[index];
    })
    .filter((x) => x);
  const filterNames = [].concat.apply([], filterArrays);
  return [...new Set(filterNames)];
};

/**
 * Returns a filtered set of filters Names based by indices.
 * @param  {Array<string>} indices the index value
 * @returns A list of string filter Names
 * Indicies = ["profiles", "sites", "docs"]
 */
const getFilteredNames = (indices = []) => {
  const filterArrays = indices
    .map((index) => {
      return indexFilterNames(index);
    })
    .flat()
    .filter((f) => f && f.hasOwnProperty('filterName'))
    .map((item) => {
      return item.filterName;
    });
  const filterNames = [].concat.apply([], filterArrays);
  return [...new Set(filterNames)];
};

const getValuesForIndex = (indices = [], universalObject) => {
  const valuesArray = indices
    .map((index) => {
      return universalObject[index];
    })
    .filter((x) => x);

  const values = [].concat.apply([], valuesArray);
  return [...new Set(values)];
};

const getDefaultIndexFilterState = (indices = [], defaultFilterValueObject) => {
  let state = {};
  indices.forEach((index) => {
    if (defaultFilterValueObject[index]) {
      state = { ...state, ...defaultFilterValueObject[index] };
    }
  });
  return state;
};

export {
  dateTimeFormatter,
  formatDateObject,
  formatDateObjectToString,
  numberWithCommas,
  getDefaultFilterState,
  getUrlFilterParams,
  getFilterValues,
  getFilteredNames,
  getDefaultIndexFilterState,
  getValuesForIndex,
};
