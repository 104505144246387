const terms = [
  { path: 'age' },
  { path: 'ai.corporateRisk' },
  {
    path: 'ai.language',
    filterName: 'LanguageFilter',
    elasticPath: ['ai.location.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.location',
    sortKey: 7,
    filterName: 'LocationFilter',
    elasticPath: ['ai.location.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.tags',
    filterName: 'ContentTagsFilter',
    elasticPath: ['ai.content.tags.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.csam',
    elasticPath: ['ai.content.csam.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.drugs',
    filterName: 'ProfileDrugContentFilter',
    elasticPath: ['ai.content.drugs.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.fraud',
    elasticPath: ['ai.content.fraud.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.leaks',
    elasticPath: ['ai.content.leaks.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.technology',
    elasticPath: ['ai.content.technology.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.weapons',
    elasticPath: ['ai.content.weapons.keyword'],
    filterValueType: 'array',
  },
  { path: 'ai.user.language' },
  {
    path: 'ai.content.violence',
    elasticPath: ['ai.content.violence.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.email',
    elasticPath: ['ai.user.selectors.email.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.emailAddresses',
    elasticPath: ['ai.user.selectors.emailAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.bitcoinAddress',
    elasticPath: ['ai.user.selectors.bitcoinAddress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.bitcoinAddresses',
    elasticPath: ['ai.user.selectors.bitcoinAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.pgp.identities.emails',
    elasticPath: ['ai.user.selectors.pgp.identities.emails.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.pgp.identities.names',
    elasticPath: ['ai.user.selectors.pgp.identities.names.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.pgp.fingerprints',
    elasticPath: ['ai.user.selectors.pgp.fingerprints.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.litecoinAddress',
    elasticPath: ['ai.user.selectors.litecoinAddress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.moneroAddress',
    elasticPath: ['ai.user.selectors.moneroAddress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.pgpKeys',
    elasticPath: ['ai.user.selectors.pgpKeys.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.phoneNumbers',
    elasticPath: ['ai.user.selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.phone',
    elasticPath: ['ai.user.selectors.phone.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.postLinks',
    elasticPath: ['ai.user.selectors.postLinks.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.selectors.location',
    elasticPath: ['ai.user.selectors.location.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.matches.names.exact',
    elasticPath: ['ai.user.matches.names.exact.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.matches.names.fuzzy',
    elasticPath: ['ai.user.matches.names.fuzzy.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.matches.selectors.pgp',
    elasticPath: ['ai.user.matches.selectors.pgp.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.matches.selectors.phoneNumbers',
    elasticPath: ['ai.user.matches.selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.matches.selectors.pgp.identities.emails',
    elasticPath: ['ai.user.matches.selectors.pgp.identities.emails.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.matches.selectors.pgp.fingerprints',
    elasticPath: ['ai.user.matches.selectors.pgp.fingerprints.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.matches.selectors.emailAddresses',
    elasticPath: ['ai.user.matches.selectors.emailAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.matches.selectors.bitcoinAddresses',
    elasticPath: ['ai.user.matches.selectors.bitcoinAddresses.keyword'],
    filterValueType: 'array',
  },
  { path: 'ai.user.matches.selectors.pgp.identities.emails' },
  {
    path: 'ai.user.activity.lastPost',
    elasticPath: ['ai.user.activity.lastPost.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.user.activity.lastPostType',
    elasticPath: ['ai.user.activity.lastPostType.keyword'],
    filterValueType: 'array',
  },
  { path: 'ai.user.activity.posts30day' },
  { path: 'ai.user.activity.posts7day' },
  {
    path: 'ai.usper.potential',
    elasticPath: ['ai.usper.potential'],
  },
  {
    path: 'ai.usper.explanation',
    elasticPath: ['ai.usper.explanation.keyword'],
  },
  {
    path: 'author',
    sortKey: 3,
    filterName: 'AuthorFilter',
    elasticPath: ['author.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'bitcoinAddresses',
    filterName: 'BitcoinAddressesFilter',
    elasticPath: ['bitcoinAddresses.keyword'],
    filterValueType: 'array',
  },
  ,
  {
    path: 'description',
    sortKey: 4,
    filterName: 'AuthorDescriptionFilter',
    elasticPath: ['description.keyword'],
    filterValueType: 'array',
  },
  { path: 'doc.contentHash' },
  {
    path: 'doc.idHash',
    filterName: 'DocIdFilter',
    elasticPath: ['doc.idHash.keyword'],
    filterValueType: 'array',
  },
  { path: 'doc.priorVersions' },
  { path: 'doc.processedTime' },
  {
    path: 'doc.type',
    filterName: 'DocFilter',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'emailAddresses',
    filterName: 'EmailAddressesFilter',
    elasticPath: ['emailAddresses.keyword'],
    filterValueType: 'array',
  },
  { path: 'followers' },
  { path: 'following' },

  {
    path: 'ethereumAddresses',
    filterName: 'EthereumAddressesFilter',
    elasticPath: ['ethereumAddresses.keyword'],
    filterValueType: 'array',
  },
  { path: 'joined', sortKey: 9 },
  { path: 'lastVisit', sortKey: 8 },
  {
    path: 'litecoinAddress',
    elasticPath: ['litecoinAddress.keyword'],
    filterValueType: 'array',
  },
  { path: 'minutesSpentOnline' },
  {
    path: 'moneroAddresses',
    filterName: 'MoneroAddressesFilter',
    elasticPath: ['moneroAddresses.keyword'],
    filterValueType: 'array',
  },
  { path: 'numberOfLikes' },
  { path: 'numberOfMediaPosts' },
  { path: 'numberOfThreads' },
  {
    path: 'numberOfThreads',
    filterName: 'NumberOfThreadsFilter',
    elasticPath: ['numberOfThreads.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'name',
    filterName: 'NameFilter',
    elasticPath: ['name.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'page',
    filterName: 'PageFilter',
    elasticPath: ['page.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgpKeys',
    elasticPath: ['pgpKeys.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.armoredKeys',
    elasticPath: ['pgp.armoredKeys.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.fingerprints',
    filterName: 'PgpFingerPrintsFilter',
    elasticPath: ['pgp.fingerprints.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.created',
    elasticPath: ['pgp.created.keyword'],
    filterValueType: 'array',
  },
  { path: '_id' },

  {
    path: 'pgp.identities.comments',
    elasticPath: ['pgp.identities.comments.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.identities.emails',
    elasticPath: ['pgp.identities.emails.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.identities.names',
    elasticPath: ['pgp.identities.names.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'pgp.identities.signers',
  },
  { path: 'pgp.identities.is_primary' },
  {
    path: 'phoneNumbers',
    elasticPath: ['phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'postal.uspsExpress',
    elasticPath: ['postal.uspsExpress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'postal.uspsPriority',
    elasticPath: ['postal.uspsPriority.keyword'],
    filterValueType: 'array',
  },
  { path: 'postLinks' },
  { path: 'rating', sortKey: 5 },
  {
    path: 'role',
    sortKey: 6,
    filterName: 'RoleFilter',
    elasticPath: ['role.keyword'],
    filterValueType: 'array',
  },
  { path: 'scraped' },
  {
    path: 'selectors.creditCards',
    elasticPath: ['selectors.creditCards.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.identity',
    elasticPath: ['selectors.identity.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.phoneNumbers',
    elasticPath: ['selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  { path: 'signature' },
  { path: 'site.id', sortKey: 2 },
  {
    path: 'site.name',
    sortKey: 1,
    filterName: 'SiteNameFilter',
    elasticPath: ['site.name.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'site.net',
    sortKey: 13,
    filterName: 'SiteNetFilter',
    elasticPath: ['site.net.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'site.type',
    sortKey: 12,
    filterName: 'SiteTypeFilter',
    elasticPath: ['site.type.keyword'],
    filterValueType: 'array',
  },
  { path: 'socialMedia', sortKey: 11 },
  { path: 'timestamp' },
  {
    path: 'userId',
    filterName: 'UserIdFilter',
    elasticPath: ['userId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'userPhone',
    elasticPath: ['userPhone.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'website',
    elasticPath: ['website.keyword'],
    filterValueType: 'array',
  },
];

export { terms };
