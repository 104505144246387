import Grid from '../core/Grid/Grid';
import styled from 'styled-components';
import { colorChartItem, gradientItem } from '../styles/colors/utils';

const TrendingGrid = styled(Grid)`
    margin: 40px;
`

const TrendingTermsList = styled.ol`
  word-break: break-all;
  margin-block-start: 0px;
  position: relative;
`;

const TrendingTermsParagraph = styled.li`
  &:before {
    left: 4px;
    content: ${(props) => `'${props.index}'`};
    position: absolute;
    color: ${(props) => props.theme.colors.fog};
    background: ${(props) =>
      gradientItem(props.theme, `trendingItem ${props.index}`, props.index)};
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  text-decoration: underline;
  font-weight: 400;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
  padding: 8px 0px;
`;

export {
    TrendingGrid,
    TrendingTermsList,
    TrendingTermsParagraph
}