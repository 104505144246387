import React from 'react';
import PersonIcon from '@material-ui/icons/Person';

const tags = [
  {
    singular: true,
    indexTag: true,
    path: 'doc.type',
    tagLabel: 'Data Type',
    elasticPath: ['doc.type.keyword'],
    filterName: 'DocFilter',
    icon: <PersonIcon style={{ verticalAlign: 'middle' }} />,
  },
  {
    singular: true,
    path: 'status.isStaff.values',
    tagLabel: 'Is Staff',
  },
  {
    singular: true,
    path: 'status.isSuperuser.values',
    tagLabel: 'Is Superuser',
  },
  {
    singular: false,
    path: 'selectors.languages',
    filterName: 'LBCLanguageFilter',
    filterValueType: 'array',
    elasticPath: ['selectors.languages.keyword'],
    tagLabel: 'Language',
  },
  {
    singular: false,
    path: 'selectors.locations',
    filterName: 'LocationsFilter',
    filterValueType: 'array',
    tagLabel: 'Location',
    elasticPath: ['selectors.locations.keyword'],
  },
  {
    singular: false,
    path: 'tags',
    tagLabel: 'Tags',
    elasticPath: ['tags.keyword'],
    filterName: 'TagsFilter',
    filterValueType: 'array',
  },
];

const earmarks = [
  {
    title: 'Created At',
    path: 'timestamp',
  },
];

const title = {
  title: 'Name',
  path: 'selectors.names',
  filterName: 'SelectorNameFilter',
  redirectTo: '/search/data',
  redirectLabel: 'Data',
  filterValueType: 'array',
  elasticPath: ['selectors.names.keyword'],
};

const body = [
  {
    title: 'Introduction',
    path: 'introduction',
    filterName: 'IntroductionFilter',
    filterValueType: 'array',
    elasticPath: ['introduction.keyword'],
  },
  {
    title: 'Username',
    path: 'selectors.usernames',
    filterName: 'UserNameFilter',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterValueType: 'array',
    elasticPath: ['selectors.usernames.keyword'],
  },
  {
    title: 'User ID',
    path: 'userId',
    filterName: 'UserIdFilter',
    elasticPath: ['userId.keyword'],
    filterValueType: 'array',
  },
  {
    title: 'Email Addresses',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SelectorEmailAddressesFilter',
    path: 'selectors.emailAddresses',
    filterValueType: 'array',
    elasticPath: ['selectors.emailAddresses.keyword'],
  },
  {
    title: 'Phone Numbers',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SelectorPhoneNumberFilter',
    filterValueType: 'array',
    path: 'selectors.phoneNumbers',
    elasticPath: ['selectors.phoneNumbers.keyword'],
  },
  {
    title: 'Bitcoin Addresses',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SelectorBitcoinAddressesFilter',
    path: 'selectors.bitcoinAddresses',
    filterValueType: 'array',
    elasticPath: ['selectors.bitcoinAddresses.keyword'],
  },
];

const footers = [
  { title: 'Advertisements', path: 'activity.advertisements' },
  {
    title: 'Transactions',
    path: 'activity.transactions',
  },
  {
    title: 'Messages',
    path: 'activity.messages',
  },
  {
    title: 'Page Links',
    filterName: 'PageLinkFilter',
    path: 'selectors.pageLinks',
    elasticPath: ['selectors.pageLinks.keyword'],
    filterValueType: 'array',
  },
  {
    title: 'IP Addresses',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'IpAddressFilter',
    path: 'selectors.ipAddresses',
    filterValueType: 'array',
    elasticPath: ['selectors.ipAddresses.keyword'],
  },
  {
    title: 'Post Links',
    filterName: 'PostLinksFilter',
    path: 'selectors.postLinks',
    filterValueType: 'array',
    elasticPath: ['selectors.postLinks.keyword'],
  },

  {
    title: 'Social Security Number',
    filterName: 'SSNFilter',
    filterValueType: 'array',
    path: 'selectors.ssn',
    elasticPath: ['selectors.ssn.keyword'],
  },
  {
    title: 'Timezones',
    path: 'selectors.timezones',
  },
];
export { tags, title, body, footers, earmarks };
