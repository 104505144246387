import React, { useState, useEffect, createContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import useLocalStorage from 'utils/Hooks/SetLocalStorage';
import { ThemeProvider } from 'styled-components';
import Auth, { Storage } from './Auth';
import App from './App';
import { filterRoutes } from 'routes.js';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Modal from 'darkblue-ui/Modals/Modal/Modal';
import lightTheme from 'darkblue-ui/styles/colors/lightTheme';
import {
  LoginBackground,
  DBLogo,
  BSLogo,
  PoweredBy,
} from 'darkblue-ui/Login/DBComponents.js';
import Loader from 'darkblue-ui/Spinners/Loader';
import DBLogoImg from 'assets/img/DarkBlue.svg';
import BSLogoImg from 'assets/img/BluestoneLogo.png';
import { AllFilterNames } from './filters/UniversalFilters';

import { SnackbarProvider } from 'notistack';

const hist = createBrowserHistory();
dayjs.locale('en');

export const UserContext = createContext({
  user: null,
  appPerms: null,
  featurePerms: [],
  restrictedFields: [],
  userDoc: null,
  setUser: null,
  setRestrictedFields: null,
  routes: null,
  apiKey: null,
  authComplete: false,
  auth: Auth,
  tos: false,
  storage: Storage,
});

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

const removeInvalidArrayElements = (val) => {
  if (Array.isArray(val)) {
    return val.filter((v) => v);
  } else {
    return val;
  }
};

const getUrlFilterParams = (location) => {
  const params = new URLSearchParams(location);
  const filterNames = AllFilterNames();
  let urlState = [];

  params.forEach((value, key) => {
    if (filterNames.includes(key)) {
      const defaultValue = removeInvalidArrayElements(JSON.parse(value));
      if (key !== 'TimeFilter' && defaultValue) {
        urlState.push([key, defaultValue]);
      } else {
        urlState.push([
          key,
          {
            start: dayjs(JSON.parse(value)[0])
              .hour(0)
              .minute(0)
              .locale('en-us')
              .toDate(),
            end: dayjs(JSON.parse(value)[1])
              .locale('en-us')
              .toDate(),
          },
        ]);
      }
    }
  });
  return Object.fromEntries(urlState);
};

const getUrlParams = (location) => {
  const params = new URLSearchParams(location);
  let urlState = [];
  params.forEach((value, key) => {
    const defaultValue = removeInvalidArrayElements(JSON.parse(value));
    urlState.push([key, defaultValue]);
  });
  return Object.fromEntries(urlState);
};

const signout = async () => {
  localStorage.clear();
  Object.entries(Cookies.get()).forEach((cookie) => {
    if (cookie[0].indexOf('Cognito') > -1) {
      Cookies.remove(cookie[0]);
    }
  });

  window.location = process.env.REACT_APP_COG_SIGN_OUT;
  return null;
};

const ModalContent = styled.div`
  font-family: 'Red Hat Text', sans-serif !important;
  border-radius: 0px;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.primaryText};
  width: 600px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.cardBackground};

  & h3 {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const RedirectComponent = () => {
  const location = useLocation();

  const urlParams = getUrlFilterParams(location.search);
  const target = urlParams.target || 'darkblue';

  const redirectTo = (authSignInUrl) => {
    return window.location.pathname
      ? `${authSignInUrl + window.location.pathname + window.location.search}`
      : authSignInUrl;
  };

  const setRedirect = async () => {
    if (target === 'darkblue') {
      window.location.href = redirectTo(Auth._config.oauth.redirectSignOut);
    }
  };
  setRedirect();
  return null;
};

const DBAuthenticator = () => {
  const local = process.env.REACT_APP_DELPHI_ENV === 'local';
  const [acceptedTOS, setAcceptedTOS] = useState(false);
  const [user, setUser] = useState(null);
  const [restrictedFields, setRestrictedFields] = useState([]);
  const [userDoc, setUserDoc] = useState(null);
  const [appPerms, setAppPerms] = useLocalStorage('appPerms', null);
  const [routes, setRoutes] = useState([]);
  const [apiKey, setApiKey] = useLocalStorage('apiKey', null);
  const [authComplete, setAuthComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSignedOut, setShowSignedOut] = useState(false);
  const [signoutReason, setSignoutReason] = useState(undefined);

  const checkUser = async () => {
    if (local) {
      const localUser = { username: 'local' };
      setUser(localUser);
      setAcceptedTOS(true);
    } else {
      try {
        const loggedUser = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        if (loggedUser) {
          if (loggedUser.attributes['custom:acceptedTOS']) {
            setAcceptedTOS(true);
          } else {
            setAcceptedTOS(false);
          }
          setUser(loggedUser);
        } else {
          setAuthComplete(false);
          setLoading(false);
        }
      } catch (err) {
        setAuthComplete(false);
        setLoading(false);
      }
    }
  };

  // POSTS fresh API Key to elastic; only fired on first run through
  const postKeyToElastic = async (apiKey, docId = '', action) => {
    const userIndex = `.user_${user.username}`;
    const body =
      action === '_update'
        ? {
            doc: {
              cognito: {
                apiKey: apiKey,
              },
              doc: {
                type: 'cognito',
              },
            },
          }
        : {
            cognito: {
              apiKey: apiKey,
            },
            doc: {
              type: 'cognito',
            },
          };
    const userApi = 'ApiKey ' + apiKey;
    let postPromise = await fetch(
      `https://${process.env.REACT_APP_API_HOST}/${userIndex}/${action}/` +
        docId,
      {
        method: 'POST',
        headers: {
          Authorization: userApi,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
    );
    return true;
  };

  // Fetches the current cognito doc stores in ES
  const fetchDoc = async () => {
    try {
      const userApi = 'ApiKey ' + apiKey.key;
      const userIndex = `.user_${user.username}`;
      let docPromise = await fetch(
        `https://${process.env.REACT_APP_API_HOST}/${userIndex}/_search`,
        {
          method: 'POST',
          headers: {
            Authorization: userApi,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: {
              bool: {
                must: {
                  term: {
                    'doc.type': 'cognito',
                  },
                },
              },
            },
          }),
        },
      );
      const doc = await docPromise.json();
      setUserDoc(doc.hits.hits[0]);
      return doc;
    } catch (e) {
      console.log('ERROR', e);
    }
  };

  const validateApi = async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken();
    const jwt = token.getJwtToken();
    let checkResp = await fetch(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/check-index`,
      {
        method: 'GET',
        headers: {
          Authorization: jwt,
          'Content-Type': 'application/json',
        },
      },
    );
    const checkIndex = await checkResp.json();

    let credsResp = await fetch(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/app-creds`,
      {
        method: 'GET',
        headers: {
          Authorization: jwt,
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await credsResp.json();
    const { apiKey, appPerms } = data;

    setApiKey(apiKey);
    setAppPerms(appPerms);
    setRoutes(filterRoutes(appPerms));
    setAuthComplete(true);
    setLoading(false);
  };

  const updateESApiKey = async () => {
    if (apiKey && user) {
      const userApi = 'ApiKey ' + apiKey.key;
      const userIndex = `.user_${user.username}`;
      const doc = await fetchDoc();
      // Run Lambda to kick out old user with stored APIkey
      if (doc && doc.hits && doc.hits.hits && doc.hits.hits.length > 0) {
        const oldApiKey = doc.hits.hits[0]['_source'].cognito.apiKey;
        const response = await fetch(
          process.env.REACT_APP_HANDLE_DUAL_USER_ENDPOINT,
          {
            method: 'POST',
            headers: {
              Authorization: user.signInUserSession.accessToken.jwtToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              accessToken: oldApiKey,
              userPoolId: user.pool.clientId,
            }),
          },
        );

        const docId = doc.hits && doc.hits.hits[0]['_id'];
        const elasticResponse = await postKeyToElastic(
          apiKey.key,
          docId,
          '_update',
        );
      } else if (
        doc &&
        doc.hits &&
        doc.hits.hits &&
        doc.hits.hits.length === 0
      ) {
        const elasticResponse = await postKeyToElastic(apiKey.key, '', '_doc');
      }
    }
  };

  const isInvalidKey = async () => {
    const currentTime = new Date().getTime();
    if (apiKey && user) {
      const doc = await fetchDoc();
      const dualUser =
        doc &&
        doc.hits &&
        doc.hits.hits[0]['_source'].cognito.apiKey !== apiKey.key;
      const expiredKey = currentTime > apiKey.expiration;
      if (dualUser || expiredKey) {
        dualUser
          ? setSignoutReason('multiple users')
          : setSignoutReason('expired key');
        setShowSignedOut(true);
      }
    }
  };

  const fetchRestrictedFields = async () => {
    try {
      const doc = await fetchDoc();
      setRestrictedFields(doc.hits.hits[0]._source.cognito.restrictedFields_v2);
    } catch {}
  };

  useEffect(() => {
    const currentTime = new Date().getTime();
    if (!user) {
      checkUser();
    } else {
      if (!apiKey) {
        validateApi();
      } else if (currentTime > apiKey.expiration) {
        validateApi();
      } else {
        setAuthComplete(true);
        setLoading(false);
      }
    }
  }, [user]);

  useEffect(() => {
    const validSessionCheck = setInterval(() => {
      isInvalidKey();
    }, 300000);
    updateESApiKey();
    return () => clearInterval(validSessionCheck);
  }, [user, apiKey]);

  useEffect(() => {
    if (!routes.length && apiKey) {
      const routes = filterRoutes(appPerms);
      setRoutes(routes);
    }
  }, [appPerms]);

  useEffect(() => {
    if (user) {
      fetchRestrictedFields();
    }
  }, [user]);

  if (showSignedOut) {
    setTimeout(signout, 10000);
    return (
      <ThemeProvider theme={lightTheme}>
        <Modal open onClickOutside={() => signout()}>
          <ModalContent>
            {signoutReason === 'multiple users' ? (
              <h3>
                Another user has taken this seat. You will be logged out
                shortly. Please contact Bluestone Analytics to request more
                licenses.
              </h3>
            ) : (
              <h3>
                Your session has timed out. You will be logged out shortly.
              </h3>
            )}
          </ModalContent>
        </Modal>
      </ThemeProvider>
    );
  } else if (loading) {
    return (
      <SnackbarProvider>
        <UserProvider
          value={{ user, authComplete, auth: Auth, restrictedFields }}
        >
          <ThemeProvider theme={lightTheme}>
            <LoginBackground />
            <GridContainer
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <GridItem style={{ marginTop: '60px', marginBottom: '50px' }}>
                <DBLogo src={DBLogoImg} />
              </GridItem>
              <GridItem
                style={{ minWidth: '500px', height: '500px', display: 'flex' }}
              >
                <Loader style={{ margin: 'auto' }} color="#2c6982" />
              </GridItem>
              <GridItem
                style={{
                  marginBottom: '24px',
                  marginTop: '50px',
                }}
              >
                <PoweredBy>Powered by</PoweredBy>
                <BSLogo src={BSLogoImg} />
              </GridItem>
            </GridContainer>
          </ThemeProvider>
        </UserProvider>
      </SnackbarProvider>
    );
  } else {
    if (authComplete) {
      return (
        <UserProvider
          value={{
            user: user,
            restrictedFields,
            setRestrictedFields,
            setUser,
            userDoc: userDoc,
            apiKey: apiKey,
            routes: routes,
            appPerms: appPerms,
            featurePerms: appPerms.filter((p) => p.includes('feature:')),
            auth: Auth,
            authComplete: authComplete,
            tos: acceptedTOS,
          }}
        >
          <App user={user} setUser={setUser} setAcceptedTOS={setAcceptedTOS} />
        </UserProvider>
      );
    } else {
      return (
        <Router history={hist}>
          <Switch>
            <Route render={() => <RedirectComponent />} />
          </Switch>
        </Router>
      );
    }
  }
};

export default DBAuthenticator;
