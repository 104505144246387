import React, { useEffect, useRef, useState } from 'react';
/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from '!mapbox-gl';

import PopupTable from './PopupTable';
import Loader from 'darkblue-ui/Spinners/Loader';

const getTypeFromField = (field) => {
  if (field === 'ai.geo.coordinates') return 'dataset';
  if (field === 'darkflow.server.geo.location') return 'ip';
  return 'adId';
};

const PopupContents = (props) => {
  return <PopupTable {...props} />;
};

const PopupContainer = ({
  filterState,
  setFilterState,
  latLong,
  setLatLong,
  popupContent,
  map,
  type,
  field,
  ...rest
}) => {
  const { data, loading, error, selectedFeature } = popupContent;
  const popupRef = useRef();

  // Add and remove popup from map layer
  useEffect(() => {
    const onClose = () => {
      setLatLong(null);
    };
    if (map.current && map.current.on && latLong) {
      const popup = new mapboxgl.Popup({})
        .setLngLat(latLong)
        .setDOMContent(popupRef.current)
        .addTo(map.current);
      popup.on('close', onClose);
    }
  }, [latLong]);

  const renderPopupContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <p>Something went wrong</p>;
    }

    if (Array.isArray(data) && data.length > 0) {
      return (
        <PopupContents
          {...rest}
          map={map}
          data={popupContent.data}
          selectedFeature={selectedFeature}
          type={getTypeFromField(type)}
        />
      );
    }

    return <p>Zoom in to see records</p>;
  };

  return (
    <div style={{ display: 'none' }}>
      <div style={{ padding: '10px' }} ref={popupRef}>
        {renderPopupContent()}
      </div>
    </div>
  );
};

const Popup = (props) => {
  return <PopupContainer {...props} />;
};

export default Popup;
