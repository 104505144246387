import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Radio from '@material-ui/core/Radio';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import dayjs from 'dayjs';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;

  border-radius: 0px;
  transition: all 100ms;
  border: 2px solid
    ${(props) =>
      props.active
        ? props.theme.colors.primary
        : props.theme.colors.multiSelectColor};

  ${CheckboxContainer}:hover {
    border: 2px solid ${(props) => props.theme.colors.altPrimary};
    cursor: pointer;
  }

  ${(props) =>
    props.sidebar &&
    css`
      width: 26px;
      height: 26px;
    `}
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 11px 8px;
  margin: auto;
`;

const Icon = styled.svg`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  fill: none;
  stroke: ${(props) => props.theme.colors.primary};
  stroke-width: 2px;
`;

const Label = styled.p`
  margin-bottom: 4px;
  margin-top: 4px;
`;

const CheckboxSelect = ({ active, className, sidebar, ...rest }) => {
  return (
    <CheckboxContainer {...rest} className={className}>
      <HiddenCheckbox active={active} {...rest} />
      <StyledCheckbox sidebar={sidebar} active={active}>
        <Icon active={active} viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

const handleUpdateState = ({
  setFilterState,
  filterState,
  filterName,
  value,
  query,
  elasticPath,
}) => {
  if (value.length > 0) {
    setFilterState({
      ...filterState,
      ...{
        [filterName]: {
          value,
          query,
          elasticPath,
        },
      },
    });
  } else {
    const { [filterName]: temp, ...newFilterState } = filterState;
    setFilterState(newFilterState);
  }
};

const DataFilter = ({
  filterState,
  setFilterState,
  filterName,
  layers,
}) => {
  const [value, setValue] = useState([]);

  const isActive = (option) => {
    if (!String(option).length) {
      return false;
    }

    const activeCheckboxes = filterState[filterName]?.value.filter((v) => {
      return v === option;
    });

    if (activeCheckboxes && activeCheckboxes.length) {
      return true;
    } else {
      return false;
    }
  };

  const handleOptionsToggle = (option) => {
    if (value?.length && value.includes(option)) {
      const newValue = value.filter((o) => o !== option);
      setValue(newValue);
      return newValue;
    } else if (Array.isArray(value)) {
      setValue([...value, option]);
      return [...value, option];
    }
  };

  useEffect(() => {
    setValue(filterState[filterName]?.value || []);
  }, [filterState]);

  return (
    <GridContainer style={{ flexDirection: 'column' }}>
      {layers.map((o) => {
        return (
          <GridItem
            style={{
              display: 'grid',
              gridTemplateColumns: '18% auto',
              alignItems: 'center',
            }}
            onClick={(e) => {
              e.preventDefault();
              const value = handleOptionsToggle(o.displayText);
              handleUpdateState({
                setFilterState,
                filterState,
                filterName,
                value,
                query: () => null,
                elasticPath: [],
              });
            }}
            key={o.displayText}
          >
            <CheckboxSelect active={isActive(o.displayText)} />
            <Label>{o.displayText}</Label>
          </GridItem>
        );
      })}
    </GridContainer>
  );
};

export default DataFilter;
