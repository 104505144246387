import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { UserContext } from '../../DBAuthenticator';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Cookies from 'js-cookie';

const StyledSidebarContainer = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['expanded'].includes(prop) && defaultValidatorFn(prop),
})`
  box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, 0.02),
    0 1.1px 3.7px rgba(0, 0, 0, 0.028), 0 2.1px 6.9px rgba(0, 0, 0, 0.035),
    0 3.8px 12.3px rgba(0, 0, 0, 0.042), 0 7.1px 23px rgba(0, 0, 0, 0.05),
    0 17px 55px rgba(0, 0, 0, 0.07);

  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 60px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition-property: top bottom width;
  transition-duration: 0.2s, 0.2s, 0.35s;
  transition-timing-function: linear, linear, ease;
  color: inherit;
  padding-bottom: 30px;
  background-color: ${(props) => props.theme.colors.sidebar};

  ${(props) =>
    props.expanded &&
    css`
      width: 200px;
    `}
`;

const ExternalLink = styled.a`
  color: ${(props) => props.theme.colors.sidebarText};
`;
const LogoContainer = styled.div`
  padding: 48px 24px;
`;

const RouteContainer = styled.div``;

const LinkContainer = styled.div`
  padding: 16px 24px;

  &:hover {
    cursor: pointer;
  }
  & > * {
    color: ${(props) =>
      props.active
        ? props.theme.colors.sidebarActiveText
        : props.theme.colors.sidebarText};
  }

  ${(props) =>
    props.active &&
    css`
      & > * {
        color: ${(props) => props.theme.colors.sidebarActiveText};
      }
    `}
`;

const LogoutContainer = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['expanded'].includes(prop) && defaultValidatorFn(prop),
})`
  display: flex;
  text-decoration: none;
  align-items: center;
  width: 100%;
  margin-right: 24px;
  & > * {
    padding-left: 2px;
    color: ${(props) =>
      props.active
        ? props.theme.colors.sidebarActiveText
        : props.theme.colors.sidebarText};
  }

  &:hover {
    cursor: pointer;
    & > * {
      color: ${(props) => props.theme.colors.primary} !important;
      p {
        color: ${(props) => props.theme.colors.primary} !important;
      }
    }
  }

  *:first-child {
    margin-right: 8px;
  }

  p {
    margin-left: 8px;
  }
`;

const StyledLinkOut = styled.div`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
  padding-left: 24px;
  color: ${(props) => props.theme.colors.sidebarText};

  &&& > * {
    p {
      padding-left: 2px;
      color: ${(props) => props.theme.colors.sidebarText};
    }
  }

  &:hover {
    cursor: pointer;
    & > * {
      color: ${(props) => props.theme.colors.primary} !important;
      p {
        color: ${(props) => props.theme.colors.primary} !important;
      }
    }
  }

  *:first-child {
    margin-right: 8px;
  }
`;

const StyledNavLink = styled(NavLink).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['expanded'].includes(prop) && defaultValidatorFn(prop),
})`
  display: flex;
  text-decoration: none;
  align-items: center;
  padding: 12px 0px;
  & > * {
    padding-left: 2px;
    color: ${(props) =>
      props.active
        ? props.theme.colors.sidebarActiveText
        : props.theme.colors.sidebarText};
  }
  ${(props) =>
    props.active &&
    css`
      pointer-events: none;
    `}
  &:hover {
    & > * {
      color: ${(props) => props.theme.colors.primary} !important;
      p {
        color: ${(props) => props.theme.colors.primary} !important;
      }
    }
  }

  *:first-child {
    // margin-right: 8px;
  }

  p {
    margin-left: 8px;
  }
`;

const StyledNavTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    color: ${(props) =>
      props.active ? props.theme.colors.sidebarActiveText : 'inherit'};
  }

  & > span > p {
    font-family: 'Red Hat Text', bold;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 12px;
    color: ${(props) =>
      props.active
        ? props.theme.colors.sidebarActiveText
        : 'inherit'} !important;
  }

  &:hover {
    cursor: pointer;
    & > * {
      color: ${(props) => props.theme.colors.primary} !important;
    }
  }

  *:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
`;

const LinkItemText = styled.span`
  font-family: 'Red Hat Text', regular;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 15px;
  color: ${(props) =>
    props.active
      ? props.theme.colors.sidebarActiveText
      : props.theme.colors.sidebarText};
  & > * {
    color: ${(props) =>
      props.active
        ? props.theme.colors.sidebarActiveText
        : props.theme.colors.sidebarText};
  }
`;

const RouteDrawer = styled.div`
  max-height: fit-content;
  transition: max-height 500ms linear;
  margin-left: 12px;
  padding-top: 14px;
  border-left: 1px solid ${(props) => props.theme.colors.sidebarText};
  ${(props) =>
    props.collapsed &&
    css`
      overflow: hidden;
      max-height: 0px;
      padding-top: 0px;
    `};
`;

const Tab = styled.div`
  max-height: 1px;
  height: 1px;
  min-height: 1px;
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.sidebarActiveText
      : props.theme.colors.sidebarText};
  max-width: 4px;
  width: 4px;
  min-width: 4px;
`;

const HeightContainer = styled.div`
  max-height: 85%;
  width: inherit;
  overflow-y: auto;

  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }
`;

const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['expanded'].includes(prop) && defaultValidatorFn(prop),
})`
  width: 30px;
  ${(props) =>
    props.expanded &&
    css`
      width: 150px;
    `}
`;

const signout = async (userSess) => {
  const user = await userSess.auth.currentAuthenticatedUser();
  let result = await userSess.auth.updateUserAttributes(user, {
    'custom:issuedAccessToken': '',
  });
  const signedOut = await userSess.auth.signOut({ global: true });
  localStorage.clear();
  Object.entries(Cookies.get()).forEach((cookie) => {
    if (cookie[0].indexOf('Cognito') > -1) {
      Cookies.remove(cookie[0]);
    }
  });

  window.location = process.env.REACT_APP_COG_SIGN_OUT;
  return null;
};

const CollapseComponent = ({
  views,
  name,
  icon,
  activeRoute,
  expanded,
  ...rest
}) => {
  const [collapsed, collapse] = useState(true);
  return (
    <CollapseContainer key={name}>
      <StyledNavTitle active={rest.active} onClick={() => collapse(!collapsed)}>
        <span style={{ maxWidth: '160px', maxHeight: '100px' }}>
          {icon ? icon : null}
          {expanded ? <p className="no-margin">{name}</p> : null}
        </span>
        <p className="no-margin">{expanded ? (collapsed ? '▼' : '▲') : null}</p>
      </StyledNavTitle>
      <RouteDrawer collapsed={collapsed || !expanded}>
        {views.map((route) => {
          const active = activeRoute === route.layout + route.path;
          return (
            <StyledNavLink
              key={route.layout + route.path}
              to={route.layout + route.path}
              active={active ? 'active' : null}
              id={route.gufId}
            >
              <Tab active={active} />
              {route.mini ? route.mini : null}
              <LinkItemText active={active}>
                {expanded ? <p className="no-margin">{route.name}</p> : null}
              </LinkItemText>
            </StyledNavLink>
          );
        })}
      </RouteDrawer>
    </CollapseContainer>
  );
};

const Sidebar = ({ routes, logo, miniLogo, expanded, setExpanded, user }) => {
  const userSess = useContext(UserContext);
  const isActiveRoute = ({ layout, path }, location) => {
    return location.pathname === layout + path;
  };

  const isActiveDrawer = ({ id }, location) => {
    return location.pathname.indexOf(id) > -1;
  };
  const location = useLocation();

  return (
    <StyledSidebarContainer expanded={expanded}>
      <HeightContainer style={{ ...(expanded ? {} : { marginRight: '24px' }) }}>
        <LogoContainer>
          <StyledNavLink to="/">
            <Logo
              expanded={expanded}
              src={expanded ? logo : miniLogo}
              alt="logo"
            />
          </StyledNavLink>
        </LogoContainer>
        <RouteContainer>
          {routes.map((route, i) => {
            return route.linkOut ? (
              <StyledLinkOut
                key={`${route.name}: ${route.id}`}
                id={route.gufId}
                onClick={async (e) => {
                  e.preventDefault();
                  await route.url(userSess);
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {route.icon ? route.icon : null}
                  <p>{route.name}</p>
                </div>
                <ExitToAppIcon style={{ paddingRight: '16px' }} />
              </StyledLinkOut>
            ) : (
              <LinkContainer
                key={route.name + route.path}
                active={isActiveRoute(route, location)}
                expanded={expanded}
                onClick={() => setExpanded(true)}
                id={route.gufId}
              >
                {route.collapse ? (
                  <CollapseComponent
                    name={route.name}
                    active={isActiveDrawer(route, location)}
                    icon={route.icon}
                    views={route.views}
                    activeRoute={location.pathname}
                    expanded={expanded}
                  />
                ) : (
                  <StyledNavLink
                    to={
                      route.layout === ''
                        ? route.path
                        : `${route.layout}${route.path}`
                    }
                    active={isActiveRoute(route, location) ? 'active' : null}
                    expanded={expanded}
                  >
                    {route.icon ? route.icon : null}
                    <LinkItemText>
                      <p className="no-margin">
                        {expanded ? route.name : route.mini}
                      </p>
                    </LinkItemText>
                  </StyledNavLink>
                )}
              </LinkContainer>
            );
          })}
        </RouteContainer>
      </HeightContainer>

      <RouteContainer
        style={{
          position: 'absolute',
          bottom: '80px',
          backgroundColor: 'inherit',
          width: '100%',
          left: '-10px',
          right: '0',
        }}
      >
        <LinkContainer id="main-sign-out-route">
          <LogoutContainer expanded={expanded}>
            <ExitToAppIcon />
            <LinkItemText onClick={() => signout(userSess)}>
              <p className="no-margin">{expanded ? 'Sign Out' : null}</p>
            </LinkItemText>
          </LogoutContainer>
        </LinkContainer>
      </RouteContainer>
    </StyledSidebarContainer>
  );
};

export default Sidebar;
