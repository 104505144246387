import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { fetchData } from '../../Search/utils';
import { StateContext } from '../../Search/StateProviderContext/StateProviderContext';

import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryArea,
  VictoryBar,
} from 'victory';

import DarkBlueVictoryTheme from '../../styles/DarkBlueVictoryTheme/DarkBlueVictoryTheme';
import { searchTermPrevalence } from '../../../utils/Queries/CustomQueries';

const SearchPrevalence = (props) => {
  const [chartData, setChartData] = useState([]);
  const [aggregations, setAggregations] = useState([]);
  const [maxY, setMaxY] = useState(0);

  const { searchFields, index, apiKey } = props;
  const themeContext = useContext(ThemeContext);
  const { filterState, loading } = useContext(StateContext);

  useEffect(() => {
    let canceled = false;

    const fetchStuff = async () => {
      const dateRange = filterState['DateTimeFilter']?.value ? filterState['DateTimeFilter']?.value : null;
      try {
        const data = await fetchData({
          canceled: canceled,
          query: searchTermPrevalence(filterState['Searchbar'], searchFields, dateRange),
          apiKey,
          index,
        });
        setAggregations(data.aggregations);
      } catch (err) {
        setAggregations([]);
        setChartData([]);
      }
    };

    if (filterState['Searchbar']?.value) {
      fetchStuff();
    } else {
      setChartData([]);
      setAggregations([]);
    }

    return () => (canceled = true);
  }, [filterState['Searchbar'], filterState['DateTimeFilter']]);

  useEffect(() => {
    if (aggregations && aggregations.dates && aggregations.dates.buckets) {
      const data = aggregations.dates.buckets.map((d) => {
        return { x: d.key_as_string.split(' ', 1)[0], y: d.doc_count };
      });

      const yVal = Math.max.apply(
        Math,
        data.map(function(o) {
          return o.y;
        }),
      );

      setChartData(data);
      setMaxY(yVal);
    }
  }, [aggregations]);

  return !loading &&
    filterState['Searchbar'] &&
    aggregations &&
    aggregations.dates &&
    aggregations.dates.buckets ? (
    <VictoryChart
      width={1800}
      height={300}
      style={{ maxHeight: '400px' }}
      theme={DarkBlueVictoryTheme}
    >
      <VictoryAxis
        renderInPortal
        fixLabelOverlap={false}
        tickCount={12}
        offsetY={50}
        style={{
          tickLabels: { fontsize: 10 },
        }}
        tickLabelComponent={<VictoryLabel textAnchor="middle" />}
      />
      <VictoryAxis
        dependentAxis
        tickCount={2}
        fixLabelOverlap={false}
        label={`Term\nCount`}
        style={{
          axisLabel: { fontsize: 8, angle: 0 },
          tickLabels: { fontsize: 10 },
        }}
        axisLabelComponent={<VictoryLabel dy={-95} dx={-20} />}
        tickLabelComponent={<VictoryLabel textAnchor="end" dy={2} />}
      />
      {chartData.length > 1 ? (
        <VictoryLine
          data={chartData}
          domain={{ y: [0, maxY * 1.1] }}
          style={{
            data: {
              stroke: themeContext.searchPrevColors[0].line,
              strokeWidth: 4,
            },
          }}
          dy={200}
        />
      ) : (
        <VictoryBar
          data={chartData}
          x={(datum) => datum.x}
          y={(datum) => datum.y}
          style={{
            data: {
              fill: themeContext.searchPrevColors[0].line,
            },
          }}
        />
      )}
      {chartData.length > 1 ? (
        <VictoryArea
          data={chartData}
          domain={{ y: [0, maxY * 1.1] }}
          style={{
            data: {
              fill: themeContext.searchPrevColors[0].area,
            },
          }}
        />
      ) : null}
    </VictoryChart>
  ) : !loading && filterState['Searchbar'] ? (
    <VictoryChart
      width={1800}
      height={300}
      style={{ maxHeight: '400px' }}
      theme={DarkBlueVictoryTheme}
    >
      <VictoryAxis
        renderInPortal
        fixLabelOverlap={false}
        tickCount={12}
        offsetY={50}
        style={{
          tickLabels: { fontsize: 10 },
        }}
        tickLabelComponent={<VictoryLabel textAnchor="middle" />}
      />
      <VictoryAxis
        dependentAxis
        tickCount={2}
        fixLabelOverlap={false}
        label={`Term\nCount`}
        style={{
          axisLabel: { fontsize: 8, angle: 0 },
          tickLabels: { fontsize: 10 },
        }}
        axisLabelComponent={<VictoryLabel dy={-95} dx={-20} />}
        tickLabelComponent={<VictoryLabel textAnchor="end" dy={2} />}
      />
    </VictoryChart>
  ) : null;
};

export default SearchPrevalence;
