import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';

// @darkblue-ui/components
import Button from '../Button/Button.js';

/**
 * @Styled Component
 * component styling Section
 */
const StyledTextArea = styled.textarea.attrs(({ autocomplete }) => ({
  autoComplete: autocomplete || 'off',
}))`
  background-color: ${(props) => props.theme.colors.fog};
  box-sizing: border-box;
  height: 48px;
  width: 300px;
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.cloud};
  border-radius: 0px;

  padding: 0px 16px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  color: ${(props) => props.theme.colors.almostBlack};

  &:focus {
    border: 1px solid
      ${(props) =>
        props.error
          ? props.theme.colors.danger
          : props.theme.colors.altPrimary};
  }

  ::placeholder {
    color: #c4c4c4;
  }

  ${(props) =>
    props.up &&
    css`
      padding-top: 2px !important;
    `}
`;

const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.label`
  color: inherit;
  font-size: 14px;
  will-change: transform;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.06, 0.67, 0.32, 0.82);
  transform: translate(17px, 33px);
  ${(props) =>
    props.up &&
    css`
      visibility: visible;
      font-size: 10px;
      color: ${(props) =>
        props.error
          ? props.theme.colors.danger
          : props.theme.colors.altPrimary};
      transform: translate(8px, 34px);

      ${(props) =>
        props.inactive &&
        css`
          color: ${(props) =>
            props.error
              ? props.theme.colors.danger
              : props.theme.colors.almostBlack};
        `}
    `}

  ${(props) =>
    props.up &&
    css`
      visibility: hidden;
    `}
`;

const HelperText = styled.em`
  padding-left: 16px;
  padding-top: 4px;
  ${(props) =>
    props.error &&
    css`
      color: ${(props) => props.theme.colors.danger};
    `}
`;

const SubmitButton = styled(Button)`
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
`;

/**
 * @Component TextArea
 * Renders: Renders a text area component.
 * @param  {obj} props
 *  label - The label to be shown in the teaxtarea.
 *  filterModelOpen - A boolean indicates whether a filter modal is open.
 *  inputProps - The input props properties.
 *  rest - Rest of props to be set on textarea.
 */
const TextArea = (props) => {
  const {
    label,
    filterModalOpen = true,
    inputProps,
    placeHolder,
    ...rest
  } = props;
  const { value, onChange, name, onSubmit, type } = inputProps;
  const inputEl = useRef(null);
  const [focused, setFocused] = useState(false);
  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <TextFieldWrapper>
      {label && (
        <Label
          filterModalOpen={filterModalOpen}
          isvalueSet={value.length > 0}
          up={focused || value}
          inactive={!focused}
          error={props.error}
        >
          {label}
        </Label>
      )}
      <StyledTextArea
        autocomplete={rest.autocomplete}
        up={focused || value}
        id={name}
        type={type}
        error={props.error}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        onKeyDown={(key) =>
          key.keyCode === 13 && onSubmit ? onSubmit() : null
        }
        innerRef={inputEl}
        placeholder={!focused ? placeHolder : null}
        {...rest}
      />
      {props.button ? (
        <SubmitButton
          className="show"
          onClick={value ? () => onSubmit() : null}
        >
          Apply
        </SubmitButton>
      ) : null}

      {props.helperText ? (
        <HelperText error={props.error} className="meta">
          {props.helperText}
        </HelperText>
      ) : null}
    </TextFieldWrapper>
  );
};

export default TextArea;
