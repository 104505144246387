import React, { useState, useEffect, useContent } from 'react';
import styled, { ThemeContext } from 'styled-components';
import bodybuilder from 'bodybuilder';

import { fetchData } from 'darkblue-ui/Search/utils';

import Grid from '../../../../darkblue-ui/core/Grid/Grid';

const CounterCard = styled(Grid)``;

const CounterLabel = styled.h3`
  margin-bottom: 0px;
`

const CounterData = styled.h1`
  margin-top: 5px;
  font-size: 30px;
`

const numberWithCommas = (value) => {
  if (value) {
    let x = value.toString();
    const pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
    return x;
  } else {
    return 'Error';
  }
};

const Counter = ({
  webType = { additionalFilters: [] },

  aggField,
  timerange,
  label,
  apiKey,
  indices = ['sites,markets,profiles,communications'],
  dashboardFilters = {},
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const { additionalFilters, value, path } = webType;

  useEffect(() => {
    const getData = async () => {
      let body = bodybuilder();

      additionalFilters.forEach((filter) =>
        filter(body, { value, elasticPath: path }),
      );

      Object.entries(dashboardFilters).forEach(([name, filter]) => {
        const { query, value, elasticPath } = filter;
        query(body, { value, elasticPath });
      });

      body.agg('cardinality', aggField, `${label}-${aggField}`);

      setLoading(true);

      const resp = await fetchData({
        query: body.build(),
        apiKey,
        index: [indices],
      });

      const aggResult = resp.aggregations[`${label}-${aggField}`].value;

      setData(aggResult);
      setLoading(false);
    };

    getData();
  }, [dashboardFilters]);

  return (
    <CounterCard>
      <CounterLabel>{label || aggField}</CounterLabel>
      <CounterData>{loading ? 'Loading' : numberWithCommas(data)}</CounterData>
    </CounterCard>
  );
};

export default Counter;
