import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import { Formik, Field, Form } from 'formik';

import SwitchGroup from '../../../darkblue-ui/Inputs/SwitchGroup';
import { getSettings, gerUserInfo, setEmailNotifications } from './utils';
import Button from '../../../darkblue-ui/Button/Button';
import Loader from 'darkblue-ui/Spinners/Loader';

const SettingsCard = styled.div`
  border-radius: 5px !important;
  padding: 20px;
  border-color: #d0d7de;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;

  @media only screen and (max-width: 1050px) {
    border-style: none;
  }
}
`;

export default function Notification({ snackBar }) {
  const [attributes, setAttributes] = useState({ loading: true });
  useEffect(() => {
    const getUser = async () => {
      const { enableEmailAlerts, emailVerified, ...rest } = await getSettings(
        'notifications',
      );
      setAttributes({ enableEmailAlerts, emailVerified });
    };
    getUser();
  }, []);

  return (
    <>
      {attributes.loading ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Loader color="#2c6982" />
        </div>
      ) : (
        <SettingsCard>
          <h2>Notifications</h2>
          <div style={{ paddingBottom: '10px' }}>
            <Divider
              style={{ backgroundColor: '#d0d7de' }}
              variant="fullWidth"
            />
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                enableEmailAlerts: attributes.enableEmailAlerts,
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const resp = await setEmailNotifications(
                  values.enableEmailAlerts,
                );
                resetForm({ values });
                snackBar(`Notifications Saved!`, {
                  variant: 'success',
                });
                setAttributes({
                  ...attributes,
                  enableEmailAlerts: values.enableEmailAlerts,
                });
                setSubmitting(false);
              }}
            >
              {({
                values,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                resetForm,
                dirty,
              }) => {
                return (
                  <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    {attributes.emailVerified ? (
                      <>
                        <div>
                          <SwitchGroup
                            name="Email Notifications"
                            value={values.enableEmailAlerts === '1'}
                            onChange={() => {
                              setFieldValue(
                                'enableEmailAlerts',
                                values.enableEmailAlerts === '1' ? '0' : '1',
                              );
                            }}
                            label={'Email Notifications'}
                          />
                        </div>
                        <div style={{ margin: '55px 10px 0px' }}>
                          <Button
                            disabled={!dirty || isSubmitting}
                            type="button"
                            style={{ padding: '10px 20px' }}
                            onClick={(e) => {
                              handleSubmit();
                            }}
                          >
                            Save
                          </Button>
                          {dirty ? (
                            <span style={{ marginLeft: '5px' }}>
                              <Button
                                type="reset"
                                style={{ padding: '8px 22px' }}
                                secondary
                                onClick={(e) => {
                                  e.preventDefault();
                                  resetForm({ values: attributes });
                                }}
                              >
                                Cancel
                              </Button>
                            </span>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <p>
                        Register and verify an email address to recieve alerts
                        on your saved searches
                      </p>
                    )}
                  </form>
                );
              }}
            </Formik>
          </div>
        </SettingsCard>
      )}
    </>
  );
}
