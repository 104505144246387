import React from 'react';
import styled from 'styled-components';

const convertToPx = (size) => {
  switch (size) {
    case 'sm':
      return { width: '17px', height: '17px' };
    default:
      return {};
  }
};

const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const IconWrapper = (props) => {
  const iconSize = convertToPx(props.size);
  return (
    <StyledIconWrapper style={iconSize}>{props.children}</StyledIconWrapper>
  );
};

export default IconWrapper;
