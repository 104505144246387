import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../darkblue-ui/Button/Button';

const PaginationItem = styled.li`
  display: inline;
`;

export default function Pagination(props) {
  const { pages, color, rowArr, setRowArr } = props;

  return (
    <ul
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {pages.map((prop, key) => {
        return (
          <PaginationItem key={key}>
            {prop.onClick !== undefined ? (
              <Button
                pagination
                customChild
                onClick={() => {
                  prop.onClick();
                  if (rowArr && rowArr.length) {
                    setRowArr([]);
                  }
                }}
                active={prop.active}
              >
                {prop.text}
              </Button>
            ) : (
              <Button onClick={() => alert("you've clicked " + prop.text)}>
                {prop.text}
              </Button>
            )}
          </PaginationItem>
        );
      })}
    </ul>
  );
}

Pagination.defaultProps = {
  color: 'primary',
};

Pagination.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
};
