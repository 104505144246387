import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import bodybuilder from 'bodybuilder';
import styled from 'styled-components';
import dayjs from 'dayjs';

import Grid from '../../../../darkblue-ui/core/Grid/Grid';
import { fetchData } from 'darkblue-ui/Search/utils';
import { colorChartItem, gradientItem } from 'darkblue-ui/styles/colors/utils';
import { TrendingTermsList, TrendingGrid, TrendingTermsParagraph } from '../../../../darkblue-ui/Dashboard/Trending';


const handleTrendingTermDateURL = (days) => {
  const start = dayjs().subtract(days, 'day');
  const end = dayjs();

  const DateTimeFilterValue = {
    value: {
      startValue: start.format('YYYY-MM-DD HH:mm:ss'),
      endValue: end.format('YYYY-MM-DD HH:mm:ss'),
    },
    elasticPath: ['timestamp'],
  };

  return encodeURIComponent(JSON.stringify(DateTimeFilterValue));
};

const handleTrendingTermSearchbarUrl = (term) => {
  const Searchbar = { value: term };

  return encodeURIComponent(JSON.stringify(Searchbar));
};

const TrendingTermsChart = ({ termsData, days, webType }) => {
  const history = useHistory();

  const dedupedData = [...new Set(termsData.map((s) => s.content))];
  return (
    <Grid>
      <TrendingTermsList>
        {dedupedData.map((d, idx) => {
          return (
            <TrendingTermsParagraph
              index={idx + 1}
              key={d}
              onClick={() => {
                const term = encodeURIComponent(`\\"${d}\\"`);
                const path = `/search/data?DateTimeFilter=${handleTrendingTermDateURL(
                  days,
                )}&Searchbar=${handleTrendingTermSearchbarUrl(d)}${
                  webType.urlModifier.length ? `&${webType.urlModifier}` : ''
                }`;
                history.push(path);
              }}
              className="no-margin"
            >
              {d}
            </TrendingTermsParagraph>
          );
        })}
      </TrendingTermsList>
    </Grid>
  );
};

const TrendingTerms = ({ webType, apiKey, dashboardFilters }) => {
  const [termsData, setTermsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { additionalFilters = [], filterValue, path } = webType;

  useEffect(() => {
    const fetchTerms = async () => {
      let body = bodybuilder();

      additionalFilters.forEach((filter) => {
        filter(body, { value: filterValue, elasticPath: path });
      });

      Object.entries(dashboardFilters).forEach(([name, filter]) => {
        const { query, value, elasticPath } = filter;
        query(body, { value, elasticPath });
      });

      body
        .size(0)
        .agg(
          'date_histogram',
          { field: 'timestamp', interval: 'day', order: { _key: 'desc' } },
          'dates',
          (a) =>
            a.aggregation(
              'terms',
              {
                field: 'entity.keyword',
                size: 5,
                order: { trendWeight: 'desc' },
              },
              'entity',
              (aa) =>
                aa
                  .agg('terms', { field: 'site.keyword', size: 5 }, 'site')
                  .agg('max', { field: 'trending' }, 'trendWeight'),
            ),
        );
      setLoading(true);
      const resp = await fetchData({
        query: body.build(),
        apiKey,
        index: ['trends'],
      });
      setTermsData(
        resp.aggregations.dates.buckets
          .map((bucket) => {
            const trendCategorization = bucket.entity.buckets.map((entity) => {
              return { content: entity.key, count: entity.doc_count };
            });
            return { content: bucket.key, data: trendCategorization };
          })
          .map((day) => {
            return day.data;
          })
          .flat()
          .slice(0, 5),
      );
      setLoading(false);
    };

    fetchTerms();
  }, [webType.label, dashboardFilters]);

  return (
    <TrendingGrid>
      <h3>Terms</h3>
      {loading ? (
        <h3>Loading...</h3>
      ) : (
      <TrendingTermsChart
        termsData={termsData}
        webType={webType}
        days={dashboardFilters.dropdownValue?.value || 30}
      />
      )}
    </TrendingGrid>
  );
};

export default TrendingTerms;
