const terms = [
  'timestamp',
  'target',
  'port',
  'message',
  'crypto.ssh.version',
  'crypto.ssh.fingerprint',
  'crypto.ssh.type',
  'crypto.ssh.key',
  'crypto.tls.subject',
  'crypto.tls.issuer',
  'crypto.tls.notValidAfter',
  'crypto.tls.notValidBefore',
  'crypto.tls.version',
  'crypto.tls.serial',
  'crypto.tls.publicKey',
  'crypto.tls.fingerprint',
  'crypto.tls.signature',
  'crypto.tls.signatureHashAlorigthm',
  'crypto.tls.extensions.SubjectKeyIdentifier.oid',
  'crypto.tls.extensions.SubjectKeyIdentifier.digest',
  'crypto.tls.extensions.BasicConstraints.oid  ',
  'crypto.tls.extensions.BasicConstraints.ca',
  'crypto.tls.extensions.BasicConstraints.path_length',
  'crypto.tls.extensions.AuthorityKeyIdentifier.oid    ',
  'crypto.tls.extensions.AuthorityKeyIdentifier.authority_cert_issuer',
  'crypto.tls.extensions.AuthorityKeyIdentifier.authority_cert_serial_number',
  'crypto.tls.extensions.AuthorityKeyIdentifier.key_identifier ',
];

export { terms };
