const terms = [
  {
    path: 'activeServices',
    filterName: 'ActiveServicesFilter',
    elasticPath: ['activeServices'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.csam',
    elasticPath: ['ai.content.csam.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.drugs',
    elasticPath: ['ai.content.drugs.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.fraud',
    elasticPath: ['ai.content.fraud.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.leaks',
    elasticPath: ['ai.content.leaks.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.opiates',
    elasticPath: ['ai.content.opiates.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.tags',
    sortKey: 7,
    filterName: 'ContentTagsFilter',
    elasticPath: ['ai.content.tags.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.violence',
    elasticPath: ['ai.content.violence.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.technology',
    elasticPath: ['ai.content.technology.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.content.weapons',
    elasticPath: ['ai.content.weapons.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.tags',
    elasticPath: ['ai.drugs.tags.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.opiates',
    elasticPath: ['ai.drugs.opiates.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticBenzodiazepines',
    elasticPath: ['ai.drugs.syntheticBenzodiazepines.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticCannabinoids',
    elasticPath: ['ai.drugs.syntheticCannabinoids.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticDissociatives',
    elasticPath: ['ai.drugs.syntheticDissociatives.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticOpiates',
    elasticPath: ['ai.drugs.syntheticOpiates.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticPsychedelics',
    elasticPath: ['ai.drugs.syntheticPsychedelics.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drugs.syntheticStimulants',
    elasticPath: ['ai.drugs.syntheticStimulants.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.site.firstSnapshot',
    filterName: 'FirstSnapshotFilter',
    elasticPath: ['ai.site.firstSnapshot'],
    filterValueType: 'string',
  },
  {
    path: 'ai.language',
    sortKey: 8,
    filterName: 'LanguageFilter',
    elasticPath: ['ai.language.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.site.changed',
    filterName: 'SiteChangedFilter',
    elasticPath: ['ai.site.changed.keyword'],
    filterValueType: 'array',
  },
  { path: 'ai.site.lastDateChanged', sortKey: 12 },
  { path: 'ai.site.mirrors', sortKey: 13 },
  {
    path: 'ai.site.type',
    filterName: 'SiteTypeFilter',
    elasticPath: ['ai.site.type.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'litecoinAddresses',
    filterName: 'LitecoinAddressesFilter',
    elasticPath: ['litecoinAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.crypto.ssh.lastChanged',
  },
  {
    path: 'ai.crypto.ssh.matches',
  },
  {
    path: 'ai.crypto.ssh.priorVersions.timestamp',
  },
  {
    path: 'ai.crypto.ssh.priorVersions',
    elasticPath: ['ai.crypto.ssh.priorVersions.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.drug.methamphetamines',
    elasticPath: ['ai.drug.methamphetamines.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.crypto.ssh.numMatches',
  },
  {
    path: 'ai.location',
    filterName: 'LocationFilter',
    sortKey: 4,
    elasticPath: ['ai.location.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ai.usper.potential',
    elasticPath: ['ai.usper.potential'],
  },
  {
    path: 'ai.usper.explanation',
    elasticPath: ['ai.usper.explanation.keyword',]
  },
  {
    path: 'analyticsIDs',
    filterName: 'AnalyticsIdFilter',
    elasticPath: ['analyticsIDs.keyword'],
    filterValueType: 'array',
  },
  { path: 'apacheServices.clients' },
  { path: 'apacheServices.protocols' },
  { path: 'apacheServices.requests' },
  { path: 'apacheServices.server' },
  { path: 'apacheServices.vhosts' },
  {
    path: 'apacheServerInfo.httpdConf',
    filterName: 'ApacheServerhttpdConfFilter',
    elasticPath: ['apacheServerInfo.httpdConf.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'apacheServerInfo.httpdInfoConf',
    filterName: 'ApacheServerhttpdInfoConfFilter',
    elasticPath: ['apacheServerInfo.httpdInfoConf.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'apacheServerInfo.httpdVHostsConf',
    filterName: 'ApacheServerVHostsConfFilter',
    elasticPath: ['apacheServerInfo.httpdVHostsConf.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'apacheServerInfo.server',
    filterName: 'ApacheServerInfoFilter',
    elasticPath: ['apacheServerInfo.server.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'bitcoinAddresses',
    filterName: 'BitcoinAddressesFilter',
    elasticPath: ['bitcoinAddresses.keyword'],
    filterValueType: 'array',
  },
  { path: 'doc.contentHash' },
  {
    path: 'doc.idHash',
    filterName: 'DocIdFilter',
    elasticPath: ['doc.idHash.keyword'],
    filterValueType: 'array',
  },
  { path: 'doc.priorVersions' },
  { path: 'doc.processedTime' },
  { path: 'doc.scraped' },
  {
    path: 'doc.type',
    filterName: 'DocFilter',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'emailAddresses',
    filterName: 'EmailAddressesFilter',
    elasticPath: ['emailAddresses.keyword'],
    filterValueType: 'array',
  },
  { path: 'images' },
  { path: 'images.exifTags' },
  { path: 'images.exifTags.location' },
  {
    path: 'foundApacheModStatus',
    filterName: 'ApacheStatusFilter',
    elasticPath: ['foundApacheModStatus'],
    filterValueType: 'array',
  },
  {
    path: 'foundApacheServerInfo',
    elasticPath: ['foundApacheServerInfo'],
    filterValueType: 'array',
  },
  { path: 'hashes' },
  {
    path: 'hiddenService',
    sortKey: 3,
    filterName: 'HiddenServiceFilter',
    elasticPath: ['hiddenService.keyword'],
    filterValueType: 'array',
  },
  { path: 'htmlMeta.lang' },
  { path: 'i2pLinks' },
  { path: '_id' },
  { path: 'interestingFiles' },
  { path: 'internalPages' },
  { path: 'ipAddresses' },
  {
    path: 'jsDetected',
    filterName: 'JSDetectedFilter',
    elasticPath: ['jsDetected'],
    filterValueType: 'array',
  },
  { path: 'jsScripts' },
  { path: 'lastAction' },
  { path: 'linkedSites' },
  { path: 'online', sortKey: 11 },
  { path: 'openBazaarLinks' },
  { path: 'openDirectories' },
  {
    path: 'openPorts',
    filterName: 'PortsFilter',
    elasticPath: ['openPorts.keyword'],
    filterValueType: 'array',
  },
  { path: 'pageForms.actions' },
  { path: 'pageForms.fieldNames' },
  { path: 'pageForms.idAttrs' },
  { path: 'pageLinks' },
  { path: 'pageReferencedDirectories' },
  {
    path: 'pageTitle',
    sortKey: 1,
    filterName: 'PageTitleFilter',
    elasticPath: ['pageTitle.keyword'],
    filterValueType: 'array',
  },
  { path: 'pgp.armoredKeys' },
  { path: 'pgp.armoredKey' },
  { path: 'pgp.created' },
  { path: 'pgp.fingerprint' },
  { path: 'pgp.fingerprints' },
  { path: 'pgp.identities.comments' },
  { path: 'pgp.identities.emails' },
  { path: 'pgp.identities.is_primary' },
  { path: 'pgp.identities.names' },
  { path: 'pgp.identities.signers' },
  { path: 'pgp.identity' },
  { path: 'phoneNumbers' },
  {
    path: 'postal.uspsExpress',
    elasticPath: ['postal.uspsExpress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'postal.uspsPriority',
    elasticPath: ['postal.uspsPriority.keyword'],
    filterValueType: 'array',
  },
  { path: 'publicKeys' },
  {
    path: 'privateKeyDetected',
    filterName: 'PrivateKeyFilter',
    elasticPath: ['jsDetected'],
    filterValueType: 'array',
  },
  {
    path: 'privateKeys',
  },
  { path: 'redirectedFrom' },
  { path: 'responseHeaders' },
  {
    path: 'responseStatus',
    sortKey: 10,
    filterName: 'ResponseStatusFilter',
    elasticPath: ['responseStatus.keyword'],
    filterValueType: 'array',
  },
  { path: 'robotsTxt' },
  { path: 'robotsTxtDetected' },
  {
    path: 'selectors.creditCards',
    elasticPath: ['selectors.creditCards.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.identity',
    elasticPath: ['selectors.identity.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.phoneNumbers',
    elasticPath: ['selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'serverVersion',
    filterName: 'ServerFilter',
    elasticPath: ['serverVersion.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'site.net',
    sortKey: 6,
    filterName: 'FirstScanFilter',
    elasticPath: ['site.net.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.ssh.fingerprint',
    filterName: 'SSHFingerprintFilter',
    elasticPath: ['crypto.ssh.fingerprint.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.ssh.key',
    elasticPath: ['crypto.ssh.key.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.ssh.type',
    filterName: 'SSHTypeFilter',
    elasticPath: ['crypto.ssh.type.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.ssh.version',
    filterName: 'SSHVersionFilter',
    elasticPath: ['crypto.ssh.version.keyword'],
    filterValueType: 'array',
  },
  { path: 'crypto.tls.extensions' },
  {
    path: 'crypto.tls.fingerprint',
    filterName: 'TLSFingerprintFilter',
    elasticPath: ['crypto.tls.fingerprint.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.tls.issuer',
    filterName: 'TLSSubjectIssuerFilter',
    elasticPath: ['crypto.tls.issuer.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.tls.notValidAfter',
    elasticPath: ['crypto.tls.notValidAfter.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.tls.notValidBefore',
    elasticPath: ['crypto.tls.notValidBefore.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.tls.publicKey',
    elasticPath: ['crypto.tls.publicKey.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.tls.serial',
    elasticPath: ['crypto.tls.serial.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.tls.signature',
    filterName: 'TLSSignatureFilter',
    elasticPath: ['crypto.tls.signature.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.tls.signatureHashAlgorithm',
    elasticPath: ['crypto.tls.signatureHashAlgorithm.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.tls.subject',
    filterName: 'TLSSubjectIssuerFilter',
    elasticPath: ['crypto.tls.subject.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'crypto.tls.version',
    filterName: 'TLSVersionFilter',
    elasticPath: ['crypto.tls.version.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'ethereumAddresses',
    filterName: 'EthereumAddressesFilter',
    elasticPath: ['ethereumAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'moneroAddresses',
    filterName: 'MoneroAddressesFilter',
    elasticPath: ['moneroAddresses.keyword'],
    filterValueType: 'array',
  },
  { path: 'site.type', sortKey: 5 },
  { path: 'siteMap.links' },
  { path: 'siteMapDetected' },
  { path: 'snapshot' },
  { path: 'snapshotPresent' },
  { path: 'snapshotSnippet', sortKey: 2 },
  { path: 'socialMedia' },
  { path: 'ssh.key' },
  { path: 'htmlMeta.tags' },
  { path: 'timedOut' },
  { path: 'timestamp' },
];

export { terms };
