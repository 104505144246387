import React, { useState, useContext, useEffect, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import _ from 'lodash';

import Button from '../../darkblue-ui/Button/Button.js';
import { UserConsumer } from '../../DBAuthenticator.js';
import Cluster from '../Elastic/Cluster';
import SavedItemTable from '../../darkblue-ui/Table/SavedItemTable.js';
import { FileCopy } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useClipboard } from 'use-clipboard-copy';
import { useSnackbar } from 'notistack';
import { startSearchDate } from 'consts/MagicValues';

import {
  DateRange,
  ReactiveList,
  MultiDropdownList,
  ReactiveComponent,
} from '@appbaseio/reactivesearch';
import { makeStyles } from '@material-ui/core/styles';

import {
  generalDatesQuery,
  savedRecords,
} from '../../utils/Queries/CustomQueries';
import Loader from '../../darkblue-ui/Spinners/Loader';
import { cleanData } from '../../utils/Functions/utils';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
`;

const NewIndicatorWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const NewIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 0px;
  background-color: ${(props) => props.theme.colors.primary};
  animation: ${pulse} 2s infinite;
`;

const ShareRecords = ({ url }) => {
  const { enqueueSnackbar } = useSnackbar();
  const copy = useClipboard().copy;

  const btnStyle = {
    alignSelf: 'center',
    width: '41px',
    height: '41px',
    minWidth: '41px',
    marginLeft: '15px',
  };

  return (
    <div>
      <p
        style={{
          marginTop: '0',
          marginBottom: '3px',
          textDecoration: 'underline',
        }}
      >
        Record URL
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <p style={{ margin: '0', wordBreak: 'break-all' }}>{url}</p>

        <Button
          style={btnStyle}
          onClick={() => {
            const copiedValue = url;
            copy(copiedValue);
            enqueueSnackbar('Successfully Copied!', {
              variant: 'success',
            });
          }}
        >
          <FileCopy fontSize="small" style={{ verticalAlign: 'middle' }} />
        </Button>
      </div>
    </div>
  );
};

const Records = () => {
  // const classes = useStyles();
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [fire, setFire] = useState(0);
  const [shareArr, setShareArr] = useState([]);
  // useEffect(() => {
  // }, [fire])
  let tempArr = [];
  const columns = useMemo(() => [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Created At', accessor: 'timestamp' },
    {
      id: 'ViewRecord',
      Cell: (props) => (
        <Button
          style={{ width: '150px', height: '26px' }}
          onClick={props.handleRedirect}
        >
          View Record
        </Button>
      ),
    },
    {
      id: 'Share Search',
      Cell: (props, displayName = 'test') => {
        if (tempArr.length < props.rows.length) {
          tempArr.push(false);
        }

        let row = props.row;
        let decodedQuery = atob(row.original['query']);
        const basePath = window.location.protocol + '//' + window.location.host;
        const endpoint = row.original['endpoint'];

        if (decodedQuery.indexOf('TimeFilter') === -1) {
          //if there is no time filter, then we create one.
          //this is a hack to prevent the default time filter of one week
          // prior to today being created.
          let startDateStr = '';
          if (
            'alert' in row.original &&
            row.original['alert']['enabled'] === true
          ) {
            startDateStr = row.original['timestamp'].slice(0, 10);
          } else {
            startDateStr = startSearchDate.toISOString().slice(0, 10);
          }

          const currentDateStr = new Date().toISOString().slice(0, 10);
          //this specific line is a hack to encode the URL parameter string manually.
          decodedQuery += `&TimeFilter=%5B%22${startDateStr}%22%2C%22${currentDateStr}%22%5D`;
        }
        const fullURL = basePath + endpoint + decodedQuery;
        return (
          <>
            <ClickAwayListener
              onClickAway={() => {
                tempArr[props.row.id] = false;
                setShareArr(tempArr);
              }}
            >
              <Tooltip
                arrow
                classes={{
                  tooltip: 'bg-override',
                }}
                interactive
                placement="right"
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => {
                  tempArr[props.row.id] = false;
                  setShareArr(tempArr);
                }}
                open={shareArr[props.row.id]}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<ShareRecords url={fullURL} />}
              >
                <button
                  style={{ width: '150px', border: 'none', height: '26px' }}
                  onClick={() => {
                    tempArr[props.row.id] = true;
                    setShareArr(tempArr);
                  }}
                >
                  Share Record <FileCopy style={{ verticalAlign: 'middle' }} />
                </button>
              </Tooltip>
            </ClickAwayListener>
            {/* {shareArr[shareArr.indexOf(props.row.id)] ? (
              <CopiedIndicator>Copied search URL!</CopiedIndicator>
            ) : null} */}
          </>
        );
      },
    },
    {
      id: 'Delete',
      Cell: (props) => (
        <Button
          style={{ width: '150px', height: '26px' }}
          color="danger"
          onClick={props.handleDelete}
        >
          Remove Saved Record
        </Button>
      ),
    },
  ]);

  return (
    <UserConsumer>
      {(userSess) => {
        return (
          <Cluster key={fire} index={'.user_' + userSess.user.username}>
            <ReactiveComponent
              componentId="TableWrapper"
              defaultQuery={savedRecords}
              customQuery={savedRecords}
            />
            <ReactiveList
              react={{
                and: ['TableWrapper'],
              }}
              loader={<Loader />}
              renderResultStats={function(stats) {
                let resultCount = stats.numberOfResults;
                if (resultCount === 10000) {
                  resultCount = '10,000+';
                }
                return (
                  <p className="s secondary">
                    Showing {stats.displayedResults} of total {resultCount} in
                    {stats.time} ms
                  </p>
                );
              }}
              componentId="SearchResults"
              dataField="doc.created"
              sortBy="desc"
              size={100}
              pagination={true}
              paginationAt="both"
              load={true}
              render={(props) => {
                const { error, data, loading } = props;
                if (error) {
                  return <h4>Trouble Retrieving Data. Please try again</h4>;
                }
                if (data && !loading) {
                  return (
                    <SavedItemTable
                      tableKey={'Record'}
                      showNotes={true}
                      data={data}
                      fire={fire}
                      columns={columns}
                      setFire={setFire}
                    />
                  );
                }
                return <div />;
              }}
            />
            {/* ); */}
            {/* }}
          ></ReactiveComponent> */}
          </Cluster>
        );
      }}
    </UserConsumer>
  );
};

export default Records;
