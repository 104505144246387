import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import _ from 'lodash';
// react plugin for creating charts

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { DateRange } from '@appbaseio/reactivesearch';
import Button from '../../darkblue-ui/Button/Button';
import FilterListIcon from '@material-ui/icons/FilterList';

// @darkblue-ui/components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from '../../darkblue-ui/Spinners/Loader';
import BluestoneLogoComponent from '../../darkblue-ui/BluestoneLogoComponent/BluestoneLogoComponent';
import FilterDrawer from '../../components/FilterDrawer/FilterDrawer';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import InlineDateFilter from '../../components/CustomFilters/InlineDateFilter';
import CalendarFilter from '../../darkblue-ui/CustomRSComponents/Filters/CalendarFilter/CalendarFilter';
import FilterSidebar from '../../darkblue-ui/FilterSidebar/FilterSidebar';
import FilterModal from '../../darkblue-ui/FilterModal/FilterModal';
import Results from '../../darkblue-ui/Results/Results';
import { startSearchDate } from '../../consts/MagicValues';
import {
  UniversalFilterNameList,
  UniversalFieldNameList,
  UniversalSelectorList,
  UniversalFilterComponentList,
  UniversalCategoryList,
  UniversalDefaultFilterState,
} from '../../filters/UniversalFilters';

import {
  numberWithCommas,
  getDefaultFilterState,
  getUrlFilterParams,
  getFilterValues,
  getDefaultIndexFilterState,
} from '../../filters/utils';

import { ReactiveComponent } from '@appbaseio/reactivesearch';

import Cluster from '../Elastic/Cluster';
import CustomSelectedFilters from '../../components/CustomSelectedFilters/CustomSelectedFilters';

import DropdownSelectFilter from '../../darkblue-ui/CustomRSComponents/Filters/DropdownSelectFilter/DropdownSelectFilter.js';

import {
  VictoryChart,
  VictoryBar,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryLabel,
  VictoryAxis,
  VictoryContainer,
  VictoryVoronoiContainer,
  VictoryGroup,
} from 'victory';

import { onlineQuery, aiFieldQuery } from '../../utils/Queries/CustomQueries';
import Counter from '../../darkblue-ui/Widgets/Counter/Counter';

import { downloadAsPDF } from '../../utils/Functions/utils.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

const Title = styled.h3`
  color: ${(props) => props.theme.colors.cardTitle};
`;

const Buffer = styled.div`
  min-width: 180px;
`;

const ThemedGetAppIcon = styled(GetAppIcon)`
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary};
    cursor: pointer;
  }
`;

const StyledCard = styled(Card)`
  background-color: ${(props) => props.theme.colors.cardBackground} !important;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img``;

const SitesDashboard = (props) => {
  const { index, logo } = props;
  const filters = UniversalFilterComponentList;
  const filterNames = getFilterValues(index, UniversalFilterNameList);
  const filterCategories = getFilterValues(index, UniversalCategoryList);
  const selectors = getFilterValues(index, UniversalSelectorList);
  const location = useLocation();
  const themeContext = useContext(ThemeContext);

  const [filterState, setFilterState] = useState(
    getDefaultFilterState(filters, {
      ...getDefaultIndexFilterState(index, UniversalDefaultFilterState),
      ...getUrlFilterParams(location.search),
    }),
  );
  const [triggerQuery, setTriggerQuery] = useState(null);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  useEffect(() => {
    if (triggerQuery) {
      triggerQuery();
    }
  }, [filterState, triggerQuery]);

  return (
    <div>
      <Cluster index={index.join(',')}>
        <GridContainer
          style={{ marginRight: '24px', marginTop: '12px' }}
          justify="flex-end"
          alignItems="center"
        >
          {index.length > 1 && (
            <GridItem>
              <DropdownSelectFilter
                otherField
                filterState={filterState}
                setFilterState={setFilterState}
                componentId="SiteNameFilter"
                filterList={filterNames}
                dataField="site.name.keyword"
              />
            </GridItem>
          )}
          <GridItem>
            <CalendarFilter
              filterList={filterNames}
              filterState={filterState}
              setFilterState={setFilterState}
            />
          </GridItem>
          <GridItem>
            <Tooltip title="Download Report">
              <ThemedGetAppIcon
                onClick={() =>
                  downloadAsPDF(
                    'dashboard',
                    'DarkBlue Vendor Trends',
                    themeContext.colors.mainBackground,
                  )
                }
              />
            </Tooltip>
          </GridItem>
        </GridContainer>
        <FilterSidebar
          filterState={filterState}
          setFilterState={setFilterState}
          filterList={filterNames}
          hideExpanded
          expanded={true}
          hide
          index={index}
          filters={filters}
        />
        <FilterModal
          filters={filters}
          filterCategories={filterCategories}
          filterState={filterState}
          filterList={filterNames}
          setFilterState={setFilterState}
          filterModalOpen={false}
          setFilterModalOpen={() => null}
        />
        <div id="dashboard" style={{ display: 'flex', paddingLeft: '20px' }}>
          <div style={{ width: '100%' }}>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={8}>
                <Title style={{ marginTop: '0px' }}>Sites Mission Space</Title>
              </GridItem>
              <GridItem xs={4}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Counter
                      field="hiddenService.keyword"
                      title="Unique URLs"
                      color="warning"
                      reactiveFilters={filterNames}
                      icon={<LanguageIcon />}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Counter
                      field="pageTitle.keyword"
                      title="Unique Page Titles"
                      color="success"
                      reactiveFilters={filterNames}
                      icon={<FileCopyIcon />}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridContainer>
                <GridItem>
                  <InlineDateFilter
                    setFilterState={setFilterState}
                    filterState={filterState}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer alignItems="center">
                <GridItem xs={11}>
                  <CustomSelectedFilters
                    filterState={filterState}
                    filters={filters}
                    index={index}
                    setFilterState={setFilterState}
                    dashboard
                  />
                </GridItem>
              </GridContainer>
            </GridContainer>
            <GridContainer
              style={{ position: 'relative', zIndex: '0' }}
              alignItems="center"
            >
              <GridItem xs={6}>
                <ReactiveComponent
                  componentId="aiFieldComponent"
                  customQuery={aiFieldQuery}
                  defaultQuery={aiFieldQuery}
                  react={{
                    and: [...filterNames],
                  }}
                  render={(props) => {
                    const { aggregations, loading } = props;
                    let aggData = [];
                    if (!loading && aggregations && aggregations.fieldContent) {
                      aggData = aggregations.fieldContent.buckets;
                    }
                    const chartData = aggData.map((item) => {
                      if (item) {
                        return { x: String(item.key), y: item.doc_count };
                      } else {
                        return { x: String(item.key), y: 0 };
                      }
                    });

                    return (
                      <StyledCard>
                        <CardHeader>
                          <h4>Record Composition</h4>
                        </CardHeader>
                        <CardBody>
                          {props.loading ? (
                            <Loader />
                          ) : (
                            <VictoryChart
                              theme={VictoryTheme.material}
                              containerComponent={<VictoryContainer />}
                              padding={{
                                top: 30,
                                bottom: 90,
                                left: 60,
                                right: 60,
                              }}
                            >
                              <VictoryAxis
                                theme={VictoryTheme.material}
                                renderInPortal
                                style={{
                                  tickLabels: { paddingBottom: 40, angle: 45 },
                                }}
                                tickLabelComponent={
                                  <VictoryLabel dy={10} textAnchor="start" />
                                }
                              />
                              <VictoryAxis dependentAxis />
                              <VictoryBar
                                style={{ data: { fill: '#c43a31' } }}
                                alignment="middle"
                                data={chartData}
                                y={(datum) => datum.y}
                                labels={({ datum }) =>
                                  `Items: ${numberWithCommas(datum.y)}`
                                }
                                labelComponent={
                                  <VictoryTooltip constrainToVisableArea />
                                }
                              />
                            </VictoryChart>
                          )}
                        </CardBody>
                      </StyledCard>
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <ReactiveComponent
                  componentId="onlineComponent"
                  customQuery={onlineQuery}
                  defaultQuery={onlineQuery}
                  react={{
                    and: ['TimeFilter', 'LocationFilter', 'LanguageFilter'],
                  }}
                  render={(props) => {
                    const { aggregations, loading } = props;
                    let aggData = [];
                    let onlineSeries = [];
                    let newSeries = [];
                    if (
                      !loading &&
                      aggregations &&
                      aggregations.dateHist &&
                      aggregations.dateHist.buckets
                    ) {
                      aggData = aggregations.dateHist.buckets;

                      onlineSeries = aggData.map((bucket) => {
                        return {
                          key: 'online',
                          x: bucket.key_as_string.split(' ', 1)[0],
                          y: bucket.doc_count,
                        };
                      });

                      newSeries = aggData.map((bucket) => {
                        if (bucket.newSites.buckets[1]) {
                          return {
                            key: 'new',
                            x: bucket.key_as_string.split(' ', 1)[0],
                            y: bucket.newSites.buckets[1].doc_count,
                          };
                        } else {
                          return {
                            key: 'new',
                            x: bucket.key_as_string.split(' ', 1)[0],
                            y: 0,
                          };
                        }
                      });
                    }
                    let chartData = [onlineSeries, newSeries];

                    return (
                      <StyledCard>
                        <CardHeader>
                          <h4>Site Status</h4>
                        </CardHeader>
                        <CardBody>
                          {loading || !(chartData[0] && chartData[0].length) ? (
                            <Loader />
                          ) : (
                            <VictoryChart
                              theme={VictoryTheme.material}
                              containerComponent={<VictoryVoronoiContainer />}
                              padding={{
                                top: 30,
                                bottom: 90,
                                left: 60,
                                right: 80,
                              }}
                            >
                              <VictoryAxis
                                style={{
                                  tickLabels: { paddingBottom: 40, angle: 50 },
                                }}
                                tickCount={8}
                                tickLabelComponent={
                                  <VictoryLabel dy={10} textAnchor="start" />
                                }
                              />
                              <VictoryAxis dependentAxis />
                              <VictoryGroup>
                                {chartData.map((net) => {
                                  return (
                                    <VictoryLine
                                      data={net}
                                      key={net[0].key}
                                      labels={({ datum }) => {
                                        return `${numberWithCommas(datum.y)} ${
                                          datum.key
                                        } sites`;
                                      }}
                                      labelComponent={
                                        <VictoryTooltip
                                          constrainToVisableArea
                                        />
                                      }
                                    />
                                  );
                                })}
                              </VictoryGroup>
                            </VictoryChart>
                          )}
                        </CardBody>
                      </StyledCard>
                    );
                  }}
                />
              </GridItem>
            </GridContainer>
            <BluestoneLogoComponent logo={logo} />
          </div>
        </div>
      </Cluster>
    </div>
  );
};

export default SitesDashboard;
