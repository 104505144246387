import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Button from '../../../Button/Button';

import { MultiDropdownList } from '@appbaseio/reactivesearch';

const StyledMarketFilter = styled(MultiDropdownList)`
  &&& button {
    display: none;

    &.show {
      display: inherit;
    }
  }
  &&& > h2 {
    display: none;
  }
`;

const FilterContainer = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.cardBackground};
  padding: 12px 20px;
  left: 0;
  right: 0;
  margin-top: 12px;
  box-shadow: ${(props) => props.theme.shadow.medHeavy};
  z-index: 15;
`;

const Market = styled.div`
  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.success
      : props.theme.colors.primaryText};
  & > span {
    &:hover {
      color: ${(props) =>
        props.isSelected ? props.theme.danger : props.theme.colors.altPrimary};
    }
  }
  &:hover {
    cursor: pointer;
  }
`;

const isSelected = (filterState, data) => {
  return filterState.MarketFilter.indexOf(data) > -1;
};

const MarketFilter = ({
  filterState,
  setFilterState,
  filterList = [],
  ...rest
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <StyledMarketFilter
      componentId="MarketFilter"
      filterType="SelectFilter"
      dataField="site.name.keyword"
      selectAllLabel={` All Markets  ${`\n`} ___________`}
      showFilter={true}
      URLParams={true}
      value={filterState.MarketFilter}
      onChange={(value, triggerQuery) => {
        const newFilterState = {
          ...filterState,
          ...{ MarketFilter: value },
        };
        setFilterState(newFilterState);
        if (triggerQuery) triggerQuery();
      }}
      render={({ loading, error, data, handleChange }) => {
        if (loading || data.length === 0) {
          return <div>Fetching Results.</div>;
        }
        if (error) {
          return (
            <div>
              Something went wrong! Error details {JSON.stringify(error)}
            </div>
          );
        }
        return (
          <div>
            <Button className="show" onClick={toggleDropdown}>
              Markets
            </Button>
            {showDropdown ? (
              <FilterContainer>
                {data.map((market) => (
                  <Market
                    isSelected={isSelected(filterState, market.key)}
                    onClick={() => handleChange(market.key)}
                    key={market.key}
                  >
                    <span>{market.key}</span>
                  </Market>
                ))}
              </FilterContainer>
            ) : null}
          </div>
        );
      }}
    />
  );
};

export default MarketFilter;
