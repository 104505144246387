const mode = 'dark';

const primary = '#025CC2';

const primaryOpacity = 'rgba(2, 92, 194, 0.2)';

const gradientBasePrimary = '#014ca1';

const altPrimary = '#2A8DFE';

const altPrimaryOpacity = `rgba(42,141,254,0.51)`;

const almostBlack = '#071D36';

const navy = '#071D36';

const charcoal = '#67737E';

const charcoalOpacity = 'rgba(103, 115, 126, 0.38)';

const cloud = '#99A8B6';

const fog = '#DEECFA';

const plume = '#F6FBFF';

const white = '#FFFFFF';

const bloodOrange = '#D99957';

const morganite = '#5D3EB4';

const aqua = '#3E99B4';

const apple = '#9DB43E';

const danger = '#9C382D';

const success = '#47AA71';

const warning = '#F0AD4E';

const usperTag = '#BFDCFE';

const alertIconDanger = '#f44336';

const alertIconSuccess = '#4caf50';

const alertIconWarning = '#ff9800';

const primaryText = '#DEECFA';

const actionButton = primaryText;

const secondaryText = charcoalOpacity;

const linkText = apple;

const sidebar = fog;

const sidebarText = charcoal;

const sidebarActiveText = primary;

const filterSidebar = '#0c2845';

const filterSidebarActiveText = altPrimary;

const filterSidebarText = primaryText;

const mainBackground = '#091B2E';

const cardBackground = '#0C2845';

const indexTag = '#F3E0CC';

const contentTag = '#BFDCFE';

const cardTitle = '#DEECFA';

const cardBody = '#F6FBFF';

const cardBodyTitle = '#99A8B6';

const tdTextColorEven = '#0c2845';

const tdTextColorOdd = '#deecfa';

const tableBackground = mainBackground;

const alternativeTableBackground = '#1B3254';

const tableText = primaryText;

const footerTitle = '#99A8B6';

const footerBody = '#F6FBFF';

const highlight = '#F9EE06';

const sandlewood = '#6D3D14';

const robin = '#00C2D1';

const tangerine = '#E58606';

const chetwodeBlue = '#5D69B1';

const puertoRico = '#52BCA3';

const atlantis = '#99C945';

const fuschia = '#CC61B0';

const elm = '#24796C';

const galliano = '#DAA51B';

const pelorous = '#2F8AC4';

const studio = '#764E9F';

const burntSienna = '#ED645A';

const medRedViolet = '#CC3A8E';

const multiSelect = '#091b2e';

const multiSelectColor = '#FFFFFF';

const multiSelectChip = '#deecfa';

const muiPaperBackgroundColor = '#deecfa';

const muiToolbar = '#8bc34a';

const muiText = 'black';

const buttonColor = '#2a8dfe';

const muiPaperColor = '#deecfa';

const muiHeader = '#deecfa';

const chartColors = [
  pelorous,
  galliano,
  atlantis,
  elm,
  fuschia,
  tangerine,
  puertoRico,
  chetwodeBlue,
  studio,
  burntSienna,
  medRedViolet,
  sandlewood,
  robin,
  // primary,
  // bloodOrange,
  // morganite,
  // aqua,
  // apple,
  // danger,
  // success,
  // warning,
];

const searchPrevColors = [
  {
    line: fog,
    area: primary,
  },
];

const bitcoinChartColors = [
  {
    axisBottom: {
      tickStroke: '#999999',
    },
    linearGradient: {
      from: '#0E5EC5',
      to: '#F6F8FF',
    },
    linePath: {
      stroke: '#071D36',
    },
    line: {
      stroke: '#ccc',
    },
    circle: {
      stroke: '#03A9F4',
    },
  },
];
const fontSizes = ['8px', '10px', '12px', '14px', '16px', '24px'];

export default {
  mode,
  colors: {
    primary,
    primaryOpacity,
    altPrimary,
    altPrimaryOpacity,
    almostBlack,
    navy,
    charcoal,
    charcoalOpacity,
    tdTextColorEven,
    tdTextColorOdd,
    cloud,
    fog,
    plume,
    white,
    bloodOrange,
    morganite,
    aqua,
    apple,
    danger,
    success,
    warning,
    usperTag,
    alertIconDanger,
    alertIconSuccess,
    multiSelect,
    multiSelectChip,
    multiSelectColor,
    alertIconWarning,
    highlight,
    sidebar,
    sidebarActiveText,
    sidebarText,
    filterSidebar,
    filterSidebarText,
    filterSidebarActiveText,
    mainBackground,
    cardBackground,
    indexTag,
    contentTag,
    cardTitle,
    cardBody,
    cardBodyTitle,
    footerTitle,
    footerBody,
    primaryText,
    secondaryText,
    linkText,
    chartColors,
    tableBackground,
    alternativeTableBackground,
    tableText,
    actionButton,
    fuschia,
  },
  chartColors: [
    pelorous,
    galliano,
    atlantis,
    elm,
    fuschia,
    tangerine,
    puertoRico,
    chetwodeBlue,
    studio,
    burntSienna,
    medRedViolet,
    sandlewood,
    robin,
    // primary,
    // bloodOrange,
    // morganite,
    // aqua,
    // apple,
    // danger,
    // success,
    // warning,
  ],
  gradients: {
    base: gradientBasePrimary,
    trending: ['#025CC2', '#1a5eaa', '#3470b4', '#4d82bd', '#6794c7'].reverse(),
    scale: [primary],
  },
  dateTimePickerColors: {
    buttonColor: '#2a8dfe',
    muiHeader: '#deecfa',
    muiToolbar: '#deecfa',
    muiText: 'black',
    muiPaperBackgroundColor: '#deecfa',
    dateText: '#FFFFFF',
    dateButtonColor: '#025CC2',
  },
  searchPrevColors,
  bitcoinChartColors,
  type: {
    p: {
      xl: { fontSize: '20px', lineHeight: '25px' },
      l: { fontSize: '16px', lineHeight: '20px' },
      m: { fontSize: '14px', lineHeight: '18px' },
      s: { fontSize: '12px', lineHeight: '15px' },
    },
  },
  shadow: {
    sidebar: '0px 2px 4px 0px rgba(14,58,108,1)',
    light: '0px 2px 4px 0px rgba(7,29,54,0.15)',
    medLight: '0px 4px 8px 0px rgba(7,29,54,0.15)',
    medHeavy: '0px 8px 16px 0px rgba(7,29,54,0.15)',
    heavy: '0px 16px 32px 0px rgba(7,29,54,0.15)',
    notification: '0 0 0 0px rgba(255, 255, 255, 0.2)',
  },
  fontSizes,
};
