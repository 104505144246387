import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { UserConsumer } from '../../DBAuthenticator.js';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import Button from '../Button/Button';
import { Redirect } from 'react-router-dom';

import { startSearchDate } from 'consts/MagicValues';

import DeleteModal from '../../components/Table/DeleteModal.js';
import Dialog from '@material-ui/core/Dialog';
import { AtomSpinner } from 'react-epic-spinners';

const apiHost = 'https://' + process.env.REACT_APP_API_HOST;

const MiniLoader = styled(AtomSpinner).attrs({
  size: 50,
})`
  padding: 10px;

  .atom-spinner .spinner-inner .spinner-line {
    border-color: ${(props) => props.theme.colors.altPrimary} !important;
  }
  * {
    border-color: ${(props) => props.theme.colors.altPrimary} !important;
    color: ${(props) => props.theme.colors.altPrimary} !important;
  }
`;

// const useStyles = makeStyles(styles);

const Styles = styled.div`
  padding: 1rem;
  overflow-x: auto;

  table {
    margin: 25px auto;
    border-collapse: collapse;
    border: 1px solid #eee;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: 100%;

    tr {
      &:hover {
        background: #f4f4f4;

        td {
          color: black;
        }
      }
    }

    th,
    td {
      padding: 5px 35px;
      border-collapse: collapse;
      text-align: left;
    }
    th {
      background: #2a8dfe;
      color: #fff;
      text-transform: capitalize;
      font-size: 12px;
      text-align: left;
      &.last {
        border-right: none;
      }

      span[role=columnheader] {
        margin-left: 10px
      }
    }
  
  .pagination {
    padding: 0.5rem;
  }
`;

const NoDataContainer = styled.div`
  width: 100%
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoData = () => {
  return (
    <NoDataContainer>
      <h2>No Data Found </h2>
    </NoDataContainer>
  );
};

const handleDeleteSavedSearch = async function(userSess, id) {
  const userIndex = '.user_' + userSess.user.username;
  const userApi = 'ApiKey ' + userSess.apiKey.key;

  let response = await fetch(`${apiHost}/${userIndex}/_doc/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: userApi,
    },
  });
  return response;
};

const handleSearchRedirect = async (userSess, row, setRedirectPage) => {
  const userIndex = '.user_' + userSess.user.username;
  const userApi = 'ApiKey ' + userSess.apiKey.key;
  const today = dayjs().format('YYYY-MM-DD HH:mm:ss');
  const id = row._id;
  let response = {};
  if ('alert' in row && row['alert']['enabled'] === true) {
    response = await fetch(`${apiHost}/${userIndex}/_update/${id}`, {
      method: 'POST',
      headers: {
        Authorization: userApi,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        doc: {
          alert: {
            hits: 0,
          },
          timestamp: today,
        },
      }),
    });
  }
  setRedirectPage();
  return response;
};

const handleToggleNotifications = async (
  userSess,
  id,
  recordData,
  fire,
  setFire,
  setLoading,
) => {
  const userIndex = '.user_' + userSess.user.username;
  const userApi = 'ApiKey ' + userSess.apiKey.key;
  const newValue = !Boolean(recordData.alert && recordData.alert.enabled);
  const today = dayjs().format('YYYY-MM-DD HH:mm:ss');
  const qs = atob(recordData.query);
  var searchParams = new URLSearchParams(qs);
  if (qs.indexOf('TimeFilter') !== -1) {
    searchParams.delete('TimeFilter');
  }
  const newQs = '?' + searchParams.toString().replace(/\+/g, '%20');

  let response = await fetch(`${apiHost}/${userIndex}/_update/${id}`, {
    method: 'POST',
    headers: {
      Authorization: userApi,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      doc: {
        alert: {
          enabled: newValue,
          hits: 0,
        },
        timestamp: today,
        query: btoa(newQs),
      },
    }),
  });
  if (response) {
    setLoading(true);
    window.setTimeout(() => {
      setLoading(false);
      setFire(fire + 1);
    }, 5000);
  } else {
    return response;
  }
};

export default function SavedItemTable({
  data,
  showNotes,
  tableKey,
  fire,
  setFire,
  columns,
}) {
  const [redirectPage, setRedirectPage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [queryIdToDelete, setQueryIdToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const table = useTable({ columns, data });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = table;

  return (
    <div>
      <UserConsumer>
        {(userSess) => (
          <Styles>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((hg) => {
                  return (
                    <tr key={hg.id} {...hg.getHeaderGroupProps()}>
                      {hg.headers.map((col) => {
                        return (
                          <th key={col.id} {...col.getHeaderProps()}>
                            {col.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                rows.map((row) => {
                  const currentPath = window.location.pathname;
                  const timestamp = row.original['timestamp'];
                  const rightNow = dayjs().format('YYYY-MM-DD HH:mm:ss');
                  let decodedQuery = atob(row.original['query']);

                  if (decodedQuery.indexOf('DateTimeFilter') !== -1) {
                    decodedQuery = decodedQuery.replace(/\+/g, '%20');
                  }

                  if (
                    row.original?.alert?.enabled &&
                    decodedQuery.indexOf('DateTimeFilter') === -1 &&
                    row.original?.doc?.type === 'search'
                  ) {
                    decodedQuery += `&DateTimeFilter=%7B"value"%3A%7B"startValue"%3A"${timestamp}"%2C"endValue"%3A"${rightNow}"%7D%7D`;
                  }

                  const path = row.original['endpoint'];
                  const nextPath = path + decodedQuery;
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    <React.Fragment key={row.id}>
                      {redirectPage ? (
                        <Redirect from={currentPath} to={redirectPage} />
                      ) : null}
                      <tr {...row.getRowProps()}>
                        {// Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td key={cell.id} {...cell.getCellProps()}>
                              {// Render the cell contents
                              loading &&
                              cell.id === 'alerting_enabled_checkbox' ? (
                                <MiniLoader />
                              ) : (
                                cell.render('Cell', {
                                  handleRedirect: () =>
                                    handleSearchRedirect(
                                      userSess,
                                      row.original,
                                      () => setRedirectPage(nextPath),
                                    ),
                                  handleDelete: () => {
                                    setQueryIdToDelete(row.original._id);
                                    setShowDeleteModal(true);
                                  },
                                  handleToggleNotifications: () => {
                                    handleToggleNotifications(
                                      userSess,
                                      row.original._id,
                                      row.original,
                                      fire,
                                      setFire,
                                      setLoading,
                                    );
                                  },
                                })
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>

            {data.length === 0 ? <NoData /> : null}
            <Dialog fullWidth={true} maxWidth="sm" open={showDeleteModal}>
              <DeleteModal
                handleDeleteSubmit={(userSess) => {
                  handleDeleteSavedSearch(userSess, queryIdToDelete);
                }}
                setShowModal={setShowDeleteModal}
                fire={fire}
                setFire={setFire}
              />
            </Dialog>
          </Styles>
        )}
      </UserConsumer>
    </div>
  );
}

SavedItemTable.defaultProps = {
  tableHeaderColor: 'gray',
  hover: false,
  colorsColls: [],
  showNotes: false,
  coloredColls: [],
  striped: false,
  SavedItemTable: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
};

SavedItemTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
};
