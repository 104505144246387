import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field, Form } from 'formik';
import { useSnackbar } from 'notistack';

import Modal from 'darkblue-ui/Modals/Modal/Modal';

import Input from 'darkblue-ui/Inputs/Input';
import {
  sendUserVerificationCode,
  updateAttribute,
  gerUserInfo,
  verifyCurrentUserAttribute,
} from './utils';
import Button from 'darkblue-ui/Button/Button';

const ModalBody = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.cardBackground};
  overflow-y: auto;
`;
export default function ConfrimEmailPhone({
  verifyEmailPhone,
  setVerifyEmailPhone,
  snackBar,
}) {
  const {
    confirmType,
    attributeName,
    title,
    email,
    open = false,
    phone_number,
  } = verifyEmailPhone;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Modal
      open={open}
      onClose={() => setVerifyEmailPhone({ ...verifyEmailPhone, open: false })}
      onClickOutside={() =>
        setVerifyEmailPhone({ ...verifyEmailPhone, open: false })
      }
    >
      <div>
        <ModalBody>
          <h1
            style={{
              fontWeight: '500',
              fontSize: '14px',
              textAlign: 'center',
            }}
          >
            Confirm {title}
          </h1>
          <Formik
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                let result = await verifyCurrentUserAttribute(
                  attributeName,
                  values.code,
                );

                snackBar(`${title} verified!`, {
                  variant: 'success',
                });

                setSubmitting(false);
                setVerifyEmailPhone({ ...verifyEmailPhone, open: false });
                resetForm({});
              } catch (e) {
                console.log(e);
              }
            }}
            initialValues={{ code: '' }}
          >
            {({
              values,
              isValid,
              handleSubmit,
              handleBlur,
              handleChange,
              resetForm,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div xs={6}>
                      <Input
                        re
                        type="text"
                        name="code"
                        style={{ width: '100%' }}
                        label="Confirmation Code"
                        onBlur={handleBlur}
                        inputProps={{
                          onChange: handleChange,
                          value: values.code || '',
                          name: 'email',
                        }}
                      />
                    </div>
                    <div
                      style={{
                        margin: '20px 10px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        disabled={!values.code}
                        style={{ marginTop: '22px', padding: '10px 20px' }}
                        type="submit"
                      >
                        Verify
                      </Button>
                      <Button
                        type="button"
                        style={{
                          marginTop: '22px',
                          marginLeft: '10px',
                          padding: '10px 20px',
                        }}
                        onClick={async () => {
                          try {
                            const response = await sendUserVerificationCode(
                              attributeName,
                            );
                            setVerifyEmailPhone({
                              ...verifyEmailPhone,
                              open: false,
                            });
                            enqueueSnackbar(
                              `Verification code sent to ${
                                confirmType == 'email' ? email : phone_number
                              }`,
                              {
                                variant: 'success',
                              },
                            );
                          } catch (err) {
                            console.log(err);
                            enqueueSnackbar(
                              'An error occurred. Please contact support.',
                              {
                                variant: 'error',
                              },
                            );
                          }
                        }}
                      >
                        Resend Code
                      </Button>
                      <Button
                        style={{
                          marginTop: '22px',
                          marginLeft: '10px',
                          padding: '10px 20px',
                        }}
                        type="reset"
                        onClick={() => {
                          setVerifyEmailPhone({
                            ...verifyEmailPhone,
                            open: false,
                          });
                          resetForm();
                        }}
                        secondary
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </div>
    </Modal>
  );
}
