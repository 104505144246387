import React from 'react';
import styled, { css } from 'styled-components';

// @darkblue-ui/components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

/**
 * @Styled Component
 * component styling Section
 */
const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  width: 150px;
`;

/**
 * @Component BlueStoneLogo
 * Renders: Bluestone branding logo.
 * @param  {obj} props
 * logo - Bluestone analytics branding logo.
 */
const BlueStoneLogo = (props) => {
  const { logo } = props;
  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      style={{ paddingBottom: '16px' }}
    >
      <GridItem>
        <p style={{ textAlign: 'center' }}>support@bluestoneanalytics.caci.com</p>
        <p style={{ textAlign: 'center' }}>1-800-503-5921</p>
      </GridItem>
      <GridItem>
        <LogoContainer>
          <Logo src={logo} alt="logo" />
        </LogoContainer>
      </GridItem>
    </GridContainer>
  );
};

export default BlueStoneLogo;
