import { createContext } from 'react';


export const StateContext = createContext({
  filterState: {},
  setFilterState: null,
  hits: [],
  loading: false,
  page: 0,
  sort: 0,
  setPage: null,
  totalHits: 0,
  apiUrl: '',
});

export const StateProvider = StateContext.Provider;
export const StateConsumer = StateContext.Consumer;
