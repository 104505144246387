import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useTable, useSortBy } from 'react-table';
import styled from 'styled-components';

import MUITooltip from '@material-ui/core/Tooltip';
import Select from 'react-select';

import HighlightComponent from '../composite/DarkBlueCard/HighlightComponent.js';
import Input from '../Inputs/Input';
import CustomSelectedFilters from '../../components/CustomSelectedFilters/CustomSelectedFilters';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

/**
 * @Styled Component
 * component styling Section
 */
const CustomMultiSelect = styled(Select)`
.select__menu {
  background-color: ${(props) => props.theme.colors.multiSelect};
   color:  ${(props) => props.theme.colors.multiSelectColor};
  } 
}

.select__control {
    background-color: ${(props) => props.theme.colors.multiSelect};
    border: 1px solid rgba(42, 141, 254, 0.51);
    border-radius: 0px;
  }

  .select__multi-value {
    background-color ${(props) => props.theme.colors.multiSelectChip}
  }
`;

const Styles = styled.div`
  padding: 1rem;

  table {
    margin: 25px auto;
    border-collapse: collapse;
    border: 1px solid #eee;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    table-layout: ${(props) =>
      props.index === 'scans' ? 'fixed !important;' : null}

    tr {
      &:hover {
        background: #deecfa;

        td {
          color: black;
        }
      }
    }

    th,
    td {
      border: 1px solid #eee;
      padding: 8px;
      border-collapse: collapse;
      text-align: center;
      word-wrap: break-word;
      text-align: left;
    }

    th {
      background: #2a8dfe;
      color: #fff;
      text-transform: capitalize;
      font-size: 12px;
      &.last {
        border-right: none;
      }

      span[role=columnheader] {
        margin-left: 10px
      }


      &:hover {
        cursor: pointer;
      }
    }
  .pagination {
    padding: 0.5rem;
  }
`;

const TableDataExpandDiv = styled.div`
  width: fit-content;
  height: fit-content;
  a:hover {
    cursor: pointer;
  }
  align-self: flex-end;
`;

/**
 * @Component Text Filter Component
 * Renders: Displays column filter search
 *  @param  {obj} prop
 *  filterState - filterState obj for filtering
 *  setFilterState - sets filterstate obj for table
 *  filterName - name to be applied to column filter state
 *  setColumnFilterState - sets column filter obj
 *   ...rest
 */
function TextFilter({ filterState, filterName, setFilterState, ...rest }) {
  const [value, setValue] = useState('');

  return (
    <span>
      <Input
        style={{
          height: '36px',
          width: '180px',
          fontSize: '12px',
          marginBottom: '10px',
        }}
        button
        name="textValue"
        label={`Search ${rest.header.charAt(0).toUpperCase() +
          rest.header.slice(1)} Column`}
        inputProps={{
          value: value || '',
          onSubmit: () => {
            const newFilterState = {
              ...filterState,
              columnFilterState: {
                ...filterState.columnFilterState,
                [filterName]: value,
              },
            };

            setFilterState(newFilterState);
          },
          onChange: (e) => {
            setValue(e.target.value || undefined);
          },
        }}
      />
    </span>
  );
}

/**
 * @Component React Table
 * Renders: scans and database table
 * @param  {obj} props
 *  data -  data to render react-table.
 *  filterState - filterstate object
 *  columnFilterState - column search filterstate
 *  setColumnFilterState - column filterstate setter
 *  index - scans | database table index
 *  columns - react-table column display
 *  defaultColumnsList - multi-select default column list
 *  setDefaultColumnsList - sets default column list based on user input
 */
const ReactTable = ({
  data,
  filterState,
  setFilterState,
  index,
  columns,
  defaultColumnsList,
  setDefaultColumnsList,
  rowArr,
  setRowArr,
}) => {
  const safeData = data || [];

  let allColumnNames =
    safeData.length > 0
      ? [
          ...new Set(
            safeData
              .map((record) => {
                return Object.entries(record).map(([key, value]) => {
                  return key;
                });
              })
              .flat(1)
              .sort(),
          ),
        ]
      : columns;

  //multi select defaultValues list.
  const multiSelectDefaultColumnValues =
    defaultColumnsList.length > 0
      ? defaultColumnsList
          .filter((x) => allColumnNames.indexOf(x) > -1)
          .map((column) => {
            return {
              id: column,
              value: column,
              label: column,
            };
          })
      : allColumnNames.map((column) => {
          return {
            id: column,
            value: column,
            label: column,
          };
        });

  const columnNames = [
    ...new Set([
      ...defaultColumnsList.filter((x) => allColumnNames.indexOf(x) > -1),
      ...allColumnNames,
    ]),
  ];

  const Tablecolumns = useMemo(
    () =>
      columnNames.map((record) => {
        return {
          Header: record,
          accessor: (row) =>
            _.get(row, record) !== undefined ? (
              <HighlightComponent text={String(_.get(row, record))} />
            ) : (
              undefined
            ),
        };
      }),
    [filterState, safeData, defaultColumnsList, setDefaultColumnsList],
  );

  const tableData = useMemo(() => safeData, [
    filterState,
    safeData,
    defaultColumnsList,
    setDefaultColumnsList,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    allColumns,
    prepareRow,
    setHiddenColumns,
  } = useTable(
    {
      columns: Tablecolumns,
      data: tableData,
      initialState: {
        sortBy: [
          {
            id: 'timestamp',
            desc: false,
          },
        ],

        //initialize hidden columns with columns not contained in the visibleColumn array.
        hiddenColumns: Tablecolumns.filter(
          ({ Header }) =>
            !multiSelectDefaultColumnValues.find(
              ({ value }) => Header === value,
            ),
        ).map((column) => column.Header),
      },
    },

    useSortBy,
  );

  return (
    <>
      <CustomSelectedFilters
        filterState={filterState}
        setFilterState={setFilterState}
        index={index}
      />
      <Styles index={index}>
        {allColumns.length > 0 && (
          <GridContainer>
            <div style={{ width: '100%' }}>
              <p style={{ fontWeight: 'bold' }}>
                {multiSelectDefaultColumnValues.length > 1
                  ? `Visible Columns`
                  : 'Visible Column'}
              </p>
              <CustomMultiSelect
                defaultValue={multiSelectDefaultColumnValues}
                isMulti
                name="HideColumns"
                options={allColumns.map((column) => {
                  return {
                    id: column.id,
                    value: column.Header,
                    label: column.Header,
                  };
                })}
                value={multiSelectDefaultColumnValues}
                styles={{
                  option: (styles, { isFocused }) => ({
                    ...styles,
                    color: isFocused ? 'black' : null,
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }),
                  multiValueRemove: (styles) => ({
                    ...styles,
                    ':hover': {
                      backgroundColor: '#025cc2',
                      color: 'white',
                      cursor: 'pointer',
                    },
                  }),
                  indicatorsContainer: (styles) => ({
                    ...styles,
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }),
                }}
                isClearable={true}
                classNamePrefix="select"
                onChange={(value, { action }) => {
                  switch (action) {
                    case 'select-option':
                    case 'remove-value':
                    case 'pop-value':
                      if (value && value.length > 0) {
                        setDefaultColumnsList(value.map(({ value }) => value));
                        setHiddenColumns(
                          Tablecolumns.filter(
                            ({ Header }) =>
                              !multiSelectDefaultColumnValues.find(
                                ({ value }) => Header === value,
                              ),
                          ).map((column) => column.Header),
                        );
                        return;
                      }
                      break;
                    case 'clear':
                      setDefaultColumnsList([
                        multiSelectDefaultColumnValues
                          .map(({ value }) => value)
                          .shift(),
                      ]);
                      break;
                  }
                }}
              />
            </div>
          </GridContainer>
        )}
        <GridContainer style={{ overflowX: 'scroll' }}>
          <table
            style={{ margin: '5px auto', minWidth: '100%' }}
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr
                  key={`headerGroup_${i}`}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) =>
                    column.Header !== 'timestamp' ? (
                      <MUITooltip
                        key={column.Header}
                        interactive
                        placement="top"
                        title={
                          <div>
                            <TextFilter
                              header={column.Header}
                              filterName={column.Header}
                              filterState={filterState}
                              setFilterState={setFilterState}
                            />
                          </div>
                        }
                        arrow
                      >
                        <th>
                          <span>{column.Header}</span>
                          <span
                            {...column.getHeaderProps(
                              column.getSortByToggleProps(),
                            )}
                            onClick={() =>
                              column.toggleSortBy(!column.isSortedDesc)
                            }
                          >
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i
                                  className="fa fa-sort-down"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-sort-up"
                                  aria-hidden="true"
                                ></i>
                              )
                            ) : (
                              <i className="fa fa-sort" aria-hidden="true"></i>
                            )}
                          </span>
                        </th>
                      </MUITooltip>
                    ) : (
                      <th key={column.Header}>
                        <span>{column.Header}</span>
                        <span
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          onClick={() =>
                            column.toggleSortBy(!column.isSortedDesc)
                          }
                        >
                          {column.isSorted ? (
                            !column.isSortedDesc ? (
                              <i
                                className="fa fa-sort-down"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-sort-up"
                                aria-hidden="true"
                              ></i>
                            )
                          ) : (
                            <i className="fa fa-sort" aria-hidden="true"></i>
                          )}
                        </span>
                      </th>
                    ),
                  )}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row, i) => {
                  prepareRow(row);

                  return (
                    <tr key={`row_${i}`} {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        let tempArray = rowArr;
                        const header = cell.column.Header;
                        const cellText = `${cell.row.original.message}`;
                        if (header === 'message' && rowArr.length < 10) {
                          tempArray.push({
                            cell: cell,
                            expanded: false,
                          });
                          setRowArr((arr) => [
                            ...arr,
                            { cell: cell, expanded: false },
                          ]);
                        }
                        return cellText.length > 200 && header === 'message' ? (
                          rowArr[i]?.expanded ? (
                            <td
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              key={cell}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                              <TableDataExpandDiv
                                onClick={() => {
                                  tempArray = [
                                    ...rowArr.slice(0, i),
                                    {
                                      ...rowArr[i],
                                      ...{
                                        expanded: false,
                                      },
                                    },
                                    ...rowArr.slice(i + 1),
                                  ];
                                  setRowArr(tempArray);
                                }}
                              >
                                <a>Minimize</a>
                              </TableDataExpandDiv>
                            </td>
                          ) : (
                            <td
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              key={cell}
                              {...cell.getCellProps()}
                            >
                              {cellText.slice(0, 197) + '...'}
                              <TableDataExpandDiv
                                onClick={() => {
                                  tempArray = [
                                    ...rowArr.slice(0, i),
                                    {
                                      ...rowArr[i],
                                      ...{
                                        expanded: true,
                                      },
                                    },
                                    ...rowArr.slice(i + 1),
                                  ];
                                  setRowArr(tempArray);
                                }}
                              >
                                <a>Expand</a>
                              </TableDataExpandDiv>
                            </td>
                          )
                        ) : (
                          <td key={cell} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td style={{ fontSize: '15px' }} colSpan="10">
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </GridContainer>
      </Styles>
    </>
  );
};

export default ReactTable;
