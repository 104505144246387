import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../../Button/Button';

import { FileCopy } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import StarIcon from '@material-ui/icons/Star';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import {
  handleFilterOn,
  handleFilterOut,
  handleRequiredFilter,
  removeMarks,
} from '../../../../utils/Functions/utils';

const StyledFieldName = styled.p`
  color: ${(props) => props.theme.colors.navy} !important;
`;

const StyledHoverText = styled.p`
  color: ${(props) => props.theme.colors.navy} !important;
  padding: 2px;
`;

const StyledContainer = styled.div`
  color: ${(props) => props.theme.colors.navy} !important;
  background-color: ${(props) => props.theme.colors.mainBackground};
  border-radius: 0px;
  margin: -10px;
  padding: 4px 8px;
`;

const ActionsContainer = (props) => {
  const {
    setFilterState,
    filterState,
    elasticPath,
    filterName,
    filterValue,
    filterValueType,
    hoverData,
    enqueueSnackbar,
    path,
    copy,
    redirectTo,
    redirectLabel,
    query,
  } = props;

  const btnStyle = {
    margin: '3px auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
    maxWidth: '100%',
  };
  const history = useHistory();

  const hoverDivStyle = hoverData
    ? { display: 'flex', flexDirection: 'column', width: '45%', ...props.style }
    : { display: 'flex', flexDirection: 'column', ...props.style };

  return (
    <>
      {hoverData && (
        <StyledHoverText
          style={{ maxWidth: '500px !important' }}
          className="no-margin"
        >
          {hoverData}
        </StyledHoverText>
      )}
      {!props?.isSelector ?
        <div style={hoverDivStyle}>
          {filterName && typeof filterValue !== 'boolean' ? (
          <>
            {props?.path ? (
              <StyledFieldName style={{ margin: '0 auto' }}>
                {props.path}
              </StyledFieldName>
            ) : null}
            <Button
              style={btnStyle}
              onClick={() => {
                handleFilterOn({
                  filterState: filterState,
                  filterName: filterName,
                  filterValue: filterValue,
                  query: query,
                  elasticPath: elasticPath,
                  onClick: setFilterState,
                  enqueueSnackbar: enqueueSnackbar,
                  filterValueType: filterValueType,
                });
              }}
            >
              Include Value
              <AddIcon style={{ verticalAlign: 'middle' }} />
            </Button>
            <Button
              color="danger"
              style={btnStyle}
              onClick={() =>
                handleFilterOut({
                  filterState: filterState,
                  filterName: filterName,
                  filterValue: filterValue,
                  onClick: setFilterState,
                  elasticPath: elasticPath,
                  filterValueType: filterValueType,
                })
              }
            >
              Exclude Value <RemoveIcon style={{ verticalAlign: 'middle' }} />
            </Button>
            {copy && (
              <Button
                style={btnStyle}
                onClick={() => {
                  const copiedValue =
                    typeof filterValue === 'string'
                      ? filterValue
                      : String(filterValue);
                  const cleanValue = removeMarks(copiedValue);
                  copy(cleanValue);
                  enqueueSnackbar('Successfully Copied!', {
                    variant: 'success',
                  });
                }}
              >
                Copy Text <FileCopy style={{ verticalAlign: 'middle' }} />
              </Button>
            )}
            {redirectLabel && redirectTo ? (
              <Button
                style={btnStyle}
                onClick={() => {
                  const route = `${redirectTo}?Searchbar=%7B%22value%22%3A%22${filterValue}%22%7D`;
                  history.push(route);
                }}
              >
                Search {redirectLabel}
                <SearchIcon style={{ verticalAlign: 'middle' }} />
              </Button>
            ) : null}
          </>
          ) : null}
            <Button
              style={btnStyle}
              onClick={() =>
                handleRequiredFilter({
                  filterState: filterState,
                  onClick: setFilterState,
                  elasticPath: path,
                  enqueueSnackbar: enqueueSnackbar,
                })
              }
            >
              Field Exists <StarIcon style={{ verticalAlign: 'middle' }} />
            </Button>
          </div>
        : null }
    </>
  );
};

export default ActionsContainer;
