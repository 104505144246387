import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { UserConsumer, UserContext } from '../../DBAuthenticator.js';

import styled, { ThemeContext, css, keyframes } from 'styled-components';
import { useClipboard } from 'use-clipboard-copy';
import { Formik } from 'formik';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import HelpToolTipButton from '../../darkblue-ui/Button/HelpToolTipButton';
import Button from '../../darkblue-ui/Button/Button';
import BluestoneLogoComponent from '../../darkblue-ui/BluestoneLogoComponent/BluestoneLogoComponent';
import TextArea from '../../darkblue-ui/Inputs/TextArea';
import Loader from '../../darkblue-ui/Spinners/Loader';
import useEnCodeTimeUri from '../../utils/Hooks/UseEnCodeTimeUri';
import { downloadAsPDF } from '../../utils/Functions/utils';
import Alert from '../../darkblue-ui/Alert/Alert';

/**
 * @Styled Component
 * component styling Section
 */
const CardBody = styled.p`
  word-break: break-all;
`;

const PgpModuleContainer = styled(GridContainer)`
  &&& {
    margin-top: 50px;
    margin-bottom: 7% !important;
  }
`;
const ExpandedCardRow = styled(GridContainer)`
  width: 100% !important;
  margin: 4px 8px !important;
`;

const ExpandedCardContainer = styled(GridContainer)`
  &&& {
    width: 100% !important;
    padding: 6px;
  }
`;

const TabPanelContainer = styled.div`
  display: ${(props) => (props.hidden ? 'none ' : null)};
  background-color: ${(props) => props.theme.colors.cardBackground};
`;

const SpanCardBody = styled.span`
  font-size: 14px;
  word-break: break-all;
`;

const TextAreaInput = styled(TextArea)`
  margin-top: 5px;
  width: 100% !important;
  background-color: ${(props) => props.theme.colors.fog};
  height: 344px;
  resize: none;
  padding: 10px;
  padding-top: 12px
  font-size: 13px;
`;

const TabsContainer = styled.div`
  background-color: #e6ecf2;

  span.MuiTabs-indicator {
    background-color: #025cc2;
    display: ${(props) => (props.value !== 2 ? '' : 'none')};
  }
`;

const StyledTab = styled(Tab)`
  span {
    color: ${(props) => (props.disabled ? 'grey' : 'black')};
  }
`;

const IconButtonTab = styled(Tab)`
  position: fixed;
`;

const HelpIconButtonTab = styled(Tab)`
  position: fixed;
`;

const ThemedAddIcon = styled(AddIcon)`
  color: ${(props) => props.theme.colors.primary} !important;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const ThemedFileCopyIcon = styled(FileCopyIcon)`
  color: ${(props) => props.theme.colors.primary} !important;
  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const ThemedFilterAddIconIcon = styled(AddIcon)`
  color: ${(props) => props.theme.colors.primary} !important;
  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const ThemedGetAppIcon = styled(GetAppIcon)`
  color: ${(props) => props.theme.colors.primary} !important;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const slideIn = () => keyframes`
  0% {
    transform: translateX(160px);
  }
  100% {
    transform: translateX(0px);
  }
`;

const slideOut = () => keyframes`
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(160px);
  }
`;

const DivIndicator = styled.div`
    position: relative;
    width: 160px;
    height: 2px;
    background-color: rgb(2, 92, 194);
    transform: ${(props) =>
      props.tabValue === 0 ? 'translateX(0px)' : 'translateX(160px);'} 
    border-radius:0px;

    ${(props) => {
      if (props.isFormSubmitted) {
        return props.tabValue === 0
          ? css`
              &.shift-left {
                animation: ${(props) => slideIn()} 300ms ease-in-out;
                -webkit-animation: ${(props) => slideIn()} 300ms ease-in-out;
              }
            `
          : css`
            &.shift-right {
              animation: ${(props) => slideOut()} 300ms ease-out;
              -webkit-animation: ${(props) => slideOut()} 300ms ease-out;
            }
            }
          `;
      }
    }}
  `;

/**
 * @function
 * Determines whether or not to show theme icons.
 * @param  {string} name key names to be checked.
 */
const showCardAcctions = (name) => {
  if (name === 'emails' || name === 'fingerprints' || name === 'names') {
    return true;
  }
};

/**
 * @Component RenderForm
 * Renders: A PGP key entry form.
 * @param  {obj} props
 *  setResponseData - Sets the response data from API call.
 *  setValue - Handles the tab switch after a successful data response.
 */
const RenderForm = (props) => {
  const { setResponseData, setValue, setIsFormSubmitted } = props;
  const [isFormSubmissionSuccess, setIsFormSubmissionSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (isFormSubmissionSuccess) {
      setValue(1);
      setIsFormSubmitted(isFormSubmissionSuccess);
    }
  }, [isFormSubmissionSuccess]);

  return (
    <UserConsumer>
      {(userSess) => {
        return (
          <GridContainer>
            <Formik
              initialValues={{
                PGPKey: '',
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  if (_.isEqual(values.PGPKey, '')) {
                    setShowError(true);
                    setErrorText('Please Enter PGP Key');
                    return;
                  }

                  let user = await userSess.auth.currentAuthenticatedUser();
                  const jwt = user.signInUserSession.idToken.jwtToken;
                  const key = {
                    pgpKey: values.PGPKey,
                  };
                  let resp = await fetch(process.env.REACT_APP_PGP_ENDPOINT, {
                    method: 'POST',
                    headers: {
                      Authorization: jwt,
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(key),
                  });
                  const data = await resp.json();

                  if (_.isEqual(data.body, 'null') || _.isEqual(data, {})) {
                    setShowError(true);
                    setErrorText('PGP Key not found');
                    return;
                  }

                  const parsedData = JSON.parse(data.body);
                  const destructedData = {
                    ...parsedData,
                    ...(parsedData.hasOwnProperty('identities') && {
                      ...parsedData.identities,
                    }),
                  };
                  setResponseData(destructedData);
                  setIsFormSubmissionSuccess(true);
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {({ values, handleChange, handleSubmit, isSubmitting }) => {
                return isSubmitting ? (
                  <GridContainer justify="center" alignItems="center">
                    <Loader color="#2c6982" />
                  </GridContainer>
                ) : (
                  <GridContainer
                    style={{
                      marginTop: '5px',
                      marginBottom: '20px',
                    }}
                    justify="center"
                    alignItems="center"
                  >
                    <GridItem xs={11}>
                      <form
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        style={{ marginBottom: '20px' }}
                      >
                        <div
                          style={{
                            marginBottom: '20px',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                          }}
                          direction="column"
                        >
                          <GridItem xs={12}>
                            <p style={{ fontWeight: 900 }}>Paste PGP Key:</p>
                            <TextAreaInput
                              label="PGP Public Key"
                              placeHolder="----BEGIN PGP PUBLIC KEY BLOCK-----
                        xo0EX2o6ugEEANiYct+QzJ0k5SXygmzUYDf7iv2Gst81DKcY+3hBD6F9sHPmgK27
                        TNOPThStq7tSa7AxgJO6gJJh+hALp6ddJcqLB3Rh8gsnjqoPOPTI7jfPoKvl6hlr
                        vtXCGkDKqageiewr/6+xR4h3RFWYRhCC/jvqxb+QIOW4l6j8QWE5Al6BABEBAAHN
                        G0FsbGFuIChhYXMpIDxhc2Fhc0BhYWEuY29tPsKtBBMBCgAXBQJfajq6AhsvAwsJ
                        BwMVCggCHgECF4AACgkQpmDXZFXbcY9ckQP7BAugFmbVhERfFyrW7KLirWzaKu7T
                        iV4vT5jCIl4Y34/D8LuR+AzES5KZl7plMV85UIizFBuY01v5WNFaADHUJFuuh341
                        z3JIBPqOiZrlr5USsxav6ze0BAbKFzp7CLGV6DZqB6cmz7mPxtxnGAbOpQqFNHKw
                        sSZlS65fS9CYQ9fOjQRfajq6AQQA75RFNm1mUadvMGI1STLE+PSfD+RLdbxc/1Jf
                        tHv57RiP+fj+8r+HEkDdLnm2CrinDy9dRDzZAa0s0fWgh1/jWTaakLZJMnWjTNay
                        iNx8y6rnOtlmxa3bLcKopgjXA851QBo+0nuKi/tBoadYhoyIW93k8WY70PFPVk2d
                        ifqtR50AEQEAAcLAgwQYAQoADwUCX2o6ugUJDwmcAAIbLgCoCRCmYNdkVdtxj50g
                        BBkBCgAGBQJfajq6AAoJEMW5tTFUQGwBf1YD/13Y5zzijMqS/cT5xsCcTyUuz8eS
                        8HLntfa2mI8CwmfuRln6Fw08ZQ2HBo3Ydpw06gKSM406df+7VI3azvY8JMNwKQoP
                        -----END PGP PUBLIC KEY BLOCK-----"
                              expanded={props.expanded}
                              inputProps={{
                                value: values.PGPKey,
                                type: 'text',
                                onChange: handleChange,
                                name: 'PGPKey',
                              }}
                            />
                          </GridItem>
                          <GridItem>
                            <Button style={{ marginTop: '20px', padding: "12px 30px" }} type="submit">
                              Submit
                            </Button>
                            {showError && (
                              <GridItem>
                                <div style={{ marginTop: '20px' }}>
                                  <Alert severity="danger" iconType="danger">
                                    {errorText}
                                  </Alert>
                                </div>
                              </GridItem>
                            )}
                          </GridItem>
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                );
              }}
            </Formik>
          </GridContainer>
        );
      }}
    </UserConsumer>
  );
};

/**
 * @Component PGPResult
 * Renders: Renders PGP parsed data.
 * @param  {obj} props
 *  responseData - The response data from entry form.
 */
const PGPResult = (props) => {
  const { index, responseData } = props;

  const clipboard = useClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return (
    <GridContainer alignItems="center">
      <GridItem xs={12}>
        <ExpandedCardContainer>
          {Object.entries(responseData)
            .filter((val) => !_.isEqual(val[1], ['']))
            .map((dataItem, index) => {
              return (
                <ExpandedCardRow key={`${dataItem}_${index}`}>
                  <GridItem xs={2}>
                    {Array.isArray(dataItem[1]) && dataItem[1].length ? (
                      <CardBody
                        key={index}
                        className="no-margin card"
                        style={{ fontWeight: 500 }}
                      >
                        {String(dataItem[0])}
                      </CardBody>
                    ) : null}
                  </GridItem>
                  <GridItem xs={10}>
                    <SpanCardBody component={'span'} className="no-margin card">
                      {Array.isArray(dataItem[1]) && dataItem[1].length > 0
                        ? dataItem[1].map((value, index) => {
                            return (
                              <React.Fragment key={`spancard_${index}`}>
                                <span>
                                  {typeof value === 'string'
                                    ? value
                                    : String(value)}
                                </span>

                                {showCardAcctions(dataItem[0]) && (
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      position: 'absolute',
                                      marginLeft: '14px',
                                    }}
                                  >
                                    {dataItem[0] === 'fingerprints' && (
                                      <span style={{ paddingRight: '5px' }}>
                                        <ThemedFilterAddIconIcon
                                          onClick={() => {
                                            const encodedTag = encodeURI(
                                              `"${dataItem[1][0]}"`,
                                            );
                                            const path = `/search/data?PgpFingerPrintsFilter=%7B%22value%22%3A${encodedTag}%7D`;
                                            history.push(path);
                                          }}
                                        />
                                      </span>
                                    )}

                                    {dataItem[0] !== 'fingerprints' && (
                                      <span style={{ paddingRight: '5px' }}>
                                        <ThemedAddIcon
                                          onClick={() => {
                                            const path = `/search/data?Searchbar=%7B%22value%22%3A%22${
                                              dataItem[1][0]
                                            }%22%7D`;
                                            history.push(path);
                                          }}
                                        />
                                      </span>
                                    )}

                                    <span>
                                      <ThemedFileCopyIcon
                                        onClick={(e) => {
                                          clipboard.copy(dataItem[1][0]);

                                          enqueueSnackbar(
                                            'Successfully Copied!',
                                            { variant: 'success' },
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })
                        : null}
                    </SpanCardBody>
                  </GridItem>
                </ExpandedCardRow>
              );
            })}
        </ExpandedCardContainer>
      </GridItem>
    </GridContainer>
  );
};

/**
 * @Component TabPanel
 * Renders: Renders the tab panel.
 * @param  {obj} props
 *  children - The element to be rendered.
 *  value -  Tab value number.
 *  index -  Tab index identifier.
 */
const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <TabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`module-tabpanel-${index}`}
      aria-labelledby={`module-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </TabPanelContainer>
  );
};

/**
 * @Component TabPanel
 * Renders: Renders the PGP entry form & result list.
 * @param  {obj} props
 */
const PGPModule = (props) => {
  const { index, logo } = props;
  const [responseData, setResponseData] = useState({});
  const [value, setValue] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const themeContext = useContext(ThemeContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * @function styledtabsIdSet
   * Returns: A object with id property.
   * @param  {int} index a unique Identifier.
   */
  const styledtabsIdSet = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `module-tabpanel-${index}`,
    };
  };

  const xsNumber = _.isEqual(responseData, {}) || value === 0 ? 5 : 9;

  return (
    <>
      <PgpModuleContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={xsNumber}>
          <Paper elevation={2}>
            <TabsContainer>
              <StyledTab
                value={0}
                onClick={(e) => {
                  setValue(0);
                }}
                label="Parse Pgpkey"
                {...styledtabsIdSet(0)}
              />
              <StyledTab
                disabled={_.isEqual(responseData, {}) || responseData === null}
                value={1}
                onClick={(e) => {
                  setValue(1);
                }}
                label="Results"
                {...styledtabsIdSet(1)}
              />
              {value != 0 ? (
                <IconButtonTab
                  style={{ float: 'right' }}
                  value={2}
                  component="div"
                  selected={false}
                  disableFocusRipple={true}
                  disableRipple={true}
                  onClick={(e) => setValue(1)}
                  icon={
                    <ThemedGetAppIcon
                      onClick={() =>
                        downloadAsPDF(
                          'module-tabpanel-1',
                          'DarkBlue PGP Module',
                          themeContext.colors.mainBackground,
                        )
                      }
                    />
                  }
                />
              ) : (
                <HelpIconButtonTab
                  value={3}
                  style={{ float: 'right' }}
                  component="div"
                  selected={false}
                  disableFocusRipple={true}
                  disableRipple={true}
                  onClick={(e) => setValue(0)}
                  label={
                    <div>
                      <HelpToolTipButton
                        size="medium"
                        tipPlacement="top"
                        text={`Pretty Good Privacy (PGP) is an encryption program used for signing, encrypting, and decrypting text such as emails and files. 
                        Paste a PGP public key and submit to see parsed information such as user identities, the key fingerprint, and the creation date.`}
                      />
                    </div>
                  }
                />
              )}

              <DivIndicator
                className={value == 0 ? 'shift-left' : 'shift-right'}
                tabValue={value}
                isFormSubmitted={isFormSubmitted}
              ></DivIndicator>
            </TabsContainer>
            <TabPanel value={value} index={0}>
              <RenderForm
                responseData={responseData}
                setResponseData={setResponseData}
                setValue={setValue}
                setIsFormSubmitted={setIsFormSubmitted}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PGPResult index={index} responseData={responseData} />
              <div style={{ marginTop: '8%' }}>
                <BluestoneLogoComponent logo={logo} />
              </div>
            </TabPanel>
          </Paper>
        </GridItem>
      </PgpModuleContainer>
      {value === 0 && <BluestoneLogoComponent logo={logo} />}
    </>
  );
};

export default PGPModule;
