import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { StateContext } from '../StateProviderContext/StateProviderContext';
import { fetchData } from '../utils';
import bodybuilder from 'bodybuilder';
import Input from '../../Inputs/Input';

const StyledCheckboxGroup = styled.div`
  & > * {
    padding: 8px 8px;
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.active ? props.theme.colors.primary : props.theme.colors.cloud};
  border-radius: 0px;
  transition: all 100ms;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.cloud};

  ${CheckboxContainer}:hover {
    border: 1px solid ${(props) => props.theme.colors.altPrimary};
  }

  ${(props) =>
    props.sidebar &&
    css`
      width: 26px;
      height: 26px;
    `}
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const Label = styled.p`
  margin-bottom: 4px;
  margin-top: 4px;
`;

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${(props) =>
    props.sidebar &&
    css`
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 8px;
    `}
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  max-width: fit-content;
  text-transform: capitalize;
  div:first-child {
    padding-right: 4px !important;
  }

  &:hover {
    cursor: pointer;
  }
`;

const KeepTitle = styled.p`
  font-weight: bold;
`;

const StyledInput = styled.input`
  border: 0px;
  background-color: ${(props) => props.theme.colors.fog};
`;

const CategoryTitle = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

const CheckboxSelect = ({ active, className, sidebar, ...rest }) => {
  return (
    <CheckboxContainer {...rest} className={className}>
      <HiddenCheckbox active={active} {...rest} />
      <StyledCheckbox sidebar={sidebar} active={active}>
        <Icon active={active} viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

const OtherInput = ({ placeholder, inputProps = {} }) => {
  return <StyledInput {...inputProps} placeholder={placeholder} />;
};

const handleUpdateState = ({
  setFilterState,
  filterState,
  filterName,
  value,
  query,
  elasticPath,
}) => {
  if (value.length > 0) {
    setFilterState({
      ...filterState,
      ...{
        [filterName]: {
          value,
          query,
          elasticPath,
        },
      },
    });
  } else {
    const { [filterName]: temp, ...newFilterState } = filterState;
    setFilterState(newFilterState);
  }
};

const SelectFilter = (props) => {
  const {
    elasticPath,
    filterName,
    defaultOptions = [],
    query,
    setQuery,
    index,
    apiKey,
  } = props;
  const [otherValue, setOtherValue] = useState('');
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);

  const { filterState, setFilterState, queryState, body, setBody } = useContext(
    StateContext,
  );

  const isActive = (option) => {
    if (!String(option).length) {
      return false;
    }

    const activeCheckboxes = filterState[filterName]?.value.filter((v) => {
      return v === option;
    });

    if (activeCheckboxes && activeCheckboxes.length) {
      return true;
    } else {
      return false;
    }
  };

  const multiDropdownAgg = (body, elasticPath) => {
    elasticPath.forEach((path) => {
      body.aggregation('terms', path);
    });
  };

  const handleOptionsToggle = (option) => {
    if (value?.length && value.includes(option)) {
      const newValue = value.filter((o) => o !== option);
      setValue(newValue);
      return newValue;
    } else if (Array.isArray(value)) {
      setValue([...value, option]);
      return [...value, option];
    }
  };

  useEffect(() => {
    let canceled = false;
    let localBody = bodybuilder();
    multiDropdownAgg(localBody, elasticPath);

    const request = fetchData({
      canceled: canceled,
      query: localBody.build(),
      apiKey,
      index,
    });

    request.then((data) => {
      const dropdownOptions = _.get(
        data.aggregations,
        `agg_terms_${elasticPath}`,
      ).buckets.map((bucket) => bucket.key);
      const options = [...new Set([...defaultOptions, ...dropdownOptions])];
      setOptions(options);
    });

    return () => request?.cancel();
  }, []);

  useEffect(() => {
    setValue(filterState[filterName]?.value || []);
  }, [filterState]);

  return (
    <>
      <CategoryTitle>{props.filterLabel}</CategoryTitle>
      <ItemContainer>
        {options.map((o) => {
          return (
            <Item
              onClick={(e) => {
                e.preventDefault();
                const value = handleOptionsToggle(o);
                handleUpdateState({
                  setFilterState,
                  filterState,
                  filterName,
                  value,
                  query,
                  elasticPath,
                });
              }}
              key={o}
            >
              <CheckboxSelect active={isActive(o)} />
              <Label>{o}</Label>
            </Item>
          );
        })}

        {value
          .filter((v) => !options.includes(v))
          .map((val) => {
            return (
              <Item key={`${filterName}: ${val}`} style={{ marginTop: '3px' }}>
                <CheckboxSelect
                  onClick={(e) => {
                    e.preventDefault();
                    const value = handleOptionsToggle(val);
                    handleUpdateState({
                      setFilterState,
                      filterState,
                      filterName,
                      value,
                      query,
                      elasticPath,
                    });
                  }}
                  active={isActive(val)}
                />
                <Label>{val}</Label>
              </Item>
            );
          })}
        <Item style={{ marginTop: '3px' }}>
          <CheckboxSelect
            onClick={(e) => {
              e.preventDefault();
              if (otherValue.length) {
                const value = handleOptionsToggle(otherValue);
                handleUpdateState({
                  setFilterState,
                  filterState,
                  filterName,
                  value,
                  query,
                  elasticPath,
                });
                setOtherValue('');
              }
            }}
            active={isActive(otherValue)}
          />
          <OtherInput
            inputProps={{
              onKeyPress: (e) => {
                if (e.key === 'Enter' && otherValue.length) {
                  const value = handleOptionsToggle(otherValue);
                  handleUpdateState({
                    setFilterState,
                    filterState,
                    filterName,
                    value,
                    query,
                    elasticPath,
                  });
                  setOtherValue('');
                }
              },
              onChange: (e) => setOtherValue(e.target.value),
              value: otherValue,
            }}
            button
            placeholder="Other..."
            type="text"
          />
        </Item>
      </ItemContainer>
    </>
  );
};

export default SelectFilter;
