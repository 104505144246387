import dayjs from 'dayjs';
import { dateTimeFormatter } from '../../filters/utils';

const onlineQuery = (props) => {
  let query = {
    size: 0,
    aggs: {
      dateHist: {
        date_histogram: {
          field: 'timestamp',
          calendar_interval: 'day',
        },
        aggs: {
          newSites: {
            terms: {
              field: 'ai.site.firstSnapshot',
              size: 2,
            },
          },
        },
      },
    },
  };

  return query;
};

const booleanQuery = (term, props) => {
  let query = { query: { bool: { must: { term: {} } } } };
  if ((props && props.length > 1) || (props && props.length === 0)) {
    query = { query: { query_string: { query: '*' } } };
  } else if (props && props.length === 1 && term) {
    query.query.bool.must.term[term] = Boolean(parseInt(props[0]));
  }
  return query;
};

const altBooleanQuery = (term, value) => {
  let query = { query: { bool: { must: { term: {} } } } };
  query.query.bool.must.term[term] = Boolean(value);
  if (value) {
    return query;
  } else {
    return null;
  }
};

const sitesDateQuery = (props) => {
  let query = {
    query: {
      bool: {
        must: [
          {
            term: {
              online: true,
            },
          },
          {
            range: {
              timestamp: {
                gte: dateTimeFormatter(props.start),
                lte: dateTimeFormatter(props.end),
              },
            },
          },
        ],
      },
    },
  };

  return query;
};

const relativeDatesQuery = (props) => {
  let query = {
    query: {
      range: {
        timestamp: {
          gte: 'now-' + props.range,
        },
      },
    },
  };
  return query;
};

const dashboardDatesQuery = (props) => {
  const today = new Date();
  let lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7);
  let query = {};
  if (props && props.start && props.end) {
    query = {
      query: {
        range: {
          timestamp: {
            gte: dateTimeFormatter(props.start),
            lte: dateTimeFormatter(props.end, 'end'),
          },
        },
      },
    };
  } else {
    query = {
      query: {
        range: {
          timestamp: {
            gte: dateTimeFormatter(
              dayjs()
                .subtract(7, 'days')
                .format('YYYY-MM-DD'),
            ),
            lte: dateTimeFormatter(dayjs().format('YYYY-MM-DD'), 'end'),
          },
        },
      },
    };
  }
  return query;
};

const generalDatesQuery = (start, end) => {
  let query = {};
  if (start && end) {
    query = {
      query: {
        range: {
          timestamp: {
            gte: start,
            lte: end,
            format: 'yyyy-MM-dd HH:mm:ss',
          },
        },
      },
    };
  } else {
    query = {
      query: {
        range: {
          timestamp: {
            gte: dayjs()
              .subtract(7, 'days')
              .hour(0)
              .minute(0)
              .second(0),
            lte: dayjs()
              .hour(0)
              .minute(0)
              .second(0),
            format: 'yyyy-MM-dd HH:mm:ss',
          },
        },
      },
    };
  }

  return query;
};

const aiFieldQuery = () => {
  let query = {
    size: 0,
    aggs: {
      fieldContent: {
        terms: { field: 'ai.content.tags.keyword' },
      },
    },
  };

  return query;
};

const uniqueItemsByMarket = () => {
  let query = {
    aggs: {
      type_count: {
        terms: {
          field: 'site.name.keyword',
        },
        aggs: {
          products: {
            cardinality: {
              field: 'productId.keyword',
            },
          },
        },
      },
    },
  };
  return query;
};

/**
 * A query to retrieve record doc type data.
 */
const savedRecords = () => {
  let query = {
    query: {
      bool: {
        must: {
          term: {
            'doc.type': 'record',
          },
        },
      },
    },
    sort: [
      { timestamp: { order: 'desc' } },
      // NOTE: these are not working and I don't know why; they cause 0 results to be returned
      // { "dataset" : {"order" : "asc"}},
      // { "name" : {"order" : "asc"}},
    ],
  };
  return query;
};

/**
 * A query to retrieve search doc type data.
 */
const savedSearches = () => {
  let query = {
    sort: [
      {
        'alert.hits': {
          order: 'desc',
        },
      },
      {
        timestamp: {
          order: 'desc',
        },
      },
    ],
    query: {
      bool: {
        must: {
          term: {
            'doc.type': 'search',
          },
        },
      },
    },
  };
  return query;
};

const uniqueItemsByNetByTime = () => {
  let query = {
    size: 0,
    aggs: {
      markets: {
        terms: {
          field: 'site.net.keyword',
        },
        aggs: {
          products: {
            cardinality: {
              field: 'productId.keyword',
            },
          },
          aggs: {
            date_histogram: {
              field: 'timestamp',
              calendar_interval: 'day',
            },
          },
        },
      },
    },
  };

  return query;
};

const uniqueItemsByMarketByTime = () => {
  let query = {
    aggs: {
      markets: {
        terms: {
          field: 'site.name.keyword',
          order: {
            1: 'desc',
          },
          size: 5,
        },
        aggs: {
          1: {
            cardinality: {
              field: 'productId.keyword',
            },
          },
          prod_type: {
            terms: {
              field: 'ai.content.tags.keyword',
              order: {
                1: 'desc',
              },
              missing: '__missing__',
              size: 10,
            },
            aggs: {
              1: {
                cardinality: {
                  field: 'productId.keyword',
                },
              },
            },
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        filter: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    'doc.type.keyword': 'review',
                  },
                },
                {
                  match_phrase: {
                    'doc.type.keyword': 'product',
                  },
                },
                {
                  match_phrase: {
                    'doc.type.keyword': 'vendor',
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
      },
    },
  };

  return query;
};

const aiContentByMarket = () => {
  let query = {
    size: 0,
    aggs: {
      markets: {
        terms: {
          field: 'site.name.keyword',
        },
        aggs: {
          content: {
            terms: {
              field: 'ai.content.tags.keyword',
            },
          },
        },
      },
    },
  };

  return query;
};

const uniqueReviewsByMarket = () => {
  let query = {
    aggs: {
      markets: {
        terms: {
          field: 'site.name.keyword',
          order: {
            sort_field: 'desc',
          },
          missing: '__missing__',
          size: 5,
        },
        aggs: {
          date_agg: {
            date_histogram: {
              field: 'timestamp',
              calendar_interval: '1d',
              time_zone: 'America/New_York',
              min_doc_count: 1,
            },
            aggs: {
              max_reviews: {
                max: {
                  field: 'review_count',
                  missing: 0,
                },
              },
            },
          },
          sort_field: {
            sum: {
              field: 'review_count',
            },
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        filter: {
          bool: {
            filter: [
              {
                match_phrase: {
                  _index: {
                    query: 'market_stats',
                  },
                },
              },
            ],
          },
        },
      },
    },
  };
  return query;
};

const mostReviewedVendor = () => {
  let query = {
    size: 0,
    aggs: {
      vendors: {
        terms: {
          field: 'vendorId.keyword',
          order: {
            review_sort: 'desc',
          },
          size: 10,
        },
        aggs: {
          review_sort: {
            cardinality: {
              field: 'reviewId.keyword',
            },
          },
          site: {
            terms: {
              field: 'site.name.keyword',
              order: {
                review_count: 'desc',
              },
              size: 10,
            },
            aggs: {
              review_count: {
                cardinality: {
                  field: 'reviewId.keyword',
                },
              },
            },
          },
        },
      },
    },
    _source: {
      excludes: [],
    },
    stored_fields: ['*'],
    script_fields: {
      pricePerGram: {
        script: {
          source:
            "if (doc['usdPrice'].size()!=0 && doc['grams'].size()!=0){ doc['usdPrice'].value / doc['grams'].value} ",
          lang: 'painless',
        },
      },
    },
    // docvalue_fields: [
    //   {
    //     field: "doc.processedTime",
    //     format: "date_time"
    //   },
    //   {
    //     field: "doc.scraped",
    //     format: "date_time"
    //   },
    //   {
    //     field: "lastModified",
    //     format: "date_time"
    //   },
    //   {
    //     field: "timestamp",
    //     format: "date_time"
    //   }
    // ],
    query: {
      bool: {
        must: [
          {
            match_all: {},
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
  return query;
};

const productCount30DayTrend = () => {
  let query = {
    aggs: {
      markets: {
        terms: {
          field: 'site.name.keyword',
          size: 5,
          order: {
            max_product_count: 'desc',
          },
          missing: '__missing__',
        },
        aggs: {
          max_product_count: {
            max: {
              field: 'product_count',
            },
          },
          date_agg: {
            date_histogram: {
              field: 'timestamp',
              fixed_interval: '1d',
              time_zone: 'America/New_York',
              min_doc_count: 1,
            },
            aggs: {
              max_product_count: {
                max: {
                  field: 'product_count',
                  missing: 0,
                },
              },
            },
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        filter: [
          {
            match_phrase: {
              _index: {
                query: 'market_stats',
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
  return query;
};

const notQuery = (termsList) => {
  const safeTermList = termsList || [];

  let terms = [];
  for (let i = 0; i < termsList.length; i = i + 2) {
    terms.push({ term: { [termsList[i]]: termsList[i + 1] } });
  }

  let query = {
    query: {
      bool: {
        must_not: terms,
      },
    },
  };

  return query;
};

const bulkSearchQuery = (searchValue, termsList) => {
  const safeTermList = termsList || [];

  const query = {
    query: {
      bool: {
        must: {
          simple_query_string: {
            query: searchValue,
            fields: [
              ...safeTermList.map((t) => {
                return t + '.keyword';
              }),
            ],
            default_operator: 'or',
          },
        },
      },
    },
  };

  if (searchValue) {
    return query;
  } else {
    return null;
  }
};

const threadQuery = (threadId, siteId, board, userId, channelId, siteNet) => {
  const removeMark = new RegExp('(<mark>|</mark>)', 'g');
  const cleanThreadId = String(threadId).replace(removeMark, '');
  const cleanSiteId = String(siteId).replace(removeMark, '');
  const cleanBoard = String(board).replace(removeMark, '');
  const cleanChannelId = String(channelId).replace(removeMark, '');
  const cleanSiteNet = String(siteNet).replace(removeMark, '');
  const idObject = cleanThreadId
    ? { term: { threadId: cleanThreadId } }
    : channelId
    ? { term: { channelId: cleanChannelId } }
    : null;

  const userObject = userId
    ? { field: 'userId.keyword' }
    : { field: 'author.keyword' };
  const siteIdObject = siteId
    ? { term: { 'site.id.keyword': cleanSiteId } }
    : null;
  const boardObject = board ? { term: { 'board.keyword': cleanBoard } } : null;
  const siteNetObject = siteNet
    ? { term: { 'site.net.keyword': cleanSiteNet } }
    : null;

  const query = {
    sort: [{ timestamp: { order: 'asc' } }],
    size: 10000,
    query: {
      bool: {
        must: [idObject, siteIdObject, boardObject, siteNetObject].filter(
          (item) => item,
        ),
      },
    },
    aggs: {
      postHist: {
        auto_date_histogram: {
          field: 'timestamp',
          buckets: 10,
        },
      },
      fieldContent: {
        terms: userObject,
      },
    },
    // aggs: {
    //   postHist: {
    //     date_histogram: {
    //       field: 'timestamp',
    //       calendar_interval: 'minute',
    //     },
    //   },
    //   fieldContent: {
    //     terms: userObject,
    //   },
    // },
  };
  return query;
};

const databaseIndiciesQuery = () => {
  const query = { size: 0, aggs: { indices: { terms: { field: '_index' } } } };
  return query;
};

const databaseQuery = (page) => {
  const query = {
    size: 50,
    from: page * 1000,
    query: {
      match_all: {},
    },
  };
  return query;
};

const scansQuery = (page) => {
  const query = {
    size: 50,
    from: page * 1000,
    sort: [{ timestamp: { order: 'desc' } }],
    query: {
      match_all: {},
    },
  };
  return query;
};

const searchQuery = ({ term = '', options = { fields: ['post'] } }) => {
  const highlightFields = options.fields.map((field) => {
    return [field, {}];
  });

  const query = {
    simple_query_string: {
      query: '"cocaine',
      fields: ['post'],
      default_operator: 'and',
    },
  };

  return query;
};

const userPreviewQuery = (author = '', userId = '', siteId) => {
  const query = {
    size: 10000,

    query: {
      bool: {
        must: [
          {
            bool: {
              should: [
                {
                  term: {
                    'userId.keyword': userId,
                  },
                },
                {
                  term: {
                    'author.keyword': author,
                  },
                },
              ],
            },
          },
          {
            term: {
              'site.id.keyword': siteId,
            },
          },
        ],
      },
    },
  };
  return query;
};

const responseStatusQuery = (responseStatus = []) => {
  const statuses = responseStatus.map((status) => {
    return { term: { responseStatus: status } };
  });
  const query = {
    size: 1000,
    query: {
      bool: {
        minimum_should_match: 1,
        should: [
          ...statuses,
          {
            bool: {
              must_not: [
                {
                  exists: {
                    field: 'responseStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  };

  return query;
};

const dataSnapshotQuery = (props) => {
  const query = {
    aggs: {
      keywords: {
        terms: {
          field: 'ai.content.tags.keyword',
          order: {
            services: 'desc',
          },
          size: 10,
        },
        aggs: {
          services: {
            cardinality: {
              field: 'hiddenService.keyword',
            },
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              timestamp: {
                gte: 'now-30d',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
  };

  return query;
};

const trendingTermsQuery = (props) => {
  const query = {
    size: 0,
    query: {
      range: {
        timestamp: {
          gte: 'now-5d',
          lte: 'now',
        },
      },
    },
    aggs: {
      date_agg: {
        date_histogram: {
          field: 'timestamp',
          interval: 'day',
          order: {
            _key: 'desc',
          },
        },
        aggs: {
          entity: {
            terms: {
              field: 'entity.keyword',
              size: 5,
              order: {
                trendWeight: 'desc',
              },
            },
            aggs: {
              site: {
                terms: {
                  field: 'site.keyword',
                  size: 5,
                },
              },
              trendWeight: {
                max: {
                  field: 'trending',
                },
              },
            },
          },
        },
      },
    },
  };

  return query;
};

const dataStreamQuery = (props) => {
  const query = {
    aggs: {
      sources: {
        terms: {
          field: 'site.name.keyword',
          order: {
            scrape: 'desc',
          },
          size: 5000,
        },
        aggs: {
          scrape: {
            min: {
              field: 'doc.scraped',
            },
          },
          siteNet: {
            terms: {
              field: 'site.net.keyword',
              order: {
                scrape: 'desc',
              },
              size: 5,
            },
            aggs: {
              scrape: {
                min: {
                  field: 'doc.scraped',
                },
              },
              index: {
                terms: {
                  field: '_index',
                  order: {
                    scrape: 'desc',
                  },
                  size: 5,
                },
                aggs: {
                  scrape: {
                    min: {
                      field: 'doc.scraped',
                    },
                  },
                  language: {
                    terms: {
                      field: 'ai.language.keyword',
                      order: {
                        _count: 'desc',
                      },
                      size: 1,
                    },
                    aggs: {
                      scrape: {
                        min: {
                          field: 'doc.scraped',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              timestamp: {
                gte: 'now-90d',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
  };

  return query;
};

const recordCountQuery = () => {
  const query = {
    aggs: {
      dates: {
        date_histogram: {
          field: 'timestamp',
          calendar_interval: '1M',
          time_zone: 'America/New_York',
          min_doc_count: 1,
        },
        aggs: {
          '1': {
            cardinality: {
              field: 'hiddenService.keyword',
            },
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              _index: {
                value: 'sites',
              },
            },
          },
        ],
        filter: [
          {
            match_phrase: {
              responseStatus: {
                query: '200',
              },
            },
          },
          {
            range: {
              timestamp: {
                format: 'strict_date_optional_time',
                gte: 'now-7M',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
  };

  return query;
};

const trendingSitesQuery = (props) => {
  const query = {
    aggs: {
      date_agg: {
        date_histogram: {
          field: 'timestamp',
          interval: 'day',
          order: {
            _key: 'desc',
          },
        },
        aggs: {
          sites: {
            terms: {
              field: 'site.name.keyword',
              order: {
                _count: 'desc',
              },
              size: 5,
            },
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        filter: [
          {
            match_phrase: {
              _index: {
                query: 'communications',
              },
            },
          },
          {
            range: {
              timestamp: {
                format: 'strict_date_optional_time',
                gte: 'now-1d',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
  };

  return query;
};

const requiredFieldsQuery = (values) => {
  const fields = values.map((value) => {
    return {
      exists: {
        field: value,
      },
    };
  });

  const query = {
    query: {
      bool: {
        must: fields,
      },
    },
  };

  return query;
};

const topOpiateVendors = (props) => {
  const query = {
    size: 0,
    aggs: {
      vendors: {
        terms: {
          field: 'vendorId.keyword',
          order: {
            1: 'desc',
          },
          size: 10,
        },
        aggs: {
          1: {
            max: {
              field: 'ai.stats.severity.totalOpiates',
            },
          },
          sites: {
            terms: {
              field: 'site.name.keyword',
              order: {
                1: 'desc',
              },
              size: 5,
            },
            aggs: {
              1: {
                max: {
                  field: 'ai.stats.severity.totalOpiates',
                },
              },
            },
          },
        },
      },
    },
  };
  return query;
};

const topMethVendors = (props) => {
  const query = {
    aggs: {
      vendors: {
        terms: {
          field: 'vendorId.keyword',
          order: {
            1: 'desc',
          },
          size: 10,
        },
        aggs: {
          1: {
            max: {
              field: 'ai.stats.severity.totalMethamphetamines',
            },
          },
          sites: {
            terms: {
              field: 'site.name.keyword',
              order: {
                1: 'desc',
              },
              size: 5,
            },
            aggs: {
              1: {
                max: {
                  field: 'ai.stats.severity.totalMethamphetamines',
                },
              },
            },
          },
        },
      },
    },
    size: 0,
  };
  return query;
};

const vendorRiskScore = (props) => {
  const query = {
    size: 0,
    aggs: {
      sites: {
        terms: {
          field: 'site.name.keyword',
          order: {
            vendors: 'desc',
          },
          size: 10,
        },
        aggs: {
          vendors: {
            cardinality: {
              field: 'vendorId.keyword',
            },
          },
          risk_score: {
            terms: {
              field: 'ai.stats.risk.rank.keyword',
              order: {
                vendors: 'desc',
              },
              size: 5,
            },
            aggs: {
              vendors: {
                cardinality: {
                  field: 'vendorId.keyword',
                },
              },
            },
          },
        },
      },
    },
    _source: {
      excludes: [],
    },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      {
        field: 'ai.stats.activity.earliestActivity',
        format: 'date_time',
      },
      {
        field: 'ai.stats.activity.earliestProduct',
        format: 'date_time',
      },
      {
        field: 'ai.stats.activity.earliestReview',
        format: 'date_time',
      },
      {
        field: 'ai.stats.activity.earliestVendorProfile',
        format: 'date_time',
      },
      {
        field: 'ai.stats.activity.lastActivity',
        format: 'date_time',
      },
      {
        field: 'ai.stats.activity.lastProduct',
        format: 'date_time',
      },
      {
        field: 'ai.stats.activity.lastReview',
        format: 'date_time',
      },
      {
        field: 'ai.stats.timestamp',
        format: 'date_time',
      },
      {
        field: 'doc.processedTime',
        format: 'date_time',
      },
      {
        field: 'doc.scraped',
        format: 'date_time',
      },
      {
        field: 'lastModified',
        format: 'date_time',
      },
      {
        field: 'timestamp',
        format: 'date_time',
      },
    ],
    query: {
      bool: {
        must: [
          {
            match_all: {},
          },
        ],
        filter: [
          {
            match_all: {},
          },
          {
            match_phrase: {
              'doc.type.keyword': {
                query: 'vendor',
              },
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              'ai.stats.risk.rank.keyword': {
                query: 'Low',
              },
            },
          },
        ],
      },
    },
  };
  return query;
};

const searchTermPrevalence = (value, fields, dateRange) => {
  const startDate =
    dateRange && dateRange.startValue
      ? dayjs(dateRange.startValue).format('YYYY-MM-DD')
      : null;
  const endDate =
    dateRange && dateRange.endValue
      ? dayjs(dateRange.endValue).format('YYYY-MM-DD')
      : null;

  const query = {
    aggs: {
      dates: {
        auto_date_histogram: {
          field: 'timestamp',
          buckets: 50,
          format: 'yyyy-MM-dd',
          minimum_interval: 'hour',
        },
      },
    },
    size: 0,
    _source: {
      excludes: ['images', 'images.exifTags', 'images.exifTags.location'],
    },
    stored_fields: ['*'],
    script_fields: {},
    docvalue_fields: [
      {
        field: 'doc.processedTime',
        format: 'date_time',
      },
      {
        field: 'doc.scraped',
        format: 'date_time',
      },
      {
        field: 'timestamp',
        format: 'date_time',
      },
    ],
    query: {
      bool: {
        must: [],
        filter: [
          {
            multi_match: {
              query: value.value,
              fields: value.elasticPath,
            },
          },
          {
            range: {
              timestamp: {
                format: 'strict_date_optional_time',
                gte: dateRange?.startValue ? startDate : 'now-6M',
                lte: dateRange?.endValue ? endDate : 'now',
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
  return query;
};

const docsQuery = (value) => {
  const query = {
    query: {
      bool: {
        must: {
          term: {
            'doc.sourceHash': value,
          },
        },
      },
    },
    size: 125,
  };
  return query;
};

const newSearchesQuery = () => {
  const query = {
    size: 10000,
    query: {
      bool: {
        must: [
          {
            term: {
              'alert.enabled': true,
            },
          },
          {
            range: {
              'alert.hits': {
                gt: 0,
              },
            },
          },
        ],
      },
    },
  };
  return query;
};

const resourcesQuery = () => {
  const query = {
    query: {
      match_all: {},
    },
    size: 10000,
  };

  return query;
};

const restrictedFieldsQuery = (fields = []) => {

  const fieldToEsMap = {
    CSAM: 'ai.content.csam',
    USP: 'ai.usper.potential',
    csam: 'ai.content.csam',
    usper: 'ai.usper.potential',
  };

  const mustNotFields = fields.map((f) => ({
    exists: {
      field: fieldToEsMap[f],
    },
  }));
  const query = {
    query: {
      bool: {
        must_not: mustNotFields,
      },
    },
  };
  return query;
};

const localBitcoinMessage = (page) => {
  const query = {
    size: 25,
    from: page * 1000,
    sort: [{ timestamp: { order: 'asc' } }],
    query: {
      match_all: {},
    },
  };
  return query;
};

const lbcThreadQuery = (threadId) => {
  let query = {
    sort: [{ timestamp: { order: 'desc' } }],
    size: 10000,
    query: {
      bool: {
        must: [{ term: { threadId: String(threadId) } }],
        filter: [
          {
            match_all: {},
          },
          {
            match_phrase: {
              'doc.type.keyword': {
                query: 'message',
              },
            },
          },
        ],
      },
    },
  };
  return query;
};

const intFieldQuery = (filterName, filterValue) => {
  const query = {
    query: {
      match: {
        [filterName]: filterValue,
      },
    },
  };

  return query;
};

export {
  onlineQuery,
  sitesDateQuery,
  savedRecords,
  savedSearches,
  generalDatesQuery,
  localBitcoinMessage,
  aiFieldQuery,
  uniqueItemsByMarket,
  uniqueItemsByNetByTime,
  uniqueItemsByMarketByTime,
  aiContentByMarket,
  booleanQuery,
  notQuery,
  bulkSearchQuery,
  threadQuery,
  docsQuery,
  databaseQuery,
  scansQuery,
  databaseIndiciesQuery,
  productCount30DayTrend,
  uniqueReviewsByMarket,
  searchQuery,
  userPreviewQuery,
  mostReviewedVendor,
  // marketsCollectedLast30Days,
  // uniqueVendorsLast30Days,
  // uniqueReviewsLast30Days,
  responseStatusQuery,
  requiredFieldsQuery,
  dataSnapshotQuery,
  trendingTermsQuery,
  dataStreamQuery,
  recordCountQuery,
  trendingSitesQuery,
  topOpiateVendors,
  topMethVendors,
  vendorRiskScore,
  searchTermPrevalence,
  altBooleanQuery,
  newSearchesQuery,
  resourcesQuery,
  restrictedFieldsQuery,
  lbcThreadQuery,
  dashboardDatesQuery,
  intFieldQuery,
};
