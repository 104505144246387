import React, { useState, useRef, useContext, useEffect } from 'react';
import styled from 'styled-components';

import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TranslateIcon from '@material-ui/icons/Translate';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Button from '../../Button/Button.js';
import { SearchTranslator } from '../../composite/DarkBlueCard/Translator.js';
import ShareIcon from '@material-ui/icons/Share';
import FileCopy from '@material-ui/icons/FileCopy';

import { useClipboard } from 'use-clipboard-copy';
import { useSnackbar } from 'notistack';

import { StateContext } from '../StateProviderContext/StateProviderContext.js';

import { searchbarQuery } from '../utils';

import SearchSort from './SearchbarToggles/SearchSort/SearchSort.js';
import FilterToggle from './SearchbarToggles/FilterToggle/FilterToggle.js';
import OtherToggle from './SearchbarToggles/OtherToggle/OtherToggle';
import Tooltip from '@material-ui/core/Tooltip';

const StyledTooltip = styled(Tooltip)`
  @global {
    &.MuiTooltip-tooltip.bg-override div {
      display: flex;
      width: fit-content;
    }
  }

  p {
    width: fit-content;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: ${(props) => props.theme.colors.primary};
  margin-left: 12px;
`;

const StyledSaveIcon = styled(SaveIcon)``;

const StyledSavedIcon = styled(SaveIcon)`
  color: ${(props) => props.theme.colors.primary} !important;
`;

const SearchButton = styled(Button).attrs(() => ({
  id: 'search-button',
}))`
  border: 1px solid ${(props) => props.theme.colors.altPrimaryOpacity};
  border-radius: 0px;
  align-self: center;
  margin-right: -2px;
  max-height: calc(100% + 2px);
  height: calc(100% + 2px);
  & p {
    font-size: 14px !important;
    color: ${(props) => props.theme.colors.plume};
  }
`;

const SearchBarContainer = styled.div`
  height: 50px;
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.altPrimaryOpacity};
  border-radius: 0px;
  background-color: ${(props) => props.theme.colors.fog};
  transition: border 100ms linear;

  &:focus-within {
    border: 3px solid ${(props) => props.theme.colors.primary};
  }

  &:focus-within ${StyledSearchIcon} {
    color: ${(props) => props.theme.colors.altPrimary};
  }

  &focus ${CustomSearchBar} {
    border: 1px solid ${(props) => props.theme.colors.primary};
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const CustomSearchBar = styled.input.attrs({
  type: 'text',
  id: 'searchbar-input',
})`
  ::placeholder {
    color: ${(props) => props.theme.colors.almostBlack};
    opacity: 0.8;
  }
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  padding-left: 12px;
  font-size: 16px;
  line-height: ${(props) => props.theme.type.p.m.lineHeight};
`;

const SaveSearchButton = styled.div.attrs(() => ({
  id: 'save-search-button',
}))`
  display: flex;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;

  & > * {
    color: ${(props) => props.theme.colors.almostBlack};
  }

  &:hover {
    & > * {
      color: ${(props) => props.theme.colors.altPrimary};
    }
  }
`;

const ThemedSelect = styled(Select)`
  .MuiSelect-select.MuiSelect-select {
    padding-right: 30px;
  }

  div {
    color: ${(props) =>
      props.hoveractive === 'true'
        ? props.theme.colors.altPrimary
        : props.theme.colors.almostBlack};
  }

  svg {
    fill: ${(props) =>
      props.hoveractive === 'true'
        ? props.theme.colors.altPrimary
        : props.theme.colors.almostBlack};
    padding-right: ${(props) => (props.searchactive.length ? '0px' : '6px')};
  }
`;

const ThemedTranslateIcon = styled(TranslateIcon).attrs(() => ({
  id: 'translate-icon',
}))`
  & > * {
    color: ${(props) => props.theme.colors.almostBlack};
  }
`;

const TranslateDefineBoxContainer = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackground};
  margin: 4px 8px;
  padding: 8px 8px;
  border-radius: 0px;
`;

const ThemedMenuItem = styled(MenuItem)`
  font-family: 'Red Hat regular';
  font-size: 12px;
  letter-spacing: 0;
  text-align: center;
  line-height: 16px;
`;

const StyledP = styled.p`
  font-size: ${(props) => props.theme.fontSizes[2]};
`;

const ShareContainer = styled.div.attrs(() => ({
  id: 'share-search-wrapper',
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 6px;

  & > * {
    color: ${(props) => props.theme.colors.almostBlack};
  }

  &:hover {
    & > * {
      color: ${(props) => props.theme.colors.altPrimary};
      cursor: pointer;
    }
  }
`;

const FiltersContainer = styled.div`
  display: flex;
`;

const TooltipText = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${(props) => props.theme.colors.almostBlack};
`;

const StyledCopy = styled(FileCopy)`
  fill: ${(props) => props.theme.colors.white} !important;
`;

const TranslateDefineBox = () => {
  return (
    <TranslateDefineBoxContainer>
      <p>
        Type a term into the search bar in English, then select foreign language
        here to translate English term into selected foreign language before
        executing search.
      </p>
    </TranslateDefineBoxContainer>
  );
};

const languageList = [
  { displayName: 'Arabic', languageCode: 'ar' },
  { displayName: 'Bangla', languageCode: 'bn' },
  { displayName: 'Chinese-simplified', languageCode: 'zh-hans' },
  { displayName: 'Chinese-traditional', languageCode: 'zh-hant' },
  { displayName: 'English', languageCode: 'en' },
  { displayName: 'French', languageCode: 'fr' },
  { displayName: 'German', languageCode: 'de' },
  { displayName: 'Hindi', languageCode: 'hi' },
  { displayName: 'Japanese', languageCode: 'ja' },
  { displayName: 'Korean', languageCode: 'ko' },
  { displayName: 'Malay', languageCode: 'ms' },
  { displayName: 'Persian Farsi', languageCode: 'fa' },
  { displayName: 'Portuguese-Brazil', languageCode: 'pt' },
  { displayName: 'Portuguese-Portugal', languageCode: 'pt-pt' },
  { displayName: 'Russian', languageCode: 'ru' },
  { displayName: 'Spanish', languageCode: 'es' },
  { displayName: 'Thai', languageCode: 'th' },
  { displayName: 'Turkish', languageCode: 'tr' },
  { displayName: 'Ukrainian', languageCode: 'uk' },
];

const ShareSearch = () => {
  const { enqueueSnackbar } = useSnackbar();
  const copy = useClipboard().copy;
  const url = window.location.href;
  const btnStyle = {
    alignSelf: 'center',
    width: '41px',
    height: '41px',
    minWidth: '41px',
    marginLeft: '15px',
  };
  return (
    <div>
      <TooltipText
        style={{
          marginTop: '0',
          marginBottom: '3px',
          fontWeight: 'bold',
        }}
      >
        Shareable URL
      </TooltipText>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <TooltipText style={{ margin: '0', wordBreak: 'break-all' }}>
          {url}
        </TooltipText>

        <Button
          style={btnStyle}
          onClick={() => {
            const copiedValue = url;
            copy(copiedValue);
            enqueueSnackbar('Successfully Copied!', {
              variant: 'success',
            });
          }}
        >
          <StyledCopy fontSize="small" style={{ verticalAlign: 'middle' }} />
        </Button>
      </div>
    </div>
  );
};

const Searchbar = ({
  setShowSaveModal = false,
  searchSaved = false,
  setSearchSaved = () => null,
  filterName,
  elasticPath,
  setSort = () => null,
  toggleSideFilter = () => null,
  showAdvFeatures = true,
  showTermsChart = false,
  setShowTermsChart = () => null,
  disableSort = false,
  disableGraph = false,
}) => {
  const {
    filterState,
    setFilterState,
    page,
    setPage,
    size,
    setSize,
    totalHits,
    timeTaken,
    relation,
    sort,
    ...rest
  } = useContext(StateContext);
  const defaultQueryString =
    (filterState[filterName] && filterState[filterName].value) || '';
  const [queryString, setQueryString] = useState(defaultQueryString);
  const [selectedLang, setSelectedLang] = useState('');
  const [open, setOpen] = useState(false);
  const [usedLangs, setUsedLangs] = useState([]);
  const [translateHover, setTranslateHover] = useState(false);
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const searchFocus = useRef(null);

  const handleLangChange = async (e) => {
    setUsedLangs((arr) => [...arr, e.target.value]);
    const qs = await SearchTranslator(
      queryString,
      e.target.value,
      usedLangs[usedLangs.length - 1],
    );
    setSelectedLang(e.target.value);
    setQueryString(qs);
  };
  const handleClick = async () => {
    let handledSearchTerm = '';
    if (queryString.length) {
      if (selectedLang === '' || selectedLang === 'en') {
        handledSearchTerm = queryString;
      } else {
        handledSearchTerm = await SearchTranslator(queryString, selectedLang);
      }
      setFilterState({
        ...filterState,
        ...{
          [filterName]: {
            value: handledSearchTerm,
            elasticPath,
            query: searchbarQuery,
          },
        },
      });
    } else {
      const { [filterName]: deletedKey, ...rest } = filterState;
      setFilterState(rest);
    }
  };

  const handleTooltipState = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setQueryString(defaultQueryString);
  }, [filterState[filterName]?.value, filterState, sort, size, page]);

  useEffect(() => {
    setSearchSaved(false);
  }, [filterState]);

  useEffect(() => {
    searchFocus.current.focus();
  }, []);

  const perPageOptions = [
    {
      displayText: '25',
      value: 25,
    },
    {
      displayText: '50',
      value: 50,
    },
    {
      displayText: '100',
      value: 100,
    },
  ];

  return (
    <>
      <SearchBarContainer>
        <SearchInputContainer>
          <StyledSearchIcon />
          <CustomSearchBar
            ref={searchFocus}
            placeholder="Search the Dark Web"
            onChange={(e) => {
              setQueryString(e.target.value);
            }}
            autoComplete="off"
            value={queryString}
            onKeyPress={(e) => (e.key === 'Enter' ? handleClick() : null)}
          />
        </SearchInputContainer>
        {showAdvFeatures ? (
          <>
            <StyledTooltip
              classes={{
                tooltip: 'bg-override my-tooltip-class',
              }}
              open={translateHover}
              onOpen={() => {
                if (!selectIsOpen) {
                  setTranslateHover(true);
                } else {
                  setTranslateHover(false);
                }
              }}
              onClose={() => {
                setTranslateHover(false);
              }}
              title={<TooltipText>Translate search term</TooltipText>}
            >
              <ThemedSelect
                searchactive={
                  Object.entries(filterState).length > 0 ? 'true' : ''
                }
                disableUnderline={true}
                value={selectedLang}
                onOpen={() => {
                  setTranslateHover(false);
                  setSelectIsOpen(true);
                }}
                onClose={() => {
                  setSelectIsOpen(false);
                  setTranslateHover(false);
                }}
                onChange={(e) => {
                  handleLangChange(e);
                  setTranslateHover(false);
                }}
                IconComponent={ThemedTranslateIcon}
                hoveractive={`${translateHover}`}
              >
                {languageList.map((lang) => {
                  return (
                    <ThemedMenuItem
                      key={lang.languageCode}
                      value={lang.languageCode}
                    >
                      {lang.displayName}
                    </ThemedMenuItem>
                  );
                })}
              </ThemedSelect>
            </StyledTooltip>
            {Object.entries(filterState).length > 0 ? (
              <>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <StyledTooltip
                    classes={{
                      tooltip: 'bg-override my-tooltip-class',
                    }}
                    interactive
                    placement="bottom"
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={<ShareSearch />}
                  >
                    <div style={{ display: 'flex' }}>
                      <StyledTooltip
                        classes={{
                          tooltip: 'bg-override my-tooltip-class',
                        }}
                        title={<TooltipText>Share search</TooltipText>}
                      >
                        <ShareContainer onClick={handleTooltipState}>
                          <ShareIcon />
                        </ShareContainer>
                      </StyledTooltip>
                    </div>
                  </StyledTooltip>
                </ClickAwayListener>
                <StyledTooltip
                  classes={{
                    tooltip: 'bg-override my-tooltip-class',
                  }}
                  title={<TooltipText>Save search</TooltipText>}
                >
                  <SaveSearchButton onClick={() => setShowSaveModal(true)}>
                    {searchSaved ? <StyledSavedIcon /> : <StyledSaveIcon />}
                  </SaveSearchButton>
                </StyledTooltip>
              </>
            ) : null}
          </>
        ) : null}
        <SearchButton search={true} onClick={handleClick}>
          Search
        </SearchButton>
      </SearchBarContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: '10px 0',
        }}
      >
        <FiltersContainer>
          {!disableSort && <SearchSort setSort={setSort} />}
          <FilterToggle
            disableSort={disableSort}
            toggleSideFilter={toggleSideFilter}
          />
          <OtherToggle
            showTermsChart={showTermsChart}
            setShowTermsChart={setShowTermsChart}
            disableGraph={disableGraph}
          />
        </FiltersContainer>
        <div>
          {totalHits > 0 && size && timeTaken ? (
            <p style={{ marginBlockStart: '0px' }}>
              Showing {size > totalHits ? totalHits : size} of total {totalHits}
              {relation === 'gte' ? '+' : null}
            </p>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Searchbar;
