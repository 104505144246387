import React, { useState } from 'react';
import styled from 'styled-components';

import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';

import Button from '../../darkblue-ui/Button/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';

import Loader from '../../darkblue-ui/Spinners/Loader';

const FilterGridItem = styled(GridItem)`
  &&& {
    padding-bottom: 12px !important;
  }
  .css-1pwe92q {
    margin-bottom: 0px;
    font-weight: 400;
  }
`;

const FilterCategory = ({
  filterCategories,
  category,
  filterList,
  filters,
  setFilterState,
  setTriggerQuery,
  filterState,
}) => {
  const [open, setOpen] = useState(category === 'Default');
  return (
    <GridItem
      xs={
        filterCategories.length > 6
          ? 3
          : Math.round(12 / filterCategories.length)
      }
    >
      <GridContainer alignItems="center" justify="center">
        {category === 'Default' ? null : (
          <Button onClick={() => setOpen(!open)} color="info">
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {category}
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </span>
          </Button>
        )}
      </GridContainer>

      <GridContainer
        style={{
          overflowY: category === 'Default' ? null : 'auto',
          marginBottom: '40px',
        }}
      >
        {filters
          .filter((filter) => {
            return filter.props.filterType === category;
          })
          .map((filter) => {
            let loaderFilter = null;
            const cleanFilterList = filterList.filter(
              (item) => item !== filter.props.componentId,
            );
            if (filter.type.name === 'MultiDropdownList') {
              loaderFilter = React.cloneElement(filter, {
                react: { and: cleanFilterList },
                loader: <Loader />,
                value: filterState[filter.props.componentId],
                URLParams: true,
                onChange: (value) => {
                  const newFilterState = {
                    ...filterState,
                    ...{ [filter.props.componentId]: value },
                  };
                  setFilterState(newFilterState);
                },
              });
            } else if (filter.type.name === 'DataSearch') {
              loaderFilter = React.cloneElement(filter, {
                react: { and: cleanFilterList },
                loader: <Loader />,
                value: filterState[filter.props.componentId],
                URLParams: true,
                onChange: (value, triggerQuery) => {
                  const newFilterState = {
                    ...filterState,
                    ...{
                      [filter.props.componentId]: value,
                    },
                  };
                  setTriggerQuery(triggerQuery);
                  setFilterState(newFilterState);
                },
              });
            } else {
              loaderFilter = React.cloneElement(filter, {
                react: { and: cleanFilterList },
                loader: <Loader />,
                value: filterState[filter.props.componentId],
                URLParams: true,
                onChange: (value) => {
                  const newFilterState = {
                    ...filterState,
                    ...{ [filter.props.componentId]: value },
                  };
                  setFilterState(newFilterState);
                },
              });
            }
            const shouldShow = category === 'Default' || true;
            return (
              <FilterGridItem
                xs={12}
                key={loaderFilter.props.componentId}
                style={{
                  position: 'relative',
                  zIndex: category === 'Default' ? 1031 : 'auto',
                  display:
                    open && shouldShow && !loaderFilter.props.hide
                      ? 'inline'
                      : 'none',
                }}
              >
                {loaderFilter}
              </FilterGridItem>
            );
          })}
      </GridContainer>
    </GridItem>
  );
};

export default React.memo(FilterCategory);
