import React, { useState, useEffect } from 'react';

import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
});

const MapMentions = ({ apiKey }) => {
  return (
    <Map containerStyle={{ height: '400px' }}>
      <Layer type="symbol" id="marker">
        <Feature coordinates={[-0.481747846041145, 51.3233379650232]} />
      </Layer>
    </Map>
  );
};

export default MapMentions;
