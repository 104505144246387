import React, { useState, createContext, useEffect, useContext } from 'react';
import bodybuilder from 'bodybuilder';
import { useLocation, useHistory } from 'react-router-dom';
import { UserContext } from '../../../DBAuthenticator';
import _ from 'lodash';

import { getSearchFields } from '../../../filters/UniversalFilters';
import {
  removeMarks,
  createObjFromPath,
} from '../../../utils/Functions/utils.js';
import {
  filterToQueryMapping,
  fetchData,
  profileExcludeQuery,
} from '../utils.js';

import {
  StateContext,
} from '../StateProviderContext/StateProviderContext';

const specialFilters = [
  'utm_content',
  'utm_medium',
  'utm_campaign',
  'utm_source',
];

const handleURLParams = (location, filterState, history, sort, size, page) => {
  const regularFilterParams = Object.entries(filterState)
    .filter(([filterName]) => !specialFilters.includes(filterName))
    .map(([filterName, { value }]) => {
      return `${filterName}=${encodeURIComponent(
        JSON.stringify({
          value,
        }),
      )}`;
    });
  const searchOptions = [
    `sort=${encodeURIComponent(sort)}`,
    `size=${encodeURIComponent(size)}`,
    `page=${encodeURIComponent(page)}`,
  ];
  const currentFilterParams = [...regularFilterParams, ...searchOptions].join(
    '&',
  );
  history.push('?' + currentFilterParams);
};

const StateProvider = ({ index = [], apiKey, ...rest }) => {
  const [filterState, setFilterState] = useState({});
  const [hits, setHits] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorFetching, setErrorFetching] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(25);
  const [sort, setSort] = useState(0);
  const [highlightFields, setHighlightFields] = useState(
    getSearchFields(index),
  );
  const [apiUrl, setApiUrl] = useState('');
  const [timeTaken, setTimeTaken] = useState(0);
  const [relation, setRelation] = useState('');
  const [secondarySorts, setSecondarySorts] = useState(true);

  const userSess = useContext(UserContext);

  const location = useLocation();
  const history = useHistory();

  const sortVals = [
    {
      displayName: 'Most Relevant',
      sortType: '_score',
      direction: 'desc',
    },
    {
      displayName: 'Time (Newest to Oldest)',
      sortType: 'timestamp',
      direction: 'desc',
    },
    {
      displayName: 'Time (Oldest to Newest)',
      sortType: 'timestamp',
      direction: 'asc',
    },
  ];

  const providerValue = React.useMemo(
    () => ({
      filterState,
      setFilterState,
      hits,
      loading,
      page,
      setPage,
      totalHits,
      size,
      setSize,
      sort,
      setSort,
      sortVals,
      apiUrl,
      apiKey,
      errorFetching,
      timeTaken,
      relation,
      secondarySorts,
      setSecondarySorts,
    }),
    [
      filterState,
      hits,
      page,
      totalHits,
      size,
      sort,
      sortVals,
      loading,
      apiUrl,
      apiKey,
      errorFetching,
      timeTaken,
      relation,
      secondarySorts,
    ],
  );

  useEffect(() => {
    setApiUrl(`https://${process.env.REACT_APP_API_HOST}/${index.join(',')}`);
    const paramString = decodeURI(location.search).replace(/\+/g, '%2B');

    const searchParams = new URLSearchParams(paramString);

    if (
      Array.from(searchParams).length &&
      Array.from(searchParams).length > 3
    ) {
      setLoading(true);
    }

    let defaultState = {};

    for (const [key, value] of searchParams) {
      if (!specialFilters.includes(key)) {
        const parsedValue = JSON.parse(value);

        if (key === 'sort') {
          setSort(Number(value));
        } else if (key === 'size') {
          setSize(Number(value));
        } else if (key === 'page') {
          setPage(Number(page));
        } else if (filterToQueryMapping[key]) {
          const { query } = filterToQueryMapping[key];
          const elasticPath =
            filterToQueryMapping[key].elasticPath || getSearchFields(index);

          const mappedParsedValue = {
            ...parsedValue,
            ...{
              query,
              elasticPath,
            },
          };
          defaultState = { ...defaultState, ...{ [key]: mappedParsedValue } };
        }
      }
    }
    setFilterState(defaultState);
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filterState]);

  useEffect(() => {
    handleURLParams(location, filterState, history, sort, size, page);
  }, [filterState, sort, size, page]);

  useEffect(() => {
    const secondarySortIndices = ['db_*', 'scans'];
    const filteredArray = index.filter((value) =>
      secondarySortIndices.includes(value),
    );

    if (filteredArray.length > 0) {
      setSecondarySorts(false);
    } else {
      setSecondarySorts(true);
    }
  }, [index]);

  useEffect(() => {
    let canceled = false;

    if (Object.entries(filterState).length > 0) {
      let queryBody = bodybuilder();
      if (Object.entries(filterState).length > 0) {
        Object.entries(filterState).forEach(
          ([filterName, { value, elasticPath: discreteEP = [] }]) => {
            if (value && specialFilters.indexOf(filterName) === -1) {
              const {
                query,
                elasticPath: defaultEP,
                highlight,
              } = filterToQueryMapping[filterName];
              const elasticPath = discreteEP.length ? discreteEP : defaultEP;
              if (highlight) {
                setHighlightFields([...highlightFields, ...elasticPath]);
              }
              const cleanValue = removeMarks(value);
              query(queryBody, { elasticPath, value: cleanValue });
            }
          },
        );
      }
      if (
        Array.isArray(
          userSess?.userDoc?._source?.cognito?.restrictedFields_v2,
        ) &&
        typeof userSess?.userDoc?._source?.cognito?.restrictedFields_v2[0] ===
          'object'
      ) {
        const restrictedFields =
          userSess.userDoc._source.cognito.restrictedFields_v2;
        restrictedFields.forEach((field) => {
          profileExcludeQuery(queryBody, field);
        });
      }

      const fetchWrapper = async () => {
        setLoading(true);
        try {
          let fieldObj = {};
          highlightFields.forEach((field) => {
            fieldObj = { ...fieldObj, ...{ [field]: {} } };
          });
          const { sortType, direction } = sortVals[sort];
          const queryBodyTmp = queryBody
            .rawOption('highlight', {
              pre_tags: ['<mark>'],
              post_tags: ['</mark>'],
              type: 'unified',
              number_of_fragments: 0,
              max_analyzed_offset: 999999,
              fields: fieldObj,
            })
            .size(size)
            .from(size * page)
            .sort(sortType, direction);

          let queryBodyString;

          if (sortType === '_score' && secondarySorts) {
            queryBodyString = queryBodyTmp
              .sort('timestamp', 'desc')
              .sort('doc.idHash.keyword', 'desc')
              .build();
          } else if (!secondarySorts && index.includes('scans')) {
            queryBodyString = queryBodyTmp.build();
          } else {
            queryBodyString = queryBodyTmp
              .sort('doc.idHash.keyword', 'desc')
              .build();
          }

          const response = await fetchData({
            canceled,
            query: queryBodyString,
            apiKey,
            index,
            apiUrl,
          });

          if (canceled) {
          } else if (response && !canceled && !response.error) {
            const highlightedHits = response.hits.hits.map((h) => {
              if (h.highlight) {
                let parsedHighlight = [];
                Object.entries(h.highlight).map(([title, value]) => {
                  const field = title.split('.keyword')[0];
                  const srcVal = _.get(h._source, field);
                  if (Array.isArray(srcVal)) {
                    value.map((item) => {
                      const cleanItem = item
                        .replace(/\<mark\>/g, '')
                        .replace(/\<\/mark\>/g, '');
                      const valIndex = _.findIndex(srcVal, function(i) {
                        return i === cleanItem;
                      });
                      if (valIndex > -1) {
                        srcVal[valIndex] = item;
                        parsedHighlight = [...parsedHighlight, [field, srcVal]];
                      }
                    });
                  } else {
                    parsedHighlight = [...parsedHighlight, [field, value[0]]];
                  }
                });
                let parsedHighlightObj = {};

                parsedHighlight.forEach(([key, value]) => {
                  createObjFromPath(parsedHighlightObj, key, value);
                });

                const mergedObject = _.merge(h._source, parsedHighlightObj);

                let newObj = {
                  ...h,
                  ...{ _source: mergedObject },
                };
                return newObj;
              } else {
                return h;
              }
            });

            setRelation(response.hits.total.relation);
            setTimeTaken(response.took);
            setHits(highlightedHits);
            setTotalHits(response.hits.total.value);
            setLoading(false);
            setErrorFetching(false);
          } else if (response.error) {
            setErrorFetching(true);
            setHits([]);
            setLoading(false);
            setTotalHits(0);
            setTimeTaken(response.took);
          }
        } catch (err) {
          setErrorFetching(true);
          setHits([]);
          setTotalHits(0);
          setLoading(false);
        }
      };

      if (userSess?.userDoc?._source?.cognito?.apiKey) {
        fetchWrapper();
      }
    } else {
      setErrorFetching(true);
      setHits([]);
      setLoading(false);
      setTotalHits(0);
    }

    return () => (canceled = true);
  }, [
    filterState,
    page,
    size,
    sort,
    userSess.userDoc?._source?.cognito?.apiKey,
  ]);

  return (
    <StateContext.Provider value={providerValue}>
      {rest.children({
        filterState,
        hits,
        setFilterState,
        setSort,
        loading,
        page,
        setPage,
        size,
        totalHits,
      })}
    </StateContext.Provider>
  );
};

export default StateProvider;
