import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

import GridContainer from '../../components/Grid/GridContainer';
import BitcoinModalView from './BitcoinModalView';
import BitcoinFullView from './BitcoinFullView';
import Loader from '../Spinners/Loader';

const chunk = (array, batchSize = 5) => {
  const chunked = [];
  for (let idx = 0; idx < array.length; idx += batchSize) {
    chunked.push(array.slice(idx, idx + batchSize));
  }
  return chunked;
};

const LoaderContainer = styled(GridContainer)`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.mainBackground};
  margin: 10px;
`;

/**
 * @Component BitCoinModule
 * Renders: Renders a Btc module.
 * @param  {obj} props
 */
const BitCoinModule = (props) => {
  const {
    bitcoinAddress,
    setShowBtcInformation,
    filterState,
    setFilterState,
    pageView,
    setErrors,
  } = props;
  const [bitcoinInfo, setBitcoinInfo] = useState([]);
  const [bitcoinAddressChunks, setBitcoinAddressChunks] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const last30Days = dayjs().subtract(30, 'days');

  useEffect(() => {
    const tempedChunks = chunk(bitcoinAddress);
    const [btcToFetch, ...newChunks] = tempedChunks;
    fetchData(btcToFetch);
    setBitcoinAddressChunks(newChunks);
  }, [bitcoinAddress[0], bitcoinAddress.length]);

  const fetchData = async (btcToFetch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BLOCK_CYPHER_URL}${btcToFetch.join(
          ';',
        )}?token=${process.env.REACT_APP_BLOCK_CYPHER}`,
      );

      if (response.state === 200) {
        const results = await response.json();
        const bitcoinInformation = Array.isArray(results)
          ? results.flat().filter((bit) => !bit.error)
          : [results];

        if (pageView) {
          setBitcoinInfo(bitcoinInformation);
          setLoading(false);
        } else {
          setBitcoinInfo(
            [...bitcoinInfo, ...bitcoinInformation].filter(
              (item) => item !== 'error',
            ),
          );
          setLoading(false);
        }
      } else if (response.status === 400) {
        const error = await response.json();
        setErrors({ errorMessage: error.error });
        setBitcoinInfo(['error']);
        setLoading(false);
      } else if (response.status === 429) {
        const results = await response.json();
        const regex = /Address (\w{1,100}) is invalid/;
        const cleanedBTCAddresses = results
          .filter((r) => r.error)
          .map((r) => {
            const match = regex.exec(r.error);
            const newBTCData = {
              address: `${
                match?.length > 2 ? match[1] : 'Something went wrong'
              }`,
            };
            return newBTCData;
          });
        const nonFailures = results.filter((r) => !r.error);
        setBitcoinInfo([
          ...bitcoinInfo,
          ...cleanedBTCAddresses,
          ...nonFailures,
        ]);
        setLoading(false);
      } else if (response.status !== 400) {
        const results = await response.json();
        const bitcoinInformation = Array.isArray(results)
          ? results.flat().filter((bit) => !bit.error)
          : [results];
        const parsedResults = bitcoinInformation.filter((r) => !r.error);
        if (parsedResults.length === 0) {
          setIsError(true);
          setLoading(false);
          enqueueSnackbar(`An error occurred. Please contact support.`, {
            variant: 'error',
          });
          setBitcoinInfo(['error']);
        } else {
          setBitcoinInfo([...bitcoinInfo, ...parsedResults]);
          setLoading(false);
        }
      } else {
        setIsError(true);
        setLoading(false);
        setBitcoinInfo(['error']);
      }
    } catch (error) {
      setIsError(true);
      setLoading(false);
      setBitcoinInfo(['error']);
      enqueueSnackbar(`An error occurred. Please contact support.`, {
        variant: 'error',
      });
    }
  };

  return bitcoinInfo.length !== 0 ? (
    !pageView ? (
      <BitcoinModalView
        last30Days={last30Days}
        isLoading={isLoading}
        setLoading={setLoading}
        fetchData={fetchData}
        setShowBtcInformation={setShowBtcInformation}
        setBitcoinAddressChunks={setBitcoinAddressChunks}
        filterState={filterState}
        setFilterState={setFilterState}
        bitcoinAddress={bitcoinAddress}
        bitcoinInfo={bitcoinInfo}
        bitcoinAddressChunks={bitcoinAddressChunks}
      />
    ) : (
      <BitcoinFullView bitcoinInfo={bitcoinInfo} last30Days={last30Days} />
    )
  ) : (
    <LoaderContainer justify="center" alignItems="center">
      <Loader />
    </LoaderContainer>
  );
};

export default BitCoinModule;
