import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const StyledP = styled.p`
  color: ${(props) => props.theme.colors.almostBlack}
`
const StyledIconButton = styled(IconButton)`
  max-width: fit-content;
  & > span {
    color: ${(props) => props.theme.colors.primary};
  }
  &&:hover {
    background-color: transparent;
  }
`;

const StyledTooltip = styled(Tooltip)``;

const HelpToolTipButton = ({ text, tipPlacement, size }) => {
  const containerTooltipRef = useRef(null);
  return (
    <StyledTooltip
      title={<StyledP>{text}</StyledP>}
      placement={tipPlacement}
      interactive
      arrow
    >
      <StyledIconButton ref={containerTooltipRef} size={size} disableRipple>
        <HelpOutlineIcon />
      </StyledIconButton>
    </StyledTooltip>
  );
};

HelpToolTipButton.defaultProps = {
  tipPlacement: 'top',
  size: 'medium',
};

HelpToolTipButton.propTypes = {
  text: PropTypes.string.isRequired,
  tipPlacement: PropTypes.string,
  size: PropTypes.string,
};

export default HelpToolTipButton;
