import React from 'react';
import styled, { css } from 'styled-components';

const CardContainer = styled.div`
  box-shadow: ${(props) => props.theme.shadow.light};
  border-radius: 0px;
  padding-top: 24px;
  padding-right: 26px;
  padding-bottom: 24px;
  padding-left: 24px;
  margin: 8px 0px;
  background-color: ${(props) => props.theme.colors.cardBackground};
`;

const Card = (props) => {
  return <CardContainer {...props}>{props.children}</CardContainer>;
};

export default Card;
