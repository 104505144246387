import React, { useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';

import Input from '../../../Inputs/Input';

import { MultiDropdownList } from '@appbaseio/reactivesearch';

import Button from '../../../Button/Button';

import StoreIcon from '@material-ui/icons/Store';

const DropdownButton = styled(Button).withConfig({ className: 'show' })`
  box-shadow: 0 0.6px 1px -9px rgba(0, 0, 0, 0.078),
    0 1.5px 2.6px -9px rgba(0, 0, 0, 0.111),
    0 3px 5.3px -9px rgba(0, 0, 0, 0.139),
    0 6.2px 11px -9px rgba(0, 0, 0, 0.172), 0 17px 30px -9px rgba(0, 0, 0, 0.25);
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CustomHR = styled.hr`
  border-top: 2px solid ${(props) => props.theme.colors.primaryText};
  width: 100%;
`;

const DropdownItemsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50px;
  right: 200px;
  width: 400px;
  z-index: ${(props) => (props.showDropdown ? 100 : -1)};
  height: 80vh;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.cardBackground};
  padding: 12px;
  box-shadow: ${(props) => props.theme.shadow.medHeavy};
  visibility: ${(props) => (props.showDropdown ? 'visible' : 'hidden')};

  & > div {
    padding-bottom: 12px;
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 26px;
  height: 26px;
  background: ${(props) =>
    props.active ? props.theme.colors.primary : props.theme.colors.cloud};
  border-radius: 0px;
  transition: all 100ms;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.cloud};

  ${CheckboxContainer}:hover {
    border: 1px solid ${(props) => props.theme.colors.altPrimary};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  display: ${(props) => (props.active ? 'inline' : 'none')};
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const CheckboxSelect = ({ active, className, ...rest }) => {
  return (
    <CheckboxContainer {...rest} className={className}>
      <HiddenCheckbox active={active} {...rest} />
      <StyledCheckbox active={active}>
        <Icon active={active} viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

const Item = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  width: 100%;
  div:first-child {
    padding-right: 4px !important;
  }

  &:hover {
    cursor: pointer;
  }
`;

const KeepTitle = styled.h2``;

const TargetedSelectFilter = styled(MultiDropdownList)`
  &&& button {
    display: none;
  }
  &&& button.show {
    display: inline;
  }
  &&& > h2 {
    display: none;
  }

  &&& > ${DropdownButton} {
    display: inline;
  }

  &&& > ${KeepTitle} {
    display: inline;
  }
`;

const isActive = (item, state = []) => {
  return state.filter((value) => value === item).length;
};

const handleFilterChange = ({
  setFilterState,
  filterState,
  handleLocalChange,
  value,
  componentId,
  active,
  enqueueSnackbar,
  customEntry,
}) => {
  let newValue = [];
  if (active && !customEntry) {
    newValue = filterState[componentId].filter((v) => v !== value);
  } else if (active && customEntry) {
    enqueueSnackbar(`${value} already exists in this filter`);
    newValue = filterState[componentId];
  } else {
    newValue = [...filterState[componentId], value];
  }
  setFilterState({
    ...filterState,
    ...{
      [componentId]: newValue,
    },
  });
  handleLocalChange(value);
};

const itemLabel = (key) => {
  switch (key) {
    case '0':
      return 'false';
    case '1':
      return 'true';
    default:
      return key;
  }
};

const DropdownSelectFilter = (props) => {
  const [otherValue, setOtherValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFilterSubmit = (handleChange) => {
    handleFilterChange({
      setFilterState: props.setFilterState,
      filterState: props.filterState,
      value: otherValue,
      handleLocalChange: handleChange,
      componentId: props.componentId,
      active: isActive(otherValue, props.filterState[props.componentId]),
      customEntry: true,
      enqueueSnackbar: enqueueSnackbar,
    });
    setOtherValue('');
  };
  return (
    <TargetedSelectFilter
      {...props}
      label={props.label}
      render={({ loading, error, data, handleChange }) => {
        if (loading) {
          return <p>Fetching Applicable Filters</p>;
        }
        if (error) {
          return <p>Unable to retrieve filter details</p>;
        }
        return (
          <DropdownContainer>
            <DropdownButton
              style={{ height: '36px', padding: '0px 18px' }}
              className="show"
              customChild
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <StoreIcon />
                <p style={{ color: 'inherit' }} className="no-margin">
                  Markets
                </p>
              </span>
            </DropdownButton>
            <DropdownItemsContainer showDropdown={showDropdown}>
              {data.length > 0 ? (
                <KeepTitle>{props.filterLabel}</KeepTitle>
              ) : null}
              {props.text ? <p>{props.text}</p> : null}
              {props.text ? <CustomHR /> : null}
              {data.map((item) => {
                return (
                  <Item
                    onClick={() =>
                      handleFilterChange({
                        setFilterState: props.setFilterState,
                        filterState: props.filterState,
                        value: item.key,
                        handleLocalChange: handleChange,
                        componentId: props.componentId,
                        active: isActive(
                          item.key,
                          props.filterState[props.componentId],
                        ),
                      })
                    }
                    key={item.key}
                  >
                    <CheckboxSelect
                      active={isActive(
                        item.key,
                        props.filterState[props.componentId],
                      )}
                    />
                    <p>{itemLabel(item.key)}</p>
                  </Item>
                );
              })}
              {data.length > 0 && props.otherField ? (
                <Item>
                  <Input
                    label="Enter a custom value"
                    button
                    inputProps={{
                      onChange: (e) => setOtherValue(e.target.value),
                      value: otherValue,
                      onSubmit: () => {
                        handleFilterSubmit(handleChange);
                      },
                      placeholder: 'Other',
                      type: 'text',
                    }}
                  />
                </Item>
              ) : null}
            </DropdownItemsContainer>
          </DropdownContainer>
        );
      }}
    />
  );
};

export default DropdownSelectFilter;
