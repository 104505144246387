import React, { useState, useEffect, useContext } from 'react';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { UserConsumer } from '../../DBAuthenticator.js';

const stripTime = (query) => {
  let q = JSON.parse(query);
  if (q && typeof q === 'object') {
    q.query.bool.must[0].bool.must = q.query.bool.must[0].bool.must.filter(
      (val) => Object.keys(val)[0] !== 'range',
    );
  }
  return JSON.stringify(q);
};

const Cluster = ({ setSearchQueryString = (d) => d, ...rest }) => {
  const wbUrl = 'https://' + process.env.REACT_APP_API_HOST;
  const index = rest.index || 'sites,communications,markets,profiles';

  return (
    <UserConsumer>
      {({ apiKey }) => {
        return apiKey ? (
          <ReactiveBase
            app={index}
            headers={{ Authorization: 'ApiKey ' + apiKey.key }}
            url={wbUrl}
            theme={{}}
            style={{
              fontFamily: 'inherit',
              fontSize: 'inherit',
              color: 'inherit',
            }}
          >
            {rest.children}
          </ReactiveBase>
        ) : null;
      }}
    </UserConsumer>
  );
};

export default Cluster;
