import React from 'react';
import dayjs from 'dayjs';

import NotFilter from '../components/CustomFilters/NotFilter/NotFilter';
import RequiredFieldFilter from '../components/CustomFilters/RequiredFieldFilter/RequiredFieldFilter';
import {
  altBooleanQuery,
  requiredFieldsQuery,
} from '../utils/Queries/CustomQueries';

import SelectFilter from '../darkblue-ui/Search/SelectFilter/SelectFilter';
import TextFilter from '../darkblue-ui/Search/TextFilter/TextFilter';
import IndexFilter from '../darkblue-ui/CustomRSComponents/Filters/IndexFilter/IndexFilter';
import ToggleFilter from '../darkblue-ui/Search/ToggleFilter/ToggleFilter';

const UniversalFieldNameList = {
  darkflow: [],
  communications: [
    'answerId',
    'author',
    'board',
    'capCode',
    'country',
    'countryName',
    'doc.contentHash',
    'doc.idHash',
    'doc.priorVersions',
    'doc.type',
    'ethereumAddress',
    'litecoinAddress',
    'moneroAddress',
    'page',
    'pgp.identities.emails',
    'pgp.identities.names',
    'phoneNumbers',
    'post',
    'postId',
    'postLinks',
    'replyTo',
    'site.id',
    'socialMedia',
    'threadId',
    'title',
    'type',
    'userId',
    'text_content.ar',
    'text_content.es',
    'text_content.fa',
    'text_content.ko',
    'text_content.pt',
    'text_content.raw.icu',
    'text_content.ru',
    'text_content.tr',
    'text_content.zh',
  ],
  markets: [
    'ai.stats.matches.names.exact',
    'ai.stats.matches.names.fuzzy',
    'ai.stats.matches.selectors.pgp',
    'ai.stats.matches.selectors.pgp.fingerprints',
    'ai.stats.matches.selectors.pgp.identities.emails',
    'ai.stats.risk.explained',
    'ai.stats.risk.rank',
    'ai.stats.selectors.email',
    'ai.stats.selectors.emailAddresses',
    'ai.stats.selectors.languages',
    'ai.stats.selectors.pgp.fingerprints',
    'ai.stats.selectors.pgp.identities.emails',
    'ai.stats.selectors.pgp.identities.names',
    'ai.stats.selectors.phoneNumbers',
    'ai.stats.selectors.postLinks',
    'ai.stats.selectors.socialMedia',
    'bitcoinAddresses',
    'bitcoinPubkey',
    'bitcoinSignature',
    'comment',
    'contact',
    'condition',
    'coupons',
    'currencies',
    'doc.contentHash',
    'doc.idHash',
    'doc.priorVersions',
    'doc.type',
    'emailAddresses',
    'ethereumAddress',
    'handle',
    'imgAvatarHash',
    'imgHeaderHash',
    'imgsIPFSHash',
    'ipAddresses',
    'ipfsHash',
    'litecoinAddress',
    'moderators',
    'moneroAddress',
    'ogWeight',
    'originalCurrency',
    'originalPrice',
    'page',
    'pgp.armoredKeys',
    'pgp.created',
    'pgp.fingerprints',
    'pgp.identities.comments',
    'pgp.identities.emails',
    'pgp.identities.names',
    'pgp.identities.signers',
    'phoneNumbers',
    'postLinks',
    'productDescription',
    'productId',
    'productName',
    'products',
    'productSignature',
    'productSlug',
    'productTags',
    'productType',
    'productVariant',
    'ratingSignature',
    'refundPolicy',
    'replyTo',
    'review',
    'reviewId',
    'shipFrom',
    'shipping',
    'shippingMethods',
    'shippingOptions',
    'shippingRegions',
    'shipTo',
    'shortDescription',
    'site.id',
    'socialMedia',
    'termsAndConditions',
    'transactionAmount',
    'transportAddresses',
    'user',
    'userType',
    'vendorBitcoinAddress',
    'vendorCountry',
    'vendorId',
    'vendorInfo',
    'vendorLocation',
    'vendorName',
    'vendorPgp',
    'vendorRating',
    'vendorSignature',
    'website',
    'text_content.ar',
    'text_content.es',
    'text_content.fa',
    'text_content.ko',
    'text_content.pt',
    'text_content.raw.icu',
    'text_content.ru',
    'text_content.tr',
    'text_content.zh',
  ],
  profiles: [
    'age',
    'ai.user.matches.selectors.pgp.fingerprints',
    'ai.user.matches.selectors.pgp.identities.emails',
    'ai.user.language',
    'ai.user.location',
    'ai.user.lastPostType',
    'ai.user.bitcoinAddresses',
    'ai.user.email',
    'ai.user.matches',
    'ai.user.phoneNumbers',
    'ai.user.socialMedia',
    'ai.user.postLinks',
    'author',
    'bitcoinAddresses',
    // 'description', CONFLICT WITH MARKETS DESCRIPTION
    'doc.contentHash',
    'doc.idHash',
    'doc.priorVersions',
    'doc.type',
    'emailAddresses',
    'name',
    'ethereumAddress',
    'litecoinAddress',
    'moneroAddress',
    'page',
    'pgp.armoredKeys',
    'pgp.created',
    'pgp.fingerprints',
    'pgp.identities.comments',
    'pgp.identities.emails',
    'pgp.identities.names',
    'pgp.identities.signers',
    'phoneNumbers',
    'postLinks',
    'role',
    'signature',
    'site.id',
    'socialMedia',
    'userId',
    'website',
    'text_content.ar',
    'text_content.es',
    'text_content.fa',
    'text_content.ko',
    'text_content.pt',
    'text_content.raw.icu',
    'text_content.ru',
    'text_content.tr',
    'text_content.zh',
  ],
  sites: [
    'activeServices',
    'ai.crypto.ssh.matches.pageTitle',
    'ai.crypto.ssh.matches.hiddenService',
    'ai.crypto.ssh.priorVersions.fingerprint',
    'ai.drug.methamphetamines',
    'apacheServerInfo.httpdConf',
    'apacheServerInfo.httpdInfoConf',
    'apacheServerInfo.httpdVHostsConf',
    'apacheServerInfo.server',
    'ethereumAddresses',
    'moneroAddresses',
    'litecoinAddresses',
    'htmlMeta.tags',
    'ai.site.mirrors',
    'analyticsIDs',
    'apacheServices.clients',
    'apacheServices.protocols',
    'apacheServices.requests',
    'apacheServices.server',
    'apacheServices.vhosts',
    'bitcoinAddresses',
    'crypto.ssh.fingerprint',
    'crypto.ssh.key',
    'crypto.ssh.type',
    'crypto.ssh.version',
    'crypto.tls.version',
    'crypto.tls.subject',
    'crypto.tls.issuer',
    'doc.contentHash',
    'doc.idHash',
    'doc.priorVersions',
    'doc.type',
    'emailAddresses',
    'hashes',
    'hiddenService',
    'htmlMeta.lang',
    'i2pLinks',
    'interestingFiles',
    'internalPages',
    'ipAddresses',
    'jsScripts',
    'lastAction',
    'linkedSites',
    'openBazaarLinks',
    'openDirectories',
    'openPorts',
    'pageForms.actions',
    'pageForms.fieldNames',
    'pageForms.idAttrs',
    'pageLinks',
    'pageReferencedDirectories',
    'pageTitle',
    'pgp.armoredKey',
    'pgp.created',
    'pgp.fingerprint',
    'pgp.fingerprints',
    'pgp.identities.comments',
    'pgp.identities.emails',
    'pgp.identities.names',
    'pgp.identities.signers',
    'pgp.identity',
    'phoneNumbers',
    'redirectedFrom',
    'responseHeaders',
    'robotsTxt',
    'serverVersion',
    'moneroAddress',
    'siteMap.links',
    'snapshot',
    'snapshotPresent',
    'snapshotSnippet',
    'socialMedia',
    'ssh.fingerprint',
    'ssh.key',
    'ssh.type',
    'ssh.version',
    'text_content.ar',
    'text_content.es',
    'text_content.fa',
    'text_content.ko',
    'text_content.pt',
    'text_content.raw.icu',
    'text_content.ru',
    'text_content.tr',
    'text_content.zh',
  ],
  docs: [
    'metadata.Title',
    'metadata.Author',
    'bitcoinAddresses',
    'doc.contentHash',
    'text',
    'metadata.Subject',
    'metadata.Creator',
    'metadata.Producer',
    'litecoinAddress',
    'created',
    'fileType',
    'fileName',
    'doc.type',
    'pgp.identities.emails',
    'pgp.identities.names',
    'phoneNumbers',
    'socialMedia',
    'doc.sourcePath',
    'sourcePage',
    'doc.sourceHash',
    'doc.contentHash',
    'doc.idHash',
  ],
  scans: [
    'target',
    'port',
    'message',
    'crypto.ssh.version',
    'crypto.ssh.fingerprint',
    'crypto.ssh.type',
    'crypto.ssh.key',
    'crypto.tls.subject',
    'crypto.tls.issuer',
    'crypto.tls.version',
    'crypto.tls.serial',
    'crypto.tls.publicKey',
    'crypto.tls.fingerprint',
    'crypto.tls.signature',
    'crypto.tls.signatureHashAlorigthm',
    'crypto.tls.extensions.SubjectKeyIdentifier.oid',
    'crypto.tls.extensions.SubjectKeyIdentifier.digest',
    'crypto.tls.extensions.BasicConstraints.oid  ',
    'crypto.tls.extensions.BasicConstraints.path_length',
    'crypto.tls.extensions.AuthorityKeyIdentifier.oid    ',
    'crypto.tls.extensions.AuthorityKeyIdentifier.authority_cert_issuer',
    'crypto.tls.extensions.AuthorityKeyIdentifier.authority_cert_serial_number',
    'crypto.tls.extensions.AuthorityKeyIdentifier.key_identifier ',
    'crypto.tls.version',
    'error',
    'pgp.identities.emails',
    'pgp.identities.names',
    'status',
  ],
  lbc_users: [
    'introduction',
    'doc.idHash',
    'tags',
    'facebookDataRaw',
    'selectors.dob',
    'selectors.languages',
    'selectors.locations',
    'selectors.ssn',
    'selectors.timezones',
    'userId',
    'selectors.info',
    'walletId',
    'selectors.socialMedia',
    'selectors.emailAddresses',
    'selectors.ipAddresses',
    'selectors.postLinks',
    'selectors.names',
    'selectors.phoneNumbers',
    'selectors.usernames',
    'selectors.bitcoinAddresses',
    'selectors.pageLinks',
  ],
  lbc_messages: [
    'conversationId',
    'escrowId',
    'doc.idHash',
    'message',
    'cachedReceipt',
    'disputeMessage',
    'advertisementMessage',
    'messageId',
    'countryCode',
    'exchangeEscrowId',
    'walletId',
    'onlineProvider',
    'verifyCode',
    'currency',
    'doc.type',
    'messageType',
    'recipientId',
    'recipientUsername',
    'senderId',
    'senderUsername',
    'userIds',
    'selectors.bitcoinAddresses',
    'selectors.emailAddresses',
    'selectors.ipAddresses',
    'selectors.phoneNumbers',
    'selectors.postLinks',
    'selectors.socialMedia',
    'selectors.pgp.armoredKeys',
    'selectors.pgp.fingerprints',
    'selectors.pgp.identities.comments',
    'selectors.pgp.identities.emails',
    'selectors.pgp.identities.names',
    'selectors.pgp.identities.signers',
    'threadId',
  ],
  lbc_transactions: [
    'buyerId',
    'description',
    'buyerUsername',
    'doc.idHash',
    'doc.type',
    'fromWalletId',
    'toWalletLabel',
    'fromWalletLabel',
    'selectors.bitcoinAddresses',
    'selectors.phoneNumbers',
    'toWalletId',
    'selectors.emailAddresses',
    'selectors.ipAddresses',
    'selectors.locations',
    'toBitcoinAddress',
    'selectors.socialMedia',
    'selectors.postLinks',
    'selectors.pgp.armoredKeys',
    'selectors.pgp.fingerprints',
    'selectors.pgp.identities.comments',
    'selectors.pgp.identities.emails',
    'selectors.pgp.identities.names',
    'selectors.pgp.identities.signers',
    'sellerId',
    'sellerUsername',
    'transactionCategory',
    'text_content.pt',
    'text_content.es',
    'text_content.ar',
    'text_content.fa',
    'text_content.ko',
    'text_content.ru',
    'text_content.tr',
    'text_content.zh',
    'transactionId',
  ],
  lbc_bulletin: [
    'city',
    'doc.idHash',
    'accountInfo',
    'advertisementId',
    'advertismentType',
    'bankName',
    'country',
    'currency',
    'exchangeCity',
    'exchangeCountry',
    'locationId',
    'locationText',
    'message',
    'onlineProvider',
    'requirements',
    'selectors.address',
    'selectors.emailAddresses',
    'selectors.socialMedia',
    'selectors.phoneNumbers',
    'selectors.pgp.fingerprints',
    'selectors.pgp.identities.comments',
    'selectors.pgp.identities.emails',
    'selectors.pgp.identities.names',
    'selectors.pgp.identities.signers',
    'selectors.postLinks',
    'selectors.pgp.armoredKeys',
    'selectors.bitcoinAddresses',
    'text_content.es',
    'text_content.pt',
    'tradeType',
    'type',
    'userId',
    'username',
    'zipcode',
  ],
  'db_*': ['*'],
  scans: ['*'],
  get all() {
    return [
      ...new Set([
        ...this.markets,
        ...this.sites,
        ...this.profiles,
        ...this.communications,
        ...this.docs,
        ...this.scans,
      ]),
    ].filter((term) => term !== 'description');
  },
};

const getSearchFields = (indices) => {
  let tempSearchFields = [];
  indices.forEach(
    (i) =>
      (tempSearchFields = [...tempSearchFields, ...UniversalFieldNameList[i]]),
  );
  return [...new Set(tempSearchFields)];
};

const UniversalCategoryList = {
  markets: [
    'DateTime',
    'General',
    'Excluded',
    'Vendor',
    'Product',
    'Selectors',
    'Toggle Filters',
    'Coordinates',
  ],
  sites: [
    'DateTime',
    'General',
    'Excluded',
    'Site',
    'Selectors',
    'Toggle Filters',
  ],
  communications: [
    'DateTime',
    'General',
    'Excluded',
    'Posts',
    'Selectors',
    'Toggle Filters',
    'Coordinates',
  ],
  profiles: [
    'DateTime',
    'General',
    'Excluded',
    'User',
    'Selectors',
    'Toggle Filters',
    'Coordinates',
  ],
  docs: ['DateTime', 'General', 'Excluded', 'Toggle Filters'],
  lbc_users: ['DateTime', 'LBC', 'Excluded', 'General', 'Selectors'],
  lbc_messages: ['DateTime', 'Message', 'Excluded', 'General', 'Selectors'],
  lbc_transactions: [
    'DateTime',
    'Transaction',
    'Excluded',
    'General',
    'Selectors',
  ],
  lbc_bulletin: [
    'DateTime',
    'Advertisement',
    'Excluded',
    'General',
    'Selectors',
  ],
  'db_*': ['DateTimeExtracted', 'DB Dataset'],
  scans: ['DateTime', 'Scans Dataset'],
  darkflow: ['Maps'],
  dataset: ['Maps', 'MapGeneral'],
  get all() {
    return [
      ...new Set([
        ...this.markets,
        ...this.sites,
        ...this.profiles,
        ...this.communications,
        ...this.docs,
      ]),
    ];
  },
};

const SidebarFilters = [
  {
    filterName: 'DateTimeFilter',
    filterCategory: 'DateTime',
    elasticPath: ['timestamp'],
    filterType: 'DateTimeFilter',
    filterLabel: 'Time Range',
  },
  {
    filterName: 'DateTimeFilter',
    filterCategory: 'Maps',
    elasticPath: ['timestamp'],
    filterType: 'DateTimeFilter',
    filterLabel: 'Time Range',
  },
  {
    filterName: 'DatasetFilter',
    filterCategory: 'Maps',
    elasticPath: ['ai.geo.coordinates'],
    filterType: 'DataFilter',
    filterLabel: 'Data Layers',
  },
  {
    filterName: 'DocExtractedFilter',
    filterCategory: 'DateTimeExtracted',
    elasticPath: ['doc.extracted'],
    filterType: 'DateTimeFilter',
    filterLabel: 'Extracted Time',
  },
  {
    filterName: 'DatabaseIndexFilter',
    filterCategory: 'DB Dataset',
    elasticPath: ['doc.dataset.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'Datasets',
    size: 25,
  },
  {
    filterName: 'SiteNetFilter',
    filterCategory: 'General',
    elasticPath: ['site.net.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'Site Net',
  },
  {
    filterName: 'DocFilter',
    filterCategory: 'General',
    elasticPath: ['doc.type.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'Data Type',
    size: 25,
  },
  {
    filterName: 'DrugContentTagsFilter',
    filterCategory: 'General',
    elasticPath: ['ai.drugs.tags.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'AI Drug Content Tags',
  },
  {
    filterName: 'ContentTagsFilter',
    filterCategory: 'General',
    elasticPath: ['ai.content.tags.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'AI Content Tags',
  },
  // Not implemented with the GraphQL endpoint
  // {
  //   filterName: 'DrugContentTagsFilter',
  //   filterCategory: 'MapGeneral',
  //   elasticPath: ['ai.drugs.tags.keyword'],
  //   filterType: 'SelectFilter',
  //   filterLabel: 'AI Drug Content Tags',
  // },
  // {
  //   filterName: 'ContentTagsFilter',
  //   filterCategory: 'MapGeneral',
  //   elasticPath: ['ai.content.tags.keyword'],
  //   filterType: 'SelectFilter',
  //   filterLabel: 'AI Content Tags',
  // },
  {
    filterName: 'MessageTypeFilter',
    filterCategory: 'Message Type',
    elasticPath: ['messageType.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'Message Type',
  },
  {
    filterName: 'FirstSnapshotFilter',
    filterCategory: 'Toggle Filters',
    elasticPath: ['ai.site.firstSnapshot'],
    filterType: 'ToggleFilter',
    filterLabel: 'First Snapshot',
  },
  {
    filterName: 'CryptoToggleFilter',
    elasticPath: [
      'bitcoinAddresses.keyword',
      'moneroAddresses.keyword',
      'litecoinAddresses.keyword',
      'ethereumAddresses.keyword',
    ],
    filterCategory: 'Toggle Filters',
    filterType: 'ToggleFilter',
    filterLabel: 'Crypto Present',
  },
  {
    filterName: 'SelectorsToggleFilter',
    elasticPath: [
      'selectors',
      'bitcoinAddresses.keyword',
      'moneroAddresses.keyword',
      'litecoinAddresses.keyword',
      'ethereumAddresses.keyword',
    ],
    filterCategory: 'Toggle Filters',
    filterType: 'ToggleFilter',
    filterLabel: 'Selectors Present',
  },
  {
    filterName: 'JSDetectedFilter',
    filterCategory: 'Toggle Filters',
    elasticPath: ['jsDetected'],
    filterType: 'ToggleFilter',
    filterLabel: 'Javascript Detected',
  },
  {
    filterName: 'PrivateKeyFilter',
    filterCategory: 'Toggle Filters',
    elasticPath: ['privateKeyDetected'],
    filterType: 'ToggleFilter',
    filterLabel: 'Private Key Detected',
  },
  {
    filterName: 'ApacheStatusFilter',
    filterCategory: 'Toggle Filters',
    elasticPath: ['foundApacheModStatus'],
    filterType: 'ToggleFilter',
    filterLabel: 'Apache Status Detected',
  },
  {
    filterName: 'ClassifiedFilter',
    filterCategory: 'Toggle Filters',
    elasticPath: ['classified'],
    filterType: 'ToggleFilter',
    filterLabel: 'Classified',
  },
];

const AdvancedFilters = [
  {
    filterName: 'SiteNameFilter',
    filterCategory: 'General',
    elasticPath: ['site.name.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'Site Names',
  },
  {
    filterName: 'LanguageFilter',
    filterCategory: 'General',
    elasticPath: ['ai.language.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'Languages',
    defaultOptions: [
      'Arabic',
      'Chinese (Simplified)',
      'Persian (Farsi)',
      'Russian',
      'Korean',
    ],
  },
  {
    filterName: 'LocationFilter',
    filterCategory: 'General',
    elasticPath: ['ai.location.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Locations',
  },
  {
    filterName: 'PageFilter',
    filterCategory: 'General',
    elasticPath: ['page.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Page',
  },
  {
    filterName: 'FileNameFilter',
    filterCategory: 'General',
    elasticPath: ['fileName.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'File Name',
  },
  {
    filterName: 'FileTypeFilter',
    filterCategory: 'General',
    elasticPath: ['fileType.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'File Type',
  },
  {
    filterName: 'DocSourcePageFilter',
    filterCategory: 'General',
    elasticPath: ['sourcePage.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Source Page',
  },
  {
    filterName: 'LinksFilter',
    filterCategory: 'General',
    elasticPath: ['links.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Links',
  },
  {
    filterName: 'IpAddressesFilter',
    filterCategory: 'General',
    elasticPath: ['ipAddresses.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'IP Addresses',
  },
  {
    filterName: 'PhoneNumbersFilter',
    filterCategory: 'General',
    elasticPath: ['phoneNumbers.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Phone Numbers',
  },
  {
    filterName: 'HiddenServiceFilter',
    filterCategory: 'General',
    elasticPath: ['hiddenService.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Hidden Service',
  },
  {
    filterName: 'BoardFilter',
    filterCategory: 'Posts',
    elasticPath: ['board.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'Boards',
  },
  {
    filterName: 'ThreadIDFilter',
    filterCategory: 'Posts',
    elasticPath: ['threadId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Thread IDs',
  },
  {
    filterName: 'TitleFilter',
    filterCategory: 'Posts',
    elasticPath: ['title.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Post Titles',
  },
  {
    filterName: 'PostIDFilter',
    filterCategory: 'Posts',
    elasticPath: ['postId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Post IDs',
  },
  {
    filterName: 'PostOriginFilter',
    filterCategory: 'Posts',
    elasticPath: ['countryName.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Post Origins',
  },
  {
    filterName: 'AuthorFilter',
    filterCategory: 'Posts',
    elasticPath: ['author.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Authors',
  },
  {
    filterName: 'UserIdFilter',
    filterCategory: 'Selectors',
    elasticPath: ['userId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'User ID',
  },
  {
    filterName: 'EthereumAddressesFilter',
    filterCategory: 'Selectors',
    elasticPath: ['ethereumAddress.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Ethereum Addresses',
  },
  {
    filterName: 'MoneroAddressesFilter',
    filterCategory: 'Selectors',
    elasticPath: ['moneroAddress.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Monero Addresses',
  },
  {
    filterName: 'LitecoinAddressesFilter',
    filterCategory: 'Selectors',
    elasticPath: ['litecoinAddress.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Litecoin Addresses',
  },
  {
    filterName: 'UserFilter',
    filterCategory: 'Selectors',
    elasticPath: ['user.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'User',
  },
  {
    filterName: 'SelectorBitcoinAddressesFilter',
    filterCategory: 'General',
    elasticPath: ['selectors.bitcoinAddresses.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Bitcoin Addresses',
  },
  {
    filterName: 'UserTypeFilter',
    filterCategory: 'Selectors',
    elasticPath: ['userType.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'User Type',
  },
  {
    filterName: 'ProfileDrugContentFilter',
    filterCategory: 'Selectors',
    elasticPath: ['ai.content.drugs.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Profile Drug Content',
  },
  {
    filterName: 'PgpFingerPrintsFilter',
    filterCategory: 'Selectors',
    elasticPath: ['pgp.fingerprints.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'PGP Fingerprints',
  },
  {
    filterName: 'PgpEmailsFilter',
    filterCategory: 'Selectors',
    elasticPath: ['pgp.identities.emails.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'PGP Emails',
  },
  {
    filterName: 'BitcoinAddressesFilter',
    filterCategory: 'Selectors',
    elasticPath: ['bitcoinAddresses.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Bitcoin Addresses',
  },
  {
    filterName: 'EmailAddressesFilter',
    filterCategory: 'Selectors',
    elasticPath: ['emailAddresses.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Email Addresses',
  },
  {
    filterName: 'AnalyticsIdFilter',
    filterCategory: 'Selectors',
    elasticPath: ['analyticsIDs.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Analytics IDs',
  },
  {
    filterName: 'NameFilter',
    filterCategory: 'Profile',
    elasticPath: ['selectors.names.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Profile Name',
  },
  {
    filterName: 'SelectorNameFilter',
    filterCategory: 'LBC',
    elasticPath: ['selectors.names.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Name',
  },
  {
    filterName: 'AuthorDescriptionFilter',
    filterCategory: 'User',
    elasticPath: ['description.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Author Description',
  },
  {
    filterName: 'RoleFilter',
    filterCategory: 'User',
    elasticPath: ['role.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Role',
  },
  {
    filterName: 'RiskRankFilter',
    filterCategory: 'Vendor',
    elasticPath: ['ai.stats.risk.rank.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'Risk Rank',
  },
  {
    filterName: 'VendorNameFilter',
    filterCategory: 'Vendor',
    elasticPath: ['vendorName.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Vendor Name',
  },
  {
    filterName: 'VendorIdFilter',
    filterCategory: 'Vendor',
    elasticPath: ['vendorId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Vendor ID',
  },
  {
    filterName: 'ShipToFilter',
    filterCategory: 'Vendor',
    elasticPath: ['shipTo.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Shipping To Origins',
  },
  {
    filterName: 'ShipFromFilter',
    filterCategory: 'Vendor',
    elasticPath: ['shipFrom.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Shipping From Origins',
  },
  {
    filterName: 'ReviewFilter',
    filterCategory: 'Vendor',
    elasticPath: ['review.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Review Content',
  },
  {
    filterName: 'RatingFilter',
    filterCategory: 'Vendor',
    elasticPath: ['rating.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Rating',
  },
  {
    filterName: 'VendorInfoFilter',
    filterCategory: 'Vendor',
    elasticPath: ['vendorInfo.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Vendor Info',
  },
  {
    filterName: 'ProductNameFilter',
    filterCategory: 'Product',
    elasticPath: ['productName.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Product Name',
  },
  {
    filterName: 'ProductIdFilter',
    filterCategory: 'Product',
    elasticPath: ['productId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Product IDs',
  },
  {
    filterName: 'ProductTypeFilter',
    filterCategory: 'Product',
    elasticPath: ['productType.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Product Type',
  },
  {
    filterName: 'SiteTypeFilter',
    filterCategory: 'Site',
    elasticPath: ['ai.site.type.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'Site Type',
  },
  {
    filterName: 'SiteChangedFilter',
    filterCategory: 'Site',
    elasticPath: ['ai.site.changed.keyword'],
    filterType: 'SelectFilter',
    filterLabel: 'Site Changed',
  },
  {
    filterName: 'ResponseStatusFilter',
    filterCategory: 'Site',
    elasticPath: ['responseStatus'],
    filterType: 'SelectFilter',
    filterLabel: 'Online',
  },
  {
    filterName: 'PageTitleFilter',
    filterCategory: 'Site',
    elasticPath: ['pageTitle.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Page Title',
  },
  {
    filterName: 'ServerFilter',
    filterCategory: 'Site',
    elasticPath: ['serverVersion.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Server Version',
  },
  {
    filterName: 'PortsFilter',
    filterCategory: 'Site',
    elasticPath: ['openPorts.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Open Ports',
  },
  {
    filterName: 'ActiveServicesFilter',
    filterCategory: 'Site',
    elasticPath: ['activeServices.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Active Services',
  },
  {
    filterName: 'SSHFingerprintFilter',
    filterCategory: 'Site',
    elasticPath: ['crypto.ssh.fingerprint.keyword', 'ssh.fingerprint.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'SSH Fingerprint',
  },
  {
    filterName: 'SSHTypeFilter',
    filterCategory: 'Site',
    elasticPath: ['crypto.ssh.type.keyword', 'ssh.type.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'SSH Algorithm Type',
  },
  {
    filterName: 'SSHVersionFilter',
    filterCategory: 'Site',
    elasticPath: ['crypto.ssh.version.keyword', 'ssh.version.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'SSH Version',
  },
  {
    filterName: 'TLSSubjectIssuerFilter',
    filterCategory: 'Site',
    elasticPath: ['crypto.tls.issuer.keyword', 'crypto.tls.subject.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'TLS Subject or Issuer',
  },
  {
    filterName: 'TLSVersionFilter',
    filterCategory: 'Site',
    elasticPath: ['crypto.tls.version.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'TLS Version',
  },
  {
    filterName: 'TLSSignatureFilter',
    filterCategory: 'Site',
    elasticPath: ['crypto.tls.signature.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'TLS Signature',
  },
  {
    filterName: 'TLSFingerprintFilter',
    filterCategory: 'Site',
    elasticPath: ['crypto.tls.fingerprint.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'TLS Fingerprint',
  },
  {
    filterName: 'ApacheServerhttpdConfFilter',
    filterCategory: 'Site',
    elasticPath: ['apacheServerInfo.httpdConf.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Apache HTTPD Conf',
  },
  {
    filterName: 'ApacheServerhttpdInfoConfFilter',
    filterCategory: 'Site',
    elasticPath: ['apacheServerInfo.httpdInfoConf.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Apache Server HTTPD Info Conf',
  },
  {
    filterName: 'ApacheServerVHostsConfFilter',
    filterCategory: 'Site',
    elasticPath: ['apacheServerInfo.httpdVHostsConf.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Apache Server Vhosts Conf',
  },
  {
    filterName: 'ApacheServerInfoFilter',
    filterCategory: 'Site',
    elasticPath: ['apacheServerInfo.server.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Apache Server Info',
  },
  {
    filterName: 'TagsFilter',
    filterCategory: 'Selectors',
    elasticPath: ['tags.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Tag',
  },
  {
    filterName: 'LocationsFilter',
    filterCategory: 'LBC',
    elasticPath: ['selectors.locations.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Locations',
  },
  {
    filterName: 'SelectorUserNameFilter',
    filterCategory: 'LBC',
    elasticPath: ['selectors.usernames.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Username',
  },
  {
    filterName: 'DobFilterName',
    filterCategory: 'LBC',
    elasticPath: ['selectors.dob.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Date Of Birth',
  },
  {
    filterName: 'LBCLanguageFilter',
    filterCategory: 'LBC',
    elasticPath: ['selectors.languages.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Languages',
  },
  {
    filterName: 'PageLinkFilter',
    filterCategory: 'LBC',
    elasticPath: ['selectors.pageLinks.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'LBC',
  },
  {
    filterName: 'TimezoneFilter',
    filterCategory: 'LBC',
    elasticPath: ['selectors.timezones.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Time Zones',
  },
  {
    filterName: 'WalletIdFilter',
    filterCategory: 'LBC',
    elasticPath: ['walletId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Wallet ID',
  },
  {
    filterName: 'PageLinksFilter',
    filterCategory: 'LBC',
    elasticPath: ['selectors.pageLinks.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Page Links',
  },
  {
    filterName: 'FacebookDataRawFilter',
    filterCategory: 'LBC',
    elasticPath: ['facebookDataRaw.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Facebook Data Raw',
  },
  {
    filterName: 'PostLinksFilter',
    filterCategory: 'Selectors',
    elasticPath: ['selectors.postLinks.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Post Links',
  },
  {
    filterName: 'SocialMediaFilter',
    filterCategory: 'Selectors',
    elasticPath: ['selectors.socialMedia.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Social Media',
  },
  {
    filterName: 'SelectorEmailAddressesFilter',
    filterCategory: 'Selectors',
    elasticPath: ['selectors.emailAddresses.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Email Addresses',
  },
  {
    filterName: 'IpAddressFilter',
    filterCategory: 'Selectors',
    elasticPath: ['selectors.ipAddresses.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Ip Address',
  },
  {
    filterName: 'BuyerIdFilter',
    filterCategory: 'Transaction',
    elasticPath: ['buyerId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Buyer ID',
  },
  {
    filterName: 'FromWalletIdFilter',
    filterCategory: 'Transaction',
    elasticPath: ['fromWalletId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'From Wallet ID',
  },
  {
    filterName: 'ToWalletIdFilter',
    filterCategory: 'Transaction',
    elasticPath: ['toWalletId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'To Wallet ID',
  },
  {
    filterName: 'FromWalletLabelFilter',
    filterCategory: 'Transaction',
    elasticPath: ['fromWalletLabel.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'From Wallet Label',
  },
  {
    filterName: 'ToBitcoinAddressFilter',
    filterCategory: 'Transaction',
    elasticPath: ['toBitcoinAddress.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'To BitcoinAddress',
  },
  {
    filterName: 'ToWalletLabelFilter',
    filterCategory: 'Transaction',
    elasticPath: ['toWalletLabel.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'To Wallet Label',
  },
  {
    filterName: 'SellerIdFilter',
    filterCategory: 'Transaction',
    elasticPath: ['sellerId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Reciever Seller ID',
  },
  {
    filterName: 'TransactionIdFilter',
    filterCategory: 'Transaction',
    elasticPath: ['transactionId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Transaction ID',
  },
  {
    filterName: 'BuyerUsernameFilter',
    filterCategory: 'Transaction',
    elasticPath: ['buyerUsername.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Buyer Username',
  },
  {
    filterName: 'sellerUsernameFilter',
    filterCategory: 'Transaction',
    elasticPath: ['sellerUsername.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Seller Username',
  },
  {
    filterName: 'DescriptionFilter',
    filterCategory: 'Transaction',
    elasticPath: ['description.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Description',
  },
  {
    filterName: 'UserIdsFilter',
    filterCategory: 'General',
    elasticPath: ['userIds.keyword'],
    filterType: 'TextFilter',
    filterLabel: "User ID's",
  },
  {
    filterName: 'TransactionCategoryFilter',
    filterCategory: 'Transactions',
    elasticPath: ['transactionCategory.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Transaction Category',
  },
  {
    filterName: 'SenderUsernameFilter',
    filterCategory: 'Message',
    elasticPath: ['senderUsername.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Sender Username',
  },
  {
    filterName: 'DisputeMessageFilter',
    filterCategory: 'Message',
    elasticPath: ['disputeMessage.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Dispute Message',
  },
  {
    filterName: 'CountryCodeFilter',
    filterCategory: 'Message',
    elasticPath: ['countryCode.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Dispute Message',
  },
  {
    filterName: 'IntroductionFilter',
    filterCategory: 'LBC',
    elasticPath: ['introduction.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Introduction',
  },
  {
    filterName: 'MessageIdFilter',
    filterCategory: 'Message',
    elasticPath: ['messageId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Message ID',
  },
  {
    filterName: 'AdvertisementIdFilter',
    filterCategory: 'General',
    elasticPath: ['advertisementId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Advertisement ID',
  },
  {
    filterName: 'SenderIdFilter',
    filterCategory: 'Message',
    elasticPath: ['senderId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Sender ID',
  },
  {
    filterName: 'RecipientUsernameFilter',
    filterCategory: 'Message',
    elasticPath: ['recipientUsername.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Recipient Username',
  },
  {
    filterName: 'AdvertisementMessageFilter',
    filterCategory: 'advertisementMessage',
    elasticPath: ['advertisementMessage.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Advertisement Message',
  },
  {
    filterName: 'ConversationIdFilter',
    filterCategory: 'Message',
    elasticPath: ['conversationId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Conversation ID',
  },
  {
    filterName: 'RecipientIdFilter',
    filterCategory: 'Message',
    elasticPath: ['recipientId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Recipient ID',
  },
  {
    filterName: 'AccountInfoFilter',
    filterCategory: 'General',
    elasticPath: ['accountInfo.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Account Information',
  },
  {
    filterName: 'ThreadIdFilter',
    filterCategory: 'Message',
    elasticPath: ['threadId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Thread ID',
  },
  {
    filterName: 'AdvertismentTypeFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['advertismentType.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Advertisement Type',
  },
  {
    filterName: 'BankNameFilter',
    filterCategory: 'General',
    elasticPath: ['bankName.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Bank Name',
  },
  {
    filterName: 'CityFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['city.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'City',
  },
  {
    filterName: 'CountryFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['country.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Country',
  },
  {
    filterName: 'CurrencyFilter',
    filterCategory: 'General',
    elasticPath: ['currency.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Currency',
  },
  {
    filterName: 'exchangeCity',
    filterCategory: 'Advertisement',
    elasticPath: ['exchangeCity.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Exchange City',
  },
  {
    filterName: 'ExchangeCountryFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['exchangeCountry.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Exchange Country',
  },
  {
    filterName: 'LocationIdFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['locationId.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Location ID',
  },
  {
    filterName: 'LocationTextFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['locationText.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Location Text',
  },
  {
    filterName: 'MessageFilter',
    filterCategory: 'General',
    elasticPath: ['message.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Message',
  },
  {
    filterName: 'OnlineProviderFilter',
    filterCategory: 'General',
    elasticPath: ['onlineProvider.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Online Provider',
  },
  {
    filterName: 'RequirementsFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['requirements.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Requirements',
  },
  {
    filterName: 'SelectorPhoneNumberFilter',
    filterCategory: 'Selectors',
    elasticPath: ['selectors.phoneNumbers.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Phone Numbers',
  },
  {
    filterName: 'TradeTypeFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['tradeType.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Trade Type',
  },
  {
    filterName: 'TypeFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['type.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Type',
  },
  {
    filterName: 'UserNameFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['username.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Username',
  },
  {
    filterName: 'ZipCodeFilter',
    filterCategory: 'Advertisement',
    elasticPath: ['zipcode.keyword'],
    filterType: 'TextFilter',
    filterLabel: 'Zipcode',
  },
];

const DebugAllFilterNames = () => {
  const extraFilterNames = [
    'DateTimeFilter',
    'NotFilter',
    'RequiredFieldFilter',
    'IndexFilter',
  ];
  const advFilterNames = AdvancedFilters.map((f) => f.filterName);
  const sidebarFilterNames = SidebarFilters.map((f) => f.filterName);
  const allNames = [...extraFilterNames, ...advFilterNames, sidebarFilterNames];
  return allNames;
};

// Use to update login page allowed url params
//console.log(DebugAllFilterNames());

const UniversalFilterComponentList = [
  <NotFilter
    componentId="NotFilter"
    filterCategory="Excluded"
    filterType="NotFilter"
    hide
    filterLabel="Excluded Fields"
  />,
  <RequiredFieldFilter
    componentId="RequiredFieldFilter"
    filterCategory="General"
    filterType="RequiredFieldFilter"
    filterLabel="Required Fields"
  />,

  //To insure LBC would not adopt sites and data specfic filters in the adv modal.
  //I had to make a copy of the required filters to have a filter type of "LBC General"
  //So that when the filter modal appears to users it would not show unrelated general filters to LBC.
  <IndexFilter
    filterType="MultiDropdownList"
    filterCategory="General"
    componentId="IndexFilter"
    elasticPath={['_index']}
    dataField="_index"
    showFilter={true}
    URLParams={true}
    hide
  />,
  <SelectFilter
    componentId="SiteNetFilter"
    sidebarFilter
    elasticPath={['site.net.keyword']}
    dataField="site.net.keyword"
    title="Site Nets"
    filterType="SelectFilter"
    filterCategory="General"
    size={50}
    sortBy="count"
    queryFormat="or"
    selectAllLabel="All sites (by net)"
    showCheckbox={true}
    showCount={true}
    showSearch={true}
    placeholder="Select Net Type"
    showFilter={true}
    filterLabel="Site Net"
  />,
  <SelectFilter
    componentId="DocFilter"
    dataField="doc.type.keyword"
    elasticPath={['doc.type.keyword']}
    title="Data Type"
    filterCategory="General"
    filterType="SelectFilter"
    size={20}
    sidebarFilter
    showCheckbox={true}
    sortBy="asc"
    placeholder="Select Data Type"
    filterLabel="Doc Type"
  />,
  <SelectFilter
    componentId="SiteTypeFilter"
    dataField="ai.site.type.keyword"
    elasticPath={['ai.site.type.keyword']}
    title="Data Type"
    filterCategory="Site"
    filterType="SelectFilter"
    size={20}
    showCheckbox={true}
    sortBy="asc"
    placeholder="Select Data Type"
    style={{ position: 'relative', zIndex: 'auto' }}
    filterLabel="Site Type"
  />,
  <SelectFilter
    componentId="SiteChangedFilter"
    dataField="ai.site.changed.keyword"
    elasticPath={['ai.site.changed.keyword']}
    title="Data Type"
    filterCategory="Site"
    filterType="SelectFilter"
    size={20}
    showCheckbox={true}
    sortBy="asc"
    placeholder="Select Data Type"
    style={{ position: 'relative', zIndex: 'auto' }}
    filterLabel="Site Changed"
  />,
  <SelectFilter
    componentId="RiskRankFilter"
    dataField="ai.stats.risk.rank.keyword"
    elasticPath={['ai.stats.risk.rank.keyword']}
    title="Risk Rank"
    filterCategory="Vendor"
    filterType="SelectFilter"
    size={20}
    showCheckbox={true}
    sortBy="asc"
    placeholder="Select Risk Rank"
    style={{ position: 'relative', zIndex: 'auto' }}
    filterLabel="Risk Rank"
  />,
  <SelectFilter
    componentId="DocIdFilter"
    dataField="doc.idHash.keyword"
    elasticPath={['doc.idHash.keyword']}
    title="Data Type"
    filterCategory="General"
    filterType="SelectFilter"
    size={10}
    hide
    showCheckbox={true}
    sortBy="asc"
    placeholder="Select Document ID"
    filterLabel="Doc ID"
  />,
  <SelectFilter
    componentId="SiteNameFilter"
    dataField="site.name.keyword"
    elasticPath={['site.name.keyword']}
    title="Site Names"
    filterCategory="General"
    filterType="SelectFilter"
    size={10}
    queryFormat="and"
    placeholder="Enter Site Name"
    otherField
    showFilter={true}
    filterLabel="Site Names"
  />,
  <SelectFilter
    componentId="ContentDrugsFilter"
    dataField="ai.drugs.tags.keyword"
    elasticPath={['ai.drugs.tags.keyword']}
    title="Drug Tags"
    sidebarFilter
    filterCategory="Product"
    filterType="SelectFilter"
    size={50}
    showOnDashboard={true}
    sortBy="count"
    queryFormat="or"
    showCheckbox={true}
    placeholder="Select Content Keywords"
    showFilter={true}
    filterLabel="Drug Content Tags"
  />,
  <SelectFilter
    componentId="LanguageFilter"
    dataField="ai.language.keyword"
    elasticPath={['ai.language.keyword']}
    filterCategory="General"
    filterType="SelectFilter"
    size={5}
    sortBy="count"
    title="Languages"
    queryFormat="or"
    placeholder="Enter Languages"
    showFilter={true}
    filterLabel="Languages"
    otherField
  />,
  <SelectFilter
    componentId="BoardFilter"
    dataField="board.keyword"
    elasticPath={['board.keyword']}
    title="Boards"
    filterCategory="Posts"
    filterType="SelectFilter"
    size={5}
    sortBy="count"
    queryFormat="and"
    selectAllLabel="All Boards"
    showCheckbox={true}
    showCount={true}
    showSearch={true}
    placeholder="Select Board"
    showFilter={true}
    filterLabel="Boards"
    otherField
  />,
  <SelectFilter
    componentId="LocationFilter"
    filterCategory="General"
    filterType="SelectFilter"
    dataField="ai.location.keyword"
    elasticPath={['ai.location.keyword']}
    title="Locations"
    size={10}
    soryBy="count"
    otherField
    queryFormat="or"
    placeholder="Enter a Location"
    showFilter={true}
    filterLabel="Locations"
    style={{ position: 'relative', zIndex: 'auto' }}
  />,
  <SelectFilter
    componentId="ContentTagsFilter"
    sidebarFilter
    dataField="ai.content.tags.keyword"
    elasticPath={['ai.content.tags.keyword']}
    title="Content Tags"
    size={50}
    filterCategory="General"
    filterType="SelectFilter"
    sortBy="count"
    queryFormat="or"
    selectAllLabel="All Content Tags"
    showCheckbox={true}
    showCount={true}
    showSearch={true}
    placeholder="Select Tags"
    showFilter={true}
    filterLabel="Content Tags"
  />,
  <SelectFilter
    componentId="MessageTypeFilter"
    sidebarFilter
    dataField="messageType.keyword"
    elasticPath={['messageType.keyword']}
    title="Message Type"
    size={50}
    filterCategory="General"
    filterType="SelectFilter"
    sortBy="count"
    queryFormat="or"
    selectAllLabel="All Message Type"
    showCheckbox={true}
    showCount={true}
    showSearch={true}
    placeholder="Select Message Type"
    showFilter={true}
    filterLabel="Message Type"
  />,
  <SelectFilter
    componentId="ResponseStatusFilter"
    dataField="responseStatus"
    elasticPath={['responseStatus']}
    filterCategory="Site"
    filterType="SelectFilter"
    // customQuery={(props) => responseStatusQuery(props)}
    // defaultQuery={(props) => responseStatusQuery(props)}
    title="Response Status"
    queryFormat="or"
    showCheckbox={true}
    showFilter={true}
    filterLabel="Online"
  />,
  <TextFilter
    searchOperators
    componentId="SelectorEmailAddressesFilter"
    dataField={['selectors.emailAddresses']}
    elasticPath={['selectors.emailAddresses.keyword']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="Email Addresses"
    queryFormat="or"
    placeholder="Enter Email Addresses"
    showFilter={true}
    filterLabel="Email Addresses"
  />,
  <TextFilter
    searchOperators
    componentId="IpAddressFilter"
    dataField={['selectors.ipAddresses']}
    elasticPath={['selectors.ipAddresses.keyword']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="Ip Address"
    queryFormat="or"
    placeholder="Enter Ip Address"
    showFilter={true}
    filterLabel="Ip Address"
  />,
  <TextFilter
    searchOperators
    componentId="UserIdsFilter"
    dataField={['userIds']}
    elasticPath={['userIds.keyword']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="Enter User ID's"
    queryFormat="and"
    placeholder="Enter User ID's"
    showFilter={true}
    filterLabel="User ID's"
  />,
  <TextFilter
    searchOperators
    componentId="FacebookDataRawFilter"
    dataField={['facebookDataRaw']}
    elasticPath={['facebookDataRaw.keyword']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Enter Facebook Data Raw"
    queryFormat="and"
    placeholder="Enter Facebook Data Raw"
    showFilter={true}
    filterLabel="Facebook Data Raw"
  />,
  <TextFilter
    searchOperators
    componentId="TransactionCategoryFilter"
    dataField={['transactionCategory']}
    elasticPath={['transactionCategory.keyword']}
    filterCategory="transactionCategory"
    filterType="TextFilter"
    autoSuggest={false}
    title="Enter Transaction Category"
    queryFormat="and"
    placeholder="Enter Transaction Category"
    showFilter={true}
    filterLabel="Transaction Category"
  />,
  <TextFilter
    searchOperators
    componentId="SenderIdFilter"
    dataField={['senderId.keyword']}
    elasticPath={['senderId.keyword']}
    filterCategory="Message"
    filterType="TextFilter"
    autoSuggest={false}
    title="Sender ID"
    queryFormat="and"
    placeholder="Enter Sender ID"
    showFilter={true}
    filterLabel="Sender ID"
  />,
  <TextFilter
    searchOperators
    componentId="SenderUsernameFilter"
    dataField={['senderUsername']}
    elasticPath={['senderUsername.keyword']}
    filterCategory="Message"
    filterType="TextFilter"
    autoSuggest={false}
    title="Sender Username"
    queryFormat="and"
    placeholder="Enter Sender Username"
    showFilter={true}
    filterLabel="Sender Username"
  />,
  <TextFilter
    searchOperators
    componentId="AdvertisementMessageFilter"
    dataField={['advertisementMessage']}
    elasticPath={['advertisementMessage.keyword']}
    filterCategory="Message"
    filterType="TextFilter"
    autoSuggest={false}
    title="Sender Username"
    queryFormat="and"
    placeholder="Enter Advertisement Message"
    showFilter={true}
    filterLabel="Advertisement Message"
  />,
  <TextFilter
    searchOperators
    componentId="MessageIdFilter"
    dataField={['messageId']}
    elasticPath={['messageId.keyword']}
    filterCategory="Message"
    filterType="TextFilter"
    autoSuggest={false}
    title="Message ID"
    queryFormat="or"
    placeholder="Enter Message ID"
    showFilter={true}
    filterLabel="Message ID"
  />,
  <TextFilter
    searchOperators
    componentId="CountryCodeFilter"
    dataField={['countryCode']}
    elasticPath={['countryCode.keyword']}
    filterCategory="Message"
    filterType="TextFilter"
    autoSuggest={false}
    title="Country Code"
    queryFormat="or"
    placeholder="Enter Country Code"
    showFilter={true}
    filterLabel="Country Code"
  />,
  <TextFilter
    searchOperators
    componentId="DisputeMessageFilter"
    dataField={['disputeMessage']}
    elasticPath={['disputeMessage.keyword']}
    filterCategory="Message"
    filterType="TextFilter"
    autoSuggest={false}
    title="Dispute Message"
    queryFormat="or"
    placeholder="Enter Dispute Message"
    showFilter={true}
    filterLabel="Dispute Message"
  />,
  <TextFilter
    searchOperators
    componentId="TagsFilter"
    dataField={['tags']}
    elasticPath={['tags.keyword']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="Tag"
    queryFormat="and"
    placeholder="Enter Tag"
    showFilter={true}
    filterLabel="Tag"
  />,
  <TextFilter
    searchOperators
    componentId="RecipientIdFilter"
    dataField={['recipientId']}
    elasticPath={['recipientId.keyword']}
    filterCategory="Message"
    filterType="TextFilter"
    autoSuggest={false}
    title="Recipient ID"
    queryFormat="and"
    placeholder="Enter Recipient ID"
    showFilter={true}
    filterLabel="Recipient ID"
  />,
  <TextFilter
    searchOperators
    componentId="ConversationIdFilter"
    dataField={['conversationId']}
    elasticPath={['conversationId.keyword']}
    filterCategory="Message"
    filterType="TextFilter"
    autoSuggest={false}
    title="Conversation ID"
    queryFormat="and"
    placeholder="Enter Conversation ID"
    showFilter={true}
    filterLabel="Conversation ID"
  />,
  <TextFilter
    searchOperators
    componentId="RecipientUsernameFilter"
    dataField={['recipientUsername.keyword']}
    elasticPath={['recipientUsername.keyword']}
    filterCategory="Message"
    filterType="TextFilter"
    autoSuggest={false}
    title="Recipient Username"
    queryFormat="or"
    placeholder="Enter Recipient Username"
    showFilter={true}
    filterLabel="Recipient Username"
  />,
  <TextFilter
    searchOperators
    componentId="WalletIdFilter"
    dataField={['walletId.keyword']}
    elasticPath={['walletId.keyword']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Wallet ID"
    queryFormat="or"
    placeholder="Wallet ID"
    showFilter={true}
    filterLabel="Wallet ID"
  />,
  <TextFilter
    searchOperators
    componentId="AccountInfoFilter"
    dataField={['accountInfo']}
    elasticPath={['accountInfo.keyword']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="Account Information"
    queryFormat="or"
    placeholder="Enter Account Information"
    showFilter={true}
    filterLabel="Account Information"
  />,
  <TextFilter
    searchOperators
    componentId="AdvertisementIdFilter"
    dataField={['advertisementId']}
    elasticPath={['advertisementId.keyword']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="Advertisement ID"
    queryFormat="or"
    placeholder="Enter Advertisement ID"
    showFilter={true}
    filterLabel="Advertisement ID"
  />,
  <TextFilter
    searchOperators
    componentId="ThreadIdFilter"
    dataField={['threadId']}
    elasticPath={['threadId.keyword']}
    filterCategory="Message"
    filterType="TextFilter"
    autoSuggest={false}
    title="Thread ID"
    queryFormat="or"
    placeholder="Enter Thread ID"
    showFilter={true}
    filterLabel="Thread ID"
  />,
  <TextFilter
    searchOperators
    componentId="AdvertismentTypeFilter"
    dataField={['advertismentType']}
    elasticPath={['advertismentType.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Advertisment Type"
    queryFormat="or"
    placeholder="Enter Advertisment Type"
    showFilter={true}
    filterLabel="Advertisment Type"
  />,
  <TextFilter
    searchOperators
    componentId="BankNameFilter"
    dataField={['bankName']}
    elasticPath={['bankName.keyword']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="Bank Name"
    queryFormat="or"
    placeholder="Enter Bank Name"
    showFilter={true}
    filterLabel="Bank Name"
  />,
  <TextFilter
    searchOperators
    componentId="CountryFilter"
    dataField={['country']}
    elasticPath={['country.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Country"
    queryFormat="or"
    placeholder="Enter Country"
    showFilter={true}
    filterLabel="Advertisement"
  />,
  <TextFilter
    searchOperators
    componentId="CityFilter"
    dataField={['city']}
    elasticPath={['city.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="City"
    queryFormat="or"
    placeholder="Enter City"
    showFilter={true}
    filterLabel="City"
  />,
  <TextFilter
    searchOperators
    componentId="LocationIdFilter"
    dataField={['locationId']}
    elasticPath={['locationId.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Location ID"
    queryFormat="or"
    placeholder="Enter Location ID"
    showFilter={true}
    filterLabel="Location ID"
  />,
  <TextFilter
    searchOperators
    componentId="LocationTextFilter"
    dataField={['locationText']}
    elasticPath={['locationText.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Location Text"
    queryFormat="or"
    placeholder="Enter Location Text"
    showFilter={true}
    filterLabel="Location Text"
  />,
  <TextFilter
    searchOperators
    componentId="ExchangeCityFilter"
    dataField={['exchangeCity']}
    elasticPath={['exchangeCity.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Exchange City"
    queryFormat="or"
    placeholder="Enter Exchange City"
    showFilter={true}
    filterLabel="Exchange City"
  />,
  <TextFilter
    searchOperators
    componentId="ExchangeCountryFilter"
    dataField={['exchangeCountry']}
    elasticPath={['exchangeCountry.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Exchange Country"
    queryFormat="and"
    placeholder="Enter Exchange Country"
    showFilter={true}
    filterLabel="Exchange Country"
  />,
  <TextFilter
    searchOperators
    componentId="CurrencyFilter"
    dataField={['currency']}
    elasticPath={['currency.keyword']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="Currency"
    queryFormat="or"
    placeholder="Enter Currency"
    showFilter={true}
    filterLabel="Currency"
  />,
  <TextFilter
    searchOperators
    componentId="MessageFilter"
    dataField={['message']}
    elasticPath={['message.keyword']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="Message"
    queryFormat="or"
    placeholder="Enter Message"
    showFilter={true}
    filterLabel="Message"
  />,
  <TextFilter
    searchOperators
    componentId="OnlineProviderFilter"
    dataField={['onlineProvider']}
    elasticPath={['onlineProvider.keyword']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="Online Provider"
    queryFormat="or"
    placeholder="Enter Online Provider"
    showFilter={true}
    filterLabel="Online Provider"
  />,
  <TextFilter
    searchOperators
    componentId="RequirementsFilter"
    dataField={['requirements']}
    elasticPath={['requirements.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Requirements"
    queryFormat="or"
    placeholder="Enter Requirements"
    showFilter={true}
    filterLabel="Requirements"
  />,
  <TextFilter
    searchOperators
    componentId="IntroductionFilter"
    dataField={['introduction']}
    elasticPath={['introduction.keyword']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Introduction"
    queryFormat="or"
    placeholder="Enter Introduction"
    showFilter={true}
    filterLabel="Introduction"
  />,
  <TextFilter
    searchOperators
    componentId="TradeTypeFilter"
    dataField={['tradeType']}
    elasticPath={['tradeType.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Trade Type"
    queryFormat="or"
    placeholder="Enter Trade Type"
    showFilter={true}
    filterLabel="Trade Type"
  />,
  <TextFilter
    searchOperators
    componentId="TypeFilter"
    dataField={['type']}
    elasticPath={['type.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Type"
    queryFormat="or"
    placeholder="Enter Type"
    showFilter={true}
    filterLabel="Type"
  />,
  <TextFilter
    searchOperators
    componentId="UserNameFilter"
    dataField={['username']}
    elasticPath={['username.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Username"
    queryFormat="or"
    placeholder="Enter Username"
    showFilter={true}
    filterLabel="Username"
  />,
  <TextFilter
    searchOperators
    componentId="ZipCodeFilter"
    dataField={['zipcode']}
    elasticPath={['zipcode.keyword']}
    filterCategory="Advertisement"
    filterType="TextFilter"
    autoSuggest={false}
    title="Zip Code"
    queryFormat="or"
    placeholder="Enter Zip Code"
    showFilter={true}
    filterLabel="Zip Code"
  />,
  <TextFilter
    searchOperators
    componentId="LocationsFilter"
    dataField={['selectors.locations']}
    elasticPath={['selectors.locations.keyword']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Locations"
    queryFormat="or"
    placeholder="Enter Location"
    showFilter={true}
    filterLabel="Locations"
  />,
  <TextFilter
    searchOperators
    componentId="DobFilterName"
    dataField={['selectors.dob']}
    elasticPath={['selectors.dob.keyword']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Date Of Birth"
    queryFormat="or"
    placeholder="Enter Date Of Birth"
    showFilter={true}
    filterLabel="Date Of Birth"
  />,
  <TextFilter
    searchOperators
    componentId="NameFilter"
    dataField={['name']}
    elasticPath={['name.keyword']}
    filterCategory="Profile"
    filterType="TextFilter"
    autoSuggest={false}
    title="Name"
    queryFormat="or"
    placeholder="Enter Name"
    showFilter={true}
    filterLabel="Name"
  />,
  <TextFilter
    searchOperators
    componentId="SelectorNameFilter"
    dataField={['selectors.names']}
    elasticPath={['selectors.names.keyword']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Name"
    queryFormat="or"
    placeholder="Enter Name"
    showFilter={true}
    filterLabel="Name"
  />,
  <TextFilter
    searchOperators
    componentId="SelectorPhoneNumberFilter"
    dataField={['selectors.phoneNumbers']}
    elasticPath={['selectors.phoneNumbers.keyword']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="Phone Number"
    queryFormat="or"
    placeholder="Enter Phone Number"
    showFilter={true}
    filterLabel="Phone Number"
  />,
  <TextFilter
    searchOperators
    componentId="PostLinksFilter"
    dataField={['selectors.postLinks']}
    elasticPath={['selectors.postLinks.keyword']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="PostLinks"
    queryFormat="or"
    placeholder="Enter Post Links"
    showFilter={true}
    filterLabel="Post Links"
  />,
  <TextFilter
    searchOperators
    componentId="SocialMediaFilter"
    dataField={['selectors.socialMedia']}
    elasticPath={['selectors.socialMedia.keyword']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="Social Media"
    queryFormat="or"
    placeholder="Enter Social Media"
    showFilter={true}
    filterLabel="Social Media"
  />,
  <TextFilter
    searchOperators
    componentId="FromWalletIdFilter"
    dataField={['fromWalletId']}
    elasticPath={['fromWalletId.keyword']}
    filterCategory="Transaction"
    filterType="TextFilter"
    autoSuggest={false}
    title="From Wallet ID"
    queryFormat="or"
    placeholder="Enter From Wallet ID"
    showFilter={true}
    filterLabel="From Wallet ID"
  />,
  <TextFilter
    searchOperators
    componentId="ToWalletIdFilter"
    dataField={['toWalletId']}
    elasticPath={['toWalletId.keyword']}
    filterCategory="Transaction"
    filterType="TextFilter"
    autoSuggest={false}
    title="To Wallet ID"
    queryFormat="or"
    placeholder="Enter To Wallet ID"
    showFilter={true}
    filterLabel="To Wallet ID"
  />,
  <TextFilter
    searchOperators
    componentId="BuyerIdFilter"
    dataField={['buyerId']}
    elasticPath={['buyerId.keyword']}
    filterCategory="Transaction"
    filterType="TextFilter"
    autoSuggest={false}
    title="Buyer ID"
    queryFormat="or"
    placeholder="Enter Buyer ID"
    showFilter={true}
    filterLabel="Buyer ID"
  />,
  <TextFilter
    searchOperators
    componentId="ToWalletLabelFilter"
    dataField={['toWalletLabel']}
    elasticPath={['toWalletLabel.keyword']}
    filterCategory="To Wallet Label"
    filterType="TextFilter"
    autoSuggest={false}
    title="To Wallet Label"
    queryFormat="or"
    placeholder="Enter To Wallet Label"
    showFilter={true}
    filterLabel="To Wallet Label"
  />,
  <TextFilter
    searchOperators
    componentId="FromWalletLabelFilter"
    dataField={['fromWalletLabel']}
    elasticPath={['fromWalletLabel.keyword']}
    filterCategory="From Wallet Label"
    filterType="TextFilter"
    autoSuggest={false}
    title="From Wallet Label"
    queryFormat="or"
    placeholder="Enter From Wallet Label"
    showFilter={true}
    filterLabel="From Wallet Label"
  />,
  <TextFilter
    searchOperators
    componentId="SellerIdFilter"
    dataField={['sellerId']}
    elasticPath={['sellerId.keyword']}
    filterCategory="Transaction"
    filterType="TextFilter"
    autoSuggest={false}
    title="Seller ID"
    queryFormat="or"
    placeholder="Enter Seller ID"
    showFilter={true}
    filterLabel="Reciever Seller ID"
  />,
  <TextFilter
    searchOperators
    componentId="SelectorUserNameFilter"
    dataField={['selectors.usernames']}
    elasticPath={['selectors.usernames.keyword']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Username"
    queryFormat="and"
    placeholder="Enter Username"
    showFilter={true}
    filterLabel="Username"
  />,
  <TextFilter
    searchOperators
    componentId="PageLinkFilter"
    dataField={['selectors.pageLinks']}
    elasticPath={['selectors.pageLinks.keyword']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Page Links"
    queryFormat="and"
    placeholder="Enter Page Links"
    showFilter={true}
    filterLabel="Page Links"
  />,
  <TextFilter
    searchOperators
    componentId="TransactionIdFilter"
    dataField={['transactionId']}
    elasticPath={['transactionId']}
    filterCategory="Transaction"
    filterType="TextFilter"
    autoSuggest={false}
    title="Transaction ID"
    queryFormat="or"
    placeholder="Enter Transaction ID"
    showFilter={true}
    filterLabel="Transaction ID"
  />,
  <TextFilter
    searchOperators
    componentId="BuyerUsernameFilter"
    dataField={['buyerUsername']}
    elasticPath={['buyerUsername.keyword']}
    filterCategory="Transaction"
    filterType="TextFilter"
    autoSuggest={false}
    title="Buyer Username"
    queryFormat="or"
    placeholder="Enter Buyer Username"
    showFilter={true}
    filterLabel="Buyer Username"
  />,
  <TextFilter
    searchOperators
    componentId="sellerUsernameFilter"
    dataField={['sellerUsername']}
    elasticPath={['sellerUsername.keyword']}
    filterCategory="Transaction"
    filterType="TextFilter"
    autoSuggest={false}
    title="Seller Username"
    queryFormat="or"
    placeholder="Enter Seller Username"
    showFilter={true}
    filterLabel="Seller Username"
  />,
  <TextFilter
    searchOperators
    componentId="DescriptionFilter"
    dataField={['description']}
    elasticPath={['description.keyword']}
    filterCategory="Transaction"
    filterType="TextFilter"
    autoSuggest={false}
    title="Description"
    queryFormat="or"
    placeholder="Enter Description"
    showFilter={true}
    filterLabel="Description"
  />,
  <TextFilter
    searchOperators
    componentId="LBCLanguageFilter"
    dataField={['selectors.languages']}
    elasticPath={['selectors.languages.keyword']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Languages"
    queryFormat="and"
    placeholder="Enter Language Code "
    showFilter={true}
    filterLabel="Languages"
  />,
  <TextFilter
    searchOperators
    componentId="TimezoneFilter"
    dataField={['selectors.timezones']}
    elasticPath={['selectors.timezones.keyword']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Timezones"
    queryFormat="or"
    placeholder="Enter Time Zone"
    showFilter={true}
    filterLabel="Time Zones"
  />,
  <TextFilter
    searchOperators
    componentId="PageLinksFilter"
    dataField={['selectors.pageLinks.keyword']}
    elasticPath={['selectors.pageLinks']}
    filterCategory="LBC"
    filterType="TextFilter"
    autoSuggest={false}
    title="Page Links"
    queryFormat="or"
    placeholder="Enter Page Address"
    showFilter={true}
    filterLabel="Page Links"
  />,
  <TextFilter
    searchOperators
    componentId="UserIdFilter"
    dataField={['userId']}
    elasticPath={['userId.keyword']}
    title="User ID"
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="and"
    placeholder="Enter User ID"
    showFilter={true}
    filterLabel="User ID"
  />,
  <TextFilter
    searchOperators
    componentId="ProductNameFilter"
    dataField={['productName']}
    elasticPath={['productName']}
    title="Product Name"
    filterCategory="Product"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="or"
    placeholder="Enter Product Name"
    showFilter={true}
    filterLabel="Product Name"
  />,
  <TextFilter
    searchOperators
    componentId="ProductIDFilter"
    dataField={['productId']}
    elasticPath={['productId']}
    filterCategory="Product"
    filterType="TextFilter"
    autoSuggest={false}
    title="Product ID"
    queryFormat="and"
    placeholder="Enter Product IDs"
    showFilter={true}
    filterLabel="Product IDs"
  />,
  <TextFilter
    searchOperators
    componentId="ProdDescFilter"
    dataField={['productDescription']}
    elasticPath={['productDescription']}
    filterCategory="Product"
    filterType="TextFilter"
    autoSuggest={false}
    title="Product Descriptions"
    queryFormat="or"
    placeholder="Enter Product Description"
    showFilter={true}
    filterLabel="Product Description"
  />,
  <TextFilter
    searchOperators
    componentId="EthereumAddressesFilter"
    dataField={['ethereumAddresses']}
    elasticPath={['ethereumAddresses']}
    title="Ethereum Addresses Type"
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="and"
    placeholder="Enter Ethereum Addresses"
    showFilter={true}
    filterLabel="Ethereum Addresses"
  />,
  <TextFilter
    searchOperators
    componentId="MoneroAddressesFilter"
    dataField={['moneroAddress']}
    elasticPath={['moneroAddress']}
    title="Monero Addresses Type"
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="and"
    placeholder="Enter Monero Addresses"
    showFilter={true}
    filterLabel="Monero Addresses"
  />,
  <TextFilter
    searchOperators
    componentId="LitecoinAddressesFilter"
    dataField={['litecoinAddresses']}
    elasticPath={['litecoinAddresses']}
    title="Litecoin Addresses"
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="and"
    placeholder="Enter Litecoin Addresses"
    showFilter={true}
    filterLabel="Litecoin Addresses"
  />,
  <TextFilter
    searchOperators
    componentId="UserFilter"
    dataField={['user']}
    elasticPath={['user']}
    title="User"
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="and"
    placeholder="Enter User"
    showFilter={true}
    filterLabel="User"
  />,
  <TextFilter
    searchOperators
    componentId="UserTypeFilter"
    dataField={['userType']}
    elasticPath={['userType']}
    title="User Type"
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="and"
    placeholder="Enter User Type"
    showFilter={true}
    filterLabel="User Type"
  />,
  <TextFilter
    searchOperators
    componentId="ProfileDrugContentFilter"
    dataField={['ai.content.drugs']}
    elasticPath={['ai.content.drugs']}
    title="Profile Drug Content"
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="or"
    placeholder="Enter Profile Drug Content"
    showFilter={true}
    filterLabel="Profile Drug Content"
  />,
  <TextFilter
    searchOperators
    componentId="VendorNameFilter"
    dataField={['vendorName']}
    elasticPath={['vendorName']}
    title="Vendor Names"
    filterCategory="Vendor"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="and"
    placeholder="Enter a Vendor Name"
    showFilter={true}
    filterLabel="Vendor Name"
  />,
  <TextFilter
    searchOperators
    componentId="VendorIdFilter"
    dataField={['vendorId']}
    elasticPath={['vendorId']}
    title="Vendor ID"
    searchOperators={true}
    filterCategory="Vendor"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="and"
    placeholder="Enter a Vendor ID"
    showFilter={true}
    filterLabel="Vendor ID"
  />,
  <TextFilter
    searchOperators
    componentId="NameFilter"
    dataField={['name']}
    elasticPath={['name']}
    filterCategory="User"
    filterType="TextFilter"
    autoSuggest={false}
    title="Profile Name"
    queryFormat="or"
    placeholder="Enter profile Name"
    showFilter={true}
    filterLabel="Profile Name"
  />,
  <TextFilter
    searchOperators
    componentId="ProductTypeFilter"
    dataField={['productType']}
    elasticPath={['productType']}
    filterCategory="Product"
    filterType="TextFilter"
    autoSuggest={false}
    title="Product Type"
    queryFormat="and"
    placeholder="Enter Product Category"
    showFilter={true}
    filterLabel="Product Category"
  />,
  <TextFilter
    searchOperators
    componentId="PgpFingerPrintsFilter"
    dataField={['pgp.fingerprints']}
    elasticPath={['pgp.fingerprints']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="PGP Finger Prints"
    queryFormat="and"
    placeholder="Enter PGP Fingerprints"
    showFilter={true}
    filterLabel="PGP Fingerprints"
  />,
  <TextFilter
    searchOperators
    componentId="PgpEmailsFilter"
    dataField={['pgp.identities.emails']}
    elasticPath={['pgp.identities.emails']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="PGP Emails"
    queryFormat="and"
    placeholder="Enter PGP Emails"
    showFilter={true}
    filterLabel="PGP Emails"
  />,
  <TextFilter
    searchOperators
    componentId="PgpNamesFilter"
    dataField={['pgp.identities.names']}
    elasticPath={['pgp.identities.names']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="PGP Names"
    queryFormat="and"
    placeholder="Enter PGP Names"
    showFilter={true}
    filterLabel="PGP Names"
  />,
  <TextFilter
    searchOperators
    componentId="ShipToFilter"
    dataField={['shipTo']}
    elasticPath={['shipTo']}
    filterCategory="Vendor"
    filterType="TextFilter"
    autoSuggest={false}
    title="Ship To"
    queryFormat="or"
    placeholder="Enter Shipping Origin Locations"
    showFilter={true}
    filterLabel="Shipping To Origins "
  />,
  <TextFilter
    searchOperators
    componentId="ShipFromFilter"
    dataField={['shipFrom']}
    elasticPath={['shipFrom']}
    filterCategory="Vendor"
    filterType="TextFilter"
    autoSuggest={false}
    title="Ship From"
    queryFormat="or"
    placeholder="Enter Shipping Origin Locations"
    showFilter={true}
    filterLabel="Shipping From Origins"
  />,
  <TextFilter
    searchOperators
    componentId="PageFilter"
    dataField={['page']}
    elasticPath={['page']}
    title="Pages"
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="and"
    placeholder="Enter Page URL"
    showFilter={true}
    filterLabel="Page"
  />,
  <TextFilter
    searchOperators
    componentId="ReviewFilter"
    dataField={['review']}
    elasticPath={['review']}
    filterCategory="Vendor"
    filterType="TextFilter"
    autoSuggest={false}
    title="Review"
    queryFormat="or"
    placeholder="Enter Review Terms"
    showFilter={true}
    filterLabel="Review Content"
  />,
  <TextFilter
    searchOperators
    componentId="RatingFilter"
    dataField={['rating']}
    elasticPath={['rating']}
    filterCategory="Vendor"
    filterType="TextFilter"
    autoSuggest={false}
    title="Rating"
    queryFormat="or"
    placeholder="Enter A Rating"
    showFilter={true}
    filterLabel="Rating"
  />,
  <TextFilter
    searchOperators
    componentId="VendorInfoFilter"
    dataField={['vendorInfo']}
    elasticPath={['vendorInfo']}
    filterCategory="Vendor"
    filterType="TextFilter"
    autoSuggest={false}
    title="Vendor Information"
    queryFormat="or"
    placeholder="Enter Vendor Information"
    showFilter={true}
    filterLabel="Vendor Info"
  />,
  <TextFilter
    searchOperators
    componentId="ThreadIDFilter"
    dataField={['threadId']}
    elasticPath={['threadId']}
    filterCategory="Posts"
    filterType="TextFilter"
    autoSuggest={false}
    title="Thread ID"
    queryFormat="and"
    placeholder="Enter Thread ID #"
    showFilter={true}
    filterLabel="Thread IDs"
  />,
  <TextFilter
    searchOperators
    componentId="TitleFilter"
    dataField={['title']}
    elasticPath={['title']}
    filterCategory="Posts"
    filterType="TextFilter"
    autoSuggest={false}
    title="Title Filter"
    queryFormat="and"
    placeholder="Enter Post Title"
    showFilter={true}
    filterLabel="Post Titles"
    style={{ position: 'relative', zIndex: 'auto' }}
  />,
  <TextFilter
    searchOperators
    componentId="PostIDFilter"
    dataField={['postId']}
    elasticPath={['postId']}
    filterCategory="Posts"
    filterType="TextFilter"
    autoSuggest={false}
    title="Post ID"
    sortBy="asc"
    queryFormat="and"
    placeholder="Enter Post ID #"
    showFilter={true}
    filterLabel="Post IDs"
  />,
  <TextFilter
    searchOperators
    componentId="PostOriginFilter"
    dataField={['countryName']}
    elasticPath={['countryName']}
    filterCategory="Posts"
    filterType="TextFilter"
    autoSuggest={false}
    title="Post Origin"
    queryFormat="or"
    placeholder="Enter Origin Location"
    showFilter={true}
    filterLabel="Post Origins"
  />,
  <TextFilter
    searchOperators
    componentId="AuthorFilter"
    dataField={['author']}
    elasticPath={['author']}
    title="Authors"
    filterCategory="Posts"
    filterType="TextFilter"
    autoSuggest={false}
    queryFormat="and"
    placeholder="Enter Author Moniker"
    showFilter={true}
    filterLabel="Authors"
  />,
  <TextFilter
    searchOperators
    componentId="AuthorDescriptionFilter"
    dataField={['description']}
    elasticPath={['description']}
    filterCategory="User"
    filterType="TextFilter"
    autoSuggest={false}
    title="Authors' Description"
    queryFormat="or"
    placeholder="Select Author Description"
    showFilter={true}
    filterLabel="Author Description"
  />,
  <TextFilter
    searchOperators
    componentId="RoleFilter"
    dataField={['role']}
    elasticPath={['role']}
    filterCategory="User"
    filterType="TextFilter"
    autoSuggest={false}
    title="User Roles"
    queryFormat="and"
    placeholder="Select User Role"
    showFilter={true}
    filterLabel="Role"
  />,
  <TextFilter
    searchOperators
    componentId="PgpKeyFilter"
    dataField={['pgp.armoredKeys']}
    elasticPath={['pgp.armoredKeys']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Selectors"
    title="Pgp Keys"
    queryFormat="and"
    placeholder="Select PGP Key"
    showFilter={true}
    filterLabel="PGP Keys"
  />,
  <TextFilter
    searchOperators
    componentId="FileNameFilter"
    dataField={['fileName']}
    elasticPath={['fileName']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="File Name"
    queryFormat="or"
    placeholder="File Name"
    showFilter={true}
    filterLabel="File Name"
  />,
  <TextFilter
    searchOperators
    componentId="FileTypeFilter"
    dataField={['fileType']}
    elasticPath={['fileType']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="File Type"
    queryFormat="or"
    placeholder="File Type"
    showFilter={true}
    filterLabel="File Type"
  />,
  <TextFilter
    searchOperators
    componentId="DocSourcePageFilter"
    dataField={['doc.sourcePage']}
    elasticPath={['doc.sourcePage']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="Source Page"
    queryFormat="or"
    placeholder="Source Page"
    showFilter={true}
    filterLabel="Source Page"
  />,
  <TextFilter
    searchOperators
    componentId="LinksFilter"
    dataField={['links']}
    elasticPath={['links']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="Links"
    queryFormat="or"
    placeholder="Links"
    showFilter={true}
    filterLabel="Links"
  />,
  <TextFilter
    searchOperators
    componentId="IpAddressesFilter"
    dataField={['ipAddresses']}
    elasticPath={['ipAddresses']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="IpAddresses"
    queryFormat="or"
    placeholder="IpAddresses"
    showFilter={true}
    filterLabel="IpAddresses"
  />,
  <TextFilter
    searchOperators
    componentId="PhoneNumbersFilter"
    dataField={['phoneNumbers']}
    elasticPath={['phoneNumbers']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="PhoneNumbers"
    queryFormat="or"
    placeholder="PhoneNumbers"
    showFilter={true}
    filterLabel="PhoneNumbers"
  />,
  <TextFilter
    searchOperators
    componentId="PageTitleFilter"
    dataField={['pageTitle']}
    elasticPath={['pageTitle']}
    filterCategory="Site"
    filterType="TextFilter"
    autoSuggest={false}
    title="Page Title"
    queryFormat="or"
    placeholder="Enter Page Title"
    showFilter={true}
    filterLabel="Page Title"
  />,
  <TextFilter
    searchOperators
    componentId="HiddenServiceFilter"
    dataField={['hiddenService']}
    elasticPath={['hiddenService']}
    filterCategory="General"
    filterType="TextFilter"
    autoSuggest={false}
    title="Hidden Service"
    queryFormat="and"
    placeholder="Enter Site URL"
    showFilter={true}
    filterLabel="Hidden Service"
  />,
  <ToggleFilter
    componentId="FirstSnapshotFilter"
    dataField="ai.site.firstSnapshot"
    elasticPath={['ai.site.firstSnapshot']}
    groupName={'Toggle Filters'}
    title="First Snapshot"
    filterCategory="Site"
    filterType="ToggleFilter"
    queryFormat="or"
    showFilter={true}
    customQuery={(props) => altBooleanQuery('ai.site.firstSnapshot', props)}
    defaultQuery={(props) => altBooleanQuery('ai.site.firstSnapshot', props)}
    filterLabel="First Snapshot"
    sidebarFilter
  />,
  <ToggleFilter
    componentId="ClassifiedFilter"
    dataField="classified"
    elasticPath={['classified']}
    groupName={'Toggle Filters'}
    title="Classified"
    filterCategory="General"
    filterType="ToggleFilter"
    queryFormat="or"
    showFilter={true}
    customQuery={(props) => altBooleanQuery('classified', props)}
    defaultQuery={(props) => altBooleanQuery('classified', props)}
    filterLabel="Classified"
    sidebarFilter
  />,
  <ToggleFilter
    componentId="BitcoinAddressesToggle"
    dataField={['bitcoinAddresses']}
    elasticPath={['bitcoinAddresses']}
    groupName={'Toggle Filters'}
    filterCategory="General"
    filterType="ToggleFilter"
    title="Bitcoin Addresses"
    queryFormat="or"
    showFilter={true}
    customQuery={(props) => requiredFieldsQuery(['bitcoinAddresses'])}
    filterLabel="Bitcoin Addresses"
    sidebarFilter
  />,
  <TextFilter
    searchOperators
    componentId="ToBitcoinAddressFilter"
    dataField={['toBitcoinAddress']}
    elasticPath={['toBitcoinAddress.keyword']}
    filterCategory="Transaction"
    filterType="TextFilter"
    autoSuggest={false}
    title="To Bitcoin Addresses"
    queryFormat="and"
    placeholder="Enter To BitcoinAddress"
    showFilter={true}
    filterLabel="To BitcoinAddress"
  />,
  <TextFilter
    searchOperators
    componentId="BitcoinAddressesFilter"
    dataField={['bitcoinAddresses']}
    elasticPath={['bitcoinAddresses']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="Bitcoin Addresses"
    queryFormat="and"
    placeholder="Enter Bitcoin Addresses"
    showFilter={true}
    filterLabel="Bitcoin Addresses"
  />,
  <TextFilter
    searchOperators
    componentId="EmailAddressesFilter"
    dataField={['emailAddresses']}
    elasticPath={['emailAddresses']}
    filterCategory="Selectors"
    filterType="TextFilter"
    autoSuggest={false}
    title="Email Address"
    queryFormat="and"
    placeholder="Enter Email Addresses"
    showFilter={true}
    filterLabel="Email Addresses"
  />,
  <TextFilter
    searchOperators
    componentId="TripCodeFilter"
    dataField={['tripCode']}
    elasticPath={['tripCode']}
    filterCategory="Post"
    filterType="TextFilter"
    autoSuggest={false}
    title="Trip Code"
    queryFormat="and"
    placeholder="Enter Trip Code"
    showFilter={true}
    filterLabel="Trip Code"
  />,
  <TextFilter
    searchOperators
    componentId="AnalyticsIdFilter"
    dataField={['analyticsIDs']}
    elasticPath={['analyticsIDs']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Selectors"
    title="Analytics ID's"
    queryFormat="and"
    placeholder="Enter Analytics ID"
    showFilter={true}
    filterLabel="Analytics IDs"
  />,
  <TextFilter
    searchOperators
    componentId="ServerFilter"
    dataField={['serverVersion']}
    elasticPath={['serverVersion']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Site"
    title="Server Version"
    queryFormat="or"
    placeholder="Enter Server Version"
    showFilter={true}
    filterLabel="Server Version"
  />,
  <TextFilter
    searchOperators
    componentId="PortsFilter"
    dataField={['openPorts']}
    elasticPath={['openPorts']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Site"
    title="Open Ports"
    queryFormat="or"
    placeholder="Enter Port or Service"
    showFilter={true}
    filterLabel="Open Ports"
  />,
  <TextFilter
    searchOperators
    componentId="ActiveServicesFilter"
    dataField={['activeServices']}
    elasticPath={['activeServices']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Site"
    title="Active Services"
    queryFormat="or"
    placeholder="Enter Service"
    showFilter={true}
    filterLabel="Active Services"
  />,
  <TextFilter
    searchOperators
    componentId="SSHFingerprintFilter"
    dataField={['crypto.ssh.fingerprint', 'ssh.fingerprint']}
    elasticPath={['crypto.ssh.fingerprint', 'ssh.fingerprint']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Site"
    title="SSH Fingerprint"
    queryFormat="and"
    placeholder="Enter SSH Fingerprint"
    showFilter={true}
    filterLabel="SSH Fingerprint"
  />,
  <TextFilter
    searchOperators
    componentId="SSHTypeFilter"
    dataField={['crypto.ssh.type', 'ssh.type']}
    elasticPath={['crypto.ssh.type', 'ssh.type']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Site"
    title="SSH Algorithm Type"
    queryFormat="and"
    placeholder="Enter SSH Algorithm"
    showFilter={true}
    filterLabel="SSH Algorithm Type"
  />,
  <TextFilter
    searchOperators
    componentId="SSHVersionFilter"
    dataField={['crypto.ssh.version', 'ssh.version']}
    elasticPath={['crypto.ssh.version', 'ssh.version']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Site"
    title="SSH Version"
    queryFormat="or"
    placeholder="Enter SSH Version"
    showFilter={true}
    filterLabel="SSH Version"
  />,
  <TextFilter
    searchOperators
    componentId="TLSSubjectIssuerFilter"
    dataField={['crypto.tls.issuer', 'crypto.tls.subject']}
    elasticPath={['crypto.tls.issuer', 'crypto.tls.subject']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Site"
    title="TLS Subject or Issuer"
    queryFormat="or"
    placeholder="Enter TLS Subject or Issuer"
    showFilter={true}
    filterLabel="TLS Subject or Issuer"
  />,
  <TextFilter
    searchOperators
    componentId="TLSVersionFilter"
    dataField={['crypto.tls.version']}
    elasticPath={['crypto.tls.version']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Site"
    title="TLS Version"
    queryFormat="and"
    placeholder="Enter TLS Version"
    showFilter={true}
    filterLabel="TLS Version"
  />,
  <TextFilter
    searchOperators
    componentId="TLSSignatureFilter"
    dataField={['crypto.tls.signature']}
    elasticPath={['crypto.tls.signature']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Site"
    title="TLS Signature"
    queryFormat="and"
    placeholder="Enter TLS Signature"
    showFilter={true}
    filterLabel="TLS Signature"
  />,
  <TextFilter
    searchOperators
    componentId="TLSFingerprintFilter"
    dataField={['crypto.tls.fingerprint']}
    elasticPath={['crypto.tls.fingerprint']}
    filterType="TextFilter"
    autoSuggest={false}
    filterCategory="Site"
    title="TLS Fingerprint"
    queryFormat="and"
    placeholder="Enter TLS Fingerprint"
    showFilter={true}
    filterLabel="TLS Fingerprint"
  />,
  <ToggleFilter
    componentId="JSDetectedFilter"
    groupName={'Toggle Filters'}
    dataField="jsDetected"
    elasticPath={['jsDetected']}
    title="Javascript Detected"
    filterCategory="Site"
    filterType="ToggleFilter"
    queryFormat="or"
    showFilter={true}
    customQuery={(props) => altBooleanQuery('jsDetected', props)}
    defaultQuery={(props) => altBooleanQuery('jsDetected', props)}
    filterLabel="Javascript Detected"
    sidebarFilter
  />,
  <ToggleFilter
    componentId="PrivateKeyFilter"
    dataField="privateKeyDetected"
    elasticPath={['privateKeyDetected']}
    groupName={'Toggle Filters'}
    title="Private Key Detected"
    filterCategory="Site"
    filterType="ToggleFilter"
    queryFormat="or"
    showFilter={true}
    customQuery={(props) => altBooleanQuery('privateKeyDetected', props)}
    defaultQuery={(props) => altBooleanQuery('privateKeyDetected', props)}
    filterLabel="Private Key Detected"
    sidebarFilter
  />,
  <ToggleFilter
    componentId="ApacheStatusFilter"
    dataField="foundApacheModStatus"
    elasticPath={['foundApacheModStatus']}
    groupName={'Toggle Filters'}
    title="Apache Status Detected"
    filterCategory="Site"
    filterType="ToggleFilter"
    queryFormat="or"
    showFilter={true}
    customQuery={(props) => altBooleanQuery('foundApacheModStatus', props)}
    defaultQuery={(props) => altBooleanQuery('foundApacheModStatus', props)}
    filterLabel="Apache Status Detected"
    sidebarFilter
  />,
  <TextFilter
    searchOperators
    componentId="ApacheServerhttpdConfFilter"
    dataField={['apacheServerInfo.httpdConf']}
    elasticPath={['apacheServerInfo.httpdConf']}
    filterCategory="Site"
    filterType="TextFilter"
    autoSuggest={false}
    title="Apache HTTPD Conf"
    queryFormat="and"
    placeholder="Enter Apache HTTPD Conf"
    showFilter={true}
    filterLabel="Apache HTTPD Conf"
  />,
  <TextFilter
    searchOperators
    componentId="ApacheServerhttpdInfoConfFilter"
    dataField={['apacheServerInfo.httpdInfoConf']}
    elasticPath={['apacheServerInfo.httpdInfoConf']}
    filterCategory="Site"
    filterType="TextFilter"
    autoSuggest={false}
    title="Apache Server HTTPD Info Conf"
    queryFormat="and"
    placeholder="Enter Apache Server HTTPD Info Conf"
    showFilter={true}
    filterLabel="Apache Server HTTPD Info Conf"
  />,
  <TextFilter
    searchOperators
    componentId="ApacheServerVHostsConfFilter"
    dataField={['apacheServerInfo.httpdVHostsConf']}
    elasticPath={['apacheServerInfo.httpdVHostsConf']}
    filterCategory="Site"
    filterType="TextFilter"
    autoSuggest={false}
    title="Apache Server VHosts Conf"
    queryFormat="and"
    placeholder="Enter Apache Server VHosts Conf"
    showFilter={true}
    filterLabel="Apache Server Vhosts Conf"
  />,
  <TextFilter
    searchOperators
    componentId="ApacheServerInfoFilter"
    dataField={['apacheServerInfo.server']}
    elasticPath={['apacheServerInfo.server']}
    filterCategory="Site"
    filterType="TextFilter"
    autoSuggest={false}
    title="Apache Server Info"
    queryFormat="and"
    placeholder="Enter Apache Server Info"
    showFilter={true}
    filterLabel="Apache Server Info"
  />,
];

const UniversalSelectorList = {
  markets: [],
  communications: [],
  profiles: [],
  sites: [],
  docs: [],
  lbc_users: [],
  lbc_messages: [],
  lbc_transactions: [],
  lbc_bulletin: [],
  get all() {
    return [
      ...new Set([
        ...this.markets,
        ...this.sites,
        ...this.profiles,
        ...this.communications,
        ...this.docs,
      ]),
    ];
  },
};

const AllFilterNames = (excludedFilterNames = []) => {
  const extraFilters = [
    'IndexFilter',
    'TimeFilter',
    'SearchboxComponent',
    'BulkSearchFilter',
    'RestrictedFieldsFilter',
  ];
  return [
    ...(excludedFilterNames.length === 0
      ? UniversalFilterComponentList.map((f) => {
          return f.props.componentId;
        })
      : UniversalFilterComponentList.filter(
          (item) => !excludedFilterNames.includes(item.props.componentId),
        ).map((f) => {
          return f.props.componentId;
        })),
    ...extraFilters,
  ];
};

const UniversalFilterNameList = {
  markets: AllFilterNames([
    'PrivateKeyFilter',
    'JSDetectedFilter',
    'ClassifiedFilter',
    'FirstSnapshotFilter',
    'ApacheStatusFilter',
    'MessageTypeFilter',
  ]),
  sites: AllFilterNames([
    'ClassifiedFilter',
    'BitcoinAddressesToggle',
    'MessageTypeFilter',
    'GeoCoordinatesFilter',
  ]),
  communications: AllFilterNames([
    'PrivateKeyFilter',
    'ClassifiedFilter',
    'JSDetectedFilter',
    'FirstSnapshotFilter',
    'ApacheStatusFilter',
    'ClassifiedFilter',
    'MessageTypeFilter',
  ]),
  profiles: AllFilterNames([
    'PrivateKeyFilter',
    'ClassifiedFilter',
    'JSDetectedFilter',
    'FirstSnapshotFilter',
    'ApacheStatusFilter',
    'MessageTypeFilter',
  ]),
  docs: AllFilterNames([
    'SiteNetFilter',
    'ContentTagsFilter',
    'FirstSnapshotFilter',
    'JSDetectedFilter',
    'PrivateKeyFilter',
    'DescriptionFilter',
    'HiddenServiceFilter',
    'ApacheStatusFilter',
    'MessageTypeFilter',
  ]),
  lbc_users: AllFilterNames([
    'FileNameFilter',
    'SiteNetFilter',
    'ClassifiedFilter',
    'ContentTagsFilter',
    'FirstSnapshotFilter',
    'JSDetectedFilter',
    'PrivateKeyFilter',
    'DescriptionFilter',
    'HiddenServiceFilter',
    'ApacheStatusFilter',
    'BitcoinAddressesToggle',
    'MessageTypeFilter',
    'GeoCoordinatesFilter',
  ]),
  lbc_messages: AllFilterNames([
    'FileNameFilter',
    'SiteNetFilter',
    'ClassifiedFilter',
    'ContentTagsFilter',
    'FirstSnapshotFilter',
    'JSDetectedFilter',
    'PrivateKeyFilter',
    'HiddenServiceFilter',
    'DescriptionFilter',
    'ApacheStatusFilter',
    'IntroductionFilter',
    'BitcoinAddressesToggle',
    'GeoCoordinatesFilter',
  ]),
  lbc_transactions: AllFilterNames([
    'FileNameFilter',
    'SiteNetFilter',
    'ClassifiedFilter',
    'ContentTagsFilter',
    'FirstSnapshotFilter',
    'JSDetectedFilter',
    'PrivateKeyFilter',
    'HiddenServiceFilter',
    'DescriptionFilter',
    'ApacheStatusFilter',
    'IntroductionFilter',
    'BitcoinAddressesToggle',
    'MessageTypeFilter',
    'GeoCoordinatesFilter',
  ]),
  lbc_bulletin: AllFilterNames([
    'FileNameFilter',
    'SiteNetFilter',
    'ClassifiedFilter',
    'ContentTagsFilter',
    'FirstSnapshotFilter',
    'JSDetectedFilter',
    'PrivateKeyFilter',
    'HiddenServiceFilter',
    'DescriptionFilter',
    'ApacheStatusFilter',
    'IntroductionFilter',
    'BitcoinAddressesToggle',
    'MessageTypeFilter',
    'GeoCoordinatesFilter',
  ]),
};

const today = new Date(
  dayjs()
    .hour(0)
    .minute(0)
    .second(0),
);
const p7 = new Date(
  dayjs()
    .subtract(7, 'days')
    .hour(0)
    .minute(0)
    .second(0),
);
const lbcAll = dayjs(new Date(1970, 0, 1))
  .hour(0)
  .minute(0)
  .toDate();

const UniversalDefaultFilterState = {
  markets: {
    TimeFilter: { start: p7, end: today },
    SearchboxComponent: '',
    BulkSearchFilter: '',
    IndexFilter: [],
  },
  communications: {
    TimeFilter: { start: p7, end: today },
    SearchboxComponent: '',
    BulkSearchFilter: '',
    IndexFilter: [],
  },
  profiles: {
    TimeFilter: { start: p7, end: today },
    SearchboxComponent: '',
    BulkSearchFilter: '',
    IndexFilter: [],
  },
  sites: {
    TimeFilter: { start: p7, end: today },
    SearchboxComponent: '',
    BulkSearchFilter: '',
    IndexFilter: [],
  },
  docs: {
    TimeFilter: { start: p7, end: today },
    SearchboxComponent: '',
    BulkSearchFilter: '',
  },
  lbc_users: {
    TimeFilter: {
      start: lbcAll,
      end: today,
    },
    SearchboxComponent: '',
    LBCIndexFilter: [],
    BulkSearchFilter: '',
  },
  lbc_messages: {
    TimeFilter: {
      start: lbcAll,
      end: today,
    },
    SearchboxComponent: '',
    LBCIndexFilter: [],
    BulkSearchFilter: '',
  },
  lbc_transactions: {
    TimeFilter: {
      start: lbcAll,
      end: today,
    },
    SearchboxComponent: '',
    LBCIndexFilter: [],
    BulkSearchFilter: '',
  },
  lbc_bulletin: {
    TimeFilter: {
      start: lbcAll,
      end: today,
    },
    SearchboxComponent: '',
    LBCIndexFilter: [],
    BulkSearchFilter: '',
  },

  get all() {
    return {
      ...this.markets,
      ...this.profiles,
      ...this.communications,
      ...this.sites,
      ...this.docs,
    };
  },
};

const getFilterKeywordMap = (filters) => {
  const getQueryType = (filterType) => {
    switch (filterType) {
      case 'MultiDropdownList':
        return 'terms';
      case 'SelectFilter':
        return 'terms';
      case 'TextFilter':
        return 'simple_query_string';
      case 'ToggleFilter':
        return 'term';
      default:
        return 'NOT DEFINED';
    }
  };

  const filterMap = filters.map((filter) => {
    return {
      [filter.props.componentId]: {
        path: filter.props.elasticPath,
        type: getQueryType(filter.props.filterType),
      },
    };
  });

  return filterMap.reduce((result, current) => {
    return { ...result, ...current };
  }, {});
};

const UniversalFilterKeywordMap = getFilterKeywordMap(
  UniversalFilterComponentList,
);

export {
  UniversalFilterNameList,
  UniversalFieldNameList,
  UniversalFilterComponentList,
  UniversalCategoryList,
  UniversalSelectorList,
  UniversalDefaultFilterState,
  UniversalFilterKeywordMap,
  AdvancedFilters,
  SidebarFilters,
  AllFilterNames,
  getSearchFields,
};
