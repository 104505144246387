import React from 'react';
import styled, { css } from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const ThemedShareIcon = styled(OpenInNewIcon)`
  color: ${(props) => props.theme.colors.primary} !important;
  vertical-align: middle;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    color: ${(props) => props.theme.colors.altPrimary} !important;
    cursor: pointer !important;
  }
`;

const tags = [
  {
    singular: true,
    indexTag: true,
    path: 'doc.type',
    tagLabel: 'Data Type',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
    filterName: 'DocFilter',
    icon: <i className="fas fa-bullhorn" style={{ fontSize: '20px' }}></i>,
  },
  {
    singular: true,
    path: 'country',
    tagLabel: 'Country',
    filterName: 'CountryFilter',
    filterValueType: 'array',
    elasticPath: ['country.keyword'],
  },
  {
    singular: true,
    path: 'city',
    tagLabel: 'City',
    filterName: 'CityFilter',
    filterValueType: 'array',
    elasticPath: ['city.keyword'],
  },
  {
    singular: true,
    path: 'tradeType',
    tagLabel: 'Trade Type',
    filterName: 'TradeTypeFilter',
    filterValueType: 'array',
    elasticPath: ['tradeType.keyword'],
  },
  {
    singular: true,
    path: 'requirements',
    tagLabel: 'Requirements',
    filterName: 'RequirementsFilter',
    filterValueType: 'array',
    elasticPath: ['requirements.keyword'],
  },
];

const earmarks = [
  {
    title: 'Created At',
    path: 'createdAt',
  },
];

const title = {
  title: 'Username',
  path: 'username',
  redirectTo: '/search/data',
  redirectLabel: 'Data',
  filterName: 'UserNameFilter',
  filterValueType: 'array',
  elasticPath: ['username.keyword'],
};

const body = [
  {
    title: 'Messsage',
    path: 'message',
    filterName: 'MessageFilter',
    filterValueType: 'array',
    elasticPath: ['message.keyword'],
  },
  {
    title: 'Account Info',
    path: 'accountInfo',
    filterName: 'AccountInfoFilter',
    filterValueType: 'array',
    elasticPath: ['accountInfo.keyword'],
  },
  {
    title: 'User Id',
    path: 'userId',
    filterName: 'UserIdFilter',
    filterValueType: 'array',
    elasticPath: ['userId.keyword'],
    icon: <ThemedShareIcon />,
  },
];

const selectors = [
  'socialMedia',
  'postLinks',
  'phoneNumbers',
  'ipAddresses',
  'emailAddresses',
  'bitcoinAddresses',
];

const footers = [
  {
    title: 'Minimum Amount',
    path: 'minAmount',
  },
  {
    title: 'Maxium Amount',
    path: 'maxAmount',
  },
  {
    title: 'Currency',
    path: 'currency',
    filterName: 'CurrencyFilter',
    filterValueType: 'array',
    elasticPath: ['currency.keyword'],
  },
  {
    title: 'Temp Price',
    path: 'tempPrice',
  },
  {
    title: 'Email Address',
    path: 'selectors.emailAddresses',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'EmailAddressesFilter',
    filterValueType: 'array',
    elasticPath: ['selectors.emailAddresses.keyword'],
  },
  {
    title: 'Online Provider',
    path: 'onlineProvider',
    filterName: 'OnlineProviderFilter',
    filterValueType: 'array',
    elasticPath: ['onlineProvider.keyword'],
  },
];

export { tags, title, body, footers, earmarks, selectors };
