import React from 'react';
import styled from 'styled-components';

const Sidebar = styled.div`
  width: 180px;
  background-color: ${(props) => props.theme.colors.secondarySidebar};
  overflow-y: auto;
  margin-top: 70px;
  height: 100%;
`;

const SidebarContainer = styled.div`
  padding: ${(props) =>
    props.second ? '15px 24px 24px 24px' : '70px 24px 24px 24px'};
`;

const Category = styled.h3`
  color: ${(props) => props.theme.colors.primary};
  size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin: 13px 0;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const FAQ = styled(Category)`
  size: 12px;
`;

const Border = styled.hr`
  border: 1px solid;
  color: ${(props) => props.theme.colors.cloud};
  height: 1px;
  opacity: 0.3;
`;

const BreakBorder = styled.hr`
  border 4px solid ${(props) => props.theme.colors.mainBackground};
`;

const categories = [
  'How To',
  'Getting Started',
  'Dark Nets',
  'Data Type',
  'Intelligence Products',
  'Tools',
  'LEO Resources',
  'Help',
  'Terminology',
];

const FAQS = [
  {
    title: 'DarkBlue FAQ',
    doc_type: 'markdown',
    location: 'DarkBlue FAQ.md',
  },
  {
    title: 'DarkPursuit FAQ',
    doc_type: 'markdown',
    location: 'DarkPursuit FAQ.md',
  },
  {
    title: 'Data FAQ',
    doc_type: 'markdown',
    location: 'Data FAQ.md',
  },
];

const ResourcesSidebar = ({
  expanded,
  setFilterState,
  filterState,
  setDoc,
}) => {
  const addTagToFilter = (tag) => {
    setDoc();
    setFilterState({
      ...filterState,
      ...{ Tags: [tag] },
    });
  };

  const handleDocChange = (faq) => {
    setDoc(faq);
  };

  return (
    <Sidebar expanded={expanded}>
      <SidebarContainer>
        <p className="bold xs">CATEGORIES</p>
        {categories.map((c, i) => {
          return (
            <Category onClick={() => addTagToFilter(c)} key={c}>
              {c}
            </Category>
          );
        })}
      </SidebarContainer>
      <BreakBorder />
      <SidebarContainer second>
        <p className="xs bold">FAQ</p>
        {FAQS.map((faq, i) => {
          return (
            <div key={faq.location}>
              <FAQ onClick={() => handleDocChange(faq)}>{faq.title}</FAQ>
              {i < FAQS.length - 1 ? <Border /> : null}
            </div>
          );
        })}
      </SidebarContainer>
    </Sidebar>
  );
};

export default ResourcesSidebar;
