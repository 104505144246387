import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

//@material-ui/core components
import Paper from '@material-ui/core/Paper';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';

//@darkblue-ui/ components
import useLocalStorage from '../../utils/Hooks/SetLocalStorage';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../darkblue-ui/Button/Button';

const AlertContainer = styled(Paper)`
  border: solid;
  background-color: ${(props) => props.theme.colors.cardBackground} !important;
  border-color: ${(props) => {
    return props.severity === 'success'
      ? props.theme.colors.success
      : props.severity === 'warning'
      ? props.theme.colors.warning
      : props.severity === 'danger'
      ? props.theme.colors.danger
      : props.theme.colors.success;
  }} !important;

  p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 0px;
    letter-spacing: 0.01071em;
  }
`;

const AlertIconContainer = styled.div`
  margin: 10px 25px;
  display: flex;
  opacity: 0.9;
  font-size: 25px;
  margin-right: 12px;
  color: ${(props) => {
    switch (props.severity) {
      case 'success':
        return props.theme.colors.alertIconSuccess;
        break;
      case 'warning':
        return props.theme.colors.alertIconWarning;
        break;
      case 'danger':
        return props.theme.colors.alertIconDanger;
        break;
      default:
        return props.theme.colors.alertIconSuccess;
        break;
    }
  }};
`;

/**
 * @Component Alert
 * Renders: An alert container
 * @param  {object} props
 *  severity - The type of alert {success | danger | warning | infomational}
 *  iconType - The icon type based on the type of alert.
 *  persistAlertOpenState - Determines whether or not to persist state to local storage.
 *  showDismissable - Determines whether or not to show the close button.
 */
export default function Alert(props) {
  const [openAlert, setOpenAlert] = useLocalStorage('openAlert', null);

  const openAlertComponent = () => {
    if (openAlert !== null) {
      return JSON.parse(openAlert);
    } else {
      return true;
    }
  };

  const { severity, iconType, persistAlertOpenState, showDismissable } = props;
  const [open, setOpen] = useState(openAlertComponent);

  useEffect(() => {
    if (persistAlertOpenState) {
      setOpenAlert(open);
    }
  }, [open]);

  return (
    <div>
      <Collapse in={open}>
        <AlertContainer severity={severity} elevation={1}>
          <GridContainer direction="row">
            <AlertIconContainer severity={severity} role="alert">
              {iconMapping[iconType]}
            </AlertIconContainer>
            <GridItem xs={9}>
              <p style={{ fontWeight: 900 }}>{props.children} </p>
            </GridItem>
            <GridItem style={{ marginTop: '13px' }}>
              <Button
                style={{ float: 'right' }}
                justIcon
                color="inherit"
                hideBackground
                onClick={() => {
                  setOpen(false);
                }}
              >
                {showDismissable && (
                  <CloseIcon style={{ color: 'black' }} fontSize="inherit" />
                )}
              </Button>
            </GridItem>
          </GridContainer>
        </AlertContainer>
      </Collapse>
    </div>
  );
}

//Component default props.
Alert.defaultProps = {
  severity: 'success',
  iconType: 'success',
  persistAlertOpenState: false,
  showDismissable: false,
};

//Props value types specfication.
Alert.propTypes = {
  severity: PropTypes.string,
  iconType: PropTypes.string,
  showDismissable: PropTypes.bool,
  persistAlertOpenState: PropTypes.bool,
  persistAlertOpenState: function(props, propName, componentName) {
    if (props.persistAlertOpenState && !props.showDismissable) {
      return new Error(
        propName +
          ' works best when showDismissable prop is supplied to ' +
          componentName +
          ' component',
      );
    }
  },
};

//The Icon type mapping specification.
const iconMapping = {
  success: <CheckCircleOutlineOutlinedIcon fontSize="inherit" />,
  warning: <ReportProblemOutlinedIcon fontSize="inherit" />,
  danger: <ErrorOutlinedIcon fontSize="inherit" />,
  info: <InfoOutlinedIcon fontSize="inherit" />,
};
