const terms = [
  { path: '_id' },
  {
    path: 'doc.type',
    filterName: 'DocFilter',
    elasticPath: ['doc.type.keyword'],
    filterValueType: 'array',
    sortKey: 1,
  },
  {
    path: 'doc.idHash',
    filterName: 'DocIdFilter',
    elasticPath: ['doc.idHash.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'btcAmount',
    sortKey: 2,
  },
  {
    path: 'description',
    filterName: 'DescriptionFilter',
    elasticPath: ['description.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'buyerId',
    sortKey: 7,
    filterName: 'BuyerIdFilter',
    elasticPath: ['buyerId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'buyerUsername',
    filterName: 'BuyerUsernameFilter',
    elasticPath: ['buyerUsername.keyword'],
    filterValueType: 'array',
    sortKey: 3,
  },
  {
    path: 'sellerUsername',
    filterName: 'SellerUsernameFilter',
    elasticPath: ['sellerUsername.keyword'],
    filterValueType: 'array',
    sortKey: 4,
  },
  {
    path: 'userIds',
    filterName: 'UserIdsFilter',
    filterValueType: 'array',
    sortKey: 8,
    elasticPath: ['userIds.keywords'],
  },
  {
    path: 'sellerId',
    sortKey: 6,
    filterName: 'SellerIdFilter',
    elasticPath: ['sellerId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'fromWalletId',
    filterName: 'FromWalletIdFilter',
    elasticPath: ['fromWalletId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.ipAddresses',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'IpAddressFilter',
    elasticPath: ['selectors.ipAddresses.keyword'],
    filterValueType: 'array',
    sortKey: 5,
  },
  {
    path: 'selectors.phoneNumbers',
    filterName: 'SelectorPhoneNumberFilter',
    elasticPath: ['selectors.phoneNumbers.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.bitcoinAddresses',
    filterName: 'SelectorBitcoinAddressesFilter',
    elasticPath: ['selectors.bitcoinAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.emailAddresses',
    filterName: 'SelectorEmailAddressesFilter',
    elasticPath: ['selectors.emailAddresses.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.pgp.armoredKeys',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.armoredKeys.keyword'],
  },
  {
    path: 'selectors.pgp.created',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
  },
  {
    path: 'selectors.pgp.fingerprints',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.fingerprints.keyword'],
  },
  {
    path: 'selectors.pgp.identities.comments',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.identities.comments.keyword'],
  },
  {
    path: 'selectors.pgp.identities.emails',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.identities.emails.keyword'],
  },
  {
    path: 'selectors.pgp.identities.is_primary',
  },
  {
    path: 'selectors.pgp.identities.names',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    elasticPath: ['selectors.pgp.identities.names.keyword'],
  },
  {
    path: 'selectors.pgp.identities.signers',
    elasticPath: ['selectors.pgp.identities.signers.keyword'],
  },
  {
    path: 'selectors.postLinks',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'PostLinksFilter',
    elasticPath: ['selectors.postLinks.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'selectors.socialMedia',
    redirectTo: '/search/data',
    redirectLabel: 'Data',
    filterName: 'SocialMediaFilter',
    elasticPath: ['selectors.socialMedia.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'toBitcoinAddress',
    filterName: 'ToBitcoinAddressFilter',
    elasticPath: ['toBitcoinAddress.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'toWalletLabel',
    filterName: 'ToWalletLabelFilter',
    elasticPath: ['toWalletLabel.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'fromWalletLabel',
    filterName: 'FromWalletLabelFilter',
    elasticPath: ['fromWalletLabel.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'toWalletId',
    filterName: 'ToWalletIdFilter',
    elasticPath: ['toWalletId.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'transactionCategory',
    filterName: 'TransactionCategoryFilter',
    elasticPath: ['transactionCategory.keyword'],
    filterValueType: 'array',
  },
  {
    path: 'transactionId',
    filterName: 'TransactionIdFilter',
    elasticPath: ['transactionId.keyword'],
    filterValueType: 'array',
  },
  { path: 'timestamp' },
];

export { terms };
