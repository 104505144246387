import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import DomPurify from 'dompurify';

const htmlEscape = (str) => {
  if (str !== null && str && String(str)) {
    const highlightedString = String(str)
      .replace(/</g, ' &lt;')
      .replace(/&lt;mark>/g, ' <mark>')
      .replace(/&lt;\/mark>/g, '</mark>');
    return highlightedString;
  } else {
    return str;
  }
};

const HighlightComponent = ({ text = '' }) => {
  const sanitizedText = htmlEscape(text);
  return ReactHtmlParser(sanitizedText);
};

export default HighlightComponent;
