import React, { useContext } from 'react';
import { UserContext } from './DBAuthenticator.js';
import SitesDashboard from 'views/Dashboard/Sites';
import MarketsDashboard from 'views/Dashboard/Markets';
import VendorTrends from 'views/Dashboard/VendorTrends';
import Search from 'views/Search/Search';
import TableSearch from 'views/Search/TableSearch';
import Searches from 'views/User/Searches.js';
import Records from 'views/User/Records.js';
import Settings from 'views/User/Settings/Settings';
import Support from 'views/Support/Support';
import Resources from 'views/Resources/Resources';
import PGPModule from 'views/Modules/PGPModule';
import BitcoinPageModule from 'views/Modules/BitcoinPageModule';
import AnalystOverview from 'views/Dashboard/AnalystOverview';
import DarkHost from 'views/DarkHost/DarkHost';
import Mapbox from 'views/Visualizations/Map/Mapbox';
import {
  createClient,
  Provider as GraphQLProvider,
  defaultExchanges,
} from 'urql';
import { devtoolsExchange } from '@urql/devtools';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import SearchIcon from '@material-ui/icons/Search';
import CenterFocusWeakTwoTone from '@material-ui/icons/CenterFocusWeakTwoTone';
import AppsIcon from '@material-ui/icons/Apps';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import StoreIcon from '@material-ui/icons/Store';
import StorageSharpIcon from '@material-ui/icons/StorageSharp';
import AllInclusiveSharpIcon from '@material-ui/icons/AllInclusiveSharp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import NoteAdd from '@material-ui/icons/NoteAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import LayersIcon from '@material-ui/icons/Layers';
import WidgetsIcon from '@material-ui/icons/Widgets';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Datasets from 'views/Search/Datasets.js';
import StorageIcon from '@material-ui/icons/Storage';
import WebIcon from '@material-ui/icons/Web';
import PublicIcon from '@material-ui/icons/Public';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';

const getDarkPursuitUrl = async (user, target) => {
  const session = await user.auth.currentSession();
  const token = session.getIdToken();
  const jwt = token.getJwtToken();

  var apiParams = {
    method: 'POST',
    headers: {
      Authorization: jwt,
      'Content-Type': 'application/json',
    },
  };

  if (target) {
    apiParams['body'] = JSON.stringify({ target: target });
  }

  let resp = await fetch(
    process.env.REACT_APP_DARKPURSUIT_ENDPOINT,
    apiParams,
  ).then(async function (response) {
    const resp = await response.json();
    const url = resp.Message;
    window.open(url, '_blank');
  });
};

const getAdvancedUrl = async () => {
  window.open(process.env.REACT_APP_ADV_TAB_ROUTE, '_blank');
};

var dashRoutes = [
  {
    collapse: true,
    name: 'User',
    id: 'user',
    gufId: 'main-user-route',
    icon: <AccountCircleIcon id="user-main-icon" />,
    views: [
      {
        path: '/queries',
        collapse: false,
        name: 'Saved Searches',
        id: 'user/queries',
        gufId: 'user-saved-searches-route',
        mini: <TurnedInIcon id="saved-searches-icon" />,
        component: Searches,
        layout: '/user',
      },
      {
        path: '/records',
        collapse: false,
        name: 'Saved Records',
        id: 'user/records',
        gufId: 'user-saved-records-route',
        mini: <NoteAdd id="saved-records-icon" />,
        component: Records,
        layout: '/user',
      },
      {
        path: '/profile',
        name: 'Settings',
        collapse: false,
        id: 'user/profile',
        gufId: 'user-profile-settings-route',
        mini: <SettingsIcon id="user-profile-settings-icon" />,
        component: (props) => <Settings {...props} />,
        layout: '/user',
      },
    ],
  },
  {
    collapse: true,
    name: 'Mission Spaces',
    icon: <DashboardIcon id="main-dashboard-icon" />,
    id: 'mission_space',

    gufId: 'main-mission-spaces-route',
    views: [
      {
        path: '/analytics',
        name: 'Analytics',
        collapse: false,
        id: 'mission_space/analytics',
        gufId: 'mission-space-analytics-route',
        mini: <VisibilityIcon fontSize="small" id="analytics-icon" />,
        // eslint-disable-next-line react/display-name
        component: (props) => <AnalystOverview {...props} />,
        layout: '/MissionSpace',
      },
      {
        path: '/markets',
        name: 'Markets',
        collapse: false,
        id: 'mission_space/markets',
        gufId: 'mission-space-markets-route',
        mini: <StoreIcon fontSize="small" id="markets-dashboard-icon" />,
        // eslint-disable-next-line react/display-name
        component: (props) => (
          <MarketsDashboard index={['markets', 'market_stats']} {...props} />
        ),
        layout: '/MissionSpace',
      },
      {
        path: '/vendors',
        name: 'Vendors',
        id: 'mission_space/vendors',
        gufId: 'mission-space-vendors-route',
        mini: <StoreIcon fontSize="small" id="vendor-dashboard-icon" />,
        // eslint-disable-next-line react/display-name
        component: (props) => (
          <VendorTrends index={['markets', 'vendor_stats']} {...props} />
        ),
        layout: '/MissionSpace',
      },
    ],
  },
  {
    collapse: false,
    name: 'Search',
    id: 'search/all',
    icon: <SearchIcon id="search-icon" />,
    layout: '/search',
    gufId: 'main-search-route',
    path: '/data',
    component: (props) => {
      const apiKey = props?.user?.apiKey;
      const client = createClient({
        url: process.env.REACT_APP_GRAPHQL_CLIENT_URI,
        exchanges: [devtoolsExchange, ...defaultExchanges],
        fetchOptions: () => {
          return {
            headers: { Authorization: `ApiKey ${apiKey.key}` },
          };
        },
      });
      return (
        <GraphQLProvider value={client}>
          <Search
            hideCategoriesTitles={['Excluded']}
            dataset="Data"
            index={['communications', 'profiles', 'markets', 'sites', 'docs']}
            {...props}
          />
        </GraphQLProvider>
      );
    },
  },

  {
    path: '/map',
    name: 'Map',
    id: 'map',
    gufId: 'visualizations-map',
    collapse: false,
    icon: <PublicIcon />,
    component: (props) => {
      const apiKey = props?.user?.apiKey;
      const client = createClient({
        url: process.env.REACT_APP_GRAPHQL_CLIENT_URI,
        requestPolicy: 'network-only',
        fetchOptions: () => {
          return {
            headers: { Authorization: `ApiKey ${apiKey.key}` },
          };
        },
      });
      return (
        <GraphQLProvider value={client}>
          <Mapbox
            layers={[
              {
                id: 'feature:map/dataset-layer',
                displayText: 'Dataset',
                index: [
                  'communications',
                  'profiles',
                  'markets',
                  'sites',
                  'docs',
                ],
                filterName: 'GeoFilter',
                timeFilterName: 'DateTimeFilter',
                path: 'ai.geo.coordinates',
                layer: 'dataset',
                categories: {
                  color: 'rgba(2, 92, 194, 1)',
                  text: 'Point of Interest',
                  field: 'ai.geo.coordinates',
                },
              },
              {
                id: 'feature:map/darkflow-layer',
                restrictedLayer: true,
                displayText: 'Tor Relay',
                index: ['darkflow'],
                filterName: 'GeoFilter',
                path: 'darkflow.server.geo.location',
                layer: 'darkflow',
                timeFilterName: 'MapsDateTimeFilter',
                category: {
                  color: '#00C2D1',
                  text: 'Tor Client',
                  field: 'darkflow.server.geo.location',
                },
              },
            ]}
            {...props}
          />
        </GraphQLProvider>
      );
    },
    layout: '',
  },
  {
    path: '/darkhost',
    views: false,
    name: 'DarkHost',
    id: 'darkhost',
    gufId: 'darkhost',
    collapse: false,
    icon: <WebIcon />,
    component: (props) => <DarkHost {...props} />,
    layout: '',
  },
  {
    collapse: true,
    name: 'Datasets',
    id: 'datasets',
    gufId: 'main-datasets-route',
    icon: <AllInclusiveSharpIcon id="datasets-main-icon" />,
    views: [
      {
        path: '/databases',
        name: 'Databases',
        collapse: false,
        id: 'datasets/databases',
        gufId: 'datasets-databases-route',
        mini: <StorageSharpIcon fontSize="small" id="databases-icon" />,
        // eslint-disable-next-line react/display-name
        component: (props) => (
          <Datasets
            disableSort={true}
            disableGraph={true}
            indexName="Databases"
            index={['db_*']}
            {...props}
          />
        ),
        layout: '/datasets',
      },
      {
        path: '/scans',
        name: 'Scans',
        id: 'datasets/scans',
        collapse: false,
        gufId: 'datasets-scans-route',
        mini: <CenterFocusWeakTwoTone fontSize="small" id="scans-icon" />,
        component: (props) => (
          <Datasets
            disableGraph={true}
            indexName="Scans"
            index={['scans']}
            {...props}
          />
        ),
        layout: '/datasets',
      },
      {
        path: '/lbc',
        name: 'LocalBitcoins',
        id: 'datasets/lbc',
        gufId: 'datasets-lbc-route',
        collapse: false,
        mini: (
          <i
            className="fab fa-btc fa-md"
            style={{ padding: '0px 1px 0px 7px' }}
            id="lbc-icon"
          ></i>
        ),
        component: (props) => (
          <Search
            hideCategoriesTitles={['Excluded', 'LBC General']}
            categories={['Doc Type', 'Message Type']}
            dataset="LBC"
            index={[
              'lbc_users',
              'lbc_messages',
              'lbc_transactions',
              'lbc_bulletin',
            ]}
            indexName="LBCResults"
            {...props}
          />
        ),
        layout: '/datasets',
      },
    ],
  },
  {
    collapse: true,
    name: 'Tools',
    id: 'module',
    icon: <LayersIcon />,
    gufId: 'main-tools-route',
    views: [
      {
        path: '/pgp',
        name: 'PGP Module',
        id: 'module/pgp',
        gufId: 'pgp-tools-route',
        collapse: false,
        mini: <WidgetsIcon fontSize="small" id="pgp-module-icon" />,
        component: (props) => (
          <PGPModule
            index={['communications', 'profiles', 'markets']}
            name="Modules"
            {...props}
          />
        ),
        layout: '/Modules',
      },
      {
        path: '/bitcoin',
        name: 'Bitcoin Module',
        id: 'module/bitcoin',
        gufId: 'bitcoin-tools-route',
        collapse: false,
        mini: <i className="fab fa-btc fa-md" id="bitcoin-module-icon"></i>,
        component: (props) => (
          <BitcoinPageModule index={['markets', 'market_stats']} {...props} />
        ),
        layout: '/Modules',
      },
    ],
  },
  {
    path: '/resources',
    name: 'Resources',
    id: 'resources',
    gufId: 'main-resources-route',
    collapse: false,
    icon: <LibraryBooksIcon id="resources-icon" />,
    component: Resources,
    layout: '',
  },
  {
    path: '/support',
    name: 'Support',
    id: 'support',
    gufId: 'main-support-route',
    collapse: false,
    icon: <RoomServiceIcon id="support-icon" />,
    component: Support,
    layout: '',
  },
  {
    path: '/darkpursuitLink',
    name: 'DarkPursuit',
    collapse: false,
    linkOut: true,
    url: async (user) => await getDarkPursuitUrl(user),
    id: 'dark_pursuit',
    gufId: 'main-darkpursuit-route',
    layout: '',
    icon: <TrackChangesIcon id="darkpursuit-id" />,
    component: '<div />',
  },
  {
    path: '/advancedLink',
    name: 'Advanced',
    collapse: false,
    linkOut: true,
    url: async (user) => await getAdvancedUrl(user),
    id: 'advanced',
    gufId: 'main-advanced-route',
    layout: '',
    icon: <AppsIcon id="kibana-id" />,
    component: '<div />',
  },
];

const filterRoutes = (perms) => {
  const allowedRoutes = dashRoutes
    .map((route) => {
      if (route.views) {
        const allowedViews = route.views.filter((r) => {
          return (
            perms.includes(`route:${r.id}`) ||
            (r.debug && process.env.REACT_APP_DELPHI_ENV !== 'prod')
          );
        });
        return { ...route, ...{ views: allowedViews } };
      } else if (
        route.collapse === false &&
        perms.includes(`route:${route.id}`)
      ) {
        return route;
      } else if (route.debug && process.env.REACT_APP_DELPHI_ENV !== 'prod') {
        return route;
      }
    })
    .filter((r) => r);
  return allowedRoutes.filter((r) => r.collapse === false || r.views.length);
};

export { filterRoutes, getDarkPursuitUrl };
